import React, { Component } from "react";
import { Card, Row, Col, Form, Button, Offcanvas } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import configData from "../config.json";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
export default class PossscOnep extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      data: [],
      isEditing: false,
      pospId: "",
      currentPage: 1,
      itemsPerPage: 10,
      fName: "",
      lName: "",
      pmobilenumber: "",
      pannumber: "",
      aadharnumber: "",
      emailid: "",
      panPath: "",
      aadharPath: "",
      pPhoto: "",
      sscPath: "",
      pDob: "",
      pGender: "",
      isButtonDisabled: false,
      chequePath: "",
      remarks: "",
      createdBy: 0,
      error: "",
      tableClms: [
        {
          name: "S.No",
          id: "pfName",
          selector: (row, i) => row.pospId,
          sortable: true,
          cell: (row) => <span>{row.pospId}</span>,
        },
        {
          name: "First Name",
          id: "pfName",
          selector: (row, i) => row.pfName,
          sortable: true,
          cell: (row) => <span>{row.pfName}</span>,
        },

        {
          name: "Last Name",
          id: "lName",
          selector: (row, i) => row.plName,
          sortable: true,
          cell: (row) => <span>{row.plName}</span>,
        },
        {
          name: "Moble Number",
          id: "pmobilenumber",
          selector: (row, i) => row.pmobilenumber,
          sortable: true,
          cell: (row) => <span>{row.pmobilenumber}</span>,
        },
        {
          name: "Pan No",
          id: "pannumber",
          selector: (row, i) => row.pannumber,
          sortable: true,
          cell: (row) => <span>{row.pannumber}</span>,
        },
        {
          name: "Aadhar Number",
          id: "aadharnumber",
          selector: (row, i) => row.aadharnumber,
          sortable: true,
          cell: (row) => <span>{row.aadharnumber}</span>,
        },
        {
          name: "Email Id",
          id: "emailid",
          selector: (row, i) => row.emailid,
          sortable: true,
          cell: (row) => <span>{row.emailid}</span>,
        },

        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen"
                style={{ marginRight: "3px" }}
              ></Button>
            </div>
          ),
        },
      ],
    };
  }
  handleOffcanvasClose = () => {
    this.setState({ isEditing: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  handleEnterEditMode = (row) => {
    // this.setState({ cData: { ...row }, isEditing: true });
    var formData = new FormData();
    var pDob = "";
    if (row.pDob !== "0000-00-00") pDob = row.pDob;
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    this.setState({
      pfName: row.pfName,
      plName: row.plName,
      pmobilenumber: row.pmobilenumber,
      pannumber: row.pannumber,
      aadharnumber: row.aadharnumber,
      emailid: row.emailid,
      panPath: row.panPath,
      aadharPath: row.aadharPath,
      pospId: row.pospId,
      pDob: pDob,

      pGender: row.pGender,
      sscPath: row.sscPath,
      chequePath: row.chequePath,
      pPhoto: row.pPhoto,
      createdBy: row.createdBy,
      isEditing: true,
    });
  };
  //    handleDelete = (row, index) => {
  //   var formData = new FormData();
  //   formData.append("sId", this.state.sId);
  //   formData.append("uId", this.state.uId);
  //   const posp_Id = row.posp_Id;
  //   // console.log(e.target.parentElement);
  //   formData.append("posp_Id", posp_Id);
  //   fetch(configData.api_url + "posps/delete", {
  //     // Enter your IP address here
  //     method: "POST",
  //     //headers :{ 'Content-Type' : 'application/json'},
  //     mode: "cors",
  //     body: formData, // body data type must match "Content-Type" header
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       this.showToast(data.message, data.status);
  //       if (data.status === "success") {
  //         this.PospfetchHandler();
  //       }
  //     });
  //   //implement your delete logic here
  // };

  handleShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleClose = () => {
    this.setState({ show: false });
  };
  handleChange = (e) => {
    const { name, value } = e.target;
    const uppercaseValue = value.toUpperCase();
    this.setState({
      [name]: name === "pannumber" ? uppercaseValue : value,
      errors: {
        ...this.state.errors,
        [name]: uppercaseValue,
      },
    });

    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: "", // Clear the corresponding error when the field value changes
      },
    });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  componentDidMount() {
    this.PospfetchHandler();
  }
  PospfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "posps/all/", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((rsdata) => {
        console.log("API Response:", rsdata);
        this.showToast(rsdata.message, rsdata.status);
        if (rsdata.status === "success") {
          const list = rsdata.list;
          const data = [];
          list.forEach((element) => {
            if (element.pStatus === "1") {
              console.log(element.pStatus);
              data.push(element);
            }
          });
          this.handleOffcanvasClose();
          this.setState({ data: data });
        }
      });
  };

  //Sension Approval

  openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  handleSubmit = (e, status) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("pospId", this.state.pospId);
    formData.append("status", status);
    formData.append("createdBy", this.state.createdBy);

    fetch(configData.api_url + "posps/updateStatus/", {
      method: "POST",
      mode: "cors",
      body: formData,
    });
    this.handleOffcanvasClose();
    this.handleApprove();
  };

  handleReject = (e, status) => {
    this.setState({ isButtonDisabled: true });
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("pospId", this.state.pospId);
    formData.append("pStatus", status);
    formData.append("remarks", this.state.remarks);
    formData.append("createdBy", this.state.createdBy);

    fetch(configData.api_url + "posps/updateStatus/", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("API Response:", data);
        this.setState({ remarks: "" });
        this.showToast(data.message, data.status);
        this.handleOffcanvasClose();
      })
      .catch((error) => {
        console.error("API Error:", error);
        this.showToast("Failed to submit form", "error");
      });
  };
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleApprove = (e, status) => {
    this.setState({ isButtonDisabled: true });
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("pospId", this.state.pospId);
    formData.append("pStatus", status);
    formData.append("createdBy", this.state.createdBy);

    fetch(configData.api_url + "posps/updateStatus/", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("API Response:", data);
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.handleClose();
        }
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((error) => {
        console.error("API Error:", error);
        this.showToast("Failed to submit form", "error");
      });
  };
  // pan pdf view
  openPDFInNewTab = (panPath) => {
    window.open(configData.api_url + panPath, "_blank");
  };
  openPDFAadharNewTab = (aadharPath) => {
    window.open(configData.api_url + aadharPath, "_blank");
  };

  openPDFSSCNewTab = (sscPath) => {
    window.open(configData.api_url + sscPath, "_blank");
  };
  openPDFChequeNewTab = (chequePath) => {
    window.open(configData.api_url + chequePath, "_blank");
  };
  openPDFPhotoNewTab = (pPhoto) => {
    window.open(configData.api_url + pPhoto, "_blank");
  };

  onKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault();
      toast.error("Spaces are not allowed.");
    }
  };
  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );
    const previousYearStart = new Date();
    previousYearStart.setFullYear(previousYearStart.getFullYear() - 100);
    previousYearStart.setMonth(0); // Set the month to January
    previousYearStart.setDate(1); // Set the date to the first day of January
    // Example end year
    const endYear = new Date();
    endYear.setFullYear(endYear.getFullYear() + 10);

    return (
      <div>
        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
            <div className="x_panel">
              <div className="x_content">
                <div className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                  <Row>
                    <div className="" style={{ float: "left" }}>
                      <h3 className="logoskybluecolor">
                        Verify POSP With IRDA{" "}
                      </h3>
                    </div>
                    <Col>
                      {this.state.isEditing && (
                        <Offcanvas
                          show={this.state.isEditing}
                          onHide={() => this.setState({ isEditing: false })}
                          {...this.props}
                          style={{ width: "500px" }}
                          placement="end"
                          backdrop="true"
                        >
                          <Offcanvas.Header closeButton>
                            <h3 className="logoskybluecolor">
                              Verify New POSP{" "}
                            </h3>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                            <Card>
                              <Card.Body>
                                <Row>
                                  <Col lg="6" md="6" sm="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          First Name
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          className="form-control"
                                          name="pfName"
                                          value={this.state.pfName}
                                          required="required"
                                          disabled={true}
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="6" md="6" sm="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Last Name
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          // onChange={this.handleChange}
                                          className="form-control"
                                          name="plName"
                                          value={this.state.plName}
                                          required="required"
                                          disabled={true}
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="6" md="6" sm="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Gender
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          // onChange={this.handleChange}
                                          className="form-control"
                                          name="pGender"
                                          value={this.state.pGender}
                                          required="required"
                                          placeholder="Enter First Name"
                                          disabled={true}
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="6" md="6" sm="12">
                                    <Form>
                                      <Form.Group
                                        className=" mb-3"
                                        controlId="formBasicEmail"
                                        name="irdabranch"
                                        onChange={this.handleChange}
                                      >
                                        <Form.Label>D.O.B</Form.Label>

                                        <DatePicker
                                          dateFormat="dd/MM/yyyy"
                                          showTimeSelect={false}
                                          selected={this.state.pDob}
                                          // onChange={(date) => this.handleDChange(date)}
                                          className="form-control"
                                          id="pDob"
                                          disabled={true}
                                          placeholderText="Select Date of Birth"
                                          peekNextMonth
                                          showMonthD
                                          ropdown
                                          showYearDropdown
                                          // minDate={previousYearStart} // Set the minimum selectable date
                                          // maxDate={endYear} // Set the maximum selectable date
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>

                                  <Col lg="12" md="12" sm="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Mobile Number
                                        </Form.Label>
                                        <Form.Control
                                          onKeyDown={this.onKeyDown}
                                          id="pmobilenumber"
                                          type="text"
                                          name="pmobilenumber"
                                          value={this.state.pmobilenumber}
                                          // onChange={this.handleChange}
                                          required="required"
                                          placeholder="Enter Mobile Number"
                                          maxLength={10}
                                          disabled={true}
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="12" md="12" sm="12">
                                    <Form>
                                      <Form.Group
                                        className=" mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Email Id
                                        </Form.Label>
                                        <Form.Control
                                          disabled={true}
                                          type="text"
                                          name="emailid"
                                          value={this.state.emailid}
                                          // onChange={this.handleChange}
                                          required="required"
                                          placeholder="Enter Email-Id"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="12" md="12" sm="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Aadhar Number
                                        </Form.Label>
                                        <Form.Control
                                          disabled={true}
                                          type="text"
                                          name="aadharnumber"
                                          value={this.state.aadharnumber}
                                          required="required"
                                          placeholder="Enter Aadhar Number"
                                          onChange={this.handleChange}
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="12" md="12" sm="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Pan Number
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          //  className="form-control"
                                          name="pannumber"
                                          disabled={true}
                                          value={this.state.pannumber}
                                          onChange={this.handlePanNumberChange}
                                          required="required"
                                          placeholder="Enter Pan Number"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                </Row>
                                <hr />
                                <Row>
                                  <Col lg="12" md="12" sm="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Pan Card Upload
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          disabled={true}
                                          accept=".pdf,.jpg,jpeg,.png"
                                          // onChange={this.handlePanChange}
                                          className="form-control"
                                          name="panPath"
                                          value={this.state.panPath}
                                          required="required"
                                        />
                                      </Form.Group>
                                    </Form>
                                    {this.state.panPath && (
                                      <div>
                                        <Link
                                          onClick={() =>
                                            this.openPDFInNewTab(
                                              this.state.panPath
                                            )
                                          }
                                        >
                                          View Pdf
                                        </Link>
                                      </div>
                                    )}
                                  </Col>
                                  <Col lg="12" md="12" sm="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Aadhar Upload
                                        </Form.Label>
                                        <Form.Control
                                          accept=".pdf,.jpg,jpeg,.png"
                                          disabled={true}
                                          type="text"
                                          onChange={this.handleAadharChange}
                                          className="form-control"
                                          name="aadharPath"
                                          value={this.state.aadharPath}
                                          required="required"
                                        />
                                      </Form.Group>
                                    </Form>
                                    {this.state.aadharPath && (
                                      <div>
                                        <Link
                                          onClick={() =>
                                            this.openPDFAadharNewTab(
                                              this.state.aadharPath
                                            )
                                          }
                                        >
                                          View Pdf
                                        </Link>
                                      </div>
                                    )}
                                  </Col>
                                  <hr />
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Tenth Certificate Upload
                                          <p
                                            style={{
                                              color: "red",
                                              fontSize: "10px",
                                            }}
                                          >
                                            (* .pdf only)
                                          </p>
                                        </Form.Label>
                                        <Form.Control
                                          accept=".pdf,.jpg,jpeg,.png"
                                          disabled={true}
                                          type="text"
                                          value={this.state.sscPath}
                                          // onChange={this.handleTcChange}
                                          className="form-control"
                                          name="sscPath"
                                          required="required"
                                        />
                                      </Form.Group>
                                    </Form>
                                    {this.state.sscPath && (
                                      <div>
                                        <Link
                                          onClick={() =>
                                            this.openPDFSSCNewTab(
                                              this.state.sscPath
                                            )
                                          }
                                        >
                                          View Pdf
                                        </Link>
                                      </div>
                                    )}
                                  </Col>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Cheque Upload{" "}
                                          <p
                                            style={{
                                              color: "red",
                                              fontSize: "10px",
                                            }}
                                          >
                                            (* .pdf only)
                                          </p>
                                        </Form.Label>
                                        <Form.Control
                                          accept=".pdf,.jpg,jpeg,.png"
                                          type="text"
                                          value={this.state.chequePath}
                                          disabled={true}
                                          // onChange={this.handleChequeChange}
                                          className="form-control"
                                          name="chequePath"
                                          required="required"
                                        />
                                      </Form.Group>
                                    </Form>
                                    {this.state.chequePath && (
                                      <div>
                                        <Link
                                          onClick={() =>
                                            this.openPDFChequeNewTab(
                                              this.state.chequePath
                                            )
                                          }
                                        >
                                          View Pdf
                                        </Link>
                                      </div>
                                    )}
                                  </Col>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Passport Size Color Photo{" "}
                                          <p
                                            style={{
                                              color: "red",
                                              fontSize: "10px",
                                            }}
                                          >
                                            (* .pdf only)
                                          </p>
                                        </Form.Label>
                                        <Form.Control
                                          accept=".pdf,.jpg,jpeg,.png"
                                          type="text"
                                          value={this.state.pPhoto}
                                          // onChange={this.handlepsPhotoChange}
                                          className="form-control"
                                          name="pPhoto"
                                          required="required"
                                          disabled={true}
                                        />
                                      </Form.Group>
                                    </Form>
                                    {this.state.pPhoto && (
                                      <div>
                                        <Link
                                          onClick={() =>
                                            this.openPDFPhotoNewTab(
                                              this.state.pPhoto
                                            )
                                          }
                                        >
                                          View Pdf
                                        </Link>
                                      </div>
                                    )}
                                  </Col>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form.Group>
                                      <Form.Label>Verifiy With IRDA</Form.Label>
                                      <Link
                                        to="#"
                                        style={{ color: "blue" }}
                                        className="nav-link collapsed"
                                        onClick={() =>
                                          this.openInNewTab(
                                            "https://agencyportal.irdai.gov.in/PublicAccess/LookUpPAN.aspx"
                                          )
                                        }
                                      >
                                        Click to verify
                                      </Link>
                                    </Form.Group>
                                  </Col>
                                </Row>

                                <hr />

                                <Row>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form.Group className="form-group">
                                      <Form.Label
                                        className="control-label"
                                        style={{ paddingTop: "8px" }}
                                      >
                                        Remarks
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        id="remarks"
                                        aria-describedby="remarks"
                                        name="remarks"
                                        value={this.state.remarks}
                                        onChange={this.handleInputChange}
                                        placeholder="Remarks"
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <hr />
                                <Row>
                                  <Col
                                    className="d-flex justify-content-start"
                                    style={{
                                      marginRight: "5px",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <Button
                                      variant="danger"
                                      style={{
                                        marginRight: "5px",
                                        marginTop: "20px",
                                      }}
                                      disabled={this.state.isButtonDisabled}
                                      type="submit"
                                      onClick={(e) => this.handleReject(e, "2")}
                                    >
                                      Reject
                                    </Button>
                                  </Col>
                                  <Col
                                    className="d-flex justify-content-end"
                                    style={{
                                      marginRight: "5px",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <Button
                                      variant="success"
                                      onClick={(e) =>
                                        this.handleApprove(e, "3")
                                      }
                                      disabled={this.state.isButtonDisabled}
                                    >
                                      Approve
                                    </Button>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Offcanvas.Body>
                        </Offcanvas>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <div>
                      {/* searchbar,pagination,filter */}
                      <input
                        style={{ float: "left", marginBottom: "10px" }}
                        type="text"
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        // paginationTotalRows={this.state.data.length}
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        paginationRowsPerPageOptions={[
                          10, 20, 30, 50, 60, 70, 80, 90, 100,
                        ]}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

import React, { Component } from "react";
import "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-toastify/dist/ReactToastify.css";
import { Image } from "react-bootstrap";
import Apnapolicy from "./Header/Img/Apnapolicy.png";
import "./Login.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import configData from "./config.json";
import { LogOut } from "./Header/LoginComponent";
import CryptoJS from "crypto-js";

export default class ChangePwd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      repassword: "",
      newpassword: "",
      oldpassword: "",
      error: false,
      show: false,
    };
  }
  showToast = (msg, type) => {
    let tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const { repassword, newpassword, oldpassword } = this.state;

    if (!this.validatePassword(oldpassword)) {
      this.showToast(
        "Old Password must contain at least one special character!",
        "error"
      );
      return;
    }
    if (!this.validatePassword(newpassword)) {
      this.showToast(
        "New Password must contain at least one special character!",
        "error"
      );
      return;
    }
    if (newpassword !== repassword) {
      this.showToast("Password mismatch!", "error");
      return;
    }
    if (newpassword === oldpassword) {
      this.showToast("Old & New passwords are same!", "error");
      return;
    }
    const formData = new FormData();
    formData.append("sessionId", sessionStorage.getItem("sessionId"));
    formData.append(
      "newpassword",
      CryptoJS.HmacMD5(newpassword, configData.secret).toString()
    );
    formData.append(
      "oldpassword",
      CryptoJS.HmacMD5(oldpassword, configData.secret).toString()
    );
    fetch(configData.api_url + "login/changepwd", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          LogOut();
        }
      });
  };

  validatePassword = (password) => {
    const passwordPattern = /^(?=.*[!@#$%^&*])/;
    return passwordPattern.test(password);
  };

  render() {
    return (
      <div style={{ overflow: "hidden" }}>
        <center className="mt-5">
          <div style={{ textAlign: "center" }}>
            <div className="Auth-form-container">
              <form className="Auth-form">
                <div className="Auth-form-content">
                  <Image
                    src={Apnapolicy}
                    className="navbar-brand pe-3 ps-4 ps-lg-2"
                    alt=""
                    style={{
                      width: "auto",
                      height: "44px",
                      marginBottom: "5px",
                    }}
                  />
                  <h4 style={{ color: "#25335b" }}> Change Password</h4>

                  <div className="form-group mt-3">
                    <input
                      className="form-control mt-1"
                      type="password"
                      onChange={(e) =>
                        this.setState({ oldpassword: e.target.value })
                      }
                      value={this.state.oldpassword}
                      placeholder="Old password"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <input
                      className="form-control mt-1"
                      type="password"
                      onChange={(e) =>
                        this.setState({ newpassword: e.target.value })
                      }
                      value={this.state.newpassword}
                      placeholder="New password"
                    />
                  </div>
                  <div className="form-group mt-3">
                    <input
                      className="form-control mt-1"
                      type="password"
                      onChange={(e) =>
                        this.setState({ repassword: e.target.value })
                      }
                      value={this.state.repassword}
                      placeholder="Retype new password"
                    />
                  </div>
                  <div
                    className="d-grid gap-2 mt-3 "
                    style={{ justifyContent: "center" }}
                  >
                    <button
                      // type="button"
                      onClick={this.onSubmit}
                      className="btn btn-light"
                      style={{ width: "100px", borderRadius: "0" }}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </center>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Button, Container, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";

export default class Premiumapprovalentry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.premiumapprovalentry_Id,
          sortable: true,
          cell: (row) => <span>{row.premiumapprovalentry_Id}</span>,
        },
        {
          name: "Month",
          selector: (row, i) => row.month,
          sortable: true,
          cell: (row) => <span>{row.month}</span>,
        },
        {
          name: "Insurer Type",
          selector: (row, i) => row.insurertype,
          sortable: true,
          cell: (row) => <span>{row.insurertype}</span>,
        },
        {
          name: "Insurer Name",
          selector: (row, i) => row.insurername,
          sortable: true,
          cell: (row) => <span>{row.insurername}</span>,
        },
        {
          name: "Customer Name",
          selector: (row, i) => row.customername,
          sortable: true,
          cell: (row) => <span>{row.customername}</span>,
        },
        {
          name: "Premium Type",
          selector: (row, i) => row.premiumtype,
          sortable: true,
          cell: (row) => <span>{row.premiumtype}</span>,
        },
        {
          name: "Policy No",
          selector: (row, i) => row.policynumber,
          sortable: true,
          cell: (row) => <span>{row.policynumber}</span>,
        },
        {
          name: "Endorsement/Installment No",
          selector: (row, i) => row.endorsementnumber,
          sortable: true,
          cell: (row) => <span>{row.endorsementnumber}</span>,
        },
        {
          name: "IRDA Category",
          selector: (row, i) => row.irdacategory,
          sortable: true,
          cell: (row) => <span>{row.irdacategory}</span>,
        },
        {
          name: "Policy Status",
          selector: (row, i) => row.polictstatus,
          sortable: true,
          cell: (row) => <span>{row.polictstatus}</span>,
        },
        {
          name: "Risk Start Date",
          selector: (row, i) => row.riskstartdate,
          sortable: true,
          cell: (row) => <span>{row.riskstartdate}</span>,
        },
        {
          name: "Risk End Date",
          selector: (row, i) => row.riskenddate,
          sortable: true,
          cell: (row) => <span>{row.riskenddate}</span>,
        },
        {
          name: "Endorsement/Installment Date",
          selector: (row, i) => row.endorsementdate,
          sortable: true,
          cell: (row) => <span>{row.endorsementdate}</span>,
        },
        {
          name: "Sum Insured",
          selector: (row, i) => row.suminsured,
          sortable: true,
          cell: (row) => <span>{row.suminsured}</span>,
        },
        {
          name: "Basic/OD Premium",
          selector: (row, i) => row.basicodpremium,
          sortable: true,
          cell: (row) => <span>{row.basicodpremium}</span>,
        },
        {
          name: "TP or Terrorism Premium",
          selector: (row, i) => row.tppremium,
          sortable: true,
          cell: (row) => <span>{row.tppremium}</span>,
        },
        {
          name: "Net Premium",
          selector: (row, i) => row.netpremium,
          sortable: true,
          cell: (row) => <span>{row.netpremium}</span>,
        },
        {
          name: "Total Gst",
          selector: (row, i) => row.totalgst,
          sortable: true,
          cell: (row) => <span>{row.totalgst}</span>,
        },
        {
          name: "SGST",
          selector: (row, i) => row.sgst,
          sortable: true,
          cell: (row) => <span>{row.sgst}</span>,
        },
        {
          name: "CGST",
          selector: (row, i) => row.cgst,
          sortable: true,
          cell: (row) => <span>{row.cgst}</span>,
        },
        {
          name: "IGST",
          selector: (row, i) => row.igst,
          sortable: true,
          cell: (row) => <span>{row.igst}</span>,
        },
        {
          name: "Gross Premium",
          selector: (row, i) => row.grosspremium,
          sortable: true,
          cell: (row) => <span>{row.grosspremium}</span>,
        },
        {
          name: "Sales Person",
          selector: (row, i) => row.salesperson,
          sortable: true,
          cell: (row) => <span>{row.salesperson}</span>,
        },
        {
          name: "POSID",
          selector: (row, i) => row.posid,
          sortable: true,
          cell: (row) => <span>{row.posid}</span>,
        },
        {
          name: "Retail/Group",
          selector: (row, i) => row.retailgroup,
          sortable: true,
          cell: (row) => <span>{row.retailgroup}</span>,
        },
        {
          name: "Policy Upload",
          selector: (row, i) => row.policyupload,
          sortable: true,
          cell: (row) => <span>{row.policyupload}</span>,
        },
        {
          name: "MIS Status",
          selector: (row, i) => row.misstatus,
          sortable: true,
          cell: (row) => <span>{row.misstatus}</span>,
        },
        {
          name: "MIS Remarks",
          selector: (row, i) => row.misremarks,
          sortable: true,
          cell: (row) => <span>{row.misremarks}</span>,
        },
        {
          name: "Group Commission",
          selector: (row, i) => row.groupcommission,
          sortable: true,
          cell: (row) => <span>{row.groupcommission}</span>,
        },
        {
          name: "OD/Basic Commission",
          selector: (row, i) => row.odbasiccummission,
          sortable: true,
          cell: (row) => <span>{row.odbasiccummission}</span>,
        },
        {
          name: "TP or Terrorism Commission",
          selector: (row, i) => row.terrorismcommission,
          sortable: true,
          cell: (row) => <span>{row.terrorismcommission}</span>,
        },
        {
          name: "Rewards",
          selector: (row, i) => row.rewards,
          sortable: true,
          cell: (row) => <span>{row.rewards}</span>,
        },
        {
          name: "Valid From",
          selector: (row, i) => row.validfrom,
          sortable: true,
          cell: (row) => <span>{row.validfrom}</span>,
        },
        {
          name: "Valid To",
          selector: (row, i) => row.validto,
          sortable: true,
          cell: (row) => <span>{row.validto}</span>,
        },
        {
          name: "POS OD/Commission",
          selector: (row, i) => row.posodconmmission,
          sortable: true,
          cell: (row) => <span>{row.posodconmmission}</span>,
        },
        {
          name: "POS TP/Commision",
          selector: (row, i) => row.postpcommission,
          sortable: true,
          cell: (row) => <span>{row.postpcommission}</span>,
        },
        {
          name: "POS Group Commission",
          selector: (row, i) => row.posgroupcommission,
          sortable: true,
          cell: (row) => <span>{row.posgroupcommission}</span>,
        },
        {
          name: "POS Reward",
          selector: (row, i) => row.posrewars,
          sortable: true,
          cell: (row) => <span>{row.posrewars}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={this.handleOffcanvasShow}
                className="bi bi-pen"
                style={{ marginRight: "3px" }}
              ></Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      month: "",
      insurertype: "",
      insurername: "",
      customername: "",
      premiumtype: "",
      policynumber: "",
      endorsementnumber: "",
      irdacategory: "",
      polictstatus: "",
      riskstartdate: "",
      riskenddate: "",
      endorsementdate: "",
      suminsured: "",
      basicodpremium: "",
      tppremium: "",
      netpremium: "",
      totalgst: "",
      sgst: "",
      cgst: "",
      igst: "",
      grosspremium: "",
      salesperson: "",
      posid: "",
      retailgroup: "",
      policyupload: "",
      misstatus: "",
      misremarks: "",
      groupcommission: "",
      odbasiccummission: "",
      terrorismcommission: "",
      rewards: "",
      validfrom: "",
      validto: "",
      posodconmmission: "",
      postpcommission: "",
      posgroupcommission: "",
      posrewars: "",
    };
  }
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  PremiumapprovalentryeditHandler = (e) => {
    e.preventDefault();
    // const updateFormData = {...editFormData};
    // updateFormData['Premiumapprovalentry_Id'] = e.target.parentElement.getAttribute("id");
    // setEditFormData(updateFormData);
    // setEdit(true);
  };

  PremiumapprovalentryfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Premiumapprovalentry/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  PremiumapprovalentrysubmitHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("month", this.state.month);
    formData.append("insurertype", this.state.insurertype);
    formData.append("insurername", this.state.insurername);
    formData.append("customername", this.state.customername);
    formData.append("premiumtype", this.state.premiumtype);
    formData.append("policynumber", this.state.policynumber);
    formData.append("endorsementnumber", this.state.endorsementnumber);
    formData.append("irdacategory", this.state.irdacategory);
    formData.append("polictstatus", this.state.polictstatus);
    formData.append("riskstartdate", this.state.riskstartdate);
    formData.append("riskenddate", this.state.riskenddate);
    formData.append("endorsementdate", this.state.endorsementdate);
    formData.append("suminsured", this.state.suminsured);
    formData.append("basicodpremium", this.state.basicodpremium);
    formData.append("tppremium", this.state.tppremium);
    formData.append("netpremium", this.state.netpremium);
    formData.append("totalgst", this.state.totalgst);
    formData.append("sgst", this.state.sgst);
    formData.append("cgst", this.state.cgst);
    formData.append("igst", this.state.igst);
    formData.append("grosspremium", this.state.grosspremium);
    formData.append("salesperson", this.state.salesperson);
    formData.append("posid", this.state.posid);
    formData.append("retailgroup", this.state.retailgroup);
    formData.append("policyupload", this.state.policyupload);
    formData.append("misstatus", this.state.misstatus);
    formData.append("misremarks", this.state.misremarks);
    formData.append("groupcommission", this.state.groupcommission);
    formData.append("odbasiccummission", this.state.odbasiccummission);
    formData.append("terrorismcommission", this.state.terrorismcommission);
    formData.append("rewards", this.state.rewards);
    formData.append("validfrom", this.state.validfrom);
    formData.append("validto", this.state.validto);
    formData.append("posodconmmission", this.state.posodconmmission);
    formData.append("postpcommission", this.state.postpcommission);
    formData.append("posgroupcommission", this.state.posgroupcommission);
    formData.append("posrewars", this.state.posrewars);

    fetch(configData.api_url + "C_Premiumapprovalentry/add", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.PremiumapprovalentryfetchHandler();
          this.handleOffcanvasClose();
          this.setState({
            month: "",
            insurertype: "",
            insurername: "",
            customername: "",
            premiumtype: "",
            policynumber: "",
            endorsementnumber: "",
            irdacategory: "",
            polictstatus: "",
            riskstartdate: "",
            riskenddate: "",
            endorsementdate: "",
            suminsured: "",
            basicodpremium: "",
            tppremium: "",
            netpremium: "",
            totalgst: "",
            sgst: "",
            cgst: "",
            igst: "",
            grosspremium: "",
            salesperson: "",
            posid: "",
            retailgroup: "",
            policyupload: "",
            misstatus: "",
            misremarks: "",
            groupcommission: "",
            odbasiccummission: "",
            terrorismcommission: "",
            rewards: "",
            validfrom: "",
            validto: "",
            posodconmmission: "",
            postpcommission: "",
            posgroupcommission: "",
            posrewars: "",
          });
        }
      });
  };
  PremiumapprovalentryupdateHandler = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("month", this.state.month);
    formData.append("insurertype", this.state.insurertype);
    formData.append("insurername", this.state.insurername);
    formData.append("customername", this.state.customername);
    formData.append("premiumtype", this.state.premiumtype);
    formData.append("policynumber", this.state.policynumber);
    formData.append("endorsementnumber", this.state.endorsementnumber);
    formData.append("irdacategory", this.state.irdacategory);
    formData.append("polictstatus", this.state.polictstatus);
    formData.append("riskstartdate", this.state.riskstartdate);
    formData.append("riskenddate", this.state.riskenddate);
    formData.append("endorsementdate", this.state.endorsementdate);
    formData.append("suminsured", this.state.suminsured);
    formData.append("basicodpremium", this.state.basicodpremium);
    formData.append("tppremium", this.state.tppremium);
    formData.append("netpremium", this.state.netpremium);
    formData.append("totalgst", this.state.totalgst);
    formData.append("sgst", this.state.sgst);
    formData.append("cgst", this.state.cgst);
    formData.append("igst", this.state.igst);
    formData.append("grosspremium", this.state.grosspremium);
    formData.append("salesperson", this.state.salesperson);
    formData.append("posid", this.state.posid);
    formData.append("retailgroup", this.state.retailgroup);
    formData.append("policyupload", this.state.policyupload);
    formData.append("misstatus", this.state.misstatus);
    formData.append("misremarks", this.state.misremarks);
    formData.append("groupcommission", this.state.groupcommission);
    formData.append("odbasiccummission", this.state.odbasiccummission);
    formData.append("terrorismcommission", this.state.terrorismcommission);
    formData.append("rewards", this.state.rewards);
    formData.append("validfrom", this.state.validfrom);
    formData.append("validto", this.state.validto);
    formData.append("posodconmmission", this.state.posodconmmission);
    formData.append("postpcommission", this.state.postpcommission);
    formData.append("posgroupcommission", this.state.posgroupcommission);
    formData.append("posrewars", this.state.posrewars);

    fetch(configData.api_url + "C_Premiumapprovalentry/update", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.PremiumapprovalentryfetchHandler();
          this.handleOffcanvasClose();
        }
      });
  };

  componentDidMount() {
    this.PremiumapprovalentryfetchHandler();
  }

  handlemonthChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ month: fieldValue });
  };
  handleinsurertypeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ insurertype: fieldValue });
  };
  handleinsurernameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ insurername: fieldValue });
  };
  handlecustomernameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ customername: fieldValue });
  };
  handlepremiumtypeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ premiumtype: fieldValue });
  };
  handlepolicynumberChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ policynumber: fieldValue });
  };
  handleendorsementnumberChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ endorsementnumber: fieldValue });
  };
  handleirdacategoryChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ irdacategory: fieldValue });
  };
  handlepolictstatusChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ polictstatus: fieldValue });
  };
  handleriskstartdateChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ riskstartdate: fieldValue });
  };
  handleriskenddateChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ riskenddate: fieldValue });
  };
  handleendorsementdateChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ endorsementdate: fieldValue });
  };
  handlesuminsuredChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ suminsured: fieldValue });
  };
  handlebasicodpremiumChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ basicodpremium: fieldValue });
  };
  handletppremiumChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ tppremium: fieldValue });
  };
  handlenetpremiumChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ netpremium: fieldValue });
  };
  handletotalgstChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ totalgst: fieldValue });
  };
  handlesgstChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ sgst: fieldValue });
  };
  handlecgstChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ cgst: fieldValue });
  };
  handleigstChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ igst: fieldValue });
  };
  handlegrosspremiumChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ grosspremium: fieldValue });
  };
  handlesalespersonChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ salesperson: fieldValue });
  };
  handleposidChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ posid: fieldValue });
  };
  handleretailgroupChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ retailgroup: fieldValue });
  };
  handlepolicyuploadChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ policyupload: fieldValue });
  };
  handlemisstatusChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ misstatus: fieldValue });
  };
  handlemisremarksChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ misremarks: fieldValue });
  };
  handlegroupcommissionChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ groupcommission: fieldValue });
  };
  handleodbasiccummissionChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ odbasiccummission: fieldValue });
  };
  handleterrorismcommissionChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ terrorismcommission: fieldValue });
  };
  handlerewardsChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ rewards: fieldValue });
  };
  handlevalidfromChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ validfrom: fieldValue });
  };
  handlevalidtoChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ validto: fieldValue });
  };
  handleposodconmmissionChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ posodconmmission: fieldValue });
  };
  handlepostpcommissionChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ postpcommission: fieldValue });
  };
  handleposgroupcommissionChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ posgroupcommission: fieldValue });
  };
  handleposrewarsChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ posrewars: fieldValue });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
    console.log(`Editing row with ID ${row.id}`);
    // implement your edit logic here
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("premiumapprovalentry_Id", row.premiumapprovalentry_Id);
    fetch(configData.api_url + "C_Premiumapprovalentry/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.PremiumapprovalentryfetchHandler();
        }
      });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div className="page-title">
        <div className="row">
          <div className="title_left">
            <h2 style={{ textAlign: "start" }}>Premium Approval Entry</h2>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                                   dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "5px", marginTop: "20px" }}
                    >
                      <Button
                        variant="primary"
                        className="bi bi-plus "
                        onClick={this.handleOffcanvasShow}
                      >
                        New
                      </Button>
                      <Offcanvas
                        show={this.state.show}
                        onHide={this.handleOffcanvasClose}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            Premiumapprovalentry page
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                            <Row className="divstyle">
                              <Row>
                                <Form>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Month
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="date"
                                        onChange={this.handlemonthChange}
                                        className="form-control"
                                        name="month"
                                        value={this.state.month}
                                        required="required"
                                        placeholder="Enter month"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.month}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Insurer Type
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleinsurertypeChange}
                                        className="form-control"
                                        name="insurertype"
                                        value={this.state.insurertype}
                                        required="required"
                                        placeholder="Enter insurertype"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.insurertype}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Insurer Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleinsurernameChange}
                                        className="form-control"
                                        name="insurername"
                                        value={this.state.insurername}
                                        required="required"
                                        placeholder="Enter insurername"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.insurername}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Customer Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlecustomernameChange}
                                        className="form-control"
                                        name="customername"
                                        value={this.state.customername}
                                        required="required"
                                        placeholder="Enter customername"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.customername}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Premium Type
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlepremiumtypeChange}
                                        className="form-control"
                                        name="premiumtype"
                                        value={this.state.premiumtype}
                                        required="required"
                                        placeholder="Enter premiumtype"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.premiumtype}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Policy No
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlepolicynumberChange}
                                        className="form-control"
                                        name="policynumber"
                                        value={this.state.policynumber}
                                        required="required"
                                        placeholder="Enter policynumber"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.policynumber}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Endorsement/Installment No
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleendorsementnumberChange
                                        }
                                        className="form-control"
                                        name="endorsementnumber"
                                        value={this.state.endorsementnumber}
                                        required="required"
                                        placeholder="Enter endorsementnumber"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.endorsementnumber}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        IRDA Category
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleirdacategoryChange}
                                        className="form-control"
                                        name="irdacategory"
                                        value={this.state.irdacategory}
                                        required="required"
                                        placeholder="Enter irdacategory"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.irdacategory}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Policy Status
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlepolictstatusChange}
                                        className="form-control"
                                        name="polictstatus"
                                        value={this.state.polictstatus}
                                        required="required"
                                        placeholder="Enter polictstatus"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.polictstatus}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Risk Start Date
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="date"
                                        onChange={
                                          this.handleriskstartdateChange
                                        }
                                        className="form-control"
                                        name="riskstartdate"
                                        value={this.state.riskstartdate}
                                        required="required"
                                        placeholder="Enter riskstartdate"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.riskstartdate}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Risk End Date
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="date"
                                        onChange={this.handleriskenddateChange}
                                        className="form-control"
                                        name="riskenddate"
                                        value={this.state.riskenddate}
                                        required="required"
                                        placeholder="Enter riskenddate"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.riskenddate}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Endorsement/Installment Date
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="date"
                                        onChange={
                                          this.handleendorsementdateChange
                                        }
                                        className="form-control"
                                        name="endorsementdate"
                                        value={this.state.endorsementdate}
                                        required="required"
                                        placeholder="Enter endorsementdate"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.endorsementdate}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Sum Insured
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        onChange={this.handlesuminsuredChange}
                                        className="form-control"
                                        name="suminsured"
                                        value={this.state.suminsured}
                                        required="required"
                                        placeholder="Enter suminsured"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.suminsured}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Basic/OD Premium
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        onChange={
                                          this.handlebasicodpremiumChange
                                        }
                                        className="form-control"
                                        name="basicodpremium"
                                        value={this.state.basicodpremium}
                                        required="required"
                                        placeholder="Enter basicodpremium"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.basicodpremium}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        TP or Terrorism Premium
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        onChange={this.handletppremiumChange}
                                        className="form-control"
                                        name="tppremium"
                                        value={this.state.tppremium}
                                        required="required"
                                        placeholder="Enter tppremium"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.tppremium}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Net Premium
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        onChange={this.handlenetpremiumChange}
                                        className="form-control"
                                        name="netpremium"
                                        value={this.state.netpremium}
                                        required="required"
                                        placeholder="Enter netpremium"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.netpremium}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Total Gst
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        onChange={this.handletotalgstChange}
                                        className="form-control"
                                        name="totalgst"
                                        value={this.state.totalgst}
                                        required="required"
                                        placeholder="Enter totalgst"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.totalgst}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        SGST
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        onChange={this.handlesgstChange}
                                        className="form-control"
                                        name="sgst"
                                        value={this.state.sgst}
                                        required="required"
                                        placeholder="Enter sgst"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.sgst}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        CGST
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        onChange={this.handlecgstChange}
                                        className="form-control"
                                        name="cgst"
                                        value={this.state.cgst}
                                        required="required"
                                        placeholder="Enter cgst"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.cgst}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        IGST
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        onChange={this.handleigstChange}
                                        className="form-control"
                                        name="igst"
                                        value={this.state.igst}
                                        required="required"
                                        placeholder="Enter igst"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.igst}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Gross Premium
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        onChange={this.handlegrosspremiumChange}
                                        className="form-control"
                                        name="grosspremium"
                                        value={this.state.grosspremium}
                                        required="required"
                                        placeholder="Enter grosspremium"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.grosspremium}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Sales Person
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlesalespersonChange}
                                        className="form-control"
                                        name="salesperson"
                                        value={this.state.salesperson}
                                        required="required"
                                        placeholder="Enter salesperson"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.salesperson}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        POSID
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleposidChange}
                                        className="form-control"
                                        name="posid"
                                        value={this.state.posid}
                                        required="required"
                                        placeholder="Enter posid"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.posid}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Retail/Group
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleretailgroupChange}
                                        className="form-control"
                                        name="retailgroup"
                                        value={this.state.retailgroup}
                                        required="required"
                                        placeholder="Enter retailgroup"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.retailgroup}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Policy Upload
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="file"
                                        onChange={this.handlepolicyuploadChange}
                                        className="form-control"
                                        name="policyupload"
                                        value={this.state.policyupload}
                                        required="required"
                                        placeholder="Enter policyupload"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.policyupload}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        MIS Status
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlemisstatusChange}
                                        className="form-control"
                                        name="misstatus"
                                        value={this.state.misstatus}
                                        required="required"
                                        placeholder="Enter misstatus"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.misstatus}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        MIS Remarks
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlemisremarksChange}
                                        className="form-control"
                                        name="misremarks"
                                        value={this.state.misremarks}
                                        required="required"
                                        placeholder="Enter misremarks"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.misremarks}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Group Commission
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlegroupcommissionChange
                                        }
                                        className="form-control"
                                        name="groupcommission"
                                        value={this.state.groupcommission}
                                        required="required"
                                        placeholder="Enter groupcommission"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.groupcommission}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        OD/Basic Commission
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleodbasiccummissionChange
                                        }
                                        className="form-control"
                                        name="odbasiccummission"
                                        value={this.state.odbasiccummission}
                                        required="required"
                                        placeholder="Enter odbasiccummission"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.odbasiccummission}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        TP or Terrorism Commission
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleterrorismcommissionChange
                                        }
                                        className="form-control"
                                        name="terrorismcommission"
                                        value={this.state.terrorismcommission}
                                        required="required"
                                        placeholder="Enter terrorismcommission"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.terrorismcommission
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Rewards
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlerewardsChange}
                                        className="form-control"
                                        name="rewards"
                                        value={this.state.rewards}
                                        required="required"
                                        placeholder="Enter rewards"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.rewards}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Valid From
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="date"
                                        onChange={this.handlevalidfromChange}
                                        className="form-control"
                                        name="validfrom"
                                        value={this.state.validfrom}
                                        required="required"
                                        placeholder="Enter validfrom"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.validfrom}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Valid To
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="date"
                                        onChange={this.handlevalidtoChange}
                                        className="form-control"
                                        name="validto"
                                        value={this.state.validto}
                                        required="required"
                                        placeholder="Enter validto"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.validto}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        POS OD/Commission
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleposodconmmissionChange
                                        }
                                        className="form-control"
                                        name="posodconmmission"
                                        value={this.state.posodconmmission}
                                        required="required"
                                        placeholder="Enter posodconmmission"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.posodconmmission}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        POS TP/Commision
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlepostpcommissionChange
                                        }
                                        className="form-control"
                                        name="postpcommission"
                                        value={this.state.postpcommission}
                                        required="required"
                                        placeholder="Enter postpcommission"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.postpcommission}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        POS Group Commission
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleposgroupcommissionChange
                                        }
                                        className="form-control"
                                        name="posgroupcommission"
                                        value={this.state.posgroupcommission}
                                        required="required"
                                        placeholder="Enter posgroupcommission"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.posgroupcommission
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        POS Reward
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleposrewarsChange}
                                        className="form-control"
                                        name="posrewars"
                                        value={this.state.posrewars}
                                        required="required"
                                        placeholder="Enter posrewars"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.posrewars}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                </Form>
                              </Row>
                            </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={
                                    this.PremiumapprovalentrysubmitHandler
                                  }
                                  style={{
                                    marginTop: "20px",
                                  }}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                      {/*offcanvas is over */}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <div style={{ marginRight: "5px" }}>
                      {/* searchbar,pagination,filter */}
                      <input
                        type="text"
                        style={{ float: "left", marginBottom: "10px" }}
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// import React, { Component } from "react";
// import {
//   Form,
//   Card,
//   Col,
//   Container,
//   Row,
//   Table,
//   Image,
//   Button,
// } from "react-bootstrap";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import configData from "../config.json";
// import "./Proposalsummary.css";

// export default class Proposalsummary extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       proposalId: sessionStorage.getItem("proposalId"),
//       proposalNum: sessionStorage.getItem("proposalNum"),
//       baseproductId: sessionStorage.getItem("baseproductId"),
//       chpiId: sessionStorage.getItem("chpiId"),
//       cqrId: sessionStorage.getItem("cqrId"),
//       uId: sessionStorage.getItem("uId"),
//       forgotPasswordCondition: false,
//       policiescheckbox: "",
//       consentCheckbox: false,
//       declarationCheckbox: false,
//       membersstatus: [],
//       products: [],
//       members: [],
//       address: [],
//       details: [],
//       medicals: [],
//       nominees: [],
//       addons: [],
//       premium: [],
//       email: [],
//       kyc:[],
//       kycData:[],
//       contactdetails:[],
//       addoncodes: {},
//       relations: {},
//       nrelations: {},
//       requestData: "",
//     };  

//     // console.log(this.props.params.itId);
//   }
//   handleInputChange = (tab, field, value) => {
//     this.setState((prevState) => ({
//       [tab]: {
//         ...prevState[tab],
//         [field]: value,
//       },
//     }));
//   };
//   showToast = (msg, type) => {
//     var tType = toast.TYPE.INFO;
//     if (type === "success") tType = toast.TYPE.SUCCESS;
//     if (type === "error") tType = toast.TYPE.ERROR;
//     if (type === "warning") tType = toast.TYPE.WARNING;
//     toast(msg, { type: tType });
//   };
//   componentDidMount() {
//     this.apisfetchHandler();
//     this.CaresupremeaddonsfetchHandler();
//     this.addOnsfetchHandler();
//     this.relationfetchHandler();
//     this.HealthnomineerelationsfetchHandler();

//     // this.apisfetchPolicyHandler();
//   }
//   relationfetchHandler = () => {
//     var formData = new FormData();
//     formData.append("baseproductId", this.state.baseproductId);
//     fetch(configData.api_url + "C_Healthrelationcodes/listByid/", {
//       method: "POST",
//       mode: "cors",
//       body: formData, // body data type must match "Content-Type" header
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         this.showToast(data.message, data.success);
//         if (data.status === "success") {
//           this.setState({ relations: data.codes });
//         }
//       });
//   };
//   apisfetchPolicyHandler = () => {
//     var formData = new FormData();
//     formData.append("proposalId", this.state.proposalId);
//     formData.append("chpiId", this.state.chpiId);
//     fetch(configData.api_url + "leads/createpolicy/", {
//       method: "POST",
//       mode: "cors",
//       body: formData, // body data type must match "Content-Type" header
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         this.showToast(data.message, data.success);
//         if (data.status === "success") {
//           this.setState({ details: data });
//         }
//       });
//   };
//   apisfetchHandler = () => {
//     var formData = new FormData();
//     formData.append("proposalId", this.state.proposalId);
//     fetch(configData.api_url + "leads/getleaddetails/", {
//       method: "POST",
//       mode: "cors",
//       body: formData, // body data type must match "Content-Type" header
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         this.showToast(data.message, data.success);
//         if (data.status === "success") {        
//            this.setState({ kyc: data.lead });
//             this.setState({ kycData: data.lead });
//           this.setState({ members: data.lead.members });
//           this.setState({ address: data.lead.address });
//           this.setState({ medicals: data.lead.medicalqns });
//           this.setState({ details: data.lead });
//           this.setState({ nominees: data.lead.nominee });
//           this.setState({ addons: data.lead.addons });
//           this.setState({ premium: data.lead.premium });
//           this.setState({ email: data.lead.emaildetails });
//         }
//       });
//   };

//   CaresupremeaddonsfetchHandler = () => {
//     var formData = new FormData();
//     formData.append("chpiId", this.state.chpiId);
//     fetch(configData.api_url + "leads/getproductdetails", {
//       method: "POST",
//       mode: "cors",
//       body: formData,
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.status === "success") {
//           this.setState({ products: data.product });
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching product data:", error);
//       });
//   };
//   HealthnomineerelationsfetchHandler = () => {
//     var formData = new FormData();
//     formData.append("sId", this.state.sId);
//     formData.append("uId", this.state.uId);
//     formData.append("baseproductId", this.state.baseproductId);
//     fetch(configData.api_url + "C_Healthnomineerelations/listByid/", {
//       // Enter your IP address here

//       method: "POST",
//       //headers :{ 'Content-Type' : 'application/json'},
//       mode: "cors",
//       body: formData, // body data type must match "Content-Type" header
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.status === "success") {
//           this.setState({ nrelations: data.codes });
//         }
//       });
//   };
//   addOnsfetchHandler = () => {
//     var formData = new FormData();
//     formData.append("baseproductId", this.state.baseproductId);
//     fetch(configData.api_url + "C_Chihealthplansintegrated/addonlistByid", {
//       method: "POST",
//       mode: "cors",
//       body: formData,
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.status === "success") {
//           this.setState({ addoncodes: data.acodes });
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching product data:", error);
//       });
//   };
//   handleCheckboxChange = (checkboxName) => {
//     this.setState((prevState) => ({
//       [checkboxName]: !prevState[checkboxName],
//     }));
//   };
//   handleButtonClick = () => {
//     const { consentCheckbox, declarationCheckbox } = this.state;
//     if (consentCheckbox && declarationCheckbox) {
//       const proposalNum = sessionStorage.getItem("proposalNum");
//       window.location.href =
//         configData.api_url +
//         "payments?origin=" +
//         window.location.origin +
//         "&proposalNum=" +
//         proposalNum +
//         "&proposalId=" +
//         this.state.proposalId;
//       // window.location.href = "/payments/";
//     } else {
//       toast.error("Please Check Terms And Conditions.");
//     }
//   };
//   handleSubmitEditClick = (e) => {
//     window.location.href = "/proposerinfo";
//   };
//   // handleClick() {
//   //   // Make API callhttps://dev-api.apnapolicy.co.in/shareproposal/add
//   //   fetch(configData.api_url + "shareproposal/add")
//   //     .then(response => response.json())
//   //     .then(data => {
//   //       const keyToCopy = "status";
//   //       if (data.hasOwnProperty(keyToCopy)) {
//   //         const valueToCopy = data[keyToCopy];
//   //         navigator.clipboard.writeText(valueToCopy)
//   //           .then(() => {
//   //             console.log('Share link copied to clipboard:', valueToCopy);
//   //             alert('Share link copied to clipboard!');
//   //           })
//   //           .catch(error => {
//   //             console.error('Failed to copy value to clipboard:', error);
//   //             alert('Failed to copy value to clipboard!');
//   //           });
//   //       } else {
//   //         console.error(`Failed to copy link to clipboard.`);
//   //         alert(`Failed to copy link to clipboard.`);
//   //       }
//   //     })
//   //     .catch(error => {
//   //       console.error('Error fetching data:', error);
//   //       alert('Error fetching data!');
//   //     });
//   // }
//   handleClick = () => {
//     // Define the data to send in the request
//     var formData = new FormData();
//     formData.append("chpiId", this.state.chpiId);
//     formData.append("cqrId", this.state.cqrId);
//     formData.append("baseproductId", this.state.baseproductId);
//     formData.append("uId", this.state.uId);
//     formData.append("proposalId", this.state.proposalId);
//     formData.append("proposalNum", this.state.proposalNum);
//     fetch(configData.api_url + "shareproposal/add", {
//       method: "POST",
//       mode: "cors",
//       body: formData,
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         if (data.status === "success") {
//           const keyToCopy = "shareUrl";
//           if (data.hasOwnProperty(keyToCopy)) {
//             const valueToCopy = data[keyToCopy];
//             navigator.clipboard
//               .writeText(valueToCopy)
//               .then(() => {
//                 console.log("Share link copied to clipboard:", valueToCopy);
//                 alert("Share link copied to clipboard!");
//               })
//               .catch((error) => {
//                 console.error("Failed to copy value to clipboard:", error);
//                 alert("Failed to copy value to clipboard!");
//               });
//           } else {
//             console.error(`Failed to copy link to clipboard.`);
//             alert(`Failed to copy link to clipboard.`);
//           }
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching data:", error);
//         alert("Error fetching data!");
//       });
//   };

//   formatDate(dateString) {
//     const date = new Date(dateString);
//     const day = date.getDate().toString().padStart(2, "0");
//     const month = (date.getMonth() + 1).toString().padStart(2, "0");
//     const year = date.getFullYear();
//     return `${day}/${month}/${year}`;
//   }
//   render() {
//     return (
//       <div>
//         <div>
//           <h1>Policy Summary</h1>
//           <Container>
//             <Row>
//               <Col>
//                 <Card>
//                   <Card.Body>
//                     <Row>
//                       {this.state.members.map((quote, index) => (
//                         <p key={index}>
//                           <Row>
//                             <Col>
//                               <span className="text-center">
//                                 Plan for {quote.fname} &nbsp;
//                                 {quote.lname}
//                               </span>
//                             </Col>
//                           </Row>
//                           <Col lg="3" md="3">
//                             <Image
//                               style={{
//                                 width: "100%",
//                                 marginLeft: "5px",
//                                 marginTop: "10px",
//                               }}
//                               src={
//                                 configData.static_url +
//                                 this.state.products.insurerlogo
//                               }
//                               alt="image"
//                             ></Image>
//                           </Col>
//                           <Col lg="6" md="6">
//                             <p>
//                               <b style={{ color: "red" }}>
//                                 {this.state.products.productname}
//                               </b>
//                             </p>

//                             <p>
//                               <b>
//                                 Cover Rs -{this.state.products.suminsured}/-
//                               </b>
//                             </p>
//                           </Col>
//                           <Col lg="3" md="3">
//                             <p>
//                               <b>Premium - {this.state.details.tenure}</b>
//                             </p>

//                             <p>
//                               <b>Rs - {this.state.premium.premium}/-</b>
//                             </p>
//                           </Col>
//                         </p>
//                       ))}
//                     </Row>
//                     <hr />
//                     <br />
//                     <h4>Proposer Details</h4>
//                     <Row>
//                       <Col>
//                         <p>
//                           We have shared these details on your email &nbsp;
//                           <b>{this.state.email.emailId}</b>
                         
//                         </p>  
//                       </Col>
//                     </Row>
//                     <Table className="table table-bordered">
//                       <thead>
//                         <tr>
//                           <th scope="col" style={{ width: "50px" }}>
//                             S No.
//                           </th>
//                           <th scope="col" style={{ width: "100px" }}>
//                             First Name
//                           </th>
//                           <th scope="col" style={{ width: "100px" }}>
//                             Last Name
//                           </th>
//                           <th scope="col" style={{ width: "100px" }}>
//                             Date Of Birth
//                           </th>
//                          <th scope="col" style={{ width: "180px" }}>
//                            Mobile Number
//                           </th>
//                        <th scope="col" style={{ width: "100px" }}>
//                            Pincode
//                           </th>
                         
//                         </tr>
//                       </thead>
//                       <tbody>
//                         <tr>
//                           <td>
//                            1.
//                           </td>
//                           <td>
//                              {this.state.kyc.fname}
//                           </td>
//                           <td> {this.state.kyc.lname}</td>
//                           <td> {this.state.kycData.dob}</td>
//                            <td> {this.state.kycData.mobile}</td>
//                           <td> {this.state.kycData.zipcode}</td>
//                         </tr>
                       
//        </tbody>
//                     </Table>
//                     <hr />
//                     <Row className="mt-3">
//                       <Col>
//                         <h4>Address</h4>
//                         <Table className="table table-bordered">
//                           <thead>
//                               <tr className="text-center">
//                           <th scope="col" style={{ width: "50px" }}>
//                             S No.
//                           </th>
//                           <th scope="col" style={{ width: "100px" }}>
//                             Area
//                           </th>
//                           <th scope="col" style={{ width: "100px" }}>
//                             City
//                           </th>
//                           <th scope="col" style={{ width: "100px" }}>
//                           State
//                           </th>
//                           <th scope="col" style={{ width: "100px" }}>
//                         Pincode
//                           </th>

                         
//                         </tr>
//                           </thead>
//                           <tbody>
//                              {this.state.address.map((quote, index) => (
//                           <tr key={index} className="text-center">
//                              <td>{index + 1}</td>
//                             <td>{quote.areaCd}</td>
//                             <td>{quote.cityCd}</td>
//                             <td>{quote.stateCd}</td>
//                             <td>{quote.pincode}</td>
//                           </tr>
//                         ))}
//                           </tbody>
//                         </Table>
                       
//                       </Col>
//                     </Row>
//                     <br />
//                     <hr />
//                     <h4>Insured Details</h4>
//                     <br />
//                     <Row>
//                       <Col>
//                         <Table className="table table-bordered">
//                           <thead>
//                             <tr>
//                               <th scope="col" style={{ width: "50px" }}>
//                                 S No.
//                               </th>
//                               <th scope="col" style={{ width: "100px" }}>
//                                 First Name
//                               </th>
//                               <th scope="col" style={{ width: "100px" }}>
//                                 Last Name
//                               </th>
//                               <th scope="col" style={{ width: "100px" }}>
//                                 Date Of Birth
//                               </th>
//                               <th scope="col" style={{ width: "50px" }}>
//                                 Age
//                               </th>
//                               <th scope="col" style={{ width: "70px" }}>
//                                 Gender (F/M)
//                               </th>
//                               <th scope="col" style={{ width: "180px" }}>
//                                 Relation with Proposer
//                               </th>
//                               <th scope="col" style={{ width: "100px" }}>
//                                 Height
//                               </th>
//                               <th scope="col" style={{ width: "100px" }}>
//                                 Weight
//                               </th>
//                             </tr>
//                           </thead>
//                           <tbody>
//                             {this.state.members.map((quote, index) => (
//                               <tr key={index}>
//                                 <td>{index + 1}</td>
//                                 <td>{quote.fname}</td>
//                                 <td>{quote.lname}</td>
//                                 <td>{this.formatDate(quote.dob)}</td>
//                                 <td>{quote.age}</td>
//                                 <td>{quote.gender}</td>
//                                 <td>{this.state.relations[quote.relation]}</td>
//                                 <td>{quote.height}</td>
//                                 <td>{quote.weight}</td>
//                               </tr>
//                             ))}
//                           </tbody>
//                         </Table>
//                       </Col>
//                     </Row>
//                     <br />
//                     <hr />
//                     <br />
//                     <h3>Medicals</h3>
//                     <Row>
//                       <Table>
//                         <tbody>
//                           {this.state.members.map((member, memberIndex) => (
//                             <tr key={memberIndex}>
//                               <td>{member.fname}</td>
//                               <td>
//                                 <table>
//                                   <thead>
//                                     <tr>
//                                       <tr>
//                                         <th>P.E.D questions</th>
//                                         {/* Add other relevant headers */}
//                                       </tr>
//                                     </tr>
//                                   </thead>
//                                   <tbody>
//                                     {this.state.medicals
//                                       .filter(
//                                         (medical) =>
//                                           medical.memberId === member.memberId
//                                       )
//                                       .filter((medical) => medical.ans !== "")
//                                       .filter((medical) => medical.ans !== "No")
//                                       .filter(
//                                         (medical) =>
//                                           medical.lhmId === member.lhmId
//                                       )
//                                       .map((medical, index) => (
//                                         <tr key={index}>
//                                           <td>{medical.questionSetCode}</td>
//                                           <td>{medical.ans}</td>
//                                         </tr>
//                                       ))}
//                                   </tbody>
//                                 </table>
//                               </td>
//                             </tr>
//                           ))}
//                         </tbody>
//                       </Table>
//                     </Row>
//                     <br />
//                     <h3>Nominee</h3>
//                     <br />
//                     <Row>
//                       <Col>
//                         <span>
//                           <h3>
//                             {this.state.nominees.name}(
//                             {
//                               this.state.nrelations[
//                                 this.state.nominees.relation
//                               ]
//                             }
//                             )
//                           </h3>
//                         </span>
//                       </Col>
//                     </Row>
//                     <br />
//                     <hr />
//                     <h4>Addons List</h4>
//                     <Row>
//                       <Col>
//                         {this.state.addons.map(
//                           (quote, index) =>
//                             quote.isChecked === "yes" && (
//                               <ul key={index}>
//                                 <li style={{ listStyle: "none" }}>
//                                   {/* {quote.aoId}&nbsp;&nbsp;&nbsp; */}
//                                 </li>
//                                 <li style={{ listStyle: "none" }}>
//                                   {this.state.addoncodes[quote.aoCode]}
//                                 </li>
//                               </ul>
//                             )
//                         )}
//                       </Col>
//                     </Row>
//                     <hr />
//                     <br />
//                     <Row>
//                       <Col md="6" lg="6">
//                         <Form.Group className="form-group">
//                           <Form.Check
//                             type="checkbox"
//                             name="consentCheckbox"
//                             id="consentCheckbox"
//                             checked={this.state.consentCheckbox}
//                             onChange={() =>
//                               this.handleCheckboxChange("consentCheckbox")
//                             }
//                             aria-describedby="consentCheckbox"
//                             placeholder="Address Line2"
//                           />
//                           <Form.Label className="control-label ms-3">
//                             consent to receive information from Central KYC
//                             Registry through SMS/email on the registered
//                             number/email address. I/we agree that the PAN
//                             details and other information provided by me/us in
//                             the application form may be used by Insurer(s) to
//                             download/verify my/our KYC documents from the
//                             CERSAI* CKYC portal for processing this application.
//                             I/We understand that only the acceptable Officially
//                             Valid documents would be relied upon for processing
//                             this application. *Central Registry of
//                             Securitisation and Asset Reconstruction and Security
//                             Interest of India. Read less
//                           </Form.Label>
//                         </Form.Group>
//                       </Col>
//                       <Col md="6" lg="6">
//                         <Form.Group className="form-group">
//                           <Form.Check
//                             type="checkbox"
//                             name="declarationCheckbox"
//                             id="declarationCheckbox"
//                             checked={this.state.declarationCheckbox}
//                             onChange={() =>
//                               this.handleCheckboxChange("declarationCheckbox")
//                             }
//                             aria-describedby="declarationCheckbox"
//                           />
//                           <Form.Label className="control-label">
//                             I hereby declare that all information provided above
//                             is true, and I accept allTerms & conditions I hereby
//                           </Form.Label>
//                         </Form.Group>
//                       </Col>
//                     </Row>
//                     <br />
//                     <Row className="mt-5">
//                       <Col>
//                         <Button
//                           style={{ float: "left" }}
//                           type="button"
//                           onClick={this.handleSubmitEditClick}
//                           //onClick={this.handleButtonClick}
//                         >
//                           Edit
//                         </Button>
//                       </Col>
//                       <Col>
//                         <Button
//                           style={{ float: "right" }}
//                           type="button"
//                           onClick={this.handleButtonClick}
//                         >
//                           Pay {this.state.premium.premium}/-
//                         </Button>
//                       </Col>
//                     </Row>
//                     <Row>
//                       <Col>
//                         <Button
//                           variant="success"
//                           className="btn btn-success"
//                           onClick={this.handleClick}
//                         >
//                           Share Link
//                         </Button>
//                       </Col>
//                     </Row>
//                   </Card.Body>
//                 </Card>
//               </Col>
//             </Row>
//           </Container>
//         </div>
//       </div>
//     );
//   }
// }
import React, { Component } from "react";
import {
  Form,
  Card,
  Col,
  Container,
  Row,
  Table,
  Image,
  Button,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import configData from "../config.json";
export default class Proposalsummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      proposalId: sessionStorage.getItem("proposalId"),
      proposalNum: sessionStorage.getItem("proposalNum"),
      baseproductId: sessionStorage.getItem("baseproductId"),
      chpiId: sessionStorage.getItem("chpiId"),
      cqrId: sessionStorage.getItem("cqrId"),
      uId: sessionStorage.getItem("uId"),
      forgotPasswordCondition: false,
      policiescheckbox: "",
      consentCheckbox: false,
      declarationCheckbox: false,
      membersstatus: [],
      products: [],
      members: [],
      address: [],
      details: [],
      medicals: [],
      nominees: [],
      addons: [],
      premium: [],
      email: [],
      kyc:[],
      kycData:[],
      addoncodes: {},
      relations: {},
      nrelations: {},
      requestData: '',
    };

    // console.log(this.props.params.itId);
  }
  handleInputChange = (tab, field, value) => {
    this.setState((prevState) => ({
      [tab]: {
        ...prevState[tab],
        [field]: value,
      },
    }));
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  componentDidMount() {
    this.apisfetchHandler();
    this.CaresupremeaddonsfetchHandler();
    this.addOnsfetchHandler();
    this.relationfetchHandler();
    this.HealthnomineerelationsfetchHandler();


    // this.apisfetchPolicyHandler();
  }
  relationfetchHandler = () => {
    var formData = new FormData();
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "C_Healthrelationcodes/listByid/", {
      method: "POST",
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          this.setState({ relations: data.codes });
        }
      });
  };
  apisfetchPolicyHandler = () => {
    var formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    formData.append("chpiId", this.state.chpiId);
    fetch(configData.api_url + "leads/createpolicy/", {
      method: "POST",
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          this.setState({ details: data });
        }
      });
  };
  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    fetch(configData.api_url + "leads/getleaddetails/", {
      method: "POST",
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          this.setState({ kyc: data.lead });
          this.setState({ kycData: data.lead.kyc });
          this.setState({ members: data.lead.members });
          this.setState({ address: data.lead.address });
          this.setState({ medicals: data.lead.medicalqns });
          this.setState({ details: data.lead });
          this.setState({ nominees: data.lead.nominee });
          this.setState({ addons: data.lead.addons });
          this.setState({ premium: data.lead.premium });
          this.setState({ email: data.lead.emaildetails });
        }
      });
  };

  CaresupremeaddonsfetchHandler = () => {
    var formData = new FormData();
    formData.append("chpiId", this.state.chpiId);
    fetch(configData.api_url + "leads/getproductdetails", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ products: data.product });
        }
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  };
  HealthnomineerelationsfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "C_Healthnomineerelations/listByid/", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ nrelations: data.codes });
        }
      });
  };
  addOnsfetchHandler = () => {
    var formData = new FormData();
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "C_Chihealthplansintegrated/addonlistByid", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ addoncodes: data.acodes });
        }
      })
      .catch((error) => {
        console.error("Error fetching product data:", error);
      });
  };
  handleCheckboxChange = (checkboxName) => {
    this.setState((prevState) => ({
      [checkboxName]: !prevState[checkboxName],
    }));
  };
  handleButtonClick = () => {
    const { consentCheckbox, declarationCheckbox } = this.state;
    if (consentCheckbox && declarationCheckbox) {
      const proposalNum = sessionStorage.getItem("proposalNum");
      window.location.href =
        configData.api_url +
        "payments?origin=" +
        window.location.origin +
        "&proposalNum=" +
        proposalNum +
        "&proposalId=" +
        this.state.proposalId;
      // window.location.href = "/payments/";
    } else {
      toast.error("Please Check Terms And Conditions.");
    }
  };
  handleSubmitEditClick = (e) => {
    window.location.href = "/proposerinfo";
  };
  
  handleClick = () => {
    // Define the data to send in the request
    var formData = new FormData();
    formData.append("chpiId", this.state.chpiId);
    formData.append("cqrId", this.state.cqrId);
    formData.append("baseproductId", this.state.baseproductId);
    formData.append("uId", this.state.uId);
    formData.append("proposalId", this.state.proposalId);
    formData.append("proposalNum", this.state.proposalNum);
     formData.append("icName","");
    fetch(configData.api_url + "shareproposal/add", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          const keyToCopy = "shareUrl";
          if (data.hasOwnProperty(keyToCopy)) {
            const valueToCopy = data[keyToCopy];
            navigator.clipboard.writeText(valueToCopy)
              .then(() => {
                console.log('Share link copied to clipboard:', valueToCopy);
                alert('Share link copied to clipboard!');
              })
              .catch(error => {
                console.error('Failed to copy value to clipboard:', error);
                alert('Failed to copy value to clipboard!');
              });
          } else {
            console.error(`Failed to copy link to clipboard.`);
            alert(`Failed to copy link to clipboard.`);
          }
        }
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        alert('Error fetching data!');
      });
  };


  render() {
    return (
      <div>
        <div>
          <h1>Policy Summary</h1>
          <Container>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Row>
                      {this.state.members.map((quote, index) => (
                        <tr key={index}>
                          <td className="text-center">
                            Plan for {quote.fname} &nbsp;
                            {quote.lname}
                          </td>
                        </tr>
                      ))}
                      <Col lg="3" md="3">
                        <Image
                          style={{
                            width: "100%",
                            marginLeft: "5px",
                            marginTop: "10px",
                          }}
                          src={
                            configData.static_url +
                            this.state.products.insurerlogo
                          }
                          alt="image"
                        ></Image>
                      </Col>
                      <Col lg="6" md="6">
                        <p>
                          <b style={{ color: "red" }}>
                            {this.state.products.productname}
                          </b>
                        </p>

                        <p>
                          <b>Cover Rs -{this.state.products.suminsured}/-</b>
                        </p>
                      </Col>
                      <Col lg="3" md="3">
                        <p>
                          <b>Premium - {this.state.details.tenure}</b>
                        </p>

                        <p>
                          <b>Rs - {this.state.premium.premium}/-</b>
                        </p>
                      </Col>
                    </Row>
                    <hr />
                    <br />
                    <h4>Proposer Details</h4>
                    <Row>
                      <Col>
                        <p>
                          We have shared these details on your email &nbsp;
                          <b>{this.state.email.emailId}</b>
                        </p>
                      </Col>
                    </Row>
                   <Table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col" style={{ width: "50px" }}>
                            S No.
                          </th>
                          <th scope="col" style={{ width: "100px" }}>
                            First Name
                          </th>
                          <th scope="col" style={{ width: "100px" }}>
                            Last Name
                          </th>
                          <th scope="col" style={{ width: "100px" }}>
                            Date Of Birth
                          </th>
                         <th scope="col" style={{ width: "180px" }}>
                           Mobile Number
                          </th>
                       <th scope="col" style={{ width: "100px" }}>
                           Pincode
                          </th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                           1.
                          </td>
                          <td>
                             {this.state.kyc.fname}
                          </td>
                          <td> {this.state.kyc.lname}</td>
                          <td> {this.state.kycData.dob}</td>
                           <td> {this.state.kyc.mobile}</td>
                          <td> {this.state.kyc.zipcode}</td>
                        </tr>
                       
       </tbody>
                    </Table>
                
                 
                    <hr />
                    <Row className="mt-3">
                      <Col>
                        <h4>Address</h4>
                        <Table className="table table-bordered">
                          <thead>
                              <tr className="text-center">
                          <th scope="col" style={{ width: "50px" }}>
                            S No.
                          </th>
                          <th scope="col" style={{ width: "100px" }}>
                            Area
                          </th>
                          <th scope="col" style={{ width: "100px" }}>
                            City
                          </th>
                          <th scope="col" style={{ width: "100px" }}>
                          State
                          </th>
                          <th scope="col" style={{ width: "100px" }}>
                        Pincode
                          </th>

                         
                        </tr>
                          </thead>
                          <tbody>
                             {this.state.address.map((quote, index) => (
                          <tr key={index} className="text-center">
                             <td>{index + 1}</td>
                            <td>{quote.areaCd}</td>
                            <td>{quote.cityCd}</td>
                            <td>{quote.stateCd}</td>
                            <td>{quote.pincode}</td>
                          </tr>
                        ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                    <br />
                    <hr />
                    <h4>Insured Details</h4>
                    <br />
                    <Row>
                      <Col>
                        <Table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col" style={{ width: "50px" }}>
                                S No.
                              </th>
                              <th scope="col" style={{ width: "100px" }}>
                                First Name
                              </th>
                              <th scope="col" style={{ width: "100px" }}>
                                Last Name
                              </th>
                              <th scope="col" style={{ width: "100px" }}>
                                Date Of Birth
                              </th>
                              <th scope="col" style={{ width: "50px" }}>
                                Age
                              </th>
                              <th scope="col" style={{ width: "70px" }}>
                                Gender (F/M)
                              </th>
                              <th scope="col" style={{ width: "180px" }}>
                                Relation with Proposer
                              </th>
                              <th scope="col" style={{ width: "100px" }}>
                                Height
                              </th>
                              <th scope="col" style={{ width: "100px" }}>
                                Weight
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.members.map((quote, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{quote.fname}</td>
                                <td>{quote.lname}</td>
                                <td>{quote.dob}</td>
                                <td>{quote.age}</td>
                                <td>{quote.gender}</td>
                                <td>{this.state.relations[quote.relation]}</td>
                                <td>{quote.height}</td>
                                <td>{quote.weight}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                    <br />
                    <hr />
        
                    <h3>Medicals</h3>
                    <Row>
                      <Table>
                        <tbody>
                          {this.state.members.map((member, memberIndex) => (
                            <tr key={memberIndex}>
                              <td>{member.fname}</td>
                              <td>
                                <table>
                                  <thead>
                                    <tr>
                                      <tr>
                                        <th>P.E.D questions</th>
                                        {/* Add other relevant headers */}
                                      </tr>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.medicals
                                      .filter(
                                        (medical) =>
                                          medical.memberId === member.memberId
                                      )
                                      .filter((medical) => medical.ans !== "")
                                      .filter((medical) => medical.ans !== "No")
                                      .filter(
                                        (medical) =>
                                          medical.lhmId === member.lhmId
                                      )
                                      .map((medical, index) => (
                                        <tr key={index}>
                                          <td>{medical.questionSetCode}</td>
                                          <td>{medical.ans}</td>
                                        </tr>
                                      ))}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Row>
                    <br />
                    <h3>Nominee</h3>
                    <br />
                    <Row>
                      <Col>
                        <span>
                          <h3>
                            {this.state.nominees.name}(
                            {
                              this.state.nrelations[
                              this.state.nominees.relation
                              ]
                            }
                            )
                          </h3>
                        </span>
                      </Col>
                    </Row>
                    <br />
                    <hr />
                    <h4>Addons List</h4>
                    <Row>
                      <Col>
                        {this.state.addons.map(
                          (quote, index) =>
                            quote.isChecked === "yes" && (
                              <ul key={index}>
                                <li style={{ listStyle: "none" }}>
                                  {/* {quote.aoId}&nbsp;&nbsp;&nbsp; */}
                                </li>
                                <li style={{ listStyle: "none" }}>
                                  {this.state.addoncodes[quote.aoCode]}
                                </li>
                              </ul>
                            )
                        )}
                      </Col>
                    </Row>
                    <hr />
                    <br />
                    <Row>
                      <Col md="6" lg="6">
                        <Form.Group className="form-group">
                          <Form.Check
                            type="checkbox"
                            name="consentCheckbox"
                            id="consentCheckbox"
                            checked={this.state.consentCheckbox}
                            onChange={() =>
                              this.handleCheckboxChange("consentCheckbox")
                            }
                            aria-describedby="consentCheckbox"
                            placeholder="Address Line2"
                          />
                          <Form.Label className="control-label ms-3">
                            consent to receive information from Central KYC
                            Registry through SMS/email on the registered
                            number/email address. I/we agree that the PAN
                            details and other information provided by me/us in
                            the application form may be used by Insurer(s) to
                            download/verify my/our KYC documents from the
                            CERSAI* CKYC portal for processing this application.
                            I/We understand that only the acceptable Officially
                            Valid documents would be relied upon for processing
                            this application. *Central Registry of
                            Securitisation and Asset Reconstruction and Security
                            Interest of India. Read less
                          </Form.Label>
                        </Form.Group>
                      </Col>
                      <Col md="6" lg="6">
                        <Form.Group className="form-group">
                          <Form.Check
                            type="checkbox"
                            name="declarationCheckbox"
                            id="declarationCheckbox"
                            checked={this.state.declarationCheckbox}
                            onChange={() =>
                              this.handleCheckboxChange("declarationCheckbox")
                            }
                            aria-describedby="declarationCheckbox"
                          />
                          <Form.Label className="control-label">
                            I hereby declare that all information provided above
                            is true, and I accept allTerms & conditions I hereby
                          </Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                    <br />
                    <Row className="mt-5">
                      <Col>
                        <Button
                          style={{ float: "left" }}
                          type="button"
                          onClick={this.handleSubmitEditClick}
                        //onClick={this.handleButtonClick}
                        >
                          Edit
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          style={{ float: "right" }}
                          type="button"
                          onClick={this.handleButtonClick}
                        >
                          Pay {this.state.premium.premium}/-
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button variant="success" className="btn btn-success" onClick={this.handleClick}>Share Link</Button></Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

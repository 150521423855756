import React, { Component } from "react";
import {
  Button,
  Container,
  Row,
  Form,
  Col,
  Offcanvas,
  Modal,
} from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import CryptoJS from "crypto-js";
import configData from "../config.json";
import "./Users.css";

export default class Users extends Component {
  constructor(props) {
    super(props);
    this.inputFileRef = React.createRef();
    this.state = {
      show: false,
      showOne: false,
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      data: [],
      errors: {},
      uName: "",
      emailId: "",
      password: "",
      users_Id: "",
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.users_Id,
          sortable: true,
          cell: (row) => <span>{row.users_Id}</span>,
        },
        {
          name: "User Name",
          selector: (row, i) => row.uName,
          sortable: true,
          cell: (row) => <span>{row.uName}</span>,
        },
        {
          name: "Email",
          selector: (row, i) => row.emailId,
          sortable: true,
          cell: (row) => <span>{row.emailId}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen"
              ></Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      isEditing: false, // Flag to indicate edit mode
      isNewEntry: false,
    };
  }
  //edit
  handleEnterEditMode = (row) => {
    // this.setState({ cData: { ...row }, isEditing: true });
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "/C_Users/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({
            uName: row.uName,
            emailId: row.emailId,
            password: row.password,
            users_Id: row.users_Id,
            isEditing: true,
          });
        }
      });
  };

  //modal
  handleButtonClick = () => {
    this.inputFileRef.current.click();
  };
  handleCloseOne = () => {
    this.setState({ showOne: false });
  };

  handleShowOne = () => {
    this.setState({ showOne: true });
  };
  //Offcanvas
  handleClick = () => {
    this.setState({ showButton: true });
  };
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type, key) => {
    const toastType =
      {
        success: toast.TYPE.SUCCESS,
        error: toast.TYPE.ERROR,
        warning: toast.TYPE.WARNING,
        info: toast.TYPE.INFO,
      }[type] || toast.TYPE.INFO; // Default to info if the type is not recognized

    toast(msg, { type: toastType, toastId: key });
  };

  UserseditHandler = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    // const updateFormData = {...editFormData};
    // updateFormData['Users_Id'] = e.target.parentElement.getAttribute("id");
    // setEditFormData(updateFormData);
    // setEdit(true);
  };
  validateForm() {
    let user = this.state;
    let errors = {};
    let formIsValid = true;

    if (!user["uName"]) {
      formIsValid = false;
      toast.info("*Please Enter User Name.");
    } else if (!user["emailId"]) {
      formIsValid = false;
      toast.info("*Please Enter Email Id.");
    } else if (!user["password"]) {
      formIsValid = false;
      toast.info("*Please Enter password.");
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  UsersfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "/C_Users/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  UserssubmitHandler = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("uName", this.state.uName);
      formData.append("emailId", this.state.emailId);
      formData.append(
        "password",
        CryptoJS.HmacMD5(this.state.password, configData.secret).toString()
      );
      fetch(configData.api_url + "/C_Users/add", {
        method: "POST",
        mode: "cors",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            this.setState({
              uName: "",
              emailId: "",
              password: "",
            });
            this.UsersfetchHandler();
            this.handleOffcanvasClose();
          }
        });
    }
  };

  UsersupdateHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);

    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("uName", this.state.uName);
    formData.append("emailId", this.state.emailId);
    formData.append(
      "password",
      CryptoJS.HmacMD5(this.state.password, configData.secret).toString()
    );
    formData.append("users_Id", this.state.users_Id);
    fetch(configData.api_url + "/C_Users/update", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.UsersfetchHandler();
          this.handleOffcanvasClose();
          this.setState({
            uName: "",
            emailId: "",
            password: "",
          });
        }
      });
  };

  componentDidMount() {
    this.UsersfetchHandler();
  }
  handleuNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ uName: fieldValue });
  };
  handleemailIdChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ emailId: fieldValue });
  };
  handlepasswordChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ password: fieldValue });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    //console.log(`Editing row with ID ${row.id}`);
    // implement your edit logic here
  };
  handleDelete = (row, index) => {
    const newData = this.state.data;
    newData.splice(index, 1);
    this.setState({ data: newData });
    //console.log(`Delete row with ID ${row.id}`);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    const users_Id = row.users_Id;
    // //console.log(e.target.parentElement);
    formData.append("users_Id", users_Id);
    fetch(configData.api_url + "/C_Users/delete", {
      // Enter your IP address here
      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.UsersfetchHandler();
        }
      });
    //implement your delete logic here
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
        (this.state.currentPage - 1) * this.state.itemsPerPage,
        this.state.currentPage * this.state.itemsPerPage
      )
      : this.state.data.slice(
        (this.state.currentPage - 1) * this.state.itemsPerPage,
        this.state.currentPage * this.state.itemsPerPage
      );

    return (
      <div>
        <h2 style={{ float: "left" }}>USERS PAGE</h2>
        <div className="x_panel">
          <Col
            className="d-flex justify-content-end"
            style={{ marginRight: "5px", marginTop: "20px" }}
          >
            <Button
              variant="warning"
              className="me-2 bi bi-upload"
              onClick={this.handleShowOne}
            >
              &nbsp;Upload *csv
            </Button>
            <Button variant="info" className="me-2 bi bi-download">
              &nbsp;Download *csv
            </Button>
            <Button
              variant="primary"
              className="bi bi-plus "
              onClick={this.handleOffcanvasShow}
            >
              New
            </Button>
            <Offcanvas
              show={this.state.show}
              onHide={this.handleOffcanvasClose}
              {...this.props}
              style={{ width: "700px" }}
              placement="end"
              backdrop="true"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>New Users page</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Container style={{ overflowY: "auto", height: "auto" }}>
                  <Row className="divstyle">
                    <Row>
                      <Form>
                        <Row>
                          <Col lg="4" md="4">
                            <Form.Label>User Name</Form.Label>
                          </Col>
                          <Col lg="8" md="8">
                            <Form.Control
                              type="text"
                              onChange={this.handleuNameChange}
                              className="form-control"
                              name="uName"
                              value={this.state.uName}
                              required="required"
                              placeholder="Enter User Name"
                            />
                          </Col>
                          <div
                            className="errorMsg text-center"
                            style={{ color: "red" }}
                          >
                            {" "}
                            {this.state.errors.uName}{" "}
                          </div>
                        </Row>
                        <br />
                        <Row>
                          <Col lg="4" md="4">
                            <Form.Label>Email</Form.Label>
                          </Col>
                          <Col lg="8" md="8">
                            <Form.Control
                              type="text"
                              onChange={this.handleemailIdChange}
                              className="form-control"
                              name="emailId"
                              value={this.state.emailId}
                              required="required"
                              placeholder="Enter Email Id"
                            />
                          </Col>
                          <div
                            className="errorMsg text-center"
                            style={{ color: "red" }}
                          >
                            {" "}
                            {this.state.errors.emailId}{" "}
                          </div>
                        </Row>
                        <br />
                        <Row>
                          <Col lg="4" md="4">
                            <Form.Label>Password</Form.Label>
                          </Col>
                          <Col lg="8" md="8">
                            <Form.Control
                              type="password"
                              onChange={this.handlepasswordChange}
                              className="form-control"
                              name="password"
                              value={this.state.password}
                              required="required"
                              placeholder="Enter Password"
                            />
                          </Col>
                          <div
                            className="errorMsg text-center"
                            style={{ color: "red" }}
                          >
                            {" "}
                            {this.state.errors.password}{" "}
                          </div>
                        </Row>
                        <br />
                      </Form>
                    </Row>
                  </Row>
                  <Row>
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "50px" }}
                    >
                      <Button
                        className="button"
                        variant="success"
                        onClick={this.UserssubmitHandler}
                        style={{
                          borderRadius: "0px",
                          marginTop: "20px",
                        }}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Offcanvas.Body>
            </Offcanvas>
            {/*offcanvas is over */}
          </Col>
          <br />
          <div>
            <Modal show={this.state.showOne} onHide={this.handleCloseOne}>
              <Modal.Header closeButton>
                <Modal.Title>Upload *csv file</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Button
                  variant="success"
                  className="bi bi-upload"
                  onClick={this.handleButtonClick}
                >
                  {" "}
                  Upload
                </Button>
                <input
                  type="file"
                  ref={this.inputFileRef}
                  style={{ display: "none" }}
                  accept=".csv"
                  onChange={this.handleFileChange}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleCloseOne}>
                  Close
                </Button>
                <Button variant="primary" onClick={this.handleCloseOne}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <br />
          <div>
            {" "}
            {this.state.isEditing && (
              // Edit Offcanvas
              <Offcanvas
                show={this.state.isEditing}
                onHide={() => this.setState({ isEditing: false })}
                {...this.props}
                style={{ width: "700px", height: "auto" }}
                placement="end"
                backdrop="true"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Edit Users</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <div className="form-horizontal form-label-left input_mask">
                    <Form className="form-horizontal form-label-left input_mask">
                      <Row>
                        <Col lg="4" md="4">
                          <Form.Label>User Name</Form.Label>
                        </Col>
                        <Col lg="8" md="8">
                          <Form.Control
                            type="text"
                            onChange={this.handleuNameChange}
                            className="form-control"
                            name="uName"
                            value={this.state.uName}
                            required="required"
                            placeholder="Enter User Name"
                          />
                        </Col>
                        <div
                          className="errorMsg text-center"
                          style={{ color: "red" }}
                        >
                          {" "}
                          {this.state.errors.uName}{" "}
                        </div>
                      </Row>
                      <br />
                      <Row>
                        <Col lg="4" md="4">
                          <Form.Label>Email</Form.Label>
                        </Col>
                        <Col lg="8" md="8">
                          <Form.Control
                            type="text"
                            onChange={this.handleemailIdChange}
                            className="form-control"
                            name="emailId"
                            value={this.state.emailId}
                            required="required"
                            placeholder="Enter Email Id"
                          />
                        </Col>
                        <div
                          className="errorMsg text-center"
                          style={{ color: "red" }}
                        >
                          {" "}
                          {this.state.errors.emailId}{" "}
                        </div>
                      </Row>
                      <br />
                      <Row>
                        <Col lg="4" md="4">
                          <Form.Label>Password</Form.Label>
                        </Col>
                        <Col lg="8" md="8">
                          <Form.Control
                            type="password"
                            onChange={this.handlepasswordChange}
                            className="form-control"
                            name="password"
                            value={this.state.password}
                            required="required"
                            placeholder="Enter Password"
                          />
                        </Col>
                        <div
                          className="errorMsg text-center"
                          style={{ color: "red" }}
                        >
                          {" "}
                          {this.state.errors.password}{" "}
                        </div>
                      </Row>
                      <br />
                      <div style={{ float: "right" }}>
                        <button
                          className=" btn btn-warning me-2 mt-5"
                          style={{
                            backgroundColor: "#fff",
                            color: "#000",
                            borderColor: "#ccc",
                          }}
                          onClick={() => this.setState({ isEditing: false })}
                        >
                          Close
                        </button>{" "}
                        <button
                          className="btn btn-primary me-2 mt-5"
                          style={{
                            backgroundColor: "#23547e",
                            color: "#fff",
                            borderColor: "#2e6da4",
                          }}
                          onClick={this.UsersupdateHandler}
                        >
                          Save
                        </button>
                      </div>
                    </Form>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            )}
          </div>
          <br />
          <div style={{ marginRight: "5px" }}>
            {/* searchbar,pagination,filter */}
            <input
              type="text"
              style={{ float: "left", marginBottom: "10px" }}
              onChange={this.handleFilter}
              placeholder="Search..."
            />
            <DataTable
              data={paginatedData}
              columns={this.state.tableClms}
              pagination
              paginationServer
              paginationTotalRows={
                this.state.filteredData
                  ? this.state.filteredData.length
                  : this.state.data.length
              }
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
              paginationPerPage={this.state.itemsPerPage}
              keyField="id"
              className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
            />
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Row, Col, Container, Offcanvas, Button, Form } from "react-bootstrap";
import configData from "../config.json";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
export default class BulkQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      show: false,
      showOne: false,
      data: [],
      filteredData: null,
      isEditing: false,
      isNewEntry: false,
      bqId: "",
      bqName: "",
      bqDescription: "",
      tableClms: [
        {
          name: "S.NO",
          width: "75px",
          id: "bqId",
          selector: (row, i) => row.bqId,
          sortable: true,
          cell: (row) => <span>{row.bqId}</span>,
        },
        {
          name: "Company Name",
          id: "bqName",
          selector: (row, i) => row.bqName,
          sortable: true,
          cell: (row) => <span>{row.bqName}</span>,
        },
        {
          name: "Description",
          id: "bqDescription",
          selector: (row, i) => row.bqDescription,
          sortable: true,
          cell: (row) => <span>{row.bqDescription}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div className="row">
              {/* edit button and delete button */}
              <div>
                <Button
                  variant="warning"
                  onClick={() => this.handleEnterViewMode(row)}
                  className="bi bi-eye"
                  style={{ marginRight: "3px" }}
                ></Button>
                <Button
                  onClick={() => this.handleEnterEditMode(row)}
                  className="bi bi-pen"
                  style={{ marginRight: "3px", backgroundColor: "#3ca8e8" }}
                ></Button>
              </div>
            </div>
          ),
        },
      ],
    };
  }
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  componentDidMount() {
    this.BulkquotesfetchHandler();
  }
  handleEnterEditMode = (row) => {
    // this.setState({ cData: { ...row }, isEditing: true });
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    this.setState({
      bqName: row.bqName,
      bqDescription: row.bqDescription,
      bqId: row.bqId,
      isEditing: true,
    });
  };

  validateForm() {
    let data = this.state;
    let errors = {};
    let formIsValid = true;

    if (!data["bqName"]) {
      formIsValid = false;
      toast.info("*Please Enter Bulk Quotes.");
    } else if (!data["bqDescription"]) {
      formIsValid = false;
      toast.info("*Please Enter Bulk Description.");
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  BulkquotesfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "bulkquotes/listbyUser", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  BQsubmitHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    if (this.validateForm()) {
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("bqName", this.state.bqName);
      formData.append("bqDescription", this.state.bqDescription);

      fetch(configData.api_url + "bulkquotes/add", {
        // Enter your IP address here
        method: "POST",
        mode: "cors",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            this.handleOffcanvasClose();
            this.BulkquotesfetchHandler();
            this.setState({
              bqName: "",
              bqDescription: "",
            });
          }
        });
    }
  };

  BQupdateHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    if (this.validateForm()) {
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("bqId", this.state.bqId);
      formData.append("bqName", this.state.bqName);
      formData.append("bqDescription", this.state.bqDescription);

      fetch(configData.api_url + "bulkquotes/update", {
        // Enter your IP address here
        method: "POST",
        mode: "cors",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            this.handleOffcanvasClose();
            this.setState({
              bqId: "",
              bqName: "",
              bqDescription: "",
            });
          }
        });
    }
  };
  handleEnterViewMode = (row) => {
    const employees_Id = row.employees_Id;
    sessionStorage.setItem("employees_Id", employees_Id);
    sessionStorage.setItem("bqId", row.bqId);
    window.location.href = "/bqdetails";
    // Replace '/profile' with the actual URL for the profile page
  };

  handlebqNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ bqName: fieldValue });
  };
  handlebqDescriptionChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ bqDescription: fieldValue });
  };
  handleOffcanvasShow = () => {
    this.setState({
      show: true,
      bqId: "",
      bqName: "",
      bqDescription: "",
    });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false, showOne: false });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );
    return (
      <div className="page-title">
        <div className="row">
          <div className="title_left">
            <h3 className="logoskybluecolor">Bulk Quotes</h3>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                                   dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "5px", marginTop: "20px" }}
                    >
                      <Button
                        variant="primary"
                        className="bi bi-plus "
                        onClick={this.handleOffcanvasShow}
                      >
                        New
                      </Button>

                      <Offcanvas
                        show={this.state.show}
                        onHide={this.handleOffcanvasClose}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>Bulk Quote</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                            <Row className="divstyle">
                              <Row>
                                <Form>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Company Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlebqNameChange}
                                        className="form-control"
                                        name="bqName"
                                        value={this.state.bqName}
                                        required="required"
                                        placeholder="Enter Quote"
                                      />
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Description
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlebqDescriptionChange
                                        }
                                        className="form-control"
                                        name="bqDescription"
                                        value={this.state.bqDescription}
                                        required="required"
                                        placeholder="Enter Quote Description"
                                      />
                                    </Col>
                                  </Row>
                                  <br />
                                </Form>
                              </Row>
                            </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={this.BQsubmitHandler}
                                  style={{
                                    marginTop: "20px",
                                  }}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                      {/*offcanvas is over */}
                      {/* Editing Offcanvas */}
                      {this.state.isEditing && (
                        <Offcanvas
                          show={this.state.isEditing}
                          onHide={() => this.setState({ isEditing: false })}
                          {...this.props}
                          style={{ width: "700px" }}
                          placement="end"
                          backdrop="true"
                        >
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Bulk Quote</Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                            <Container
                              style={{ overflowY: "auto", height: "auto" }}
                            >
                              <Row className="divstyle">
                                <Row>
                                  <Form>
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Comnpany Name
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handlebqNameChange}
                                          className="form-control"
                                          name="bqName"
                                          value={this.state.bqName}
                                          required="required"
                                          placeholder="Enter Quote"
                                        />
                                      </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          {" "}
                                          Description
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={
                                            this.handlebqDescriptionChange
                                          }
                                          className="form-control"
                                          name="bqDescription"
                                          value={this.state.bqDescription}
                                          required="required"
                                          placeholder="Enter Quote Description"
                                        />
                                      </Col>
                                    </Row>
                                    <br />
                                  </Form>
                                </Row>
                              </Row>
                              <Row>
                                <Col
                                  className="d-flex justify-content-end"
                                  style={{ marginRight: "50px" }}
                                >
                                  <Button
                                    className="button"
                                    variant="success"
                                    onClick={this.BQupdateHandler}
                                    style={{
                                      marginTop: "20px",
                                    }}
                                  >
                                    Update
                                  </Button>
                                </Col>
                              </Row>
                            </Container>
                          </Offcanvas.Body>
                        </Offcanvas>
                      )}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <div style={{ marginRight: "5px" }}>
                      {/* searchbar,pagination,filter */}
                      <input
                        type="text"
                        style={{ float: "left", marginBottom: "10px" }}
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />

                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        paginationRowsPerPageOptions={[
                          10, 20, 30, 50, 60, 70, 80, 90, 100,
                        ]}
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

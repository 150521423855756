import React, { Component } from "react";
import {
  Button,
  Container,
  Row,Form,
  Col,
  Offcanvas,
} from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from '../config.json';

export default class Ruleexecutionhistory extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.ruleexecutionhistory_Id,
          sortable: true,
          cell: (row) => <span>{row.ruleexecutionhistory_Id}</span>,
        },
        {name:"Rule Id",selector:(row, i) => row.ruleId,sortable:true,cell: (row) => <span>{row.ruleId}</span>,},{name:"Rule Group Id",selector:(row, i) => row.rulegroupId,sortable:true,cell: (row) => <span>{row.rulegroupId}</span>,},{name:"Rule Flow Id",selector:(row, i) => row.ruleflowId,sortable:true,cell: (row) => <span>{row.ruleflowId}</span>,},{name:"Input Data JSON",selector:(row, i) => row.inputdataJSON,sortable:true,cell: (row) => <span>{row.inputdataJSON}</span>,},{name:"Condition Result",selector:(row, i) => row.conditionResult,sortable:true,cell: (row) => <span>{row.conditionResult}</span>,},{name:"Execution Start",selector:(row, i) => row.executionStart,sortable:true,cell: (row) => <span>{row.executionStart}</span>,},{name:"Execution End",selector:(row, i) => row.executionEnd,sortable:true,cell: (row) => <span>{row.executionEnd}</span>,},{name:"Status",selector:(row, i) => row.status,sortable:true,cell: (row) => <span>{row.status}</span>,},{name:"Error Message",selector:(row, i) => row.errorMessage,sortable:true,cell: (row) => <span>{row.errorMessage}</span>,},{name:"Dummy One",selector:(row, i) => row.dummyOne,sortable:true,cell: (row) => <span>{row.dummyOne}</span>,},{name:"Dummy Two",selector:(row, i) => row.dummyTwo,sortable:true,cell: (row) => <span>{row.dummyTwo}</span>,},{name:"Dummy Three",selector:(row, i) => row.dummyThree,sortable:true,cell: (row) => <span>{row.dummyThree}</span>,},{name:"Dummy Four",selector:(row, i) => row.dummyFour,sortable:true,cell: (row) => <span>{row.dummyFour}</span>,},{name:"Dummy Five",selector:(row, i) => row.dummyFive,sortable:true,cell: (row) => <span>{row.dummyFive}</span>,},
        //***tableClms
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
              style={{marginRight:"10px"}}
                variant="primary"
                onClick={() => this.RuleexecutionhistoryeditHandler(row)}
                className="bi bi-pen" 
              >
              </Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              >
              </Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      isEditing: false,
     ruleexecutionhistory_Id:"",
      ruleId:'',rulegroupId:'',ruleflowId:'',inputdataJSON:'',conditionResult:'',executionStart:'',executionEnd:'',status:'',errorMessage:'',dummyOne:'',dummyTwo:'',dummyThree:'',dummyFour:'',dummyFive:'',
      //***state
    };
  }
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({ show: true, ruleId:'',rulegroupId:'',ruleflowId:'',inputdataJSON:'',conditionResult:'',executionStart:'',executionEnd:'',status:'',errorMessage:'',dummyOne:'',dummyTwo:'',dummyThree:'',dummyFour:'',dummyFive:'', });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false,isEditing: false, ruleId:'',rulegroupId:'',ruleflowId:'',inputdataJSON:'',conditionResult:'',executionStart:'',executionEnd:'',status:'',errorMessage:'',dummyOne:'',dummyTwo:'',dummyThree:'',dummyFour:'',dummyFive:'', });
  };
  showToast = (msg, type) => {
    switch(type) {
      case "success":
        toast.success(msg);
        break;
      case "error":
        toast.error(msg);
        break;
      case "warning":
        toast.warn(msg); // 'warn' is used instead of 'warning'
        break;
      default:
        toast.info(msg);
     }   
  };
  RuleexecutionhistoryeditHandler = (row)=>{
      // e.preventDefault();
      this.setState({ isEditing: true,ruleexecutionhistory_Id:row.ruleexecutionhistory_Id, ruleId:row.ruleId,rulegroupId:row.rulegroupId,ruleflowId:row.ruleflowId,inputdataJSON:row.inputdataJSON,conditionResult:row.conditionResult,executionStart:row.executionStart,executionEnd:row.executionEnd,status:row.status,errorMessage:row.errorMessage,dummyOne:row.dummyOne,dummyTwo:row.dummyTwo,dummyThree:row.dummyThree,dummyFour:row.dummyFour,dummyFive:row.dummyFive, //***editstate
      });
  }
       
RuleexecutionhistoryfetchHandler = () => {
  var formData = new FormData();
  formData.append("sId", this.state.sId);
 formData.append("uId", this.state.uId);
            fetch(configData.api_url +'C_Ruleexecutionhistory/list', {  // Enter your IP address here
        
              method: 'POST', 
              //headers :{ 'Content-Type' : 'application/json'},
              mode: 'cors', 
              body: formData// body data type must match "Content-Type" header
            }).then(response => response.json())
            .then((data) => {
              if (data.status === "success") {
                this.setState({ data: data.list });
              }
            });
           }
           RuleexecutionhistorysubmitHandler=(e)=>{
            e.preventDefault();
              //   setButtons(contacts);
             var formData = new FormData();
             formData.append("sId", this.state.sId);
              formData.append("uId", this.state.uId);
             formData.append('ruleId',this.state.ruleId);
formData.append('rulegroupId',this.state.rulegroupId);
formData.append('ruleflowId',this.state.ruleflowId);
formData.append('inputdataJSON',this.state.inputdataJSON);
formData.append('conditionResult',this.state.conditionResult);
formData.append('executionStart',this.state.executionStart);
formData.append('executionEnd',this.state.executionEnd);
formData.append('status',this.state.status);
formData.append('errorMessage',this.state.errorMessage);
formData.append('dummyOne',this.state.dummyOne);
formData.append('dummyTwo',this.state.dummyTwo);
formData.append('dummyThree',this.state.dummyThree);
formData.append('dummyFour',this.state.dummyFour);
formData.append('dummyFive',this.state.dummyFive);

             //***tableData*
             fetch(configData.api_url + 'C_Ruleexecutionhistory/add', {  // Enter your IP address here
          
               method: 'POST',
               //headers :{ 'Content-Type' : 'application/json'},
               mode: 'cors',
               body: formData// body data type must match "Content-Type" header
             }).then(response => response.json())
               .then((data) => {
                 this.showToast(data.message, data.status);
                 if (data.status === "success") {
                   this.RuleexecutionhistoryfetchHandler();
                   this.handleOffcanvasClose();
                   this.setState({ruleId:'',rulegroupId:'',ruleflowId:'',inputdataJSON:'',conditionResult:'',executionStart:'',executionEnd:'',status:'',errorMessage:'',dummyOne:'',dummyTwo:'',dummyThree:'',dummyFour:'',dummyFive:'',});
                }
              });
             }
   RuleexecutionhistoryupdateHandler=(e)=>{
        e.preventDefault();
     var formData = new FormData();
     formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("ruleexecutionhistory_Id", this.state.ruleexecutionhistory_Id);
          formData.append('ruleId',this.state.ruleId);
formData.append('rulegroupId',this.state.rulegroupId);
formData.append('ruleflowId',this.state.ruleflowId);
formData.append('inputdataJSON',this.state.inputdataJSON);
formData.append('conditionResult',this.state.conditionResult);
formData.append('executionStart',this.state.executionStart);
formData.append('executionEnd',this.state.executionEnd);
formData.append('status',this.state.status);
formData.append('errorMessage',this.state.errorMessage);
formData.append('dummyOne',this.state.dummyOne);
formData.append('dummyTwo',this.state.dummyTwo);
formData.append('dummyThree',this.state.dummyThree);
formData.append('dummyFour',this.state.dummyFour);
formData.append('dummyFive',this.state.dummyFive);

      //***tableData1
          fetch(configData.api_url +'C_Ruleexecutionhistory/update', {  
            method: 'POST', 
            mode: 'cors', 
            body: formData
          }).then(response => response.json())
            .then((data) => {
              this.showToast(data.message, data.status);
            if (data.status === "success") {
              this.RuleexecutionhistoryfetchHandler();
              this.handleOffcanvasClose();
            }
          });
         }

  componentDidMount() {
    this.RuleexecutionhistoryfetchHandler();
    
     //***componentDidMount
  }
  handleruleIdChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({ruleId: fieldValue });
};handlerulegroupIdChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({rulegroupId: fieldValue });
};handleruleflowIdChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({ruleflowId: fieldValue });
};handleinputdataJSONChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({inputdataJSON: fieldValue });
};handleconditionResultChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({conditionResult: fieldValue });
};handleexecutionStartChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({executionStart: fieldValue });
};handleexecutionEndChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({executionEnd: fieldValue });
};handlestatusChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({status: fieldValue });
};handleerrorMessageChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({errorMessage: fieldValue });
};handledummyOneChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({dummyOne: fieldValue });
};handledummyTwoChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({dummyTwo: fieldValue });
};handledummyThreeChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({dummyThree: fieldValue });
};handledummyFourChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({dummyFour: fieldValue });
};handledummyFiveChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({dummyFive: fieldValue });
};
  //***inputFunctions
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
    console.log(`Editing row with ID ${row.id}`);
    // implement your edit logic here
  };
handleDelete = (row, index) => {
  var formData = new FormData();
    formData.append('ruleexecutionhistory_Id', row.ruleexecutionhistory_Id);
    fetch(configData.api_url +'C_Ruleexecutionhistory/delete', {
          method: 'POST', 
        mode: 'cors', 
        body: formData
      }).then(response => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.RuleexecutionhistoryfetchHandler();
        }
      });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div className="page-title">
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="x_panel">
            <div className="x_content">
              <div
                id="datatable_wrapper"
                className="dataTables_wrapper form-inline
                dt-bootstrap no-footer"
              >
                <Row className="row">
                <div>
                 <h3 className="logoskybluecolor" style={{ textAlign: "start" }}>Rule Execution History</h3>
                </div>
            <Col
            className="d-flex justify-content-end"
            style={{ marginRight: "5px", marginTop: "20px" }}
          >
            
            <Offcanvas
              show={this.state.show}
              onHide={this.handleOffcanvasClose}
              {...this.props}
              style={{ width: "500px" }}
              placement="end"
              backdrop="true"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Add Ruleexecutionhistory</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Container style={{ "overflowY": "auto", height: "auto" }}>
                <Row className="divstyle">
                    <Row>
                      <Form>
                  <Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Rule Id</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handleruleIdChange} className="form-control" name="ruleId" value={this.state.ruleId} required="required" placeholder="Enter Rule Id" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.ruleId} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Rule Group Id</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handlerulegroupIdChange} className="form-control" name="rulegroupId" value={this.state.rulegroupId} required="required" placeholder="Enter Rule Group Id" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.rulegroupId} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Rule Flow Id</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handleruleflowIdChange} className="form-control" name="ruleflowId" value={this.state.ruleflowId} required="required" placeholder="Enter Rule Flow Id" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.ruleflowId} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Input Data JSON</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handleinputdataJSONChange} className="form-control" name="inputdataJSON" value={this.state.inputdataJSON} required="required" placeholder="Enter Input Data JSON" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.inputdataJSON} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Condition Result</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handleconditionResultChange} className="form-control" name="conditionResult" value={this.state.conditionResult} required="required" placeholder="Enter Condition Result" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.conditionResult} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Execution Start</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handleexecutionStartChange} className="form-control" name="executionStart" value={this.state.executionStart} required="required" placeholder="Enter Execution Start" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.executionStart} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Execution End</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handleexecutionEndChange} className="form-control" name="executionEnd" value={this.state.executionEnd} required="required" placeholder="Enter Execution End" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.executionEnd} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Status</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handlestatusChange} className="form-control" name="status" value={this.state.status} required="required" placeholder="Enter Status" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.status} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Error Message</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handleerrorMessageChange} className="form-control" name="errorMessage" value={this.state.errorMessage} required="required" placeholder="Enter Error Message" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.errorMessage} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Dummy One</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handledummyOneChange} className="form-control" name="dummyOne" value={this.state.dummyOne} required="required" placeholder="Enter Dummy One" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummyOne} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Dummy Two</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handledummyTwoChange} className="form-control" name="dummyTwo" value={this.state.dummyTwo} required="required" placeholder="Enter Dummy Two" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummyTwo} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Dummy Three</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handledummyThreeChange} className="form-control" name="dummyThree" value={this.state.dummyThree} required="required" placeholder="Enter Dummy Three" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummyThree} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Dummy Four</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handledummyFourChange} className="form-control" name="dummyFour" value={this.state.dummyFour} required="required" placeholder="Enter Dummy Four" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummyFour} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Dummy Five</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handledummyFiveChange} className="form-control" name="dummyFive" value={this.state.dummyFive} required="required" placeholder="Enter Dummy Five" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummyFive} </div>
</Row><br/>
                  {/* tableContent */}
                  </Form>
                  </Row>
                  </Row>
                  <Row>
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "50px" }}
                    >
                      <Button
                        className="button"
                        variant="success"
                        onClick={this.RuleexecutionhistorysubmitHandler}
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Offcanvas.Body>
            </Offcanvas>
            {this.state.isEditing && (
              // Edit Offcanvas
              <Offcanvas
              show={this.state.isEditing}
              // onHide={() => this.setState({ isEditing: false })}
              onHide={this.handleOffcanvasClose}
              {...this.props}
              style={{ width: "500px" }}
              placement="end"
              backdrop="true"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Edit Ruleexecutionhistory</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Container style={{ "overflowY": "auto", height: "auto" }}>
                <Row className="divstyle">
                    <Row>
                      <Form>
                  <Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Rule Id</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handleruleIdChange} className="form-control" name="ruleId" value={this.state.ruleId} required="required" placeholder="Enter Rule Id" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.ruleId} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Rule Group Id</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handlerulegroupIdChange} className="form-control" name="rulegroupId" value={this.state.rulegroupId} required="required" placeholder="Enter Rule Group Id" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.rulegroupId} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Rule Flow Id</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handleruleflowIdChange} className="form-control" name="ruleflowId" value={this.state.ruleflowId} required="required" placeholder="Enter Rule Flow Id" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.ruleflowId} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Input Data JSON</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handleinputdataJSONChange} className="form-control" name="inputdataJSON" value={this.state.inputdataJSON} required="required" placeholder="Enter Input Data JSON" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.inputdataJSON} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Condition Result</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handleconditionResultChange} className="form-control" name="conditionResult" value={this.state.conditionResult} required="required" placeholder="Enter Condition Result" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.conditionResult} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Execution Start</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handleexecutionStartChange} className="form-control" name="executionStart" value={this.state.executionStart} required="required" placeholder="Enter Execution Start" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.executionStart} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Execution End</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handleexecutionEndChange} className="form-control" name="executionEnd" value={this.state.executionEnd} required="required" placeholder="Enter Execution End" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.executionEnd} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Status</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handlestatusChange} className="form-control" name="status" value={this.state.status} required="required" placeholder="Enter Status" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.status} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Error Message</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handleerrorMessageChange} className="form-control" name="errorMessage" value={this.state.errorMessage} required="required" placeholder="Enter Error Message" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.errorMessage} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Dummy One</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handledummyOneChange} className="form-control" name="dummyOne" value={this.state.dummyOne} required="required" placeholder="Enter Dummy One" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummyOne} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Dummy Two</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handledummyTwoChange} className="form-control" name="dummyTwo" value={this.state.dummyTwo} required="required" placeholder="Enter Dummy Two" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummyTwo} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Dummy Three</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handledummyThreeChange} className="form-control" name="dummyThree" value={this.state.dummyThree} required="required" placeholder="Enter Dummy Three" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummyThree} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Dummy Four</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handledummyFourChange} className="form-control" name="dummyFour" value={this.state.dummyFour} required="required" placeholder="Enter Dummy Four" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummyFour} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Dummy Five</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handledummyFiveChange} className="form-control" name="dummyFive" value={this.state.dummyFive} required="required" placeholder="Enter Dummy Five" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummyFive} </div>
</Row><br/>
                  {/* tableContent */}
                  </Form>
                  </Row>
                  </Row>
                  <Row>
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "50px" }}
                    >
                      <Button
                        className="button"
                        variant="success"
                        onClick={this.RuleexecutionhistoryupdateHandler}
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        Update
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Offcanvas.Body>
            </Offcanvas>
            )}
        {/*offcanvas is over */}
                  </Col> 
                  </Row>
        <br />
        <Row>
<div style={{"marginRight":"5px"}}>
  {/* searchbar,pagination,filter */}
  <input
    type="text"
    style={{ float: "left", marginBottom: "10px" }}
    onChange={this.handleFilter}
    placeholder="Search..."
  />
  <Button
    variant="primary"
    className="bi bi-plus "  
    style={{ float: "right", marginBottom: "10px" }}
    onClick={this.handleOffcanvasShow}
  >
    New
  </Button>
  <DataTable
    data={paginatedData}
    columns={this.state.tableClms}
    pagination
    paginationServer
    paginationTotalRows={
      this.state.filteredData
        ? this.state.filteredData.length
        : this.state.data.length
    }
    onChangeRowsPerPage={this.handlePerRowsChange}
    onChangePage={this.handlePageChange}
    paginationPerPage={this.state.itemsPerPage}
    keyField="id"
    className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
  />
</div>
  </Row>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

import React, { Component } from "react";
import "./InsuranceTypes.css";
import { Col, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import configData from "../config.json";

export default class Draftpolicies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      insurances: [],
      show: false,
      errors: {},
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.cId,
          sortable: true,
          cell: (row) => <span>{row.cId}</span>,
        },
        {
          name: "Name",
          selector: (row, i) => row.cname,
          sortable: true,
          cell: (row) => <span>{row.cname}</span>,
        },
        {
          name: "Mobile",
          selector: (row, i) => row.mobile,
          sortable: true,
          cell: (row) => <span>{row.mobile}</span>,
        },
        {
          name: "EmailId",
          selector: (row, i) => row.emailId,
          sortable: true,
          cell: (row) => <span>{row.emailId}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row, index) => (
            <div>
              {/* edit button and delete button */}
              {/* <button
                style={{ marginLeft: "10px" }}
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen btn btn-primary"
              ></button> */}
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      isEditing: false, // Flag to indicate edit mode
      isNewEntry: false,
    };
  }
  handleEnterEditMode = (row) => {
    this.setState({ insuranceData: { ...row }, isEditing: true });
  };

  handleEnterNewEntryMode = () => {
    this.setState({ isNewEntry: true });
  };

  // Function to save edited data
  handleEditSave = (row) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    console.log("Clicked Edit Button. Row Data:", row);
    this.setState({ insuranceData: { ...row }, isEditing: true }, () => {
      console.log("Edit Data in State:", this.state.insuranceData);
    });
  };

  // offcanvas Open
  handleShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleClose = () => {
    this.setState({ show: false });
  };
  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.insurance.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "/customers/draftlist/", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success" || data.status === "true") {
          this.setState({ insurances: data.list });
        }
      });
  };

  componentDidMount() {
    this.apisfetchHandler();
  }
  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
        (this.state.currentPage - 1) * this.state.itemsPerPage,
        this.state.currentPage * this.state.itemsPerPage
      )
      : this.state.insurances.slice(
        (this.state.currentPage - 1) * this.state.itemsPerPage,
        this.state.currentPage * this.state.itemsPerPage
      );
    return (
      <div>
        <Row>
          <Col>
            <div className="" style={{ float: "left" }}>
              <h2 style={{ textAlign: "start" }}>Draft Policies</h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
            <div className="x_panel">
              <div className="x_content">
                <div className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                  <Row className="mt-5">
                    <div>
                      {/* searchbar,pagination,filter */}
                      <input
                        style={{ float: "left", marginBottom: "10px" }}
                        type="text"
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.insurances.length
                        }
                        // paginationTotalRows={this.state.insurance.length}
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                          paginationRowsPerPageOptions={[10, 20, 30, 50,60,70,80,90, 100]}
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

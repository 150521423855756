import React, { Component } from "react";
import "./DashBoards.css";
import {
  Container,
  Row,
  Col,
  Card,
  Dropdown,
  Modal,
  Form,
  Button,
} from "react-bootstrap";
import { Link, Outlet } from "react-router-dom";
import { Pie, Doughnut, Line, Bar } from "react-chartjs-2";
import configData from "../config.json";
import { toast } from "react-toastify";
// import { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend
);

export default class DashBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      step: 1,
      time: new Date(),
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      loginTimes: [],
      logoutTimes: [],
      isWorking: false,
      lastActivity: null,
      data: [],
      data1: [],
      data2: [],
      chartData: [],
      // widgetId: 95,
      showModal: false,
      widgets: [],
      editIndex: null,
      layouttwo: [],
      layout: [],
    };
    // this.handleUserActivity = this.handleUserActivity.bind(this);
  }

  handleCloseOne = () => {
    this.setState({ showOne: false });
  };
  handleShow = () => {
    this.setState({ show: true });
  };

  handleShowOne = () => {
    this.setState({ showOne: true });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  handleShowModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false, heading: "", content: "" });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleNextStep = () =>
    this.setState((prevState) => ({ step: prevState.step + 1 }));
  handlePrevStep = () => {
    this.setState((prevState) => ({
      step: Math.max(prevState.step - 1, 1), // Limit to min step 1
    }));
  };
  componentDidMount() {
    this.WidgetdefinationfetchHandler();
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
    this.endCurrentSession();
  }
  WidgetdefinationfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Widgetdefinition/list", {
      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
          data.list.forEach((element) => {
            var chartData1 = {
              labels: [],
              datasets: [
                {
                  label: "Loading..",
                  data: [],
                  backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#4BC0C0",
                    "#9966FF",
                  ],
                  borderColor: "rgba(75, 192, 192, 1)",
                  borderWidth: 1,
                },
              ],
            };
            //var chartData1 = this.genreateChartData(data.widgetData.Counts);
            var chartData = this.state.chartData;
            chartData[`card_${element.widgetId}`] = chartData1;
            this.setState({ chartData: chartData });
            this.getwidgetDataFetchHandler(
              element.widgetId,
              `card_${element.widgetId}`,
              element.widgetName
            );
          });
          const generatedLayout = data.list.map((item, index) => ({
            i: `card_${item.widgetId}`,
            x: (index % 4) * 3,
            y: Math.floor(index / 2) * 2,
            w: Math.max(2, Math.ceil((parseInt(item.minWidth) || 200) / 100)),
            h: Math.max(
              2.5,
              Math.ceil((parseInt(item.minHeight) || 200) / 100)
            ),
            minW: Math.ceil((parseInt(item.minWidth) || 200) / 100),
            minH: Math.ceil((parseInt(item.minHeight) || 200) / 100),
            widgetDetails: item,
            widgetData: this.state.chartData[`card_${item.widgetId}`],
          }));
          this.setState({ data: data.list, layout: generatedLayout });
        }
      });
  };

  getwidgetDataFetchHandler = (widgetId, name, widgetName) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("fromDate", this.state.fromDate);
    formData.append("toDate", this.state.toDate);
    formData.append("widgetId", widgetId);
    fetch(configData.api_url + "C_Widgetdefinition/getwidgetData", {
      // Enter your IP address here
      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          var chartData1 = this.genreateChartData(
            data.widgetData.Counts,
            widgetName
          );
          var chartData = this.state.chartData;
          chartData[name] = chartData1;
          this.setState({ chartData: chartData });
          // this.setState((prevState) => ({
          //   chartData: [...prevState.chartData, chartData1],
          // }));
        }
      });
  };
  genreateChartData = (widgetCounts, columnName, widgetName) => {
    const labels = widgetCounts.map((item) => item.label || "N/A");
    const counts = widgetCounts.map((item) => item.count);
    const pieChartData = {
      labels: labels,
      datasets: [
        {
          label: widgetName,
          data: counts,
          backgroundColor: [
            "#FF6384",
            "#36A2EB",
            "#FFCE56",
            "#4BC0C0",
            "#9966FF",
          ],
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
        },
      ],
    };
    // console.log(pieChartData);
    return pieChartData;
  };
  endCurrentSession() {
    const logoutTime = new Date().toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
    this.setState((prevState) => ({
      logoutTimes: [...prevState.logoutTimes, logoutTime],
    }));
  }

  tick() {
    this.setState({
      time: new Date(),
    });
  }

  getGreeting() {
    const hour = this.state.time.getHours();
    if (hour < 12) return "Good morning";
    if (hour < 18) return "Good afternoon";
    return "Good evening";
  }

  render() {
    //const ResponsiveGridLayout = WidthProvider(Responsive);
    const greeting = this.getGreeting();
    const uName = sessionStorage.getItem("userName");
    const currentTime = this.state.time.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    return (
      <div>
        <Row className="dashboard d-flex justify-content-between">
          <Col lg="2" md="2" sm="2" className="d-flex">
            <Dropdown className="d-inline mx-2 overview">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                className="btn btn-light"
                style={{ marginTop: "5px", border: "1px solid #23ae73" }}
              >
                Overview
              </Dropdown.Toggle>
              <Dropdown.Menu></Dropdown.Menu>
            </Dropdown>
            <span className="bi bi-lock"></span>
          </Col>

          <Col
            lg={{ span: 6, offset: 4 }}
            md={{ span: 6, offset: 4 }}
            sm={{ span: 6, offset: 4 }}
            className="d-flex justify-content-end"
          >
            <span className="bi bi-arrow-clockwise"></span>
            <Dropdown className="d-inline mx-2 component">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                className="btn btn-light"
                style={{ marginTop: "5px", border: "1px solid #23ae73" }}
              >
                + Component
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>
                  <Link to="">KPI</Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link onClick={this.handleShowOne}>Chart Creation</Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link to="Dashboardcharts">Employee Chart</Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="d-inline mx-2 component">
              <Dropdown.Toggle
                id="dropdown-autoclose-true"
                className="btn btn-light"
                style={{ marginTop: "5px", border: "1px solid #23ae73" }}
              ></Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item>
                  <Link to="">View in Full Screen</Link>
                </Dropdown.Item>
                <Dropdown.Item>
                  <Link to="">Delete</Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Container fluid>
              <Row className="mb-2">
                <Col md={12} lg="12">
                  <Card className="p-3">
                    <Row>
                      <Col lg="9" md="9">
                        <h2>
                          {greeting}, {uName}!
                        </h2>
                        <p>Your performance is looking good!</p>
                      </Col>
                      <Col>
                        <p>Current time: {currentTime}</p>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              {/* <Row>
                <div>
                  <ResponsiveGridLayout
                    className="layout"
                    layouts={{ lg: this.state.layout }}
                    breakpoints={{
                      lg: 1200,
                      md: 996,
                      sm: 768,
                      xs: 480,
                      xxs: 0,
                    }}
                    //cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                    //cols={{ lg: 4, md: 3, sm: 6, xs: 4, xxs: 2 }}
                    rowHeight={100}
                    isResizable={true}
                    isDraggable={true}
                    autoSize={true}
                    compactType="vertical" // Rearrange cards in a vertical manner on resize
                  >
                    {this.state.layout.map((widget, index) => (
                      <div
                        key={widget.i}
                        style={{
                          width: widget.w,
                          height: widget.h,
                          minWidth: widget.minW,
                          minHeight: widget.minH,
                          position: "relative",
                          margin: "0 auto",
                        }}
                      >
                        <div>
                          <div
                            style={{
                              padding: "10px",
                              background: "#f8f9fa",
                              borderRadius: "4px",
                            }}
                          >
                            <h5>{widget.widgetDetails.widgetName}</h5>
                            <div
                              style={{
                                width: "100%",
                                height: "100%", // Make the chart container responsive
                                position: "relative",
                              }}
                            >
                              {widget.widgetDetails.chartType === "PIE" && (
                                <Pie
                                  data={this.state.chartData[widget.i]}
                                  options={{
                                    responsive: true,
                                    maintainAspectRatio: true,
                                  }}
                                />
                              )}
                              {widget.widgetDetails.chartType === "DONUT" && (
                                <Doughnut
                                  data={this.state.chartData[widget.i]}
                                  options={{
                                    responsive: true,
                                    maintainAspectRatio: true,
                                  }}
                                />
                              )}
                              {widget.widgetDetails.chartType === "LINE" && (
                                <Line
                                  data={this.state.chartData[widget.i]}
                                  options={{
                                    responsive: true,
                                    maintainAspectRatio: true,
                                  }}
                                />
                              )}
                              {widget.widgetDetails.chartType === "BAR" && (
                                <Bar
                                  data={this.state.chartData[widget.i]}
                                  options={{
                                    responsive: true,
                                    maintainAspectRatio: true,
                                  }}
                                />
                              )}
                              {widget.widgetDetails.chartType === "BISCUT" && (
                                <div style={{ width: "100%", height: "100%" }}>
                                  <div className="box text-center">
                                    <i
                                      className="bi bi-currency-rupee"
                                      aria-hidden="true"
                                      style={{
                                        fontSize: "24px",
                                        color: "#333",
                                      }}
                                    ></i>
                                    <div className="box-title mt-2">
                                      {this.state.chartData[
                                        widget.i
                                      ].datasets[0].data.map((item, idx) => (
                                        <div
                                          key={idx}
                                          style={{ marginBottom: "10px" }}
                                        >
                                          <h3>{item}</h3>
                                          <br />
                                          <p>
                                            {this.state.chartData[widget.i]
                                              .labels[idx] || "N/A"}{" "}
                                          </p>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </ResponsiveGridLayout>
                </div>
              </Row> */}
              <Row>
                {this.state.layout.map((widget, index) => (
                  <Col
                    lg="4"
                    key={index + 1}
                    style={
                      {
                        // width: widget.w,
                        // height: widget.h,
                        // minWidth: widget.minW,
                        // minHeight: widget.minH,
                        // position: "relative",
                        // margin: "0 auto",
                      }
                    }
                  >
                    <Card className="mt-3" key={widget.i}>
                      <div
                        style={{
                          padding: "10px",
                          background: "#f8f9fa",
                          borderRadius: "4px",
                        }}
                      >
                        <h5>{widget.widgetDetails.widgetName}</h5>
                        <div
                          style={{
                            width: "100%",
                            height: "100%", // Make the chart container responsive
                            position: "relative",
                          }}
                        >
                          {widget.widgetDetails.chartType === "PIE" && (
                            <Pie
                              data={this.state.chartData[widget.i]}
                              options={{
                                responsive: true,
                                maintainAspectRatio: true,
                              }}
                            />
                          )}
                          {widget.widgetDetails.chartType === "DONUT" && (
                            <Doughnut
                              data={this.state.chartData[widget.i]}
                              options={{
                                responsive: true,
                                maintainAspectRatio: true,
                              }}
                            />
                          )}
                          {widget.widgetDetails.chartType === "LINE" && (
                            <Line
                              data={this.state.chartData[widget.i]}
                              options={{
                                responsive: true,
                                maintainAspectRatio: true,
                              }}
                            />
                          )}
                          {widget.widgetDetails.chartType === "BAR" && (
                            <Bar
                              data={this.state.chartData[widget.i]}
                              options={{
                                responsive: true,
                                maintainAspectRatio: true,
                              }}
                            />
                          )}
                          {widget.widgetDetails.chartType === "BISCUT" && (
                            <div style={{ width: "100%", height: "100%" }}>
                              <div className="box text-center">
                                <i
                                  className="bi bi-currency-rupee"
                                  aria-hidden="true"
                                  style={{
                                    fontSize: "24px",
                                    color: "#333",
                                  }}
                                ></i>
                                <div className="box-title mt-2">
                                  {this.state.chartData[
                                    widget.i
                                  ].datasets[0].data.map((item, idx) => (
                                    <div
                                      key={idx}
                                      style={{ marginBottom: "10px" }}
                                    >
                                      <h3>{item}</h3>
                                      <br />
                                      <p>
                                        {this.state.chartData[widget.i].labels[
                                          idx
                                        ] || "N/A"}{" "}
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </Col>
        </Row>
        <div>
          <Modal
            size="lg"
            show={this.state.showOne}
            onHide={this.handleCloseOne}
          >
            <Modal.Header closeButton>
              <Modal.Title>Regularize note</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="chartType">
                  <Form.Label>Select Chart Type</Form.Label>
                  <Form.Control
                    as="select"
                    value={this.state.dashboard}
                    name="dashboard"
                    onChange={this.handleChartTypeChange}
                  >
                    <option value="">Select Widget</option>

                    {this.state.data.map((api, index, idx) => (
                      <option key={index} value={api.widgetId}>
                        {api.widgetName}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <br />
                <Button
                  variant="primary"
                  style={{ float: "right", marginTop: "5px" }}
                  //onClick={this.handleNextWidStep}
                >
                  Save
                </Button>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseOne}>
                Close
              </Button>
              <Button variant="primary" onClick={this.handleSubmit}>
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <Row>
          <Outlet />
        </Row>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Button, Container, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";

export default class Irdainsurancetypeslife extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.irdainsurancetypeslife_Id,
          sortable: true,
          cell: (row) => <span>{row.irdainsurancetypeslife_Id}</span>,
        },
        {
          name: "IRDA Insurance Type",
          selector: (row, i) => row.IRDAInsuranceType,
          sortable: true,
          cell: (row) => <span>{row.IRDAInsuranceType}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen"
                style={{ marginRight: "3px" }}
              ></Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      IRDAInsuranceType: "",
      irdainsurancetypeslife_Id: "",
      isEditing: false, // Flag to indicate edit mode
      isNewEntry: false,
    };
  }
  handleEnterEditMode = (row) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    this.setState({
      IRDAInsuranceType: row.IRDAInsuranceType,
      irdainsurancetypeslife_Id: row.irdainsurancetypeslife_Id,
      isEditing: true,
    });
    // this.setState({ cData: { ...row }, isEditing: true });
  };
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  IrdainsurancetypeslifeeditHandler = (e) => {
    e.preventDefault();
    // const updateFormData = {...editFormData};
    // updateFormData['Irdainsurancetypeslife_Id'] = e.target.parentElement.getAttribute("id");
    // setEditFormData(updateFormData);
    // setEdit(true);
  };

  IrdainsurancetypeslifefetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Irdainsurancetypeslife/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  validateForm() {
    let data = this.state;
    let errors = {};
    let formIsValid = true;
    if (!data["IRDAInsuranceType"]) {
      formIsValid = false;
      toast.info("*Please Enter  IRDA Insurance Type.");
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  IrdainsurancetypeslifesubmitHandler = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      //   setButtons(contacts);
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("IRDAInsuranceType", this.state.IRDAInsuranceType);

      fetch(configData.api_url + "C_Irdainsurancetypeslife/add", {
        // Enter your IP address here

        method: "POST",
        //headers :{ 'Content-Type' : 'application/json'},
        mode: "cors",
        body: formData, // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            this.IrdainsurancetypeslifefetchHandler();
            this.handleOffcanvasClose();
          }
        });
    }
  };
  IrdainsurancetypeslifeupdateHandler = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      //   setButtons(contacts);

      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("IRDAInsuranceType", this.state.IRDAInsuranceType);
      formData.append(
        "irdainsurancetypeslife_Id",
        this.state.irdainsurancetypeslife_Id
      );

      fetch(configData.api_url + "C_Irdainsurancetypeslife/update", {
        // Enter your IP address here

        method: "POST",
        //headers :{ 'Content-Type' : 'application/json'},
        mode: "cors",
        body: formData, // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            this.IrdainsurancetypeslifefetchHandler();
            this.handleOffcanvasClose();
            this.setState({
              IRDAInsuranceType: "",
              irdainsurancetypeslife_Id: "",
            });
          }
        });
    }
  };

  componentDidMount() {
    this.IrdainsurancetypeslifefetchHandler();
  }
  handleIRDAInsuranceTypeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ IRDAInsuranceType: fieldValue });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
    console.log(`Editing row with ID ${row.id}`);
    // implement your edit logic here
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    const irdainsurancetypeslife_Id = row.irdainsurancetypeslife_Id;
    // console.log(e.target.parentElement);
    formData.append("irdainsurancetypeslife_Id", irdainsurancetypeslife_Id);
    fetch(configData.api_url + "C_Irdainsurancetypeslife/delete", {
      // Enter your IP address here
      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.IrdainsurancetypeslifefetchHandler();
          this.showToast(data.message, data.status);
        }
      });
    //implement your delete logic here
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div className="page-title">
        <div className="row">
          <div className="title_left">
            <h2 style={{ textAlign: "start" }}>Irda insurance types life</h2>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                                   dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "5px", marginTop: "20px" }}
                    >
                      <Button
                        variant="primary"
                        className="bi bi-plus "
                        onClick={this.handleOffcanvasShow}
                      >
                        New
                      </Button>
                      <Offcanvas
                        show={this.state.show}
                        onHide={this.handleOffcanvasClose}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            Irda insurance types life
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                            <Row className="divstyle">
                              <Row>
                                <Form>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        IRDA Insurance Type
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleIRDAInsuranceTypeChange
                                        }
                                        className="form-control"
                                        name="IRDAInsuranceType"
                                        value={this.state.IRDAInsuranceType}
                                        required="required"
                                        placeholder="Enter IRDAInsuranceType"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.IRDAInsuranceType}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                </Form>
                              </Row>
                            </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={
                                    this.IrdainsurancetypeslifesubmitHandler
                                  }
                                  style={{
                                    borderRadius: "100px 100px 100px 100px",
                                    marginTop: "20px",
                                  }}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                      {/*offcanvas is over */}
                    </Col>
                  </Row>
                  <br />
                  <div>
                    {""}
                    {this.state.isEditing && (
                      <Offcanvas
                        show={this.state.isEditing}
                        onHide={() => this.setState({ isEditing: false })}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            Irda insurance types life
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                            <Row className="divstyle">
                              <Row>
                                <Form>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        IRDA Insurance Type
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        value={this.state.IRDAInsuranceType}
                                        onChange={
                                          this.handleIRDAInsuranceTypeChange
                                        }
                                        className="form-control"
                                        name="IRDAInsuranceType"
                                        required="required"
                                        placeholder="Enter IRDAInsuranceType"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.IRDAInsuranceType}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                </Form>
                              </Row>
                            </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={
                                    this.IrdainsurancetypeslifeupdateHandler
                                  }
                                  style={{
                                    borderRadius: "100px 100px 100px 100px",
                                    marginTop: "20px",
                                  }}
                                >
                                  Update
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                    )}
                  </div>
                  <br />
                  <Row>
                    <div style={{ marginRight: "5px" }}>
                      {/* searchbar,pagination,filter */}
                      <input
                        type="text"
                        style={{ float: "left", marginBottom: "10px" }}
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                          paginationRowsPerPageOptions={[10, 20, 30, 50,60,70,80,90, 100]}
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

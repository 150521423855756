import React, { Component } from "react";
import {
  Offcanvas,
  Row,
  Col,
  Form,
  Button,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import DataTable from "react-data-table-component";
import configData from "../config.json";
import Calendar from "react-calendar";
import "./Workdesk.css";
import "react-calendar/dist/Calendar.css";
import { toast } from "react-toastify";

export default class Workdesk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      show1: false,
      data: [],
      tables: [],
      workdesk: [],
      leavetypes: [],
      raddendance: [],
      note: "",
      leave: [],
      errors: {},
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      filteredData: null,
      filteredDataOne: null,
      markAttendanceDate: new Date(),
      regularizeAttendanceDate: new Date(),
      attended: [],
      Holidays: [],
      disabledDates: this.loadDisabledDatesFromStorage() || [],
      tableClms: [
        {
          name: "S.No",
          selector: (row, index) => index + 1,
          sortable: true,
          cell: (row) => <input type="checkbox" id={`checkbox-${row.id}`} />,
        },
        {
          name: "Requester",
          selector: (row) => row.fName,
          sortable: true,
          cell: (row) => (
            <span>
              {row.fName} {row.lName}
            </span>
          ),
        },
        {
          name: "Type",
          selector: (row) => row.LeaveType,
          sortable: true,
          cell: (row) => <span>{row.LeaveType}</span>,
        },
        {
          name: "Days",
          selector: (row) => row.NoOfLeaves,
          sortable: true,
          cell: (row) => <span>{row.NoOfLeaves}</span>,
        },
        {
          name: "From Date",
          selector: (row) => row.FromDate,
          sortable: true,
          cell: (row) => <span>{row.FromDate}</span>,
        },
        {
          name: "To Date",
          selector: (row) => row.ToDate,
          sortable: true,
          cell: (row) => <span>{row.ToDate}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row, index) => (
            <div>
              <button
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen btn btn-primary m-1"
              ></button>
            </div>
          ),
        },
      ],
      tableClmsOne: [
        {
          name: "S.No",
          selector: (row, index) => index + 1,
          sortable: true,
          cell: (row) => <input type="checkbox" id={`checkbox-${row.id}`} />,
        },
        {
          name: "Requester",
          selector: (row) => row.fName,
          sortable: true,
          cell: (row) => (
            <span>
              {row.fName} {row.lName}
            </span>
          ),
        },
        {
          name: "Date",
          selector: (row) => row.aDate,
          sortable: true,
          cell: (row) => <span>{row.aDate}</span>,
        },
        {
          name: "Reason",
          selector: (row) => row.reason,
          sortable: true,
          cell: (row) => <span>{row.reason}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row, index) => (
            <div>
              <button
                onClick={() => this.handleEnterEditModeOne(row)}
                className="bi bi-pen btn btn-primary m-1"
              ></button>
            </div>
          ),
        },
      ],
      RequestedBy: "",
      LeaveBalance: "",
      FromDate: this.getCurrentDate(), // Set default value to current date
      ToDate: "",
      NoOfLeaves: "",
      LeaveType: "",
      remarks: "",
      RemarksRemarks: "",
      ApproverID: "",
      ApproverType: "",
      currentPage: 1,
      itemsPerPage: 10,
      Decision: "",
      DecisionRemarks: "",
      leaverequestapprovals_Id: "",
      reason: "",
      aId: "",
      aDate: "",
      employees_Id: "",
      isEditing: false,
      isNewEntry: false,
    };
  }
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  handleCloseTne = () => {
    this.setState({ showOne: false });
  };
  handleShowTwo = () => {
    this.setState({ showOne: true });
  };
  handleReguralizeDateChange = (date) => {
    this.setState({ regularizeAttendanceDate: date });
  };

  handleNoteChange = (event) => {
    this.setState({ note: event.target.value });
  };
  isDateDisabledOne = ({ date }) => {
    // Calculate the current month's starting date
    const currentDate = new Date();
    const currentMonthStartDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      // date.getFullYear(),
      // date.getMonth(),
      1
    );

    const isPastMonthStartDate = date < currentMonthStartDate;

    // Check if the current date is a Saturday (day 6) or Sunday (day 0)
    const isWeekend = date.getDay() === 6 || date.getDay() === 0;

    // Check if the current date is included in the list of disabled dates
    const isDisabledDate = this.state.disabledDates.some((disabledDate) => {
      // Parse the stored date string back to Date object
      const storedDate = new Date(disabledDate);
      return (
        storedDate.getFullYear() === date.getFullYear() &&
        storedDate.getMonth() === date.getMonth() &&
        storedDate.getDate() === date.getDate()
      );
    });
    if (
      date < currentMonthStartDate ||
      date > currentDate ||
      date.getDay() === 0 ||
      date.getDay() === 6
    ) {
      return true;
    }
    // Disable the date if it's before the current month's starting date,
    // after the current date, or if it's a Saturday, Sunday, or a disabled date
    return (
      isPastMonthStartDate || date > new Date() || isWeekend || isDisabledDate
    );
  };
  tileClassNameOne = ({ date }) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-based
    const day = date.getDate();
    const dateString = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;
    // Find the holiday object for the current date
    const holiday = this.state.Holidays.find(
      (holiday) => holiday.Date === dateString
    );
    // Check if the current date is a holiday
    const isHoliday = holiday !== undefined;
    // Check if the current date is a Saturday
    const isSaturday = date.getDay() === 6; // Saturday is the 6th day of the week (zero-based)

    // Check if the current date is the last Saturday of the month
    const lastDayOfMonth = new Date(year, month, 0).getDate();
    const isLastSaturday = isSaturday && day > lastDayOfMonth - 7;
    if (isHoliday) {
      return "holiday"; // Apply holiday class
    } else if (isLastSaturday) {
      return "last-saturday"; // Apply last-saturday class
    } else {
      return null; // No special class
    }
  };
  loadDisabledDatesFromStorage() {
    const disabledDates = JSON.parse(localStorage.getItem("disabledDates"));
    console.log("Loaded disabled dates:", disabledDates);
    return disabledDates;
  }
  saveDisabledDatesToStorage(disabledDates) {
    console.log("Saving disabled dates:", disabledDates);
    localStorage.setItem("disabledDates", JSON.stringify(disabledDates));
  }
  // handleSubmit = () => {
  //   console.log("Submitting...");
  //   console.log("Date:", this.state.regularizeAttendanceDate);
  //   console.log("Note:", this.state.note);

  //   // Calculate the date 60 days ago
  //   const past60Days = new Date();
  //   past60Days.setDate(past60Days.getDate() - 60);

  //   // Add the selected date to the list of disabled dates if it's within the past 60 days
  //   const updatedDisabledDates =
  //     this.state.regularizeAttendanceDate >= past60Days
  //       ? [
  //           ...this.state.disabledDates,
  //           this.state.regularizeAttendanceDate.toISOString(), // Store date as string for simplicity
  //         ]
  //       : [...this.state.disabledDates];

  //   // Save updated disabled dates to localStorage
  //   this.saveDisabledDatesToStorage(updatedDisabledDates);

  //   // Reset the form and close the modal
  //   this.setState({
  //     showOne: false,
  //     disabledDates: updatedDisabledDates,
  //     regularizeAttendanceDate: new Date(),
  //     note: "",
  //   });
  // };
  handleSubmit = (e, status) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("aId", this.state.aId);
    formData.append("aStatus", status);
    formData.append("employees_Id", this.state.employees_Id);
    fetch(configData.api_url + "C_Employees/updateregulariseAttendance", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.handleOffcanvasClose();
          this.regralizefetchHandler();
          this.setState({
            aId: "",
            aStatus: "",
            employees_Id: "",
            sId: "",
          });
        }
      });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  //editing
  handleEnterEditMode = (row) => {
    // this.setState({ cData: { ...row }, isEditing: true });
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("workdeskstatus", this.state.workdeskstatus);
    formData.append(
      "leaverequestapprovals_Id",
      this.state.leaverequestapprovals_Id
    );
    this.setState({
      // uName: row.uName,
      fName: row.fName,
      LeaveBalance: row.LeaveBalance,
      FromDate: row.FromDate,
      ToDate: row.ToDate,
      NoOfLeaves: row.NoOfLeaves,
      leaves: row.leaves,
      LeaveType: row.LeaveType,
      remarks: row.remarks,
      Decision: row.Decision,
      DecisionRemarks: row.DecisionRemarks,
      ApproverID: row.ApproverID,
      ApproverType: row.ApproverType,
      leaverequestapprovals_Id: row.leaverequestapprovals_Id,
      isEditing: true,
    });
  };
  //edit
  handleEnterEditModeOne = (row, date) => {
    // this.setState({ cData: { ...row }, isEditing: true });
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    this.regralizefetchHandler(date);
    this.setState({
      fName: row.fName,
      aDate: row.aDate,
      aStatus: row.aStatus,
      reason: row.reason,
      aId: row.aId,
      employees_Id: row.employees_Id,
      selectedDate: date,
      isEditingone: true,
    });
  };
  // offcanvas Open
  handleShow = (workdesk) => {
    this.setState({ show: true, selectedTable: workdesk });
  };
  // Offcanvas close button
  handleClose = () => {
    this.setState({ show: false, selectedTable: null });
  };
  // offcanvas Open one
  handleShowOne = (workdesk) => {
    this.setState({ show1: true, selectedTable: workdesk });
  };
  // Offcanvas close button
  handleCloseOne = () => {
    this.setState({ show1: false, selectedTable: null });
  };

  getTables = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "/masters/gettables", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({ tables: data.tables });
          //console.log(this.state.tables);
        }
      });
  };

  componentDidMount() {
    this.workdeskfetchHandler();
    this.leavetypesfetchHandler();
    this.leavetypesfetchUpdateHandler();
    this.leavetypesfetchUpdateStatus();
    this.AttendencefetchHandler();
    this.HolidayslistfetchHandler();
    this.regralizefetchHandler();
  }
  regralizefetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);

    fetch(configData.api_url + "C_Employees/getregulariseAttendance", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ raddendance: data.list });
        }
      });
  };
  HolidayslistfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Holidayslist/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ Holidays: data.list });
        }
      });
  };
  AttendencefetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Employees/getattendance", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list.attended });
        }
      });
  };
  leavetypesfetchUpdateStatus = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("DecisionRemarks", this.state.DecisionRemarks);
    formData.append(
      "leaverequestapprovals_Id",
      this.state.leaverequestapprovals_Id
    );
    fetch(configData.api_url + "C_Leaverequestapprovals/updateleaveStatus", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ leavetypes: data.list });
        }
      });
  };
  leavetypesfetchUpdateHandler = () => {
    fetch(configData.api_url + "C_Leaverequestapprovals/updateworkdeskStatus", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ leavetypes: data.list });
        }
      });
  };
  leavetypesfetchHandler = () => {
    fetch(configData.api_url + "C_Leavetypes/list", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ leavetypes: data.list });
        }
      });
  };
  workdeskfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    //formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Leaverequestapprovals/getleaveslist", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ workdesk: data.list });
        }
      });
  };
  WorkdeskupdateHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("remarks", this.state.remarks);
    formData.append("FromDate", this.state.FromDate);
    formData.append("ToDate", this.state.ToDate);
    formData.append("Decision", this.state.Decision);
    formData.append("DecisionRemarks", this.state.DecisionRemarks);
    formData.append(
      "leaverequestapprovals_Id",
      this.state.leaverequestapprovals_Id
    );
    fetch(configData.api_url + "C_Leaverequestapprovals/updateleaveStatus", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.workdeskfetchHandler();
          this.handleOffcanvasClose();
          this.setState({
            uName: "",
            LeaveBalance: "",
            FromDate: "",
            ToDate: "",
            NoOfLeaves: "",
            LeaveType: "",
            remarks: "",
            Decision: "",
            DecisionRemarks: "",
            ApproverID: "",
            ApproverType: "",
            leaverequestapprovals_Id: "",
          });
        }
      });
  };
  // Handler method for Approved button
  handleApprove = () => {
    // Additional logic if needed before making the API call
    this.updateWorkdesk("approved");
  };

  // Handler method for Rejected button
  handleReject = () => {
    // Additional logic if needed before making the API call
    this.updateWorkdesk("rejected");
  };

  handleRequestedByChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ RequestedBy: fieldValue });
  };
  handleLeaveBalanceChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ LeaveBalance: fieldValue });
  };
  getCurrentDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    let month = currentDate.getMonth() + 1;
    let day = currentDate.getDate();

    // Add leading zeros if month or day is less than 10
    month = month < 10 ? "0" + month : month;
    day = day < 10 ? "0" + day : day;

    return `${year}-${month}-${day}`;
  }
  handleFromDateChange = (e) => {
    const fromDate = e.target.value;
    this.setState({ FromDate: fromDate }, () => {
      this.calculateLeaveBalance();
    });
  };

  handleToDateChange = (e) => {
    const toDate = e.target.value;
    this.setState({ ToDate: toDate }, () => {
      this.calculateLeaveBalance();
    });
  };

  calculateLeaveBalance = () => {
    const { FromDate, ToDate } = this.state;

    // Perform date validations if needed

    // Calculate the difference in days
    const fromDateTime = new Date(FromDate).getTime();
    const toDateTime = new Date(ToDate).getTime();
    const differenceInDays = (toDateTime - fromDateTime) / (1000 * 3600 * 24);

    // Update the state with the calculated leave balance
    this.setState({ NoOfLeaves: differenceInDays });
  };
  handleNoOfLeavesChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ NoOfLeaves: fieldValue });
  };
  handleLeaveTypeChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ LeaveType: fieldValue });
  };
  handleDecisionChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ remarks: fieldValue });
  };
  handleDecisionChanges = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ Decision: fieldValue });
  };
  handleDecisionRemarksChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ DecisionRemarks: fieldValue });
  };
  handleApproverIDChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ ApproverID: fieldValue });
  };
  handleApproverTypeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ ApproverType: fieldValue });
  };
  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.workdesk.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };
  handleFilterOne = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredDataOne = this.state.raddendance.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredDataOne });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };
  handlePageChangeone = (page) => {
    this.setState({ currentPage: page });
  };
  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };
  handlePerRowsChangeone = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };
  handleDateChange = (date) => {
    console.log("Selected Date:", date);
    // Update state to reflect the selected date
    this.setState({ markAttendanceDate: date });
  };
  tileDisabled = ({ date, view }) => {
    // Disable past dates in month view
    if (view === "month" && date < new Date()) {
      return true;
    }
    // Disable future dates in month view
    if (view === "month" && date > new Date()) {
      return true;
    }
    // Disable specific dates in year view if needed
    // Example: Disable dates before 2023
    // if (view === "year" && date.getFullYear() < 2023) {
    //   return true;
    // }
    return false;
  };
  render() {
    // const uName = sessionStorage.getItem("userName");
    const { selectedTable } = this.state;
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.workdesk.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    const paginatedDataone = this.state.filteredDataOne
      ? this.state.filteredDataOne.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.raddendance.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );
    // const minDate = new Date();
    // minDate.setDate(minDate.getDate() - 10);

    return (
      <div>
        <div className="container">
          {/* <div className="row">
            <div className="col">
              <h3 className="logoskybluecolor">Workdesk</h3>
            </div>
          </div> */}

          <div className="row mt-1">
            <div className="col">
              <div>
                <Offcanvas
                  show={this.state.show}
                  onHide={this.handleClose}
                  {...this.props}
                  style={{ width: "500px", height: "auto" }}
                  placement="end"
                  backdrop="true"
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>
                      Edit pages{selectedTable ? "Table" : ""}{" "}
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <div className="container">
                      <div className="row">
                        <div className="col">
                          {selectedTable && (
                            <pre>{JSON.stringify(selectedTable, null, 2)}</pre>
                          )}
                        </div>
                      </div>
                    </div>
                  </Offcanvas.Body>
                </Offcanvas>
              </div>
              <br />
              <div>
                {" "}
                {this.state.isEditing && (
                  // Edit Offcanvas
                  <Offcanvas
                    show={this.state.isEditing}
                    onHide={() => this.setState({ isEditing: false })}
                    {...this.props}
                    style={{ width: "500px", height: "auto" }}
                    placement="end"
                    backdrop="true"
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>Edit Leave Request</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <div className="form-horizontal form-label-left input_mask">
                        <Form className="form-horizontal form-label-left input_mask">
                          <Row>
                            <Col lg="4" md="4">
                              <Form.Label style={{ paddingTop: "8px" }}>
                                Requested By
                              </Form.Label>
                            </Col>
                            <Col lg="8" md="8">
                              <Form.Control
                                type="text"
                                onChange={this.handleRequestedByChange}
                                className="form-control"
                                name="fName"
                                //defaultValue={uName}
                                value={this.state.fName}
                                required="required"
                                placeholder="Enter RequestedBy"
                                disabled
                              />
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            <Col lg="4" md="4">
                              <Form.Label style={{ paddingTop: "8px" }}>
                                Prev. Leave Balance
                              </Form.Label>
                            </Col>
                            <Col lg="8" md="8">
                              <Form.Control
                                type="text"
                                disabled
                                onChange={this.handleLeaveBalanceChange}
                                className="form-control"
                                name="leaves"
                                value={this.state.leaves}
                                required="required"
                                placeholder="Enter LeaveBalance"
                              />
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            <Col lg="4" md="4">
                              <Form.Label style={{ paddingTop: "8px" }}>
                                From Date
                              </Form.Label>
                            </Col>
                            <Col lg="8" md="8">
                              <Form.Control
                                type="date"
                                disabled
                                onChange={this.handleFromDateChange}
                                className="form-control"
                                name="FromDate"
                                min={this.getCurrentDate()}
                                value={this.state.FromDate}
                                required="required"
                                placeholder="Enter FromDate"
                              />
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            <Col lg="4" md="4">
                              <Form.Label style={{ paddingTop: "8px" }}>
                                To Date
                              </Form.Label>
                            </Col>
                            <Col lg="8" md="8">
                              <Form.Control
                                type="date"
                                disabled
                                onChange={this.handleToDateChange}
                                className="form-control"
                                name="ToDate"
                                value={this.state.ToDate}
                                required="required"
                                placeholder="Enter ToDate"
                              />
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            <Col lg="4" md="4">
                              <Form.Label style={{ paddingTop: "8px" }}>
                                No. of Leaves
                              </Form.Label>
                            </Col>
                            <Col lg="8" md="8">
                              <Form.Control
                                type="number"
                                disabled
                                onChange={this.handleNoOfLeavesChange}
                                className="form-control"
                                name="NoOfLeaves"
                                value={this.state.NoOfLeaves}
                                required="required"
                                placeholder="Enter NoOfLeaves"
                              />
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            <Col lg="4" md="4">
                              <Form.Label style={{ paddingTop: "8px" }}>
                                Leave Type
                              </Form.Label>
                            </Col>
                            <Col lg="8" md="8">
                              <select
                                className="form-select"
                                name="LeaveType"
                                value={this.state.LeaveType}
                                disabled
                                onChange={this.handleLeaveTypeChange}
                              >
                                <option defaultValue> Select Leave Type</option>
                                {this.state.leavetypes.map(
                                  (api, index, idx) => (
                                    <option key={index} value={api.LeaveType}>
                                      {" "}
                                      {api.LeaveType}{" "}
                                    </option>
                                  )
                                )}
                              </select>
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            <Col lg="4" md="4">
                              <Form.Label style={{ paddingTop: "8px" }}>
                                Remarks
                              </Form.Label>
                            </Col>
                            <Col lg="8" md="8">
                              <Form.Control
                                disabled
                                as="textarea"
                                onChange={this.handleDecisionChange}
                                className="form-control"
                                name="remarks"
                                value={this.state.remarks}
                                required="required"
                                placeholder="Enter Remarks"
                                rows={3}
                              />
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            <Col lg="4" md="4">
                              <Form.Label style={{ paddingTop: "8px" }}>
                                Decision
                              </Form.Label>
                            </Col>
                            <Col lg="8" md="8">
                              <select
                                className="form-select"
                                name="Decision"
                                value={this.state.Decision}
                                onChange={this.handleDecisionChanges}
                              >
                                <option>Select</option>
                                <option value="Approved">Approved</option>
                                <option value="Rejected">Rejected</option>
                              </select>
                            </Col>
                          </Row>
                          <br />
                          <Row>
                            <Col lg="4" md="4">
                              <Form.Label style={{ paddingTop: "8px" }}>
                                Decision Remarks
                              </Form.Label>
                            </Col>
                            <Col lg="8" md="8">
                              <Form.Control
                                as="textarea"
                                onChange={this.handleDecisionRemarksChange}
                                className="form-control"
                                name="DecisionRemarks"
                                value={this.state.DecisionRemarks}
                                required="required"
                                placeholder="Enter DecisionRemarks"
                                rows={3}
                              />
                            </Col>
                          </Row>
                          <br />
                          <div style={{ float: "right" }}>
                            <Button
                              className="btn btn-success me-2 mt-5"
                              variant="success"
                              onClick={this.WorkdeskupdateHandler}
                            >
                              Update
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </Offcanvas.Body>
                  </Offcanvas>
                )}
              </div>

              <br />
              <div>
                {" "}
                {this.state.isEditingone && (
                  // Edit Offcanvas
                  <Offcanvas
                    show={this.state.isEditingone}
                    onHide={() => this.setState({ isEditingone: false })}
                    {...this.props}
                    style={{ width: "500px", height: "auto" }}
                    placement="end"
                    backdrop="true"
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>
                        Edit Attendance Regularization Requests
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <div className="form-horizontal form-label-left input_mask">
                        <Form className="form-horizontal form-label-left input_mask">
                          <div className="container">
                            <div className="row">
                              <div className="col mt-5">
                                <div>
                                  <Calendar
                                    onChange={this.handleReguralizeDateChange}
                                    value={this.state.aDate}
                                    maxDate={new Date()}
                                    // minDate={past60Days}
                                    defaultValue={this.state.date}
                                    //tileDisabled={this.isDateDisabledOne}
                                    tileClassNameOne={this.tileClassNameOne}
                                    tileContent={({ date, view }) => {
                                      // Display holiday purpose on hover for month view
                                      if (view === "month") {
                                        const year = date.getFullYear();
                                        const month = date.getMonth() + 1; // Months are zero-based
                                        const day = date.getDate();
                                        const dateString = `${year}-${
                                          month < 10 ? "0" + month : month
                                        }-${day < 10 ? "0" + day : day}`;
                                        const holiday =
                                          this.state.Holidays.find(
                                            (holiday) =>
                                              holiday.Date === dateString
                                          );

                                        if (holiday) {
                                          return (
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip id="tooltip">
                                                  {holiday.Festival}
                                                </Tooltip>
                                              }
                                            >
                                              <span className="d-inline-block holiday-tooltip">
                                                {holiday.Festival}
                                              </span>
                                            </OverlayTrigger>
                                          );
                                        } else {
                                          const lastDayOfMonth = new Date(
                                            year,
                                            month,
                                            0
                                          ).getDate();
                                          const isSaturday =
                                            date.getDay() === 6; // Saturday is the 6th day of the week (zero-based)
                                          const isLastSaturday =
                                            isSaturday &&
                                            day > lastDayOfMonth - 7;

                                          if (isLastSaturday) {
                                            return <span></span>;
                                          }
                                        }
                                      }
                                    }}
                                  />
                                  <Form.Group className="form-group">
                                    <Form.Label className="control-label">
                                      Please Enter Regularize note
                                    </Form.Label>
                                    <Form.Control
                                      as="textarea"
                                      name="reason"
                                      value={this.state.reason}
                                      rows={5}
                                    />
                                  </Form.Group>
                                </div>
                              </div>
                              <div className="mt-5">
                                <Button
                                  variant="danger"
                                  type="submit"
                                  style={{
                                    marginRight: "5px",
                                    marginTop: "20px",
                                  }}
                                  onClick={(e) => this.handleSubmit(e, "3")}
                                >
                                  Reject
                                </Button>
                                &nbsp;
                                <Button
                                  variant="success"
                                  type="submit"
                                  style={{ float: "right" }}
                                  onClick={(e) => this.handleSubmit(e, "4")}
                                >
                                  Approve
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </Offcanvas.Body>
                  </Offcanvas>
                )}
              </div>
              <br />
              <div>
                <div>
                  <h3 className="logoskybluecolor">Leave Request</h3>
                  <br />
                  {/* searchbar,pagination,filter */}
                  <input
                    style={{ float: "left", marginBottom: "10px" }}
                    type="text"
                    onChange={this.handleFilter}
                    placeholder="Search..."
                  />{" "}
                  &nbsp;
                  <DataTable
                    data={paginatedData}
                    columns={this.state.tableClms}
                    pagination
                    paginationServer
                    paginationTotalRows={
                      this.state.filteredData
                        ? this.state.filteredData.length
                        : this.state.workdesk.length
                    }
                    // paginationTotalRows={this.state.insurance.length}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                    paginationPerPage={this.state.itemsPerPage}
                    keyField="Leaverequestapprovals_Id"
                    className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                  />
                </div>
              </div>
              <br />
              <div>
                <div>
                  <h3 className="logoskybluecolor">
                    Attendance Regularization Requests
                  </h3>
                  <br />
                  {/* searchbar,pagination,filter */}
                  <input
                    style={{ float: "left", marginBottom: "10px" }}
                    type="text"
                    onChange={this.handleFilterOne}
                    placeholder="Search..."
                  />{" "}
                  &nbsp;
                  <DataTable
                    data={paginatedDataone}
                    columns={this.state.tableClmsOne}
                    pagination
                    paginationServer
                    paginationTotalRows={
                      this.state.filteredDataOne
                        ? this.state.filteredDataOne.length
                        : this.state.raddendance.length
                    }
                    // paginationTotalRows={this.state.insurance.length}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChangeone}
                    paginationRowsPerPageOptions={[
                      10, 20, 30, 50, 60, 70, 80, 90, 100,
                    ]}
                    paginationPerPage={this.state.itemsPerPage}
                    keyField="Leaverequestapprovals_Id"
                    className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Button, Col, Row, Card, Form } from "react-bootstrap";
import configData from "../config.json";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./GoProposerinfo.css";
export default class GoNomineeDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forgotPasswordCondition: false,
      chpiId: sessionStorage.getItem("chpiId"),
      proposalId: sessionStorage.getItem("proposalId"),
      baseproductId: sessionStorage.getItem("baseproductId"),
      cqrId: sessionStorage.getItem("cqrId"),
      occupation: [],
      nominess: [],
      tab1Data: {
        firstName: "",
        lastName: "",
        dob: "",
        occupation: "",
        relation: "",
        mobile: "",
        gender: "",
      },
      minDate: new Date(),
      maxDate: new Date(),
    };
  }
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  handleInputChange = (tab, field, value) => {
    if (tab === "tab1Data" && field === "firstName") {
      // Allow letters and spaces in the name, including an empty value
      const lettersAndSpacesRegex = /^[A-Za-z\s]*$/;

      if (!lettersAndSpacesRegex.test(value.trim())) {
        toast.error("Not allowed special characters and Numbers.");
        return; // Stop further processing for this field
      }
    }
    if (tab === "tab1Data" && field === "lastName") {
      // Allow letters and spaces in the name, including an empty value
      const lettersAndSpacesRegex = /^[A-Za-z\s]*$/;

      if (!lettersAndSpacesRegex.test(value.trim())) {
        toast.error("Not allowed special characters and Numbers.");
        return; // Stop further processing for this field
      }
    }
    this.setState((prevState) => ({
      [tab]: {
        ...prevState[tab],
        [field]: value,
      },
    }));
  };

  validateForm() {
    const { tab1Data } = this.state;
    const currentDate = new Date();
    const maxDate = new Date();
    maxDate.setFullYear(currentDate.getFullYear() - 98);
    let formIsValid = true;
    if (!tab1Data["firstName"]) {
      formIsValid = false;
      toast.error("Please Enter Nominee Name.");
    } else if (tab1Data["firstName"].length > 50) {
      formIsValid = false;
      toast.error("Please Enter Name Should 50 Characters.");
    } else if (!tab1Data["lastName"]) {
      formIsValid = false;
      toast.error("Please Enter Nominee Last Name.");
    } else if (tab1Data["lastName"].length > 50) {
      formIsValid = false;
      toast.error("Please Enter Last Name Should 50 Characters.");
    } else if (!tab1Data["dob"]) {
      formIsValid = false;
      toast.error("Please Enter Date of Birth.");
    } else {
      const dob = new Date(tab1Data["dob"]);

      // Check if DOB is more than 99 years ago or if it's tomorrow's date
      if (dob >= currentDate || dob <= maxDate) {
        formIsValid = false;
        toast.error(
          "Invalid Date of Birth. Age must be below 98 years and not tomorrow's date."
        );
      }
    }
    if (!tab1Data["gender"]) {
      formIsValid = false;
      toast.error("Please Select Gender.");
    } else if (!tab1Data["relation"]) {
      formIsValid = false;
      toast.error("Please Enter Relation Of Nominee.");
    }

    return formIsValid;
  }

  handleSubmitBackClick = (e) => {
    window.location.href = "/gomedicals";
  };
  handleSubmitNextClick = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      var fd = new FormData();
      fd.append("proposalId", this.state.proposalId);
      fd.append("name", this.state.tab1Data.firstName);
      fd.append("lastName", this.state.tab1Data.lastName);
      fd.append("dob", this.state.tab1Data.dob);
      fd.append("occupation", this.state.tab1Data.occupation);
      fd.append("gender", this.state.tab1Data.gender);
      fd.append("relation", this.state.tab1Data.relation);
      fd.append("mobile", this.state.tab1Data.mobile);
      fetch(configData.api_url + "god_Leads/updatenominee/", {
        method: "POST",
        headers: {},
        mode: "cors",
        body: fd, // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            window.location.href = "/goaddons";
          }
        });
      console.log(this.handleSubmitNextClick);
    }
  };
  //Data receive
  componentDidMount() {
    //this.apisfetchHandler();
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 18);
    const maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 98);
    this.setState({ minDate: maxDate, maxDate: minDate });
    this.HealthoccupationsfetchHandler();
    this.HealthnomineerelationsfetchHandler();
  }

  HealthnomineerelationsfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "C_Healthnomineerelations/listByid/", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ nominess: data.list });
        }
      });
  };
  HealthoccupationsfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "C_Healthoccupations/listByid/", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ occupation: data.list });
        }
      });
  };
  render() {
    const previousYearStart = new Date();
    previousYearStart.setFullYear(previousYearStart.getFullYear() - 100);
    previousYearStart.setMonth(0); // Set the month to January
    previousYearStart.setDate(1); // Set the date to the first day of January
    // Example end year
    const endYear = new Date();
    endYear.setFullYear(endYear.getFullYear() + 10);
    return (
      <div>
        <Row id="MainRoOne">
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-person-fill"
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Proposer Info</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-person-vcard-fill"
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Member Info</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        onClick={this.handleSubmitBackClick}
                        className="bi bi-heart-pulse"
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6
                        style={{ color: "#3d5a95" }}
                        onClick={this.handleSubmitBackClick}
                      >
                        Medicals
                      </h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-pencil-square"
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Nominee Details</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span className="bi bi-arrow-right" id="inActiveArrow"></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="inActiveTab">
                      <span
                        className="bi bi-clipboard-plus"
                        id="normalIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#98968c" }}>Addon's</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}></Col>
            </Row>
          </Col>
        </Row>
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">First Name of Nominee *</th>
                      <th scope="col">Last Name of Nominee *</th>
                      <th scope="col">Date of Birth *</th>
                      <th scope="col">Profession of Nominee *</th>
                      <th scope="col">Gender *</th>
                      <th scope="col">Relation with Proposer *</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr key="nominee">
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          id="firstName "
                          name="firstName"
                          value={this.state.tab1Data.firstName}
                          onChange={(e) =>
                            this.handleInputChange(
                              "tab1Data",
                              "firstName",
                              e.target.value
                            )
                          }
                          aria-describedby="firstName"
                          placeholder="Nominee First Name"
                        />
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          id="name "
                          name="lastName"
                          value={this.state.tab1Data.lastName}
                          onChange={(e) =>
                            this.handleInputChange(
                              "tab1Data",
                              "lastName",
                              e.target.value
                            )
                          }
                          aria-describedby="lastName"
                          placeholder="Nominee Last Name"
                        />
                      </td>
                      <td>
                        <DatePicker
                          dateFormat="dd/MM/yyyy"
                          showTimeSelect={false}
                          selected={this.state.tab1Data.dob}
                          onChange={(date) =>
                            this.handleInputChange(
                              "tab1Data",
                              "dob",
                              date // Date object
                            )
                          }
                          className="form-control"
                          id="dob"
                          placeholderText="Select Date of Birth"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          minDate={this.state.minDate} // Set the minimum selectable date
                          maxDate={this.state.maxDate}
                        />
                      </td>

                      <td>
                        <Form.Group className="form-group">
                          <Form.Select
                            name="occupation"
                            className="form-control"
                            onChange={(e) =>
                              this.handleInputChange(
                                "tab1Data",
                                "occupation",
                                e.target.value
                              )
                            }
                            id="occupation"
                            // value={this.state.tab1Data.emailtype}
                            aria-describedby="occupation"
                            aria-label="Default select example"
                          >
                            <option value="">Select</option>
                            {this.state.occupation.map(
                              (occupation, index, idx) => (
                                <option
                                  key={index}
                                  value={occupation.occupation}
                                >
                                  {occupation.occupation}
                                </option>
                              )
                            )}
                          </Form.Select>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group className="form-group">
                          <Form.Select
                            name="gender"
                            className="form-control"
                            onChange={(e) =>
                              this.handleInputChange(
                                "tab1Data",
                                "gender",
                                e.target.value
                              )
                            }
                            id="gender"
                            // value={this.state.tab1Data.relation}
                            aria-describedby="gender"
                            aria-label="Default select example"
                          >
                            <option>Select</option>
                            <option value="MALE">Male</option>
                            <option value="FEMALE">Female</option>
                            <option value="UNKNOWN">Unknown</option>
                          </Form.Select>
                        </Form.Group>
                      </td>
                      <td>
                        <Form.Group className="form-group">
                          <Form.Select
                            name="relation"
                            className="form-control"
                            onChange={(e) =>
                              this.handleInputChange(
                                "tab1Data",
                                "relation",
                                e.target.value
                              )
                            }
                            id="relation"
                            value={this.state.tab1Data.relation}
                            aria-describedby="relation"
                            aria-label="Default select example"
                          >
                            <option>Select</option>
                            {this.state.nominess.map((quote, index) => (
                              <option key={index} value={quote.nomineecode}>
                                {quote.nomineerelation}
                              </option>
                            ))}
                          </Form.Select>
                        </Form.Group>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <br />
        <Row className="mt-5">
          <Col>
            {" "}
            <Button
              id="getQuotes4"
              onClick={this.handleSubmitBackClick}
              type="submit"
              style={{ float: "left" }}
            >
              Back
            </Button>
          </Col>
          <Col>
            {" "}
            <Button
              id="getQuotes4"
              onClick={this.handleSubmitNextClick}
              type="submit"
              style={{ float: "right" }}
            >
              Next
            </Button>
          </Col>
          {/* </Form> */}
        </Row>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Button, Container, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";

export default class Irdaproductname extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.irdaproductname_Id,
          sortable: true,
          cell: (row) => <span>{row.irdaproductname_Id}</span>,
        },
        {
          name: "Product Name",
          selector: (row, i) => row.productName,
          sortable: true,
          cell: (row) => <span>{row.productName}</span>,
        },
        {
          name: "IRDA Categorisation",
          selector: (row, i) => row.irdaCategorisation,
          sortable: true,
          cell: (row) => <span>{row.irdaCategorisation}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen"
                style={{ marginRight: "3px" }}
              ></Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      productName: "",
      irdaCategorisation: "",
      irdaproductname_Id: "",
      isEditing: false,
    };
  }
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({
      show: true,
      productName: "",
      irdaCategorisation: "",
      irdaproductname_Id: "",
    });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  // Edit mode
  handleEnterEditMode = (row) => {
    // this.setState({ cData: { ...row }, isEditing: true });
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Irdaproductname/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({
            irdaproductname_Id: row.irdaproductname_Id,
            productName: row.productName,
            irdaCategorisation: row.irdaCategorisation,
            isEditing: true,
          });
        }
      });
  };
  // Update
  IrdaproductUpdate = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("irdaproductname_Id", this.state.irdaproductname_Id);
    formData.append("productName", this.state.productName);
    formData.append("irdaCategorisation", this.state.irdaCategorisation);

    fetch(configData.api_url + "C_Irdaproductname/update", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.GodigithealthpinmasterfetchHandler();
          this.handleOffcanvasClose();
        }
      });
  };
  IrdaproductnameeditHandler = (e) => {
    e.preventDefault();
    // const updateFormData = {...editFormData};
    // updateFormData['Irdaproductname_Id'] = e.target.parentElement.getAttribute("id");
    // setEditFormData(updateFormData);
    // setEdit(true);
  };

  IrdaproductnamefetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Irdaproductname/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  IrdaproductnamesubmitHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("productName", this.state.productName);
    formData.append("irdaCategorisation", this.state.irdaCategorisation);

    fetch(configData.api_url + "C_Irdaproductname/add", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.IrdaproductnamefetchHandler();
          this.handleOffcanvasClose();
          this.setState({ productName: "", irdaCategorisation: "" });
        }
      });
  };
  IrdaproductnameupdateHandler = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("productName", this.state.productName);
    formData.append("irdaCategorisation", this.state.irdaCategorisation);

    fetch(configData.api_url + "C_Irdaproductname/update", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.IrdaproductnamefetchHandler();
          this.handleOffcanvasClose();
        }
      });
  };

  componentDidMount() {
    this.IrdaproductnamefetchHandler();
  }
  handleproductNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ productName: fieldValue });
  };
  handleirdaCategorisationChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ irdaCategorisation: fieldValue });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
    console.log(`Editing row with ID ${row.id}`);
    // implement your edit logic here
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("irdaproductname_Id", row.irdaproductname_Id);
    fetch(configData.api_url + "C_Irdaproductname/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.IrdaproductnamefetchHandler();
        }
      });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div className="page-title">
        <div className="row">
          <div className="title_left">
            <h2 style={{ textAlign: "start" }}>IRDA Product Name</h2>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                                   dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "5px", marginTop: "20px" }}
                    >
                      <Button
                        variant="primary"
                        className="bi bi-plus "
                        onClick={this.handleOffcanvasShow}
                      >
                        New
                      </Button>
                      <Offcanvas
                        show={this.state.show}
                        onHide={this.handleOffcanvasClose}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            Irda Product Name Page
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                            <Row className="divstyle">
                              <Row>
                                <Form>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Product Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleproductNameChange}
                                        className="form-control"
                                        name="productName"
                                        value={this.state.productName}
                                        required="required"
                                        placeholder="Enter productName"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.productName}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        IRDA Categorisation
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleirdaCategorisationChange
                                        }
                                        className="form-control"
                                        name="irdaCategorisation"
                                        value={this.state.irdaCategorisation}
                                        required="required"
                                        placeholder="Enter irdaCategorisation"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.irdaCategorisation
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />
                                </Form>
                              </Row>
                            </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={this.IrdaproductnamesubmitHandler}
                                  style={{
                                    marginTop: "20px",
                                  }}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                      {/*offcanvas is over */}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <br />
                    <Row>
                      {this.state.isEditing && (
                        <Offcanvas
                          show={this.state.isEditing}
                          onHide={() => this.setState({ isEditing: false })}
                          {...this.props}
                          style={{ width: "500px" }}
                          placement="end"
                          backdrop="true"
                        >
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                              Irdaproductname page
                            </Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                            <Container
                              style={{ overflowY: "auto", height: "auto" }}
                            >
                              <Row className="divstyle">
                                <Row>
                                  <Form>
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Product Name
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={
                                            this.handleproductNameChange
                                          }
                                          className="form-control"
                                          name="productName"
                                          value={this.state.productName}
                                          required="required"
                                          placeholder="Enter productName"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.productName}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          IRDA Categorisation
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={
                                            this.handleirdaCategorisationChange
                                          }
                                          className="form-control"
                                          name="irdaCategorisation"
                                          value={this.state.irdaCategorisation}
                                          required="required"
                                          placeholder="Enter irdaCategorisation"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {
                                          this.state.errors.irdaCategorisation
                                        }{" "}
                                      </div>
                                    </Row>
                                    <br />
                                  </Form>
                                </Row>
                              </Row>
                              <Row>
                                <Col
                                  className="d-flex justify-content-end"
                                  style={{ marginRight: "50px" }}
                                >
                                  <Button
                                    className="button"
                                    variant="success"
                                    onClick={this.IrdaproductUpdate}
                                    style={{
                                      marginTop: "20px",
                                    }}
                                  >
                                    Update
                                  </Button>
                                </Col>
                              </Row>
                            </Container>
                          </Offcanvas.Body>
                        </Offcanvas>
                      )}
                      {""}
                    </Row>
                    <div style={{ marginRight: "5px" }}>
                      {/* searchbar,pagination,filter */}
                      <input
                        type="text"
                        style={{ float: "left", marginBottom: "10px" }}
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        paginationRowsPerPageOptions={[
                          10, 20, 30, 50, 60, 70, 80, 90, 100,
                        ]}
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

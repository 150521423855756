import React, { Component } from "react";
import { Button, Container, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";

export default class Widgetsrightstable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.widgetsrightstable_Id,
          sortable: true,
          cell: (row) => <span>{row.widgetsrightstable_Id}</span>,
        },
        {
          name: "Widget Id",
          selector: (row, i) => row.widgetId,
          sortable: true,
          cell: (row) => <span>{row.widgetId}</span>,
        },
        {
          name: "Role Id",
          selector: (row, i) => row.roleId,
          sortable: true,
          cell: (row) => <span>{row.roleId}</span>,
        },
        {
          name: "User Id",
          selector: (row, i) => row.userID,
          sortable: true,
          cell: (row) => <span>{row.userID}</span>,
        },
        {
          name: "DummyOne",
          selector: (row, i) => row.dummyOne,
          sortable: true,
          cell: (row) => <span>{row.dummyOne}</span>,
        },
        {
          name: "Dummy Two",
          selector: (row, i) => row.dummyTwo,
          sortable: true,
          cell: (row) => <span>{row.dummyTwo}</span>,
        },
        {
          name: "Dummy Three",
          selector: (row, i) => row.dummyThree,
          sortable: true,
          cell: (row) => <span>{row.dummyThree}</span>,
        },
        {
          name: "Dummy Four",
          selector: (row, i) => row.dummyFourt,
          sortable: true,
          cell: (row) => <span>{row.dummyFourt}</span>,
        },
        {
          name: "Dummy Five",
          selector: (row, i) => row.dummyFive,
          sortable: true,
          cell: (row) => <span>{row.dummyFive}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                style={{ marginRight: "10px" }}
                variant="primary"
                onClick={this.handleOffcanvasShow}
                className="bi bi-pen"
              ></Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      widgetId: "",
      roleId: "",
      userID: "",
      canView: false,
      canDrilldown: false,
      departmentId: "",
      dummyOne: "",
      dummyTwo: "",
      dummyThree: "",
      dummyFourt: "",
      dummyFive: "",
    };
  }
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({
      show: true,
      widgetId: "",
      roleId: "",
      userID: "",
      canView: false,
      canDrilldown: false,
      departmentId: "",
      dummyOne: "",
      dummyTwo: "",
      dummyThree: "",
      dummyFourt: "",
      dummyFive: "",
    });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  WidgetsrightstableeditHandler = (e) => {
    e.preventDefault();
    // const updateFormData = {...editFormData};
    // updateFormData['Widgetsrightstable_Id'] = e.target.parentElement.getAttribute("id");
    // setEditFormData(updateFormData);
    // setEdit(true);
  };

  WidgetsrightstablefetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Widgetsrightstable/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  WidgetsrightstablesubmitHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("widgetId", this.state.widgetId);
    formData.append("roleId", this.state.roleId);
    formData.append("userID", this.state.userID);
    formData.append("canView", this.state.canView);
    formData.append("canDrilldown", this.state.canDrilldown);
    formData.append("departmentId", this.state.departmentId);
    formData.append("dummyOne", this.state.dummyOne);
    formData.append("dummyTwo", this.state.dummyTwo);
    formData.append("dummyThree", this.state.dummyThree);
    formData.append("dummyFourt", this.state.dummyFourt);
    formData.append("dummyFive", this.state.dummyFive);

    fetch(configData.api_url + "C_Widgetsrightstable/add", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.WidgetsrightstablefetchHandler();
          this.handleOffcanvasClose();
          this.setState({
            widgetId: "",
            roleId: "",
            userID: "",
            canView: "",
            canDrilldown: "",
            departmentId: "",
            dummyOne: "",
            dummyTwo: "",
            dummyThree: "",
            dummyFourt: "",
            dummyFive: "",
          });
        }
      });
  };
  WidgetsrightstableupdateHandler = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("widgetId", this.state.widgetId);
    formData.append("roleId", this.state.roleId);
    formData.append("userID", this.state.userID);
    formData.append("canView", this.state.canView);
    formData.append("canDrilldown", this.state.canDrilldown);
    formData.append("departmentId", this.state.departmentId);
    formData.append("dummyOne", this.state.dummyOne);
    formData.append("dummyTwo", this.state.dummyTwo);
    formData.append("dummyThree", this.state.dummyThree);
    formData.append("dummyFourt", this.state.dummyFourt);
    formData.append("dummyFive", this.state.dummyFive);

    fetch(configData.api_url + "C_Widgetsrightstable/update", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.WidgetsrightstablefetchHandler();
          this.handleOffcanvasClose();
        }
      });
  };

  componentDidMount() {
    this.WidgetsrightstablefetchHandler();
  }
  handlewidgetIdChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ widgetId: fieldValue });
  };
  handleroleIdChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ roleId: fieldValue });
  };
  handleuserIDChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ userID: fieldValue });
  };
  handlecanViewChange = (e) => {
    const isChecked = e.target.checked; // Get the checkbox state
    this.setState({ canView: isChecked });
  };

  handlecanDrilldownChange = (e) => {
    e.preventDefault();
    const isChecked = e.target.checked;
    this.setState({ canDrilldown: isChecked });
  };
  handlecanDepartmentIDChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ departmentId: fieldValue });
  };
  handledummyOneChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyOne: fieldValue });
  };
  handledummyTwoChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyTwo: fieldValue });
  };
  handledummyThreeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyThree: fieldValue });
  };
  handledummyFourtChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyFourt: fieldValue });
  };
  handledummyFiveChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyFive: fieldValue });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
    console.log(`Editing row with ID ${row.id}`);
    // implement your edit logic here
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("widgetsrightstable_Id", row.widgetsrightstable_Id);
    fetch(configData.api_url + "C_Widgetsrightstable/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.WidgetsrightstablefetchHandler();
        }
      });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div className="page-title">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <div>
                      <h3
                        className="logoskybluecolor"
                        style={{ textAlign: "start" }}
                      >
                        Widgets Rights Table
                      </h3>
                    </div>
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "5px", marginTop: "20px" }}
                    >
                      <Offcanvas
                        show={this.state.show}
                        onHide={this.handleOffcanvasClose}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            Widgetsrightstable page
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                            <Row className="divstyle">
                              <Row>
                                <Form>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Widget Id
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlewidgetIdChange}
                                        className="form-control"
                                        name="widgetId"
                                        value={this.state.widgetId}
                                        required="required"
                                        placeholder="Enter widgetId"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.widgetId}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Role Id
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleroleIdChange}
                                        className="form-control"
                                        name="roleId"
                                        value={this.state.roleId}
                                        required="required"
                                        placeholder="Enter roleId"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.roleId}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        User Id
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleuserIDChange}
                                        className="form-control"
                                        name="userID"
                                        value={this.state.userID}
                                        required="required"
                                        placeholder="Enter userID"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.userID}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label>Can View</Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Check
                                        type="checkbox"
                                        onChange={this.handlecanViewChange}
                                        className="form-check"
                                        name="canView"
                                        checked={this.state.canView}
                                      />
                                    </Col>
                                  </Row>

                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label>Can Drilldown</Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Check
                                        type="checkbox"
                                        onChange={this.handlecanDrilldownChange}
                                        className="form-check-view"
                                        name="canDrilldown"
                                        checked={this.state.canDrilldown}
                                      />
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Department Id
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlecanDepartmentIDChange
                                        }
                                        className="form-control"
                                        name="departmentId"
                                        value={this.state.departmentId}
                                        required="required"
                                        placeholder="Enter Department Id"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.userID}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        DummyOne
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyOneChange}
                                        className="form-control"
                                        name="dummyOne"
                                        value={this.state.dummyOne}
                                        required="required"
                                        placeholder="Enter dummyOne"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyOne}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Two
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyTwoChange}
                                        className="form-control"
                                        name="dummyTwo"
                                        value={this.state.dummyTwo}
                                        required="required"
                                        placeholder="Enter dummyTwo"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyTwo}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Three
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyThreeChange}
                                        className="form-control"
                                        name="dummyThree"
                                        value={this.state.dummyThree}
                                        required="required"
                                        placeholder="Enter dummyThree"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyThree}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Four
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyFourtChange}
                                        className="form-control"
                                        name="dummyFourt"
                                        value={this.state.dummyFourt}
                                        required="required"
                                        placeholder="Enter dummyFourt"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyFourt}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Five
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyFiveChange}
                                        className="form-control"
                                        name="dummyFive"
                                        value={this.state.dummyFive}
                                        required="required"
                                        placeholder="Enter dummyFive"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyFive}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                </Form>
                              </Row>
                            </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={this.WidgetsrightstablesubmitHandler}
                                  style={{
                                    marginTop: "20px",
                                  }}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                      {/*offcanvas is over */}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <div style={{ marginRight: "5px" }}>
                      {/* searchbar,pagination,filter */}
                      <input
                        type="text"
                        style={{ float: "left", marginBottom: "10px" }}
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <Button
                        variant="primary"
                        className="bi bi-plus "
                        style={{ float: "right" }}
                        onClick={this.handleOffcanvasShow}
                      >
                        New
                      </Button>
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Card, Row, Col, Form, Button, Offcanvas } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import configData from "../config.json";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";

export default class Possctwp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      data: [],
      currentPage: 1,
      itemsPerPage: 10,
      pfName: "",
      plName: "",
      pmobilenumber: "",
      panPath: "",
      aadharPath: "",
      pospId: "",
      addressLine: "",
      pincode: "",
      areaCd: "",
      cityCd: "",
      stateCd: "",
      irdabranch: "",
      pannumber: "",
      aadharnumber: "",
      emailid: "",
      sscPath: "",
      acityCd: "",
      accName: "",
      accNO: "",
      bankName: "",
      ifscCode: "",
      branchCode: "",
      chequePath: "",
      fullName: "",
      amobilenumber: "",
      aemailid: "",
      apincode: "",
      aareaCd: "",
      pGender: "",
      pDob: "",
      pPhoto: "",
      createdBy: 0,
      aAddressLine: "",
      astateCd: "",
      remarks1: "",
      error: "",
      tableClms: [
        {
          name: "S.NO",
          width: "75px",
          selector: (row, i) => row.pospId,
          sortable: true,
          cell: (row) => <span>{row.pospId}</span>,
        },
        {
          name: "First Name",
          id: "pfName",
          selector: (row, i) => row.pfName,
          sortable: true,
          cell: (row) => <span>{row.pfName}</span>,
        },
        {
          name: "Last Name",
          id: "plName",
          selector: (row, i) => row.plName,
          sortable: true,
          cell: (row) => <span>{row.plName}</span>,
        },

        {
          name: "Mobile Number",
          id: "pmobilenumber",
          selector: (row, i) => row.pmobilenumber,
          sortable: true,
          cell: (row) => <span>{row.pmobilenumber}</span>,
        },
        {
          name: "PAN",
          id: "pannumber",
          selector: (row, i) => row.pannumber,
          sortable: true,
          cell: (row) => <span>{row.pannumber}</span>,
        },

        {
          name: "Aadhar Number",
          id: "aadharnumber",
          selector: (row, i) => row.aadharnumber,
          sortable: true,
          cell: (row) => <span>{row.aadharnumber}</span>,
        },
        {
          name: "Email-Id",
          id: "emailid",
          selector: (row, i) => row.emailid,
          sortable: true,
          cell: (row) => <span>{row.emailid}</span>,
        },
        {
          name: "Address",
          id: "addressLine",
          selector: (row, i) => row.addressLine,
          sortable: true,
          cell: (row) => <span>{row.addressLine}</span>,
        },

        {
          name: "Pincode",
          id: "pincode",
          selector: (row, i) => row.pincode,
          sortable: true,
          cell: (row) => <span>{row.pincode}</span>,
        },
        {
          name: "Area",
          id: "areaCd",
          selector: (row, i) => row.areaCd,
          sortable: true,
          cell: (row) => <span>{row.areaCd}</span>,
        },

        {
          name: "State",
          id: "stateCd",
          selector: (row, i) => row.stateCd,
          sortable: true,
          cell: (row) => <span>{row.stateCd}</span>,
        },
        {
          name: "Irda Branch",
          id: "irdabranch",
          selector: (row, i) => row.irdabranch,
          sortable: true,
          cell: (row) => <span>{row.irdabranch}</span>,
        },
        {
          name: "Bank Name",
          id: "bankName",
          selector: (row, i) => row.bankName,
          sortable: true,
          cell: (row) => <span>{row.bankName}</span>,
        },
        {
          name: "Account Number",
          id: "accNO",
          selector: (row, i) => row.accNO,
          sortable: true,
          cell: (row) => <span>{row.accNO}</span>,
        },
        {
          name: "IFSC Code",
          id: "ifscCode",
          selector: (row, i) => row.ifscCode,
          sortable: true,
          cell: (row) => <span>{row.ifscCode}</span>,
        },
        {
          name: "Branch Code",
          id: "branchCode",
          selector: (row, i) => row.branchCode,
          sortable: true,
          cell: (row) => <span>{row.branchCode}</span>,
        },
        {
          name: "Alernate Person Full Name",
          id: "fullName",
          selector: (row, i) => row.fullName,
          sortable: true,
          cell: (row) => <span>{row.fullName}</span>,
        },
        {
          name: "IFSC Code",
          id: "ifscCode",
          selector: (row, i) => row.ifscCode,
          sortable: true,
          cell: (row) => <span>{row.ifscCode}</span>,
        },
        {
          name: "Alternate Person Mobile Number",
          id: "amobilenumber",
          selector: (row, i) => row.amobilenumber,
          sortable: true,
          cell: (row) => <span>{row.amobilenumber}</span>,
        },
        {
          name: "Alternate Person Email Id",
          id: "aemailid",
          selector: (row, i) => row.aemailid,
          sortable: true,
          cell: (row) => <span>{row.aemailid}</span>,
        },
        {
          name: "State",
          id: "stateCd",
          selector: (row, i) => row.stateCd,
          sortable: true,
          cell: (row) => <span>{row.stateCd}</span>,
        },
        {
          name: "Alternate Person Address",
          id: "aAddressLine",
          selector: (row, i) => row.aAddressLine,
          sortable: true,
          cell: (row) => <span>{row.aAddressLine}</span>,
        },
        {
          name: "Alternate Person Pincode",
          id: "apincode",
          selector: (row, i) => row.apincode,
          sortable: true,
          cell: (row) => <span>{row.apincode}</span>,
        },
        {
          name: "Alternate Person Area",
          id: "aareaCd",
          selector: (row, i) => row.aareaCd,
          sortable: true,
          cell: (row) => <span>{row.aareaCd}</span>,
        },
        {
          name: "Alternate Person City",
          id: "acityCd",
          selector: (row, i) => row.acityCd,
          sortable: true,
          cell: (row) => <span>{row.acityCd}</span>,
        },

        {
          name: "IRDA Office",
          id: "irdabranch",
          selector: (row, i) => row.irdabranch,
          sortable: true,
          cell: (row) => <span>{row.irdabranch}</span>,
        },

        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-box-arrow-in-up"
                style={{ marginRight: "3px" }}
              ></Button>
              {/* <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button> */}
            </div>
          ),
        },
      ],
    };
  }

  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };

  handleEnterEditMode = (row) => {
    // this.setState({ cData: { ...row }, isEditing: true });
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    this.setState({
      pfName: row.pfName,
      plName: row.plName,
      pGender: row.pGender,
      pDob: row.pDob,
      pmobilenumber: row.pmobilenumber,
      pannumber: row.pannumber,
      aadharnumber: row.aadharnumber,
      emailid: row.emailid,
      panPath: row.panPath,
      aadharPath: row.aadharPath,
      chequePath: row.chequePath,
      addressLine: row.addressLine,
      pincode: row.pincode,
      branchCode: row.branchCode,
      areaCd: row.areaCd,
      cityCd: row.cityCd,
      stateCd: row.stateCd,
      irdabranch: row.irdabranch,
      sscPath: row.sscPath,
      accName: row.accName,
      apincode: row.apincode,
      aemailid: row.aemailid,
      fullName: row.fullName,
      amobilenumber: row.amobilenumber,
      aareaCd: row.aareaCd,
      accNO: row.accNO,
      bankName: row.bankName,
      ifscCode: row.ifscCode,
      acityCd: row.acityCd,
      aAddressLine: row.aAddressLine,
      astateCd: row.astateCd,
      pospId: row.pospId,
      pPhoto: row.pPhoto,
      createdBy: row.createdBy,

      isEditing: true,
    });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ isEditing: false });
  };
  //   handleDelete = (row, index) => {
  //   var formData = new FormData();
  //   formData.append("sId", this.state.sId);
  //   formData.append("uId", this.state.uId);
  //   const pospId = row.pospId;
  //   // console.log(e.target.parentElement);
  //   formData.append("pospId", pospId);
  //   fetch(configData.api_url + "posps/delete", {
  //     // Enter your IP address here
  //     method: "POST",
  //     //headers :{ 'Content-Type' : 'application/json'},
  //     mode: "cors",
  //     body: formData, // body data type must match "Content-Type" header
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       this.showToast(data.message, data.status);
  //       if (data.status === "success") {
  //         this.PospfetchHandler();
  //       }
  //     });
  //   //implement your delete logic here
  // };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: "", // Clear the corresponding error when the field value changes
      },
    });
  };

  componentDidMount() {
    this.handleretrivedata();
  }
  //Sension Approval
  handleretrivedata = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("pospId", this.state.pospId);

    fetch(configData.api_url + "posps/all/", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((rsdata) => {
        console.log("API Response:", rsdata);
        this.showToast(rsdata.message, rsdata.status);
        if (rsdata.status === "success") {
          const list = rsdata.list;
          const data = [];
          list.forEach((element) => {
            if (element.pStatus === "4") {
              console.log(element.pStatus);
              data.push(element);
            }
          });
          this.setState({ data: data });
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        this.showToast("Failed to Fetch Data", "error");
      });
  };
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleReject = (e, status) => {
    e.preventDefault();
    const { remarks1 } = this.state;
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("pospId", this.state.pospId);
    formData.append("remarks1", remarks1);
    formData.append("pStatus", status);
    formData.append("createdBy", this.state.createdBy);
    fetch(configData.api_url + "posps/updateStatus/", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("API Response:", data);
        this.showToast(data.message, data.status);
        // this.handleOffcanvasClose();
        if (data.status === "success") {
          // Reset form fields upon successful submission
          this.setState({ remarks1: "" });
          this.handleOffcanvasClose();
          this.handleretrivedata();
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        this.showToast("Failed to submit form", "error");
      });
  };
  handleApprove = (e, status) => {
    e.preventDefault();
    const { remarks1 } = this.state;
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("pospId", this.state.pospId);
    formData.append("remarks1", remarks1);
    formData.append("pStatus", status);
    formData.append("createdBy", this.state.createdBy);
    fetch(configData.api_url + "posps/updateStatus/", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("API Response:", data);
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          // Reset form fields upon successful submission
          this.setState({ remarks1: "" });
          this.handleOffcanvasClose();
          this.handleretrivedata();
        }
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((error) => {
        console.error("API Error:", error);
        this.showToast("Failed to submit form", "error");
      });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );
    if (this.state.pStatus === "4") {
      return <div>You do not have access to this page.</div>;
    }

    return (
      <div>
        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
            <div className="x_panel">
              <div className="x_content">
                <div className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                  <Row>
                    <div className="" style={{ float: "left" }}>
                      <h3 className="logoskybluecolor">
                        POSP Details Verfication
                      </h3>
                    </div>
                    <Col>
                      {this.state.isEditing && (
                        <Offcanvas
                          show={this.state.isEditing}
                          onHide={() => this.setState({ isEditing: false })}
                          {...this.props}
                          style={{ width: "500px" }}
                          placement="end"
                          backdrop="true"
                        >
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                              {" "}
                              <h3 className="logoskybluecolor">
                                POSP Details Verfication
                              </h3>
                            </Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                            <Card>
                              <Card.Body>
                                <Row>
                                  <Col lg="6" md="6" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          First Name
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          //  onChange={this.handleChange}
                                          className="form-control"
                                          name="pfName"
                                          value={this.state.pfName}
                                          required="required"
                                          placeholder="Enter First Name"
                                          disabled={true}
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="6" md="6" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Last Name
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          //  onChange={this.handleChange}
                                          className="form-control"
                                          name="plName"
                                          value={this.state.plName}
                                          required="required"
                                          placeholder="Enter Last Name"
                                          disabled={true}
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="6" md="6" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Gender{" "}
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          //  onChange={this.handleChange}
                                          className="form-control"
                                          name="pGender"
                                          value={this.state.pGender}
                                          required="required"
                                          disabled={true}
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="6" md="6" sm="12">
                                    <Form>
                                      <Form.Group
                                        className=" mb-3"
                                        controlId="formBasicEmail"
                                        name="irdabranch"
                                        onChange={this.handleChange}
                                      >
                                        <Form.Label>D.O.B</Form.Label>

                                        <DatePicker
                                          dateFormat="dd/MM/yyyy"
                                          showTimeSelect={false}
                                          selected={this.state.pDob}
                                          // onChange={(date) => this.handleDChange(date)}
                                          className="form-control"
                                          id="pDob"
                                          disabled={true}
                                          placeholderText="Select Date of Birth"
                                          peekNextMonth
                                          showMonthD
                                          ropdown
                                          showYearDropdown
                                          // minDate={previousYearStart} // Set the minimum selectable date
                                          // maxDate={endYear} // Set the maximum selectable date
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="6" md="6" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Mobile Number
                                        </Form.Label>
                                        <Form.Control
                                          onKeyDown={this.onKeyDown}
                                          id="pmobilenumber"
                                          type="text"
                                          name="pmobilenumber"
                                          value={this.state.pmobilenumber}
                                          //  onChange={this.handleChange}
                                          required="required"
                                          placeholder="Enter Mobile Number"
                                          maxLength={10}
                                          disabled={true}
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="6" md="6" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className=" mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Email Id
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="emailid"
                                          value={this.state.emailid}
                                          //  onChange={this.handleChange}
                                          required="required"
                                          placeholder="Enter Email-Id"
                                          disabled={true}
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Aadhar Number
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="aadharnumber"
                                          value={this.state.aadharnumber}
                                          required="required"
                                          placeholder="Enter Aadhar Number"
                                          // onChange={this.handleChange}
                                          disabled={true}
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          PAN Number
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          //  className="form-control"
                                          name="pannumber"
                                          value={this.state.pannumber}
                                          // onChange={this.handlePANNumberChange}
                                          required="required"
                                          disabled={true}
                                          placeholder="Enter PAN Number"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                </Row>
                                <hr />
                                <Row>
                                  <Col lg="12" md="12" sm="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Pan Card Upload
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          disabled={true}
                                          accept=".pdf,.jpg,jpeg,.png"
                                          // onChange={this.handlePanChange}
                                          className="form-control"
                                          name="panPath"
                                          value={this.state.panPath}
                                          required="required"
                                        />
                                      </Form.Group>
                                    </Form>
                                    {this.state.panPath && (
                                      <div>
                                        <Link
                                          onClick={() =>
                                            this.openPDFInNewTab(
                                              this.state.panPath
                                            )
                                          }
                                        >
                                          View Pdf
                                        </Link>
                                      </div>
                                    )}
                                  </Col>
                                  <Col lg="12" md="12" sm="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Aadhar Upload
                                        </Form.Label>
                                        <Form.Control
                                          accept=".pdf,.jpg,jpeg,.png"
                                          disabled={true}
                                          type="text"
                                          onChange={this.handleAadharChange}
                                          className="form-control"
                                          name="aadharPath"
                                          value={this.state.aadharPath}
                                          required="required"
                                        />
                                      </Form.Group>
                                    </Form>
                                    {this.state.aadharPath && (
                                      <div>
                                        <Link
                                          onClick={() =>
                                            this.openPDFAadharNewTab(
                                              this.state.aadharPath
                                            )
                                          }
                                        >
                                          View Pdf
                                        </Link>
                                      </div>
                                    )}
                                  </Col>
                                  <hr />
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Tenth Certificate Upload
                                          <p
                                            style={{
                                              color: "red",
                                              fontSize: "10px",
                                            }}
                                          >
                                            (* .pdf only)
                                          </p>
                                        </Form.Label>
                                        <Form.Control
                                          accept=".pdf,.jpg,jpeg,.png"
                                          disabled={true}
                                          type="text"
                                          value={this.state.sscPath}
                                          // onChange={this.handleTcChange}
                                          className="form-control"
                                          name="sscPath"
                                          required="required"
                                        />
                                      </Form.Group>
                                    </Form>
                                    {this.state.sscPath && (
                                      <div>
                                        <Link
                                          onClick={() =>
                                            this.openPDFSSCNewTab(
                                              this.state.sscPath
                                            )
                                          }
                                        >
                                          View Pdf
                                        </Link>
                                      </div>
                                    )}
                                  </Col>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Cheque Upload{" "}
                                          <p
                                            style={{
                                              color: "red",
                                              fontSize: "10px",
                                            }}
                                          >
                                            (* .pdf only)
                                          </p>
                                        </Form.Label>
                                        <Form.Control
                                          accept=".pdf,.jpg,jpeg,.png"
                                          type="text"
                                          value={this.state.chequePath}
                                          disabled={true}
                                          // onChange={this.handleChequeChange}
                                          className="form-control"
                                          name="chequePath"
                                          required="required"
                                        />
                                      </Form.Group>
                                    </Form>
                                    {this.state.chequePath && (
                                      <div>
                                        <Link
                                          onClick={() =>
                                            this.openPDFChequeNewTab(
                                              this.state.chequePath
                                            )
                                          }
                                        >
                                          View Pdf
                                        </Link>
                                      </div>
                                    )}
                                  </Col>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Passport Size Color Photo{" "}
                                          <p
                                            style={{
                                              color: "red",
                                              fontSize: "10px",
                                            }}
                                          >
                                            (* .pdf only)
                                          </p>
                                        </Form.Label>
                                        <Form.Control
                                          accept=".pdf,.jpg,jpeg,.png"
                                          type="text"
                                          value={this.state.pPhoto}
                                          // onChange={this.handlepsPhotoChange}
                                          className="form-control"
                                          name="pPhoto"
                                          required="required"
                                          disabled={true}
                                        />
                                      </Form.Group>
                                    </Form>
                                    {this.state.pPhoto && (
                                      <div>
                                        <Link
                                          onClick={() =>
                                            this.openPDFPhotoNewTab(
                                              this.state.pPhoto
                                            )
                                          }
                                        >
                                          View Pdf
                                        </Link>
                                      </div>
                                    )}
                                  </Col>
                                </Row>

                                <hr />
                                <Row>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className=" mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Account Holder Name
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="accName"
                                          value={this.state.accName}
                                          disabled={true}
                                          required="required"
                                          placeholder="Enter Account Holder Name"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className=" mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Account Number
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="accNO"
                                          value={this.state.accNO}
                                          disabled={true}
                                          required="required"
                                          placeholder="Enter Account Number"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className=" mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Bank Name
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="bankName"
                                          value={this.state.bankName}
                                          disabled={true}
                                          required="required"
                                          placeholder="Enter Bank Name"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className=" mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          IFSC Code
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="ifscCode"
                                          value={this.state.ifscCode}
                                          disabled={true}
                                          required="required"
                                          placeholder="Enter IFSC Code"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className=" mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Branch Code
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="branchCode"
                                          value={this.state.branchCode}
                                          disabled={true}
                                          required="required"
                                          placeholder="Enter Branch Code"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                </Row>
                                <hr />
                                <Row>
                                  <h6>Communication Details</h6>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Address Line{" "}
                                        </Form.Label>
                                        <Form.Control
                                          as="textarea"
                                          rows={3}
                                          disabled={true}
                                          className="form-control"
                                          name="addressLine"
                                          id="addressLine"
                                          value={this.state.addressLine}
                                          required="required"
                                          maxLength={60}
                                          placeholder="Enter Your Address Line"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col sm="6" md="6" lg="6">
                                    <Form.Group className="form-group">
                                      <Form.Label
                                        className="control-label"
                                        style={{ paddingTop: "8px" }}
                                      >
                                        Pin Code
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="pincode"
                                        id="pincode"
                                        value={this.state.pincode}
                                        disabled={true}
                                        className="form-control"
                                        aria-describedby="pincode"
                                        placeholder="Pin Code"
                                        maxLength={6}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg="6" md="6" sm="12" xs="12">
                                    <Form.Group className="form-group">
                                      <Form.Label
                                        className="control-label"
                                        style={{ paddingTop: "8px" }}
                                      >
                                        Area
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        name="areaCd"
                                        id="areaCd"
                                        value={this.state.areaCd}
                                        disabled={true}
                                        aria-describedby="area"
                                        placeholder="Enter Area"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col sm="3" lg="6" md="6">
                                    <Form.Group className="form-group">
                                      <Form.Label
                                        className="control-label"
                                        style={{ paddingTop: "8px" }}
                                      >
                                        City
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        name="cityCd"
                                        id="cityCd"
                                        value={this.state.cityCd}
                                        disabled={true}
                                        aria-describedby="cityCd"
                                        placeholder="City"
                                      />
                                    </Form.Group>
                                  </Col>

                                  <Col lg="6" md="6" sm="12" xs="12">
                                    <Form.Group className="form-group">
                                      <Form.Label
                                        className="control-label"
                                        style={{ paddingTop: "8px" }}
                                      >
                                        State
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        id="stateCd"
                                        aria-describedby="State"
                                        name="stateCd"
                                        value={this.state.stateCd}
                                        disabled={true}
                                        placeholder="State"
                                      />
                                    </Form.Group>
                                  </Col>
                                  {/*   <Col lg="12" md="12" sm="12" xs="12">
                            <Form>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicEmail"
                              >
                                <Form.Label style={{ paddingTop: "8px" }}>
                                Country Name                                
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  //  className="form-control"
                                   name="CtName"
                                  value={this.state.CtName}
                                  onChange={this.handlePANNumberChange}
                                  required="required"
                                  placeholder="Enter Country Name"
                                />
                              </Form.Group>
                            </Form>
                          </Col> */}
                                </Row>
                                <hr />
                                <Row>
                                  <h6>Alternate Person Details</h6>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Full Name
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          disabled={true}
                                          className="form-control"
                                          name="fullName"
                                          value={this.state.fullName}
                                          required="required"
                                          placeholder="Enter Full Name"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="6" md="6" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Mobile Number
                                        </Form.Label>
                                        <Form.Control
                                          onKeyDown={this.onKeyDown}
                                          id="amobilenumber"
                                          type="text"
                                          name="amobilenumber"
                                          value={this.state.amobilenumber}
                                          disabled={true}
                                          required="required"
                                          placeholder="Enter Mobile Number"
                                          maxLength={10}
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="6" md="6" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className=" mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Email Id
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          name="aemailid"
                                          value={this.state.aemailid}
                                          disabled={true}
                                          required="required"
                                          placeholder="Enter Email-Id"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Address Line{" "}
                                        </Form.Label>
                                        <Form.Control
                                          as="textarea"
                                          rows={3}
                                          disabled={true}
                                          className="form-control"
                                          name="aAddressLine"
                                          id="aAddressLine"
                                          value={this.state.aAddressLine}
                                          required="required"
                                          maxLength={60}
                                          placeholder="Enter Your Address Line"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                  <Col sm="3" lg="6" md="6">
                                    <Form.Group className="form-group">
                                      <Form.Label
                                        className="control-label"
                                        style={{ paddingTop: "8px" }}
                                      >
                                        Pin Code
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="apincode"
                                        id="apincode"
                                        value={this.state.apincode}
                                        disabled={true}
                                        className="form-control"
                                        aria-describedby="apincode"
                                        placeholder="Pin Code"
                                        maxLength={6}
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col lg="6" md="6" sm="12" xs="12">
                                    <Form.Group className="form-group">
                                      <Form.Label
                                        className="control-label"
                                        style={{ paddingTop: "8px" }}
                                      >
                                        Area
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        name="aareaCd"
                                        id="aareaCd"
                                        value={this.state.aareaCd}
                                        disabled={true}
                                        aria-describedby="aareaCd"
                                        placeholder="Enter Area"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col sm="3" lg="6" md="6">
                                    <Form.Group className="form-group">
                                      <Form.Label
                                        className="control-label"
                                        style={{ paddingTop: "8px" }}
                                      >
                                        City
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        name="acityCd"
                                        id="acityCd"
                                        value={this.state.acityCd}
                                        disabled={true}
                                        aria-describedby="acityCd"
                                        placeholder="City"
                                      />
                                    </Form.Group>
                                  </Col>

                                  <Col lg="6" md="6" sm="12" xs="12">
                                    <Form.Group className="form-group">
                                      <Form.Label
                                        className="control-label"
                                        style={{ paddingTop: "8px" }}
                                      >
                                        State
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        id="astateCd"
                                        aria-describedby="State"
                                        name="astateCd"
                                        value={this.state.astateCd}
                                        disabled={true}
                                        placeholder="State"
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <hr />
                                <Row>
                                  <h6>Branch Details</h6>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form>
                                      <Form.Group
                                        className=" mb-3"
                                        controlId="formBasicEmail"
                                        name="irdabranch"
                                        disabled={true}
                                      >
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          IRDA Branch Details
                                        </Form.Label>
                                        <Form.Control
                                          type="text"
                                          className="form-control"
                                          id="irdabranch"
                                          aria-describedby="State"
                                          name="irdabranch"
                                          value={this.state.irdabranch}
                                          disabled={true}
                                          placeholder="State"
                                        />
                                      </Form.Group>
                                    </Form>
                                  </Col>
                                </Row>
                                <hr />
                                <Row>
                                  <Col lg="12" md="12" sm="12" xs="12">
                                    <Form.Group className="form-group">
                                      <Form.Label
                                        className="control-label"
                                        style={{ paddingTop: "8px" }}
                                      >
                                        Remarks
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        id="remarks1"
                                        aria-describedby="remarks1"
                                        name="remarks1"
                                        value={this.state.remarks1}
                                        onChange={this.handleInputChange}
                                        placeholder="Remarks"
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    className="d-flex justify-content-start"
                                    style={{
                                      marginRight: "5px",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <Button
                                      variant="danger"
                                      type="submit"
                                      style={{
                                        marginRight: "5px",
                                        marginTop: "20px",
                                      }}
                                      onClick={(e) => this.handleReject(e, "5")}
                                    >
                                      Reject
                                    </Button>
                                  </Col>
                                  <Col
                                    className="d-flex justify-content-end"
                                    style={{
                                      marginRight: "5px",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <Button
                                      variant="success"
                                      type="submit"
                                      onClick={(e) =>
                                        this.handleApprove(e, "6")
                                      }
                                    >
                                      Approve
                                    </Button>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Offcanvas.Body>
                        </Offcanvas>
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <div>
                      {/* searchbar,pagination,filter */}
                      <input
                        style={{ float: "left", marginBottom: "10px" }}
                        type="text"
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />

                      <DataTable
                        columns={this.state.tableClms}
                        data={paginatedData}
                        // defaultSortFieldId={6}
                        striped
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        paginationRowsPerPageOptions={[10, 20, 50]}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

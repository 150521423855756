import React, { Component } from "react";
import "./RGIProposerinfo.css";
import { Button, Col, Row, Card, Table, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import configData from "../config.json";

export default class RGIMemberinfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forgotPasswordCondition: false,
      //chpiId: sessionStorage.getItem("chpiId"),
      proposalId: sessionStorage.getItem("proposalId"),
      baseproductId: "2868",
      chpiId: "713",
      //baseproductId: sessionStorage.getItem("baseproductId"),
      cqrId: sessionStorage.getItem("cqrId"),
      members: [],
      relations: {},
      maritalstatus: [],
      occupation: [],
      tab2Data: {
        titleCd: "",
        fname: "",
        mname: "",
        lname: "",
        age: "",
        relation: "",
        height: "",
        weight: "",
        cname: "",
        dob: "",
        mgender: "",
        lhmId: "",
        annualincome: "",
        maritalstatus: "",
        emailtype: "",
        profession: "",
      },
    };
  }
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  // back page
  handleSubmitClick = (e) => {
    window.location.href = "/rgiproposerinfo";
  };

  //Data receive
  componentDidMount() {
    this.apisfetchHandler();
    this.relationfetchHandler();
    this.HealthmaritalstatusfetchHandler();
    this.HealthoccupationsfetchHandler();
  }
  HealthmaritalstatusfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "C_Healthmaritalstatus/listByid/", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ maritalstatus: data.list });
        }
      });
  };
  HealthoccupationsfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "C_Healthoccupations/listByid/", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ occupation: data.list });
        }
      });
  };
  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "rg_Leads/getleaddetails/", {
      method: "POST",
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          this.setState({ members: data.lead.members });
        }
      });
  };
  relationfetchHandler = () => {
    var formData = new FormData();
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "C_Healthrelationcodes/listByid/", {
      method: "POST",
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          this.setState({ relations: data.codes });
        }
      });
  };
  // update data
  handleUpdatememberChange = (e, index, fieldName) => {
    const { value } = e.target;
    if (fieldName === "fname") {
      // Allow only letters in the company name (no numbers)
      const lettersAndSpacesRegex = /^[A-Za-z\s]*$/;

      if (!lettersAndSpacesRegex.test(value) && value !== "") {
        // Display an error toast message or handle the error as needed
        toast.error("Not allowed special characters and Numbers.");
        return; // Stop further processing for this field
      }
    }
    if (fieldName === "lname") {
      // Allow only letters in the company name (no numbers)
      const lettersAndSpacesRegex = /^[A-Za-z\s]*$/;

      if (!lettersAndSpacesRegex.test(value) && value !== "") {
        // Display an error toast message or handle the error as needed
        toast.error("Not allowed special characters and Numbers.");
        return; // Stop further processing for this field
      }
    }
    if (fieldName === "dob" && value) {
      const dateObject = new Date(value);
      const year = dateObject.getFullYear();
      const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
      const day = dateObject.getDate().toString().padStart(2, "0");
      // Construct the formatted date string
      const formattedDate = `${day}/${month}/${year}`;
      // Update the state with the formatted date string
      this.setState((prevState) => {
        const updatedMembers = [...prevState.members];
        updatedMembers[index] = {
          ...updatedMembers[index],
          [fieldName]: formattedDate,
        };
        return { members: updatedMembers };
      });
    }

    this.setState((prevState) => {
      const updatedMembers = [...prevState.members];
      updatedMembers[index] = { ...updatedMembers[index], [fieldName]: value };
      return { members: updatedMembers };
    });
  };

  handleUpdatememberhChange = (e, index, fieldName) => {
    const { value } = e.target;
    var error = "no";
    if (value < 1 || value > 240) {
      error = "yes";
      toast.error("Member height must be between 1 to 240.");
    }
    // console.log(error);
    if (error !== "yes") {
      this.setState((prevState) => {
        const updatedMembers = [...prevState.members];
        updatedMembers[index] = {
          ...updatedMembers[index],
          [fieldName]: value,
        };
        return { members: updatedMembers };
      });
    }
  };
  handleUpdatememberwChange = (e, index, fieldName) => {
    const { value } = e.target;
    // console.log(value);
    var error = "no";
    if (value < 1 || value > 240) {
      error = "yes";
      toast.error("Member height must be between 1 to 240.");
    }
    // console.log(error);
    if (error !== "yes") {
      // console.log(error);
      this.setState((prevState) => {
        const updatedMembers = [...prevState.members];
        updatedMembers[index] = {
          ...updatedMembers[index],
          [fieldName]: value,
        };
        return { members: updatedMembers };
      });
    }
  };

  //   validations
  validateForm = () => {
    let formIsValid = true;
    this.state.members.forEach((member, index) => {
      if (!member.titleCd) {
        formIsValid = false;
        toast.error(`Member ${index + 1}: Please Select Title.`);
      } else if (!member.fname) {
        formIsValid = false;
        toast.error(`Member ${index + 1}: Please Enter First Name.`);
      } else if (!member.lname) {
        formIsValid = false;
        toast.error(`Member ${index + 1}: Please Enter Last Name.`);
      } else if (!member.gender) {
        formIsValid = false;
        toast.error(`Member ${index + 1}: Please Select Gender.`);
      } else if (!member.relation) {
        formIsValid = false;
        toast.error(`Member ${index + 1}: Please Select Relation.`);
      } else if (!member.height) {
        formIsValid = false;
        toast.error(`Member ${index + 1}: Please Enter Height.`);
      } else if (!member.maritalstatus) {
        formIsValid = false;
        toast.error(`Member ${index + 1}: Please Select Marital Status.`);
      } else if (!member.profession) {
        formIsValid = false;
        toast.error(`Member ${index + 1}: Please Select Occupation.`);
      } else if (member.height <= 1 || member.height === "1") {
        formIsValid = false;
        toast.error(`Member ${index + 1}: Height should be greater than zero.`);
      } else if (!/^\d+$/.test(member.height)) {
        formIsValid = false;
        toast.error(`Member ${index + 1}: Height should only contain digits.`);
      } else if (member.height.length > 100) {
        formIsValid = false;
        toast.error(
          `Member ${index + 1}: Height should be at most 100 characters.`
        );
      } else if (!member.weight) {
        formIsValid = false;
        toast.error(`Member ${index + 1}: Please Enter weight.`);
      } else if (member.weight <= 1 || member.weight === "1") {
        formIsValid = false;
        toast.error(`Member ${index + 1}: weight should be greater than zero.`);
      } else if (!/^\d+$/.test(member.weight)) {
        formIsValid = false;
        toast.error(`Member ${index + 1}: weight should only contain digits.`);
      } else if (member.weight.length > 100) {
        formIsValid = false;
        toast.error(
          `Member ${index + 1}: weight should be at most 100 characters.`
        );
      }
    });
    return formIsValid;
  };

  handleSubmitNextClick = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      var fd = new FormData();
      fd.append("proposalId", this.state.proposalId);
      fd.append("baseproductId", this.state.baseproductId);
      fd.append("members", JSON.stringify(this.state.members));

      fetch(configData.api_url + "rg_Leads/updateleadmembers/", {
        method: "POST",
        headers: {},
        mode: "cors",
        body: fd, // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            window.location.href = "/rgimedicals";
          }
        });
    }
  };
  render() {
    return (
      <div>
        <Row id="MainRoOne">
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-person-fill  "
                        onClick={this.handleSubmitClick}
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6
                        style={{ color: "#3d5a95" }}
                        onClick={this.handleSubmitClick}
                      >
                        Proposer Info
                      </h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-person-vcard-fill"
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Member Info</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="inActiveTab">
                      <span
                        className="bi bi-heart-pulse"
                        id="normalIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#98968c" }}>Medicals</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span className="bi bi-arrow-right" id="inActiveArrow"></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="inActiveTab">
                      <span
                        className="bi bi-pencil-square"
                        id="normalIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#98968c" }}>Nominee Details</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span className="bi bi-arrow-right" id="inActiveArrow"></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="inActiveTab">
                      <span
                        className="bi bi-clipboard-plus"
                        id="normalIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#98968c" }}>Add-On's</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}></Col>
            </Row>
          </Col>
        </Row>
        <Card style={{ width: "100%" }}>
          <Card.Body>
            <div className="tab-content" id="myTabContent"></div>
            <div className="container">
              {/* <Form> */}
              <Row>
                <Col className="table-responsive PoliciesFormFillingPage">
                  <Table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">
                          <p>S.No</p>
                        </th>
                        <th scope="col" style={{ width: "50px" }}>
                          <p>Salutation&nbsp;</p>
                        </th>
                        <th scope="col" style={{ width: "150px" }}>
                          <p>&nbsp;First&nbsp;Name&nbsp;</p>
                        </th>
                        <th scope="col" style={{ width: "150px" }}>
                          <p> Middle&nbsp;Name</p>
                        </th>
                        <th scope="col" style={{ width: "150px" }}>
                          <p>Last&nbsp;Name</p>
                        </th>
                        <th scope="col" style={{ width: "100px" }}>
                          <p>Date&nbsp;of Birth</p>
                        </th>
                        <th scope="col" style={{ width: "100px" }}>
                          <p>Age&nbsp;in&nbsp;Years</p>
                        </th>
                        <th scope="col" style={{ width: "150px" }}>
                          <p>
                            &nbsp;Gender&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </p>
                        </th>
                        <th scope="col" style={{ width: "150px" }}>
                          <p>Relation&nbsp;with&nbsp;Proposer</p>
                        </th>
                        <th scope="col" style={{ width: "150px" }}>
                          <p>Marital&nbsp;Status</p>
                        </th>
                        <th scope="col" style={{ width: "150px" }}>
                          <p>Occupation&nbsp;</p>
                        </th>
                        <th scope="col" style={{ width: "100px" }}>
                          <p> Height&nbsp;(cm)</p>
                        </th>
                        <th scope="col" style={{ width: "100px" }}>
                          <p>Weight&nbsp;(Kgs)</p>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.members.map((quote, index) => (
                        <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td
                            className="text-center"
                            style={{ width: "100px" }}
                          >
                            <Form.Group className="form-group">
                              <Form.Select
                                name="titleCd"
                                value={quote.titleCd}
                                onChange={(e) =>
                                  this.handleUpdatememberChange(
                                    e,
                                    index,
                                    "titleCd"
                                  )
                                }
                                className="form-control"
                                id="titleCd"
                                style={{ height: "47px" }}
                              >
                                <option>Select</option>
                                {quote.gender === "MALE" && (
                                  <option value="Mr.">Mr.</option>
                                )}
                                {quote.gender === "FEMALE" && (
                                  <>
                                    <option value="Mrs.">Mrs.</option>
                                    <option value="Ms.">Ms.</option>
                                    <option value="Dr.">Dr.</option>
                                    <option value="Miss.">Miss.</option>
                                    <option value="M/S">M/S</option>
                                  </>
                                )}
                              </Form.Select>
                            </Form.Group>
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "150px" }}
                          >
                            {/* {quote.fname} */}
                            <Form.Control
                              type="text"
                              onChange={(e) =>
                                this.handleUpdatememberChange(e, index, "fname")
                              }
                              className="form-control"
                              name="fname"
                              value={quote.fname}
                              required="required"
                              placeholder="Enter First Name"
                            />
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "150px" }}
                          >
                            <Form.Control
                              type="text"
                              onChange={(e) =>
                                this.handleUpdatememberChange(e, index, "mname")
                              }
                              className="form-control"
                              name="mname"
                              value={quote.mname}
                              required="required"
                              placeholder="Enter Middle Name"
                            />
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "150px" }}
                          >
                            <Form.Control
                              type="text"
                              onChange={(e) =>
                                this.handleUpdatememberChange(e, index, "lname")
                              }
                              className="form-control"
                              name="lname"
                              value={quote.lname}
                              required="required"
                              placeholder="Enter Last Name"
                            />
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "100px" }}
                          >
                            <Form.Control
                              className="date dob hasDatepicker"
                              type="date"
                              name="dob"
                              value={quote.dob}
                              disabled
                              placeholder="Enter mobile No"
                              id="dob"
                              onChange={(e) =>
                                this.handleUpdatememberChange(e, index, "dob")
                              }
                            />
                          </td>
                          <td className="text-center" style={{ width: "60px" }}>
                            <Form.Group className="form-group">
                              <Form.Control
                                name="age"
                                value={quote.age}
                                onChange={(e) =>
                                  this.handleUpdatememberChange(e, index, "age")
                                }
                                disabled
                                readOnly
                                className="age"
                                type="text"
                              />
                            </Form.Group>
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "100px" }}
                          >
                            <Form.Group className="form-group">
                              <Form.Select
                                aria-label="Default select example"
                                className="form-control"
                                name="mgender"
                                value={quote.mgender}
                                onChange={(e) =>
                                  this.handleUpdatememberChange(
                                    e,
                                    index,
                                    "mgender"
                                  )
                                }
                                style={{ height: "47px" }}
                              >
                                <option>Select</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Male Transgender">
                                  Male Transgender
                                </option>
                                <option value="Female Transgender">
                                  Female Transgender
                                </option>
                              </Form.Select>
                            </Form.Group>
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "150px" }}
                          >
                            <Form.Group className="form-group">
                              <Form.Control
                                disabled
                                name="relation"
                                value={quote.relation}
                                onChange={(e) =>
                                  this.handleUpdatememberChange(
                                    e,
                                    index,
                                    "relation"
                                  )
                                }
                                type="text"
                                className="form-control"
                                id="relation"
                              />
                            </Form.Group>
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "150px" }}
                          >
                            <Form.Group className="form-group">
                              <Form.Select
                                name="maritalstatus"
                                value={quote.maritalstatus}
                                className="form-control"
                                onChange={(e) =>
                                  this.handleUpdatememberChange(
                                    e,
                                    index,
                                    "maritalstatus"
                                  )
                                }
                                id="maritalstatus"
                                //value={this.state.tab1Data.maritalstatus}
                                aria-describedby="maritalstatus"
                                aria-label="Default select example"
                                style={{ height: "47px" }}
                              >
                                <option value="">Select</option>
                                {this.state.maritalstatus.map(
                                  (quote, index) => (
                                    <option
                                      key={index}
                                      value={quote.maritalstatusId}
                                    >
                                      {quote.maritalstatus}
                                    </option>
                                  )
                                )}
                              </Form.Select>
                            </Form.Group>
                          </td>

                          <td
                            className="text-center"
                            style={{ width: "150px" }}
                          >
                            <Form.Group className="form-group">
                              <Form.Select
                                name="profession"
                                className="form-control"
                                onChange={(e) =>
                                  this.handleUpdatememberChange(
                                    e,
                                    index,
                                    "profession"
                                  )
                                }
                                id="profession"
                                value={quote.profession}
                                aria-describedby="profession"
                                aria-label="Default select example"
                                style={{ height: "47px" }}
                              >
                                <option value="">Select</option>
                                {this.state.occupation.map(
                                  (occupation, index, idx) => (
                                    <option
                                      key={index}
                                      value={occupation.occupationid}
                                    >
                                      {occupation.occupation}
                                    </option>
                                  )
                                )}
                              </Form.Select>
                            </Form.Group>
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "100px" }}
                          >
                            <Form.Control
                              type="number"
                              min="1"
                              max="240"
                              onChange={(e) =>
                                this.handleUpdatememberhChange(
                                  e,
                                  index,
                                  "height"
                                )
                              }
                              className="form-control"
                              name="height"
                              value={quote.height}
                              required="required"
                              placeholder="Enter Height in C.Mtr"
                            />
                          </td>
                          <td
                            className="text-center"
                            style={{ width: "100px" }}
                          >
                            <Form.Control
                              type="number"
                              min="0"
                              max="240"
                              onChange={(e) =>
                                this.handleUpdatememberwChange(
                                  e,
                                  index,
                                  "weight"
                                )
                              }
                              className="form-control"
                              name="weight"
                              value={quote.weight}
                              required="required"
                              placeholder="Enter weight in K.gs"
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
            <Row className="mt-5">
              <Col>
                {" "}
                <Button
                  id="getQuotes4"
                  onClick={this.handleSubmitClick}
                  type="button"
                  style={{ float: "left" }}
                >
                  Back
                </Button>
              </Col>
              <Col>
                {" "}
                <Button
                  id="getQuotes4"
                  onClick={this.handleSubmitNextClick}
                  type="button"
                  style={{ float: "right" }}
                >
                  Next
                </Button>
              </Col>
              {/* </Form> */}
            </Row>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
  Offcanvas,
  Form,
  Container,
  Table,
} from "react-bootstrap";
import "./Settings.css";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import configData from "../config.json";
export default class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showOne: false,
      settings: [],
      rows: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      isdefault: false,
      cData: {
        sId: "",
        // settingsName: "",
        // settingsValue: "",
      },
      settingsData: { settingsName: "", settingsValue: "" },

      errors: {},
      showButton: false,
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.sId,
          sortable: true,
          cell: (row) => <span>{row.sId}</span>,
        },
        {
          name: " Name",
          selector: (row, i) => row.settingsName,
          sortable: true,
          cell: (row) => <span>{row.settingsName}</span>,
        },

        {
          name: "JSON value",
          selector: (row, i) => row.settingsValue,
          sortable: true,
          cell: (row) => <span>{row.settingsValue}</span>,
        },

        {
          name: "Actions",
          sortable: false,
          cell: (row, index) => (
            <div>
              {/* edit button and delete button */}
              <button
                className="bi bi-eye btn btn-warning m-1"
                style={{
                  backgroundColor: "#169F85",
                  border: " 1px solid #169F85",
                  color: "#000",
                }}
              />
              <button
                // style={{ marginLeft: "5px" }}
                variant="primary"
                onClick={this.handleShowOne}
                className="bi bi-pen btn btn-primary m-1"
              />{" "}
              <button
                variant="danger"
                onClick={() => this.handleDelete(row, index)}
                className="bi bi-trash btn btn-danger m-1"
              />
              <button
                className="bi bi-check-square btn btn-danger m-1"
                style={{
                  backgroundColor: "#26B99A",
                  border: " 1px solid #169F85",
                  color: "#fff",
                }}
              />
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
    };
  }

  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("sId", row.cData.sId);
    fetch(configData.api_url + "settings/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.apisfetchHandler();
        }
      });
    //console.log(row.cId);
  };

  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };

  handleRemoveSpecific = (idx) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    const tempRows = this.state.settings; // to avoid  direct state mutation
    tempRows.splice(idx, 1);
    this.setState({ settings: tempRows });
  };
  handleAdd = (e) => {
    e.preventDefault();
    if (this.featurevalidateForm()) {
      this.setState((prevState) => ({
        settings: [...prevState.settings, this.state.settingsData],
      }));
      this.setState({
        settingsData: { settingsName: "", settingsValue: "" },
      });
    }
  };

  handleShow = () => {
    this.setState({
      show: true,
      cData: {
        sId: "",
      },
      settingsData: { settingsName: "", settingsValue: "" },
    });
  };

  handleClose = () => {
    this.setState({ show: false });
  };
  handleShowOne = () => {
    this.setState({ showOne: true });
  };

  handleCloseOne = () => {
    this.setState({ showOne: false });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.settings.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };
  inputChange = (e, id) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const newFormData = this.state.cData;
    newFormData[fieldName] = fieldValue;
    this.setState({ cData: newFormData });
    const newRows = [...this.state.rows]; // Create a copy of rows array
    const index = newRows.findIndex((row) => row.id === id); // Find the index of the row
    if (index !== -1) {
      newRows[index][fieldName] = fieldValue; // Update the specific field value of the row
      this.setState({ rows: newRows });
    }
  };

  inputChangeSelect = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const newFormData = { ...this.state.settingsData };
    newFormData[fieldName] = fieldValue;
    this.setState({ settingsData: newFormData });
  };

  validateForm(e) {
    let settings = this.state.cData;
    let errors = {};
    let formIsValid = true;

    if (!settings["sId"]) {
      toast.info("Please pass Name");
      formIsValid = false;
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  featurevalidateForm() {
    let settings = this.state.settingsData;
    let errors = {};
    let formIsValid = true;

    if (!settings["settingsName"]) {
      formIsValid = false;
      toast.info("Please pass Names");
      return false;
    }
    if (!settings["settingsValue"]) {
      formIsValid = false;
      toast.info("Please pass Description");
      return false;
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  handleAddClick = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      const { cData, settingsData, rows } = this.state;
      const newRow = {
        sId: cData.sId,
        // settingsData: cData.settingsData,
        // settingValue: cData.settingsValue,
        settings: [...rows, settingsData],
      };
      //console.log(cData.sId);
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("sId", newRow.sId);
      formData.append("settingsName", newRow.settingsName);
      formData.append("settingsValue", newRow.settingsvalue);
      formData.append("settings", JSON.stringify(newRow.settings));

      fetch(configData.api_url + "settings/all/", {
        method: "POST",
        mode: "cors",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);

          if (data.status === "success") {
            this.apisfetchHandler();
            this.setState(() => ({
              settings: [],
              cData: {
                sId: "",
                settingsValue: "",
                settingsName: "",
              },
              settingsData: { settingsName: "", settingValue: "" }, // Clear the featuresData array
            }));
          }
        });
    }
  };

  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "settings/all/", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success" || data.status === "true") {
          this.setState({ settings: data.list });
        }
      });
  };
  componentDidMount() {
    this.apisfetchHandler();
    // this.apisfetchHandlerupdatestatus();
  }
  apisfetchHandlerupdatestatus = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "settings/updatestatus/", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success" || data.status === "true") {
          // this.setState({ insurance: data.list });
        }
        ////console.log(this.state.settings);
      });
  };
  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.settings.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );
    return (
      <div className="page-title">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="title_left">
              <h2> Settings</h2>
            </div>
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "5px", marginTop: "20px" }}
                    >
                      <div className="dataTables_length" id="datatable_length">
                        <Button
                          variant="primary"
                          className="bi bi-plus"
                          onClick={this.handleShow}
                        >
                          New
                        </Button>
                        <Offcanvas
                          show={this.state.show}
                          onHide={this.handleClose}
                          {...this.props}
                          style={{ width: "700px", height: "auto" }}
                          placement="end"
                          backdrop="true"
                        >
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Add Settings</Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                            <Container>
                              <Row className="divstyle">
                                <Col>
                                  <Form.Group className="mt-3">
                                    <Form.Control
                                      type="text"
                                      name="sId"
                                      value={this.state.sId}
                                      placeholder="Name"
                                      aria-describedby="inputGroupPrepend"
                                      onChange={this.inputChange}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <br />

                              <Row>
                                <Col>
                                  <Table
                                    className="table table-bordered table-hover"
                                    id="tab_logic"
                                  >
                                    <thead>
                                      <tr>
                                        <th className="text-center">S.NO</th>
                                        <th className="text-center">setting</th>
                                        <th className="text-center">
                                          Description
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.settings.length > 0 ? (
                                        this.state.settings.map(
                                          (api, index, idx) => (
                                            <tr key={index}>
                                              <td>{index + 1}</td>
                                              <td>{api.settingsName}</td>
                                              <td>
                                                {api.settingsValue}
                                                <button
                                                  variant="danger"
                                                  className="bi bi-trash-fill btn btn-danger"
                                                  onClick={() =>
                                                    this.handleRemoveSpecific(
                                                      index
                                                    )
                                                  }
                                                  // idx={idx}
                                                ></button>
                                              </td>
                                            </tr>
                                          )
                                        )
                                      ) : (
                                        <tr>
                                          <td colSpan="3"> No Records</td>
                                        </tr>
                                      )}
                                      <tr>
                                        <td>--</td>
                                        <td>
                                          {" "}
                                          <Form.Group className="mt-3">
                                            <Form.Control
                                              type="text"
                                              name="settingsName"
                                              value={this.state.settingsName}
                                              placeholder="Name"
                                              aria-describedby="inputGroupPrepend"
                                              onChange={this.inputChangeSelect}
                                            />
                                          </Form.Group>
                                        </td>
                                        <td>
                                          <Row className="d-flex mt-3">
                                            <Col md="9">
                                              <Form.Group>
                                                <Form.Control
                                                  as="textarea"
                                                  name="settingsValue"
                                                  value={
                                                    this.state.settingsValue
                                                  }
                                                  onChange={
                                                    this.inputChangeSelect
                                                  }
                                                  rows={3}
                                                  placeholder=" Description"
                                                />
                                              </Form.Group>
                                            </Col>
                                            <Col
                                              md="3"
                                              className="d-flex pr-0 pl-0"
                                            >
                                              <Button
                                                variant="warning"
                                                onClick={this.handleAdd}
                                                className="bi bi-file-plus "
                                              />
                                            </Col>
                                            <div
                                              className="errorMsg"
                                              style={{ color: "red" }}
                                            >
                                              {this.state.errors.settingsValue}
                                            </div>
                                          </Row>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </Col>
                              </Row>

                              <Row className="d-flex justify-content-end  ">
                                <Col className="d-flex justify-content-end">
                                  <button
                                    className=" btn btn-warning me-2"
                                    style={{
                                      backgroundColor: "#fff",
                                      color: "#000",
                                      borderColor: "#ccc",
                                    }}
                                    onClick={this.handleClose}
                                  >
                                    Close
                                  </button>{" "}
                                  <button
                                    className="btn btn-primary me-2"
                                    style={{
                                      backgroundColor: "#23547e",
                                      color: "#fff",
                                      borderColor: "#2e6da4",
                                    }}
                                    onClick={this.handleAddClick}
                                  >
                                    save{" "}
                                  </button>
                                </Col>
                              </Row>
                            </Container>
                          </Offcanvas.Body>
                        </Offcanvas>
                      </div>
                      <div className="dataTables_length" id="datatable_length">
                        <Offcanvas
                          show={this.state.showOne}
                          onHide={this.handleCloseOne}
                          {...this.props}
                          style={{ width: "700px", height: "auto" }}
                          placement="end"
                          backdrop="true"
                        >
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title>Edit Setting</Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                            <Container>
                              <Row className="divstyle">
                                <Col>
                                  <Form.Group className="mt-3">
                                    <Form.Control
                                      type="text"
                                      name="sId"
                                      value={this.state.sId}
                                      placeholder="Name"
                                      aria-describedby="inputGroupPrepend"
                                      onChange={this.inputChange}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <br />

                              <Row>
                                <Col>
                                  <Table
                                    className="table table-bordered table-hover"
                                    id="tab_logic"
                                  >
                                    <thead>
                                      <tr>
                                        <th className="text-center">S.NO</th>
                                        <th className="text-center">setting</th>
                                        <th className="text-center">
                                          Description
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.settings.length > 0 ? (
                                        this.state.settings.map(
                                          (api, index, idx) => (
                                            <tr key={index}>
                                              <td>{index + 1}</td>
                                              <td>{api.settingsName}</td>
                                              <td>
                                                {api.settingsValue}
                                                <button
                                                  variant="danger"
                                                  className="bi bi-trash-fill btn btn-danger"
                                                  onClick={() =>
                                                    this.handleRemoveSpecific(
                                                      index
                                                    )
                                                  }
                                                  // idx={idx}
                                                ></button>
                                              </td>
                                            </tr>
                                          )
                                        )
                                      ) : (
                                        <tr>
                                          <td colSpan="3"> No Records</td>
                                        </tr>
                                      )}
                                      <tr>
                                        <td>--</td>
                                        <td>
                                          {" "}
                                          <Form.Group className="mt-3">
                                            <Form.Control
                                              type="text"
                                              name="settingsName"
                                              value={this.state.settingsName}
                                              placeholder="Company Name"
                                              aria-describedby="inputGroupPrepend"
                                              onChange={this.inputChangeSelect}
                                            />
                                          </Form.Group>
                                        </td>
                                        <td>
                                          <Row className="d-flex mt-3">
                                            <Col md="9">
                                              <Form.Group>
                                                <Form.Control
                                                  as="textarea"
                                                  name="settingsValue"
                                                  value={
                                                    this.state.settingsValue
                                                  }
                                                  onChange={
                                                    this.inputChangeSelect
                                                  }
                                                  rows={3}
                                                  placeholder="Feature Description"
                                                />
                                              </Form.Group>
                                            </Col>
                                            <Col
                                              md="3"
                                              className="d-flex pr-0 pl-0"
                                            >
                                              <Button
                                                variant="warning"
                                                onClick={this.handleAdd}
                                                className="bi bi-file-plus "
                                              />
                                            </Col>
                                            <div
                                              className="errorMsg"
                                              style={{ color: "red" }}
                                            >
                                              {this.state.errors.settingValue}
                                            </div>
                                          </Row>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </Col>
                              </Row>

                              <Row className="d-flex mt-3">
                                <Col>
                                  {" "}
                                  <Button
                                    variant="success"
                                    onClick={this.handleAddClick}
                                  >
                                    save{" "}
                                  </Button>
                                </Col>
                              </Row>
                            </Container>
                          </Offcanvas.Body>
                        </Offcanvas>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <div>
                        <input
                          style={{ float: "left", marginBottom: "10px" }}
                          type="text"
                          onChange={this.handleFilter}
                          placeholder="Search..."
                        />
                      </div>
                      <br />

                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.settings.length
                        }
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        paginationRowsPerPageOptions={[
                          10, 20, 30, 50, 60, 70, 80, 90, 100,
                        ]}
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";

import {

  Button,

  Container,

  Row,Form,

  Col,

  Offcanvas,

} from "react-bootstrap";

import { toast } from "react-toastify";

import DataTable from "react-data-table-component";

import configData from '../config.json';



export default class Requeststatus extends Component {

  constructor(props) {

    super(props);

    this.state = { 

      show: false,

      data: [],

      sId: sessionStorage.getItem("sessionId"),

      uId: sessionStorage.getItem("uId"),

      errors: {},

      filteredData: null,

      tableClms: [

        {

          name: "S.NO",

          selector: (row, i) => row.requeststatus_Id,

          sortable: true,

          cell: (row) => <span>{row.requeststatus_Id}</span>,

        },

        {name:"IntegId",selector:(row, i) => row.integId,sortable:true,cell: (row) => <span>{row.integId}</span>,},{name:"Request ID",selector:(row, i) => row.requestId,sortable:true,cell: (row) => <span>{row.requestId}</span>,},{name:"Request Status",selector:(row, i) => row.requestStatus,sortable:true,cell: (row) => <span>{row.requestStatus}</span>,},{name:"Response ID",selector:(row, i) => row.responseId,sortable:true,cell: (row) => <span>{row.responseId}</span>,},{name:"Response Status",selector:(row, i) => row.responseStatus,sortable:true,cell: (row) => <span>{row.responseStatus}</span>,},

        {name:"Dummy One",selector:(row, i) => row.dummyOne,sortable:true,cell: (row) => <span>{row.dummyOne}</span>,},{name:"Dummy Two",selector:(row, i) => row.dummyTwo,sortable:true,cell: (row) => <span>{row.dummyTwo}</span>,},{name:"Dummy Three",selector:(row, i) => row.dummyThree,sortable:true,cell: (row) => <span>{row.dummyThree}</span>,},{name:"Dummy Four",selector:(row, i) => row.dummyFour,sortable:true,cell: (row) => <span>{row.dummyFour}</span>,},{name:"Dummy Five",selector:(row, i) => row.dummyFive,sortable:true,cell: (row) => <span>{row.dummyFive}</span>,},{name:"Dummy Six",selector:(row, i) => row.dummysix,sortable:true,cell: (row) => <span>{row.dummysix}</span>,},{name:"Dummy Seven",selector:(row, i) => row.dummySeven,sortable:true,cell: (row) => <span>{row.dummySeven}</span>,},{name:"Dummy Eight",selector:(row, i) => row.dummyEight,sortable:true,cell: (row) => <span>{row.dummyEight}</span>,},{name:"Dummy Nine",selector:(row, i) => row.dummyNine,sortable:true,cell: (row) => <span>{row.dummyNine}</span>,},{name:"Dummy Ten",selector:(row, i) => row.dummyTen,sortable:true,cell: (row) => <span>{row.dummyTen}</span>,},//***tableClms

        {

          name: "Actions",

          sortable: false,

          cell: (row) => (

            <div>

              {/* edit button and delete button */}

              <Button

              style={{marginRight:"10px"}}

                variant="primary"

                onClick={this.handleOffcanvasShow}

                className="bi bi-pen" 

              >

              </Button>

              <Button

                variant="danger"

                onClick={() => this.handleDelete(row)}

                className="bi bi-trash danger"

              >

              </Button>

            </div>

          ),

        },

      ],

      currentPage: 1,

      itemsPerPage: 10,

      integId:'',requestId:'',requestStatus:'',responseId:'',responseStatus:'',

      dummyOne:'',dummyTwo:'',dummyThree:'',dummyFour:'',dummyFive:'',dummysix:'',dummySeven:'',dummyEight:'',dummyNine:'',dummyTen:'',//***state

    };

  }

  // offcanvas Open

  handleOffcanvasShow = () => {

    this.setState({ show: true });

  };

  // Offcanvas close button

  handleOffcanvasClose = () => {

    this.setState({ show: false });

  };

  showToast = (msg, type) => {

    switch(type) {

      case "success":

        toast.success(msg);

        break;

      case "error":

        toast.error(msg);

        break;

      case "warning":

        toast.warn(msg); // 'warn' is used instead of 'warning'

        break;

      default:

        toast.info(msg);

     }   

  };

  RequeststatuseditHandler=(e)=>{

      e.preventDefault();

      // const updateFormData = {...editFormData};

      // updateFormData['Requeststatus_Id'] = e.target.parentElement.getAttribute("id");

      // setEditFormData(updateFormData);

      // setEdit(true);

  

       }

       

RequeststatusfetchHandler = () => {

  var formData = new FormData();

  formData.append("sId", this.state.sId);

 formData.append("uId", this.state.uId);

            fetch(configData.api_url +'C_Requeststatus/list', {  // Enter your IP address here

        

              method: 'POST', 

              //headers :{ 'Content-Type' : 'application/json'},

              mode: 'cors', 

              body: formData// body data type must match "Content-Type" header

            }).then(response => response.json())

            .then((data) => {

              if (data.status === "success") {

                this.setState({ data: data.list });

              }

            });

           }

           RequeststatussubmitHandler=(e)=>{

            e.preventDefault();

              //   setButtons(contacts);

             var formData = new FormData();

             formData.append("sId", this.state.sId);

              formData.append("uId", this.state.uId);

             formData.append('integId',this.state.integId);

formData.append('requestId',this.state.requestId);

formData.append('requestStatus',this.state.requestStatus);

formData.append('responseId',this.state.responseId);

formData.append('responseStatus',this.state.responseStatus);



             formData.append('dummyOne',this.state.dummyOne);
formData.append('dummyTwo',this.state.dummyTwo);
formData.append('dummyThree',this.state.dummyThree);
formData.append('dummyFour',this.state.dummyFour);
formData.append('dummyFive',this.state.dummyFive);
formData.append('dummysix',this.state.dummysix);
formData.append('dummySeven',this.state.dummySeven);
formData.append('dummyEight',this.state.dummyEight);
formData.append('dummyNine',this.state.dummyNine);
formData.append('dummyTen',this.state.dummyTen);
//***tableData*

             fetch(configData.api_url + 'C_Requeststatus/add', {  // Enter your IP address here

          

               method: 'POST',

               //headers :{ 'Content-Type' : 'application/json'},

               mode: 'cors',

               body: formData// body data type must match "Content-Type" header

             }).then(response => response.json())

               .then((data) => {

                 this.showToast(data.message, data.status);

                 if (data.status === "success") {

                   this.RequeststatusfetchHandler();

                   this.handleOffcanvasClose();

                   this.setState({integId:'',requestId:'',requestStatus:'',responseId:'',responseStatus:'',});

                }

              });

             }

   RequeststatusupdateHandler=(e)=>{

        e.preventDefault();

     var formData = new FormData();

     formData.append("sId", this.state.sId);

    formData.append("uId", this.state.uId);

          formData.append('integId',this.state.integId);

formData.append('requestId',this.state.requestId);

formData.append('requestStatus',this.state.requestStatus);

formData.append('responseId',this.state.responseId);

formData.append('responseStatus',this.state.responseStatus);



      formData.append('dummyOne',this.state.dummyOne);
formData.append('dummyTwo',this.state.dummyTwo);
formData.append('dummyThree',this.state.dummyThree);
formData.append('dummyFour',this.state.dummyFour);
formData.append('dummyFive',this.state.dummyFive);
formData.append('dummysix',this.state.dummysix);
formData.append('dummySeven',this.state.dummySeven);
formData.append('dummyEight',this.state.dummyEight);
formData.append('dummyNine',this.state.dummyNine);
formData.append('dummyTen',this.state.dummyTen);
//***tableData1

          fetch(configData.api_url +'C_Requeststatus/update', {  

            method: 'POST', 

            mode: 'cors', 

            body: formData

          }).then(response => response.json())

            .then((data) => {

              this.showToast(data.message, data.status);

            if (data.status === "success") {

              this.RequeststatusfetchHandler();

              this.handleOffcanvasClose();

            }

          });

         }



  componentDidMount() {

    this.RequeststatusfetchHandler();

    

     //***componentDidMount

  }

  handleintegIdChange = (e) => {

  e.preventDefault();

  const fieldValue = e.target.value;

  this.setState({integId: fieldValue });

};handlerequestIdChange = (e) => {

  e.preventDefault();

  const fieldValue = e.target.value;

  this.setState({requestId: fieldValue });

};handlerequestStatusChange = (e) => {

  e.preventDefault();

  const fieldValue = e.target.value;

  this.setState({requestStatus: fieldValue });

};handleresponseIdChange = (e) => {

  e.preventDefault();

  const fieldValue = e.target.value;

  this.setState({responseId: fieldValue });

};handleresponseStatusChange = (e) => {

  e.preventDefault();

  const fieldValue = e.target.value;

  this.setState({responseStatus: fieldValue });

};

  handledummyOneChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({dummyOne: fieldValue });
};handledummyTwoChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({dummyTwo: fieldValue });
};handledummyThreeChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({dummyThree: fieldValue });
};handledummyFourChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({dummyFour: fieldValue });
};handledummyFiveChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({dummyFive: fieldValue });
};handledummysixChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({dummysix: fieldValue });
};handledummySevenChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({dummySeven: fieldValue });
};handledummyEightChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({dummyEight: fieldValue });
};handledummyNineChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({dummyNine: fieldValue });
};handledummyTenChange = (e) => {
  e.preventDefault();
  const fieldValue = e.target.value;
  this.setState({dummyTen: fieldValue });
};//***inputFunctions

  handleRowSelect = (row) => {

    this.setState({

      selectedRow: row.index,

    });

  };

  handleRowUpdate = (newData, oldData) => {

    const data = [...this.state.data];

    const index = oldData.index;

    data[index] = newData;

    this.setState({ data });

  };



  handleEdit = (row) => {

    console.log(`Editing row with ID ${row.id}`);

    // implement your edit logic here

  };

handleDelete = (row, index) => {

  var formData = new FormData();

    formData.append('requeststatus_Id', row.requeststatus_Id);

    fetch(configData.api_url +'C_Requeststatus/delete', {

          method: 'POST', 

        mode: 'cors', 

        body: formData

      }).then(response => response.json())

      .then((data) => {

        this.showToast(data.message, data.status);

        if (data.status === "success") {

          this.RequeststatusfetchHandler();

        }

      });

  };

  handlePageChange = (page) => {

    this.setState({ currentPage: page });

  };



  handlePerRowsChange = (newPerPage, page) => {

    this.setState({ itemsPerPage: newPerPage, currentPage: page });

  };



  handleFilter = (e) => {

    const searchValue = e.target.value.toLowerCase();

    const filteredData = this.state.data.filter((row) => {

      return Object.values(row).join(" ").toLowerCase().includes(searchValue);

    });

    this.setState({ filteredData });

  };



  render() {

    const paginatedData = this.state.filteredData

      ? this.state.filteredData.slice(

          (this.state.currentPage - 1) * this.state.itemsPerPage,

          this.state.currentPage * this.state.itemsPerPage

        )

      : this.state.data.slice(

          (this.state.currentPage - 1) * this.state.itemsPerPage,

          this.state.currentPage * this.state.itemsPerPage

        );



    return (

      <div className="page-title">

      <div className="row">

        <div className="col-md-12 col-sm-12 col-xs-12">

          <div className="x_panel">

            <div className="x_content">

              <div

                id="datatable_wrapper"

                className="dataTables_wrapper form-inline

                dt-bootstrap no-footer"

              >

                <Row className="row">

                <div>

                 <h3 className="logoskybluecolor" style={{ textAlign: "start" }}>Request Status</h3>

                </div>

            <Col

            className="d-flex justify-content-end"

            style={{ marginRight: "5px", marginTop: "20px" }}

          >

            

            <Offcanvas

              show={this.state.show}

              onHide={this.handleOffcanvasClose}

              {...this.props}

              style={{ width: "500px" }}

              placement="end"

              backdrop="true"

            >

              <Offcanvas.Header closeButton>

                <Offcanvas.Title>Requeststatus page</Offcanvas.Title>

              </Offcanvas.Header>

              <Offcanvas.Body>

                <Container style={{ "overflowY": "auto", height: "auto" }}>

                <Row className="divstyle">

                    <Row>

                      <Form>

                  <Row>

  <Col  lg="4" md="4">

  <Form.Label style={{ paddingTop: "8px" }}>IntegId</Form.Label>

  </Col>

  <Col lg="8" md="8">

  <Form.Control type="number" onChange={this.handleintegIdChange} className="form-control" name="integId" value={this.state.integId} required="required" placeholder="Enter IntegId" />

  </Col>  

  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.integId} </div>

</Row><br/><Row>

  <Col  lg="4" md="4">

  <Form.Label style={{ paddingTop: "8px" }}>Request ID</Form.Label>

  </Col>

  <Col lg="8" md="8">

  <Form.Control type="number" onChange={this.handlerequestIdChange} className="form-control" name="requestId" value={this.state.requestId} required="required" placeholder="Enter Request ID" />

  </Col>  

  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.requestId} </div>

</Row><br/><Row>

  <Col  lg="4" md="4">

  <Form.Label style={{ paddingTop: "8px" }}>Request Status</Form.Label>

  </Col>

  <Col lg="8" md="8">

  <Form.Control type="text" onChange={this.handlerequestStatusChange} className="form-control" name="requestStatus" value={this.state.requestStatus} required="required" placeholder="Enter Request Status" />

  </Col>  

  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.requestStatus} </div>

</Row><br/><Row>

  <Col  lg="4" md="4">

  <Form.Label style={{ paddingTop: "8px" }}>Response ID</Form.Label>

  </Col>

  <Col lg="8" md="8">

  <Form.Control type="number" onChange={this.handleresponseIdChange} className="form-control" name="responseId" value={this.state.responseId} required="required" placeholder="Enter Response ID" />

  </Col>  

  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.responseId} </div>

</Row><br/><Row>

  <Col  lg="4" md="4">

  <Form.Label style={{ paddingTop: "8px" }}>Response Status</Form.Label>

  </Col>

  <Col lg="8" md="8">

  <Form.Control type="text" onChange={this.handleresponseStatusChange} className="form-control" name="responseStatus" value={this.state.responseStatus} required="required" placeholder="Enter Response Status" />

  </Col>  

  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.responseStatus} </div>

</Row><br/>

                  <Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Dummy One</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handledummyOneChange} className="form-control" name="dummyOne" value={this.state.dummyOne} required="required" placeholder="Enter Dummy One" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummyOne} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Dummy Two</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handledummyTwoChange} className="form-control" name="dummyTwo" value={this.state.dummyTwo} required="required" placeholder="Enter Dummy Two" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummyTwo} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Dummy Three</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handledummyThreeChange} className="form-control" name="dummyThree" value={this.state.dummyThree} required="required" placeholder="Enter Dummy Three" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummyThree} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Dummy Four</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handledummyFourChange} className="form-control" name="dummyFour" value={this.state.dummyFour} required="required" placeholder="Enter Dummy Four" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummyFour} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Dummy Five</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handledummyFiveChange} className="form-control" name="dummyFive" value={this.state.dummyFive} required="required" placeholder="Enter Dummy Five" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummyFive} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Dummy Six</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handledummysixChange} className="form-control" name="dummysix" value={this.state.dummysix} required="required" placeholder="Enter Dummy Six" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummysix} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Dummy Seven</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handledummySevenChange} className="form-control" name="dummySeven" value={this.state.dummySeven} required="required" placeholder="Enter Dummy Seven" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummySeven} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Dummy Eight</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handledummyEightChange} className="form-control" name="dummyEight" value={this.state.dummyEight} required="required" placeholder="Enter Dummy Eight" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummyEight} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Dummy Nine</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handledummyNineChange} className="form-control" name="dummyNine" value={this.state.dummyNine} required="required" placeholder="Enter Dummy Nine" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummyNine} </div>
</Row><br/><Row>
  <Col  lg="4" md="4">
  <Form.Label style={{ paddingTop: "8px" }}>Dummy Ten</Form.Label>
  </Col>
  <Col lg="8" md="8">
  <Form.Control type="text" onChange={this.handledummyTenChange} className="form-control" name="dummyTen" value={this.state.dummyTen} required="required" placeholder="Enter Dummy Ten" />
  </Col>  
  <div className="errorMsg" style={{ color: "red" }}> {this.state.errors.dummyTen} </div>
</Row><br/>{/* tableContent */}

                  </Form>

                  </Row>

                  </Row>

                  <Row>

                    <Col

                      className="d-flex justify-content-end"

                      style={{ marginRight: "50px" }}

                    >

                      <Button

                        className="button"

                        variant="success"

                        onClick={this.RequeststatussubmitHandler}

                        style={{

                          marginTop: "20px",

                        }}

                      >

                        Save

                      </Button>

                    </Col>

                  </Row>

                </Container>

              </Offcanvas.Body>

            </Offcanvas>

        {/*offcanvas is over */}

                  </Col> 

                  </Row>

        <br />

        <Row>

<div style={{"marginRight":"5px"}}>

  {/* searchbar,pagination,filter */}

  <input

    type="text"

    style={{ float: "left", marginBottom: "10px" }}

    onChange={this.handleFilter}

    placeholder="Search..."

  />

  <Button

    variant="primary"

    className="bi bi-plus "  

    style={{ float: "right", marginBottom: "10px" }}

    onClick={this.handleOffcanvasShow}

  >

    New

  </Button>

  <DataTable

    data={paginatedData}

    columns={this.state.tableClms}

    pagination

    paginationServer

    paginationTotalRows={

      this.state.filteredData

        ? this.state.filteredData.length

        : this.state.data.length

    }

    onChangeRowsPerPage={this.handlePerRowsChange}

    onChangePage={this.handlePageChange}

    paginationPerPage={this.state.itemsPerPage}

    keyField="id"

    className="table table-striped table-bordered dataTable no-footer employeesTableIcons"

  />

</div>

  </Row>



                </div>

              </div>

            </div>

          </div>

        </div>

      </div>



    );

  }

}

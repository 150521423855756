import React, { Component } from "react";
import { Button, Container, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";

export default class Bookedpoliciesnonlife extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.bookedpoliciesnonlife_Id,
          sortable: true,
          cell: (row) => <span>{row.bookedpoliciesnonlife_Id}</span>,
        },
        {
          name: "Insurer Name",
          selector: (row, i) => row.InsurerName,
          sortable: true,
          cell: (row) => <span>{row.InsurerName}</span>,
        },
        {
          name: "Customer Name",
          selector: (row, i) => row.CustomerName,
          sortable: true,
          cell: (row) => <span>{row.CustomerName}</span>,
        },
        {
          name: "Policy No",
          selector: (row, i) => row.PolicyNo,
          sortable: true,
          cell: (row) => <span>{row.PolicyNo}</span>,
        },
        {
          name: "Product",
          selector: (row, i) => row.Product,
          sortable: true,
          cell: (row) => <span>{row.Product}</span>,
        },
        {
          name: "IRDA Categorization",
          selector: (row, i) => row.IRDACategorization,
          sortable: true,
          cell: (row) => <span>{row.IRDACategorization}</span>,
        },
        {
          name: "Risk Start Date",
          selector: (row, i) => row.RiskStartDate,
          sortable: true,
          cell: (row) => <span>{row.RiskStartDate}</span>,
        },
        {
          name: "Risk End Date",
          selector: (row, i) => row.RiskEndDate,
          sortable: true,
          cell: (row) => <span>{row.RiskEndDate}</span>,
        },
        {
          name: "Sum Insured",
          selector: (row, i) => row.SumInsured,
          sortable: true,
          cell: (row) => <span>{row.SumInsured}</span>,
        },
        {
          name: "Basic Od Premium",
          selector: (row, i) => row.BasicOdPremium,
          sortable: true,
          cell: (row) => <span>{row.BasicOdPremium}</span>,
        },
        {
          name: "Basic Od Commission",
          selector: (row, i) => row.BasicOdCommission,
          sortable: true,
          cell: (row) => <span>{row.BasicOdCommission}</span>,
        },
        {
          name: "TP Premium",
          selector: (row, i) => row.TPPremium,
          sortable: true,
          cell: (row) => <span>{row.TPPremium}</span>,
        },
        {
          name: "TP Premium Commission",
          selector: (row, i) => row.TPPremiumCommission,
          sortable: true,
          cell: (row) => <span>{row.TPPremiumCommission}</span>,
        },
        {
          name: "Net Premium",
          selector: (row, i) => row.NetPremium,
          sortable: true,
          cell: (row) => <span>{row.NetPremium}</span>,
        },
        {
          name: "SGST",
          selector: (row, i) => row.SGST,
          sortable: true,
          cell: (row) => <span>{row.SGST}</span>,
        },
        {
          name: "CGST",
          selector: (row, i) => row.CGST,
          sortable: true,
          cell: (row) => <span>{row.CGST}</span>,
        },
        {
          name: "IGST",
          selector: (row, i) => row.IGST,
          sortable: true,
          cell: (row) => <span>{row.IGST}</span>,
        },
        {
          name: "Total GST",
          selector: (row, i) => row.TotalGST,
          sortable: true,
          cell: (row) => <span>{row.TotalGST}</span>,
        },
        {
          name: "Gross Premium",
          selector: (row, i) => row.GrossPremium,
          sortable: true,
          cell: (row) => <span>{row.GrossPremium}</span>,
        },
        {
          name: "Sales Manager",
          selector: (row, i) => row.SalesManager,
          sortable: true,
          cell: (row) => <span>{row.SalesManager}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen"
                style={{ marginRight: "3px" }}
              ></Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      nonlifeinsurancecompanies: [],
      InsurerName: "",
      CustomerName: "",
      PolicyNo: "",
      Product: "",
      irdainsurancetypesnonlife: [],
      IRDACategorization: "",
      RiskStartDate: "",
      RiskEndDate: "",
      SumInsured: "",
      BasicOdPremium: "",
      BasicOdCommission: "",
      TPPremium: "",
      TPPremiumCommission: "",
      NetPremium: "",
      SGST: "",
      CGST: "",
      IGST: "",
      TotalGST: "",
      GrossPremium: "",
      employees: [],
      SalesManager: "",
      bookedpoliciesnonlife_Id: "",
      isEditing: false, // Flag to indicate edit mode
      isNewEntry: false,
    };
  }
  //editing data
  handleEnterEditMode = (row) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    this.setState({
      InsurerName: row.InsurerName,
      CustomerName: row.CustomerName,
      PolicyNo: row.PolicyNo,
      Product: row.Product,
      IRDACategorization: row.IRDACategorization,
      RiskStartDate: row.RiskStartDate,
      RiskEndDate: row.RiskEndDate,
      SumInsured: row.SumInsured,
      BasicOdPremium: row.BasicOdPremium,
      BasicOdCommission: row.BasicOdCommission,
      TPPremium: row.TPPremium,
      TPPremiumCommission: row.TPPremiumCommission,
      NetPremium: row.NetPremium,
      SGST: row.SGST,
      CGST: row.CGST,
      IGST: row.IGST,
      TotalGST: row.TotalGST,
      GrossPremium: row.GrossPremium,
      SalesManager: row.SalesManager,
      bookedpoliciesnonlife_Id: row.bookedpoliciesnonlife_Id,
      isEditing: true,
    });
    // this.setState({ cData: { ...row }, isEditing: true });
  };
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({
      show: true,
      InsurerName: "",
      CustomerName: "",
      PolicyNo: "",
      Product: "",
      IRDACategorization: "",
      RiskStartDate: "",
      RiskEndDate: "",
      SumInsured: "",
      BasicOdPremium: "",
      BasicOdCommission: "",
      TPPremium: "",
      TPPremiumCommission: "",
      NetPremium: "",
      SGST: "",
      CGST: "",
      IGST: "",
      TotalGST: "",
      GrossPremium: "",
      SalesManager: "",
      bookedpoliciesnonlife_Id: "",
    });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  BookedpoliciesnonlifeeditHandler = (e) => {
    e.preventDefault();
    // const updateFormData = {...editFormData};
    // updateFormData['Bookedpoliciesnonlife_Id'] = e.target.parentElement.getAttribute("id");
    // setEditFormData(updateFormData);
    // setEdit(true);
  };

  BookedpoliciesnonlifefetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Bookedpoliciesnonlife/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  validateForm() {
    let data = this.state;
    let errors = {};
    let formIsValid = true;
    if (!data["InsurerName"]) {
      formIsValid = false;
      toast.info("*Please Enter  Insurer Name.");
    } else if (!data["CustomerName"]) {
      formIsValid = false;
      toast.info("*Please Enter Customer Name.");
    } else if (!data["PolicyNo"]) {
      formIsValid = false;
      toast.info("*Please Enter PolicyNo.");
    } else if (!data["Product"]) {
      formIsValid = false;
      toast.info("*Please Enter Product.");
    } else if (!data["IRDACategorization"]) {
      formIsValid = false;
      toast.info("*Please Enter IRDA Categorization.");
    } else if (!data["RiskStartDate"]) {
      formIsValid = false;
      toast.info("*Please Enter Risk StartDate.");
    } else if (!data["RiskEndDate"]) {
      formIsValid = false;
      toast.info("*Please Enter Risk EndDate.");
    } else if (!data["SumInsured"]) {
      formIsValid = false;
      toast.info("*Please Enter Sum Insured.");
    } else if (!data["BasicOdPremium"]) {
      formIsValid = false;
      toast.info("*Please Enter Basic OdPremium.");
    } else if (!data["BasicOdCommission"]) {
      formIsValid = false;
      toast.info("*Please Enter Basic OdCommission.");
    } else if (!data["TPPremium"]) {
      formIsValid = false;
      toast.info("*Please Enter TPPremium.");
    } else if (!data["TPPremiumCommission"]) {
      formIsValid = false;
      toast.info("*Please Enter TPPremium Commission.");
    } else if (!data["NetPremium"]) {
      formIsValid = false;
      toast.info("*Please Enter NetPremium.");
    } else if (!data["SGST"]) {
      formIsValid = false;
      toast.info("*Please Enter SGST.");
    } else if (!data["CGST"]) {
      formIsValid = false;
      toast.info("*Please Enter CGST.");
    } else if (!data["IGST"]) {
      formIsValid = false;
      toast.info("*Please Enter IGST.");
    } else if (!data["TotalGST"]) {
      formIsValid = false;
      toast.info("*Please Enter Total GST.");
    } else if (!data["GrossPremium"]) {
      formIsValid = false;
      toast.info("*Please Enter Gross Premium.");
    } else if (!data["SalesManager"]) {
      formIsValid = false;
      toast.info("*Please Enter Sales Manager.");
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  BookedpoliciesnonlifesubmitHandler = (e) => {
    if (this.validateForm()) {
      e.preventDefault();
      //   setButtons(contacts);
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("InsurerName", this.state.InsurerName);
      formData.append("CustomerName", this.state.CustomerName);
      formData.append("PolicyNo", this.state.PolicyNo);
      formData.append("Product", this.state.Product);
      formData.append("IRDACategorization", this.state.IRDACategorization);
      formData.append("RiskStartDate", this.state.RiskStartDate);
      formData.append("RiskEndDate", this.state.RiskEndDate);
      formData.append("SumInsured", this.state.SumInsured);
      formData.append("BasicOdPremium", this.state.BasicOdPremium);
      formData.append("BasicOdCommission", this.state.BasicOdCommission);
      formData.append("TPPremium", this.state.TPPremium);
      formData.append("TPPremiumCommission", this.state.TPPremiumCommission);
      formData.append("NetPremium", this.state.NetPremium);
      formData.append("SGST", this.state.SGST);
      formData.append("CGST", this.state.CGST);
      formData.append("IGST", this.state.IGST);
      formData.append("TotalGST", this.state.TotalGST);
      formData.append("GrossPremium", this.state.GrossPremium);
      formData.append("SalesManager", this.state.SalesManager);

      fetch(configData.api_url + "C_Bookedpoliciesnonlife/add", {
        // Enter your IP address here

        method: "POST",
        //headers :{ 'Content-Type' : 'application/json'},
        mode: "cors",
        body: formData, // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            this.BookedpoliciesnonlifefetchHandler();
            this.handleOffcanvasClose();
            this.setState({
              sId: "",
              uId: "",
              InsurerName: "",
              CustomerName: "",
              PolicyNo: "",
              Product: "",
              IRDACategorization: "",
              RiskStartDate: "",
              RiskEndDate: "",
              SumInsured: "",
              BasicOdPremium: "",
              BasicOdCommission: "",
              TPPremium: "",
              TPPremiumCommission: "",
              NetPremium: "",
              SGST: "",
              CGST: "",
              IGST: "",
              TotalGST: "",
              GrossPremium: "",
              SalesManager: "",
              bookedpoliciesnonlife_Id: "",
            });
          }
        });
    }
  };
  BookedpoliciesnonlifeupdateHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    if (this.validateForm()) {
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("InsurerName", this.state.InsurerName);
      formData.append("CustomerName", this.state.CustomerName);
      formData.append("PolicyNo", this.state.PolicyNo);
      formData.append("Product", this.state.Product);
      formData.append("IRDACategorization", this.state.IRDACategorization);
      formData.append("RiskStartDate", this.state.RiskStartDate);
      formData.append("RiskEndDate", this.state.RiskEndDate);
      formData.append("SumInsured", this.state.SumInsured);
      formData.append("BasicOdPremium", this.state.BasicOdPremium);
      formData.append("BasicOdCommission", this.state.BasicOdCommission);
      formData.append("TPPremium", this.state.TPPremium);
      formData.append("TPPremiumCommission", this.state.TPPremiumCommission);
      formData.append("NetPremium", this.state.NetPremium);
      formData.append("SGST", this.state.SGST);
      formData.append("CGST", this.state.CGST);
      formData.append("IGST", this.state.IGST);
      formData.append("TotalGST", this.state.TotalGST);
      formData.append("GrossPremium", this.state.GrossPremium);
      formData.append("SalesManager", this.state.SalesManager);
      formData.append(
        "bookedpoliciesnonlife_Id",
        this.state.bookedpoliciesnonlife_Id
      );

      fetch(configData.api_url + "C_Bookedpoliciesnonlife/update", {
        // Enter your IP address here

        method: "POST",
        //headers :{ 'Content-Type' : 'application/json'},
        mode: "cors",
        body: formData, // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            this.BookedpoliciesnonlifefetchHandler();
            this.handleOffcanvasClose();
            this.setState({
              InsurerName: "",
              CustomerName: "",
              PolicyNo: "",
              Product: "",
              IRDACategorization: "",
              RiskStartDate: "",
              RiskEndDate: "",
              SumInsured: "",
              BasicOdPremium: "",
              BasicOdCommission: "",
              TPPremium: "",
              TPPremiumCommission: "",
              NetPremium: "",
              SGST: "",
              CGST: "",
              IGST: "",
              TotalGST: "",
              GrossPremium: "",
              SalesManager: "",
              bookedpoliciesnonlife_Id: "",
              isEditing: true,
            });
          }
        });
    }
  };
  componentDidMount() {
    this.BookedpoliciesnonlifefetchHandler();
    this.nonlifeinsurancecompaniesfetchHandler();
    this.irdainsurancetypesnonlifefetchHandler();
    this.employeesfetchHandler();
  }
  handleInsurerNameChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ InsurerName: fieldValue });
  };
  nonlifeinsurancecompaniesfetchHandler = () => {
    fetch(configData.api_url + "C_Nonlifeinsurancecompanies/list", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ nonlifeinsurancecompanies: data.list });
        }
      });
  };
  handleCustomerNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ CustomerName: fieldValue });
  };
  handlePolicyNoChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ PolicyNo: fieldValue });
  };
  handleProductChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ Product: fieldValue });
  };
  handleIRDACategorizationChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ IRDACategorization: fieldValue });
  };
  irdainsurancetypesnonlifefetchHandler = () => {
    fetch(configData.api_url + "C_Irdainsurancetypesnonlife/list", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ irdainsurancetypesnonlife: data.list });
        }
      });
  };
  handleRiskStartDateChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ RiskStartDate: fieldValue });
  };
  handleRiskEndDateChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ RiskEndDate: fieldValue });
  };
  handleSumInsuredChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ SumInsured: fieldValue });
  };
  handleBasicOdPremiumChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ BasicOdPremium: fieldValue });
  };
  handleBasicOdCommissionChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ BasicOdCommission: fieldValue });
  };
  handleTPPremiumChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ TPPremium: fieldValue });
  };
  handleTPPremiumCommissionChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ TPPremiumCommission: fieldValue });
  };
  handleNetPremiumChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ NetPremium: fieldValue });
  };
  handleSGSTChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ SGST: fieldValue });
  };
  handleCGSTChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ CGST: fieldValue });
  };
  handleIGSTChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ IGST: fieldValue });
  };
  handleTotalGSTChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ TotalGST: fieldValue });
  };
  handleGrossPremiumChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ GrossPremium: fieldValue });
  };
  handleSalesManagerChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ SalesManager: fieldValue });
  };
  employeesfetchHandler = () => {
    fetch(configData.api_url + "C_Employees/list", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ employees: data.list });
        }
      });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
    console.log(`Editing row with ID ${row.id}`);
    // implement your edit logic here
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    const bookedpoliciesnonlife_Id = row.bookedpoliciesnonlife_Id;
    formData.append("bookedpoliciesnonlife_Id", bookedpoliciesnonlife_Id);
    fetch(configData.api_url + "C_Bookedpoliciesnonlife/delete", {
      method: "POST",
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.BookedpoliciesnonlifefetchHandler();
        }
      });
    //implement your delete logic here
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div className="page-title">
        <div className="row">
          <div className="title_left">
            <h2 style={{ textAlign: "start" }}>Booked policies non-life</h2>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                                   dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "5px", marginTop: "20px" }}
                    >
                      <Button
                        variant="primary"
                        className="bi bi-plus "
                        onClick={this.handleOffcanvasShow}
                      >
                        New
                      </Button>
                      <Offcanvas
                        show={this.state.show}
                        onHide={this.handleOffcanvasClose}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            Booked policies non-life
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                            <Row className="divstyle">
                              <Row>
                                <Form>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Insurer Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <select
                                        className="form-select"
                                        name="tableName"
                                        onChange={this.handleInsurerNameChange}
                                      >
                                        <option defaultValue>
                                          {" "}
                                          Select Table{" "}
                                        </option>
                                        {this.state.nonlifeinsurancecompanies.map(
                                          (api, index, idx) => (
                                            <option
                                              key={index}
                                              value={
                                                api.NonLifeInsuranceCompanies
                                              }
                                            >
                                              {" "}
                                              {
                                                api.NonLifeInsuranceCompanies
                                              }{" "}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.InsurerName}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Customer Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleCustomerNameChange}
                                        className="form-control"
                                        name="CustomerName"
                                        value={this.state.CustomerName}
                                        required="required"
                                        placeholder="Enter CustomerName"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.CustomerName}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Policy No
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlePolicyNoChange}
                                        className="form-control"
                                        name="PolicyNo"
                                        value={this.state.PolicyNo}
                                        required="required"
                                        placeholder="Enter PolicyNo"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.PolicyNo}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Product
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleProductChange}
                                        className="form-control"
                                        name="Product"
                                        value={this.state.Product}
                                        required="required"
                                        placeholder="Enter Product"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.Product}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        IRDA Categorization
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <select
                                        className="form-select"
                                        name="tableName"
                                        onChange={
                                          this.handleIRDACategorizationChange
                                        }
                                      >
                                        <option defaultValue>
                                          {" "}
                                          Select Table{" "}
                                        </option>
                                        {this.state.irdainsurancetypesnonlife.map(
                                          (api, index, idx) => (
                                            <option
                                              key={index}
                                              value={api.IRDAInsuranceType}
                                            >
                                              {" "}
                                              {api.IRDAInsuranceType}{" "}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.IRDACategorization
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Risk Start Date
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="date"
                                        onChange={
                                          this.handleRiskStartDateChange
                                        }
                                        className="form-control"
                                        name="RiskStartDate"
                                        value={this.state.RiskStartDate}
                                        required="required"
                                        placeholder="Enter RiskStartDate"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.RiskStartDate}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Risk End Date
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="date"
                                        onChange={this.handleRiskEndDateChange}
                                        className="form-control"
                                        name="RiskEndDate"
                                        value={this.state.RiskEndDate}
                                        required="required"
                                        placeholder="Enter RiskEndDate"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.RiskEndDate}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Sum Insured
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleSumInsuredChange}
                                        className="form-control"
                                        name="SumInsured"
                                        value={this.state.SumInsured}
                                        required="required"
                                        placeholder="Enter SumInsured"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.SumInsured}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Basic Od Premium
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleBasicOdPremiumChange
                                        }
                                        className="form-control"
                                        name="BasicOdPremium"
                                        value={this.state.BasicOdPremium}
                                        required="required"
                                        placeholder="Enter BasicOdPremium"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.BasicOdPremium}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Basic Od Commission
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleBasicOdCommissionChange
                                        }
                                        className="form-control"
                                        name="BasicOdCommission"
                                        value={this.state.BasicOdCommission}
                                        required="required"
                                        placeholder="Enter BasicOdCommission"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.BasicOdCommission}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        TP Premium
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleTPPremiumChange}
                                        className="form-control"
                                        name="TPPremium"
                                        value={this.state.TPPremium}
                                        required="required"
                                        placeholder="Enter TPPremium"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.TPPremium}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        TP Premium Commission
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleTPPremiumCommissionChange
                                        }
                                        className="form-control"
                                        name="TPPremiumCommission"
                                        value={this.state.TPPremiumCommission}
                                        required="required"
                                        placeholder="Enter TPPremiumCommission"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.TPPremiumCommission
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Net Premium
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleNetPremiumChange}
                                        className="form-control"
                                        name="NetPremium"
                                        value={this.state.NetPremium}
                                        required="required"
                                        placeholder="Enter NetPremium"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.NetPremium}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        SGST
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleSGSTChange}
                                        className="form-control"
                                        name="SGST"
                                        value={this.state.SGST}
                                        required="required"
                                        placeholder="Enter SGST"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.SGST}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        CGST
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleCGSTChange}
                                        className="form-control"
                                        name="CGST"
                                        value={this.state.CGST}
                                        required="required"
                                        placeholder="Enter CGST"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.CGST}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        IGST
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleIGSTChange}
                                        className="form-control"
                                        name="IGST"
                                        value={this.state.IGST}
                                        required="required"
                                        placeholder="Enter IGST"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.IGST}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Total GST
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleTotalGSTChange}
                                        className="form-control"
                                        name="TotalGST"
                                        value={this.state.TotalGST}
                                        required="required"
                                        placeholder="Enter TotalGST"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.TotalGST}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Gross Premium
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleGrossPremiumChange}
                                        className="form-control"
                                        name="GrossPremium"
                                        value={this.state.GrossPremium}
                                        required="required"
                                        placeholder="Enter GrossPremium"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.GrossPremium}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Sales Manager
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <select
                                        className="form-select"
                                        name="tableName"
                                        onChange={this.handleSalesManagerChange}
                                      >
                                        <option defaultValue>
                                          {" "}
                                          Select Table{" "}
                                        </option>
                                        {this.state.employees.map(
                                          (api, index, idx) => (
                                            <option
                                              key={index}
                                              value={api.rmId}
                                            >
                                              {" "}
                                              {api.fName}{" "}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.SalesManager}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                </Form>
                              </Row>
                            </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={
                                    this.BookedpoliciesnonlifesubmitHandler
                                  }
                                  style={{
                                    marginTop: "20px",
                                  }}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                      {/*offcanvas is over */}
                    </Col>
                  </Row>
                  <br />
                  <div>
                    {" "}
                    {this.state.isEditing && (
                      // Edit Offcanvas
                      <Offcanvas
                        show={this.state.isEditing}
                        onHide={() => this.setState({ isEditing: false })}
                        {...this.props}
                        style={{ width: "500px", height: "auto" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            Edit Booked policies non-life
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <div className="form-horizontal form-label-left input_mask">
                            <Form className="form-horizontal form-label-left input_mask">
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    Insurer Name
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <select
                                    className="form-select"
                                    name="tableName"
                                    value={this.state.InsurerName}
                                    onChange={this.handleInsurerNameChange}
                                  >
                                    <option defaultValue> Select Table </option>
                                    {this.state.nonlifeinsurancecompanies.map(
                                      (api, index, idx) => (
                                        <option
                                          key={index}
                                          value={api.NonLifeInsuranceCompanies}
                                        >
                                          {" "}
                                          {api.NonLifeInsuranceCompanies}{" "}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.InsurerName}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    Customer Name
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleCustomerNameChange}
                                    className="form-control"
                                    name="CustomerName"
                                    value={this.state.CustomerName}
                                    required="required"
                                    placeholder="Enter CustomerName"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.CustomerName}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    Policy No
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handlePolicyNoChange}
                                    className="form-control"
                                    name="PolicyNo"
                                    value={this.state.PolicyNo}
                                    required="required"
                                    placeholder="Enter PolicyNo"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.PolicyNo}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    Product
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleProductChange}
                                    className="form-control"
                                    name="Product"
                                    value={this.state.Product}
                                    required="required"
                                    placeholder="Enter Product"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.Product}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    IRDA Categorization
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <select
                                    className="form-select"
                                    name="tableName"
                                    value={this.state.IRDACategorization}
                                    onChange={
                                      this.handleIRDACategorizationChange
                                    }
                                  >
                                    <option defaultValue> Select Table </option>
                                    {this.state.irdainsurancetypesnonlife.map(
                                      (api, index, idx) => (
                                        <option
                                          key={index}
                                          value={api.IRDAInsuranceType}
                                        >
                                          {" "}
                                          {api.IRDAInsuranceType}{" "}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.IRDACategorization}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    Risk Start Date
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="date"
                                    onChange={this.handleRiskStartDateChange}
                                    className="form-control"
                                    name="RiskStartDate"
                                    value={this.state.RiskStartDate}
                                    required="required"
                                    placeholder="Enter RiskStartDate"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.RiskStartDate}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    Risk End Date
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="date"
                                    onChange={this.handleRiskEndDateChange}
                                    className="form-control"
                                    name="RiskEndDate"
                                    value={this.state.RiskEndDate}
                                    required="required"
                                    placeholder="Enter RiskEndDate"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.RiskEndDate}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    Sum Insured
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleSumInsuredChange}
                                    className="form-control"
                                    name="SumInsured"
                                    value={this.state.SumInsured}
                                    required="required"
                                    placeholder="Enter SumInsured"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.SumInsured}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    Basic Od Premium
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleBasicOdPremiumChange}
                                    className="form-control"
                                    name="BasicOdPremium"
                                    value={this.state.BasicOdPremium}
                                    required="required"
                                    placeholder="Enter BasicOdPremium"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.BasicOdPremium}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    Basic Od Commission
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={
                                      this.handleBasicOdCommissionChange
                                    }
                                    className="form-control"
                                    name="BasicOdCommission"
                                    value={this.state.BasicOdCommission}
                                    required="required"
                                    placeholder="Enter BasicOdCommission"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.BasicOdCommission}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    TP Premium
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleTPPremiumChange}
                                    className="form-control"
                                    name="TPPremium"
                                    value={this.state.TPPremium}
                                    required="required"
                                    placeholder="Enter TPPremium"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.TPPremium}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    TP Premium Commission
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={
                                      this.handleTPPremiumCommissionChange
                                    }
                                    className="form-control"
                                    name="TPPremiumCommission"
                                    value={this.state.TPPremiumCommission}
                                    required="required"
                                    placeholder="Enter TPPremiumCommission"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.TPPremiumCommission}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    Net Premium
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleNetPremiumChange}
                                    className="form-control"
                                    name="NetPremium"
                                    value={this.state.NetPremium}
                                    required="required"
                                    placeholder="Enter NetPremium"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.NetPremium}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    SGST
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleSGSTChange}
                                    className="form-control"
                                    name="SGST"
                                    value={this.state.SGST}
                                    required="required"
                                    placeholder="Enter SGST"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.SGST}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    CGST
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleCGSTChange}
                                    className="form-control"
                                    name="CGST"
                                    value={this.state.CGST}
                                    required="required"
                                    placeholder="Enter CGST"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.CGST}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    IGST
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleIGSTChange}
                                    className="form-control"
                                    name="IGST"
                                    value={this.state.IGST}
                                    required="required"
                                    placeholder="Enter IGST"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.IGST}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    Total GST
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleTotalGSTChange}
                                    className="form-control"
                                    name="TotalGST"
                                    value={this.state.TotalGST}
                                    required="required"
                                    placeholder="Enter TotalGST"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.TotalGST}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    Gross Premium
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleGrossPremiumChange}
                                    className="form-control"
                                    name="GrossPremium"
                                    value={this.state.GrossPremium}
                                    required="required"
                                    placeholder="Enter GrossPremium"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.GrossPremium}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    Sales Manager
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <select
                                    className="form-select"
                                    name="tableName"
                                    value={this.state.SalesManager}
                                    onChange={this.handleSalesManagerChange}
                                  >
                                    <option defaultValue> Select Table </option>
                                    {this.state.employees.map(
                                      (api, index, idx) => (
                                        <option key={index} value={api.rmId}>
                                          {" "}
                                          {api.fName}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.SalesManager}{" "}
                                </div>
                              </Row>
                              <br />
                              <br />
                              <div style={{ float: "right" }}>
                                <button
                                  className="btn btn-primary me-2 mt-5"
                                  style={{
                                    backgroundColor: "#23547e",
                                    color: "#fff",
                                    borderColor: "#2e6da4",
                                  }}
                                  onClick={
                                    this.BookedpoliciesnonlifeupdateHandler
                                  }
                                >
                                  Update
                                </button>
                              </div>
                            </Form>
                          </div>
                        </Offcanvas.Body>
                      </Offcanvas>
                    )}
                  </div>
                  <br />

                  <Row>
                    <div style={{ marginRight: "5px" }}>
                      {/* searchbar,pagination,filter */}
                      <input
                        type="text"
                        style={{ float: "left", marginBottom: "10px" }}
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        paginationRowsPerPageOptions={[
                          10, 20, 30, 50, 60, 70, 80, 90, 100,
                        ]}
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

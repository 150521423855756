import React, { Component } from "react";
import "./PoliciesFormFillingPage.css";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import configData from "../config.json";
import {
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
  Button,
  Offcanvas,
} from "react-bootstrap";

export default class PoliciesFormFillingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chpiId: sessionStorage.getItem("chpiId"),
      proposalId: sessionStorage.getItem("proposalId"),
      cqrId: sessionStorage.getItem("cqrId"),
      show: false,
      policiestab2: [],
      policiesform: [],
      members: [],
      policiesTable: [],
      mandatoryaddons: [],
      optionaladdons: [],
      showExistingSince: {},
      isSameAsPermanent: false,
      dateValues: {},
      tab1Data: {
        addresstype: "PERMANENT",
        line1: "",
        line2: "",
        areaCd: "",
        cityCd: "",
        stateCd: "",
        pincode: "",
        paddresstype: "COMMUNICATION",
        pline1: "",
        pline2: "",
        pareaCd: "",
        pcityCd: "",
        pstateCd: "",
        ppincode: "",
        mobile: "",
        mobiletype: "",
        mobilecode: "",
        emailId: "",
        emailtype: "",
        cname: "",
        clname: "",
      },
      tab2Data: {
        titleCd: "",
        fname: "",
        lname: "",
        age: "",
        relation: "",
        height: "",
        weight: "",
        cname: "",
        dob: "",
        gender: "",
        lhmId: "",
        annualincome: "",
        maritalstatus: "",
      },
      tab3Data: {
        medicalQns: "",
        productCode: "",
        questionSetCode: "",
        questionCode: "",
        qnDesc: "",
        newDesc: "",
      },
      tab4Data: {
        name: "",
        dob: "",
        relation: "",
        mobile: "",
      },
      tab5Data: {
        caresupremeaddons_Id: "",
        addONCode: "",
        isChecked: "",
      },
      consentCheckbox: false,
      declarationCheckbox: false,
      activeTabIndex: 0,
      pStatus: 2,
      firstTabInput: "",
    };
    // console.log(this.props.params.itId);
  }
  // Offcanvas for edit

  handleCheckboxChange = (checkboxName) => {
    this.setState((prevState) => ({
      [checkboxName]: !prevState[checkboxName],
    }));
  };
  componentDidMount() {
    this.apisfetchHandler();
    // this.logCheckboxState();
    // this.loadBasics();
  }
  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    fetch(configData.api_url + "leads/getleaddetails/", {
      method: "POST",
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          this.setState({ members: data.lead.members });
          this.CaresupremeaddonsfetchHandler();
          // console.log(this.state.members);
        }
      });
  };
  CaresupremeaddonsfetchHandler = () => {
    var formData = new FormData();
    formData.append("chpiId", this.state.chpiId);
    fetch(configData.api_url + "leads/getproductdetails", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ mandatoryaddons: data.product.mandatory_addons });
          this.setState({ optionaladdons: data.product.optional_addons });
          this.setState({ policiesTable: data.product.medicalqns });
          this.setMedicalQuestions();
        }
      });
  };

  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };

  handleInputChange = (tab, field, value) => {
    this.setState((prevState) => ({
      [tab]: {
        ...prevState[tab],
        [field]: value,
      },
    }));
  };

  //tab1data details

  handleSameCheckboxChange = () => {
    const { tab1Data, isSameAsPermanent } = this.state;
    tab1Data.pline1 = tab1Data.line1;
    tab1Data.pline2 = tab1Data.line2;
    tab1Data.pareaCd = tab1Data.areaCd;
    tab1Data.pcityCd = tab1Data.cityCd;
    tab1Data.pstateCd = tab1Data.stateCd;
    tab1Data.ppincode = tab1Data.pincode;
    this.setState({
      isSameAsPermanent: !isSameAsPermanent,
      tab1Data: tab1Data,
    });
  };

  handleInputChange1 = (tab, field, value) => {
    this.setState((prevState) => ({
      [tab]: {
        ...prevState[tab],
        [field]: value,
      },
    }));
    if (this.state.isSameAsPermanent) {
      const field1 = "p" + field;
      this.setState((prevState) => ({
        [tab]: {
          ...prevState[tab],
          [field1]: value,
        },
      }));
    }
  };

  checkValidateTab5 = () => {
    const { tab1Data } = this.state;
    // console.log("tabq");
    if (tab1Data.addresstype === "") {
      return false;
    }
    if (tab1Data.areaCd === "") {
      return false;
    }
    if (tab1Data.stateCd === "") {
      return false;
    }
    if (tab1Data.city === "") {
      return false;
    }
    if (tab1Data.pincode === "") {
      return false;
    }
    if (tab1Data.mobile === "") {
      return false;
    }
    if (tab1Data.emailId === "") {
      return false;
    }
    if (tab1Data.emailtype === "") {
      return false;
    }
    if (this.state.pStatus === 2) {
      // console.log(this.state.pStatus);
      this.submitTab1();
    }
    return true;
  };
  errorMessages = () => {
    const { tab1Data } = this.state;
    let formIsValid = true;

    if (tab1Data.addresstype === "") {
      formIsValid = false;
      this.showToast("*Please pass Addresstype.", "error");
    }
    if (tab1Data.areaCd === "") {
      formIsValid = false;
      this.showToast("*Please Pass Area.", "error");
    }
    if (tab1Data.stateCd === "") {
      formIsValid = false;
      this.showToast("*Please Pass State.", "error");
    }
    if (!/^\d{10}$/.test(tab1Data.mobile)) {
      formIsValid = false;
      this.showToast("*Mobile/Landline should be 10 digits long.", "error");
    }
    if (
      !tab1Data.emailId ||
      !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(tab1Data.emailId)
    ) {
      formIsValid = false;
      if (!tab1Data.emailId) {
        this.showToast("*Please Enter an Email Address.", "error");
      } else {
        this.showToast("*Please Enter a Valid Email Address.", "error");
      }
    }
    if (tab1Data.emailtype === "") {
      formIsValid = false;
      this.showToast("*Please Pass Email Type.", "error");
    }

    console.log(formIsValid);
    return formIsValid;
  };
  //  in mail and mobile, zipcode adding spaces
  onKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault();
      toast.error("Spaces are not allowed.");
    }
  }
  addressApiSubmit = () => {
    const addressesList = [
      {
        addresstype: this.state.tab1Data.addresstype,
        line1: this.state.tab1Data.line1,
        line2: this.state.tab1Data.line2,
        areaCd: this.state.tab1Data.areaCd,
        cityCd: this.state.tab1Data.cityCd,
        stateCd: this.state.tab1Data.stateCd,
        pincode: this.state.tab1Data.pincode,
      },
      {
        addresstype: this.state.tab1Data.paddresstype,
        line1: this.state.tab1Data.pline1,
        line2: this.state.tab1Data.pline2,
        areaCd: this.state.tab1Data.pareaCd,
        cityCd: this.state.tab1Data.pcityCd,
        stateCd: this.state.tab1Data.pstateCd,
        pincode: this.state.tab1Data.ppincode,
      },
      // Add more address objects if needed
    ];
    var fd = new FormData();
    fd.append("proposalId", this.state.proposalId);
    fd.append("address", JSON.stringify(addressesList));
    // console.log(this.state.policiestab2);
    fetch(configData.api_url + "leads/updateleadaddress/", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      mode: "cors",
      body: fd, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({
            pStatus: this.state.pStatus + 1,
          });
        }
      });
  };
  contactApiSubmit = () => {
    var fd = new FormData();
    fd.append("proposalId", this.state.proposalId);
    fd.append("mobile", this.state.tab1Data.mobile);
    fd.append("mobiletype", this.state.tab1Data.mobiletype);
    fd.append("mobilecode", this.state.tab1Data.mobilecode);
    fetch(configData.api_url + "leads/updateleadcontact/", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      mode: "cors",
      body: fd, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          // this.setState({
          //   pStatus: this.state.pStatus + 1,
          // });
        }
      });
  };
  emailApiSubmit = () => {
    var fd = new FormData();
    fd.append("proposalId", this.state.proposalId);
    fd.append("emailId", this.state.tab1Data.emailId);
    fd.append("emailtype", this.state.tab1Data.emailtype);
    // console.log(this.state.policiestab2);
    fetch(configData.api_url + "leads/updateleademaildetails/", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      mode: "cors",
      body: fd, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          // this.setState({
          //   pStatus: this.state.pStatus + 1,
          // });
        }
      });
  };
  submitTab1 = () => {
    // if (this.errorMessages()) {
    this.addressApiSubmit();
    this.contactApiSubmit();
    this.emailApiSubmit();
    // }
  };
  //Tab2data
  handlefNameEditChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ fname: fieldValue });
  };

  handlelNameEditChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ lname: fieldValue });
  };

  handleDateEditChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    this.setState((prevState) => ({
      [fieldName]: fieldValue, // Use fieldName directly
    }));
  };

  ageChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    this.setState((prevState) => ({
      [fieldName]: fieldValue, // Use fieldName directly
    }));
  };

  genderChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    this.setState((prevState) => ({
      [fieldName]: fieldValue, // Use fieldName directly
    }));
  };

  handlerelationEditChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ relation: fieldValue });
  };
  handleheightEditChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ height: fieldValue });
  };
  handleweightEditChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ weight: fieldValue });
  };
  handleOffcanvasShow = () => {
    this.setState({ show: true });
  };

  StatesupdateHandler = (e) => {
    e.preventDefault();

    // Validate input fields
    if (!this.validateFields()) {
      // Fields are not valid, return or handle accordingly
      return;
    }

    const updatedMembers = [...this.state.members];
    const memberIndex = updatedMembers.findIndex(
      (member) => member.lhmId === this.state.lhmId
    );

    updatedMembers[memberIndex] = {
      ...updatedMembers[memberIndex],
      fname: this.state.fname,
      lname: this.state.lname,
      gender: this.state.gender,
      relation: this.state.relation,
      height: this.state.height,
      weight: this.state.weight,
    };

    this.setState(
      {
        members: updatedMembers,
        isEditing: false,
      },
      () => {
        this.showToast("Data updated successfully", "success");
      }
    );
  };

  validateFields = () => {
    const { fname, lname, height, weight } = this.state;

    // Check if any of the required fields are empty
    if (!fname || !lname || !height || !weight) {
      // Handle validation error, show message or perform any necessary action
      this.showToast("Please fill in all required fields.", "error");
      return false;
    }

    // Additional validations if needed...

    return true;
  };

  handleEnterEditMode = (row) => {
    console.log("Original row data:", row);
    this.setState({
      fname: row.fname,
      lname: row.lname,
      age: row.age,
      dob: row.dob,
      gender: row.gender,
      relation: row.relation,
      height: row.height,
      weight: row.weight,
      lhmId: row.lhmId,
      titleCd: row.titleCd,
      isEditing: true,
    });

    console.log("Updated state:", this.state);
  };

  checkValidateTab1 = () => {
    if (this.state.pStatus === 3) this.submitTab2();
    return true;
  };
  submitTab2 = () => {
    var fd = new FormData();
    fd.append("proposalId", this.state.proposalId);
    fd.append("mebers", JSON.stringify(this.state.members));

    // console.log(this.state.policiestab2);
    fetch(configData.api_url + "leads/updateleadmembers/", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      mode: "cors",
      body: fd, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({
            pStatus: this.state.pStatus + 1,
          });
        }
      });
  };
  //tab3 details
  setMedicalQuestions = () => {
    const initialState = {};
    this.state.policiesTable.forEach((medicals) => {
      var isChecked = "No";
      if (medicals.qnDesc === "Existing since? (MM/YYYY)") isChecked = "";
      this.state.members.forEach((member) => {
        if (!initialState[medicals.caresuprememedicalqns_Id]) {
          initialState[medicals.caresuprememedicalqns_Id] = {};
        }
        initialState[medicals.caresuprememedicalqns_Id][member.lhmId] =
          isChecked;
      });
    });
    this.setState({
      showExistingSince: initialState,
    });
    console.log(initialState);
  };

  handleMedicalCheckboxChange = (fieldName) => {
    this.setState((prevState) => ({
      tab3Data: {
        ...prevState.tab3Data,
        [fieldName]: !prevState.tab3Data[fieldName],
      },
    }));
  };
  handleCheckboxMedChange = (questionId, memberId, isChecked, dateValue) => {
    this.setState((prevState) => {
      var newShowExistingSince = { ...prevState.showExistingSince };
      console.log(dateValue, "dateValue");
      console.log(newShowExistingSince[questionId][memberId]);
      var dateValue1 = dateValue;
      if (isChecked === "check") {
        dateValue1 = "No";
        if (dateValue) dateValue1 = "Yes";
      }
      console.log(dateValue1);
      newShowExistingSince[questionId][memberId] = dateValue1;
      console.log(newShowExistingSince[questionId][memberId]);
      return {
        showExistingSince: newShowExistingSince,
      };
    });
  };
  checkValidateTab2 = () => {
    // console.log(this.state.tab3Data);

    if (this.state.pStatus === 4) this.submitTab3();
    return true;
  };
  errorMessages2 = () => {
    // you can add alert or console.log or any thing you want
    let formIsValid = true;

    return formIsValid;
  };
  submitTab3 = () => {
    const allQuestionData = [];
    // Loop through all checked checkboxes and add question data to the array
    const { showExistingSince } = this.state;
    this.state.policiesTable.forEach((medicals) => {
      this.state.members.forEach((member) => {
        let ans =
          showExistingSince[medicals.caresuprememedicalqns_Id]?.[member.lhmId];
        // Add question data to the array
        allQuestionData.push({
          csmqId: medicals.caresuprememedicalqns_Id,
          ans: ans,
          lhmId: member.lhmId,
          questionSetCode: medicals.questionSetCode,
          questionCode: medicals.questionCode,
        });
      });
    });

    const fd = new FormData();
    fd.append("proposalId", this.state.proposalId);
    fd.append("qns", JSON.stringify(allQuestionData));
    fetch(configData.api_url + "leads/updatemedicalqns/", {
      method: "POST",
      headers: {},
      mode: "cors",
      body: fd,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          console.log(fd);
          this.setState({
            pStatus: this.state.pStatus + 1,
          });
        }
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });
  };
  //Tab4 Details
  checkValidateTab4 = () => {
    const { tab4Data } = this.state;
    //console.log(this.state.tab1Data);
    if (tab4Data.name === "") {
      return false;
    }
    if (tab4Data.dob === "") {
      return false;
    }
    if (tab4Data.relation === "") {
      return false;
    }
    if (tab4Data.mobile === "") {
      return false;
    }
    if (this.state.pStatus === 5) this.submitTab4();
    return true;
  };
  errorMessages4 = () => {
    // you can add alert or console.log or any thing you want
    const { tab4Data } = this.state;
    let formIsValid = true;
    if (tab4Data.name === "") {
      formIsValid = false;
      this.showToast("Please Fill In The Nominee Name.", "error"); // Use toast to display error messages
    }
    if (tab4Data.dob === "") {
      formIsValid = false;
      this.showToast("*Please Pass Nominee dob.", "error");
    }
    if (tab4Data.relation === "") {
      formIsValid = false;
      this.showToast("*Please Pass Nominee Relation.", "error");
    }
    if (tab4Data.mobile === "") {
      formIsValid = false;
      this.showToast("*Please Pass Nominee Mobile.", "error");
    }
    return formIsValid;
  };
  submitTab4 = (e) => {
    if (this.errorMessages4()) {
      var fd = new FormData();
      fd.append("proposalId", this.state.proposalId);
      fd.append("name", this.state.tab4Data.name);
      fd.append("dob", this.state.tab4Data.dob);
      fd.append("relation", this.state.tab4Data.relation);
      fd.append("mobile", this.state.tab4Data.mobile);
      fetch(configData.api_url + "leads/updatenominee/", {
        method: "POST",
        headers: {
          // "Content-Type": "application/json",
        },
        mode: "cors",
        body: fd, // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            //window.location.href = "/";
          }
        });
      //window.location.href = "/policiespagepdf/";
    }
  };
  //Tab5 Finish data
  handleAddonsCheckboxChange = (addONCode, caresupremeaddons_Id, isChecked) => {
    this.setState((prevState) => {
      const updatedAddonsData = prevState.mandatoryaddons.map((quote) => {
        if (quote.addONCode === addONCode) {
          return { ...quote, dummyOne: isChecked ? "Yes" : "No" };
        }
        return quote;
      });
      return { mandatoryaddons: updatedAddonsData };
    });

    console.log("Addon Code:", addONCode);
    console.log("Product ID:", caresupremeaddons_Id);
    console.log("Checkbox Value:", isChecked ? "Yes" : "No");
  };
  handleOptionalCheckboxChange = (
    addONCode,
    caresupremeaddons_Id,
    isChecked
  ) => {
    this.setState((prevState) => {
      const updatedAddonsData = prevState.optionaladdons.map((quote) => {
        if (quote.addONCode === addONCode) {
          return { ...quote, dummyOne: isChecked ? "Yes" : "No" };
        }
        return quote;
      });
      return { optionaladdons: updatedAddonsData };
    });

    console.log("Addon Code:", addONCode);
    console.log("Product ID:", caresupremeaddons_Id);
    console.log("Checkbox Value:", isChecked ? "Yes" : "No");
  };

  handleComplete = (e) => {
    // Filter out the selected checkboxes from mandatoryaddons
    const addons = [
      ...this.state.mandatoryaddons,
      ...this.state.optionaladdons,
    ];
    console.log(addons);
    const selectedAddons = addons.filter((quote) => quote.dummyOne);

    // Create an array of selected addons with the required fields
    const selectedAddonsList = selectedAddons.map((quote) => ({
      aoId: quote.caresupremeaddons_Id,
      addONCode: quote.addONCode,
      isChecked: quote.dummyOne || "Yes",
    }));
    console.log(selectedAddonsList);
    // Prepare the FormData
    const formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    formData.append("addons", JSON.stringify(selectedAddonsList));
    // Make the API request
    fetch(configData.api_url + "leads/updateleadaddons/", {
      method: "POST",
      headers: {},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          // Handle success, e.g., redirect or update state
          // this.createPolicy();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  createPolicy() {
    const formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    formData.append("chpiId", this.state.chpiId);
    formData.append("cqrId", this.state.cqrId);
    fetch(configData.api_url + "leads/cr/", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
      },
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          sessionStorage.setItem(
            "policyNum",
            data.intPolicyDataIO.policy.proposalNum
          );
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  render() {
    const { policiesTable, showExistingSince, members } = this.state;

    return (
      <div>
        <h3 className="logoskybluecolor">PoliciesFormFillingPage</h3>
        <div className="container thm-container bgColorContainer">
          <div className="row comparePolicyScreenTopHeading">
            <div className="col-md-3">
              <Link to="/">
                <span className="bi bi-chevron-left" aria-hidden="true">
                  Back To Quotes
                </span>
              </Link>
            </div>
            <div className="col-md-3">
              {" "}
              Self
              <Link>
                <span className="bi bi-pencil-square" aria-hidden="true"></span>
              </Link>
            </div>
            <div className="col-md-3"> &nbsp; </div>
            <div className="col-md-3"> &nbsp; </div>
          </div>
          <div className="row">
            <br />
            <input type="hidden" id="itId" value="3" />
            <input type="hidden" id="custId" value="337" />
            <input type="hidden" id="plcId" value="1581" />
          </div>
          <div>
            <div className="row">&nbsp;</div>
            <div className="row compareDetailssection">
              <div className="col-md-12 compareDetailssectionleft">
                <br />
                <FormWizard onComplete={this.handleComplete}>
                  <FormWizard.TabContent title="Proposal Info" icon="ti-user">
                    <div>
                      <Container className="bgalphacolor">
                        <Row>
                          <Col>
                            <Card className="card-default">
                              <Card.Body>
                                {/* <Form> */}
                                <Row>
                                  <Col md="4" lg="4">
                                    <Form.Group className="form-group">
                                      <Form.Label className="control-label">
                                        First Name
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="cname"
                                        id="cname"
                                        value={this.state.tab1Data.cname}
                                        onChange={(e) =>
                                          this.handleInputChange(
                                            "tab1Data",
                                            "cname",
                                            e.target.value
                                          )
                                        }
                                        className="form-control"
                                        aria-describedby="enterName"
                                        placeholder="Enter Name"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md="4" lg="4">
                                    <Form.Group className="form-group">
                                      <Form.Label className="control-label">
                                        Last Name
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="clname"
                                        id="clname"
                                        value={this.state.tab1Data.clname}
                                        onChange={(e) =>
                                          this.handleInputChange(
                                            "tab1Data",
                                            "clname",
                                            e.target.value
                                          )
                                        }
                                        className="form-control"
                                        aria-describedby="enterName"
                                        placeholder="Enter Last Name"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <hr />
                                  <Form.Label className="control-label">
                                    Permanent Address
                                  </Form.Label>
                                  <hr />
                                  <br />
                                  <Col md="4" lg="4">
                                    <Form.Group className="form-group">
                                      <Form.Label className="control-label">
                                        Address Line1
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="line1"
                                        value={this.state.tab1Data.line1}
                                        id="line1"
                                        onChange={(e) =>
                                          this.handleInputChange1(
                                            "tab1Data",
                                            "line1",
                                            e.target.value
                                          )
                                        }
                                        placeholder="Address Line1"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md="4" lg="4">
                                    <Form.Group className="form-group">
                                      <Form.Label className="control-label">
                                        Address Line2
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        name="line2"
                                        id="line2"
                                        value={this.state.tab1Data.line2}
                                        onChange={(e) =>
                                          this.handleInputChange1(
                                            "tab1Data",
                                            "line2",
                                            e.target.value
                                          )
                                        }
                                        aria-describedby="line2"
                                        placeholder="Address Line2"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md="4" lg="4">
                                    <Form.Group className="form-group">
                                      <Form.Label className="control-label">
                                        Area
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        name="areaCd"
                                        id="areaCd"
                                        value={this.state.tab1Data.areaCd}
                                        onChange={(e) =>
                                          this.handleInputChange1(
                                            "tab1Data",
                                            "areaCd",
                                            e.target.value
                                          )
                                        }
                                        aria-describedby="areaCd"
                                        placeholder="Area"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md="4" lg="4">
                                    <Form.Group className="form-group">
                                      <Form.Label className="control-label">
                                        City
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        name="cityCd"
                                        id="cityCd"
                                        value={this.state.tab1Data.cityCd}
                                        onChange={(e) =>
                                          this.handleInputChange1(
                                            "tab1Data",
                                            "cityCd",
                                            e.target.value
                                          )
                                        }
                                        aria-describedby="cityCd"
                                        placeholder="City"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md="4" lg="4">
                                    <Form.Group className="form-group">
                                      <Form.Label className="control-label">
                                        State
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        id="stateCd"
                                        aria-describedby="State"
                                        name="stateCd"
                                        value={this.state.tab1Data.stateCd}
                                        onChange={(e) =>
                                          this.handleInputChange1(
                                            "tab1Data",
                                            "stateCd",
                                            e.target.value
                                          )
                                        }
                                        placeholder="State"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md="4" lg="4">
                                    <Form.Group className="form-group">
                                      <Form.Label className="control-label">
                                        Pin Code
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="pincode"
                                        id="pincode"
                                        onKeyDown={this.onKeyDown}
                                        value={this.state.tab1Data.pincode}
                                        onChange={(e) =>
                                          this.handleInputChange1(
                                            "tab1Data",
                                            "pincode",
                                            e.target.value
                                          )
                                        }
                                        className="form-control"
                                        aria-describedby="pincode"
                                        placeholder="Pin Code"
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <hr />
                                <Row>
                                  <Col>
                                    {" "}
                                    <Form.Label className="control-label">
                                      Communication Address
                                    </Form.Label>
                                  </Col>
                                  <Col>
                                    <Form.Group className="form-group">
                                      <Form.Check
                                        style={{ float: "unset" }}
                                        type="checkbox"
                                        label="  Same as Permanent Address"
                                        checked={this.state.isSameAsPermanent}
                                        onChange={this.handleSameCheckboxChange}
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <hr />
                                <Row>
                                  <Col md="4" lg="4">
                                    <Form.Group className="form-group">
                                      <Form.Label className="control-label">
                                        Address Line1
                                      </Form.Label>
                                      <Form.Control
                                        className="form-control"
                                        id="pline1"
                                        name="pline1"
                                        value={this.state.tab1Data.pline1}
                                        onChange={(e) =>
                                          this.handleInputChange(
                                            "tab1Data",
                                            "pline1",
                                            e.target.value
                                          )
                                        }
                                        type="text"
                                        placeholder="Address Line1"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md="4" lg="4">
                                    <Form.Group className="form-group">
                                      <Form.Label className="control-label">
                                        Address Line2
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        name="pline2"
                                        id="pline2"
                                        value={this.state.tab1Data.pline2}
                                        onChange={(e) =>
                                          this.handleInputChange(
                                            "tab1Data",
                                            "pline2",
                                            e.target.value
                                          )
                                        }
                                        aria-describedby="pline2"
                                        placeholder="Address Line2"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md="4" lg="4">
                                    <Form.Group className="form-group">
                                      <Form.Label className="control-label">
                                        Area
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        name="pareaCd"
                                        id="pareaCd"
                                        value={this.state.tab1Data.pareaCd}
                                        onChange={(e) =>
                                          this.handleInputChange(
                                            "tab1Data",
                                            "pareaCd",
                                            e.target.value
                                          )
                                        }
                                        aria-describedby="pareaCd"
                                        placeholder="Area"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md="4" lg="4">
                                    <Form.Group className="form-group">
                                      <Form.Label className="control-label">
                                        City
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        id="pcityCd"
                                        name="pcityCd"
                                        value={this.state.tab1Data.pcityCd}
                                        onChange={(e) =>
                                          this.handleInputChange(
                                            "tab1Data",
                                            "pcityCd",
                                            e.target.value
                                          )
                                        }
                                        aria-describedby="pcityCd"
                                        placeholder="City"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md="4" lg="4">
                                    <Form.Group className="form-group">
                                      <Form.Label className="control-label">
                                        State
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        id="pstate"
                                        name="pstateCd"
                                        value={this.state.tab1Data.pstateCd}
                                        onChange={(e) =>
                                          this.handleInputChange(
                                            "tab1Data",
                                            "pstateCd",
                                            e.target.value
                                          )
                                        }
                                        aria-describedby="pstateCd"
                                        placeholder="State"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md="4" lg="4">
                                    <Form.Group className="form-group">
                                      <Form.Label className="control-label">
                                        Pin Code
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="ppincode"
                                        value={this.state.tab1Data.ppincode}
                                        onChange={(e) =>
                                          this.handleInputChange(
                                            "tab1Data",
                                            "ppincode",
                                            e.target.value
                                          )
                                        }
                                        id="ppincode"
                                        className="form-control"
                                        aria-describedby="ppincode"
                                        placeholder="Pin Code"
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <hr />
                                <Row>
                                  <Col md="4" lg="4">
                                    <Form.Group className="form-group">
                                      <Form.Label className="control-label">
                                        Contact Type Cd
                                      </Form.Label>
                                      <Form.Select
                                        name="mobiletype"
                                        className="form-control"
                                        onChange={(e) =>
                                          this.handleInputChange(
                                            "tab1Data",
                                            "mobiletype",
                                            e.target.value
                                          )
                                        }
                                        id="mobiletype"
                                        value={this.state.tab1Data.mobiletype}
                                        aria-describedby="mobiletype"
                                        placeholder="mobiletype"
                                      >
                                        <option value="">Select</option>
                                        <option value="Mobile">Mobile</option>
                                        <option value="Landline">
                                          Landline
                                        </option>
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                  <Col md="4" lg="4">
                                    <Form.Group className="form-group">
                                      <Form.Label className="control-label">
                                        Contact/Landline No.
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="mobile"
                                        className="form-control"
                                        onChange={(e) =>
                                          this.handleInputChange(
                                            "tab1Data",
                                            "mobile",
                                            e.target.value
                                          )
                                        }
                                        id="mobile"
                                        value={this.state.tab1Data.mobile}
                                        aria-describedby="mobile"
                                        placeholder="Mobile/Landline"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md="4" lg="4">
                                    <Form.Group className="form-group">
                                      <Form.Label className="control-label">
                                        Std/ISD Code
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="mobilecode"
                                        className="form-control"
                                        onChange={(e) =>
                                          this.handleInputChange(
                                            "tab1Data",
                                            "mobilecode",
                                            e.target.value
                                          )
                                        }
                                        id="mobilecode"
                                        value={this.state.tab1Data.mobilecode}
                                        aria-describedby="mobilecode"
                                        placeholder="STD Code"
                                      />
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md="4" lg="4">
                                    <Form.Group className="form-group">
                                      <Form.Label className="control-label">
                                        Email Address
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="emailId"
                                        onKeyDown={this.onKeyDown}
                                        value={this.state.tab1Data.emailId}
                                        onChange={(e) =>
                                          this.handleInputChange(
                                            "tab1Data",
                                            "emailId",
                                            e.target.value
                                          )
                                        }
                                        id="emailId"
                                        className="form-control"
                                        aria-describedby="emailId"
                                        placeholder="Email"
                                      />
                                    </Form.Group>
                                  </Col>
                                  <Col md="4" lg="4">
                                    <Form.Group className="form-group">
                                      <Form.Label className="control-label">
                                        Email Type Cd
                                      </Form.Label>
                                      <Form.Select
                                        name="emailtype"
                                        className="form-control"
                                        onChange={(e) =>
                                          this.handleInputChange(
                                            "tab1Data",
                                            "emailtype",
                                            e.target.value
                                          )
                                        }
                                        id="emailtype"
                                        value={this.state.tab1Data.emailtype}
                                        aria-describedby="emailtype"
                                      >
                                        <option value="">Select</option>
                                        <option value="Personal">
                                          Personal
                                        </option>
                                        <option value="Corporate">
                                          Corporate
                                        </option>
                                      </Form.Select>
                                    </Form.Group>
                                  </Col>
                                </Row>
                                {/* </Form> */}
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </FormWizard.TabContent>
                  {/* Tabs should be validated */}
                  <FormWizard.TabContent
                    title="Member Details"
                    icon="ti-id-badge"
                    isValid={this.checkValidateTab5()}
                    validationError={this.errorMessages}
                  >
                    <div className="tab-content" id="myTabContent"></div>
                    <div className="container">
                      {/* <Form> */}
                      <Row>
                        <Col className="table-responsive PoliciesFormFillingPage">
                          <Table className="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">S No.</th>
                                <th scope="col" style={{ width: "100px" }}>
                                  First Name
                                </th>
                                <th scope="col" style={{ width: "100px" }}>
                                  Last Name
                                </th>
                                <th scope="col" style={{ width: "100px" }}>
                                  Date Of Birth
                                </th>
                                <th scope="col" style={{ width: "50px" }}>
                                  Age
                                </th>
                                <th scope="col" style={{ width: "70px" }}>
                                  Gender (F/M)
                                </th>
                                <th scope="col">Relation with Proposer</th>
                                <th scope="col" style={{ width: "50px" }}>
                                  Height (feet/inch)
                                </th>
                                <th scope="col">Weight (Kgs)</th>
                                <th scope="col" style={{ width: "50px" }}>
                                  InhId
                                </th>
                                <th scope="col">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {members.map((quote, index) => (
                                <tr key={index}>
                                  <td className="text-center">{index + 1}</td>
                                  <td className="text-center">{quote.fname}</td>
                                  <td className="text-center">{quote.lname}</td>
                                  <td className="text-center">{quote.dob}</td>
                                  <td className="text-center">{quote.age}</td>
                                  <td className="text-center">
                                    {quote.gender}
                                  </td>
                                  <td className="text-center">
                                    {quote.relation}
                                  </td>
                                  <td className="text-center">
                                    {quote.height}
                                  </td>
                                  <td className="text-center">
                                    {quote.weight}
                                  </td>

                                  <td className="text-center">{quote.lhmId}</td>

                                  <td>
                                    <Button
                                      variant="primary"
                                      onClick={() =>
                                        this.handleEnterEditMode(quote)
                                      }
                                      className="bi bi-pen"
                                    ></Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col style={{ float: "right" }}>
                          <Button
                            className="btn btn primary"
                            id="tabindex2"
                            onClick={this.submitTab2}
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        {this.state.isEditing && (
                          // Edit Offcanvas
                          <Offcanvas
                            show={this.state.isEditing}
                            onHide={() => this.setState({ isEditing: false })}
                            {...this.props}
                            style={{ width: "400px", height: "auto" }}
                            placement="end"
                            backdrop="true"
                          >
                            <Offcanvas.Header closeButton>
                              <h6>Member Details</h6>
                            </Offcanvas.Header>
                            <Offcanvas.Body className="m-3">
                              <Row>
                                <Col>
                                  <Form.Label>First Name</Form.Label>{" "}
                                </Col>
                                <Col>
                                  {" "}
                                  <Form.Control
                                    type="text"
                                    onChange={this.handlefNameEditChange}
                                    className="form-control"
                                    name="fname"
                                    value={this.state.fname}
                                    required="required"
                                    placeholder="Enter First Name"
                                  />
                                </Col>
                              </Row>
                              <br />
                              <Row>
                                <Col>
                                  <Form.Label>Last Name</Form.Label>{" "}
                                </Col>
                                <Col>
                                  {" "}
                                  <Form.Control
                                    type="text"
                                    onChange={this.handlelNameEditChange}
                                    className="form-control"
                                    name="lname"
                                    value={this.state.lname}
                                    required="required"
                                    placeholder="Enter Last Name"
                                  />
                                </Col>
                              </Row>
                              <br />
                              <Row>
                                <Col>
                                  <Form.Label>D.O.B</Form.Label>
                                </Col>
                                <Col>
                                  <Form.Group className="form-group">
                                    <div className="input-group">
                                      <Form.Control
                                        className="date dob hasDatepicker"
                                        type="date"
                                        name="dob"
                                        value={this.state.dob}
                                        disabled
                                        placeholder="Enter mobile No"
                                        id="dob"
                                        onChange={this.handleDateEditChange}
                                      />
                                    </div>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <br />
                              <Row>
                                <Col>
                                  <Form.Label>Age</Form.Label>{" "}
                                </Col>
                                <Col>
                                  {" "}
                                  <Form.Group className="form-group">
                                    <Form.Control
                                      name="age"
                                      value={
                                        isNaN(this.state.age)
                                          ? 0
                                          : this.state.age
                                      }
                                      onChange={this.ageChange}
                                      disabled
                                      readOnly
                                      className="age"
                                      type="text"
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                              <br />
                              <Row>
                                <Col>
                                  <Form.Label>Gender</Form.Label>{" "}
                                </Col>
                                <Col>
                                  <Form.Group className="form-group">
                                    <Form.Select
                                      aria-label="Default select example"
                                      className="form-control"
                                      name="gender"
                                      value={this.state.gender}
                                      onChange={this.genderChange}
                                    >
                                      <option value="male">Male</option>
                                      <option value="female">Female</option>
                                      <option value="other">Other</option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Form.Label>Relation</Form.Label>{" "}
                                </Col>
                                <Col>
                                  <Form.Group className="form-group">
                                    <Form.Select
                                      name="relation"
                                      value={this.state.relation}
                                      onChange={this.handlerelationEditChange}
                                      className="relation"
                                      type="text"
                                    >
                                      <option>Select</option>
                                      <option value="SELF">Self</option>
                                      <option value="FATH">Father</option>
                                      <option value="MOTH">Mother</option>
                                      <option value="SISTER">Sister</option>
                                      <option value="BROTHER">Brother</option>
                                      <option value="SPSE">Spouse</option>
                                      <option value="SONM">Son</option>
                                      <option value="UDTR">Daughter</option>
                                      <option value="FLAW">
                                        FATHER IN LAW
                                      </option>
                                      <option value="MLAW">
                                        MOTHER IN LAW
                                      </option>
                                    </Form.Select>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <br />
                              <Row>
                                <Col>
                                  <Form.Label>Height in Cm</Form.Label>{" "}
                                </Col>
                                <Col>
                                  {" "}
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleheightEditChange}
                                    className="form-control"
                                    name="height"
                                    value={this.state.height}
                                    required="required"
                                    placeholder="Enter Height in C.Mtr"
                                  />
                                </Col>
                              </Row>
                              <br />
                              <Row>
                                <Col>
                                  <Form.Label>Weight in K.gs</Form.Label>{" "}
                                </Col>
                                <Col>
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleweightEditChange}
                                    className="form-control"
                                    name="weight"
                                    value={this.state.weight}
                                    required="required"
                                    placeholder="Enter weight in K.gs"
                                  />
                                </Col>
                              </Row>
                              <div style={{ float: "right" }}>
                                <button
                                  className="btn btn-danger me-2 mt-5"
                                  onClick={() =>
                                    this.setState({ isEditing: false })
                                  }
                                >
                                  Close
                                </button>
                              </div>
                              <div style={{ float: "right" }}>
                                <button
                                  className="btn btn-primary me-2 mt-5"
                                  style={{
                                    backgroundColor: "#23547e",
                                    color: "#fff",
                                    borderColor: "#2e6da4",
                                  }}
                                  onClick={this.StatesupdateHandler}
                                >
                                  Update
                                </button>
                              </div>
                            </Offcanvas.Body>
                          </Offcanvas>
                        )}
                      </Row>
                      {/* </Form> */}
                    </div>
                  </FormWizard.TabContent>

                  <FormWizard.TabContent
                    title="Medicals"
                    isValid={this.checkValidateTab1()}
                    validationError={this.errorMessages1}
                    icon="ti-check-box"
                  >
                    <div>
                      .
                      <div className="col">
                        <Table>
                          <thead>
                            <tr>
                              <th>S.no</th>
                              <th>Set Code</th>
                              <th>Questions</th>
                              <th>Questions def</th>
                              {members.map((member) => (
                                <th key={member.relation}>
                                  {member.fname} {member.lname}
                                  <div>
                                    <label>No P.E.D</label>
                                    <input type="checkbox" />
                                  </div>
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {policiesTable.map((medicals, index) => (
                              <tr key={index}>
                                <td>{medicals.caresuprememedicalqns_Id}</td>
                                <td>{medicals.questionSetCode}</td>
                                <td>{medicals.questionCode}</td>
                                <td>{medicals.qnDesc}</td>
                                {members.map((member) => (
                                  <td key={member.relation}>
                                    {medicals.qnDesc ===
                                      "Existing since? (MM/YYYY)" ? (
                                      <input
                                        type="month"
                                        name={`checkboxmonth-${medicals.caresuprememedicalqns_Id}-${member.lhmId}`}
                                        value={
                                          this.state.showExistingSince[
                                          medicals.caresuprememedicalqns_Id
                                          ]?.[member.lhmId] || ""
                                        }
                                        onChange={(e) =>
                                          this.handleCheckboxMedChange(
                                            medicals.caresuprememedicalqns_Id,
                                            member.lhmId,
                                            "month",
                                            e.target.value
                                          )
                                        }
                                      />
                                    ) : (
                                      <input
                                        type="checkbox"
                                        checked={
                                          showExistingSince[
                                          medicals.caresuprememedicalqns_Id
                                          ]?.[member.relation]
                                        }
                                        onChange={(e) =>
                                          this.handleCheckboxMedChange(
                                            medicals.caresuprememedicalqns_Id,
                                            member.lhmId,
                                            "check",
                                            !showExistingSince[
                                            medicals.caresuprememedicalqns_Id
                                            ]?.[member.relation],
                                            ""
                                          )
                                        }
                                      />
                                    )}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                      <Row className="mt-4">
                        <Col style={{ float: "right" }}>
                          <Button
                            className="btn btn primary"
                            id="tabindex3"
                            onClick={this.submitTab3}
                          >
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </FormWizard.TabContent>

                  <FormWizard.TabContent
                    title="Nominee Details"
                    isValid={this.checkValidateTab2()}
                    validationError={this.errorMessages2}
                    icon="ti-pencil-alt"
                  >
                    <div>
                      <Container className="bgalphacolor">
                        <Row>
                          <Col>
                            <Card className="card-default">
                              <Card.Body>
                                {/* <Form> */}
                                <Row>
                                  <Col>
                                    <table className="table table-bordered">
                                      <thead>
                                        <tr>
                                          <th scope="col">Name of Nominee *</th>
                                          <th scope="col">Date of Birth *</th>
                                          <th scope="col">
                                            Relation with Proposer *
                                          </th>
                                          <th scope="col">
                                            Mobile of Nominee *
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr key="nominee">
                                          <td>
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="name "
                                              name="name"
                                              value={this.state.tab4Data.name}
                                              onChange={(e) =>
                                                this.handleInputChange(
                                                  "tab4Data",
                                                  "name",
                                                  e.target.value
                                                )
                                              }
                                              aria-describedby="name"
                                              placeholder="Nominee Name"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              type="date"
                                              className="form-control"
                                              id="dateOfBirth"
                                              value={this.state.tab4Data.dob}
                                              onChange={(e) =>
                                                this.handleInputChange(
                                                  "tab4Data",
                                                  "dob",
                                                  e.target.value
                                                )
                                              }
                                              name="dob"
                                              aria-describedby="dob"
                                              placeholder="Date Of Birth"
                                            />
                                          </td>
                                          <td>
                                            <select
                                              className="form-control"
                                              name="relation"
                                              onChange={(e) =>
                                                this.handleInputChange(
                                                  "tab4Data",
                                                  "relation",
                                                  e.target.value
                                                )
                                              }
                                              value={
                                                this.state.tab4Data.relation
                                              }
                                            >
                                              <option>Select</option>
                                              <option value="SELF">Self</option>
                                              <option value="FATH">
                                                Father
                                              </option>
                                              <option value="MOTH">
                                                Mother
                                              </option>
                                              <option value="SISTER">
                                                Sister
                                              </option>
                                              <option value="BROTHER">
                                                Brother
                                              </option>
                                              <option value="SPSE">
                                                Spouse
                                              </option>
                                              <option value="SONM">Son</option>
                                              <option value="UDTR">
                                                Daughter
                                              </option>
                                              <option value="FLAW">
                                                FATHER IN LAW
                                              </option>
                                              <option value="MLAW">
                                                MOTHER IN LAW
                                              </option>
                                            </select>
                                          </td>
                                          <td>
                                            <input
                                              type="number"
                                              onKeyDown={this.onKeyDown}
                                              className="form-control"
                                              id="mobile"
                                              value={this.state.tab4Data.mobile}
                                              onChange={(e) =>
                                                this.handleInputChange(
                                                  "tab4Data",
                                                  "mobile",
                                                  e.target.value
                                                )
                                              }
                                              name="mobile"
                                              aria-describedby="mobile"
                                              placeholder="Nominee Number"
                                            />
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </Col>
                                </Row>
                                <Row className="mt-4">
                                  <Col style={{ float: "right" }}>
                                    <Button
                                      className="btn btn primary"
                                      id="tabindex4"
                                      onClick={this.submitTab4}
                                    >
                                      Submit
                                    </Button>
                                  </Col>
                                </Row>
                                {/* </Form> */}
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </FormWizard.TabContent>
                  <FormWizard.TabContent
                    title="Add-On's"
                    // isValid={this.checkValidateTab4()}
                    // validationError={this.errorMessages4}
                    icon="ti-check-box"
                  >
                    <div>
                      <card>
                        <Card.Body>
                          <row>
                            <Col>
                              <h3>Mandatory AddOns</h3>
                              <Table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th scope="col">S No.</th>
                                    <th scope="col">Addon Name</th>
                                    <th scope="col">Select</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.mandatoryaddons.map(
                                    (quote, index) => (
                                      <tr key={index}>
                                        <td>{quote.caresupremeaddons_Id}</td>
                                        <td>{quote.addOn}</td>
                                        <td>
                                          <input
                                            type="checkbox"
                                            defaultChecked={quote.dummyOne}
                                            onChange={(e) =>
                                              this.handleAddonsCheckboxChange(
                                                quote.addONCode,
                                                quote.productId,
                                                e.target.checked
                                              )
                                            }
                                          />
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                              <br />
                              <h3>Optional AddOns</h3>
                              <Table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th scope="col">S No.</th>
                                    <th scope="col">Addon Name</th>

                                    <th scope="col">Select</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.optionaladdons.map(
                                    (quote, index) => (
                                      <tr key={index}>
                                        <td>{quote.caresupremeaddons_Id}</td>
                                        <td>{quote.addOn}</td>

                                        <td>
                                          <input
                                            type="checkbox"
                                            defaultChecked={quote.dummyOne}
                                            onChange={(e) =>
                                              this.handleOptionalCheckboxChange(
                                                quote.addONCode,
                                                quote.productId,
                                                e.target.checked
                                              )
                                            }
                                          />
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </Table>
                            </Col>
                          </row>
                        </Card.Body>
                      </card>
                      <br />
                    </div>
                  </FormWizard.TabContent>
                </FormWizard>
                {/* add style */}
                <style>{`@import url("https://cdn.jsdelivr.net/gh/lykmapipo/themify-icons@0.1.2/css/themify-icons.css");`}</style>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

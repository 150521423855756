import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "react-toastify/dist/ReactToastify.css";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import LoginPage from "./LoginPage";
import ChangePwd from "./ChangePwd";
import Header from "./Header/Header";

const root = ReactDOM.createRoot(document.getElementById("root"));
function tick() {
  const userName = sessionStorage.getItem("userName");
  const isPwd = sessionStorage.getItem("isPwd");
  var element = "";
  if (!userName) {
    element = <LoginPage />;
  } else if (isPwd < 1) {
    // console.log(isPwd);
    element = <Header />;
  } else {
    element = <ChangePwd />;
  }
  root.render(
    <React.StrictMode>
      {element}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="colored"
      />
    </React.StrictMode>
  );
}

setInterval(tick, 1000);

reportWebVitals();

import React, { Component } from "react";
import { Button, Container, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";

export default class Widgetdashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.widgetdashboard_Id,
          sortable: true,
          cell: (row) => <span>{row.widgetdashboard_Id}</span>,
        },
        {
          name: "User Id",
          selector: (row, i) => row.userId,
          sortable: true,
          cell: (row) => <span>{row.userId}</span>,
        },
        {
          name: "Widget Id",
          selector: (row, i) => row.widgetId,
          sortable: true,
          cell: (row) => <span>{row.widgetId}</span>,
        },
        {
          name: "Widget Width",
          selector: (row, i) => row.widgetWidth,
          sortable: true,
          cell: (row) => <span>{row.widgetWidth}</span>,
        },
        {
          name: "Widget Height",
          selector: (row, i) => row.widgetHeight,
          sortable: true,
          cell: (row) => <span>{row.widgetHeight}</span>,
        },
        {
          name: "Max Width",
          selector: (row, i) => row.maxWidth,
          sortable: true,
          cell: (row) => <span>{row.maxWidth}</span>,
        },
        {
          name: "Min Width",
          selector: (row, i) => row.minWidth,
          sortable: true,
          cell: (row) => <span>{row.minWidth}</span>,
        },
        {
          name: "Max Height",
          selector: (row, i) => row.maxHeight,
          sortable: true,
          cell: (row) => <span>{row.maxHeight}</span>,
        },
        {
          name: "Min Height",
          selector: (row, i) => row.minheight,
          sortable: true,
          cell: (row) => <span>{row.minheight}</span>,
        },
        {
          name: "Dummy One",
          selector: (row, i) => row.dummyOne,
          sortable: true,
          cell: (row) => <span>{row.dummyOne}</span>,
        },
        {
          name: "Dummy Two",
          selector: (row, i) => row.dummyTwo,
          sortable: true,
          cell: (row) => <span>{row.dummyTwo}</span>,
        },
        {
          name: "Dummy Three",
          selector: (row, i) => row.dummyThree,
          sortable: true,
          cell: (row) => <span>{row.dummyThree}</span>,
        },
        {
          name: "Dummy Four",
          selector: (row, i) => row.dummyFour,
          sortable: true,
          cell: (row) => <span>{row.dummyFour}</span>,
        },
        {
          name: "Dummy Five",
          selector: (row, i) => row.dummyFive,
          sortable: true,
          cell: (row) => <span>{row.dummyFive}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                style={{ marginRight: "10px" }}
                variant="primary"
                onClick={this.handleOffcanvasShow}
                className="bi bi-pen"
              ></Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      userId: "",
      widgetId: "",
      widgetWidth: "",
      widgetHeight: "",
      maxWidth: "",
      minWidth: "",
      maxHeight: "",
      minheight: "",
      dummyOne: "",
      dummyTwo: "",
      dummyThree: "",
      dummyFour: "",
      dummyFive: "",
    };
  }
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({
      show: true,
      userId: "",
      widgetId: "",
      widgetWidth: "",
      widgetHeight: "",
      maxWidth: "",
      minWidth: "",
      maxHeight: "",
      minheight: "",
      dummyOne: "",
      dummyTwo: "",
      dummyThree: "",
      dummyFour: "",
      dummyFive: "",
    });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    switch (type) {
      case "success":
        toast.success(msg);
        break;
      case "error":
        toast.error(msg);
        break;
      case "warning":
        toast.warn(msg); // 'warn' is used instead of 'warning'
        break;
      default:
        toast.info(msg);
    }
  };
  WidgetdashboardeditHandler = (e) => {
    e.preventDefault();
    // const updateFormData = {...editFormData};
    // updateFormData['Widgetdashboard_Id'] = e.target.parentElement.getAttribute("id");
    // setEditFormData(updateFormData);
    // setEdit(true);
  };

  WidgetdashboardfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Widgetdashboard/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  WidgetdashboardsubmitHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("userId", this.state.userId);
    formData.append("widgetId", this.state.widgetId);
    formData.append("widgetWidth", this.state.widgetWidth);
    formData.append("widgetHeight", this.state.widgetHeight);
    formData.append("maxWidth", this.state.maxWidth);
    formData.append("minWidth", this.state.minWidth);
    formData.append("maxHeight", this.state.maxHeight);
    formData.append("minheight", this.state.minheight);
    formData.append("dummyOne", this.state.dummyOne);
    formData.append("dummyTwo", this.state.dummyTwo);
    formData.append("dummyThree", this.state.dummyThree);
    formData.append("dummyFour", this.state.dummyFour);
    formData.append("dummyFive", this.state.dummyFive);

    fetch(configData.api_url + "C_Widgetdashboard/add", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.WidgetdashboardfetchHandler();
          this.handleOffcanvasClose();
          this.setState({
            userId: "",
            widgetId: "",
            widgetWidth: "",
            widgetHeight: "",
            maxWidth: "",
            minWidth: "",
            maxHeight: "",
            minheight: "",
            dummyOne: "",
            dummyTwo: "",
            dummyThree: "",
            dummyFour: "",
            dummyFive: "",
          });
        }
      });
  };
  WidgetdashboardupdateHandler = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("userId", this.state.userId);
    formData.append("widgetId", this.state.widgetId);
    formData.append("widgetWidth", this.state.widgetWidth);
    formData.append("widgetHeight", this.state.widgetHeight);
    formData.append("maxWidth", this.state.maxWidth);
    formData.append("minWidth", this.state.minWidth);
    formData.append("maxHeight", this.state.maxHeight);
    formData.append("minheight", this.state.minheight);
    formData.append("dummyOne", this.state.dummyOne);
    formData.append("dummyTwo", this.state.dummyTwo);
    formData.append("dummyThree", this.state.dummyThree);
    formData.append("dummyFour", this.state.dummyFour);
    formData.append("dummyFive", this.state.dummyFive);

    fetch(configData.api_url + "C_Widgetdashboard/update", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.WidgetdashboardfetchHandler();
          this.handleOffcanvasClose();
        }
      });
  };

  componentDidMount() {
    this.WidgetdashboardfetchHandler();
  }
  handleuserIdChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ userId: fieldValue });
  };
  handlewidgetIdChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ widgetId: fieldValue });
  };
  handlewidgetWidthChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ widgetWidth: fieldValue });
  };
  handlewidgetHeightChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ widgetHeight: fieldValue });
  };
  handlemaxWidthChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ maxWidth: fieldValue });
  };
  handleminWidthChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ minWidth: fieldValue });
  };
  handlemaxHeightChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ maxHeight: fieldValue });
  };
  handleminheightChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ minheight: fieldValue });
  };
  handledummyOneChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyOne: fieldValue });
  };
  handledummyTwoChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyTwo: fieldValue });
  };
  handledummyThreeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyThree: fieldValue });
  };
  handledummyFourChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyFour: fieldValue });
  };
  handledummyFiveChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyFive: fieldValue });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
    console.log(`Editing row with ID ${row.id}`);
    // implement your edit logic here
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("widgetdashboard_Id", row.widgetdashboard_Id);
    fetch(configData.api_url + "C_Widgetdashboard/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.WidgetdashboardfetchHandler();
        }
      });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div className="page-title">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <div>
                      <h3
                        className="logoskybluecolor"
                        style={{ textAlign: "start" }}
                      >
                        Dashboard{" "}
                      </h3>
                    </div>
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "5px", marginTop: "20px" }}
                    >
                      <Offcanvas
                        show={this.state.show}
                        onHide={this.handleOffcanvasClose}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            Widgetdashboard page
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                            <Row className="divstyle">
                              <Row>
                                <Form>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        User Id
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleuserIdChange}
                                        className="form-control"
                                        name="userId"
                                        value={this.state.userId}
                                        required="required"
                                        placeholder="Enter User Id"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.userId}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Widget Id
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlewidgetIdChange}
                                        className="form-control"
                                        name="widgetId"
                                        value={this.state.widgetId}
                                        required="required"
                                        placeholder="Enter Widget Id"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.widgetId}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Widget Width
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlewidgetWidthChange}
                                        className="form-control"
                                        name="widgetWidth"
                                        value={this.state.widgetWidth}
                                        required="required"
                                        placeholder="Enter Widget Width"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.widgetWidth}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Widget Height
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlewidgetHeightChange}
                                        className="form-control"
                                        name="widgetHeight"
                                        value={this.state.widgetHeight}
                                        required="required"
                                        placeholder="Enter Widget Height"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.widgetHeight}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Max Width
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlemaxWidthChange}
                                        className="form-control"
                                        name="maxWidth"
                                        value={this.state.maxWidth}
                                        required="required"
                                        placeholder="Enter Max Width"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.maxWidth}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Min Width
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleminWidthChange}
                                        className="form-control"
                                        name="minWidth"
                                        value={this.state.minWidth}
                                        required="required"
                                        placeholder="Enter Min Width"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.minWidth}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Max Height
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlemaxHeightChange}
                                        className="form-control"
                                        name="maxHeight"
                                        value={this.state.maxHeight}
                                        required="required"
                                        placeholder="Enter Max Height"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.maxHeight}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Min Height
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleminheightChange}
                                        className="form-control"
                                        name="minheight"
                                        value={this.state.minheight}
                                        required="required"
                                        placeholder="Enter Min Height"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.minheight}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy One
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyOneChange}
                                        className="form-control"
                                        name="dummyOne"
                                        value={this.state.dummyOne}
                                        required="required"
                                        placeholder="Enter Dummy One"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyOne}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Two
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyTwoChange}
                                        className="form-control"
                                        name="dummyTwo"
                                        value={this.state.dummyTwo}
                                        required="required"
                                        placeholder="Enter Dummy Two"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyTwo}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Three
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyThreeChange}
                                        className="form-control"
                                        name="dummyThree"
                                        value={this.state.dummyThree}
                                        required="required"
                                        placeholder="Enter Dummy Three"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyThree}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Four
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyFourChange}
                                        className="form-control"
                                        name="dummyFour"
                                        value={this.state.dummyFour}
                                        required="required"
                                        placeholder="Enter Dummy Four"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyFour}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Five
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyFiveChange}
                                        className="form-control"
                                        name="dummyFive"
                                        value={this.state.dummyFive}
                                        required="required"
                                        placeholder="Enter Dummy Five"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyFive}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                </Form>
                              </Row>
                            </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={this.WidgetdashboardsubmitHandler}
                                  style={{
                                    marginTop: "20px",
                                  }}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                      {/*offcanvas is over */}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <div style={{ marginRight: "5px" }}>
                      {/* searchbar,pagination,filter */}
                      <input
                        type="text"
                        style={{ float: "left", marginBottom: "10px" }}
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <Button
                        variant="primary"
                        className="bi bi-plus "
                        style={{ float: "right" }}
                        onClick={this.handleOffcanvasShow}
                      >
                        New
                      </Button>
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React from "react";
import {
  Button,
  Row,
  Form,
  Col,
  Offcanvas,
  Tab,
  Tabs,
  Table,
  Modal,
} from "react-bootstrap";
import "./APIPage.css";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";

export default class APIPage extends React.Component {
  constructor(props) {
    super(props);
    this.inputFileRef = React.createRef();
    this.state = {
      apis: [],
      isdefault: false,
      show: false,
      isButtonDisabled: false,
      showOne: false,
      showTest: false,
      responseData: "",
      activeTab: "Request1",
      requests: {
        Request1: {
          Params: [],
          Auth: {
            type: "Bearer",
            token: "",
          },
          Header: [],
          Body: [],
          // Add more sections like Pre-req, Tests, and Settings as needed
        },
      },
      sending: [],
      sendingParams: [], // Data for Params table
      sendingHeader: [], // Data for Header table
      sendingBody: [],
      sendingBodyunlencoded: [],
      sendingAuth: [],

      receiving: [],
      tables: [],
      columns: [],
      rcolumns: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      aId: "",
      apiData: {
        aName: "",
        acName: "",
        aDescription: "",
        aKey: "",
        sendMethod: "",
        aUrl: "",
        currentStep: 1,
      },

      sendingData: {},
      paramsData: {
        key: "",
        tableName: "",
        columnName: "",
        sampleValue: "",
        currentStep: 1,
      },
      headerData: {
        key: "",
        tableName: "",
        columnName: "",
        sampleValue: "",
        currentStep: 1,
      },
      bodyData: {
        key: "",
        tableName: "",
        columnName: "",
        sampleValue: "",
        currentStep: 1,
      },

      editingIndex: -1,

      bodyunlencodedData: {
        key: "",
        tableName: "",
        columnName: "",
        sampleValue: "",
        currentStep: 1,
      },
      receivingData: {
        key: "",
        tableName: "",
        columnName: "",
        sampleValue: "",
        currentStep: 1,
      },
      receivingDataTest: {},
      errors: {},
      showButton: false,
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.aId,
          sortable: true,
          cell: (row) => <span>{row.aId}</span>,
        },
        {
          name: "Name",
          selector: (row, i) => row.aName,
          sortable: true,
          cell: (row) => <span>{row.aName}</span>,
        },
        {
          name: "Company Name",
          selector: (row, i) => row.acName,
          sortable: true,
          cell: (row) => <span>{row.acName}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row, index) => (
            <div>
              {/* edit button and delete button */}
              <button
                style={{ backgroundColor: "#dca169", color: "#fff" }}
                variant="warning"
                onClick={() => this.handleShowAPITest(row)}
                className="bi bi-journal-check btn btn-warning me-2"
              ></button>
              <button
                // style={{ marginLeft: "10px" }}
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen btn btn-primary me-2"
              ></button>

              <button
                variant="danger"
                onClick={() => this.handleDelete(row, index)}
                className="bi bi-trash btn btn-danger"
              ></button>
            </div>
          ),
        },
      ],
      selectedOption: "none",
      textValue: "",
      activeRequest: {
        Params: [],
      },
      currentPage: 1,
      itemsPerPage: 40,
      keyField: "aId",
      isEditing: false, // Flag to indicate edit mode
      isNewEntry: false,
      isOffCanvasOpen: false,
    };

    //this.handleClick = this.handleClick.bind(this);
  }
  handleButtonCloseClick = () => {
    // Close off-canvas by updating state
    this.setState({ showTest: false });

    // Reset fields and update state
    this.setState({
      showTest: false,
      responseData: [],
      showButton: true,
      sending: {},
      receiving: {
        receivingDataTest: [],
      },
      apiData: {
        aName: "",
        acName: "",
        aDescription: "",
        aKey: "",
        aUrl: "",
        sendMethod: "",
      },
      sendingData: {},
      receivingData: {
        key: "",
        sampleValue: "",
      },
    });
  };
  //edit

  //Receive Page+ edit + update +

  handleEditInputChange(index, field, value) {
    const updatedReceiving = [...this.state.receiving];
    updatedReceiving[index][field] = value;
    this.setState({ receiving: updatedReceiving });
    this.getColumns(value, "rcolumns");
  }
  updateSendRowReceive = (e) => {
    e.preventDefault();
    this.setState({ editingIndex: -1 });
    if (this.receivevalidate()) {
      this.setState(
        (prevState) => ({
          receiving: [...prevState.receiving, this.state.receivingData],
        }),
        () => {
          // Clear the fields after the state has been updated
          this.setState({
            receivingData: {
              key: "",
              tableName: "",
              columnName: "",
              sampleValue: "",
            },
          });
        }
      );
    }
  };
  getResponseData(e) {
    e.preventDefault();

    console.log();
  }
  receivevalidate() {
    const { currentStep, receivingData } = this.state;
    let formIsValid = true;

    if (!receivingData["key"]) {
      formIsValid = false;
      toast.info("*Enter Key.");
    } else if (!receivingData["tableName"]) {
      formIsValid = false;
      toast.info("*Select Table Name.");
    } else if (!receivingData["columnName"]) {
      formIsValid = false;
      toast.info("*Select Column Name.");
    } else if (!receivingData["sampleValue"]) {
      formIsValid = false;
      toast.info("*Enter Sample value.");
    } else {
      // If all fields are filled, proceed to the next step
      this.setState({ currentStep: currentStep + 1 });
    }

    return formIsValid;
  }

  //params page  edit + update //

  handleEditInputChangeParamz(index, field, value) {
    const updatedParamz = [...this.state.sendingParams];
    updatedParamz[index][field] = value;
    this.getColumns(value, "rcolumns");
    this.setState({ sendingParams: updatedParamz });
  }

  handleEditTableRowParamz(index) {
    this.setState({ editingIndex: index });
  }

  updateSendParamz = (e) => {
    e.preventDefault();
    this.setState({ editingIndex: -1 });
    if (this.keyvalidatepr()) {
      this.setState(
        (prevState) => ({
          sendingParams: [...prevState.sendingParams, this.state.paramsData],
        }),
        () => {
          // Clear the input fields after adding a row
          this.setState({
            paramsData: {
              key: "",
              tableName: "",
              columnName: "",
              sampleValue: "",
              currentStep: 1,
            },
          });
        }
      );
    }
  };

  // Heder Row Edit + update
  handleEditInputChangeHeader(index, field, value) {
    const updatedHeader = [...this.state.sendingHeader];
    updatedHeader[index][field] = value;
    this.getColumns(value, "rcolumns");
    this.setState({ sendingHeader: updatedHeader });
  }

  handleEditTableRowHeader(index) {
    this.setState({ editingIndex: index });
  }
  updateSendHeader = (e) => {
    e.preventDefault();
    this.setState({ editingIndex: -1 });
    if (this.keyvalidatehr()) {
      this.setState(
        (prevState) => ({
          sendingHeader: [...prevState.sendingHeader, this.state.headerData],
        }),
        () => {
          // Clear the input fields after adding a row
          this.setState({
            headerData: {
              key: "",
              tableName: "",
              columnName: "",
              sampleValue: "",
              currentStep: 1,
            },
          });
        }
      );
    }
  };

  // Body  table Row edit + update
  handleEditInputChangeBody(index, field, value) {
    const updatedBody = [...this.state.sendingBody];
    updatedBody[index][field] = value;
    this.getColumns(value, "rcolumns");
    this.setState({ sendingBody: updatedBody });
  }

  handleEditTableRowBody(index) {
    this.setState({ editingIndex: index });
  }
  updateSendBody = (e) => {
    e.preventDefault();
    this.setState({ editingIndex: -1 });
    if (this.keyvalidatebd()) {
      this.setState(
        (prevState) => ({
          sendingBody: [...prevState.sendingBody, this.state.bodyData],
        }),
        () => {
          // Clear the input fields after adding a row
          this.setState({
            bodyData: {
              key: "",
              tableName: "",
              columnName: "",
              sampleValue: "",
              currentStep: 1,
            },
          });
        }
      );
    }
  };
  // body unlencoded
  handleEditInputChangeBodyunlencoded(index, field, value) {
    const updatedBodyunlencoded = [...this.state.sendingBodyunlencoded];
    updatedBodyunlencoded[index][field] = value;
    this.getColumns(value, "rcolumns");
    this.setState({ sendingBodyunlencoded: updatedBodyunlencoded });
  }

  handleEditTableRowBodyunlencoded(index) {
    this.setState({ editingIndex: index });
  }
  updateSendBodyunlencoded = (e) => {
    e.preventDefault();
    this.setState({ editingIndex: -1 });
    if (this.keyvalidatebdunlencoded()) {
      this.setState(
        (prevState) => ({
          sendingBodyunlencoded: [
            ...prevState.sendingBodyunlencoded,
            this.state.bodyDataunlencoded,
          ],
        }),
        () => {
          // Clear the input fields after adding a row
          this.setState({
            bodyDataunlencoded: {
              key: "",
              tableName: "",
              columnName: "",
              sampleValue: "",
              currentStep: 1,
            },
          });
        }
      );
    }
  };

  ///Row Edit Front page

  handleEditTableRow(index) {
    this.setState({ editingIndex: index });
  }

  handleEnterEditMode = (row) => {
    // this.setState({ cData: { ...row }, isEditing: true });
    this.setState({
      aId: row.aId,
      apiData: {
        aName: row.aName,
        acName: row.acName,
        aDescription: row.aDescription,
        aToken: row.aToken,
        sendMethod: row.sendMethod,
        aUrl: row.aUrl,
        currentStep: 1,
      },
      sendingData: {
        paramsData: [],
        headerData: [],
        bodyData: [],
        sendingAuth: [],
        bodyunlencoded: [],
      },

      sendingBody: row.send.body,
      sendingParams: row.send.params,
      sendingHeader: row.send.header,
      sendingAuth: row.send.auth,
      sendingBodyunlencoded: row.send.unlencoded,
      receiving: row.received,
      isEditing: true,
    });
  };

  //modal
  handleButtonClick = () => {
    this.inputFileRef.current.click();
  };
  handleCloseOne = () => {
    this.setState({ showOne: false });
  };
  handleShow = () => {
    this.setState({
      show: true,
      aId: "",
      sending: {
        body: [],
        params: [],
        header: [],
        auth: [],
        bodyunlencoded: [],
      },
      receiving: [],
      apiData: {
        aName: "",
        acName: "",
        aDescription: "",
        aKey: "",
        sendMethod: "",
        aUrl: "",
      },
      sendingData: {
        paramsData: [],
        headerData: [],
        bodyData: [],
        sendingAuth: [],
        bodyunlencoded: [],
      },
      receivingData: {
        key: "",
        tableName: "",
        columnName: "",
        sampleValue: "",
      },
      paramsData: {
        key: "",
        tableName: "",
        columnName: "",
        sampleValue: "",
        currentStep: 1,
      },
    });
  };

  handleShowOne = () => {
    this.setState({ showOne: true });
  };
  //Offcanvas
  handleClick = () => {
    this.setState({ showButton: true });
  };
  handleClose = () => {
    this.setState({ show: false });
  };

  // handleRemoveSpecificRowS = (idx) => {
  //   const tempRows = this.state.sending; // to avoid  direct state mutation
  //   tempRows.splice(idx, 1);
  //   this.setState({ sending: tempRows });
  // };
  // delete for params

  handleRemoveSpecificRowPramz = (idx) => {
    const tempRows = this.state.sendingParams; // to avoid  direct state mutation
    tempRows.splice(idx, 1);
    this.setState({ sendingParams: tempRows });
  };

  handleRemoveSpecificRowHeader = (idx) => {
    const tempRows = this.state.sendingHeader; // to avoid  direct state mutation
    tempRows.splice(idx, 1);
    this.setState({ sendingHeader: tempRows });
  };
  handleRemoveSpecificRowBody = (idx) => {
    const tempRows = this.state.sendingBody; // to avoid  direct state mutation
    tempRows.splice(idx, 1);
    this.setState({ sendingBody: tempRows });
  };
  handleRemoveSpecificRowBodyunlencoded = (idx) => {
    const tempRows = this.state.sendingBodyunlencoded; // to avoid  direct state mutation
    tempRows.splice(idx, 1);
    this.setState({ sendingBodyunlencoded: tempRows });
  };

  addSendpRow = (e) => {
    e.preventDefault();

    if (this.keyvalidatepr()) {
      this.setState(
        (prevState) => ({
          sendingParams: [...prevState.sendingParams, this.state.paramsData],
        }),
        () => {
          // Clear the input fields after adding a row
          this.setState({
            paramsData: {
              key: "",
              tableName: "",
              columnName: "",
              sampleValue: "",
              currentStep: 1,
            },
          });
        }
      );
    }
  };

  //Prams

  keyvalidatepr() {
    const { currentStep, paramsData } = this.state;
    let formIsValid = true;
    if (!paramsData["key"]) {
      formIsValid = false;
      toast.info("*Enter pKey.");
    } else if (!paramsData["tableName"]) {
      formIsValid = false;
      toast.info("*Select Table Name.");
    } else if (!paramsData["columnName"]) {
      formIsValid = false;
      toast.info("*Select Column Name.");
    } else if (!paramsData["sampleValue"]) {
      formIsValid = false;
      toast.info("*Enter Sample value.");
    } else {
      // If all fields are filled, proceed to the next step
      this.setState({ currentStep: currentStep + 1 });
    }
    return formIsValid;
  }
  //header
  addSendhrRow = (e) => {
    e.preventDefault();
    if (this.keyvalidatehr()) {
      this.setState((prevState) => ({
        sendingHeader: [...prevState.sendingHeader, this.state.headerData],
      }));
      this.setState({
        headerData: {
          key: "",
          tableName: "",
          columnName: "",
          sampleValue: "",
          currentStep: 1,
        },
      });
    }
  };
  keyvalidatehr() {
    const { currentStep, headerData } = this.state;
    let formIsValid = true;
    if (!headerData["key"]) {
      formIsValid = false;
      toast.info("*Enter Header Key.");
    } else if (!headerData["tableName"]) {
      formIsValid = false;
      toast.info("*Select Header Table Name.");
    } else if (!headerData["columnName"]) {
      formIsValid = false;
      toast.info("*Select Header Column Name.");
    } else if (!headerData["sampleValue"]) {
      formIsValid = false;
      toast.info("*Enter Header Sample value.");
    } else {
      // If all fields are filled, proceed to the next step
      this.setState({ currentStep: currentStep + 1 });
    }
    return formIsValid;
  }
  //Body
  addSendBdRow = (e) => {
    e.preventDefault();
    if (this.keyvalidatebd()) {
      this.setState((prevState) => ({
        sendingBody: [
          ...prevState.sendingBody,
          this.state.bodyData,
          //this.state.bodyData, this.state.headerData, this.state.bodyData,
        ],
      }));
      this.setState({
        bodyData: {
          key: "",
          tableName: "",
          columnName: "",
          sampleValue: "",
          currentStep: 1,
        },
      });
    }
  };
  keyvalidatebd() {
    const { currentStep, bodyData } = this.state;
    let formIsValid = true;
    if (!bodyData["key"]) {
      formIsValid = false;
      toast.info("*Enter Body  Key.");
    } else if (!bodyData["tableName"]) {
      formIsValid = false;
      toast.info("*Select Body Table Name.");
    } else if (!bodyData["columnName"]) {
      formIsValid = false;
      toast.info("*Select Body Column Name.");
    } else if (!bodyData["sampleValue"]) {
      formIsValid = false;
      toast.info("*Enter Body Sample value.");
    } else {
      // If all fields are filled, proceed to the next step
      this.setState({ currentStep: currentStep + 1 });
    }
    return formIsValid;
  }
  //body unlencoded
  addSendBdunlencodedRow = (e) => {
    e.preventDefault();
    if (this.keyvalidatebdunlencoded()) {
      this.setState((prevState) => ({
        sendingBodyunlencoded: [
          ...prevState.sendingBodyunlencoded,
          this.state.bodyunlencodedData,

          //this.state.bodyData, this.state.headerData, this.state.bodyData,
        ],
      }));
      this.setState({
        bodyunlencodedData: {
          key: "",
          tableName: "",
          columnName: "",
          sampleValue: "",
          currentStep: 1,
        },
      });
    }
  };
  keyvalidatebdunlencoded() {
    const { currentStep, bodyunlencodedData } = this.state;
    let formIsValid = true;
    if (!bodyunlencodedData["key"]) {
      formIsValid = false;
      toast.info("*Enter Bodys  Key.");
    } else if (!bodyunlencodedData["tableName"]) {
      formIsValid = false;
      toast.info("*Select Body Table Name.");
    } else if (!bodyunlencodedData["columnName"]) {
      formIsValid = false;
      toast.info("*Select Body Column1 Name.");
    } else if (!bodyunlencodedData["sampleValue"]) {
      formIsValid = false;
      toast.info("*Enter Body Sample value.");
    } else {
      // If all fields are filled, proceed to the next step
      this.setState({ currentStep: currentStep + 1 });
    }
    return formIsValid;
  }
  inputSParmsChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.paramsData;
    inputData[fieldName] = fieldValue;
    this.setState({ paramsData: inputData });
  };

  inputAuthChange = (e) => {
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.sendingAuth;
    inputData[fieldName] = fieldValue;
    this.setState({ sendingAuth: inputData });
  };

  inputAuthTChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.authDataTest;
    inputData[fieldName] = fieldValue;
    this.setState({ authDataTest: inputData });
    console.log(this.state.authDataTest);
  };

  inputAuthText = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.sendingAuthTest;

    inputData[fieldName] = fieldValue;
    this.setState({ sendingAuthTest: inputData });
  };
  //change header
  inputSHrChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.headerData;

    inputData[fieldName] = fieldValue;
    this.setState({ headerData: inputData });
    console.log(this.state.headerData);
  };

  //params
  inputtableSParmsChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.paramsData;
    inputData[fieldName] = fieldValue;
    this.setState({ paramsData: inputData });
    this.getColumns(fieldValue, "columns");
  };
  inputtableSHrChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.headerData;
    inputData[fieldName] = fieldValue;
    this.setState({ headerData: inputData });
    this.getColumns(fieldValue, "columns");
  };
  //Body
  inputBdChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.bodyData;
    inputData[fieldName] = fieldValue;
    this.setState({ bodyData: inputData });
  };

  inputtableBdChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.bodyData;
    inputData[fieldName] = fieldValue;
    this.setState({ bodyData: inputData });
    this.getColumns(fieldValue, "columns");
  };

  inputBdunlencodedChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.bodyunlencodedData;
    inputData[fieldName] = fieldValue;
    this.setState({ bodyunlencodedData: inputData });
  };
  inputtableBdunlencodedChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.bodyunlencodedData;
    inputData[fieldName] = fieldValue;
    this.setState({ bodyunlencodedData: inputData });
    this.getColumns(fieldValue, "columns");
  };

  inputtableSChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.sendingData;
    inputData[fieldName] = fieldValue;
    this.setState({ sendingData: inputData });
    this.getColumns(fieldValue, "columns");
  };
  handleRemoveSpecificRowReceive = (idx) => {
    const tempRows = this.state.receiving; // to avoid  direct state mutation
    tempRows.splice(idx, 1);
    this.setState({ receiving: tempRows });
  };

  addSendRowReceive = (e) => {
    e.preventDefault();
    this.setState({ editingIndex: -1 });
    if (this.receivevalidate()) {
      this.setState(
        (prevState) => ({
          receiving: [...prevState.receiving, this.state.receivingData],
        }),
        () => {
          // Clear the fields after the state has been updated
          this.setState({
            receivingData: {
              key: "",
              tableName: "",
              columnName: "",
              sampleValue: "",
            },
          });
        }
      );
    }
  };

  handleRemoveSpecificRowSTest = (idx) => {
    const tempRows = this.state.sending; // to avoid  direct state mutation
    tempRows.splice(idx, 1);
    this.setState({ sending: tempRows });
  };
  addSendRowTest = (e) => {
    e.preventDefault();
    if (this.keyvalidateTest()) {
      this.setState((prevState) => ({
        sending: [...prevState.sending, this.state.sendingData],
        sendingData: {
          key: "",
          tableName: "",
          columnName: "",
          sampleValue: "",
        },
      }));
    }
  };

  keyvalidateTest() {
    const { sendingData } = this.state; // Remove currentStep from here
    let formIsValid = true;

    if (!sendingData["key"]) {
      formIsValid = false;
      toast.info("*Enter Key.");
    } else if (!sendingData["tableName"]) {
      formIsValid = false;
      toast.info("*Select Table Name.");
    } else if (!sendingData["columnName"]) {
      formIsValid = false;
      toast.info("*Select Column Name.");
    } else if (!sendingData["sampleValue"]) {
      formIsValid = false;
      toast.info("*Select Sample value.");
    }

    return formIsValid;
  }
  inputSChangeTest = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputDataTest = this.state.sendingData;
    inputDataTest[fieldName] = fieldValue;
    this.setState({ sendingData: inputDataTest });
  };
  inputtableSChangeTest = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputDataTest = this.state.sendingData;
    inputDataTest[fieldName] = fieldValue;
    this.setState({ sendingData: inputDataTest });
    this.getColumns(fieldValue, "columns");
  };
  handleRemoveSpecificRowReceiveTest = (idx) => {
    const tempRows = this.state.receiving; // to avoid  direct state mutation
    tempRows.splice(idx, 1);
    this.setState({ receiving: tempRows });
  };

  addSendRowReceiveTest = (e) => {
    e.preventDefault();
    if (this.receivevalidateTest()) {
      this.setState((prevState) => ({
        receiving: [...prevState.receiving, this.state.receivingData],
      }));
      this.setState({
        receivingData: {
          key: "",
          tableName: "",
          columnName: "",
          sampleValue: "",
        },
      });
    }
  };
  //receve validate//
  receivevalidateTest() {
    const { currentStep, receivingData } = this.state;
    let formIsValid = true;

    if (!receivingData["key"]) {
      formIsValid = false;
      toast.info("*Enter Key.");
    } else if (!receivingData["tableName"]) {
      formIsValid = false;
      toast.info("*Select Table Name.");
    } else if (!receivingData["columnName"]) {
      formIsValid = false;
      toast.info("*Select Column Name.");
    } else if (!receivingData["sample"]) {
      formIsValid = false;
      toast.info("*Enter Sample value.");
    } else {
      // If all fields are filled, proceed to the next step
      this.setState({ currentStep: currentStep + 1 });
    }
    return formIsValid;
  }

  inputRChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.receivingData;
    inputData[fieldName] = fieldValue;
    this.setState({ receivingData: inputData });
  };
  inputChangeRTest = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.apiData;
    inputData[fieldName] = fieldValue;
    this.setState({ apiData: inputData });
    console.log(this.state.apiData);
  };

  inputChangeReTest = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.bodyData;
    inputData[fieldName] = fieldValue;
    this.setState({ bodyData: inputData });
  };

  inputtableRChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.receivingData;
    inputData[fieldName] = fieldValue;
    this.setState({ receivingData: inputData });
    this.getColumns(fieldValue, "rcolumns");
  };

  handleShowAPITest = (row) => {
    this.setState({
      aId: row.aId,
      apiData: {
        aName: row.aName,
        acName: row.acName,
        aDescription: row.aDescription,
        aToken: row.aToken,
        sendMethod: row.sendMethod,
        aUrl: row.aUrl,
        currentStep: 1,
      },
      sendingData: {
        paramsData: [],
        headerData: [],
        bodyData: [],
        sendingAuth: [],
        bodyunlencoded: [],
      },

      sendingBody: row.send.body,
      sendingParams: row.send.params,
      sendingHeader: row.send.header,
      sendingAuth: row.send.auth,
      sendingBodyunlencoded: row.send.unlencoded,
      receiving: row.received,
    });

    this.setState({ showTest: true });
  };

  handleCloseTest = () => {
    this.setState({ showTest: false });
  };
  makeRequestbody(className) {
    const roleItems = document.getElementsByClassName(className);
    const roleData = [];
    console.log(roleItems.length);
    for (let i = 0; i <= roleItems.length - 1; i++) {
      const roled = {};
      var named = roleItems[i].getAttribute("name");
      var value = roleItems[i].value;
      roled["key"] = named;
      roled["value"] = value;
      roleData.push(roled);
    }
    return roleData;
  }
  componentDidMount() {
    this.apisfetchHandler();
    this.getTables();
    const { apiData } = this.state;
    const isFieldsFilled = Object.values(apiData).every(
      (value) => value !== ""
    );
    if (isFieldsFilled) {
      this.setState({ apiData: { ...apiData } });
    }
  }
  onKeyDown = (event) => {
    if (event.code === "Space") event.preventDefault();
  };
  onPaste(event) {
    event.preventDefault();
    const text = event.clipboardData.getData("Text");
    const value = text.split(" ").join("");
    event.target.value = value;
  }
  inputChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.apiData;
    inputData[fieldName] = fieldValue;
    this.setState({ apiData: inputData });
  };

  inputChangeTest = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputDataTest = this.state.apiData;
    inputDataTest[fieldName] = fieldValue;
    this.setState({ apiData: inputDataTest });
  };

  onSubmitAPI = (e) => {
    this.setState({ isButtonDisabled: true });
    e.preventDefault();

    const {
      sendingParams,
      sendingHeader,
      sendingBody,
      sendingAuth,
      sendingBodyunlencoded,
      sendingRawData,
    } = this.state;

    var sending = {
      body: sendingBody,
      params: sendingParams,
      header: sendingHeader,
      auth: sendingAuth,
      unlencoded: sendingBodyunlencoded,
      rawData: sendingRawData,
    };
    console.log(sending);
    if (this.validateForm()) {
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("aName", this.state.apiData.aName);
      formData.append("acName", this.state.apiData.acName);
      formData.append("aDescription", this.state.apiData.aDescription);
      formData.append("aUrl", this.state.apiData.aUrl);
      formData.append("aToken", this.state.apiData.aKey);
      formData.append("asMethod", this.state.apiData.sendMethod);
      formData.append("send", JSON.stringify(sending));
      formData.append("received", JSON.stringify(this.state.receiving));
      fetch(configData.api_url + "apis/add", {
        method: "POST",
        mode: "cors",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            this.setState({
              showButton: true,
              show: false,
              sending: {
                body: [],
                params: [],
                header: [],
                auth: [],
                bodyunlencoded: [],
              },
              receiving: [],
              apiData: {
                aName: "",
                acName: "",
                aDescription: "",
                aKey: "",
                sendMethod: "",
                aUrl: "",
              },
              sendingData: {
                paramsData: [],
                headerData: [],
                bodyData: [],
                sendingAuth: [],
                bodyunlencoded: [],
              },
              receivingData: {
                key: "",
                tableName: "",
                columnName: "",
                sampleValue: "",
              },
            });
          }
        });
      this.apisfetchHandler();
    }
    // console.log(this.state.sending);
    // console.log(this.state.apiData);
  };
  //validations//
  validateForm() {
    const { currentStep, apiData } = this.state;
    let formIsValid = true;

    if (!apiData["acName"]) {
      formIsValid = false;
      toast.info("*Please Enter Company Name.");
    } else if (!apiData["aName"]) {
      formIsValid = false;
      toast.info("*Please Enter Api Name.");
    } else if (!apiData["aDescription"]) {
      formIsValid = false;
      toast.info("*Enter Api Description.");
    } else if (!apiData["aUrl"]) {
      formIsValid = false;
      toast.info("*Enter Your Api Url.");
    } else if (!apiData["aKey"]) {
      formIsValid = false;
      toast.info("*Enter Your API Key/Token.");
    } else {
      this.setState({ currentStep: currentStep + 1 });
    }

    return formIsValid;
  }
  validateFormTest() {
    const { currentStep, apiData } = this.state;
    let formIsValid = true;
    if (!apiData["aName"]) {
      formIsValid = false;
      toast.info("*Enter Your Api  Name.");
    } else if (!apiData["acName"]) {
      formIsValid = false;
      toast.info("*Enter Your Api acName.");
    } else if (!apiData["aDescription"]) {
      formIsValid = false;
      toast.info("*Enter Your Api aDescription.");
    } else if (!apiData["aUrl"]) {
      formIsValid = false;
      toast.info("*Enter Your Api Url.");
    } else if (!apiData["aToken"]) {
      formIsValid = false;
      toast.info("*Enter Your API Key/Token.");
    } else {
      // If all fields are filled, proceed to the next step
      this.setState({ currentStep: currentStep + 1 });
    }

    return formIsValid;
  }
  apisHandlerupdate = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("aName", this.state.apiData.aName);
      formData.append("acName", this.state.apiData.acName);
      formData.append("aDescription", this.state.apiData.aDescription);
      formData.append("aUrl", this.state.apiData.aUrl);
      formData.append("aToken", this.state.apiData.aKey);
      formData.append("asMethod", this.state.apiData.sendMethod);
      formData.append("send", JSON.stringify(this.state.sending));
      formData.append("received", JSON.stringify(this.state.receiving));
      formData.append("aId", this.state.aId);
      fetch(configData.api_url + "apis/update", {
        method: "POST",
        mode: "cors",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            this.apisfetchHandler();
            this.handleClose();
            this.setState({
              showButton: true,
              show: false,
              sending: {
                body: [],
                params: [],
                header: [],
                auth: [],
              },
              receiving: [],
              apiData: {
                aName: "",
                acName: "",
                aDescription: "",
                aKey: "",
                sendMethod: "",
                aUrl: "",
              },
              sendingData: {
                key: "",
                tableName: "",
                columnName: "",
                sampleValue: "",
              },
              receivingData: {
                key: "",
                tableName: "",
                columnName: "",
                sampleValue: "",
              },
            });
          }
        });
    }
    //console.log(this.state.apiData);
  };
  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "apis/list", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ apis: data.list });
          this.setState({ apis: data.list });
          ////console.log(this.state.apis);
          ////console.log(this.state.tableClms);
        }
      });
  };
  getTables = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "masters/gettables", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({ tables: data.tables });
        }
      });
  };
  getColumns = (tableName, rcolumn) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("tableName", tableName);
    fetch(configData.api_url + "masters/getcolumns", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({ [rcolumn]: data.columns });
        }
      });
  };

  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  // --Table edit options
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
    // const newData=this.state.apis;
    //console.log(`Editing row with ID ${row.aId}`);
    // implement your edit logic here
  };
  handleDelete = (row, index) => {
    const updatedData = this.state.apis;
    updatedData.splice(index, 1);
    const updatedDataTest = this.state.apis;
    updatedDataTest.splice(index, 1);

    this.setState({ apis: updatedData });
    this.setState({ apis: updatedDataTest });

    const formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("aId", row.aId);
    fetch(configData.api_url + "apis/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.apisfetchHandler();
        }
      });
    //console.log(row.aId);

    //implement your delete logic here.
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };
  inputChangeParamsTest = (e) => {
    const fieldValue = e.target.value;
    const inputName = e.target.name;

    this.setState((prevState) => {
      const updatedSendingParams = prevState.sendingParams.map((param) => {
        if (param.key === inputName) {
          return { ...param, sampleValue: fieldValue };
        }
        return param;
      });

      return { sendingParams: updatedSendingParams };
    });
  };
  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.apis.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  inputChangeBodyTest = (e) => {
    // const bodyDataTest = e.target.value;
    // this.setState({ bodyDataTest, textValue: "" });
    const fieldValue = e.target.value;
    const inputName = e.target.name;

    this.setState((prevState) => {
      const updatedSendingParams = prevState.sendingHeader.map((param) => {
        if (param.key === inputName) {
          return { ...param, sampleValue: fieldValue };
        }
        return param;
      });

      return { sendingHeader: updatedSendingParams };
    });
  };
  inputChangeSendBodyTest = (e) => {
    // const bodyDataTest = e.target.value;
    // this.setState({ bodyDataTest, textValue: "" });
    const fieldValue = e.target.value;
    const inputName = e.target.name;

    this.setState((prevState) => {
      const updatedSendingParams = prevState.sendingBody.map((param) => {
        if (param.key === inputName) {
          return { ...param, sampleValue: fieldValue };
        }
        return param;
      });

      return { sendingBody: updatedSendingParams };
    });
  };
  onSubmitTEST = (e) => {
    var sending = {
      body: this.makeRequestbody("bodyDataTest"),
      params: this.makeRequestbody("paramsDataTest"),
      header: this.makeRequestbody("headerDataTest"),
      auth: this.makeRequestbody("authDataTest"),
      unlencoded: this.makeRequestbody("bodyunlencodedDataTest"),
      rawData: this.makeRequestbody("sendingRawData"),
    };

    var receiving = {
      receiving: this.makeRequestbody("receivingDataTest"),
    };
    console.log(sending);
    console.log(receiving);
    e.preventDefault();

    if (this.validateFormTest()) {
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("aName", this.state.apiData.aName);
      formData.append("acName", this.state.apiData.acName);
      formData.append("aDescription", this.state.apiData.aDescription);
      formData.append("aUrl", this.state.apiData.aUrl);
      formData.append("aToken", this.state.apiData.aKey);
      formData.append("asMethod", this.state.apiData.sendMethod);
      formData.append("send", JSON.stringify(sending));
      formData.append("received", JSON.stringify(receiving));

      // Check if headerDataText is defined before iterating over it

      fetch(configData.api_url + "apis/testapi", {
        method: "POST",
        mode: "cors",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            this.setState({
              responseData: data.result,
              showButton: true,
              // showTest: false,
              sending: {},
              receiving: {
                receivingDataTest: [],
              },
              // apiData: {
              //   aName: "",
              //   acName: "",
              //   aDescription: "",
              //   aKey: "",
              //   aUrl: "",
              //   sendMethod: "",
              // },
              sendingData: {},
              receivingData: {
                key: "",
                sampleValue: "",
              },
            });
          }
        });
    }
  };

  //  nested tabs functionality
  handleTabChange = (tabKey) => {
    this.setState({ activeTab: tabKey });
  };
  //IN Body has select option has table thats
  // here
  handleDropdownChange = (e) => {
    const selectedOption = e.target.value;
    this.setState({ selectedOption, textValue: "" });
  };
  // Test Body select option//

  handleDropdownChangeTest = (e) => {
    const selectedOptionTest = e.target.value;
    this.setState({ selectedOptionTest, textValue: "" });
  };

  handleTestChange = (e) => {
    this.setState({ textValue: e.target.value });
  };

  handleAddRow = (section) => {
    const { activeRequest } = this.state;
    const newRequest = { ...activeRequest };
    newRequest[section].push({ key: "", value: "", description: "" });
    this.setState({ activeRequest: newRequest });
  };

  handleRemoveRow = (section, index) => {
    const { activeRequest } = this.state;
    const newRequest = { ...activeRequest };
    newRequest[section].splice(index, 1);
    this.setState({ activeRequest: newRequest });
  };

  renderFormFields() {
    const { selectedOption, textValue } = this.state;

    if (selectedOption === "none") {
      return null;
    } else if (selectedOption === "form-data") {
      return (
        <div>
          <br />

          <Row>
            <Col>
              <Table
                className="table table-bordered table-hover"
                id="tab_logic"
              >
                <thead>
                  <tr>
                    <th className="text-center">S.NO</th>
                    <th className="text-center">KEY</th>
                    <th className="text-center">Table Name</th>
                    <th className="text-center">Column Name</th>
                    <th className="text-center">Sample</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.sendingBody.length > 0 ? (
                    this.state.sendingBody.map((api, index, idx) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {this.state.editingIndex === index ? (
                            <Form.Control
                              style={{
                                width: "130px",
                                height: "38px",
                              }}
                              type="Text"
                              value={api.key}
                              onChange={(e) =>
                                this.handleEditInputChangeBody(
                                  index,
                                  "key",
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            api.key
                          )}
                        </td>
                        <td>
                          {this.state.editingIndex === index ? (
                            <select
                              className="form-select"
                              name="tableName"
                              value={api.tableName}
                              onChange={(e) =>
                                this.handleEditInputChangeBody(
                                  index,
                                  "tableName",
                                  e.target.value
                                )
                              }
                            >
                              <option defaultValue>Select Table</option>
                              {this.state.tables.map((api, index, idx) => (
                                <option key={index} value={api}>
                                  {api}
                                </option>
                              ))}
                            </select>
                          ) : (
                            api.tableName
                          )}
                        </td>
                        <td>
                          {this.state.editingIndex === index ? (
                            <select
                              className="form-select"
                              value={api.columnName}
                              name="columnName"
                              onChange={(e) =>
                                this.handleEditInputChangeBody(
                                  index,
                                  "columnName",
                                  e.target.value
                                )
                              }
                            >
                              <option defaultValue>Select Column</option>
                              {this.state.rcolumns.map((api, index, idx) => (
                                <option key={index} value={api}>
                                  {api}
                                </option>
                              ))}
                            </select>
                          ) : (
                            api.columnName
                          )}
                        </td>
                        <td>
                          {this.state.editingIndex === index ? (
                            <Form.Control
                              style={{
                                width: "130px",
                                height: "38px",
                              }}
                              type="Text"
                              name="sampleValue"
                              placeholder="sample"
                              value={api.sampleValue}
                              onChange={(e) =>
                                this.handleEditInputChangeBody(
                                  index,
                                  "sampleValue",
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            api.sampleValue
                          )}
                        </td>
                        <td>
                          ~
                          {this.state.editingIndex === index && ( // Extra "Add" button below the row being edited
                            <Button
                              type="button"
                              variant="success"
                              onClick={this.updateSendBody}
                            >
                              update
                            </Button>
                          )}
                          <button
                            className="bi bi-pen btn btn-info"
                            onClick={() => this.handleEditTableRowBody(index)}
                          ></button>
                          {this.state.editingIndex !== index && ( // Condition to render Add button above the row being edited
                            <button
                              variant="danger"
                              className="bi bi-trash-fill btn btn-danger"
                              onClick={() =>
                                this.handleRemoveSpecificRowBody(index)
                              }
                            ></button>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6"> No Records</td>
                    </tr>
                  )}
                  <tr>
                    <td>---</td>
                    <td>
                      <Form.Control
                        style={{
                          width: "130px",
                          height: "38px",
                        }}
                        type="Text"
                        name="key"
                        placeholder="bd key"
                        value={this.state.key}
                        onChange={this.inputBdChange}
                      />
                      <div className="errorMsg" style={{ color: "red" }}>
                        {this.state.errors.key}
                      </div>
                    </td>
                    <td>
                      <select
                        className="form-select"
                        name="tableName"
                        value={this.state.tableName}
                        onChange={this.inputtableBdChange}
                      >
                        <option defaultValue>Select Table</option>
                        {this.state.tables.map((api, index, idx) => (
                          <option key={index} value={api}>
                            {api}
                          </option>
                        ))}
                      </select>
                      <div className="errorMsg" style={{ color: "red" }}>
                        {this.state.errors.tableName}
                      </div>
                    </td>
                    <td>
                      <select
                        className="form-select "
                        name="columnName"
                        value={this.state.columnName}
                        onChange={this.inputBdChange}
                      >
                        <option defaultValue>Select Column</option>
                        {this.state.columns.map((api, index, idx) => (
                          <option key={index} value={api}>
                            {api}
                          </option>
                        ))}
                      </select>
                      <div className="errorMsg" style={{ color: "red" }}>
                        {this.state.errors.columnName}
                      </div>
                    </td>
                    <td>
                      <Form.Control
                        style={{
                          width: "130px",
                          height: "38px",
                        }}
                        type="Text"
                        name="sampleValue"
                        placeholder=""
                        value={this.state.sampleValue}
                        onChange={this.inputBdChange}
                      />
                    </td>
                    <td>
                      <Button
                        type="button"
                        variant="success"
                        // className="bi bi-plus"
                        onClick={this.addSendBdRow}
                      >
                        Add
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      );
    } else if (selectedOption === "raw") {
      return (
        <div>
          <Row>
            <Col>
              <select
                value={this.state.selectedOption}
                onChange={this.handleFormatChangeTest}
              >
                <option value="JSON">JSON</option>
                <option value="JAVASCRIPT">JavaScript</option>
                <option value="TEXT">Text</option>
                <option value="HTML">HTML</option>
                <option value="XML">XML</option>
              </select>
            </Col>
            <Col>
              <Form.Control
                as="textarea"
                value={this.state.textValue}
                onChange={this.handleTestChange}
                // onChange={this.handleTextChange}
              />
              {selectedOption === "JSON" && (
                <>
                  <Form.Control
                    as="textarea"
                    value={textValue}
                    onChange={this.handleTextChange}
                  />
                  <pre>
                    {this.formatTextBasedOnOption(textValue, selectedOption)}
                  </pre>
                </>
              )}
            </Col>
          </Row>
        </div>
      );
    } else if (selectedOption === "binary") {
      return <input type="file" />;
    } else if (selectedOption === "x-www-form-unlencoded") {
      return (
        <div>
          <br />

          <Row>
            <Col>
              <Table
                className="table table-bordered table-hover"
                id="tab_logic"
              >
                <thead>
                  <tr>
                    <th className="text-center">S.NO</th>
                    <th className="text-center">KEY</th>
                    <th className="text-center">Table Name</th>
                    <th className="text-center">Column Name</th>
                    <th className="text-center">Sample</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.sendingBodyunlencoded.length > 0 ? (
                    this.state.sendingBodyunlencoded.map((api, index, idx) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          {this.state.editingIndex === index ? (
                            <Form.Control
                              style={{
                                width: "130px",
                                height: "38px",
                              }}
                              type="Text"
                              value={api.key}
                              onChange={(e) =>
                                this.handleEditInputChangeBodyunlencoded(
                                  index,
                                  "key",
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            api.key
                          )}
                        </td>
                        <td>
                          {this.state.editingIndex === index ? (
                            <select
                              className="form-select"
                              name="tableName"
                              value={api.tableName}
                              onChange={(e) =>
                                this.handleEditInputChangeBodyunlencoded(
                                  index,
                                  "tableName",
                                  e.target.value
                                )
                              }
                            >
                              <option defaultValue>Select Table</option>
                              {this.state.tables.map((api, index, idx) => (
                                <option key={index} value={api}>
                                  {api}
                                </option>
                              ))}
                            </select>
                          ) : (
                            api.tableName
                          )}
                        </td>
                        <td>
                          {this.state.editingIndex === index ? (
                            <select
                              className="form-select"
                              value={api.columnName}
                              name="columnName"
                              onChange={(e) =>
                                this.handleEditInputChangeBodyunlencoded(
                                  index,
                                  "columnName",
                                  e.target.value
                                )
                              }
                            >
                              <option defaultValue>Select Column</option>
                              {this.state.rcolumns.map((api, index, idx) => (
                                <option key={index} value={api}>
                                  {api}
                                </option>
                              ))}
                            </select>
                          ) : (
                            api.columnName
                          )}
                        </td>
                        <td>
                          {this.state.editingIndex === index ? (
                            <Form.Control
                              style={{
                                width: "130px",
                                height: "38px",
                              }}
                              type="Text"
                              name="sampleValue"
                              placeholder="sample"
                              value={api.sampleValue}
                              onChange={(e) =>
                                this.handleEditInputChangeBodyunlencoded(
                                  index,
                                  "sampleValue",
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            api.sampleValue
                          )}
                        </td>
                        <td>
                          {this.state.editingIndex === index && ( // Extra "Add" button below the row being edited
                            <Button
                              type="button"
                              variant="success"
                              onClick={this.updateSendBodyunlencoded}
                            >
                              update
                            </Button>
                          )}
                          <button
                            className="bi bi-pen btn btn-info"
                            onClick={() =>
                              this.handleEditTableRowBodyunlencoded(index)
                            }
                          ></button>
                          {this.state.editingIndex !== index && ( // Condition to render Add button above the row being edited
                            <button
                              variant="danger"
                              className="bi bi-trash-fill btn btn-danger"
                              onClick={() =>
                                this.handleRemoveSpecificRowBodyunlencoded(
                                  index
                                )
                              }
                            ></button>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" style={{ color: "red" }}>
                        {" "}
                        No Records
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td>---</td>
                    <td>
                      <Form.Control
                        style={{
                          width: "130px",
                          height: "38px",
                        }}
                        type="Text"
                        name="key"
                        placeholder="bd key"
                        value={this.state.key}
                        onChange={this.inputBdunlencodedChange}
                      />
                      <div className="errorMsg" style={{ color: "red" }}>
                        {this.state.errors.key}
                      </div>
                    </td>
                    <td>
                      <select
                        className="form-select"
                        name="tableName"
                        value={this.state.tableName}
                        onChange={this.inputtableBdunlencodedChange}
                      >
                        <option defaultValue>Select Table</option>
                        {this.state.tables.map((api, index, idx) => (
                          <option key={index} value={api}>
                            {api}
                          </option>
                        ))}
                      </select>
                      <div className="errorMsg" style={{ color: "red" }}>
                        {this.state.errors.tableName}
                      </div>
                    </td>
                    <td>
                      <select
                        className="form-select "
                        name="columnName"
                        value={this.state.columnName}
                        onChange={this.inputBdunlencodedChange}
                      >
                        <option defaultValue>Select Column</option>
                        {this.state.columns.map((api, index, idx) => (
                          <option key={index} value={api}>
                            {api}
                          </option>
                        ))}
                      </select>
                      <div className="errorMsg" style={{ color: "red" }}>
                        {this.state.errors.columnName}
                      </div>
                    </td>
                    <td>
                      <Form.Control
                        style={{
                          width: "130px",
                          height: "38px",
                        }}
                        type="Text"
                        name="sampleValue"
                        placeholder=""
                        value={this.state.sampleValue}
                        onChange={this.inputBdunlencodedChange}
                      />
                    </td>
                    <td>
                      <Button
                        type="button"
                        variant="success"
                        // className="bi bi-plus"
                        onClick={this.addSendBdunlencodedRow}
                      >
                        Add
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      );
    }

    return null;
  }
  // test reder
  renderFormFieldsTest() {
    const { selectedOptionTest, textValue } = this.state;

    if (selectedOptionTest === "none") {
      return null;
    } else if (selectedOptionTest === "form-data") {
      return (
        <div>
          <br />

          <Row>
            <Col>
              <Table
                className="table table-bordered table-hover"
                id="tab_logic"
              >
                <thead>
                  <tr>
                    <th className="text-center">S.No</th>
                    <th className="text-center">KEY</th>
                    <th className="text-center">Sample</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.sendingBody.length > 0 ? (
                    this.state.sendingBody.map((api, index, idx) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{api.key}</td>

                        <td>
                          <Form.Control
                            type="Text"
                            name={api.key}
                            placeholder=""
                            className="bodyDataTest"
                            value={api.sampleValue}
                            onChange={this.inputChangeSendBodyTest}
                          />
                        </td>
                        <td
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        ></td>
                      </tr>
                    ))
                  ) : (
                    <div></div>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      );
    } else if (selectedOptionTest === "raw") {
      return (
        <div>
          <Row>
            <Col>
              <select
                className="sendingRawData"
                value={this.state.selectedOptionTest}
                onChange={this.handleFormatChange}
              >
                <option value="JSON">JSON</option>
                <option value="JAVASCRIPT">JavaScript</option>
                <option value="TEXT">Text</option>
                <option value="HTML">HTML</option>
                <option value="XML">XML</option>
              </select>
            </Col>
            <Col>
              <Form.Control
                as="textarea"
                value={this.state.textValue}
                onChange={this.handleTextChange}
                // onChange={this.handleTextChange}
              />
              {selectedOptionTest === "JSON" && (
                <>
                  <Form.Control
                    as="textarea"
                    value={textValue}
                    onChange={this.handleTestChange}
                  />
                  <pre>
                    {this.formatTextBasedOnOption(
                      textValue,
                      selectedOptionTest
                    )}
                  </pre>
                </>
              )}
            </Col>
          </Row>
        </div>
      );
    } else if (selectedOptionTest === "binary") {
      return <input type="file" />;
    } else if (selectedOptionTest === "x-www-form-unlencoded") {
      return (
        <div>
          <br />

          <Row>
            <Col>
              <Table
                className="table table-bordered table-hover"
                id="tab_logic"
              >
                <thead>
                  <tr>
                    <th className="text-center">S.No</th>
                    <th className="text-center">KEY</th>
                    <th className="text-center">Sample</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {this.state.sendingBodyunlencoded.length > 0 ? (
                      this.state.sendingBodyunlencoded.map(
                        (api, index, idx) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{api.key}</td>
                            <td>
                              <Form.Control
                                type="Text"
                                name={api.key}
                                placeholder="sample Value"
                                value={api.sampleValue}
                                className="bodyunlencodedDataTest"
                                onChange={this.inputChangeBodyTest}
                              />
                            </td>
                            <td
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            />
                          </tr>
                        )
                      )
                    ) : (
                      <div />
                    )}
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      );
    }

    return null;
  }
  // handleTextChang
  handleTextChange = (e) => {
    this.setState({ textValue: e.target.value });
  };

  handleFormatChange = (e) => {
    this.setState({ selectedOption: e.target.value });
  };

  handleFormatChangeTest = (e) => {
    this.setState({ selectedOption: e.target.value });
  };

  formatTextBasedOnOption(text, option) {
    switch (option) {
      case "JSON":
        return this.formatAsJSON(text);
      case "JAVASCRIPT":
        return this.formatAsJavaScript(text);
      case "TEXT":
        return text; // No additional formatting for Text
      case "HTML":
        return this.formatAsHTML(text);
      case "XML":
        return this.formatAsXML(text);
      default:
        return text;
    }
  }

  formatAsJSON(text) {
    try {
      return JSON.stringify(JSON.parse(text), null, 2);
    } catch (error) {
      console.error(error);
      return text; // Return the original text if JSON parsing fails
    }
  }

  formatAsJavaScript(text) {
    // Example: Add "console.log" to each line
    return text
      .split("\n")
      .map((line) => `console.log('${line}');`)
      .join("\n");
  }

  formatAsHTML(text) {
    // Example: Wrap text in HTML <p> tags
    return `<p>${text}</p>`;
  }

  formatAsXML(text) {
    // Example: Wrap text in an XML <data> element
    return `<data>${text}</data>`;
  }

  handleBulkEdit = (section) => {
    // Implement bulk edit functionality for the specified section
    // You can open a modal or a form for bulk editing
  };
  render() {
    // loaded
    const { requests } = this.state;
    const DefaultData = this.state.apiData;

    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.apis.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div>
        <Row>
          <Col lg="12" md="12" sm="12">
            <h2 style={{ float: "left" }}>API DETAILS</h2>
          </Col>
        </Row>
        <div className="x_panel">
          <Row className="d-flex justifyContent-end">
            <Col></Col>
            <Col
              className="d-flex "
              style={{
                marginTop: "20px",
                float: "inline-end",
                justifyContent: "end",
              }}
            >
              <Button
                variant="warning"
                className="me-2 bi bi-upload"
                onClick={this.handleShowOne}
              >
                Upload *csv
              </Button>
              <Button variant="info" className="me-2 bi bi-download">
                Download *csv
              </Button>
              <Button
                variant="primary"
                className="bi bi-plus "
                onClick={this.handleShow}
              >
                New
              </Button>
              <div>
                <Modal show={this.state.showOne} onHide={this.handleCloseOne}>
                  <Modal.Header closeButton>
                    <Modal.Title>Upload *csv file</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Button
                      variant="success"
                      className="bi bi-upload"
                      onClick={this.handleButtonClick}
                    >
                      {" "}
                      Upload
                    </Button>
                    <input
                      type="file"
                      ref={this.inputFileRef}
                      style={{ display: "none" }}
                      accept=".csv"
                      onChange={this.handleFileChange}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleCloseOne}>
                      Close
                    </Button>
                    <Button variant="primary" onClick={this.handleCloseOne}>
                      Save Changes
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>

              <Offcanvas
                show={this.state.show}
                onHide={this.handleClose}
                {...this.props}
                style={{ width: "860px" }}
                placement="end"
                backdrop="true"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>New API Details</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <div style={{ margin: "0px", padding: "0px" }}>
                    <Row>
                      <Form>
                        <Row className="d-flex">
                          <Col lg="4" md="4">
                            <Form.Label>Company Name</Form.Label>
                          </Col>
                          <Col lg="8" md="8">
                            <input
                              type="text"
                              className="form-control mt-1"
                              placeholder="Enter company name"
                              value={DefaultData.acName}
                              onChange={this.inputChange}
                              name="acName"
                            />
                          </Col>
                          <div
                            className="errorMsg text-center"
                            style={{ color: "red" }}
                          >
                            {this.state.errors.acName}
                          </div>
                        </Row>
                        <br />
                        <Row className="d-flex">
                          <Col lg="4" md="4">
                            <Form.Label>API Name</Form.Label>
                          </Col>
                          <Col lg="8" md="8">
                            <Form.Control
                              type="text"
                              placeholder="Enter Your API Name"
                              value={DefaultData.aName}
                              onChange={this.inputChange}
                              name="aName"
                            />
                          </Col>
                          <div
                            className="errorMsg text-center"
                            style={{ color: "red" }}
                          >
                            {this.state.errors.aName}
                          </div>
                        </Row>
                        <br />
                        <Row className="d-flex">
                          <Col lg="4" md="4">
                            <Form.Label>API Description</Form.Label>
                          </Col>
                          <Col lg="8" md="8">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              placeholder="Enter Your API Description"
                              value={DefaultData.aDescription}
                              onChange={this.inputChange}
                              name="aDescription"
                            />
                          </Col>
                          <div
                            className="errorMsg text-center"
                            style={{ color: "red" }}
                          >
                            {this.state.errors.aDescription}
                          </div>
                        </Row>
                        <br />
                        <Row className="d-flex">
                          <Col lg="4" md="4">
                            <Form.Label>API URL </Form.Label>
                          </Col>
                          <Col lg="8" md="8">
                            <Form.Control
                              type="text"
                              placeholder="Enter Your API URL "
                              value={DefaultData.aUrl}
                              onChange={this.inputChange}
                              name="aUrl"
                            />
                          </Col>
                          <div
                            className="errorMsg text-center"
                            style={{ color: "red" }}
                          >
                            {this.state.errors.aUrl}
                          </div>
                        </Row>
                        <br />
                        <Row className="d-flex">
                          <Col lg="4" md="4">
                            <Form.Label>API key/Token </Form.Label>
                          </Col>
                          <Col lg="8" md="8">
                            <Form.Control
                              type="text"
                              placeholder="Enter Your API key/Token "
                              value={DefaultData.aKey}
                              onChange={this.inputChange}
                              name="aKey"
                            />
                          </Col>
                          <div
                            className="errorMsg text-center"
                            style={{ color: "red" }}
                          >
                            {this.state.errors.aKey}
                          </div>
                        </Row>
                        <br />
                      </Form>
                    </Row>
                    <br />
                    <Row>
                      <Col lg="4" md="4">
                        <Form.Label>Select Method </Form.Label>
                      </Col>
                      <Col
                        lg="4"
                        className="d-flex justifyContent-start"
                        style={{ width: "250px" }}
                      >
                        <Form.Select
                          aria-label="Default select example"
                          name="sendMethod"
                          value={DefaultData.sendMethod}
                          onChange={this.inputChange}
                        >
                          <option>Select method</option>
                          <option value="GET">GET</option>
                          <option value="POST">POST</option>
                          <option value="PUT">PUT</option>
                          <option value="PATCH">PATCH</option>
                          <option value="DELETE">DELETE</option>
                          <option value="LINK">LINK</option>
                          <option value="LINK">UNLINK</option>
                          <option value="HEAD">HEAD</option>
                          <option value="PURGE">PURGE</option>
                          <option value="LOCK">LOCK</option>
                          <option value="UNLOCK">UNLOCK</option>
                        </Form.Select>
                      </Col>
                    </Row>
                    <br />
                    <br />
                    <div style={{ margin: "0px", padding: "0px" }}>
                      <Tabs
                        defaultActiveKey="Send123"
                        id="justify-tab-example"
                        justify={true.toString()}
                        className="TabsOne"
                      >
                        <Tab
                          eventKey="Send123"
                          justify={true.toString()}
                          id="TabsTwo"
                          style={{ color: "red" }}
                          className="cursor-pointer py-4 px-8 bg-red-background border border-red-intermediate flex flex-grow"
                          title="Send"
                        >
                          <div>
                            <br />

                            {/* Child tabs Params , Auth , Header, Body */}

                            <Tabs defaultActiveKey="childTab1" id="child-tabs">
                              {Object.keys(requests).map((key) => (
                                // PARAMS FIELDS TABLES
                                <Tab
                                  justify={true.toString()}
                                  id="TabsTwo"
                                  key={key}
                                  eventKey={key}
                                  title="Params"
                                >
                                  <br />
                                  <Row>
                                    <Col>
                                      <Table
                                        className="table table-bordered table-hover"
                                        id="tab_logic"
                                      >
                                        <thead>
                                          <tr>
                                            <th className="text-center">
                                              S.NO
                                            </th>
                                            <th className="text-center">KEY</th>
                                            <th className="text-center">
                                              Table Name
                                            </th>
                                            <th className="text-center">
                                              Column Name
                                            </th>
                                            <th className="text-center">
                                              Sample
                                            </th>
                                            <th className="text-center">
                                              Actions
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.sendingParams.length >
                                          0 ? (
                                            this.state.sendingParams.map(
                                              (api, index, idx) => (
                                                <tr key={index}>
                                                  <td>{index + 1}</td>
                                                  <td>
                                                    {this.state.editingIndex ===
                                                    index ? (
                                                      <Form.Control
                                                        style={{
                                                          width: "130px",
                                                          height: "38px",
                                                        }}
                                                        type="Text"
                                                        value={api.key}
                                                        onChange={(e) =>
                                                          this.handleEditInputChangeParamz(
                                                            index,
                                                            "key",
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      api.key
                                                    )}
                                                  </td>
                                                  <td>
                                                    {this.state.editingIndex ===
                                                    index ? (
                                                      <select
                                                        className="form-select"
                                                        name="tableName"
                                                        value={api.tableName}
                                                        onChange={(e) =>
                                                          this.handleEditInputChangeParamz(
                                                            index,
                                                            "tableName",
                                                            e.target.value
                                                          )
                                                        }
                                                      >
                                                        <option defaultValue>
                                                          Select Table
                                                        </option>
                                                        {this.state.tables.map(
                                                          (api, index, idx) => (
                                                            <option
                                                              key={index}
                                                              value={api}
                                                            >
                                                              {api}
                                                            </option>
                                                          )
                                                        )}
                                                      </select>
                                                    ) : (
                                                      api.tableName
                                                    )}
                                                  </td>
                                                  <td>
                                                    {this.state.editingIndex ===
                                                    index ? (
                                                      <select
                                                        className="form-select"
                                                        value={api.columnName}
                                                        name="columnName"
                                                        onChange={(e) =>
                                                          this.handleEditInputChangeParamz(
                                                            index,
                                                            "columnName",
                                                            e.target.value
                                                          )
                                                        }
                                                      >
                                                        <option defaultValue>
                                                          Select Column
                                                        </option>
                                                        {this.state.rcolumns.map(
                                                          (api, index, idx) => (
                                                            <option
                                                              key={index}
                                                              value={api}
                                                            >
                                                              {api}
                                                            </option>
                                                          )
                                                        )}
                                                      </select>
                                                    ) : (
                                                      api.columnName
                                                    )}
                                                  </td>
                                                  <td>
                                                    {this.state.editingIndex ===
                                                    index ? (
                                                      <Form.Control
                                                        style={{
                                                          width: "130px",
                                                          height: "38px",
                                                        }}
                                                        type="Text"
                                                        name="sampleValue"
                                                        placeholder="sample"
                                                        value={api.sampleValue}
                                                        onChange={(e) =>
                                                          this.handleEditInputChangeParamz(
                                                            index,
                                                            "sampleValue",
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      api.sampleValue
                                                    )}
                                                  </td>
                                                  <td>
                                                    {this.state.editingIndex ===
                                                      index && ( // Extra "Add" button below the row being edited
                                                      <Button
                                                        type="button"
                                                        variant="success"
                                                        onClick={
                                                          this.updateSendParamz
                                                        }
                                                      >
                                                        update
                                                      </Button>
                                                    )}
                                                    <button
                                                      className="bi bi-pen btn btn-info"
                                                      onClick={() =>
                                                        this.handleEditTableRowParamz(
                                                          index
                                                        )
                                                      }
                                                    ></button>
                                                    {this.state.editingIndex !==
                                                      index && ( // Condition to render Add button above the row being edited
                                                      <button
                                                        variant="danger"
                                                        className="bi bi-trash-fill btn btn-danger"
                                                        onClick={() =>
                                                          this.handleRemoveSpecificRowPramz(
                                                            index
                                                          )
                                                        }
                                                      ></button>
                                                    )}
                                                  </td>
                                                </tr>
                                              )
                                            )
                                          ) : (
                                            <tr>
                                              <td colSpan="6"> No Records</td>
                                            </tr>
                                          )}
                                          <tr>
                                            <td>---</td>
                                            <td>
                                              <Form.Control
                                                style={{
                                                  width: "130px",
                                                  height: "38px",
                                                }}
                                                type="Text"
                                                name="key"
                                                placeholder="key"
                                                value={
                                                  this.state.paramsData.key
                                                }
                                                onChange={
                                                  this.inputSParmsChange
                                                }
                                              />
                                              <div
                                                className="errorMsg"
                                                style={{ color: "red" }}
                                              >
                                                {
                                                  this.state.errors
                                                    .paginationServerkey
                                                }
                                              </div>
                                            </td>
                                            <td>
                                              <select
                                                className="form-select"
                                                name="tableName"
                                                value={
                                                  this.state.paramsData
                                                    .tableName
                                                }
                                                onChange={
                                                  this.inputtableSParmsChange
                                                }
                                              >
                                                <option defaultValue>
                                                  Select Table
                                                </option>
                                                {this.state.tables.map(
                                                  (api, index, idx) => (
                                                    <option
                                                      key={index}
                                                      value={api}
                                                    >
                                                      {api}
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                              <div
                                                className="errorMsg"
                                                style={{ color: "red" }}
                                              >
                                                {this.state.errors.tableName}
                                              </div>
                                            </td>
                                            <td>
                                              <select
                                                className="form-select "
                                                name="columnName"
                                                value={
                                                  this.state.paramsData
                                                    .columnName
                                                }
                                                onChange={
                                                  this.inputSParmsChange
                                                }
                                              >
                                                <option defaultValue>
                                                  Select Column
                                                </option>
                                                {this.state.columns.map(
                                                  (api, index, idx) => (
                                                    <option
                                                      key={index}
                                                      value={api}
                                                    >
                                                      {api}
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                              <div
                                                className="errorMsg"
                                                style={{ color: "red" }}
                                              >
                                                {this.state.errors.columnName}
                                              </div>
                                            </td>
                                            <td>
                                              <Form.Control
                                                style={{
                                                  width: "130px",
                                                  height: "38px",
                                                }}
                                                type="Text"
                                                name="sampleValue"
                                                placeholder=""
                                                value={
                                                  this.state.paramsData
                                                    .sampleValue
                                                }
                                                onChange={
                                                  this.inputSParmsChange
                                                }
                                              />
                                            </td>
                                            <td>
                                              <Button
                                                type="button"
                                                variant="success"
                                                // className="bi bi-plus"
                                                onClick={this.addSendpRow}
                                              >
                                                Add
                                              </Button>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </Col>
                                  </Row>
                                </Tab>
                              ))}
                              <Tab
                                justify={true.toString()}
                                id="TabsTwo"
                                eventKey="childTab2"
                                title="Auth"
                              >
                                <br />
                                <Row>
                                  <Col>
                                    <Row className="d-flex">
                                      <Col lg="2" md="2" sm="2">
                                        <p>Type</p>
                                      </Col>
                                      <Col lg="4" md="4" sm="4">
                                        <Form.Select
                                          aria-label="Default select example"
                                          name="sendMethod"
                                          value={this.state.sendMethod}
                                          onChange={this.inputAuthChange}
                                        >
                                          <option>
                                            Inherit auth from parent
                                          </option>
                                          <option value="No_Auth">
                                            No Auth
                                          </option>
                                          <option value="API_Key">
                                            API Key
                                          </option>
                                          <option value="Bearer_Auth">
                                            Bearer Auth
                                          </option>
                                          <option value="Basic_Auth">
                                            Basic Auth
                                          </option>
                                          <option value="Digest_Auth">
                                            Digest Auth
                                          </option>
                                          <option value="OAuth1">
                                            OAuth1.0
                                          </option>
                                          <option value="">UNLINK</option>
                                          <option value="HEAD">HEAD</option>
                                          <option value="PURGE">PURGE</option>
                                          <option value="LOCK">LOCK</option>
                                          <option value="UNLOCK">UNLOCK</option>
                                        </Form.Select>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Tab>
                              <Tab
                                justify={true.toString()}
                                id="TabsTwo"
                                eventKey="childTab3"
                                title="Header"
                              >
                                <br />
                                <Row>
                                  <Col>
                                    <Table
                                      className="table table-bordered table-hover"
                                      id="tab_logic"
                                    >
                                      <thead>
                                        <tr>
                                          <th className="text-center">S.NO</th>
                                          <th className="text-center">KEY</th>
                                          <th className="text-center">
                                            Table Name
                                          </th>
                                          <th className="text-center">
                                            Column Name
                                          </th>
                                          <th className="text-center">
                                            Sample
                                          </th>
                                          <th className="text-center">
                                            Actions
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.sendingHeader.length > 0 ? (
                                          this.state.sendingHeader.map(
                                            (api, index, idx) => (
                                              <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                  {this.state.editingIndex ===
                                                  index ? (
                                                    <Form.Control
                                                      style={{
                                                        width: "130px",
                                                        height: "38px",
                                                      }}
                                                      type="Text"
                                                      value={api.key}
                                                      onChange={(e) =>
                                                        this.handleEditInputChangeHeader(
                                                          index,
                                                          "key",
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  ) : (
                                                    api.key
                                                  )}
                                                </td>
                                                <td>
                                                  {this.state.editingIndex ===
                                                  index ? (
                                                    <select
                                                      className="form-select"
                                                      name="tableName"
                                                      value={api.tableName}
                                                      onChange={(e) =>
                                                        this.handleEditInputChangeHeader(
                                                          index,
                                                          "tableName",
                                                          e.target.value
                                                        )
                                                      }
                                                    >
                                                      <option defaultValue>
                                                        Select Table
                                                      </option>
                                                      {this.state.tables.map(
                                                        (api, index, idx) => (
                                                          <option
                                                            key={index}
                                                            value={api}
                                                          >
                                                            {api}
                                                          </option>
                                                        )
                                                      )}
                                                    </select>
                                                  ) : (
                                                    api.tableName
                                                  )}
                                                </td>
                                                <td>
                                                  {this.state.editingIndex ===
                                                  index ? (
                                                    <select
                                                      className="form-select"
                                                      value={api.columnName}
                                                      name="columnName"
                                                      onChange={(e) =>
                                                        this.handleEditInputChangeHeader(
                                                          index,
                                                          "columnName",
                                                          e.target.value
                                                        )
                                                      }
                                                    >
                                                      <option defaultValue>
                                                        Select Column
                                                      </option>
                                                      {this.state.rcolumns.map(
                                                        (api, index, idx) => (
                                                          <option
                                                            key={index}
                                                            value={api}
                                                          >
                                                            {api}
                                                          </option>
                                                        )
                                                      )}
                                                    </select>
                                                  ) : (
                                                    api.columnName
                                                  )}
                                                </td>
                                                <td>
                                                  {this.state.editingIndex ===
                                                  index ? (
                                                    <Form.Control
                                                      style={{
                                                        width: "130px",
                                                        height: "38px",
                                                      }}
                                                      type="Text"
                                                      name="sampleValue"
                                                      placeholder="sample"
                                                      value={api.sampleValue}
                                                      onChange={(e) =>
                                                        this.handleEditInputChangeHeader(
                                                          index,
                                                          "sampleValue",
                                                          e.target.value
                                                        )
                                                      }
                                                    />
                                                  ) : (
                                                    api.sampleValue
                                                  )}
                                                </td>
                                                <td>
                                                  {this.state.editingIndex ===
                                                    index && ( // Extra "Add" button below the row being edited
                                                    <Button
                                                      type="button"
                                                      variant="success"
                                                      onClick={
                                                        this.updateSendHeader
                                                      }
                                                    >
                                                      update
                                                    </Button>
                                                  )}
                                                  <button
                                                    className="bi bi-pen btn btn-info"
                                                    onClick={() =>
                                                      this.handleEditTableRowHeader(
                                                        index
                                                      )
                                                    }
                                                  ></button>
                                                  {this.state.editingIndex !==
                                                    index && ( // Condition to render Add button above the row being edited
                                                    <button
                                                      variant="danger"
                                                      className="bi bi-trash-fill btn btn-danger"
                                                      onClick={() =>
                                                        this.handleRemoveSpecificRowHeader(
                                                          index
                                                        )
                                                      }
                                                    ></button>
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          )
                                        ) : (
                                          <tr>
                                            <td colSpan="6"> No Records</td>
                                          </tr>
                                        )}
                                        <tr>
                                          <td>---</td>
                                          <td>
                                            <Form.Control
                                              style={{
                                                width: "130px",
                                                height: "38px",
                                              }}
                                              type="Text"
                                              name="key"
                                              placeholder="key"
                                              value={this.state.key}
                                              onChange={this.inputSHrChange}
                                            />
                                            <div
                                              className="errorMsg"
                                              style={{ color: "red" }}
                                            >
                                              {this.state.errors.key}
                                            </div>
                                          </td>
                                          <td>
                                            <select
                                              className="form-select"
                                              name="tableName"
                                              value={DefaultData.tableName}
                                              onChange={
                                                this.inputtableSHrChange
                                              }
                                            >
                                              <option defaultValue>
                                                Select Table
                                              </option>
                                              {this.state.tables.map(
                                                (api, index, idx) => (
                                                  <option
                                                    key={index}
                                                    value={api}
                                                  >
                                                    {api}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                            <div
                                              className="errorMsg"
                                              style={{ color: "red" }}
                                            >
                                              {this.state.errors.tableName}
                                            </div>
                                          </td>
                                          <td>
                                            <select
                                              className="form-select "
                                              name="columnName"
                                              value={DefaultData.columnName}
                                              onChange={this.inputSHrChange}
                                            >
                                              <option defaultValue>
                                                Select Column
                                              </option>
                                              {this.state.columns.map(
                                                (api, index, idx) => (
                                                  <option
                                                    key={index}
                                                    value={api}
                                                  >
                                                    {api}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                            <div
                                              className="errorMsg"
                                              style={{ color: "red" }}
                                            >
                                              {this.state.errors.columnName}
                                            </div>
                                          </td>
                                          <td>
                                            <Form.Control
                                              style={{
                                                width: "130px",
                                                height: "38px",
                                              }}
                                              type="Text"
                                              name="sampleValue"
                                              placeholder=""
                                              value={DefaultData.sampleValue}
                                              onChange={this.inputSHrChange}
                                            />
                                          </td>
                                          <td>
                                            <Button
                                              type="button"
                                              variant="success"
                                              // className="bi bi-plus"
                                              onClick={this.addSendhrRow}
                                            >
                                              Add
                                            </Button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </Col>
                                </Row>
                              </Tab>

                              {/* Body
                               */}
                              <Tab
                                justify={true.toString()}
                                id="TabsTwo"
                                eventKey="childTab4"
                                title="Body"
                              >
                                <br />
                                <Row>
                                  <Col lg="4" md="4" sm="4">
                                    <Form.Group>
                                      <Row>
                                        <Col lg="6" md="6" sm="6">
                                          <Form.Label>
                                            Select an option:
                                          </Form.Label>
                                        </Col>
                                        <Col lg="4" md="4" sm="4">
                                          <Form.Control
                                            as="select"
                                            onChange={this.handleDropdownChange}
                                          >
                                            <option value="none">None</option>
                                            <option value="form-data">
                                              form-data
                                            </option>
                                            <option value="raw">Raw</option>
                                            <option value="x-www-form-unlencoded">
                                              x-www-form-unlencoded
                                            </option>
                                            <option value="binary">
                                              Binary
                                            </option>
                                          </Form.Control>
                                        </Col>
                                      </Row>
                                    </Form.Group>
                                  </Col>
                                  <Col>{this.renderFormFields()}</Col>
                                </Row>
                              </Tab>
                            </Tabs>
                          </div>
                        </Tab>
                        {/* Receive Tab in New 
                        
                        
                        
                        
                        */}
                        <Tab
                          eventKey="Receive"
                          justify="true"
                          id="TabsTwo"
                          style={{ color: "red" }}
                          className="cursor-pointer py-4 px-8 bg-red-background border border-red-intermediate flex flex-grow"
                          title="Receive"
                        >
                          <div>
                            <br />
                            <Row>
                              <Col>
                                <Table
                                  className="table table-bordered table-hover"
                                  id="tab_logic"
                                >
                                  <thead>
                                    <tr>
                                      <th className="text-center">S.NO</th>
                                      <th className="text-center">KEY</th>
                                      <th className="text-center">
                                        Table Name
                                      </th>
                                      <th className="text-center">
                                        Column Name
                                      </th>
                                      <th className="text-center">Sample</th>
                                      <th className="text-center">Actions</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state.receiving.length > 0 ? (
                                      this.state.receiving.map(
                                        (api, index, idx) => (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                              {this.state.editingIndex ===
                                              index ? (
                                                <Form.Control
                                                  style={{
                                                    width: "130px",
                                                    height: "38px",
                                                  }}
                                                  type="Text"
                                                  value={api.key}
                                                  onChange={(e) =>
                                                    this.handleEditInputChange(
                                                      index,
                                                      "key",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              ) : (
                                                api.key
                                              )}
                                            </td>
                                            <td>
                                              {this.state.editingIndex ===
                                              index ? (
                                                <select
                                                  className="form-select"
                                                  name="tableName"
                                                  value={api.tableName}
                                                  onChange={(e) =>
                                                    this.handleEditInputChange(
                                                      index,
                                                      "tableName",
                                                      e.target.value
                                                    )
                                                  }
                                                >
                                                  <option defaultValue>
                                                    Select Table
                                                  </option>
                                                  {this.state.tables.map(
                                                    (api, index, idx) => (
                                                      <option
                                                        key={index}
                                                        value={api}
                                                      >
                                                        {api}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              ) : (
                                                api.tableName
                                              )}
                                            </td>
                                            <td>
                                              {this.state.editingIndex ===
                                              index ? (
                                                <select
                                                  className="form-select"
                                                  value={api.columnName}
                                                  name="columnName"
                                                  onChange={(e) =>
                                                    this.handleEditInputChange(
                                                      index,
                                                      "columnName",
                                                      e.target.value
                                                    )
                                                  }
                                                >
                                                  <option defaultValue>
                                                    Select Column
                                                  </option>
                                                  {this.state.rcolumns.map(
                                                    (api, index, idx) => (
                                                      <option
                                                        key={index}
                                                        value={api}
                                                      >
                                                        {api}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              ) : (
                                                api.columnName
                                              )}
                                            </td>
                                            <td>
                                              {this.state.editingIndex ===
                                              index ? (
                                                <Form.Control
                                                  style={{
                                                    width: "130px",
                                                    height: "38px",
                                                  }}
                                                  type="Text"
                                                  name="sampleValue"
                                                  placeholder="sample"
                                                  value={api.sampleValue}
                                                  onChange={(e) =>
                                                    this.handleEditInputChange(
                                                      index,
                                                      "sampleValue",
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                              ) : (
                                                api.sampleValue
                                              )}
                                            </td>
                                            <td>
                                              {this.state.editingIndex ===
                                                index && ( // Extra "Add" button below the row being edited
                                                <Button
                                                  type="button"
                                                  variant="success"
                                                  onClick={
                                                    this.updateSendRowReceive
                                                  }
                                                >
                                                  update
                                                </Button>
                                              )}
                                              <button
                                                className="bi bi-pen btn btn-info"
                                                onClick={() =>
                                                  this.handleEditTableRow(index)
                                                }
                                              ></button>
                                              {this.state.editingIndex !==
                                                index && ( // Condition to render Add button above the row being edited
                                                <button
                                                  variant="danger"
                                                  className="bi bi-trash-fill btn btn-danger"
                                                  onClick={() =>
                                                    this.handleRemoveSpecificRowReceive(
                                                      index
                                                    )
                                                  }
                                                ></button>
                                              )}
                                            </td>
                                          </tr>
                                        )
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan="6"> No Records</td>
                                      </tr>
                                    )}
                                    <tr>
                                      <td>---</td>
                                      <td>
                                        <Form.Control
                                          style={{
                                            width: "130px",
                                            height: "38px",
                                          }}
                                          type="Text"
                                          name="key"
                                          placeholder="key"
                                          value={DefaultData.key}
                                          onChange={this.inputRChange}
                                        />
                                        <div
                                          className="errorMsg"
                                          style={{ color: "red" }}
                                        >
                                          {this.state.errors.key}
                                        </div>
                                      </td>
                                      <td>
                                        <select
                                          className="form-select"
                                          name="tableName"
                                          value={DefaultData.tableName}
                                          onChange={this.inputtableRChange}
                                        >
                                          <option defaultValue>
                                            Select Table
                                          </option>
                                          {this.state.tables.map(
                                            (api, index, idx) => (
                                              <option key={index} value={api}>
                                                {api}
                                              </option>
                                            )
                                          )}
                                        </select>
                                        <div
                                          className="errorMsg"
                                          style={{ color: "red" }}
                                        >
                                          {this.state.errors.tableName}
                                        </div>
                                      </td>
                                      <td>
                                        <select
                                          className="form-select"
                                          value={DefaultData.columnName}
                                          name="columnName"
                                          onChange={this.inputRChange}
                                        >
                                          <option defaultValue>
                                            Select Column
                                          </option>
                                          {this.state.rcolumns.map(
                                            (api, index, idx) => (
                                              <option key={index} value={api}>
                                                {api}
                                              </option>
                                            )
                                          )}
                                        </select>
                                        <div
                                          className="errorMsg"
                                          style={{ color: "red" }}
                                        >
                                          {this.state.errors.columnName}
                                        </div>
                                      </td>
                                      <td>
                                        <Form.Control
                                          style={{
                                            width: "130px",
                                            height: "38px",
                                          }}
                                          type="Text"
                                          name="sampleValue"
                                          placeholder=""
                                          value={DefaultData.sampleValue}
                                          onChange={this.inputRChange}
                                        />
                                      </td>
                                      <td>
                                        <Button
                                          type="button"
                                          variant="success"
                                          // className="bi bi-plus"
                                          onClick={this.addSendRowReceive}
                                        >
                                          Add
                                        </Button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </Col>
                            </Row>
                          </div>
                        </Tab>
                      </Tabs>
                    </div>
                    <br />
                    <br />
                    <Row className="mt-5">
                      <Col className="d-flex  justifyContent-end">
                        <Button
                          variant="success"
                          onClick={this.onSubmitAPI}
                          //  disabled={this.State.isButtonDisabled}
                        >
                          Save
                        </Button>

                        {/* <Button variant="success" onClick={this.onSubmitAPI}>
                        Save
                      </Button> */}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <textarea
                          name="postContent"
                          rows={4}
                          cols={40}
                        ></textarea>
                      </Col>
                    </Row>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>

              {/* testing Offcanvas */}

              <Offcanvas
                show={this.state.showTest}
                onHide={this.handleCloseTest}
                {...this.props}
                style={{ width: "860px" }}
                placement="end"
                backdrop="true"
              >
                <Offcanvas.Header closeButton onClick={this.closeOffcanvas}>
                  <Offcanvas.Title>Sending Parameter</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <div>
                    <div style={{ margin: "0px", padding: "0px" }}>
                      <Row className="d-flex">
                        <Col lg="4" md="4">
                          <Form.Label>Company Name Test</Form.Label>
                        </Col>
                        <Col lg="8" md="8">
                          <input
                            type="text"
                            className="form-control mt-1"
                            placeholder="Enter company name"
                            value={this.state.apiData.acName}
                            onChange={this.inputChangeTest}
                            name="acName"
                          />
                        </Col>
                        <div
                          className="errorMsg text-center"
                          style={{ color: "red" }}
                        >
                          {this.state.errors.acName}
                        </div>
                      </Row>
                      <br />
                      <Row className="d-flex">
                        <Col lg="4" md="4">
                          <Form.Label>API Name</Form.Label>
                        </Col>
                        <Col lg="8" md="8">
                          <Form.Control
                            type="text"
                            placeholder="Enter Your API Name"
                            value={this.state.apiData.aName}
                            onChange={this.inputChangeTest}
                            name="aName"
                          />
                        </Col>
                        <div
                          className="errorMsg text-center"
                          style={{ color: "red" }}
                        >
                          {this.state.errors.aName}
                        </div>
                      </Row>
                      <br />
                      <Row className="d-flex">
                        <Col lg="4" md="4">
                          <Form.Label>API Description</Form.Label>
                        </Col>
                        <Col lg="8" md="8">
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Enter Your API Description"
                            value={this.state.apiData.aDescription}
                            onChange={this.inputChangeTest}
                            name="aDescription"
                          />
                        </Col>
                        <div
                          className="errorMsg text-center"
                          style={{ color: "red" }}
                        >
                          {this.state.errors.aDescription}
                        </div>
                      </Row>
                      <br />
                      <Row className="d-flex">
                        <Col lg="4" md="4">
                          <Form.Label>API URL </Form.Label>
                        </Col>
                        <Col lg="8" md="8">
                          <Form.Control
                            type="text"
                            placeholder="Enter Your API URL "
                            value={this.state.apiData.aUrl}
                            onChange={this.inputChangeTest}
                            name="aUrl"
                          />
                        </Col>
                        <div
                          className="errorMsg text-center"
                          style={{ color: "red" }}
                        >
                          {this.state.errors.aUrl}
                        </div>
                      </Row>
                      <br />
                      <Row className="d-flex">
                        <Col lg="4" md="4">
                          <Form.Label>API key/Token </Form.Label>
                        </Col>
                        <Col lg="8" md="8">
                          <Form.Control
                            type="text"
                            placeholder="Enter Your API key/Token "
                            value={this.state.apiData.aToken}
                            onChange={this.inputChange}
                            name="aKey"
                          />
                        </Col>
                        <div
                          className="errorMsg text-center"
                          style={{ color: "red" }}
                        >
                          {this.state.errors.aKey}
                        </div>
                      </Row>
                      <br />
                      <Row>
                        <Col lg="4" md="4">
                          <Form.Label>Select Method </Form.Label>
                        </Col>
                        <Col
                          lg="4"
                          className="d-flex justifyContent-start"
                          style={{ width: "250px" }}
                        >
                          <Form.Select
                            aria-label="Default select example"
                            name="sendMethod"
                            value={DefaultData.sendMethod}
                            onChange={this.inputChange}
                          >
                            <option>Select method</option>
                            <option value="GET">GET</option>
                            <option value="POST">POST</option>
                            <option value="PUT">PUT</option>
                            <option value="PATCH">PATCH</option>
                            <option value="DELETE">DELETE</option>
                            <option value="LINK">LINK</option>
                            <option value="LINK">UNLINK</option>
                            <option value="HEAD">HEAD</option>
                            <option value="PURGE">PURGE</option>
                            <option value="LOCK">LOCK</option>
                            <option value="UNLOCK">UNLOCK</option>
                          </Form.Select>
                        </Col>
                      </Row>
                      <br />
                      <Tabs
                        defaultActiveKey="Send123"
                        id="justify-tab-example"
                        className="TabsOne  mt-5"
                      >
                        <Tab
                          eventKey="Send123"
                          id="TabsTwo"
                          style={{ color: "red" }}
                          className="cursor-pointer py-4 px-8 bg-red-background border border-red-intermediate flex flex-grow"
                          title="Send"
                        >
                          <div>
                            <br />

                            {/* Send Test Tabs */}
                            <Row>
                              <Tabs
                                defaultActiveKey="childTab1"
                                id="child-tabs"
                              >
                                {Object.keys(requests).map((key) => (
                                  // PARAMS FIELDS TABLES
                                  <Tab key={key} eventKey={key} title="Params">
                                    <br />
                                    <Row>
                                      <Col>
                                        <Table
                                          className="table table-bordered table-hover"
                                          id="tab_logic"
                                        >
                                          <thead>
                                            <tr>
                                              <th className="text-center">
                                                S.No
                                              </th>
                                              <th className="text-center">
                                                KEY
                                              </th>
                                              <th className="text-center">
                                                Sample
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.sendingParams.length >
                                            0 ? (
                                              this.state.sendingParams.map(
                                                (api, index, idx) => (
                                                  <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{api.key}</td>
                                                    <td>
                                                      <Form.Control
                                                        type="Text"
                                                        name={api.key}
                                                        placeholder="sample Value"
                                                        className="paramsDataTests"
                                                        value={api.sampleValue}
                                                        onChange={
                                                          this
                                                            .inputChangeParamsTest
                                                        }
                                                      />
                                                    </td>
                                                    <td
                                                      style={{
                                                        whiteSpace: "nowrap",
                                                      }}
                                                    />
                                                  </tr>
                                                )
                                              )
                                            ) : (
                                              <div />
                                            )}
                                          </tbody>
                                        </Table>
                                      </Col>
                                    </Row>
                                  </Tab>
                                ))}
                                <Tab
                                  justify={true.toString()}
                                  id="TabsTwo"
                                  eventKey="childTab2"
                                  title="Auth"
                                >
                                  <br />
                                  <Row>
                                    <Col>
                                      <Row className="d-flex">
                                        <Col lg="2" md="2" sm="2">
                                          <p>Type</p>
                                        </Col>
                                        <Col lg="4" md="4" sm="4">
                                          <Form.Select
                                            aria-label="Default select example"
                                            name="authMethod"
                                            value={this.state.authMethod}
                                            onChange={this.inputAuthTChange}
                                          >
                                            <option>
                                              Inherit auth from parent
                                            </option>
                                            <option value="No_Auth">
                                              No Auth
                                            </option>
                                            <option value="API_Key">
                                              API Key
                                            </option>
                                            <option value="Bearer_Auth">
                                              Bearer Auth
                                            </option>
                                            <option value="Basic_Auth">
                                              Basic Auth
                                            </option>
                                            <option value="Digest_Auth">
                                              Digest Auth
                                            </option>
                                            <option value="OAuth1">
                                              OAuth1.0
                                            </option>
                                            <option value="Unlink">
                                              UNLINK
                                            </option>
                                            <option value="HEAD">HEAD</option>
                                            <option value="PURGE">PURGE</option>
                                            <option value="LOCK">LOCK</option>
                                            <option value="UNLOCK">
                                              UNLOCK
                                            </option>
                                          </Form.Select>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Tab>
                                {/* header in Test */}
                                <Tab
                                  justify={true.toString()}
                                  id="TabsTwo"
                                  eventKey="childTab3"
                                  title="Header"
                                >
                                  <br />
                                  <Row>
                                    <Col>
                                      <Table
                                        className="table table-bordered table-hover"
                                        id="tab_logic"
                                      >
                                        <thead>
                                          <tr>
                                            <th className="text-center">
                                              S.No
                                            </th>
                                            <th className="text-center">KEY</th>
                                            <th className="text-center">
                                              Sample
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.sendingHeader.length >
                                          0 ? (
                                            this.state.sendingHeader.map(
                                              (api, index, idx) => (
                                                <tr key={index}>
                                                  <td>{index + 1}</td>
                                                  <td>{api.key}</td>

                                                  <td>
                                                    <Form.Control
                                                      type="Text"
                                                      name={api.key}
                                                      placeholder=""
                                                      className="headerDataTest"
                                                      value={api.sampleValue}
                                                      onChange={
                                                        this.inputChangeBodyTest
                                                      }
                                                    />
                                                  </td>
                                                  <td
                                                    style={{
                                                      whiteSpace: "nowrap",
                                                    }}
                                                  ></td>
                                                </tr>
                                              )
                                            )
                                          ) : (
                                            <div></div>
                                          )}
                                        </tbody>
                                      </Table>
                                    </Col>
                                  </Row>
                                </Tab>

                                {/* Body
                                 */}
                                <Tab
                                  justify={true.toString()}
                                  id="TabsTwo"
                                  eventKey="childTab4"
                                  title="Body"
                                >
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4" sm="4">
                                      <Form.Group>
                                        <Row>
                                          <Col lg="6" md="6" sm="6">
                                            <Form.Label>
                                              Select an option:
                                            </Form.Label>
                                          </Col>
                                          <Col lg="4" md="4" sm="4">
                                            <Form.Control
                                              as="select"
                                              onChange={
                                                this.handleDropdownChangeTest
                                              }
                                            >
                                              <option value="none">None</option>
                                              <option value="form-data">
                                                form-data
                                              </option>
                                              <option value="raw">Raw</option>
                                              <option value="x-www-form-unlencoded">
                                                x-www-form-unlencoded
                                              </option>
                                              <option value="binary">
                                                Binary
                                              </option>
                                            </Form.Control>
                                          </Col>
                                        </Row>
                                      </Form.Group>
                                    </Col>
                                    <Col>{this.renderFormFieldsTest()}</Col>
                                  </Row>
                                </Tab>
                              </Tabs>
                            </Row>
                          </div>
                        </Tab>

                        {/* Receive Tab Test */}
                        <Tab
                          eventKey="Receive"
                          justify="true"
                          id="TabsTwo"
                          className="cursor-pointer py-4 px-8 bg-red-background border border-red-intermediate flex flex-grow"
                          title="Receive"
                        >
                          <div>
                            <br />
                            <Row>
                              <Col>
                                <Table
                                  className="table table-bordered table-hover"
                                  id="tab_logic"
                                >
                                  <thead>
                                    <tr>
                                      <th className="text-center">KEY</th>
                                      <th className="text-center">Sample</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        {this.state.receivingDataTest.key}
                                      </td>

                                      <td>
                                        <Form.Control
                                          type="Text"
                                          name={
                                            this.state.receivingDataTest.key
                                          }
                                          placeholder=""
                                          value={
                                            this.state.receivingDataTest
                                              .sampleValue
                                          }
                                          className="receivingDataTest"
                                          onChange={this.inputChangeBodyTest}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </Col>
                            </Row>
                          </div>
                        </Tab>
                      </Tabs>

                      <Row className="mt-2">
                        <Col className="d-flex  justifyContent-end"></Col>
                        <Col className="d-flex  justifyContent-end">
                          <button
                            type="submit"
                            style={{
                              marginLeft: "10px",
                              backgroundColor: "#dca169",
                              color: "#fff",
                            }}
                            className="btn btn-warning"
                            onClick={this.onSubmitTEST}
                          >
                            Test
                          </button>
                          &nbsp;
                          <button
                            className="btn btn-primary"
                            onClick={this.handleButtonCloseClick}
                          >
                            Close
                          </button>
                        </Col>
                      </Row>
                      <br />

                      <Row>
                        <Col>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Enter Your Response Data"
                            name="textarea"
                            value={this.state.responseData}
                            onChange={this.getResponseData}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            </Col>
          </Row>
          <br />
          <br />
          <div>
            {" "}
            {this.state.isEditing && (
              // Edit Offcanvas
              <Offcanvas
                show={this.state.isEditing}
                onHide={() => this.setState({ isEditing: false })}
                {...this.props}
                style={{ width: "860px", height: "auto" }}
                placement="end"
                backdrop="true"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Edit APIPage</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <div className="form-horizontal form-label-left input_mask">
                    <Form className="form-horizontal form-label-left input_mask">
                      <Row>
                        <Form>
                          <Row className="d-flex">
                            <Col lg="4" md="4">
                              <Form.Label>Company Name</Form.Label>
                            </Col>
                            <Col lg="8" md="8">
                              <input
                                type="text"
                                className="form-control mt-1"
                                placeholder="Enter company name"
                                value={DefaultData.acName}
                                onChange={this.inputChange}
                                name="acName"
                              />
                            </Col>
                            <div
                              className="errorMsg text-center"
                              style={{ color: "red" }}
                            >
                              {this.state.errors.acName}
                            </div>
                          </Row>
                          <br />
                          <Row className="d-flex">
                            <Col lg="4" md="4">
                              <Form.Label>API Name</Form.Label>
                            </Col>
                            <Col lg="8" md="8">
                              <Form.Control
                                type="text"
                                placeholder="Enter Your API Name"
                                value={DefaultData.aName}
                                onChange={this.inputChange}
                                name="aName"
                              />
                            </Col>
                            <div
                              className="errorMsg text-center"
                              style={{ color: "red" }}
                            >
                              {this.state.errors.aName}
                            </div>
                          </Row>
                          <br />
                          <Row className="d-flex">
                            <Col lg="4" md="4">
                              <Form.Label>API Description</Form.Label>
                            </Col>
                            <Col lg="8" md="8">
                              <Form.Control
                                as="textarea"
                                rows={3}
                                placeholder="Enter Your API Description"
                                value={DefaultData.aDescription}
                                onChange={this.inputChange}
                                name="aDescription"
                              />
                            </Col>
                            <div
                              className="errorMsg text-center"
                              style={{ color: "red" }}
                            >
                              {this.state.errors.aDescription}
                            </div>
                          </Row>
                          <br />
                          <Row className="d-flex">
                            <Col lg="4" md="4">
                              <Form.Label>API URL </Form.Label>
                            </Col>
                            <Col lg="8" md="8">
                              <Form.Control
                                type="text"
                                placeholder="Enter Your API URL "
                                value={DefaultData.aUrl}
                                onChange={this.inputChange}
                                name="aUrl"
                              />
                            </Col>
                            <div
                              className="errorMsg text-center"
                              style={{ color: "red" }}
                            >
                              {this.state.errors.aUrl}
                            </div>
                          </Row>
                          <br />
                          <Row className="d-flex">
                            <Col lg="4" md="4">
                              <Form.Label>API key/Token </Form.Label>
                            </Col>
                            <Col lg="8" md="8">
                              <Form.Control
                                type="text"
                                placeholder="Enter Your API key/Token "
                                value={DefaultData.aToken}
                                onChange={this.inputChange}
                                name="aToken"
                              />
                            </Col>
                            <div
                              className="errorMsg text-center"
                              style={{ color: "red" }}
                            >
                              {this.state.errors.aToken}
                            </div>
                          </Row>
                        </Form>
                      </Row>
                      <br />
                      <Row>
                        <Col lg="4" md="4">
                          <Form.Label>Select Method </Form.Label>
                        </Col>
                        <Col
                          lg="4"
                          className="d-flex justifyContent-start"
                          style={{ width: "250px" }}
                        >
                          <Form.Select
                            aria-label="Default select example"
                            name="sendMethod"
                            value={DefaultData.sendMethod}
                            onChange={this.inputChange}
                          >
                            <option>Select method</option>
                            <option value="GET">GET</option>
                            <option value="POST">POST</option>
                            <option value="PUT">PUT</option>
                            <option value="PATCH">PATCH</option>
                            <option value="DELETE">DELETE</option>
                            <option value="LINK">LINK</option>
                            <option value="LINK">UNLINK</option>
                            <option value="HEAD">HEAD</option>
                            <option value="PURGE">PURGE</option>
                            <option value="LOCK">LOCK</option>
                            <option value="UNLOCK">UNLOCK</option>
                          </Form.Select>
                        </Col>
                      </Row>
                      <br />
                      <br />
                      <div style={{ margin: "0px", padding: "0px" }}>
                        <Tabs
                          defaultActiveKey="Send123"
                          id="justify-tab-example"
                          className="TabsOne mt-5"
                        >
                          <Tab
                            eventKey="Send123"
                            id="TabsTwo"
                            style={{ color: "red" }}
                            className="cursor-pointer py-4 px-8 bg-red-background border border-red-intermediate flex flex-grow"
                            title="Send"
                          >
                            <div>
                              <br />

                              <Tabs
                                defaultActiveKey="childTab1"
                                id="child-tabs"
                              >
                                {Object.keys(requests).map((key) => (
                                  // PARAMS FIELDS TABLES
                                  <Tab
                                    justify={true.toString()}
                                    id="TabsTwo"
                                    key={key}
                                    eventKey={key}
                                    title="Params"
                                  >
                                    <br />
                                    <Row>
                                      <Col>
                                        <Table
                                          className="table table-bordered table-hover"
                                          id="tab_logic"
                                        >
                                          <thead>
                                            <tr>
                                              <th className="text-center">
                                                S.No
                                              </th>
                                              <th className="text-center">
                                                KEY
                                              </th>
                                              <th className="text-center">
                                                TableName
                                              </th>
                                              <th className="text-center">
                                                columnName
                                              </th>
                                              <th className="text-center">
                                                Sample
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.sendingParams.length >
                                            0 ? (
                                              this.state.sendingParams.map(
                                                (api, index, idx) => (
                                                  <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                      {this.state
                                                        .editingIndex ===
                                                      index ? (
                                                        <Form.Control
                                                          style={{
                                                            width: "130px",
                                                            height: "38px",
                                                          }}
                                                          type="Text"
                                                          value={api.key}
                                                          onChange={(e) =>
                                                            this.handleEdiInputChange(
                                                              index,
                                                              "key",
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                      ) : (
                                                        api.key
                                                      )}
                                                    </td>
                                                    <td>
                                                      {this.state
                                                        .editingIndex ===
                                                      index ? (
                                                        <select
                                                          className="form-select"
                                                          name="tableName"
                                                          value={api.tableName}
                                                          onChange={(e) =>
                                                            this.handleEditInputChange(
                                                              index,
                                                              "tableName",
                                                              e.target.value
                                                            )
                                                          }
                                                        >
                                                          <option defaultValue>
                                                            Select Table
                                                          </option>
                                                          {this.state.tables.map(
                                                            (
                                                              api,
                                                              index,
                                                              idx
                                                            ) => (
                                                              <option
                                                                key={index}
                                                                value={api}
                                                              >
                                                                {api}
                                                              </option>
                                                            )
                                                          )}
                                                        </select>
                                                      ) : (
                                                        api.tableName
                                                      )}
                                                    </td>
                                                    <td>
                                                      {this.state
                                                        .editingIndex ===
                                                      index ? (
                                                        <select
                                                          className="form-select"
                                                          value={api.columnName}
                                                          name="columnName"
                                                          onChange={(e) =>
                                                            this.handleEditInputChange(
                                                              index,
                                                              "columnName",
                                                              e.target.value
                                                            )
                                                          }
                                                        >
                                                          <option defaultValue>
                                                            Select Column
                                                          </option>
                                                          {this.state.rcolumns.map(
                                                            (
                                                              api,
                                                              index,
                                                              idx
                                                            ) => (
                                                              <option
                                                                key={index}
                                                                value={api}
                                                              >
                                                                {api}
                                                              </option>
                                                            )
                                                          )}
                                                        </select>
                                                      ) : (
                                                        api.columnName
                                                      )}
                                                    </td>
                                                    <td>
                                                      {this.state
                                                        .editingIndex ===
                                                      index ? (
                                                        <Form.Control
                                                          style={{
                                                            width: "130px",
                                                            height: "38px",
                                                          }}
                                                          type="Text"
                                                          name="sampleValue"
                                                          placeholder="sample"
                                                          value={
                                                            api.sampleValue
                                                          }
                                                          onChange={(e) =>
                                                            this.handleEditInputChange(
                                                              index,
                                                              "sampleValue",
                                                              e.target.value
                                                            )
                                                          }
                                                        />
                                                      ) : (
                                                        api.sampleValue
                                                      )}
                                                    </td>
                                                    <td>
                                                      {this.state
                                                        .editingIndex ===
                                                        index && ( // Extra "Add" button below the row being edited
                                                        <Button
                                                          type="button"
                                                          variant="success"
                                                          onClick={
                                                            this
                                                              .updateSendRowReceive
                                                          }
                                                        >
                                                          update
                                                        </Button>
                                                      )}
                                                      <button
                                                        className="bi bi-pen btn btn-info"
                                                        onClick={() =>
                                                          this.handleEditTableRow(
                                                            index
                                                          )
                                                        }
                                                      ></button>
                                                      {this.state
                                                        .editingIndex !==
                                                        index && ( // Condition to render Add button above the row being edited
                                                        <button
                                                          variant="danger"
                                                          className="bi bi-trash-fill btn btn-danger"
                                                          onClick={() =>
                                                            this.handleRemoveSpecificRowReceive(
                                                              index
                                                            )
                                                          }
                                                        ></button>
                                                      )}
                                                    </td>
                                                  </tr>
                                                )
                                              )
                                            ) : (
                                              <tr>
                                                <td colSpan="6"> No Records</td>
                                              </tr>
                                            )}
                                            <tr>
                                              <td>---</td>
                                              <td>
                                                <Form.Control
                                                  style={{
                                                    width: "130px",
                                                    height: "38px",
                                                  }}
                                                  type="Text"
                                                  name="key"
                                                  placeholder="key"
                                                  value={
                                                    this.state.paramsData.key
                                                  }
                                                  onChange={
                                                    this.inputSParmsChange
                                                  }
                                                />
                                                <div
                                                  className="errorMsg"
                                                  style={{ color: "red" }}
                                                >
                                                  {
                                                    this.state.errors
                                                      .paginationServerkey
                                                  }
                                                </div>
                                              </td>
                                              <td>
                                                <select
                                                  className="form-select"
                                                  name="tableName"
                                                  value={
                                                    this.state.paramsData
                                                      .tableName
                                                  }
                                                  onChange={
                                                    this.inputtableSParmsChange
                                                  }
                                                >
                                                  <option defaultValue>
                                                    Select Table
                                                  </option>
                                                  {this.state.tables.map(
                                                    (api, index, idx) => (
                                                      <option
                                                        key={index}
                                                        value={api}
                                                      >
                                                        {api}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                                <div
                                                  className="errorMsg"
                                                  style={{ color: "red" }}
                                                >
                                                  {this.state.errors.tableName}
                                                </div>
                                              </td>
                                              <td>
                                                <select
                                                  className="form-select "
                                                  name="columnName"
                                                  value={
                                                    this.state.paramsData
                                                      .columnName
                                                  }
                                                  onChange={
                                                    this.inputSParmsChange
                                                  }
                                                >
                                                  <option defaultValue>
                                                    Select Column
                                                  </option>
                                                  {this.state.columns.map(
                                                    (api, index, idx) => (
                                                      <option
                                                        key={index}
                                                        value={api}
                                                      >
                                                        {api}
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                                <div
                                                  className="errorMsg"
                                                  style={{ color: "red" }}
                                                >
                                                  {this.state.errors.columnName}
                                                </div>
                                              </td>
                                              <td>
                                                <Form.Control
                                                  style={{
                                                    width: "130px",
                                                    height: "38px",
                                                  }}
                                                  type="Text"
                                                  name="sampleValue"
                                                  placeholder=""
                                                  value={
                                                    this.state.paramsData
                                                      .sampleValue
                                                  }
                                                  onChange={
                                                    this.inputSParmsChange
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <Button
                                                  type="button"
                                                  variant="success"
                                                  // className="bi bi-plus"
                                                  onClick={this.addSendpRow}
                                                >
                                                  Add
                                                </Button>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </Table>
                                      </Col>
                                    </Row>
                                  </Tab>
                                ))}
                                <Tab
                                  justify={true.toString()}
                                  id="TabsTwo"
                                  eventKey="childTab2"
                                  title="Auth"
                                >
                                  <br />
                                  <Row>
                                    <Col>
                                      <Row className="d-flex">
                                        <Col lg="2" md="2" sm="2">
                                          <p>Type</p>
                                        </Col>
                                        <Col lg="4" md="4" sm="4">
                                          <Form.Select
                                            aria-label="Default select example"
                                            name="sendMethod"
                                            className=""
                                            value={this.state.sendMethod}
                                          >
                                            <option>
                                              Inherit auth from parent
                                            </option>
                                            <option value="No_Auth">
                                              No Auth
                                            </option>
                                            <option value="API_Key">
                                              API Key
                                            </option>
                                            <option value="Bearer_Auth">
                                              Bearer Auth
                                            </option>
                                            <option value="Basic_Auth">
                                              Basic Auth
                                            </option>
                                            <option value="Digest_Auth">
                                              Digest Auth
                                            </option>
                                            <option value="OAuth1">
                                              OAuth1.0
                                            </option>
                                            <option value="">UNLINK</option>
                                            <option value="HEAD">HEAD</option>
                                            <option value="PURGE">PURGE</option>
                                            <option value="LOCK">LOCK</option>
                                            <option value="UNLOCK">
                                              UNLOCK
                                            </option>
                                          </Form.Select>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Tab>
                                <Tab
                                  //={true.toString()}
                                  id="TabsTwo"
                                  eventKey="childTab3"
                                  title="Header"
                                >
                                  <br />
                                  <Row>
                                    <Col>
                                      <Table
                                        className="table table-bordered table-hover"
                                        id="tab_logic"
                                      >
                                        <thead>
                                          <tr>
                                            <th className="text-center">
                                              S.NO
                                            </th>
                                            <th className="text-center">KEY</th>
                                            <th className="text-center">
                                              Table Name
                                            </th>
                                            <th className="text-center">
                                              Column Name
                                            </th>
                                            <th className="text-center">
                                              Sample
                                            </th>
                                            <th className="text-center">
                                              Actions
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.sendingHeader.length >
                                          0 ? (
                                            this.state.sendingHeader.map(
                                              (api, index, idx) => (
                                                <tr key={index}>
                                                  <td>{index + 1}</td>
                                                  <td>
                                                    {this.state.editingIndex ===
                                                    index ? (
                                                      <Form.Control
                                                        style={{
                                                          width: "130px",
                                                          height: "38px",
                                                        }}
                                                        type="Text"
                                                        value={api.key}
                                                        onChange={(e) =>
                                                          this.handleEditInputChangeHeader(
                                                            index,
                                                            "key",
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      api.key
                                                    )}
                                                  </td>
                                                  <td>
                                                    {this.state.editingIndex ===
                                                    index ? (
                                                      <select
                                                        className="form-select"
                                                        name="tableName"
                                                        value={api.tableName}
                                                        onChange={(e) =>
                                                          this.handleEditInputChangeHeader(
                                                            index,
                                                            "tableName",
                                                            e.target.value
                                                          )
                                                        }
                                                      >
                                                        <option defaultValue>
                                                          Select Table
                                                        </option>
                                                        {this.state.tables.map(
                                                          (api, index, idx) => (
                                                            <option
                                                              key={index}
                                                              value={api}
                                                            >
                                                              {api}
                                                            </option>
                                                          )
                                                        )}
                                                      </select>
                                                    ) : (
                                                      api.tableName
                                                    )}
                                                  </td>
                                                  <td>
                                                    {this.state.editingIndex ===
                                                    index ? (
                                                      <select
                                                        className="form-select"
                                                        value={api.columnName}
                                                        name="columnName"
                                                        onChange={(e) =>
                                                          this.handleEditInputChangeHeader(
                                                            index,
                                                            "columnName",
                                                            e.target.value
                                                          )
                                                        }
                                                      >
                                                        <option defaultValue>
                                                          Select Column
                                                        </option>
                                                        {this.state.rcolumns.map(
                                                          (api, index, idx) => (
                                                            <option
                                                              key={index}
                                                              value={api}
                                                            >
                                                              {api}
                                                            </option>
                                                          )
                                                        )}
                                                      </select>
                                                    ) : (
                                                      api.columnName
                                                    )}
                                                  </td>
                                                  <td>
                                                    {this.state.editingIndex ===
                                                    index ? (
                                                      <Form.Control
                                                        style={{
                                                          width: "130px",
                                                          height: "38px",
                                                        }}
                                                        type="Text"
                                                        name="sampleValue"
                                                        placeholder="sample"
                                                        value={api.sampleValue}
                                                        onChange={(e) =>
                                                          this.handleEditInputChangeHeader(
                                                            index,
                                                            "sampleValue",
                                                            e.target.value
                                                          )
                                                        }
                                                      />
                                                    ) : (
                                                      api.sampleValue
                                                    )}
                                                  </td>
                                                  <td>
                                                    {this.state.editingIndex ===
                                                      index && ( // Extra "Add" button below the row being edited
                                                      <Button
                                                        type="button"
                                                        variant="success"
                                                        onClick={
                                                          this.updateSendHeader
                                                        }
                                                        disabled={
                                                          this.State
                                                            .isButtonDisabled
                                                        }
                                                      >
                                                        update
                                                      </Button>
                                                    )}
                                                    <button
                                                      className="bi bi-pen btn btn-info"
                                                      onClick={() =>
                                                        this.handleEditTableRowHeader(
                                                          index
                                                        )
                                                      }
                                                    ></button>
                                                    {this.state.editingIndex !==
                                                      index && ( // Condition to render Add button above the row being edited
                                                      <button
                                                        variant="danger"
                                                        className="bi bi-trash-fill btn btn-danger"
                                                        onClick={() =>
                                                          this.handleRemoveSpecificRowHeader(
                                                            index
                                                          )
                                                        }
                                                      ></button>
                                                    )}
                                                  </td>
                                                </tr>
                                              )
                                            )
                                          ) : (
                                            <tr>
                                              <td colSpan="6"> No Records</td>
                                            </tr>
                                          )}
                                          <tr>
                                            <td>---</td>
                                            <td>
                                              <Form.Control
                                                style={{
                                                  width: "130px",
                                                  height: "38px",
                                                }}
                                                type="Text"
                                                name="key"
                                                placeholder="key"
                                                value={DefaultData.key}
                                                onChange={this.inputSHrChange}
                                              />
                                            </td>
                                            <td>
                                              <select
                                                className="form-select"
                                                name="tableName"
                                                value={DefaultData.tableName}
                                                onChange={
                                                  this.inputtableSHrChange
                                                }
                                              >
                                                <option defaultValue>
                                                  Select Table
                                                </option>
                                                {this.state.tables.map(
                                                  (api, index, idx) => (
                                                    <option
                                                      key={index}
                                                      value={api}
                                                    >
                                                      {api}
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                              <div
                                                className="errorMsg"
                                                style={{ color: "red" }}
                                              >
                                                {this.state.errors.tableName}
                                              </div>
                                            </td>
                                            <td>
                                              <select
                                                className="form-select "
                                                name="columnName"
                                                value={DefaultData.columnName}
                                                onChange={this.inputSHrChange}
                                              >
                                                <option defaultValue>
                                                  Select Column
                                                </option>
                                                {this.state.columns.map(
                                                  (api, index, idx) => (
                                                    <option
                                                      key={index}
                                                      value={api}
                                                    >
                                                      {api}
                                                    </option>
                                                  )
                                                )}
                                              </select>
                                              <div
                                                className="errorMsg"
                                                style={{ color: "red" }}
                                              >
                                                {this.state.errors.columnName}
                                              </div>
                                            </td>
                                            <td>
                                              <Form.Control
                                                style={{
                                                  width: "130px",
                                                  height: "38px",
                                                }}
                                                type="Text"
                                                name="sampleValue"
                                                placeholder=""
                                                value={DefaultData.sampleValue}
                                                onChange={this.inputSHrChange}
                                              />
                                            </td>
                                            <td>
                                              <Button
                                                type="button"
                                                variant="success"
                                                // className="bi bi-plus"
                                                onClick={this.addSendhrRow}
                                              >
                                                Add
                                              </Button>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </Col>
                                  </Row>
                                </Tab>
                                <Tab
                                  justify={true.toString()}
                                  id="TabsTwo"
                                  eventKey="childTab4"
                                  title="Body"
                                >
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4" sm="4">
                                      <Form.Group>
                                        <Row>
                                          <Col lg="6" md="6" sm="6">
                                            <Form.Label>
                                              Select an option:
                                            </Form.Label>
                                          </Col>
                                          <Col lg="4" md="4" sm="4">
                                            <Form.Control
                                              as="select"
                                              onChange={
                                                this.handleDropdownChange
                                              }
                                            >
                                              <option value="none">None</option>
                                              <option value="form-data">
                                                form-data
                                              </option>
                                              <option value="raw">Raw</option>
                                              <option value="x-www-form-unlencoded">
                                                x-www-form-urlencoded
                                              </option>
                                              <option value="binary">
                                                Binary
                                              </option>
                                            </Form.Control>
                                          </Col>
                                        </Row>
                                      </Form.Group>
                                    </Col>
                                    <Col>{this.renderFormFields()}</Col>
                                  </Row>
                                </Tab>
                              </Tabs>
                            </div>
                          </Tab>
                          <Tab
                            eventKey="Receive"
                            justify
                            id="TabsTwo"
                            style={{ color: "red" }}
                            className="cursor-pointer py-4 px-8 bg-red-background border border-red-intermediate flex flex-grow"
                            title="Receive"
                          >
                            <div>
                              <br />
                              <Row>
                                <Col>
                                  <Table
                                    className="table table-bordered table-hover"
                                    id="tab_logic"
                                  >
                                    <thead>
                                      <tr>
                                        <th className="text-center">S.NO</th>
                                        <th className="text-center">KEY</th>
                                        <th className="text-center">Sample</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.receiving.length > 0 ? (
                                        this.state.receiving.map(
                                          (api, index, idx) => (
                                            <tr key={index}>
                                              <td>{index + 1}</td>
                                              <td>{api.arpKey}</td>
                                              <td>{api.arpValue}</td>
                                              <td>
                                                <button className="bi bi-pen btn btn-info"></button>
                                                <button
                                                  variant="danger"
                                                  className="bi bi-trash-fill btn btn-danger"
                                                  onClick={() =>
                                                    this.handleRemoveSpecificRowReceive(
                                                      index
                                                    )
                                                  }
                                                  // idx={idx}
                                                ></button>
                                              </td>
                                            </tr>
                                          )
                                        )
                                      ) : (
                                        <tr>
                                          <td colSpan="6"> No Records</td>
                                        </tr>
                                      )}
                                      <tr>
                                        <td>---</td>
                                        <td>
                                          <Form.Control
                                            style={{
                                              width: "130px",
                                              height: "38px",
                                            }}
                                            type="Text"
                                            name="arpKey"
                                            placeholder="key"
                                            value={
                                              this.state.receivingData.arpKey
                                            }
                                            onChange={this.inputRChange}
                                          />
                                          <div
                                            className="errorMsg"
                                            style={{ color: "red" }}
                                          >
                                            {this.state.errors.key}
                                          </div>
                                        </td>
                                        <td>
                                          <select
                                            className="form-select"
                                            name="tableName"
                                            value={
                                              this.state.receivingData.tableName
                                            }
                                            onChange={this.inputtableRChange}
                                          >
                                            <option defaultValue>
                                              Select Table
                                            </option>
                                            {this.state.tables.map(
                                              (api, index, idx) => (
                                                <option key={index} value={api}>
                                                  {api}
                                                </option>
                                              )
                                            )}
                                          </select>
                                          <div
                                            className="errorMsg"
                                            style={{ color: "red" }}
                                          >
                                            {this.state.errors.tableName}
                                          </div>
                                        </td>
                                        <td>
                                          <select
                                            className="form-select"
                                            value={
                                              this.state.receivingData
                                                .columnName
                                            }
                                            name="columnName"
                                            onChange={this.inputRChange}
                                          >
                                            <option defaultValue>
                                              Select Column
                                            </option>
                                            {this.state.rcolumns.map(
                                              (api, index, idx) => (
                                                <option key={index} value={api}>
                                                  {api}
                                                </option>
                                              )
                                            )}
                                          </select>
                                          <div
                                            className="errorMsg"
                                            style={{ color: "red" }}
                                          >
                                            {this.state.errors.columnName}
                                          </div>
                                        </td>
                                        <td>
                                          <Form.Control
                                            style={{
                                              width: "130px",
                                              height: "38px",
                                            }}
                                            type="Text"
                                            name="arpValue"
                                            placeholder=""
                                            value={
                                              this.state.receivingData.arpValue
                                            }
                                            onChange={this.inputRChange}
                                          />
                                        </td>
                                        <td>
                                          <Button
                                            type="button"
                                            variant="success"
                                            // className="bi bi-plus"
                                            onClick={this.addSendRowReceive}
                                          >
                                            Add
                                          </Button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </Col>
                              </Row>
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                      <br />
                      <button
                        className="btn btn-primary me-2 mt-5"
                        style={{
                          backgroundColor: "#23547e",
                          color: "#fff",
                          borderColor: "#2e6da4",
                          float: "right",
                        }}
                        onClick={this.apisHandlerupdate}
                      >
                        Update
                      </button>
                    </Form>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            )}
          </div>
          <br />
          <div>
            <Row>
              <Col>
                {/* searchbar,pagination,filter */}
                <input
                  style={{ float: "left", marginBottom: "10px" }}
                  type="text"
                  onChange={this.handleFilter}
                  placeholder="Search..."
                />

                <DataTable
                  data={paginatedData}
                  // keyField={this.state.keyField}
                  columns={this.state.tableClms}
                  pagination
                  paginationServer
                  paginationTotalRows={
                    this.state.filteredData
                      ? this.state.filteredData.length
                      : this.state.apis.length
                  }
                  onChangeRowsPerPage={this.handlePerRowsChange}
                  onChangePage={this.handlePageChange}
                  paginationRowsPerPageOptions={[
                    10, 20, 30, 50, 60, 70, 80, 90, 100,
                  ]}
                  paginationPerPage={this.state.itemsPerPage}
                  className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

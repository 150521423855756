import React, { Component } from "react";
import { Button, Row, Form, Col, Offcanvas, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";

export default class SampleJSONrequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      requestId: sessionStorage.getItem("requestId"),
      integrationlibrary_Id: sessionStorage.getItem("integrationlibrary_Id"),
      data: [],
      jsonData: "",
      SampleJSONrequest: [],
      currentPage: 1,
      isSuccess: false,
      isSuccessretrieve: false,
      showRetrieveButton: false,
    };
  }
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  handleOffcanvasShow = () => {
    this.setState({
      show: true,
      data: [],
    });
  };
  handleChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ jsonData: fieldValue });
  };
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  handleFormRetrie = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("requestId", this.state.requestId);
    fetch(configData.api_url + "C_Integrationlibrary/retriveJson", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({
            jsonData: JSON.stringify(data.data),
            isSuccessretrieve: true,
            SampleJSONrequest: [],
          });
        }
      })
      .catch((error) => console.error("Error:", error));
  };
  handleFormSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("integId", this.state.integrationlibrary_Id);
    formData.append("jsonData", JSON.stringify(this.state.jsonData));
    fetch(configData.api_url + "C_Integrationlibrary/saveJson", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({ isSuccess: true });
          sessionStorage.setItem("requestId", data.requestId);
          setTimeout(() => {
            this.setState({ showRetrieveButton: true });
          }, 10000);
        }
      });
  };
  //C_integrationlibrary/saveJson

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.SampleJSONrequest.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );
    const { isSuccess, isSuccessretrieve, jsonData } = this.state;
    return (
      <div>
        <Row>
          <div style={{ marginRight: "5px" }}>
            <div className="page-title">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="x_panel">
                    <div className="x_content">
                      <div
                        id="datatable_wrapper"
                        className="dataTables_wrapper form-inline
                            dt-bootstrap no-footer"
                      >
                        <div className="title_left">
                          <h2
                            className="logoskybluecolor"
                            style={{ textAlign: "start" }}
                          >
                            Sample JSON Request
                          </h2>
                        </div>
                        <Row className="row">
                          {" "}
                          <Col
                            className="d-flex justify-content-end"
                            style={{ marginRight: "5px", marginTop: "20px" }}
                          >
                            <Offcanvas
                              show={this.state.show}
                              onHide={this.handleOffcanvasClose}
                              {...this.props}
                              style={{ width: "500px" }}
                              placement="end"
                              backdrop="true"
                            >
                              <Offcanvas.Header closeButton>
                                <Offcanvas.Title>
                                  Sample JSON Request.
                                </Offcanvas.Title>
                              </Offcanvas.Header>
                              <hr />
                              <Offcanvas.Body>
                                <Container
                                  style={{ overflowY: "auto", height: "auto" }}
                                >
                                  <Row className="divstyle">
                                    <Col>
                                      <Form.Group>
                                        <Form.Label>JSON Data</Form.Label>
                                        <Form.Control
                                          as="textarea"
                                          rows="10"
                                          cols="50"
                                          value={jsonData}
                                          onChange={this.handleChange}
                                          placeholder="Enter JSON data here"
                                          style={{
                                            width: "100%",
                                            margin: "10px 0",
                                          }}
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>

                                  <br />
                                  {isSuccess && (
                                    <p>Wait for 10 sec to Retrieve data</p>
                                  )}
                                  <Row>
                                    {isSuccess && (
                                      <Col>
                                        {this.state.showRetrieveButton && (
                                          <Button
                                            variant="success"
                                            className="button"
                                            onClick={(e) =>
                                              this.handleFormRetrie(e)
                                            }
                                          >
                                            Retrieve
                                          </Button>
                                        )}
                                      </Col>
                                    )}
                                    <Col className="d-flex justify-content-end">
                                      {" "}
                                      <Button
                                        variant="success"
                                        className="button"
                                        onClick={(e) =>
                                          this.handleFormSubmit(e)
                                        }
                                      >
                                        Submit
                                      </Button>
                                    </Col>
                                  </Row>
                                  {isSuccessretrieve && (
                                    <Row className="divstyle">
                                      <Col>
                                        <Form.Group>
                                          <Form.Label>
                                            JSON Data Response
                                          </Form.Label>
                                          <Form.Control
                                            as="textarea"
                                            rows="10"
                                            cols="50"
                                            value={jsonData}
                                            //onChange={this.handleChange}
                                            readOnly
                                          />
                                        </Form.Group>
                                      </Col>
                                    </Row>
                                  )}
                                </Container>
                              </Offcanvas.Body>
                            </Offcanvas>
                          </Col>{" "}
                        </Row>
                        <Row>
                          {" "}
                          <div style={{ marginRight: "5px" }}>
                            <input
                              type="text"
                              style={{ float: "left", marginBottom: "10px" }}
                              onChange={this.handleFilter}
                              placeholder="Search..."
                            />
                            <Button
                              variant="primary"
                              style={{ float: "right", marginBottom: "10px" }}
                              className="bi bi-plus "
                              onClick={this.handleOffcanvasShow}
                            >
                              New
                            </Button>
                            <DataTable
                              data={paginatedData}
                              columns={this.state.tableClms}
                              pagination
                              paginationServer
                              paginationTotalRows={
                                this.state.filteredData
                                  ? this.state.filteredData.length
                                  : this.state.SampleJSONrequest.length
                              }
                              paginationRowsPerPageOptions={[
                                10, 20, 30, 50, 60, 70, 80, 90, 100,
                              ]}
                              onChangeRowsPerPage={this.handlePerRowsChange}
                              onChangePage={this.handlePageChange}
                              paginationPerPage={this.state.itemsPerPage}
                              keyField="id"
                              className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                            />
                          </div>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* searchbar,pagination,filter */}
            </div>
          </div>
        </Row>
      </div>
    );
  }
}

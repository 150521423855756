import React, { Component } from "react";
import { Card, Row, Col, Form, Button, Offcanvas } from "react-bootstrap";
import configData from "../config.json";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default class PospE extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      errors: {},
      category: [],
      categories: "",
      filteredData: null,
      pospId: "",
      createdBy: 0,
      tableClms: [
        {
          name: "S.NO",
          width: "75px",
          selector: (row, i) => row.pospId,
          sortable: true,
          cell: (row) => <span>{row.pospId}</span>,
        },
        {
          name: "First Name",
          id: "pfName",
          selector: (row, i) => row.pfName,
          sortable: true,
          cell: (row) => <span>{row.pfName}</span>,
        },
        {
          name: "Last Name",
          id: "plName",
          selector: (row, i) => row.plName,
          sortable: true,
          cell: (row) => <span>{row.plName}</span>,
        },

        {
          name: "Mobile Number",
          id: "pmobilenumber",
          selector: (row, i) => row.pmobilenumber,
          sortable: true,
          cell: (row) => <span>{row.pmobilenumber}</span>,
        },
        {
          name: "PAN",
          id: "pannumber",
          selector: (row, i) => row.pannumber,
          sortable: true,
          cell: (row) => <span>{row.pannumber}</span>,
        },
        {
          name: "Aadhar Number",
          id: "aadharnumber",
          selector: (row, i) => row.aadharnumber,
          sortable: true,
          cell: (row) => <span>{row.aadharnumber}</span>,
        },
        {
          name: "Email-Id",
          id: "emailid",
          selector: (row, i) => row.emailid,
          sortable: true,
          cell: (row) => <span>{row.emailid}</span>,
        },
        {
          name: "Address",
          id: "addressLine",
          selector: (row, i) => row.addressLine,
          sortable: true,
          cell: (row) => <span>{row.addressLine}</span>,
        },

        {
          name: "Pincode",
          id: "pincode",
          selector: (row, i) => row.pincode,
          sortable: true,
          cell: (row) => <span>{row.pincode}</span>,
        },
        {
          name: "Area",
          id: "areaCd",
          selector: (row, i) => row.areaCd,
          sortable: true,
          cell: (row) => <span>{row.areaCd}</span>,
        },

        {
          name: "State",
          id: "stateCd",
          selector: (row, i) => row.stateCd,
          sortable: true,
          cell: (row) => <span>{row.stateCd}</span>,
        },
        {
          name: "Irda Branch",
          id: "irdabranch",
          selector: (row, i) => row.irdabranch,
          sortable: true,
          cell: (row) => <span>{row.irdabranch}</span>,
        },
        {
          name: "Bank Name",
          id: "bankName",
          selector: (row, i) => row.bankName,
          sortable: true,
          cell: (row) => <span>{row.bankName}</span>,
        },
        {
          name: "Account Number",
          id: "accNO",
          selector: (row, i) => row.accNO,
          sortable: true,
          cell: (row) => <span>{row.accNO}</span>,
        },
        {
          name: "IFSC Code",
          id: "ifscCode",
          selector: (row, i) => row.ifscCode,
          sortable: true,
          cell: (row) => <span>{row.ifscCode}</span>,
        },
        {
          name: "Branch Code",
          id: "branchCode",
          selector: (row, i) => row.branchCode,
          sortable: true,
          cell: (row) => <span>{row.branchCode}</span>,
        },
        {
          name: "Alernate Person Full Name",
          id: "fullName",
          selector: (row, i) => row.fullName,
          sortable: true,
          cell: (row) => <span>{row.fullName}</span>,
        },
        {
          name: "IFSC Code",
          id: "ifscCode",
          selector: (row, i) => row.ifscCode,
          sortable: true,
          cell: (row) => <span>{row.ifscCode}</span>,
        },
        {
          name: "Alternate Person Mobile Number",
          id: "amobilenumber",
          selector: (row, i) => row.amobilenumber,
          sortable: true,
          cell: (row) => <span>{row.amobilenumber}</span>,
        },
        {
          name: "Alternate Person Email Id",
          id: "aemailid",
          selector: (row, i) => row.aemailid,
          sortable: true,
          cell: (row) => <span>{row.aemailid}</span>,
        },
        {
          name: "Alternate Person Address",
          id: "aAddressLine",
          selector: (row, i) => row.aAddressLine,
          sortable: true,
          cell: (row) => <span>{row.aAddressLine}</span>,
        },
        {
          name: "Alternate Person Pincode",
          id: "apincode",
          selector: (row, i) => row.apincode,
          sortable: true,
          cell: (row) => <span>{row.apincode}</span>,
        },
        {
          name: "Alternate Person Area",
          id: "aareaCd",
          selector: (row, i) => row.aareaCd,
          sortable: true,
          cell: (row) => <span>{row.aareaCd}</span>,
        },
        {
          name: "Alternate Person City",
          id: "acityCd",
          selector: (row, i) => row.acityCd,
          sortable: true,
          cell: (row) => <span>{row.acityCd}</span>,
        },

        {
          name: "IRDA Office",
          id: "irdabranch",
          selector: (row, i) => row.irdabranch,
          sortable: true,
          cell: (row) => <span>{row.irdabranch}</span>,
        },

        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-box-arrow-in-up"
                style={{ marginRight: "3px" }}
              ></Button>
              {/* <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button> */}
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      IRDAInsuranceCategory: "",
      irdainsurancecategories_Id: "",
      isEditing: false, // Flag to indicate edit mode
      isNewEntry: false,
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
    };
  }
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };

  handleShow = () => {
    this.setState({ show: true });
  };

  handleClose = () => {
    this.setState({ show: false });
    this.PospfetchHandler();
  };
  handleOffcanvasClose = () => {
    this.setState({ isEditing: false });
  };

  PospfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("pospId", this.state.pospId);

    fetch(configData.api_url + "posps/all", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((rsdata) => {
        console.log("API Response:", rsdata);
        this.showToast(rsdata.message, rsdata.status);
        if (rsdata.status === "success") {
          const list = rsdata.list;
          const data = [];
          list.forEach((element) => {
            if (element.pStatus === "9") {
              console.log(element.pStatus);
              data.push(element);
            }
          });
          this.handleOffcanvasClose();
          this.setState({ data: data });
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        this.showToast("Failed to fetch data", "error");
      });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  componentDidMount() {
    this.PospfetchHandler();
    this.IrdainsurancecategoriesfetchHandler();
  }

  IrdainsurancecategoriesfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Irdainsurancecategories/list", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ category: data.list });
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        this.showToast("Failed to fetch categories", "error");
      });
  };

  handleEnterEditMode = (row) => {
    this.setState({
      pospId: row.pospId,
      createdBy: row.createdBy,
      IRDAInsuranceCategory: row.IRDAInsuranceCategory,
      irdainsurancecategories_Id: row.irdainsurancecategories_Id,
      isEditing: true,
    });
  };

  handleChange = (e) => {
    const { name, value, checked } = e.target;
    let { categories } = this.state;

    if (!Array.isArray(categories)) {
      categories = [];
    }

    if (checked) {
      categories.push(value);
    } else {
      categories = categories.filter((item) => item !== value);
    }

    this.setState({
      [name]: value,
      categories,
      errors: {
        ...this.state.errors,
        [name]: "",
      },
    });
  };
  handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      this.setState((prevState) => ({
        selectedCategories: [...prevState.selectedCategories, value],
      }));
    } else {
      this.setState((prevState) => ({
        selectedCategories: prevState.selectedCategories.filter(
          (category) => category !== value
        ),
      }));
    }
  };
  handleSubmit = (e, status) => {
    e.preventDefault();

    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("pospId", this.state.pospId);
    formData.append("pStatus", status);
    formData.append("categories", this.state.categories.join(","));
    formData.append("createdBy", this.state.createdBy);
    fetch(configData.api_url + "posps/updateCategories/", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);

        if (data.status === "success") {
          this.setState({
            categories: [],
          });
          this.handleClose();
          sessionStorage.setItem("pospId", data.pospId);
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        this.showToast("Failed to submit form", "error");
      });
  };

  onKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault();
      toast.error("Spaces are not allowed.");
    }
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );
    return (
      <div>
        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
            <div className="x_panel">
              <div className="x_content">
                <div className="dataTables_wrapper form-inline dt-bootstrap no-footer">
                  <Row>
                    <Row>
                      <Col>
                        <div className="" style={{ float: "left" }}>
                          <h3 className="logoskybluecolor">POSP ID Approval</h3>
                        </div>
                      </Col>
                    </Row>
                    <Col>
                      {this.state.isEditing && (
                        <Offcanvas
                          show={this.state.isEditing}
                          onHide={() => this.setState({ isEditing: false })}
                          {...this.props}
                          style={{ width: "500px" }}
                          placement="end"
                          backdrop="true"
                        >
                          <Offcanvas.Header closeButton>
                            <h3 className="logoskybluecolor">
                              POSP ID Approval
                            </h3>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                            <Card>
                              <Card.Body>
                                <Row>
                                  <Form.Label>Select Categories</Form.Label>
                                  <Col>
                                    {this.state.category.map((data, index) => (
                                      <Form key={index}>
                                        <input
                                          style={{ margin: "5px" }}
                                          type="checkbox"
                                          value={data.IRDAInsuranceCategory}
                                          name="categories"
                                          onChange={this.handleChange}

                                          // checked={this.state.categories.includes(data.IRDAInsuranceCategory)}
                                        />
                                        <b>{data.IRDAInsuranceCategory}</b>
                                      </Form>
                                    ))}
                                  </Col>
                                </Row>
                                <Row>
                                  <Col
                                    className="d-flex justify-content-end"
                                    style={{
                                      marginRight: "5px",
                                      marginTop: "20px",
                                    }}
                                  >
                                    <Button
                                      variant="success"
                                      type="submit"
                                      onClick={(e) =>
                                        this.handleSubmit(e, "10")
                                      }
                                    >
                                      Submit
                                    </Button>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Offcanvas.Body>
                        </Offcanvas>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <DataTable
                        columns={this.state.tableClms}
                        data={paginatedData}
                        noHeader
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={this.state.data.length}
                        paginationPerPage={this.state.itemsPerPage}
                        paginationComponentOptions={{ noRowsPerPage: false }}
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

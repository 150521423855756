import React, { Component } from "react";
import { Button, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";

export default class PremiumRegisterVerification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      show1: false,
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      data: [],
      pospentry: [],
      mispcreation: [],
      premiumregister: [],
      nonlifeinsurancecompanies: [],
      insurerNames: [],
      lifeinsurancecompanies: [],
      irdainsurancetypesnonlife: [],
      irdacategorys: [],
      tables: [],
      plantypes: [],
      selectedProduct: "",
      month: "",
      insurertype: "",
      insurername: "",
      customername: "",
      contactperson: "",
      contactemailid: "",
      contactmobileno: "",
      contactaddress: "",
      premiumtype: "",
      policynumber: "",
      endorsementnumber: "",
      irdacategory: "",
      vehicleregistrationno: "",
      polictstatus: "",
      riskstartdate: "",
      riskenddate: "",
      endorsementdate: "",
      nextduedate: "",
      suminsured: "",
      basicodpremium: "",
      tppremium: "",
      netpremium: "",

      totalgst: "",
      policytype: "",
      gstType: "",
      sgst: "",
      cgst: "",
      igst: "",
      grosspremium: "",
      salesperson: "",
      posid: "",
      retailgroup: "",
      policyupload: "",
      misstatus: "",
      thirdParty: "",
      misremarks: "",
      CName: "",
      MNumber: "",
      CEmailId: "",
      paymentfrequency: "",
      premiumapprovalentry_Id: "",
      startDate: new Date(),
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.premiumapprovalentry_Id,
          sortable: true,
          cell: (row) => <span>{row.premiumapprovalentry_Id}</span>,
        },
        {
          name: "Month",
          id: "month",
          selector: (row, i) => row.month,
          sortable: true,
          cell: (row) => <span>{row.month}</span>,
        },
        {
          name: "Insurer Type",
          id: "insurertype",
          selector: (row, i) => row.insurertype,
          sortable: true,
          cell: (row) => <span>{row.insurertype}</span>,
        },
        {
          name: "Insurer Name",
          id: "insurername",
          selector: (row, i) => row.insurername,
          sortable: true,
          cell: (row) => <span>{row.insurername}</span>,
        },
        {
          name: "Customer Name",
          id: "customername",
          selector: (row, i) => row.customername,
          sortable: true,
          cell: (row) => <span>{row.customername}</span>,
        },
        {
          name: "Premium Type",
          id: "premiumtype",
          selector: (row, i) => row.premiumtype,
          sortable: true,
          cell: (row) => <span>{row.premiumtype}</span>,
        },
        {
          name: "Policy Number",
          id: "policynumber",
          selector: (row, i) => row.policynumber,
          sortable: true,
          cell: (row) => <span>{row.policynumber}</span>,
        },
        {
          name: "Endorsement/Installment No ",
          id: "endorsementnumber",
          selector: (row, i) => row.endorsementnumber,
          sortable: true,
          cell: (row) => <span>{row.endorsementnumber}</span>,
        },
        {
          name: "vechile No ",
          id: "vehicleregistrationno",
          selector: (row, i) => row.vehicleregistrationno,
          sortable: true,
          cell: (row) => <span>{row.vehicleregistrationno}</span>,
        },

        {
          name: "Product ",
          id: "product",
          selector: (row, i) => row.product,
          sortable: true,
          cell: (row) => <span>{row.product}</span>,
        },
        {
          name: "IRDA Product Category",
          id: "irdacategory",
          selector: (row, i) => row.irdacategory,
          sortable: true,
          cell: (row) => <span>{row.irdacategory}</span>,
        },
        {
          name: "Policy Status",
          id: "polictstatus",
          selector: (row, i) => row.polictstatus,
          sortable: true,
          cell: (row) => <span>{row.polictstatus}</span>,
        },
        {
          name: "Sale Person",
          id: "salesperson",
          selector: (row, i) => row.salesperson,
          sortable: true,
          cell: (row) => <span>{row.salesperson}</span>,
        },
        {
          name: "Upload",
          id: "policyupload",
          selector: (row, i) => row.policyupload,
          sortable: true,
          cell: (row) => <span>{row.policyupload}</span>,
        },
        {
          name: "GSTtype",
          id: "gstType",
          selector: (row, i) => row.gstType,
          sortable: true,
          cell: (row) => (
            <span style={{ color: "red" }}>
              <b>{row.gstType}</b>
            </span>
          ),
        },
        {
          name: "Remarks",
          id: "misremarks",
          selector: (row, i) => row.misremarks,
          sortable: true,
          cell: (row) => (
            <span style={{ color: "red" }}>
              <b>{row.misremarks}</b>
            </span>
          ),
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-eye"
                style={{ marginRight: "3px" }}
              ></Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      isEditing: false, // Flag to indicate edit mode
      isNewEntry: false,
    };
  }
  //edit
  handleEnterEditMode = (row) => {
    // this.setState({ cData: { ...row }, isEditing: true });
    const insurertype = row.insurertype;
    let insurerNames = [];

    if (
      insurertype === "Lifeinsurancecompanies" &&
      Array.isArray(this.state.lifeinsurancecompanies)
    ) {
      insurerNames = this.state.lifeinsurancecompanies.map(
        (company) => company.LifeInsuranceCompanies
      );
    } else if (
      insurertype === "Nonlifeinsurancecompanies" &&
      Array.isArray(this.state.nonlifeinsurancecompanies)
    ) {
      insurerNames = this.state.nonlifeinsurancecompanies.map(
        (company) => company.NonLifeInsuranceCompanies
      );
    }
    var endorsementdate = "";
    if (row.endorsementdate !== "0000-00-00")
      endorsementdate = row.endorsementdate;
    var riskstartdate = "";
    if (row.riskstartdate !== "0000-00-00") riskstartdate = row.riskstartdate;
    var riskenddate = "";
    if (row.riskenddate !== "0000-00-00") riskenddate = row.riskenddate;
    var nextduedate = "";
    if (row.nextduedate !== "0000-00-00") nextduedate = row.nextduedate;
    this.setState({
      month: row.month,
      product: row.product,
      insurertype: row.insurertype,
      insurername: row.insurername,
      insurerNames,
      customername: row.customername,
      premiumtype: row.premiumtype,
      policynumber: row.policynumber,
      policytype: row.policytype,
      endorsementnumber: row.endorsementnumber,
      irdacategory: row.irdacategory,
      polictstatus: row.polictstatus,
      riskstartdate: riskstartdate,
      nextduedate: nextduedate,
      endorsementdate: endorsementdate,
      riskenddate: riskenddate,
      suminsured: row.suminsured,
      basicodpremium: row.basicodpremium,
      tppremium: row.tppremium,
      netpremium: row.netpremium,
      totalgst: row.totalgst,
      sgst: row.sgst,
      cgst: row.cgst,
      igst: row.igst,
      grosspremium: row.grosspremium,
      salesperson: row.salesperson,
      posid: row.posid,
      retailgroup: row.retailgroup,
      policyupload: row.policyupload,
      misstatus: row.misstatus,
      misremarks: row.misremarks,
      premiumapprovalentry_Id: row.premiumapprovalentry_Id,
      CName: row.CName,
      MNumber: row.MNumber,
      CEmailId: row.CEmailId,
      gstType: row.gstType,
      thirdParty: row.thirdParty,
      paymentfrequency: row.paymentfrequency,
      vehicleregistrationno: row.vehicleregistrationno,
      isEditing: true,
    });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({
      show: true,
      selectedProduct: "",
      month: "",
      insurertype: "",
      insurername: "",
      customername: "",
      contactperson: "",
      contactemailid: "",
      contactmobileno: "",
      contactaddress: "",
      premiumtype: "",
      policynumber: "",
      endorsementnumber: "",
      irdacategory: "",
      vehicleregistrationno: "",
      polictstatus: "",
      riskstartdate: "",
      riskenddate: "",
      endorsementdate: "",
      nextduedate: "",
      suminsured: "",
      basicodpremium: "",
      tppremium: "",
      netpremium: "",

      totalgst: "",
      policytype: "",
      gstType: "",
      sgst: "",
      cgst: "",
      igst: "",
      grosspremium: "",
      salesperson: "",
      posid: "",
      retailgroup: "",
      policyupload: "",
      misstatus: "",
      thirdParty: "",
      misremarks: "",
      CName: "",
      MNumber: "",
      CEmailId: "",
      paymentfrequency: "",
      premiumapprovalentry_Id: "",
    });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };

  handleOffcanvasCloses = () => {
    this.setState({ isEditing: false });
  };
  // Function to open the PDF URL in a new browser tab
  openPDFInNewTab = (policyupload) => {
    window.open(configData.api_url + policyupload, "_blank");
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  // handleFilter = (e) => {
  //   const searchValue = e.target.value.toLowerCase();
  //   const filteredData = this.state.premiumregister.filter((row) => {
  //     return Object.values(row).join(" ").toLowerCase().includes(searchValue);
  //   });
  //   this.setState({ filteredData });
  // };
  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.premiumregister.filter((row) => {
      // Check if the search value is a month (format: YYYY-MM)
      const isMonthSearch = /^\d{4}-\d{2}$/.test(searchValue);
      // Check if the search value is a full date (format: YYYY-MM-DD)
      const isDateSearch = /^\d{4}-\d{2}-\d{2}$/.test(searchValue);
      // If searching for a month
      if (isMonthSearch) {
        return row.month.toLowerCase() === searchValue;
      }
      // If searching for a date
      if (isDateSearch) {
        return (
          row.riskstartdate.toLowerCase() === searchValue ||
          row.riskenddate.toLowerCase() === searchValue ||
          row.endorsementdate.toLowerCase() === searchValue
        );
      }
      // For general text search, match against relevant fields
      const rowString = Object.values(row).join(" ").toLowerCase();
      return rowString.includes(searchValue);
    });
    this.setState({ filteredData });
  };

  handlepmonthChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ month: fieldValue });
  };
  handleinsurerTypeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ insurertype: fieldValue });
  };
  handleinsurerNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ insurername: fieldValue });
  };
  handlecNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ customername: fieldValue });
  };
  handlecContactPersonChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ contactperson: fieldValue });
  };
  handlecontctemailChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ contactemailid: fieldValue });
  };
  handlecontactmobileChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ contactmobileno: fieldValue });
  };
  handlecontactaddressChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ contactaddress: fieldValue });
  };
  handlepremiumChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ premiumtype: fieldValue });
  };
  handlepolicynoChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ policynumber: fieldValue });
  };
  handleRSChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      this.setState({
        riskstartdate: formattedDate,
        startDate: new Date(),
      });
    }
  };
  handleEIChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

      // Assuming you want to update the 'dob' state with the selected date
      this.setState({
        endorsementdate: formattedDate,
        startDate: new Date(),
      });
    }
  };
  handleNDChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

      // Assuming you want to update the 'dob' state with the selected date
      this.setState({
        nextduedate: formattedDate,
        startDate: new Date(),
      });
    }
  };

  handleRDChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      this.setState({
        riskenddate: formattedDate,
        startDate: new Date(),
      });
    }
  };
  calculateGrossPremium = () => {
    const { gstType, sgst, cgst, igst, netpremium } = this.state;
    let totalgst = 0;

    if (gstType === "IGST") {
      totalgst = igst;
    } else if (gstType === "SCGTCGST") {
      totalgst = sgst + cgst;
    }

    const grosspremium = totalgst + netpremium;

    this.setState({ totalgst, grosspremium });
  };
  handleeinstallmentnoChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ endorsementnumber: fieldValue });
  };
  handleProductChange = (e) => {
    const selectedProduct = e.target.value;
    this.setState({
      irdacategory: selectedProduct,
      selectedProduct: selectedProduct,
      vehicleregistrationno: selectedProduct === "Motor" ? "" : null, // Reset or clear based on product
    });
  };

  handlePolicyTypeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ policytype: fieldValue });
  };
  handleproductsChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ product: fieldValue });
  };

  handlevehicleChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ vehicleregistrationno: fieldValue });
  };
  handleirdaChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ irdacategorys: fieldValue });
  };
  handlepstatusChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ polictstatus: fieldValue });
  };
  handlepaymentstatusChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ paymentfrequency: fieldValue });
  };

  handlesumChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ suminsured: fieldValue });
  };
  handleBasicChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ basicodpremium: fieldValue });
  };
  handleThirdChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ thirdParty: fieldValue });
  };
  handleTerrorismChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ tppremium: fieldValue });
  };
  handlenetpremiumChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ netpremium: fieldValue });
  };
  handletotalgstChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ totalgst: fieldValue });
  };
  handlePremiumChange = (fieldName) => (e) => {
    this.setState(
      {
        [fieldName]: parseFloat(e.target.value) || 0,
        isGrossPremiumManual: false,
      },
      this.calculatePremiums
    );
  };
  handleGstTypeChange = (e) => {
    const gstType = e.target.value;

    // Reset GST fields based on the selected GST type
    if (gstType === "IGST") {
      this.setState(
        { gstType, sgst: 0, cgst: 0, isGrossPremiumManual: false },
        this.calculatePremiums
      );
    } else if (gstType === "SCGTCGST") {
      this.setState(
        { gstType, igst: 0, isGrossPremiumManual: false },
        this.calculatePremiums
      );
    } else {
      this.setState(
        { gstType, sgst: 0, cgst: 0, igst: 0, isGrossPremiumManual: false },
        this.calculatePremiums
      );
    }
  };

  handlesgstChange = (event) => {
    this.setState(
      { sgst: parseFloat(event.target.value) || 0 },
      this.calculateGrossPremium
    );
  };

  handlecgstChange = (event) => {
    this.setState(
      { cgst: parseFloat(event.target.value) || 0 },
      this.calculateGrossPremium
    );
  };
  handlegrosspremiumChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ grosspremium: fieldValue });
  };
  handlesalesChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ salesperson: fieldValue });
  };
  handleposidChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ posid: fieldValue });
  };
  handleretailgroupChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ retailgroup: fieldValue });
  };
  handlepolicyuploadChange = (e) => {
    e.preventDefault();
    // const fieldValue = e.target.value;
    // this.setState({ policyupload: fieldValue });
    const files = e.target.files[0];
    this.setState({ policyupload: files });
  };
  handleCNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ CName: fieldValue });
  };
  handleMNumberChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ MNumber: fieldValue });
  };
  handleCEmailIdChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ CEmailId: fieldValue });
  };

  handlemiststusChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ misstatus: fieldValue });
  };
  handlemisremarksChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ misremarks: fieldValue });
  };

  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value }, this.calculatePremiums);
  };
  calculatePremiums = () => {
    const { basicodpremium, thirdParty, tppremium, gstType, sgst, cgst, igst } =
      this.state;

    const netpremium =
      Number(basicodpremium) + Number(thirdParty) + Number(tppremium);
    let totalgst = "";

    if (gstType === "SCGTCGST") {
      totalgst = Number(sgst) + Number(cgst);
    } else if (gstType === "IGST") {
      totalgst = Number(igst);
    }

    const grosspremium = netpremium + totalgst;

    this.setState({ netpremium, totalgst, grosspremium });
  };
  componentDidMount() {
    this.PospfetchHandler();
    this.PremiumapprovalentryfetchHandler();
    this.lifeinsurancecompaniesfetchHandler();
    this.LiferidersinsurancecompaniesfetchHandler();
    this.nonlifeinsurancecompaniesfetchHandler();
    this.EmployeesfetchHandler();
    this.IrdainsurancecategoriesfetchHandler();
    this.IrdainsurancetypesnonlifefetchHandler();
    this.apisfetchHandler();
  }
  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "plantypes/", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success" || data.status === "true") {
          this.setState({ plantypes: data.list });
        }
      });
  };
  PospfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);

    fetch(configData.api_url + "posps/all", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ pospentry: data.list });
        }
      });
  };
  PremiumapprovalentryfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Premiumapprovalentry/list", {
      method: "POST",
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((rsdata) => {
        this.showToast(rsdata.message, rsdata.status);

        if (rsdata.status === "success") {
          const list = rsdata.list;
          const data = [];
          list.forEach((element) => {
            if (element.mStatus === "2") {
              data.push(element);
            }
            if (element.mStatus === "3") {
              data.push(element);
            }
            if (element.mStatus === "7") {
              data.push(element);
            }
          });

          this.setState({ premiumregister: data });
        }
      });
  };

  EmployeesfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Employees/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ mispcreation: data.list });
        }
      });
  };
  IrdainsurancetypesnonlifefetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Irdainsurancetypesnonlife/list", {
      // Enter your IP address here
      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ irdainsurancetypesnonlife: data.list });
        }
      });
  };
  IrdainsurancecategoriesfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Irdainsurancecategories/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ irdacategorys: data.list });
        }
      });
  };
  lifeinsurancecompaniesfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Lifeinsurancecompanies/list", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ lifeinsurancecompanies: data.list });
        }
      });
  };

  LiferidersinsurancecompaniesfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Liferidersinsurancecompanies/list", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };

  nonlifeinsurancecompaniesfetchHandler = () => {
    fetch(configData.api_url + "C_Nonlifeinsurancecompanies/list", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ nonlifeinsurancecompanies: data.list });
        }
      });
  };
  validateForm() {
    let data = this.state;
    let errors = {};
    let formIsValid = true;

    if (!data["misstatus"]) {
      formIsValid = false;
      toast.info("*Please Select MIS Ststus.");
    } else if (!data["misremarks"]) {
      formIsValid = false;
      toast.info("*Please Enter MIS Remarks.");
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);

    formData.append("premiumapprovalentry_Id", row.premiumapprovalentry_Id);
    fetch(configData.api_url + "C_Premiumapprovalentry/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.PremiumapprovalentryfetchHandler();
        }
      });
  };
  PremiumapprovalentrysaveHandler = (e, status) => {
    e.preventDefault();
    //   setButtons(contacts);
    if (this.validateForm()) {
      var formData = new FormData();
      formData.append("mStatus", status);
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("month", this.state.month);
      formData.append("insurertype", this.state.insurertype);
      formData.append("insurername", this.state.insurername);
      formData.append("customername", this.state.customername);
      formData.append("premiumtype", this.state.premiumtype);
      formData.append("policynumber", this.state.policynumber);
      formData.append("policytype", this.state.policytype);
      formData.append("endorsementnumber", this.state.endorsementnumber);
      formData.append("irdacategory", this.state.irdacategory);
      formData.append("polictstatus", this.state.polictstatus);
      formData.append("riskstartdate", this.state.riskstartdate);
      formData.append("riskenddate", this.state.riskenddate);
      formData.append("endorsementdate", this.state.endorsementdate);
      formData.append("nextduedate", this.state.nextduedate);
      formData.append("suminsured", this.state.suminsured);
      formData.append("basicodpremium", this.state.basicodpremium);
      formData.append("tppremium", this.state.tppremium);
      formData.append("netpremium", this.state.netpremium);
      formData.append("totalgst", this.state.totalgst);
      formData.append("gstType", this.state.gstType);
      formData.append("sgst", this.state.sgst);
      formData.append("cgst", this.state.cgst);
      formData.append("igst", this.state.igst);
      formData.append("grosspremium", this.state.grosspremium);
      formData.append("salesperson", this.state.salesperson);
      formData.append("posid", this.state.posid);
      formData.append("retailgroup", this.state.retailgroup);
      formData.append("policyupload", this.state.policyupload);
      formData.append("thirdParty", this.state.thirdParty);
      formData.append("product", this.state.product);
      formData.append("CName", this.state.CName);
      formData.append("MNumber", this.state.MNumber);
      formData.append("misremarks", this.state.misremarks);
      formData.append("CEmailId", this.state.CEmailId);
      formData.append("paymentfrequency", this.state.paymentfrequency);
      formData.append(
        "premiumapprovalentry_Id",
        this.state.premiumapprovalentry_Id
      );

      fetch(configData.api_url + "C_Premiumapprovalentry/update", {
        method: "POST",
        //headers :{ 'Content-Type' : 'application/json'},
        mode: "cors",
        body: formData, // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            this.PremiumapprovalentryfetchHandler();
            this.handleOffcanvasCloses();
            this.handleOffcanvasClose();
            this.setState({
              month: "",
              insurertype: "",
              insurername: "",
              customername: "",
              premiumtype: "",
              policynumber: "",
              endorsementnumber: "",
              policytype: "",
              irdacategory: "",
              polictstatus: "",
              riskstartdate: "",
              riskenddate: "",
              endorsementdate: "",
              nextduedate: "",
              suminsured: "",
              basicodpremium: "",
              tppremium: "",
              netpremium: "",
              totalgst: "",
              sgst: "",
              cgst: "",
              igst: "",
              grosspremium: "",
              salesperson: "",
              posid: "",
              retailgroup: "",
              policyupload: "",
              CName: "",
              MNumber: "",
              CEmailId: "",
              product: "",
              paymentfrequency: "",
            });
          }
        });
    }
  };
  handleRejectClick = (e, status) => {
    e.preventDefault();
    if (this.validateForm()) {
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("misstatus", this.state.misstatus);
      formData.append("misremarks", this.state.misremarks);
      formData.append(
        "premiumapprovalentry_Id",
        this.state.premiumapprovalentry_Id
      );
      formData.append("mStatus", status);
      console.log(formData);
      fetch(configData.api_url + "C_Premiumapprovalentry/update", {
        // Enter your IP address here
        method: "POST",
        //headers :{ 'Content-Type' : 'application/json'},
        mode: "cors",
        body: formData, // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.success);
          if (data.status === "success") {
            this.handleOffcanvasCloses();
            this.handleOffcanvasClose();
            this.setState({
              month: "",
              insurertype: "",
              insurername: "",
              customername: "",
              premiumtype: "",
              policynumber: "",
              endorsementnumber: "",
              irdacategory: "",
              polictstatus: "",
              riskstartdate: "",
              riskenddate: "",
              endorsementdate: "",
              suminsured: "",
              basicodpremium: "",
              tppremium: "",
              netpremium: "",
              totalgst: "",
              gstType: "",
              sgst: "",
              cgst: "",
              igst: "",
              grosspremium: "",
              salesperson: "",
              posid: "",
              retailgroup: "",
              policyupload: "",
              misstatus: "",
              misremarks: "",
            });
          }
        });
      // window.location.href = "/mispcreationprocess";
    }
  };
  PremiumapprovalentryupdateHandler = (e, status) => {
    e.preventDefault();
    if (this.validateForm()) {
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("month", this.state.month);
      formData.append("mStatus", status);
      formData.append("insurertype", this.state.insurertype);
      formData.append("insurername", this.state.insurername);
      formData.append("customername", this.state.customername);
      formData.append("contactperson", this.state.contactperson);
      formData.append("contactemailid", this.state.contactemailid);
      formData.append("contactmobileno", this.state.contactmobileno);
      formData.append("contactaddress", this.state.contactaddress);
      formData.append("premiumtype", this.state.premiumtype);
      formData.append("policynumber", this.state.policynumber);
      formData.append("endorsementnumber", this.state.endorsementnumber);
      formData.append("irdacategory", this.state.irdacategory);
      formData.append("polictstatus", this.state.polictstatus);
      formData.append("riskstartdate", this.state.riskstartdate);
      formData.append("riskenddate", this.state.riskenddate);
      formData.append("endorsementdate", this.state.endorsementdate);
      formData.append("suminsured", this.state.suminsured);
      formData.append("basicodpremium", this.state.basicodpremium);
      formData.append("tppremium", this.state.tppremium);
      formData.append("netpremium", this.state.netpremium);
      formData.append("totalgst", this.state.totalgst);
      formData.append("sgst", this.state.sgst);
      formData.append("cgst", this.state.cgst);
      formData.append("igst", this.state.igst);
      formData.append("gstType", this.state.gstType);
      formData.append("CName", this.state.CName);
      formData.append("MNumber", this.state.MNumber);
      formData.append("CEmailId", this.state.CEmailId);
      formData.append("grosspremium", this.state.grosspremium);
      formData.append("salesperson", this.state.salesperson);
      formData.append("posid", this.state.posid);
      formData.append("retailgroup", this.state.retailgroup);
      formData.append("policyupload", this.state.policyupload);
      formData.append("misstatus", this.state.misstatus);
      formData.append("misremarks", this.state.misremarks);
      formData.append("paymentfrequency", this.state.paymentfrequency);
      formData.append(
        "premiumapprovalentry_Id",
        this.state.premiumapprovalentry_Id
      );

      fetch(configData.api_url + "C_Premiumapprovalentry/update", {
        method: "POST",
        mode: "cors",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            this.PremiumapprovalentryfetchHandler();
            this.handleOffcanvasCloses();
            this.handleOffcanvasClose();
            this.setState({
              month: "",
              insurertype: "",
              insurername: "",
              customername: "",
              premiumtype: "",
              policynumber: "",
              endorsementnumber: "",
              irdacategory: "",
              polictstatus: "",
              riskstartdate: "",
              riskenddate: "",
              nextduedate: "",
              endorsementdate: "",
              suminsured: "",
              basicodpremium: "",
              tppremium: "",
              netpremium: "",
              totalgst: "",
              sgst: "",
              cgst: "",
              igst: "",
              grosspremium: "",
              salesperson: "",
              posid: "",
              retailgroup: "",
              policyupload: "",
              misstatus: "",
              misremarks: "",
              CName: "",
              MNumber: "",
              CEmailId: "",
              gstType: "",
              paymentfrequency: "",
            });
          }
        });
    }
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.premiumregister.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );
    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="title_left"></div>
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="x_panel">
                <div className="x_content">
                  <div
                    id="datatable_wrapper"
                    className="dataTables_wrapper form-inline
                      dt-bootstrap no-footer"
                  >
                    <Row>
                      <h3 className="logoskybluecolor">
                        Premium Register Verification
                      </h3>
                      <div>
                        {" "}
                        {this.state.isEditing && (
                          // Edit Offcanvas
                          <Offcanvas
                            show={this.state.isEditing}
                            onHide={() => this.setState({ isEditing: false })}
                            {...this.props}
                            style={{ width: "600px", height: "auto" }}
                            placement="end"
                            backdrop="true"
                          >
                            <Offcanvas.Header closeButton>
                              <Offcanvas.Title>
                                Edit Premium Register Verification
                              </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                              <div className="form-horizontal form-label-left input_mask">
                                <Form className="form-horizontal form-label-left input_mask">
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Month
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="month"
                                        onChange={this.handlepmonthChange}
                                        className="form-control"
                                        name="month"
                                        value={this.state.month}
                                        required="required"
                                        placeholder="Enter Month"
                                      />
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Insurer Type
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Select
                                        name="insurertype"
                                        value={this.state.insurertype}
                                        onChange={this.handleinsurerTypeChange}
                                        aria-label="Default select example"
                                        className="form-control"
                                        id="insurertype"
                                      >
                                        <option selected>
                                          Select Insurer Type
                                        </option>
                                        <option value="Lifeinsurancecompanies">
                                          Life insurance companies
                                        </option>
                                        <option value="Nonlifeinsurancecompanies">
                                          Non life insurance companies
                                        </option>
                                      </Form.Select>
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Insurer Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Select
                                        name="insurername"
                                        value={this.state.insurername}
                                        onChange={this.handleinsurerNameChange}
                                        aria-label="Default select example"
                                        className="form-control"
                                        id="insurername"
                                      >
                                        <option value="">
                                          Select Insurer Name
                                        </option>
                                        {this.state.insurerNames.map(
                                          (insurer, index) => (
                                            <option key={index} value={insurer}>
                                              {insurer}
                                            </option>
                                          )
                                        )}
                                      </Form.Select>
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Customer Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlecNameChange}
                                        className="form-control"
                                        name="customername"
                                        value={this.state.customername}
                                        required="required"
                                        placeholder="Enter Customer Name"
                                      />
                                    </Col>
                                  </Row>
                                  <br />

                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Premium Type
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Select
                                        name="premiumtype"
                                        value={this.state.premiumtype}
                                        onChange={this.handlepremiumChange}
                                        aria-label="Default select example"
                                        className="form-control"
                                        id="premiumtype"
                                      >
                                        <option selected>
                                          Select Premium Type
                                        </option>
                                        <option value="Endorsement">
                                          Endorsement
                                        </option>
                                        <option value="Installment">
                                          Installment
                                        </option>
                                        <option value="NewPremium">
                                          New Premium
                                        </option>
                                        <option value="NewPremium">
                                          Renewal
                                        </option>
                                      </Form.Select>
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Policy No
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlepolicynoChange}
                                        className="form-control"
                                        name="policynumber"
                                        value={this.state.policynumber}
                                        required="required"
                                        placeholder="Enter Policy No"
                                      />
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Policy Type
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Select
                                        name="policytype"
                                        value={this.state.policytype}
                                        onChange={this.handlePolicyTypeChange}
                                        aria-label="Default select example"
                                        className="form-control"
                                        id="policytype"
                                      >
                                        <option defaultValue>
                                          Select Policy Type
                                        </option>
                                        {this.state.plantypes.map(
                                          (api, index, idx) => (
                                            <option
                                              key={index}
                                              value={api.ptName}
                                            >
                                              {api.ptName}
                                            </option>
                                          )
                                        )}
                                      </Form.Select>
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Product
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleproductsChange}
                                        className="form-control"
                                        name="product"
                                        value={this.state.product}
                                        required="required"
                                        placeholder="Enter Product"
                                      />
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Endorsement/Installment No.
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleeinstallmentnoChange
                                        }
                                        className="form-control"
                                        name="endorsementnumber"
                                        value={this.state.endorsementnumber}
                                        required="required"
                                        placeholder="Enter Endorsement/Installment No"
                                      />
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        IRDA Product Category
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Select
                                        onChange={this.handleProductChange}
                                        aria-label="Default select example"
                                        className="form-control"
                                        id="irdacategory"
                                        name="irdacategory"
                                        value={this.state.irdacategory}
                                      >
                                        <option defaultValue>
                                          {" "}
                                          Select Product
                                        </option>
                                        {this.state.irdainsurancetypesnonlife.map(
                                          (api, index, idx) => (
                                            <option
                                              key={index}
                                              value={api.IRDAInsuranceType}
                                            >
                                              {api.IRDAInsuranceType}
                                            </option>
                                          )
                                        )}
                                      </Form.Select>
                                    </Col>
                                  </Row>
                                  <br />
                                  {this.state.vehicleregistrationno && (
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Vehicle&nbsp;Registration&nbsp;No.
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handlevehicleChange}
                                          className="form-control"
                                          name="vehicleregistrationno"
                                          value={
                                            this.state.vehicleregistrationno
                                          }
                                          id="vehicleregistrationno"
                                          placeholder="Enter Vehicle Registration No."
                                        />
                                      </Col>
                                    </Row>
                                  )}

                                  <br />

                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Policy Status
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Select
                                        name="polictstatus"
                                        value={this.state.polictstatus}
                                        onChange={this.handlepstatusChange}
                                        aria-label="Default select example"
                                        className="form-control"
                                        id="polictstatus"
                                      >
                                        <option selected>
                                          Select Policy Status
                                        </option>
                                        <option value="Active">Active</option>
                                        <option value="Pending">Pending</option>
                                        <option value="Cancelled">
                                          Cancelled
                                        </option>
                                      </Form.Select>
                                    </Col>
                                  </Row>

                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Payment Frequency
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Select
                                        name="paymentfrequency"
                                        value={this.state.paymentfrequency}
                                        onChange={
                                          this.handlepaymentstatusChange
                                        }
                                        aria-label="Default select example"
                                        className="form-control"
                                        id="paymentfrequency"
                                      >
                                        <option selected>
                                          Select Payment Frequeny
                                        </option>
                                        <option value="Monthly">Monthly</option>
                                        <option value="Quarterly">
                                          Quarterly
                                        </option>
                                        <option value="Halfyearly">
                                          Halfyearly
                                        </option>
                                        <option value="Annually">
                                          Annually
                                        </option>
                                        <option value="Custom">Custom</option>
                                      </Form.Select>
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Risk Start Date
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <DatePicker
                                        dropdownMode="select"
                                        selected={this.state.riskstartdate}
                                        onChange={(date) =>
                                          this.handleRSChange(date)
                                        }
                                        className="form-control"
                                        name="riskstartdate"
                                        showMonthDropdown
                                        showYearDropdown
                                        id="riskstartdate"
                                        dateFormat="dd/MM/yyyy"
                                        required
                                        placeholderText="DD/MM/YYYY"
                                      />
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Risk End Date
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <DatePicker
                                        dropdownMode="select"
                                        selected={this.state.riskenddate}
                                        onChange={(date) =>
                                          this.handleRDChange(date)
                                        }
                                        className="form-control"
                                        name="riskenddate"
                                        id="riskenddate"
                                        showMonthDropdown
                                        showYearDropdown
                                        dateFormat="dd/MM/yyyy"
                                        required
                                        placeholderText="DD/MM/YYYY"
                                      />
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Endorsement &nbsp;/&nbsp; Installment
                                        Date
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <DatePicker
                                        dropdownMode="select"
                                        selected={this.state.endorsementdate}
                                        onChange={(date) =>
                                          this.handleEIChange(date)
                                        }
                                        className="form-control"
                                        name="endorsementdate"
                                        showMonthDropdown
                                        showYearDropdown
                                        id="endorsementdate"
                                        dateFormat="dd/MM/yyyy"
                                        required
                                        placeholderText="DD/MM/YYYY"
                                      />
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Next Due Date
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <DatePicker
                                        dropdownMode="select"
                                        selected={this.state.nextduedate}
                                        onChange={(date) =>
                                          this.handleNDChange(date)
                                        }
                                        className="form-control"
                                        name="nextduedate"
                                        showMonthDropdown
                                        showYearDropdown
                                        id="nextduedate"
                                        dateFormat="dd/MM/yyyy"
                                        required
                                        placeholderText="DD/MM/YYYY"
                                      />
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Sum Insured
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        onChange={this.handlesumChange}
                                        className="form-control"
                                        name="suminsured"
                                        id="suminsured"
                                        value={this.state.suminsured}
                                        required="required"
                                        placeholder="Enter Sum Insured"
                                      />
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Basic&nbsp;/&nbsp;OD Premium
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        onChange={this.handleInputChange}
                                        className="form-control"
                                        name="basicodpremium"
                                        id="basicodpremium"
                                        value={this.state.basicodpremium}
                                        required="required"
                                        placeholder="Enter Basic&nbsp;/&nbsp;OD Premium"
                                      />
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Third Party Premium
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        onChange={this.handleInputChange}
                                        className="form-control"
                                        name="thirdParty"
                                        value={this.state.thirdParty}
                                        required="required"
                                        id="thirdParty"
                                        placeholder="Enter Third Party Premium"
                                      />
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Terrorism Premium
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        onChange={this.handleInputChange}
                                        className="form-control"
                                        name="tppremium"
                                        value={this.state.tppremium}
                                        required="required"
                                        id="tppremium"
                                        placeholder="Enter Terrorism Premium  "
                                      />
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Net Premium
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        onChange={this.handleInputChange}
                                        className="form-control"
                                        name="netpremium"
                                        id="netpremium"
                                        value={this.state.netpremium}
                                        required="required"
                                        placeholder="Enter Net Premium"
                                      />
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Select GST Type
                                      </Form.Label>
                                    </Col>
                                    <Col lg="4" md="4">
                                      <Form.Group>
                                        <Form.Select
                                          className="form-control"
                                          name="gstType"
                                          id="gstType"
                                          value={this.state.gstType}
                                          onChange={this.handleGstTypeChange}
                                        >
                                          <option>Select GST</option>
                                          <option value="IGST">IGST</option>
                                          <option value="SCGTCGST">
                                            CGST & SGST
                                          </option>
                                        </Form.Select>
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <br />
                                  {this.state.gstType === "SCGTCGST" && (
                                    <>
                                      <Row>
                                        <Col lg="4" md="4">
                                          <Form.Label
                                            style={{ paddingTop: "8px" }}
                                          >
                                            SGST
                                          </Form.Label>
                                        </Col>
                                        <Col lg="8" md="8">
                                          <Form.Control
                                            type="number"
                                            onChange={this.handlePremiumChange(
                                              "sgst"
                                            )}
                                            className="form-control"
                                            name="sgst"
                                            id="sgst"
                                            value={this.state.sgst}
                                            required="required"
                                            placeholder="Enter SGST"
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                      <Row>
                                        <Col lg="4" md="4">
                                          <Form.Label
                                            style={{ paddingTop: "8px" }}
                                          >
                                            CGST
                                          </Form.Label>
                                        </Col>
                                        <Col lg="8" md="8">
                                          <Form.Control
                                            type="number"
                                            onChange={this.handlePremiumChange(
                                              "cgst"
                                            )}
                                            className="form-control"
                                            name="cgst"
                                            id="cgst"
                                            value={this.state.cgst}
                                            required="required"
                                            placeholder="Enter CGST"
                                          />
                                        </Col>
                                      </Row>
                                      <br />
                                    </>
                                  )}
                                  {this.state.gstType === "IGST" && (
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          IGST
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="number"
                                          onChange={this.handlePremiumChange(
                                            "igst"
                                          )}
                                          className="form-control"
                                          name="igst"
                                          id="igst"
                                          value={this.state.igst}
                                          required="required"
                                          placeholder="Enter IGST"
                                        />
                                      </Col>
                                    </Row>
                                  )}
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Total GST
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        className="form-control"
                                        name="totalgst"
                                        id="totalgst"
                                        value={this.state.totalgst}
                                      />
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Gross Premium
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        className="form-control"
                                        name="grosspremium"
                                        id="grosspremium"
                                        value={this.state.grosspremium}
                                        readOnly
                                      />
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Sales Person
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Select
                                        onChange={this.handlesalesChange}
                                        aria-label="Default select example"
                                        className="form-control"
                                        id="salesperson"
                                        name="salesperson"
                                        value={this.state.salesperson}
                                      >
                                        <option defaultValue>
                                          {" "}
                                          Select Sales Person{" "}
                                        </option>
                                        {this.state.mispcreation
                                          .filter(
                                            (employee) =>
                                              employee.dName === "Sales"
                                          )
                                          .map((api, index, idx) => (
                                            <option
                                              key={index}
                                              // value={api.eId}
                                              value={`${api.fName} ${api.eId}`}
                                            >
                                              {api.fName}&nbsp;{api.lName}
                                            </option>
                                          ))}
                                      </Form.Select>
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        POSID
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Select
                                        onChange={this.handleposidChange}
                                        aria-label="Default select example"
                                        className="form-control"
                                        id="posid"
                                        name="posid"
                                        value={this.state.posid}
                                      >
                                        <option defaultValue>
                                          {" "}
                                          Select POSID{" "}
                                        </option>
                                        {this.state.pospentry.map(
                                          (api, index, idx) => (
                                            <option
                                              key={index}
                                              value={api.pfName}
                                            >
                                              {api.pfName}&nbsp;{api.plName}
                                            </option>
                                          )
                                        )}
                                      </Form.Select>
                                    </Col>
                                  </Row>
                                  <br />

                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Retail/Group
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Select
                                        name="retailgroup"
                                        value={this.state.retailgroup}
                                        onChange={this.handleretailgroupChange}
                                        aria-label="Default select example"
                                        className="form-control"
                                        id="retailgroup"
                                      >
                                        <option selected>Select</option>
                                        <option value="Retail">Retail</option>
                                        <option value="Group">Group</option>
                                      </Form.Select>
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Policy Upload
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        accept=".pdf"
                                        type="file"
                                        onChange={this.handlepolicyuploadChange}
                                        className="form-control"
                                        name="policyupload"
                                        id="policyupload"
                                        //value={this.state.policyupload}
                                        required="required"
                                        placeholder="Enter Policy Upload"
                                      />
                                    </Col>
                                    {this.state.policyupload && (
                                      <div>
                                        <Link
                                          onClick={() =>
                                            this.openPDFInNewTab(
                                              this.state.policyupload
                                            )
                                          }
                                        >
                                          View Pdf
                                        </Link>
                                      </div>
                                    )}
                                  </Row>
                                  <br />
                                  <hr />
                                  <p>
                                    <b>Contact Details</b>
                                  </p>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Full&nbsp;Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Group>
                                        <Form.Control
                                          type="text"
                                          onChange={this.handleCNameChange}
                                          className="form-control"
                                          name="CName"
                                          id="CName"
                                          value={this.state.CName}
                                          required="required"
                                          placeholder="Enter Full Name"
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Mobile&nbsp;Number
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Group>
                                        <Form.Control
                                          type="text"
                                          onChange={this.handleMNumberChange}
                                          className="form-control"
                                          name="MNumber"
                                          id="MNumber"
                                          value={this.state.MNumber}
                                          required="required"
                                          placeholder="Enter Mobile Numbers"
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Email&nbsp;Id
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Group>
                                        <Form.Control
                                          type="text"
                                          onChange={this.handleCEmailIdChange}
                                          className="form-control"
                                          name="CEmailId"
                                          id="CEmailId"
                                          value={this.state.CEmailId}
                                          required="required"
                                          placeholder="Enter Mail ID"
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row>
                                  <hr />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        MIS Status
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Select
                                        name="misstatus"
                                        value={this.state.misstatus}
                                        onChange={this.handlemiststusChange}
                                        aria-label="Default select example"
                                        className="form-control"
                                        id="misstatus"
                                      >
                                        <option selected>
                                          Select MIS Status
                                        </option>
                                        <option value="0">Verified Ok</option>
                                        <option value="1">
                                          Need More Information
                                        </option>
                                        <option value="2">Info Mismatch</option>
                                      </Form.Select>
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        MIS Remarks
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        as="textarea"
                                        rows={3}
                                        onChange={this.handlemisremarksChange}
                                        className="form-control"
                                        name="misremarks"
                                        value={this.state.misremarks}
                                        required="required"
                                        placeholder="Enter MIS Remarks"
                                        id="misremarks"
                                      />
                                    </Col>
                                  </Row>
                                  <br />

                                  <Row>
                                    <Col
                                      className="d-flex "
                                      style={{ marginRight: "50px" }}
                                    >
                                      <Button
                                        className="me-2 mt-5 button"
                                        variant="success"
                                        onClick={(e) =>
                                          this.PremiumapprovalentrysaveHandler(
                                            e,
                                            "3"
                                          )
                                        }
                                        style={{
                                          marginTop: "20px",
                                          float: "left",
                                        }}
                                      >
                                        Save
                                      </Button>
                                    </Col>
                                    <Col
                                      className="d-flex "
                                      style={{ marginRight: "50px" }}
                                    >
                                      <Button
                                        className="me-2 mt-5 button"
                                        variant="danger"
                                        onClick={(e) =>
                                          this.handleRejectClick(e, "4")
                                        }
                                        style={{
                                          marginTop: "20px",
                                          float: "left",
                                        }}
                                      >
                                        Reject
                                      </Button>
                                    </Col>
                                    <Col
                                      className="d-flex justify-content-end"
                                      style={{ marginRight: "50px" }}
                                    >
                                      <Button
                                        className="btn btn-primary me-2 mt-5 button"
                                        variant="success"
                                        style={{
                                          backgroundColor: "#23547e",
                                          color: "#fff",
                                          borderColor: "#2e6da4",
                                          marginTop: "20px",
                                        }}
                                        onClick={(e) =>
                                          this.PremiumapprovalentryupdateHandler(
                                            e,
                                            "5"
                                          )
                                        }
                                      >
                                        Approve
                                      </Button>
                                    </Col>
                                  </Row>
                                </Form>
                              </div>
                            </Offcanvas.Body>
                          </Offcanvas>
                        )}
                      </div>
                    </Row>
                    <Row>
                      <div style={{ marginRight: "5px" }}>
                        {/* searchbar,pagination,filter */}
                        <input
                          type="text"
                          style={{ float: "left", marginBottom: "10px" }}
                          onChange={this.handleFilter}
                          placeholder="Search..."
                        />
                        <DataTable
                          data={paginatedData}
                          columns={this.state.tableClms}
                          pagination
                          paginationServer
                          paginationTotalRows={
                            this.state.filteredData
                              ? this.state.filteredData.length
                              : this.state.premiumregister.length
                          }
                          paginationRowsPerPageOptions={[
                            10, 20, 30, 50, 60, 70, 80, 90, 100,
                          ]}
                          onChangeRowsPerPage={this.handlePerRowsChange}
                          onChangePage={this.handlePageChange}
                          paginationPerPage={this.state.itemsPerPage}
                          keyField="id"
                          className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                        />
                      </div>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import { Component } from "react";
import {
  Card,
  Button,
  Form,
  Row,
  Col,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import configData from "../config.json";
import { toast } from "react-toastify";
import { Bar, Line, Pie } from "react-chartjs-2";
import "./Charts.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default class ChartWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      widgetslibrary_Id: sessionStorage.getItem("widgetslibrary_Id"),
      chartType: "",
      xAxisColumn: "",
      yAxisColumn: "",
      tableName: "",
      tables: [],
      columns: [],
      rcolumns: [],
      columnsWT: [],
      conditions: [],
      previewdata: [],
      selectedColumns: {
        xAxis: { name: "", alias: "", operation: "", columnType: "" },
        yAxis: { name: "", alias: "", operation: "", columnType: "" },
      },
      savedConfigurations: [],
      currentChartData: null,
      widgetId: "",
      widgetName: "",
      widgetDescription: "",
      columnName: "",
      dataTypeOne: "",
      value1: "",
      columnTwo: "",
      dataTypeTwo: "",
      formulaTwo: "",
      columnThree: "",
      dataTypeThree: "",
      formulaThree: "",
      columnFour: "",
      dataTypeFour: "",
      formulaFour: "",
      groupbyColumn: "",
      extractMonth: null,
      extractYear: null,
      chartmasterlist: [],
      rConditions: {
        tableName: "",
        columnName: "",
        columnName2: "",
        columnName3: "",
        columnName4: "",
      },
      rowscondition: [
        {
          columnName2: "",
          columnvalueOperator: "",
          logicalOperator: "",
          value1: "",
        },
      ],
      selectedColumnDataType: "",
      chartData: null,
    };
  }

  componentDidMount() {
    this.getTables();
    this.chartmasterlist();
  }
  chartmasterlist = (e) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Charttypemaster/list", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({
            chartmasterlist: data.list,
            // chartType: this.state.selectedChartType,
            // columns: [],
          });
        }
      });
  };
  handleChartTypeChange = (e) => {
    const chartType = e.target.value;
    this.setState({ chartType });

    const selectedChart = this.state.chartmasterlist.find(
      (chart) => chart.chartName === chartType
    );

    if (selectedChart) {
      this.setState({
        maxHeight: selectedChart.maxHeight,
        maxWidth: selectedChart.maxWidth,
        minHeight: selectedChart.minHeight,
        minWidth: selectedChart.minWidth,
      });
    }
  };
  showToast = (msg, type) => {
    const tType = toast.TYPE[type.toUpperCase()] || toast.TYPE.INFO;
    toast(msg, { type: tType });
  };

  getTables = () => {
    const formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);

    fetch(configData.api_url + "/masters/gettables", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({ tables: data.tables });
        }
      });
  };

  getColumns = (tableName) => {
    const formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("tableName", tableName);

    fetch(configData.api_url + "/masters/getcolumns", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({ columns: data.columns });
        }
      });
  };

  handleNextWidStep = () =>
    this.setState((prevState) => ({ step: prevState.step + 1 }));

  handleNextStep = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("widgetName", this.state.widgetName);
    formData.append("widgetDescription", this.state.widgetDescription);

    fetch(configData.api_url + "C_Widgetslibrary/add", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({
            // widgetName: "",
            // widgetDescription: "",
            step: this.state.step + 1,
            widgetslibrary_Id: data.widgetslibrary_Id,
          });
          sessionStorage.setItem("widgetslibrary_Id", data.widgetslibrary_Id);
          //sessionStorage.setItem("widgetslibrary_Id", data.widgetslibrary_Id);
        }
      });
  };

  WidgetslibrarysubmitHandler = (e) => {
    e.preventDefault();
    const widgetslibrary_Id =
      this.state.widgetslibrary_Id ||
      sessionStorage.getItem("widgetslibrary_Id");
    console.log("Using widgetslibrary_Id:", widgetslibrary_Id);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("widgetId", widgetslibrary_Id);
    formData.append("widgetName", this.state.widgetName);
    formData.append("chartType", this.state.chartType);
    formData.append("maxWidth", this.state.maxWidth);
    formData.append("minWidth", this.state.minWidth);
    formData.append("maxHeight", this.state.maxHeight);
    formData.append("minHeight", this.state.minHeight);
    formData.append("tableName", this.state.tableName);
    formData.append("columnName", this.state.columnName);
    // formData.append("value", "");
    formData.append("aggregation", this.state.aggregation);
    // const startIndex = 1;
    // const endIndex = 4;
    // for (let i = startIndex; i <= endIndex; i++) {
    //   const rowIndex = i - 1; // Map to rowscondition indices starting from 0
    //   const row = this.state.rowscondition[rowIndex] || {}; // Default to an empty object if no row exists

    //   const operator = row.columnvalueOperator || "";
    //   const value =
    //     operator === "Between" || operator === "Not Between"
    //       ? `${row.value1 || ""},${row.value2 || ""}` // Concatenate values with a comma
    //       : row.value1 || ""; // Use single value otherwise
    //   // Append row data or default to empty strings
    //   formData.append(`columnName${i}`, row.columnName || "");
    //   formData.append(`columnvalueOperator${i}`, operator);
    //   formData.append(`logicalOperator${i}`, row.logicalOperator || "");
    //   formData.append(`value${i}`, value);
    // }
    const startIndex = 1;
    const endIndex = 4;
    const formatDate = (date) => {
      if (!date) return "";
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };
    for (let i = startIndex; i <= endIndex; i++) {
      const rowIndex = i - 1;
      const row = this.state.rowscondition[rowIndex] || {};
      const operatorKey =
        i === 1 ? "columnvalueOperator" : `columnvalueOperator${i}`;
      const logicalKey = i === 1 ? "logicalOperator" : `logicalOperator${i}`;
      const valueKey = i === 1 ? "value1" : `value${i}`;

      // const operator = row[operatorKey] || "";
      const formattedValue =
        row.dataType === "date" ? formatDate(row.value1) : row.value1 || "";
      formData.append(`columnName${i}`, row.columnName || "");
      formData.append(operatorKey, row.columnvalueOperator || "");
      formData.append(logicalKey, row.logicalOperator || "");
      formData.append(valueKey, formattedValue);
    }
    // const startIndex = 1;
    // const endIndex = 4;

    // for (let i = startIndex; i <= endIndex; i++) {
    //   const rowIndex = i - 1;
    //   const row = this.state.rowscondition[rowIndex] || {};

    //   // Dynamically set columnName key
    //   const columnNameKey = i === 1 ? "columnName" : `columnName${i}`;

    //   // Other keys follow a similar pattern
    //   const operatorKey =
    //     i === 1 ? "columnvalueOperator" : `columnvalueOperator${i}`;
    //   const logicalKey = i === 1 ? "logicalOperator" : `logicalOperator${i}`;
    //   const valueKey = i === 1 ? "value1" : `value${i}`;

    //   // Handle operator-specific value logic
    //   const operator = row[operatorKey] || "";
    //   const value =
    //     operator === "Between" || operator === "Not Between"
    //       ? `${row.value1 || ""},${row.value2 || ""}`
    //       : row.value1 || "";

    //   // Append values to formData with dynamic keys
    //   formData.append(columnNameKey, row.columnName || "");
    //   formData.append(operatorKey, operator);
    //   formData.append(logicalKey, row.logicalOperator || "");
    //   formData.append(valueKey, value);
    // }

    formData.append("arithematicOperator", "");
    formData.append("columnofArithematic", "");
    formData.append("groupbyColumn", this.state.groupbyColumn);
    formData.append("extractMonth", this.state.extractMonth);
    formData.append("extractYear", this.state.extractYear);
    formData.append("dummyone", "");
    formData.append("dummytwo", "");
    formData.append("dummythree", "");
    formData.append("dummyfour", "");
    formData.append("dummyfive", "");
    formData.append("dummysix", "");
    formData.append("dummyseven", "");
    formData.append("dummyeight", "");
    formData.append("dummynine", "");
    formData.append("dummyten", "");
    fetch(configData.api_url + "C_Widgetdefinition/add", {
      // Enter your IP address here
      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({
            widgetslibrary_Id: "",
            widgetId: "",
            widgetName: "",
            chartType: "",
            tableName: "",
          });
        }
      });
  };
  handleColumnSelection = (axis, column) => {
    const selectedColumn = this.state.columns.find(
      (col) => col.name === column
    );
    this.setState((prevState) => ({
      selectedColumns: {
        ...prevState.selectedColumns,
        [axis]: {
          ...prevState.selectedColumns[axis],
          name: column,
          alias: column,
          columnType: selectedColumn?.type,
        },
      },
    }));
  };

  handleColumnAliasChange = (axis, alias) => {
    this.setState((prevState) => ({
      selectedColumns: {
        ...prevState.selectedColumns,
        [axis]: { ...prevState.selectedColumns[axis], alias },
      },
    }));
  };

  handleMathOperationChange = (axis, operation) => {
    this.setState((prevState) => ({
      selectedColumns: {
        ...prevState.selectedColumns,
        [axis]: { ...prevState.selectedColumns[axis], operation },
      },
    }));
  };
  WidgetslibraryfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Widgetslibrary/list", {
      // Enter your IP address here
      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  handlePrevStep = () => {
    this.setState((prevState) => ({
      step: Math.max(prevState.step - 1, 1),
    }));
  };
  renderTooltip = (text) => <Tooltip id="button-tooltip">{text}</Tooltip>;
  handleColumnChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state;
    const selectedLabel = e.target.options[e.target.selectedIndex].text;
    inputData[fieldName] = selectedLabel;
    this.setState({ rConditions: inputData, columnValue: fieldValue });
  };
  handleColumnChangeone = (index, e) => {
    const selectedColumnName = e.target.value; // Get the selected column name

    // Find the selected column object based on the column name
    const selectedColumn = this.state.columns.find(
      (col) => col.COLUMN_NAME === selectedColumnName
    );

    // If a valid column is selected
    if (selectedColumn) {
      const selectedDataType = selectedColumn.DATA_TYPE; // Get the data type

      this.setState((prevState) => {
        const rowscondition = [...prevState.rowscondition];

        // Update the row with the selected column name and its data type
        rowscondition[index] = {
          ...rowscondition[index],
          columnName: selectedColumnName,
          dataType: selectedDataType,
        };

        return { rowscondition };
      });
    } else {
      console.error(`Column "${selectedColumnName}" not found.`);
    }
  };

  inputSChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state;
    inputData[fieldName] = fieldValue;
    this.setState({ dataTypeOne: inputData });
    //this.setState({visibletype: e.target.value})
  };
  handleColumnOneChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state;
    inputData[fieldName] = fieldValue;
    this.setState({ rConditions: inputData });
  };

  inputtableSChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state;
    inputData[fieldName] = fieldValue;
    this.setState({ rConditions: inputData });
    this.getColumnsWT(fieldValue, "columns");
  };
  handledataTypeOneChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dataTypeOne: fieldValue });
  };
  handlelogicalOperatorChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ logicalOperator: fieldValue });
  };
  handlevalue1Change = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ value1: fieldValue });
  };
  handlegroupbyColumnChange = (e) => {
    // e.preventDefault();
    // const fieldValue = e.target.value;
    // this.setState({ groupbyColumn: fieldValue });
    const selectedColumnName = e.target.value;
    const selectedColumn = this.state.columns.find(
      (col) => col.COLUMN_NAME === selectedColumnName
    );

    this.setState({
      groupbyColumn: selectedColumnName,
      groupbyColumnType: selectedColumn ? selectedColumn.DATA_TYPE : "",
    });
  };
  handleAggregateColumnChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ aggregation: fieldValue });
  };
  handleExactmonthChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ extractMonth: fieldValue });
  };
  handleDOJChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      console.log(date);
      console.log(formattedDate);
      // Assuming you want to update the 'dob' state with the selected date
      this.setState({
        extractYear: formattedDate,
        startDate: new Date(),
      });
    }
  };
  getColumnsWT = (tableName, rcolumn) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("tableName", tableName);
    fetch(configData.api_url + "/masters/getcolumns", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({ [rcolumn]: data.columnsWT });
        }
      });
  };

  handleInputChange = (index, event) => {
    const { name, value } = event.target;
    this.setState((prevState) => {
      const updatedRows = [...prevState.rowscondition];
      updatedRows[index] = {
        ...updatedRows[index], // Clone the specific row
        [name]: value, // Update the specific field
      };
      return { rowscondition: updatedRows };
    });
  };
  handleDateChange = (index, date, fieldName) => {
    this.setState((prevState) => {
      const updatedRows = [...prevState.rowscondition];
      updatedRows[index] = {
        ...updatedRows[index],
        [fieldName]: date,
      };
      return { rowscondition: updatedRows };
    });
  };
  // handleAddRow = () => {
  //   const newRow = {
  //     columnValue: "",
  //     columnvalueOperator: "",
  //     logicalOperator: "",
  //     value2: "",
  //   };
  //   this.setState((prevState) => ({
  //     rowscondition: [...prevState.rowscondition, newRow],
  //   }));
  // };
  handleAddRow = () => {
    const maxRows = 4; // Limit to 3 rows
    this.setState((prevState) => {
      if (prevState.rowscondition.length < maxRows) {
        const newRow = {
          columnValue: "",
          columnvalueOperator: "",
          logicalOperator: "",
          value1: "",
        };
        return { rowscondition: [...prevState.rowscondition, newRow] };
      }
      return null; // Do nothing if maxRows is reached
    });
  };

  handleRemoveRow = (index) => {
    const updatedRows = this.state.rowscondition.filter(
      (_, rowIndex) => rowIndex !== index
    );
    this.setState({ rowscondition: updatedRows });
  };
  HandlePreviewsubmitHandler = (e) => {
    e.preventDefault();
    const widgetslibrary_Id =
      this.state.widgetslibrary_Id ||
      sessionStorage.getItem("widgetslibrary_Id");
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("widgetId", widgetslibrary_Id);
    formData.append("widgetName", this.state.widgetName);
    formData.append("chartType", this.state.chartType);
    formData.append("maxWidth", this.state.maxWidth);
    formData.append("minWidth", this.state.minWidth);
    formData.append("maxHeight", this.state.maxHeight);
    formData.append("minHeight", this.state.minHeight);
    formData.append("tableName", this.state.tableName);
    formData.append("columnName", this.state.columnName);
    formData.append("aggregation", this.state.aggregation);
    const startIndex = 1;
    const endIndex = 4;
    const formatDate = (date) => {
      if (!date) return "";
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };
    for (let i = startIndex; i <= endIndex; i++) {
      const rowIndex = i - 1;
      const row = this.state.rowscondition[rowIndex] || {};
      const operatorKey =
        i === 1 ? "columnvalueOperator" : `columnvalueOperator${i}`;
      const logicalKey = i === 1 ? "logicalOperator" : `logicalOperator${i}`;
      const valueKey = i === 1 ? "value1" : `value${i}`;
      const formattedValue =
        row.dataType === "date" ? formatDate(row.value1) : row.value1 || "";
      formData.append(`columnName${i}`, row.columnName || "");
      formData.append(operatorKey, row.columnvalueOperator || "");
      formData.append(logicalKey, row.logicalOperator || "");
      formData.append(valueKey, formattedValue);
    }
    formData.append("arithematicOperator", "");
    formData.append("columnofArithematic", "");
    formData.append("groupbyColumn", this.state.groupbyColumn);
    formData.append("extractMonth", this.state.extractMonth);
    formData.append("extractYear", this.state.extractYear);
    fetch(configData.api_url + "C_Widgetdefinition/previewwidgetData", {
      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.widgetData && data.widgetData.Counts) {
          const Labels = data.widgetData.Counts.map((item) => item.label);
          const Values = data.widgetData.Counts.map((item) =>
            parseInt(item.count, 10)
          );

          this.setState({
            chartData: {
              labels: Labels,
              datasets: [
                {
                  label: "Widget",
                  data: Values,
                  backgroundColor: [
                    "rgba(255, 99, 132, 0.2)",
                    "rgba(54, 162, 235, 0.2)",
                    "rgba(255, 206, 86, 0.2)",
                    "rgba(75, 192, 192, 0.2)",
                    "rgba(153, 102, 255, 0.2)",
                  ],
                  borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                  ],
                  borderWidth: 1,
                },
              ],
            },
          });
        }
      });
  };

  renderChartPreview = () => {
    const { chartType, chartData } = this.state;

    if (!chartData) {
      return null;
    }
    switch (chartType) {
      case "BAR":
        return <Bar data={chartData} />;
      case "LINE":
        return <Line data={chartData} />;
      case "PIE":
        return <Pie data={chartData} />;
      default:
        return <p>Select a valid chart type for preview.</p>;
    }
  };
  render() {
    const { step, widgetName, widgetDescription, chartType, tableName } =
      this.state;
    const previousYearStart = new Date();
    previousYearStart.setFullYear(previousYearStart.getFullYear() - 100);
    previousYearStart.setMonth(0); // Set the month to January
    previousYearStart.setDate(1);
    return (
      <div>
        <Row className="navigation-rows">
          <Col
            xs={4}
            sm={4}
            lg={4}
            className={`nav-item ${step === 1 ? "active-step" : ""}`}
          >
            <OverlayTrigger
              placement="top"
              overlay={this.renderTooltip("Profile Info")}
            >
              <Card
                className="nav-card"
                onClick={() => this.setState({ step: 1 })}
              >
                <span className="bi bi-boxes nav-icon"></span>
                <p className="nav-label">Profile Info</p>
              </Card>
            </OverlayTrigger>
          </Col>
          <Col
            xs={4}
            sm={4}
            lg={4}
            className={`nav-item ${step === 2 ? "active-step" : ""}`}
          >
            <OverlayTrigger
              placement="top"
              overlay={this.renderTooltip("Chart Selection")}
            >
              <Card
                className="nav-card"
                onClick={() => this.setState({ step: 2 })}
              >
                <span className="bi bi-journal-album nav-icon"></span>
                <p className="nav-label">Chart Selection</p>
              </Card>
            </OverlayTrigger>
          </Col>
          <Col
            xs={4}
            sm={4}
            lg={4}
            className={`nav-item ${step === 3 ? "active-step" : ""}`}
          >
            <OverlayTrigger
              placement="top"
              overlay={this.renderTooltip("Table Selection")}
            >
              <Card
                className="nav-card"
                onClick={() => this.setState({ step: 3 })}
              >
                <span className="bi bi-table nav-icon"></span>
                <p className="nav-label">Table Selection</p>
              </Card>
            </OverlayTrigger>
          </Col>
          {/* <Col
            xs={3}
            sm={3}
            className={`nav-item ${step === 4 ? "active-step" : ""}`}
          >
            <OverlayTrigger
              placement="top"
              overlay={this.renderTooltip("Summary")}
            >
              <Card
                className="nav-card"
                onClick={() => this.setState({ step: 4 })}
              >
                <span className="bi bi-file-earmark-check-fill nav-icon"></span>
                <p className="nav-label">Summary</p>
              </Card>
            </OverlayTrigger>
          </Col> */}
        </Row>
        <br />
        <hr />
        {step === 1 && (
          <Row>
            <Col xs={12} sm={8} lg={4}>
              {" "}
              <Card>
                <Card.Header>Widget Name and Description</Card.Header>
                <Card.Body>
                  <Form>
                    <Form.Group controlId="textBox">
                      <Form.Label>Widget Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="widgetName"
                        value={this.state.widgetName}
                        placeholder="Widget Name"
                        onChange={(e) =>
                          this.setState({ widgetName: e.target.value })
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="textArea">
                      <Form.Label>Widget Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="widgetDescription"
                        value={this.state.widgetDescription}
                        placeholder="Widget Description"
                        onChange={(e) =>
                          this.setState({ widgetDescription: e.target.value })
                        }
                      />
                    </Form.Group>
                    <Button
                      variant="primary"
                      style={{ float: "right", marginTop: "5px" }}
                      onClick={this.handleNextStep}
                    >
                      Next
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        {step === 2 && (
          <Row>
            <Col xs={12} sm={8} lg={4}>
              <Card>
                <Card.Header>Select Chart Type and Table</Card.Header>
                <Card.Body>
                  <Form>
                    <Form.Group controlId="chartType">
                      <Form.Label>Select Chart Type</Form.Label>
                      <Form.Control
                        as="select"
                        value={chartType}
                        // onChange={(e) =>
                        //   this.setState(
                        //     { chartType: e.target.value },
                        //     this.checkColumns
                        //   )
                        // }
                        onChange={this.handleChartTypeChange}
                        // onChange={(e) => this.setState({ chartType: e.target.value })}
                      >
                        <option value="">Select Chart Type</option>

                        {this.state.chartmasterlist.map((api, index, idx) => (
                          <option key={index} value={api.chartType}>
                            {api.chartName}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="tableName">
                      <Form.Label>Select Data Source</Form.Label>
                      <Form.Control
                        as="select"
                        className="form-select"
                        value={this.state.tableName}
                        name="tableName"
                        onChange={this.inputtableSChange}
                      >
                        <option value="">Select Table</option>
                        {this.state.tables.map((api, index, idx) => (
                          <option key={index} value={api}>
                            {api}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                    <Button
                      variant="primary"
                      style={{ float: "right", marginTop: "5px" }}
                      onClick={this.handleNextWidStep}
                    >
                      Next
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}

        {step === 3 && (
          <Row>
            <Col xs={12} sm={12} lg={12}>
              <Card>
                <Card.Body>
                  <Form>
                    <Row>
                      <Col>
                        <Card>
                          <Card.Header>Selected Widget</Card.Header>
                          <Card.Body>
                            <p>Selected Name: {widgetName}</p>
                            <p>Selected Description: {widgetDescription}</p>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col>
                        <Card>
                          <Card.Header>Selected Table & Chart Type</Card.Header>
                          <Card.Body>
                            <p>Selected Chart Type: {chartType}</p>
                            <p>Selected Table Name: {tableName}</p>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    <br />
                    {/* X-Axis Configuration */}
                    <Row>
                      {(chartType === "LINE" || chartType === "BAR") && (
                        <Col>
                          <Card>
                            <Card.Body>
                              <h3>Select </h3>
                              <br />
                              <Row>
                                <Col lg="3">
                                  {" "}
                                  <Form.Group controlId="xAxisColumn">
                                    <Form.Label>Select Column</Form.Label>
                                    <Form.Control
                                      as="select"
                                      // value={xAxisColumn}
                                      // onChange={(e) =>
                                      //   this.setState({ xAxisColumn: e.target.value })
                                      // }
                                      className="form-select"
                                      aria-label="Default select example"
                                      value={this.state.columnName}
                                      name="columnName"
                                      onChange={this.handleColumnChange}
                                    >
                                      <option value="">Column A</option>
                                      {this.state.columns.map((api, index) => (
                                        <option
                                          key={index}
                                          value={api.COLUMN_NAME}
                                        >
                                          {api.COLUMN_NAME}
                                        </option>
                                      ))}
                                    </Form.Control>
                                  </Form.Group>
                                </Col>
                                {/* <Col>
                                  <Form.Group controlId="yAxisColumn">
                                    <Form.Label>Select Column</Form.Label>
                                    <Form.Control
                                      as="select"
                                      className="form-select"
                                      aria-label="Default select example"
                                      value={this.state.columnName2}
                                      name="columnName2"
                                      onChange={this.handleColumnChange}
                                      //onChange={this.handleColumnOneChange}
                                    >
                                      <option value="">Column B</option>
                                      {this.state.columns.map((api, index) => (
                                        <option
                                          key={api.DATA_TYPE}
                                          value={api.COLUMN_NAME}
                                        >
                                          {api.COLUMN_NAME}
                                        </option>
                                      ))}
                                    </Form.Control>
                                  </Form.Group>
                                </Col> */}
                                <Col lg="3">
                                  <Form.Group>
                                    <Form.Label>Aggregation</Form.Label>
                                    <Form.Control
                                      as="select"
                                      className="form-select"
                                      value={this.state.aggregation}
                                      onChange={
                                        this.handleAggregateColumnChange
                                      }
                                      name="aggregation"
                                    >
                                      <option value="">Select</option>
                                      <option value="count">Count</option>
                                      <option value="average">Average</option>
                                      <option value="sum">Sum</option>
                                    </Form.Control>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <br />
                              <h3>Where condition</h3>
                              <br />
                              {this.state.rowscondition.map((row, index) => (
                                <div className="row">
                                  <div className="col">
                                    {" "}
                                    <Form.Group controlId="textBox">
                                      <Form.Label>Select Column</Form.Label>
                                      <Form.Control
                                        as="select"
                                        className="form-select"
                                        value={row.columnName || ""}
                                        name="columnName"
                                        //onChange={this.handleColumnChangeone}
                                        onChange={(e) =>
                                          this.handleColumnChangeone(index, e)
                                        }
                                      >
                                        <option value="">Select</option>
                                        {this.state.columns.map(
                                          (api, index) => (
                                            <option
                                              key={index}
                                              value={api.COLUMN_NAME}
                                            >
                                              {api.COLUMN_NAME}
                                            </option>
                                          )
                                        )}
                                      </Form.Control>
                                    </Form.Group>
                                  </div>
                                  <div className="col">
                                    <Form.Group
                                      controlId={`columnvalueOperator-${index}`}
                                    >
                                      <Form.Label>Operator</Form.Label>
                                      <>
                                        {" "}
                                        {row.dataType === "int" && (
                                          <Form.Select
                                            className="form-select"
                                            name="columnvalueOperator"
                                            // onChange={
                                            //   this.handledataTypeOneChange
                                            // }
                                            onChange={(e) =>
                                              this.handleInputChange(index, e)
                                            }
                                            // value={
                                            //   this.state.columnvalueOperator
                                            // }
                                            value={
                                              row.columnvalueOperator || ""
                                            }
                                          >
                                            <option defaultValue>
                                              Operators{" "}
                                            </option>
                                            <option value="=">=</option>
                                            <option value="<">&lt;</option>
                                            <option value=">">&gt;</option>
                                            <option value="<=">&le;</option>
                                            <option value=">=">&ge;</option>
                                            <option value="!=">
                                              &ne; Not Equal
                                            </option>
                                            <option value="Is Empty">
                                              Is Empty
                                            </option>
                                            <option value="Is Not Empty">
                                              Is Not Empty
                                            </option>
                                          </Form.Select>
                                        )}
                                        {row.dataType === "varchar" && (
                                          <select
                                            className="form-select"
                                            name="columnvalueOperator"
                                            // onChange={
                                            //   this.handledataTypeOneChange
                                            // }
                                            onChange={(e) =>
                                              this.handleInputChange(index, e)
                                            }
                                            value={
                                              row.columnvalueOperator || ""
                                            }
                                          >
                                            <option>VarcharOperators</option>
                                            <option value="=">=</option>
                                            <option value="<">&lt;</option>
                                            <option value=">">&gt;</option>
                                            <option value="<=">&le;</option>
                                            <option value=">=">&ge;</option>
                                            <option value="!=">
                                              &ne; Not Equal
                                            </option>
                                            <option value="Is">Is</option>
                                            <option value="Isn't">Isn't</option>
                                            <option value="Contains">
                                              Contains
                                            </option>
                                            <option value="Doesn't Contains">
                                              Doesn't Contains
                                            </option>
                                            <option value="Starts With">
                                              Starts With
                                            </option>
                                            <option value="Ends With">
                                              Ends With
                                            </option>
                                            <option value="Is Empty">
                                              Is Empty
                                            </option>
                                            <option value="Is Not Empty">
                                              Is Not Empty
                                            </option>
                                          </select>
                                        )}
                                        {row.dataType === "text" && (
                                          <select
                                            className="form-select"
                                            value={
                                              row.columnvalueOperator || ""
                                            }
                                            // onChange={
                                            //   this.handledataTypeOneChange
                                            // }
                                            onChange={(e) =>
                                              this.handleInputChange(index, e)
                                            }
                                            name="columnvalueOperator"
                                          >
                                            <option defaultValue>
                                              textOperators
                                            </option>
                                            <option value="=">=</option>
                                            <option value="<">&lt;</option>
                                            <option value=">">&gt;</option>
                                            <option value="<=">&le;</option>
                                            <option value=">=">&ge;</option>
                                            <option value="!=">
                                              &ne; Not Equal
                                            </option>
                                            <option value="Is">Is</option>
                                            <option value="Isn't">Isn't</option>
                                            <option value="Contains">
                                              Contains
                                            </option>
                                            <option value="Doesn't Contains">
                                              Doesn't Contains
                                            </option>
                                            <option value="Is Before">
                                              Is Before
                                            </option>
                                            <option value="Is After">
                                              Is After
                                            </option>
                                            <option value="Starts With">
                                              Starts With
                                            </option>
                                            <option value="Ends With">
                                              Ends With
                                            </option>
                                            <option value="Is Empty">
                                              Is Empty
                                            </option>
                                            <option value="Is Not Empty">
                                              Is Not Empty
                                            </option>
                                          </select>
                                        )}
                                        {row.dataType === "datetime" && (
                                          <select
                                            className="form-select"
                                            value={
                                              row.columnvalueOperator || ""
                                            }
                                            // onChange={
                                            //   this.handledataTypeOneChange
                                            // }
                                            onChange={(e) =>
                                              this.handleInputChange(index, e)
                                            }
                                            name="columnvalueOperator"
                                          >
                                            <option defaultValue>
                                              textOperators
                                            </option>
                                            <option value="=">=</option>
                                            <option value="<">&lt;</option>
                                            <option value=">">&gt;</option>
                                            <option value="<=">&le;</option>
                                            <option value=">=">&ge;</option>
                                            <option value="!=">
                                              &ne; Not Equal
                                            </option>
                                            <option value="Before">
                                              Before
                                            </option>
                                            <option value="Between">
                                              Between
                                            </option>
                                            <option value="Starts With">
                                              Starts With
                                            </option>
                                            <option value="Ends With">
                                              Ends With
                                            </option>
                                            <option value="Year">Year</option>
                                            <option value="Month">Month</option>
                                          </select>
                                        )}
                                        {row.dataType === "date" && (
                                          <select
                                            className="form-select"
                                            value={
                                              row.columnvalueOperator || ""
                                            }
                                            // onChange={
                                            //   this.handledataTypeOneChange
                                            // }
                                            onChange={(e) =>
                                              this.handleInputChange(index, e)
                                            }
                                            name="columnvalueOperator"
                                          >
                                            <option defaultValue>
                                              textOperators
                                            </option>
                                            <option value="=">=</option>
                                            <option value="<">&lt;</option>
                                            <option value=">">&gt;</option>
                                            <option value="<=">&le;</option>
                                            <option value=">=">&ge;</option>
                                            <option value="!=">
                                              &ne; Not Equal
                                            </option>
                                            <option value="Today">Today</option>
                                            <option value="Yesterday">
                                              Yesterday
                                            </option>
                                            <option value="Tomorrow">
                                              Tomorrow
                                            </option>
                                            <option value="Last N Days">
                                              Last N Days
                                            </option>
                                            <option value="Next N Days">
                                              Next N Days
                                            </option>
                                            <option value="Before">
                                              Before
                                            </option>
                                            <option value="Between">
                                              Between
                                            </option>
                                            <option value="Starts With">
                                              Starts With
                                            </option>
                                            <option value="Ends With">
                                              Ends With
                                            </option>
                                            <option value="Current Month">
                                              Current Month
                                            </option>
                                            <option value="Current Year">
                                              Current Year
                                            </option>
                                            <option value="Year">Year</option>
                                            <option value="Month">Month</option>
                                          </select>
                                        )}
                                      </>
                                    </Form.Group>
                                  </div>

                                  <div className="col">
                                    <Form.Group controlId={`value1-${index}`}>
                                      <Form.Label>Value</Form.Label>
                                      <div className="d-flex">
                                        {row.dataType === "date" &&
                                        row.columnvalueOperator ? (
                                          <>
                                            <DatePicker
                                              selected={row.value1 || null}
                                              onChange={(date) =>
                                                this.handleDateChange(
                                                  index,
                                                  date,
                                                  "value1"
                                                )
                                              }
                                              dateFormat="dd/MM/yyyy"
                                              className="form-control"
                                              placeholderText="Select Date"
                                            />
                                          </>
                                        ) : (
                                          <Form.Control
                                            type="text"
                                            onChange={(e) =>
                                              this.handleInputChange(index, e)
                                            }
                                            className="form-control"
                                            name="value1"
                                            value={row.value1 || ""}
                                            placeholder="Enter Value"
                                          />
                                        )}
                                      </div>
                                    </Form.Group>
                                  </div>
                                  <div className="col">
                                    {" "}
                                    <Form.Group controlId="textBox">
                                      <Form.Label>Logical Operators</Form.Label>
                                      <Form.Control
                                        as="select"
                                        className="form-select"
                                        value={row.logicalOperator}
                                        onChange={(e) =>
                                          this.handleInputChange(index, e)
                                        }
                                        // value={this.state.logicalOperator}
                                        // onChange={
                                        //   this.handlelogicalOperatorChange
                                        // }
                                        name="logicalOperator"
                                      >
                                        <option value="">Select</option>
                                        <option value="AND">AND</option>
                                        <option value="OR">OR</option>
                                        <option value="NOT">NOT</option>
                                      </Form.Control>
                                    </Form.Group>
                                  </div>
                                  <div className="col-1">
                                    {index === 0 ? (
                                      row.logicalOperator ? (
                                        <Button
                                          onClick={this.handleAddRow}
                                          style={{ marginTop: "30px" }}
                                          className="bi bi-plus"
                                        ></Button>
                                      ) : null
                                    ) : (
                                      <Button
                                        onClick={() =>
                                          this.handleRemoveRow(index)
                                        }
                                        style={{ marginTop: "30px" }}
                                        className="bi bi-trash"
                                      ></Button>
                                    )}
                                  </div>
                                </div>
                              ))}
                              <div className="row">
                                <div className="col">
                                  {" "}
                                  <Form.Group controlId="textBox">
                                    <Form.Label>Group By Column</Form.Label>
                                    <Form.Control
                                      as="select"
                                      className="form-select"
                                      value={this.state.groupbyColumn}
                                      onChange={this.handlegroupbyColumnChange}
                                      name="groupbyColumn"
                                    >
                                      <option value="">Select</option>
                                      {this.state.columns.map((api, index) => (
                                        <option
                                          key={index}
                                          value={api.COLUMN_NAME}
                                        >
                                          {api.COLUMN_NAME}
                                        </option>
                                      ))}
                                    </Form.Control>
                                  </Form.Group>
                                </div>
                                <div className="col">
                                  {" "}
                                  <Form.Group>
                                    <Form.Label>Group By</Form.Label>
                                    {this.state.groupbyColumnType === "date" ? (
                                      <Form.Select
                                        className="form-select"
                                        name="extractMonth"
                                        value={this.state.extractMonth}
                                        onChange={this.handleExactmonthChange}
                                      >
                                        <option value="">Select</option>
                                        <option value="MY">Month & Year</option>
                                        <option value="Y">Year</option>
                                        <option value="M">Month</option>
                                      </Form.Select>
                                    ) : (
                                      <p className="mt-2">
                                        No operators available for this type
                                      </p>
                                    )}
                                  </Form.Group>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      )}

                      {/* Display only one column selector for Pie chart */}
                      {(chartType === "PIE" || chartType === "DONUT") && (
                        <Col>
                          <Card>
                            <Card.Body>
                              <h3>Select </h3>
                              <br />
                              <Row>
                                <Col lg="3">
                                  {" "}
                                  <Form.Group controlId="xAxisColumn">
                                    <Form.Label>Select Column</Form.Label>
                                    <Form.Control
                                      as="select"
                                      // value={xAxisColumn}
                                      // onChange={(e) =>
                                      //   this.setState({ xAxisColumn: e.target.value })
                                      // }
                                      className="form-select"
                                      aria-label="Default select example"
                                      value={this.state.columnName}
                                      name="columnName"
                                      onChange={this.handleColumnChange}
                                    >
                                      <option value="">Column A</option>
                                      {this.state.columns.map((api, index) => (
                                        <option
                                          key={index}
                                          value={api.COLUMN_NAME}
                                        >
                                          {api.COLUMN_NAME}
                                        </option>
                                      ))}
                                    </Form.Control>
                                  </Form.Group>
                                </Col>
                                <Col lg="3">
                                  <Form.Group>
                                    <Form.Label>Aggregation</Form.Label>
                                    <Form.Control
                                      as="select"
                                      className="form-select"
                                      value={this.state.aggregation}
                                      onChange={
                                        this.handleAggregateColumnChange
                                      }
                                      name="aggregation"
                                    >
                                      <option value="">Select</option>
                                      <option value="count">Count</option>
                                      <option value="average">Average</option>
                                      <option value="sum">Sum</option>
                                    </Form.Control>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <br />
                              <h3>Where condition</h3>
                              <br />
                              {this.state.rowscondition.map((row, index) => (
                                <div className="row">
                                  <div className="col">
                                    {" "}
                                    <Form.Group controlId="textBox">
                                      <Form.Label>Select Column</Form.Label>
                                      <Form.Control
                                        as="select"
                                        className="form-select"
                                        value={row.columnName || ""}
                                        name="columnName"
                                        //onChange={this.handleColumnChangeone}
                                        onChange={(e) =>
                                          this.handleColumnChangeone(index, e)
                                        }
                                      >
                                        <option value="">Select</option>
                                        {this.state.columns.map(
                                          (api, index) => (
                                            <option
                                              key={index}
                                              value={api.COLUMN_NAME}
                                            >
                                              {api.COLUMN_NAME}
                                            </option>
                                          )
                                        )}
                                      </Form.Control>
                                    </Form.Group>
                                  </div>
                                  <div className="col">
                                    <Form.Group
                                      controlId={`columnvalueOperator-${index}`}
                                    >
                                      <Form.Label>Operator</Form.Label>
                                      <>
                                        {" "}
                                        {row.dataType === "int" && (
                                          <Form.Select
                                            className="form-select"
                                            name="columnvalueOperator"
                                            // onChange={
                                            //   this.handledataTypeOneChange
                                            // }
                                            onChange={(e) =>
                                              this.handleInputChange(index, e)
                                            }
                                            // value={
                                            //   this.state.columnvalueOperator
                                            // }
                                            value={
                                              row.columnvalueOperator || ""
                                            }
                                          >
                                            <option defaultValue>
                                              Operators{" "}
                                            </option>
                                            <option value="=">=</option>
                                            <option value="<">&lt;</option>
                                            <option value=">">&gt;</option>
                                            <option value="<=">&le;</option>
                                            <option value=">=">&ge;</option>
                                            <option value="!=">
                                              &ne; Not Equal
                                            </option>
                                            <option value="Is Empty">
                                              Is Empty
                                            </option>
                                            <option value="Is Not Empty">
                                              Is Not Empty
                                            </option>
                                          </Form.Select>
                                        )}
                                        {row.dataType === "varchar" && (
                                          <select
                                            className="form-select"
                                            name="columnvalueOperator"
                                            // onChange={
                                            //   this.handledataTypeOneChange
                                            // }
                                            onChange={(e) =>
                                              this.handleInputChange(index, e)
                                            }
                                            value={
                                              row.columnvalueOperator || ""
                                            }
                                          >
                                            <option>VarcharOperators</option>
                                            <option value="=">=</option>
                                            <option value="<">&lt;</option>
                                            <option value=">">&gt;</option>
                                            <option value="<=">&le;</option>
                                            <option value=">=">&ge;</option>
                                            <option value="!=">
                                              &ne; Not Equal
                                            </option>
                                            <option value="Is">Is</option>
                                            <option value="Isn't">Isn't</option>
                                            <option value="Contains">
                                              Contains
                                            </option>
                                            <option value="Doesn't Contains">
                                              Doesn't Contains
                                            </option>
                                            <option value="Starts With">
                                              Starts With
                                            </option>
                                            <option value="Ends With">
                                              Ends With
                                            </option>
                                            <option value="Is Empty">
                                              Is Empty
                                            </option>
                                            <option value="Is Not Empty">
                                              Is Not Empty
                                            </option>
                                          </select>
                                        )}
                                        {row.dataType === "text" && (
                                          <select
                                            className="form-select"
                                            value={
                                              row.columnvalueOperator || ""
                                            }
                                            // onChange={
                                            //   this.handledataTypeOneChange
                                            // }
                                            onChange={(e) =>
                                              this.handleInputChange(index, e)
                                            }
                                            name="columnvalueOperator"
                                          >
                                            <option defaultValue>
                                              textOperators
                                            </option>
                                            <option value="=">=</option>
                                            <option value="<">&lt;</option>
                                            <option value=">">&gt;</option>
                                            <option value="<=">&le;</option>
                                            <option value=">=">&ge;</option>
                                            <option value="!=">
                                              &ne; Not Equal
                                            </option>
                                            <option value="Is">Is</option>
                                            <option value="Isn't">Isn't</option>
                                            <option value="Contains">
                                              Contains
                                            </option>
                                            <option value="Doesn't Contains">
                                              Doesn't Contains
                                            </option>
                                            <option value="Is Before">
                                              Is Before
                                            </option>
                                            <option value="Is After">
                                              Is After
                                            </option>
                                            <option value="Starts With">
                                              Starts With
                                            </option>
                                            <option value="Ends With">
                                              Ends With
                                            </option>
                                            <option value="Is Empty">
                                              Is Empty
                                            </option>
                                            <option value="Is Not Empty">
                                              Is Not Empty
                                            </option>
                                          </select>
                                        )}
                                        {row.dataType === "datetime" && (
                                          <select
                                            className="form-select"
                                            value={
                                              row.columnvalueOperator || ""
                                            }
                                            // onChange={
                                            //   this.handledataTypeOneChange
                                            // }
                                            onChange={(e) =>
                                              this.handleInputChange(index, e)
                                            }
                                            name="columnvalueOperator"
                                          >
                                            <option defaultValue>
                                              textOperators
                                            </option>
                                            <option value="=">=</option>
                                            <option value="<">&lt;</option>
                                            <option value=">">&gt;</option>
                                            <option value="<=">&le;</option>
                                            <option value=">=">&ge;</option>
                                            <option value="!=">
                                              &ne; Not Equal
                                            </option>
                                            <option value="Before">
                                              Before
                                            </option>
                                            <option value="Between">
                                              Between
                                            </option>
                                            <option value="Starts With">
                                              Starts With
                                            </option>
                                            <option value="Ends With">
                                              Ends With
                                            </option>
                                            <option value="Year">Year</option>
                                            <option value="Month">Month</option>
                                          </select>
                                        )}
                                        {row.dataType === "date" && (
                                          <select
                                            className="form-select"
                                            value={
                                              row.columnvalueOperator || ""
                                            }
                                            // onChange={
                                            //   this.handledataTypeOneChange
                                            // }
                                            onChange={(e) =>
                                              this.handleInputChange(index, e)
                                            }
                                            name="columnvalueOperator"
                                          >
                                            <option defaultValue>
                                              textOperators
                                            </option>
                                            <option value="=">=</option>
                                            <option value="<">&lt;</option>
                                            <option value=">">&gt;</option>
                                            <option value="<=">&le;</option>
                                            <option value=">=">&ge;</option>
                                            <option value="!=">
                                              &ne; Not Equal
                                            </option>
                                            <option value="Today">Today</option>
                                            <option value="Yesterday">
                                              Yesterday
                                            </option>
                                            <option value="Tomorrow">
                                              Tomorrow
                                            </option>
                                            <option value="Last N Days">
                                              Last N Days
                                            </option>
                                            <option value="Next N Days">
                                              Next N Days
                                            </option>
                                            <option value="Before">
                                              Before
                                            </option>
                                            <option value="Between">
                                              Between
                                            </option>
                                            <option value="Starts With">
                                              Starts With
                                            </option>
                                            <option value="Ends With">
                                              Ends With
                                            </option>
                                            <option value="Current Month">
                                              Current Month
                                            </option>
                                            <option value="Current Year">
                                              Current Year
                                            </option>
                                            <option value="Year">Year</option>
                                            <option value="Month">Month</option>
                                          </select>
                                        )}
                                      </>
                                    </Form.Group>
                                  </div>

                                  <div className="col">
                                    <Form.Group controlId={`value1-${index}`}>
                                      <Form.Label>Value</Form.Label>
                                      <div className="d-flex">
                                        {row.dataType === "date" &&
                                        row.columnvalueOperator ? (
                                          <>
                                            <DatePicker
                                              selected={row.value1 || null}
                                              onChange={(date) =>
                                                this.handleDateChange(
                                                  index,
                                                  date,
                                                  "value1"
                                                )
                                              }
                                              dateFormat="dd/MM/yyyy"
                                              className="form-control"
                                              placeholderText="Select Date"
                                            />
                                          </>
                                        ) : (
                                          <Form.Control
                                            type="text"
                                            onChange={(e) =>
                                              this.handleInputChange(index, e)
                                            }
                                            className="form-control"
                                            name="value1"
                                            value={row.value1 || ""}
                                            placeholder="Enter Value"
                                          />
                                        )}
                                      </div>
                                    </Form.Group>
                                  </div>
                                  <div className="col">
                                    {" "}
                                    <Form.Group controlId="textBox">
                                      <Form.Label>Logical Operators</Form.Label>
                                      <Form.Control
                                        as="select"
                                        className="form-select"
                                        value={row.logicalOperator}
                                        onChange={(e) =>
                                          this.handleInputChange(index, e)
                                        }
                                        // value={this.state.logicalOperator}
                                        // onChange={
                                        //   this.handlelogicalOperatorChange
                                        // }
                                        name="logicalOperator"
                                      >
                                        <option value="">Select</option>
                                        <option value="AND">AND</option>
                                        <option value="OR">OR</option>
                                        <option value="NOT">NOT</option>
                                      </Form.Control>
                                    </Form.Group>
                                  </div>
                                  <div className="col">
                                    {index === 0 ? (
                                      row.logicalOperator ? (
                                        <Button
                                          onClick={this.handleAddRow}
                                          style={{ marginTop: "30px" }}
                                          className="bi bi-plus"
                                        ></Button>
                                      ) : null
                                    ) : (
                                      <Button
                                        onClick={() =>
                                          this.handleRemoveRow(index)
                                        }
                                        style={{ marginTop: "30px" }}
                                        className="bi bi-trash"
                                      ></Button>
                                    )}
                                  </div>
                                </div>
                              ))}
                              <div className="row">
                                <div className="col">
                                  {" "}
                                  <Form.Group controlId="textBox">
                                    <Form.Label>Group By Column</Form.Label>
                                    <Form.Control
                                      as="select"
                                      className="form-select"
                                      value={this.state.groupbyColumn}
                                      onChange={this.handlegroupbyColumnChange}
                                      name="groupbyColumn"
                                    >
                                      <option value="">Select</option>
                                      {this.state.columns.map((api, index) => (
                                        <option
                                          key={index}
                                          value={api.COLUMN_NAME}
                                        >
                                          {api.COLUMN_NAME}
                                        </option>
                                      ))}
                                    </Form.Control>
                                  </Form.Group>
                                </div>
                                <div className="col">
                                  {" "}
                                  <Form.Group>
                                    <Form.Label>Group By</Form.Label>
                                    {this.state.groupbyColumnType === "date" ? (
                                      <Form.Select
                                        className="form-select"
                                        name="extractMonth"
                                        value={this.state.extractMonth}
                                        onChange={this.handleExactmonthChange}
                                      >
                                        <option value="">Select</option>
                                        <option value="MY">Month & Year</option>
                                        <option value="Y">Year</option>
                                        <option value="M">Month</option>
                                      </Form.Select>
                                    ) : (
                                      <p className="mt-2">
                                        No operators available for this type
                                      </p>
                                    )}
                                  </Form.Group>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      )}
                      {/*BISCUT*/}
                      {chartType === "BISCUT" && (
                        <Col>
                          <Card>
                            <Card.Body>
                              <h3>Select </h3>
                              <br />
                              <Row>
                                <Col lg="3">
                                  {" "}
                                  <Form.Group controlId="xAxisColumn">
                                    <Form.Label>Select Column</Form.Label>
                                    <Form.Control
                                      as="select"
                                      // value={xAxisColumn}
                                      // onChange={(e) =>
                                      //   this.setState({ xAxisColumn: e.target.value })
                                      // }
                                      className="form-select"
                                      aria-label="Default select example"
                                      value={this.state.columnName}
                                      name="columnName"
                                      onChange={this.handleColumnChange}
                                    >
                                      <option value="">Column A</option>
                                      {this.state.columns.map((api, index) => (
                                        <option
                                          key={index}
                                          value={api.COLUMN_NAME}
                                        >
                                          {api.COLUMN_NAME}
                                        </option>
                                      ))}
                                    </Form.Control>
                                  </Form.Group>
                                </Col>

                                <Col lg="3">
                                  <Form.Group>
                                    <Form.Label>Aggregation</Form.Label>
                                    <Form.Control
                                      as="select"
                                      className="form-select"
                                      value={this.state.aggregation}
                                      onChange={
                                        this.handleAggregateColumnChange
                                      }
                                      name="aggregation"
                                    >
                                      <option value="">Select</option>
                                      <option value="count">Count</option>
                                      <option value="average">Average</option>
                                      <option value="sum">Sum</option>
                                    </Form.Control>
                                  </Form.Group>
                                </Col>
                              </Row>
                              <br />
                              <h3>Where condition</h3>
                              <br />
                              {this.state.rowscondition.map((row, index) => (
                                <div className="row">
                                  <div className="col">
                                    {" "}
                                    <Form.Group controlId="textBox">
                                      <Form.Label>Select Column</Form.Label>
                                      <Form.Control
                                        as="select"
                                        className="form-select"
                                        value={row.columnName || ""}
                                        name="columnName"
                                        //onChange={this.handleColumnChangeone}
                                        onChange={(e) =>
                                          this.handleColumnChangeone(index, e)
                                        }
                                      >
                                        <option value="">Select</option>
                                        {this.state.columns.map(
                                          (api, index) => (
                                            <option
                                              key={index}
                                              value={api.COLUMN_NAME}
                                            >
                                              {api.COLUMN_NAME}
                                            </option>
                                          )
                                        )}
                                      </Form.Control>
                                    </Form.Group>
                                  </div>
                                  <div className="col">
                                    <Form.Group
                                      controlId={`columnvalueOperator-${index}`}
                                    >
                                      <Form.Label>Operator</Form.Label>
                                      <>
                                        {" "}
                                        {row.dataType === "int" && (
                                          <Form.Select
                                            className="form-select"
                                            name="columnvalueOperator"
                                            // onChange={
                                            //   this.handledataTypeOneChange
                                            // }
                                            onChange={(e) =>
                                              this.handleInputChange(index, e)
                                            }
                                            // value={
                                            //   this.state.columnvalueOperator
                                            // }
                                            value={
                                              row.columnvalueOperator || ""
                                            }
                                          >
                                            <option defaultValue>
                                              Operators{" "}
                                            </option>
                                            <option value="=">=</option>
                                            <option value="<">&lt;</option>
                                            <option value=">">&gt;</option>
                                            <option value="<=">&le;</option>
                                            <option value=">=">&ge;</option>
                                            <option value="!=">
                                              &ne; Not Equal
                                            </option>
                                            <option value="Is Empty">
                                              Is Empty
                                            </option>
                                            <option value="Is Not Empty">
                                              Is Not Empty
                                            </option>
                                          </Form.Select>
                                        )}
                                        {row.dataType === "varchar" && (
                                          <select
                                            className="form-select"
                                            name="columnvalueOperator"
                                            // onChange={
                                            //   this.handledataTypeOneChange
                                            // }
                                            onChange={(e) =>
                                              this.handleInputChange(index, e)
                                            }
                                            value={
                                              row.columnvalueOperator || ""
                                            }
                                          >
                                            <option>VarcharOperators</option>
                                            <option value="=">=</option>
                                            <option value="<">&lt;</option>
                                            <option value=">">&gt;</option>
                                            <option value="<=">&le;</option>
                                            <option value=">=">&ge;</option>
                                            <option value="!=">
                                              &ne; Not Equal
                                            </option>
                                            <option value="Is">Is</option>
                                            <option value="Isn't">Isn't</option>
                                            <option value="Contains">
                                              Contains
                                            </option>
                                            <option value="Doesn't Contains">
                                              Doesn't Contains
                                            </option>
                                            <option value="Starts With">
                                              Starts With
                                            </option>
                                            <option value="Ends With">
                                              Ends With
                                            </option>
                                            <option value="Is Empty">
                                              Is Empty
                                            </option>
                                            <option value="Is Not Empty">
                                              Is Not Empty
                                            </option>
                                          </select>
                                        )}
                                        {row.dataType === "text" && (
                                          <select
                                            className="form-select"
                                            value={
                                              row.columnvalueOperator || ""
                                            }
                                            // onChange={
                                            //   this.handledataTypeOneChange
                                            // }
                                            onChange={(e) =>
                                              this.handleInputChange(index, e)
                                            }
                                            name="columnvalueOperator"
                                          >
                                            <option defaultValue>
                                              textOperators
                                            </option>
                                            <option value="=">=</option>
                                            <option value="<">&lt;</option>
                                            <option value=">">&gt;</option>
                                            <option value="<=">&le;</option>
                                            <option value=">=">&ge;</option>
                                            <option value="!=">
                                              &ne; Not Equal
                                            </option>
                                            <option value="Is">Is</option>
                                            <option value="Isn't">Isn't</option>
                                            <option value="Contains">
                                              Contains
                                            </option>
                                            <option value="Doesn't Contains">
                                              Doesn't Contains
                                            </option>
                                            <option value="Is Before">
                                              Is Before
                                            </option>
                                            <option value="Is After">
                                              Is After
                                            </option>
                                            <option value="Starts With">
                                              Starts With
                                            </option>
                                            <option value="Ends With">
                                              Ends With
                                            </option>
                                            <option value="Is Empty">
                                              Is Empty
                                            </option>
                                            <option value="Is Not Empty">
                                              Is Not Empty
                                            </option>
                                          </select>
                                        )}
                                        {row.dataType === "datetime" && (
                                          <select
                                            className="form-select"
                                            value={
                                              row.columnvalueOperator || ""
                                            }
                                            // onChange={
                                            //   this.handledataTypeOneChange
                                            // }
                                            onChange={(e) =>
                                              this.handleInputChange(index, e)
                                            }
                                            name="columnvalueOperator"
                                          >
                                            <option defaultValue>
                                              textOperators
                                            </option>
                                            <option value="=">=</option>
                                            <option value="<">&lt;</option>
                                            <option value=">">&gt;</option>
                                            <option value="<=">&le;</option>
                                            <option value=">=">&ge;</option>
                                            <option value="!=">
                                              &ne; Not Equal
                                            </option>
                                            <option value="Before">
                                              Before
                                            </option>
                                            <option value="Between">
                                              Between
                                            </option>
                                            <option value="Starts With">
                                              Starts With
                                            </option>
                                            <option value="Ends With">
                                              Ends With
                                            </option>
                                            <option value="Year">Year</option>
                                            <option value="Month">Month</option>
                                          </select>
                                        )}
                                        {row.dataType === "date" && (
                                          <select
                                            className="form-select"
                                            value={
                                              row.columnvalueOperator || ""
                                            }
                                            // onChange={
                                            //   this.handledataTypeOneChange
                                            // }
                                            onChange={(e) =>
                                              this.handleInputChange(index, e)
                                            }
                                            name="columnvalueOperator"
                                          >
                                            <option defaultValue>
                                              textOperators
                                            </option>
                                            <option value="=">=</option>
                                            <option value="<">&lt;</option>
                                            <option value=">">&gt;</option>
                                            <option value="<=">&le;</option>
                                            <option value=">=">&ge;</option>
                                            <option value="!=">
                                              &ne; Not Equal
                                            </option>
                                            <option value="Today">Today</option>
                                            <option value="Yesterday">
                                              Yesterday
                                            </option>
                                            <option value="Tomorrow">
                                              Tomorrow
                                            </option>
                                            <option value="Last N Days">
                                              Last N Days
                                            </option>
                                            <option value="Next N Days">
                                              Next N Days
                                            </option>
                                            <option value="Before">
                                              Before
                                            </option>
                                            <option value="Between">
                                              Between
                                            </option>
                                            <option value="Starts With">
                                              Starts With
                                            </option>
                                            <option value="Ends With">
                                              Ends With
                                            </option>
                                            <option value="Current Month">
                                              Current Month
                                            </option>
                                            <option value="Current Year">
                                              Current Year
                                            </option>
                                            <option value="Year">Year</option>
                                            <option value="Month">Month</option>
                                          </select>
                                        )}
                                      </>
                                    </Form.Group>
                                  </div>

                                  <div className="col">
                                    <Form.Group controlId={`value1-${index}`}>
                                      <Form.Label>Value</Form.Label>
                                      <div className="d-flex">
                                        {row.dataType === "date" &&
                                        row.columnvalueOperator ? (
                                          <>
                                            <DatePicker
                                              selected={row.value1 || null}
                                              onChange={(date) =>
                                                this.handleDateChange(
                                                  index,
                                                  date,
                                                  "value1"
                                                )
                                              }
                                              dateFormat="dd/MM/yyyy"
                                              className="form-control"
                                              placeholderText="Select Date"
                                            />
                                          </>
                                        ) : (
                                          <Form.Control
                                            type="text"
                                            onChange={(e) =>
                                              this.handleInputChange(index, e)
                                            }
                                            className="form-control"
                                            name="value1"
                                            value={row.value1 || ""}
                                            placeholder="Enter Value"
                                          />
                                        )}
                                      </div>
                                    </Form.Group>
                                  </div>
                                  <div className="col">
                                    {" "}
                                    <Form.Group controlId="textBox">
                                      <Form.Label>Logical Operators</Form.Label>
                                      <Form.Control
                                        as="select"
                                        className="form-select"
                                        value={row.logicalOperator}
                                        onChange={(e) =>
                                          this.handleInputChange(index, e)
                                        }
                                        // value={this.state.logicalOperator}
                                        // onChange={
                                        //   this.handlelogicalOperatorChange
                                        // }
                                        name="logicalOperator"
                                      >
                                        <option value="">Select</option>
                                        <option value="AND">AND</option>
                                        <option value="OR">OR</option>
                                        <option value="NOT">NOT</option>
                                      </Form.Control>
                                    </Form.Group>
                                  </div>
                                  <div className="col">
                                    {index === 0 ? (
                                      row.logicalOperator ? (
                                        <Button
                                          onClick={this.handleAddRow}
                                          style={{ marginTop: "30px" }}
                                          className="bi bi-plus"
                                        ></Button>
                                      ) : null
                                    ) : (
                                      <Button
                                        onClick={() =>
                                          this.handleRemoveRow(index)
                                        }
                                        style={{ marginTop: "30px" }}
                                        className="bi bi-trash"
                                      ></Button>
                                    )}
                                  </div>
                                </div>
                              ))}
                              <div className="row">
                                <div className="col">
                                  {" "}
                                  <Form.Group controlId="textBox">
                                    <Form.Label>Group By Column</Form.Label>
                                    <Form.Control
                                      as="select"
                                      className="form-select"
                                      value={this.state.groupbyColumn}
                                      onChange={this.handlegroupbyColumnChange}
                                      name="groupbyColumn"
                                    >
                                      <option value="">Select</option>
                                      {this.state.columns.map((api, index) => (
                                        <option
                                          key={index}
                                          value={api.COLUMN_NAME}
                                        >
                                          {api.COLUMN_NAME}
                                        </option>
                                      ))}
                                    </Form.Control>
                                  </Form.Group>
                                </div>
                                <div className="col">
                                  {" "}
                                  <Form.Group>
                                    <Form.Label>Group By</Form.Label>
                                    {this.state.groupbyColumnType === "date" ? (
                                      <Form.Select
                                        className="form-select"
                                        name="extractMonth"
                                        value={this.state.extractMonth}
                                        onChange={this.handleExactmonthChange}
                                      >
                                        <option value="">Select</option>
                                        <option value="MY">Month & Year</option>
                                        <option value="Y">Year</option>
                                        <option value="M">Month</option>
                                      </Form.Select>
                                    ) : (
                                      <p className="mt-2">
                                        No operators available for this type
                                      </p>
                                    )}
                                  </Form.Group>
                                </div>
                              </div>
                            </Card.Body>
                          </Card>
                        </Col>
                      )}
                    </Row>
                  </Form>
                  <Button
                    variant="info"
                    className="btn btn-info"
                    onClick={this.HandlePreviewsubmitHandler}
                    style={{ marginTop: "5px" }}
                  >
                    Preview Chart
                  </Button>
                  <Button
                    variant="success"
                    className="btn btn-success"
                    style={{ float: "right", marginTop: "5px" }}
                    onClick={this.WidgetslibrarysubmitHandler}
                  >
                    Save Configuration
                  </Button>

                  {/* Display Saved Configurations in a Table */}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
        <div>
          <Row>
            <Col lg="6">{this.renderChartPreview()}</Col>
          </Row>
        </div>
      </div>
    );
  }
}

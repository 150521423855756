import React, { Component } from "react";
import { Button, Container, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";

export default class Widgetslibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          width: "75px",
          selector: (row, i) => row.widgetslibrary_Id,
          sortable: true,
          cell: (row) => <span>{row.widgetslibrary_Id}</span>,
        },

        {
          name: "Widget Name",
          selector: (row, i) => row.widgetName,
          sortable: true,
          cell: (row) => <span>{row.widgetName}</span>,
        },
        {
          name: "Widget Description",
          selector: (row, i) => row.widgetDescription,
          sortable: true,
          cell: (row) => <span>{row.widgetDescription}</span>,
        },

        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                style={{ marginRight: "10px" }}
                variant="primary"
                onClick={this.handleOffcanvasShow}
                className="bi bi-pen"
              ></Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,

      widgetName: "",
      widgetDescription: "",
      dummyOne: "",
      dummyTwo: "",
      dummyThree: "",
      dummyFour: "",
      dummyFive: "",
      dummySix: "",
      dummySeven: "",
      dummyEight: "",
      dummyNine: "",
      dummyTen: "",
    };
  }
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({
      show: true,

      widgetName: "",
      widgetDescription: "",
      dummyOne: "",
      dummyTwo: "",
      dummyThree: "",
      dummyFour: "",
      dummyFive: "",
      dummySix: "",
      dummySeven: "",
      dummyEight: "",
      dummyNine: "",
      dummyTen: "",
    });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  WidgetslibraryeditHandler = (e) => {
    e.preventDefault();
    // const updateFormData = {...editFormData};
    // updateFormData['Widgetslibrary_Id'] = e.target.parentElement.getAttribute("id");
    // setEditFormData(updateFormData);
    // setEdit(true);
  };
  handleEnternewMode = (row) => {
    window.location.href = "/widgetdef";
    // Replace '/profile' with the actual URL for the profile page
  };

  WidgetslibraryfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Widgetslibrary/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  WidgetslibrarysubmitHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("widgetName", this.state.widgetName);
    formData.append("widgetDescription", this.state.widgetDescription);
    formData.append("dummyOne", this.state.dummyOne);
    formData.append("dummyTwo", this.state.dummyTwo);
    formData.append("dummyThree", this.state.dummyThree);
    formData.append("dummyFour", this.state.dummyFour);
    formData.append("dummyFive", this.state.dummyFive);
    formData.append("dummySix", this.state.dummySix);
    formData.append("dummySeven", this.state.dummySeven);
    formData.append("dummyEight", this.state.dummyEight);
    formData.append("dummyNine", this.state.dummyNine);
    formData.append("dummyTen", this.state.dummyTen);

    fetch(configData.api_url + "C_Widgetslibrary/add", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.WidgetslibraryfetchHandler();
          this.handleOffcanvasClose();
          this.setState({
            widgetId: "",
            widgetName: "",
            widgetDescription: "",
            dummyOne: "",
            dummyTwo: "",
            dummyThree: "",
            dummyFour: "",
            dummyFive: "",
            dummySix: "",
            dummySeven: "",
            dummyEight: "",
            dummyNine: "",
            dummyTen: "",
          });
        }
      });
  };
  WidgetslibraryupdateHandler = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("widgetName", this.state.widgetName);
    formData.append("widgetDescription", this.state.widgetDescription);
    formData.append("dummyOne", this.state.dummyOne);
    formData.append("dummyTwo", this.state.dummyTwo);
    formData.append("dummyThree", this.state.dummyThree);
    formData.append("dummyFour", this.state.dummyFour);
    formData.append("dummyFive", this.state.dummyFive);
    formData.append("dummySix", this.state.dummySix);
    formData.append("dummySeven", this.state.dummySeven);
    formData.append("dummyEight", this.state.dummyEight);
    formData.append("dummyNine", this.state.dummyNine);
    formData.append("dummyTen", this.state.dummyTen);

    fetch(configData.api_url + "C_Widgetslibrary/update", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.WidgetslibraryfetchHandler();
          this.handleOffcanvasClose();
        }
      });
  };

  componentDidMount() {
    this.WidgetslibraryfetchHandler();
  }

  handlewidgetNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ widgetName: fieldValue });
  };
  handlewidgetDescriptionChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ widgetDescription: fieldValue });
  };

  handledummyOneChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyOne: fieldValue });
  };
  handledummyTwoChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyTwo: fieldValue });
  };
  handledummyThreeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyThree: fieldValue });
  };
  handledummyFourChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyFour: fieldValue });
  };
  handledummyFiveChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyFive: fieldValue });
  };
  handledummySixChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummySix: fieldValue });
  };
  handledummySevenChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummySeven: fieldValue });
  };
  handledummyEightChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyEight: fieldValue });
  };
  handledummyNineChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyNine: fieldValue });
  };
  handledummyTenChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyTen: fieldValue });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
    console.log(`Editing row with ID ${row.id}`);
    // implement your edit logic here
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("widgetslibrary_Id", row.widgetslibrary_Id);
    fetch(configData.api_url + "C_Widgetslibrary/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.WidgetslibraryfetchHandler();
        }
      });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div className="page-title">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <div>
                      <h3
                        className="logoskybluecolor"
                        style={{ textAlign: "start" }}
                      >
                        Widgets Library
                      </h3>
                    </div>
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "5px", marginTop: "20px" }}
                    >
                      <Offcanvas
                        show={this.state.show}
                        onHide={this.handleOffcanvasClose}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            Widgets Library Page
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                            <Row className="divstyle">
                              <Row>
                                <Form>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Widget Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlewidgetNameChange}
                                        className="form-control"
                                        name="widgetName"
                                        value={this.state.widgetName}
                                        required="required"
                                        placeholder="Enter widgetName"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.widgetName}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Widget Description
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlewidgetDescriptionChange
                                        }
                                        className="form-control"
                                        name="widgetDescription"
                                        value={this.state.widgetDescription}
                                        required="required"
                                        placeholder="Enter widgetDescription"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.widgetDescription}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy One
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyOneChange}
                                        className="form-control"
                                        name="dummyOne"
                                        value={this.state.dummyOne}
                                        required="required"
                                        placeholder="Enter dummyOne"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyOne}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Two
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyTwoChange}
                                        className="form-control"
                                        name="dummyTwo"
                                        value={this.state.dummyTwo}
                                        required="required"
                                        placeholder="Enter dummyTwo"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyTwo}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Three
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyThreeChange}
                                        className="form-control"
                                        name="dummyThree"
                                        value={this.state.dummyThree}
                                        required="required"
                                        placeholder="Enter dummyThree"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyThree}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Four
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyFourChange}
                                        className="form-control"
                                        name="dummyFour"
                                        value={this.state.dummyFour}
                                        required="required"
                                        placeholder="Enter dummyFour"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyFour}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Five
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyFiveChange}
                                        className="form-control"
                                        name="dummyFive"
                                        value={this.state.dummyFive}
                                        required="required"
                                        placeholder="Enter dummyFive"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyFive}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Six
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummySixChange}
                                        className="form-control"
                                        name="dummySix"
                                        value={this.state.dummySix}
                                        required="required"
                                        placeholder="Enter dummySix"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummySix}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Seven
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummySevenChange}
                                        className="form-control"
                                        name="dummySeven"
                                        value={this.state.dummySeven}
                                        required="required"
                                        placeholder="Enter dummySeven"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummySeven}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Eight
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyEightChange}
                                        className="form-control"
                                        name="dummyEight"
                                        value={this.state.dummyEight}
                                        required="required"
                                        placeholder="Enter dummyEight"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyEight}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Nine
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyNineChange}
                                        className="form-control"
                                        name="dummyNine"
                                        value={this.state.dummyNine}
                                        required="required"
                                        placeholder="Enter dummyNine"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyNine}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Ten
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyTenChange}
                                        className="form-control"
                                        name="dummyTen"
                                        value={this.state.dummyTen}
                                        required="required"
                                        placeholder="Enter dummyTen"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyTen}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                </Form>
                              </Row>
                            </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={this.WidgetslibrarysubmitHandler}
                                  style={{
                                    marginTop: "20px",
                                  }}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                      {/*offcanvas is over */}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <div style={{ marginRight: "5px" }}>
                      {/* searchbar,pagination,filter */}
                      <input
                        type="text"
                        style={{ float: "left", marginBottom: "10px" }}
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <Button
                        variant="primary"
                        className="bi bi-plus "
                        style={{ float: "right" }}
                        onClick={this.handleEnternewMode}
                      >
                        New
                      </Button>
                      <Button
                        variant="primary"
                        className="bi bi-plus "
                        style={{ float: "right" }}
                        onClick={this.handleOffcanvasShow}
                      >
                        offcanvas
                      </Button>
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Button, Container, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";

export default class Offices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      errors: {},
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.offices_Id,
          sortable: true,
          cell: (row) => <span>{row.offices_Id}</span>,
        },
        {
          name: "Office Name",
          selector: (row, i) => row.officename,
          sortable: true,
          cell: (row) => <span>{row.officename}</span>,
        },
        {
          name: "Country",
          selector: (row, i) => row.Country,
          sortable: true,
          cell: (row) => <span>{row.Country}</span>,
        },
        {
          name: "State",
          selector: (row, i) => row.state,
          sortable: true,
          cell: (row) => <span>{row.state}</span>,
        },
        {
          name: "City",
          selector: (row, i) => row.city,
          sortable: true,
          cell: (row) => <span>{row.city}</span>,
        },
        {
          name: "Address",
          selector: (row, i) => row.address,
          sortable: true,
          cell: (row) => <span>{row.address}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen"
                style={{ marginRight: "3px" }}
              ></Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      officename: "",
      countries: [],
      Country: "",
      states: [],
      state: "",
      cities: [],
      city: "",
      address: "",
      offices_Id: "",
      isEditing: false, // Flag to indicate edit mode
      isNewEntry: false,
    };
  }
  //edit
  handleEnterEditMode = (row) => {
    // this.setState({ cData: { ...row }, isEditing: true });
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    this.setState({
      officename: row.officename,
      countries: [],
      Country: row.Country,
      states: [],
      state: row.state,
      cities: [],
      city: row.city,
      address: row.address,
      offices_Id: row.offices_Id,
      isEditing: true,
    });
  };
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  OfficeseditHandler = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    // const updateFormData = {...editFormData};
    // updateFormData['Offices_Id'] = e.target.parentElement.getAttribute("id");
    // setEditFormData(updateFormData);
    // setEdit(true);
  };

  OfficesfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Offices/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  validateForm() {
    let data = this.state;
    let errors = {};
    let formIsValid = true;
    if (!data["officename"]) {
      formIsValid = false;
      toast.info("*Please Enter officename.");
    } else if (!data["Country"]) {
      formIsValid = false;
      toast.info("*Please Enter Country.");
    } else if (!data["state"]) {
      formIsValid = false;
      toast.info("*Please Enter State.");
    } else if (!data["city"]) {
      formIsValid = false;
      toast.info("*Please enter city.");
    } else if (!data["address"]) {
      formIsValid = false;
      toast.info("*Please Enter Address.");
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  OfficessubmitHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    if (this.validateForm()) {
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("officename", this.state.officename);
      formData.append("Country", this.state.Country);
      formData.append("state", this.state.state);
      formData.append("city", this.state.city);
      formData.append("address", this.state.address);

      fetch(configData.api_url + "C_Offices/add", {
        // Enter your IP address here

        method: "POST",
        //headers :{ 'Content-Type' : 'application/json'},
        mode: "cors",
        body: formData, // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            this.OfficesfetchHandler();
            this.handleOffcanvasClose();
            this.setState({
              officename: "",
              countries: [],
              Country: "",
              states: [],
              state: "",
              cities: [],
              city: "",
              address: "",
              offices_Id: "",
              isEditing: true,
            });
          }
        });
    }
  };
  OfficesupdateHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);

    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("officename", this.state.officename);
    formData.append("Country", this.state.Country);
    formData.append("state", this.state.state);
    formData.append("city", this.state.city);
    formData.append("address", this.state.address);
    formData.append("offices_Id", this.state.offices_Id);
    fetch(configData.api_url + "C_Offices/update", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.OfficesfetchHandler();
          this.handleOffcanvasClose();
          this.setState({
            officename: "",
            countries: [],
            Country: "",
            states: [],
            state: "",
            cities: [],
            city: "",
            address: "",
            offices_Id: "",
            isEditing: true,
          });
        }
      });
  };

  componentDidMount() {
    this.OfficesfetchHandler();
    this.countriesfetchHandler();
    this.statesfetchHandler();
    this.citiesfetchHandler();
  }
  handleofficenameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ officename: fieldValue });
  };
  handleCountryChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ Country: fieldValue });
  };
  countriesfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Countries/list", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "true") {
          this.setState({ countries: data.list });
        }
      });
  };
  handlestateChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ state: fieldValue });
  };
  statesfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_states/list", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "true") {
          this.setState({ states: data.list });
        }
      });
  };
  handlecityChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ city: fieldValue });
  };
  citiesfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_cities/list", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "true") {
          this.setState({ cities: data.list });
        }
      });
  };
  handleaddressChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ address: fieldValue });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    //console.log(`Editing row with ID ${row.id}`);
    // implement your edit logic here
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("offices_Id", row.offices_Id);
    fetch(configData.api_url + "C_Offices/delete", {
      // Enter your IP address here
      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.OfficesfetchHandler();
        }
      });
    //implement your delete logic here
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div>
        <div>
          <h2>Offices </h2>
        </div>
        <div className="x_panel">
          <Col
            className="d-flex justify-content-end"
            style={{ marginRight: "5px", marginTop: "20px" }}
          >
            <Button
              variant="primary"
              className="bi bi-plus "
              onClick={this.handleOffcanvasShow}
            ></Button>
            <Offcanvas
              show={this.state.show}
              onHide={this.handleOffcanvasClose}
              {...this.props}
              style={{ width: "500px" }}
              placement="end"
              backdrop="true"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>Offices page</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Container style={{ overflowY: "auto", height: "auto" }}>
                  <Row className="divstyle">
                    <Row>
                      <Form>
                        <Row>
                          <Col lg="4" md="4">
                            <Form.Label>Office Name</Form.Label>
                          </Col>
                          <Col lg="8" md="8">
                            <Form.Control
                              type="text"
                              onChange={this.handleofficenameChange}
                              className="form-control"
                              name="officename"
                              value={this.state.officename}
                              required="required"
                              placeholder="Enter officename"
                            />
                          </Col>
                          <div className="errorMsg" style={{ color: "red" }}>
                            {this.state.errors.officename}
                          </div>
                        </Row>
                        <br />
                        <Row>
                          <Col lg="4" md="4">
                            <Form.Label>Country</Form.Label>
                          </Col>
                          <Col lg="8" md="8">
                            <select
                              className="form-select"
                              name="tableName"
                              onChange={this.handleCountryChange}
                            >
                              <option defaultValue> Select Table </option>
                              {this.state.countries.map((api, index, idx) => (
                                <option key={index} value={api.countryname}>
                                  {api.countryname}
                                </option>
                              ))}
                            </select>
                          </Col>
                          <div className="errorMsg" style={{ color: "red" }}>
                            {this.state.errors.Country}
                          </div>
                        </Row>
                        <br />
                        <Row>
                          <Col lg="4" md="4">
                            <Form.Label>State</Form.Label>
                          </Col>
                          <Col lg="8" md="8">
                            <select
                              className="form-select"
                              name="tableName"
                              onChange={this.handlestateChange}
                            >
                              <option defaultValue> Select Table </option>
                              {this.state.states.map((api, index, idx) => (
                                <option key={index} value={api.State}>
                                  {api.State}
                                </option>
                              ))}
                            </select>
                          </Col>
                          <div className="errorMsg" style={{ color: "red" }}>
                            {this.state.errors.state}
                          </div>
                        </Row>
                        <br />
                        <Row>
                          <Col lg="4" md="4">
                            <Form.Label>City</Form.Label>
                          </Col>
                          <Col lg="8" md="8">
                            <select
                              className="form-select"
                              name="tableName"
                              onChange={this.handlecityChange}
                            >
                              <option defaultValue> Select Table </option>
                              {this.state.cities.map((api, index, idx) => (
                                <option key={index} value={api.city}>
                                  {api.city}
                                </option>
                              ))}
                            </select>
                          </Col>
                          <div className="errorMsg" style={{ color: "red" }}>
                            {this.state.errors.city}
                          </div>
                        </Row>
                        <br />
                        <Row>
                          <Col lg="4" md="4">
                            <Form.Label>Address</Form.Label>
                          </Col>
                          <Col lg="8" md="8">
                            <Form.Control
                              type="text"
                              onChange={this.handleaddressChange}
                              className="form-control"
                              name="address"
                              value={this.state.address}
                              required="required"
                              placeholder="Enter address"
                            />
                          </Col>
                          <div className="errorMsg" style={{ color: "red" }}>
                            {this.state.errors.address}
                          </div>
                        </Row>
                        <br />
                      </Form>
                    </Row>
                  </Row>
                  <Row>
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "50px" }}
                    >
                      <Button
                        className="button"
                        variant="success"
                        onClick={this.OfficessubmitHandler}
                        style={{
                          marginTop: "20px",
                        }}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Offcanvas.Body>
            </Offcanvas>
            {/*offcanvas is over */}
          </Col>
          <br />
          <div>
            {this.state.isEditing && (
              // Edit Offcanvas
              <Offcanvas
                show={this.state.isEditing}
                onHide={() => this.setState({ isEditing: false })}
                {...this.props}
                style={{ width: "500px", height: "auto" }}
                placement="end"
                backdrop="true"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Edit Offices</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <div className="form-horizontal form-label-left input_mask">
                    <Form className="form-horizontal form-label-left input_mask">
                      <Row>
                        <Col lg="4" md="4">
                          <Form.Label>Office Name</Form.Label>
                        </Col>
                        <Col lg="8" md="8">
                          <Form.Control
                            type="text"
                            onChange={this.handleofficenameChange}
                            className="form-control"
                            name="officename"
                            value={this.state.officename}
                            required="required"
                            placeholder="Enter officename"
                          />
                        </Col>
                        <div className="errorMsg" style={{ color: "red" }}>
                          {this.state.errors.officename}
                        </div>
                      </Row>
                      <br />
                      <Row>
                        <Col lg="4" md="4">
                          <Form.Label>Country</Form.Label>
                        </Col>
                        <Col lg="8" md="8">
                          <select
                            className="form-select"
                            name="tableName"
                            onChange={this.handleCountryChange}
                          >
                            <option defaultValue> Select Country </option>
                            {this.state.countries.map((api, index, idx) => (
                              <option key={index} value={api.countryname}>
                                {api.countryname}
                              </option>
                            ))}
                          </select>
                        </Col>
                        <div className="errorMsg" style={{ color: "red" }}>
                          {this.state.errors.Country}
                        </div>
                      </Row>
                      <br />
                      <Row>
                        <Col lg="4" md="4">
                          <Form.Label>State</Form.Label>
                        </Col>
                        <Col lg="8" md="8">
                          <select
                            className="form-select"
                            name="tableName"
                            onChange={this.handlestateChange}
                          >
                            <option defaultValue> Select State </option>
                            {this.state.states.map((api, index, idx) => (
                              <option key={index} value={api.State}>
                                {api.State}
                              </option>
                            ))}
                          </select>
                        </Col>
                        <div className="errorMsg" style={{ color: "red" }}>
                          {this.state.errors.state}
                        </div>
                      </Row>
                      <br />
                      <Row>
                        <Col lg="4" md="4">
                          <Form.Label>City</Form.Label>
                        </Col>
                        <Col lg="8" md="8">
                          <select
                            className="form-select"
                            name="tableName"
                            onChange={this.handlecityChange}
                          >
                            <option defaultValue> Select City </option>
                            {this.state.cities.map((api, index, idx) => (
                              <option key={index} value={api.city}>
                                {api.city}
                              </option>
                            ))}
                          </select>
                        </Col>
                        <div className="errorMsg" style={{ color: "red" }}>
                          {this.state.errors.city}
                        </div>
                      </Row>
                      <br />
                      <Row>
                        <Col lg="4" md="4">
                          <Form.Label>Address</Form.Label>
                        </Col>
                        <Col lg="8" md="8">
                          <Form.Control
                            type="text"
                            onChange={this.handleaddressChange}
                            className="form-control"
                            name="address"
                            value={this.state.address}
                            required="required"
                            placeholder="Enter address"
                          />
                        </Col>
                        <div className="errorMsg" style={{ color: "red" }}>
                          {this.state.errors.address}
                        </div>
                      </Row>
                      <br />
                      <div style={{ float: "right" }}>
                        <button
                          className="btn btn-primary me-2 mt-5"
                          style={{
                            backgroundColor: "#23547e",
                            color: "#fff",
                            borderColor: "#2e6da4",
                          }}
                          onClick={this.OfficesupdateHandler}
                        >
                          Update
                        </button>
                      </div>
                    </Form>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            )}
          </div>
          <br />

          <div style={{ marginRight: "5px" }}>
            {/* searchbar,pagination,filter */}
            <input
              type="text"
              onChange={this.handleFilter}
              placeholder="Search..."
              style={{ marginBottom: "10px" }}
            />
            <DataTable
              data={paginatedData}
              columns={this.state.tableClms}
              pagination
              paginationServer
              paginationTotalRows={
                this.state.filteredData
                  ? this.state.filteredData.length
                  : this.state.data.length
              }
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
              paginationPerPage={this.state.itemsPerPage}
              keyField="id"
                paginationRowsPerPageOptions={[10, 20, 30, 50,60,70,80,90, 100]}
              className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
            />
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Image, Button } from "react-bootstrap";
import Apnapolicy from "./Img/Apnapolicy.png";
import LoginPage from "../LoginPage";
import { toast } from "react-toastify";
// import { forgotPassword } from "./LoginComponent";
import CryptoJS from "crypto-js";
import configData from "../config.json";
export default class Forgotpsd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      backCondition: false,
    };
  }
  showToast = (msg, type) => {
    let tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  validatePassword = (password) => {
    const passwordPattern = /^(?=.*[!@#$%^&*])/;
    return passwordPattern.test(password);
  };
  generatePassword = (passwordLength) => {
    // let charset = "";
    let newPassword = "";
    let charset =
      "!@#$%^&*0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";

    for (let i = 0; i < passwordLength; i++) {
      newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    console.log(newPassword);
    if (!this.validatePassword(newPassword)) {
      newPassword = this.generatePassword(8);
    }
    return newPassword;
  };
  onSubmit = async (e) => {
    e.preventDefault();

    const { email } = this.state;
    if (email === "") {
      this.showToast("Enter Email !", "error");
      return;
    }
    if (!this.validateEmail(email)) {
      this.showToast("Invalid Email!", "error");
      return;
    }
    let password = this.generatePassword(8);
    var formData = new FormData();
    formData.append("emailId", email);
    formData.append("password1", password);
    formData.append(
      "password",
      CryptoJS.HmacMD5(password, configData.secret).toString()
    );

    fetch(configData.api_url + "C_Employees/forgotpassword", {
      // Enter your IP address here
      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({
            email: "",
            backCondition: false,
          });
          // this.setState({ data: data.list });
        }
      });
  };
  validateEmail = (email) => {
    var regex = /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  };
  handleBackPasswordClick = () => {
    // Set the state to indicate that the "Forgot Password" button was clicked
    this.setState({ backCondition: true });
  };

  render() {
    const { backCondition } = this.state;
    // Conditionally render the ForgotPsd component based on the condition
    if (backCondition) {
      return <LoginPage />;
    }
    return (
      <div style={{ overflow: "hidden" }}>
        <center className="mt-5">
          <div style={{ textAlign: "center" }}>
            <div className="Auth-form-container">
              <form className="Auth-form">
                <div className="Auth-form-content">
                  <Image
                    src={Apnapolicy}
                    className="navbar-brand pe-3 ps-4 ps-lg-2"
                    alt=""
                    style={{
                      width: "auto",
                      height: "44px",
                      marginBottom: "5px",
                    }}
                  />
                  <h4 style={{ color: "#25335b" }}>Forgot Password</h4>

                  <div className="form-group mt-3">
                    <input
                      className="form-control mt-1"
                      type="text"
                      onChange={(e) => this.setState({ email: e.target.value })}
                      value={this.state.email}
                      placeholder="Email"
                    />
                  </div>

                  <div className="row form-group mt-3 ">
                    <div className="col">
                      <Button
                        onClick={this.handleBackPasswordClick}
                        color="link"
                        variant="light"
                        style={{ float: "left", width: "max-content" }}
                      >
                        Back
                      </Button>
                    </div>
                    <div className="col">
                      <button
                        type="button"
                        onClick={this.onSubmit}
                        className="btn btn-#44986f"
                        style={{
                          width: "100px",
                          border: "0px",
                          color: "#fff",
                          backgroundColor: "#44986f",
                          float: "right",
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </center>
      </div>
    );
  }
}

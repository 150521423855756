import React, { Component } from "react";
import { Button, Container, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";

export default class Chihealthplansintegrated extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.chihealthplansintegrated_Id,
          sortable: true,
          cell: (row) => <span>{row.chihealthplansintegrated_Id}</span>,
        },
        {
          name: "Insurer name",
          selector: (row, i) => row.insurername,
          sortable: true,
          cell: (row) => <span>{row.insurername}</span>,
        },
        {
          name: "insurer logo",
          selector: (row, i) => row.insurerlogo,
          sortable: true,
          cell: (row) => <span>{row.insurerlogo}</span>,
        },
        {
          name: "product name",
          selector: (row, i) => row.productname,
          sortable: true,
          cell: (row) => <span>{row.productname}</span>,
        },
        {
          name: "product code",
          selector: (row, i) => row.productcode,
          sortable: true,
          cell: (row) => <span>{row.productcode}</span>,
        },
        {
          name: "Base product Id",
          selector: (row, i) => row.baseproductId,
          sortable: true,
          cell: (row) => <span>{row.baseproductId}</span>,
        },
        {
          name: "sum insured",
          selector: (row, i) => row.suminsured,
          sortable: true,
          cell: (row) => <span>{row.suminsured}</span>,
        },
        {
          name: "si code",
          selector: (row, i) => row.sicode,
          sortable: true,
          cell: (row) => <span>{row.sicode}</span>,
        },
        {
          name: "premium api security key",
          selector: (row, i) => row.premiumapisecuritykey,
          sortable: true,
          cell: (row) => <span>{row.premiumapisecuritykey}</span>,
        },
        {
          name: "auth security key",
          selector: (row, i) => row.auth_secret,
          sortable: true,
          cell: (row) => <span>{row.auth_secret}</span>,
        },
        {
          name: "partner id",
          selector: (row, i) => row.partnerid,
          sortable: true,
          cell: (row) => <span>{row.partnerid}</span>,
        },

        {
          name: "Cover type",
          selector: (row, i) => row.covertype,
          sortable: true,
          cell: (row) => <span>{row.covertype}</span>,
        },

        {
          name: "tenure",
          selector: (row, i) => row.tenure,
          sortable: true,
          cell: (row) => <span>{row.tenure}</span>,
        },
        {
          name: "plan type",
          selector: (row, i) => row.plantype,
          sortable: true,
          cell: (row) => <span>{row.plantype}</span>,
        },
        {
          name: "Customer Type",
          selector: (row, i) => row.customertype,
          sortable: true,
          cell: (row) => <span>{row.customertype}</span>,
        },

        {
          name: "apikeyfortokens",
          selector: (row, i) => row.apikeyfortokens,
          sortable: true,
          cell: (row) => <span>{row.apikeyfortokens}</span>,
        },
        {
          name: "security key for tokens",
          selector: (row, i) => row.securitykeyfortokens,
          sortable: true,
          cell: (row) => <span>{row.securitykeyfortokens}</span>,
        },
        {
          name: "Zone",
          selector: (row, i) => row.zone,
          sortable: true,
          cell: (row) => <span>{row.zone}</span>,
        },
        {
          name: "Min Age",
          selector: (row, i) => row.minAge,
          sortable: true,
          cell: (row) => <span>{row.minAge}</span>,
        },
        {
          name: "Max Age",
          selector: (row, i) => row.maxAge,
          sortable: true,
          cell: (row) => <span>{row.maxAge}</span>,
        },
        {
          name: "Eldest Min Age",
          selector: (row, i) => row.eldestminAge,
          sortable: true,
          cell: (row) => <span>{row.eldestminAge}</span>,
        },
        {
          name: "Family DefsAccepted",
          selector: (row, i) => row.familyDefsAccepted,
          sortable: true,
          cell: (row) => <span>{row.familyDefsAccepted}</span>,
        },
        {
          name: "Max Adult",
          selector: (row, i) => row.maxAdult,
          sortable: true,
          cell: (row) => <span>{row.maxAdult}</span>,
        },
        {
          name: "Max Child",
          selector: (row, i) => row.maxChild,
          sortable: true,
          cell: (row) => <span>{row.maxChild}</span>,
        },
        {
          name: "Min Child",
          selector: (row, i) => row.minChild,
          sortable: true,
          cell: (row) => <span>{row.minChild}</span>,
        },
        {
          name: "Min Adult",
          selector: (row, i) => row.minAdult,
          sortable: true,
          cell: (row) => <span>{row.minAdult}</span>,
        },
        {
          name: "dummyOne",
          selector: (row, i) => row.dummyOne,
          sortable: true,
          cell: (row) => <span>{row.dummyOne}</span>,
        },
        {
          name: "dummy Two",
          selector: (row, i) => row.dummyTwo,
          sortable: true,
          cell: (row) => <span>{row.dummyTwo}</span>,
        },

        {
          name: "dummy Three",
          selector: (row, i) => row.dummyThree,
          sortable: true,
          cell: (row) => <span>{row.dummyThree}</span>,
        },

        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen"
                style={{ marginRight: "3px" }}
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 30,
      chihealthplansintegrated_Id: "",
      insurername: "",
      insurerlogo: "",
      productname: "",
      productcode: "",
      suminsured: "",
      sicode: "",
      premiumapisecuritykey: "",
      partnerid: "",
      tenure: "",
      plantype: "",
      covertype: "",
      customertype: "",
      apikeyfortokens: "",
      baseproductId: "",
      securitykeyfortokens: "",
      zone: "",
      minAge: "",
      maxAge: "",
      eldestminAge: "",
      familyDefsAccepted: "",
      maxAdult: "",
      maxChild: "",
      minChild: "",
      minAdult: "",
      dummyOne: "",
      dummyTwo: "",
      dummyThree: "",
      isEditing: false, // Flag to indicate edit mode
      isNewEntry: false,
    };
  }
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({
      show: true,
      chihealthplansintegrated_Id: "",
      insurername: "",
      insurerlogo: "",
      productname: "",
      productcode: "",
      suminsured: "",
      sicode: "",
      premiumapisecuritykey: "",
      partnerid: "",
      tenure: "",
      plantype: "",
      covertype: "",
      customertype: "",
      apikeyfortokens: "",
      baseproductId: "",
      securitykeyfortokens: "",
      zone: "",
      minAge: "",
      maxAge: "",
      eldestminAge: "",
      familyDefsAccepted: "",
      maxAdult: "",
      maxChild: "",
      minChild: "",
      minAdult: "",
      dummyOne: "",
      dummyTwo: "",
      dummyThree: "",
    });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  ChihealthplansintegratededitHandler = (e) => {
    e.preventDefault();
    // const updateFormData = {...editFormData};
    // updateFormData['Chihealthplansintegrated_Id'] = e.target.parentElement.getAttribute("id");
    // setEditFormData(updateFormData);
    // setEdit(true);
  };
  handleEnterEditMode = (row) => {
    // this.setState({ cData: { ...row }, isEditing: true });
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Chihealthplansintegrated/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({
            Department: row.Department,
            chihealthplansintegrated_Id: row.chihealthplansintegrated_Id,
            insurername: row.insurername,
            insurerlogo: row.insurerlogo,
            productname: row.productname,
            productcode: row.productcode,
            suminsured: row.suminsured,
            sicode: row.sicode,
            premiumapisecuritykey: row.premiumapisecuritykey,
            partnerid: row.partnerid,
            tenure: row.tenure,
            plantype: row.plantype,
            covertype: row.covertype,
            customertype: row.customertype,
            apikeyfortokens: row.apikeyfortokens,
            baseproductId: row.baseproductId,
            securitykeyfortokens: row.securitykeyfortokens,
            zone: row.zone,
            minAge: row.minAge,
            maxAge: row.maxAge,
            eldestminAge: row.eldestminAge,
            familyDefsAccepted: row.familyDefsAccepted,
            maxAdult: row.maxAdult,
            maxChild: row.maxChild,
            minChild: row.minChild,
            minAdult: row.minAdult,
            dummyOne: row.dummyOne,
            dummyTwo: row.dummyTwo,
            dummyThree: row.dummyThree,
            isEditing: true,
          });
        }
      });
  };

  ChihealthplansintegratedfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Chihealthplansintegrated/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  ChihealthplansintegratedsubmitHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("insurername", this.state.insurername);
    formData.append("insurerlogo", this.state.insurerlogo);
    formData.append("productname", this.state.productname);
    formData.append("productcode", this.state.productcode);
    formData.append("suminsured", this.state.suminsured);
    formData.append("sicode", this.state.sicode);
    formData.append("premiumapisecuritykey", this.state.premiumapisecuritykey);
    formData.append("partnerid", this.state.partnerid);
    formData.append("tenure", this.state.tenure);
    formData.append("plantype", this.state.plantype);
    formData.append("covertype", this.state.covertype);
    formData.append("customertype", this.state.customertype);
    formData.append("baseproductId", this.state.baseproductId);
    formData.append("securitykeyfortokens", this.state.securitykeyfortokens);
    formData.append("zone", this.state.zone);
    formData.append("minAge", this.state.minAge);
    formData.append("maxAge", this.state.maxAge);
    formData.append("eldestminAge", this.state.eldestminAge);
    formData.append("familyDefsAccepted", this.state.familyDefsAccepted);
    formData.append("maxAdult", this.state.maxAdult);
    formData.append("maxChild", this.state.maxChild);
    formData.append("minChild", this.state.minChild);
    formData.append("minAdult", this.state.minAdult);
    formData.append("dummyOne", this.state.dummyOne);
    formData.append("dummyTwo", this.state.dummyTwo);
    formData.append("dummyThree", this.state.dummyThree);

    fetch(configData.api_url + "C_Chihealthplansintegrated/add", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.ChihealthplansintegratedfetchHandler();
          this.handleOffcanvasClose();
          this.setState({
            insurername: "",
            insurerlogo: "",
            productname: "",
            productcode: "",
            suminsured: "",
            sicode: "",
            premiumapisecuritykey: "",
            partnerid: "",
            tenure: "",
            plantype: "",
            covertype: "",
            customertype: "",
            baseproductId: "",
            securitykeyfortokens: "",
            apikeyfortokens: "",
            zone: "",
            minAge: "",
            maxAge: "",
            eldestminAge: "",
            familyDefsAccepted: "",
            maxAdult: "",
            maxChild: "",
            minChild: "",
            minAdult: "",
            dummyOne: "",
            dummyTwo: "",
          });
        }
      });
  };
  ChihealthplansintegratedupdateHandler = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append(
      "chihealthplansintegrated_Id",
      this.state.chihealthplansintegrated_Id
    );
    formData.append("insurername", this.state.insurername);
    formData.append("insurerlogo", this.state.insurerlogo);
    formData.append("productname", this.state.productname);
    formData.append("productcode", this.state.productcode);
    formData.append("suminsured", this.state.suminsured);
    formData.append("sicode", this.state.sicode);
    formData.append("premiumapisecuritykey", this.state.premiumapisecuritykey);
    formData.append("apikeyfortokens", this.state.apikeyfortokens);
    formData.append("partnerid", this.state.partnerid);
    formData.append("tenure", this.state.tenure);
    formData.append("plantype", this.state.plantype);
    formData.append("covertype", this.state.covertype);
    formData.append("customertype", this.state.customertype);
    formData.append("baseproductId", this.state.baseproductId);
    formData.append("securitykeyfortokens", this.state.securitykeyfortokens);
    formData.append("zone", this.state.zone);
    formData.append("minAge", this.state.minAge);
    formData.append("maxAge", this.state.maxAge);
    formData.append("eldestminAge", this.state.eldestminAge);
    formData.append("familyDefsAccepted", this.state.familyDefsAccepted);
    formData.append("maxAdult", this.state.maxAdult);
    formData.append("maxChild", this.state.maxChild);
    formData.append("minChild", this.state.minChild);
    formData.append("minAdult", this.state.minAdult);
    formData.append("dummyOne", this.state.dummyOne);
    formData.append("dummyTwo", this.state.dummyTwo);
    formData.append("dummyThree", this.state.dummyThree);

    fetch(configData.api_url + "C_Chihealthplansintegrated/update", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.ChihealthplansintegratedfetchHandler();
          this.handleOffcanvasClose();
        }
      });
  };

  componentDidMount() {
    this.ChihealthplansintegratedfetchHandler();
  }
  handleinsurernameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ insurername: fieldValue });
  };
  handleinsurerlogoChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ insurerlogo: fieldValue });
  };
  handleproductnameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ productname: fieldValue });
  };
  handleproductcodeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ productcode: fieldValue });
  };

  handlesuminsuredChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ suminsured: fieldValue });
  };
  handlesicodeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ sicode: fieldValue });
  };
  handlepremiumapisecuritykeyChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ premiumapisecuritykey: fieldValue });
  };
  handlepartneridChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ partnerid: fieldValue });
  };

  handletenureChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ tenure: fieldValue });
  };
  handleplantypeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ plantype: fieldValue });
  };
  handlecovertypeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ covertype: fieldValue });
  };
  handlecustomertypeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ customertype: fieldValue });
  };
  handlebaseproductIdChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ baseproductId: fieldValue });
  };
  handleapikeyfortokensChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ apikeyfortokens: fieldValue });
  };
  handlesecuritykeyfortokensChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ securitykeyfortokens: fieldValue });
  };

  handlezoneChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ zone: fieldValue });
  };
  handleminAgeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ minAge: fieldValue });
  };
  handlenMaxAgeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ maxAge: fieldValue });
  };
  handleneldestminAgeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ eldestminAge: fieldValue });
  };
  handlefamilyDefsAcceptedChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ familyDefsAccepted: fieldValue });
  };
  handlemaxAdultChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ maxAdult: fieldValue });
  };
  handlemaxChildChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ maxChild: fieldValue });
  };
  handleminChildChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ minChild: fieldValue });
  };
  handleminAdultChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ minAdult: fieldValue });
  };
  handledummyOneChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyOne: fieldValue });
  };
  handledummyTwoChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyTwo: fieldValue });
  };
  handledummyThreeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyTwo: fieldValue });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
    console.log(`Editing row with ID ${row.id}`);
    // implement your edit logic here
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append(
      "chihealthplansintegrated_Id",
      row.chihealthplansintegrated_Id
    );
    fetch(configData.api_url + "C_Chihealthplansintegrated/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.ChihealthplansintegratedfetchHandler();
        }
      });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div className="page-title">
        <div className="row">
          <div className="title_left">
            <h2 style={{ textAlign: "start" }}>CHI Health Plans Integrated</h2>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                                   dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "5px", marginTop: "20px" }}
                    >
                      <Button
                        variant="primary"
                        className="bi bi-plus "
                        onClick={this.handleOffcanvasShow}
                      >
                        New
                      </Button>
                      <Offcanvas
                        show={this.state.show}
                        onHide={this.handleOffcanvasClose}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            Chihealthplansintegrated page
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                            <Row className="divstyle">
                              <Row>
                                <Form>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Insurer name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleinsurernameChange}
                                        className="form-control"
                                        name="insurername"
                                        value={this.state.insurername}
                                        required="required"
                                        placeholder="Enter insurername"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.insurername}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        insurer logo
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="file"
                                        onChange={this.handleinsurerlogoChange}
                                        className="form-control"
                                        name="insurerlogo"
                                        value={this.state.insurerlogo}
                                        required="required"
                                        placeholder="Enter insurerlogo"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.insurerlogo}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        product name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleproductnameChange}
                                        className="form-control"
                                        name="productname"
                                        value={this.state.productname}
                                        required="required"
                                        placeholder="Enter productname"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.productname}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        product code
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleproductcodeChange}
                                        className="form-control"
                                        name="productcode"
                                        value={this.state.productcode}
                                        required="required"
                                        placeholder="Enter productcode"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.productcode}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Base product Id
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlebaseproductIdChange
                                        }
                                        className="form-control"
                                        name="baseproductId"
                                        value={this.state.baseproductId}
                                        required="required"
                                        placeholder="Enter baseproductId"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.baseproductId}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        sum insured
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        onChange={this.handlesuminsuredChange}
                                        className="form-control"
                                        name="suminsured"
                                        value={this.state.suminsured}
                                        required="required"
                                        placeholder="Enter suminsured"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.suminsured}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        si code
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        onChange={this.handlesicodeChange}
                                        className="form-control"
                                        name="sicode"
                                        value={this.state.sicode}
                                        required="required"
                                        placeholder="Enter sicode"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.sicode}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        auth security key
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlecovertypeChange}
                                        className="form-control"
                                        name="covertype"
                                        value={this.state.covertype}
                                        required="required"
                                        placeholder="Enter covertype"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.covertype}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        premium api security key
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlepremiumapisecuritykeyChange
                                        }
                                        className="form-control"
                                        name="premiumapisecuritykey"
                                        value={this.state.premiumapisecuritykey}
                                        required="required"
                                        placeholder="Enter premiumapisecuritykey"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.premiumapisecuritykey
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        partner id
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlepartneridChange}
                                        className="form-control"
                                        name="partnerid"
                                        value={this.state.partnerid}
                                        required="required"
                                        placeholder="Enter partnerid"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.partnerid}{" "}
                                    </div>
                                  </Row>

                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        tenure
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handletenureChange}
                                        className="form-control"
                                        name="tenure"
                                        value={this.state.tenure}
                                        required="required"
                                        placeholder="Enter tenure"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.tenure}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        plan type
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleplantypeChange}
                                        className="form-control"
                                        name="plantype"
                                        value={this.state.plantype}
                                        required="required"
                                        placeholder="Enter plantype"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.plantype}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        customer type
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlecustomertypeChange}
                                        className="form-control"
                                        name="customertype"
                                        value={this.state.customertype}
                                        required="required"
                                        placeholder="Enter customertype"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.customertype}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        partner id for tokens
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlebaseproductIdChange
                                        }
                                        className="form-control"
                                        name="baseproductId"
                                        value={this.state.baseproductId}
                                        required="required"
                                        placeholder="Enter baseproductId"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.baseproductId}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Api key for tokens
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleapikeyfortokensChange
                                        }
                                        className="form-control"
                                        name="apikeyfortokens"
                                        value={this.state.apikeyfortokens}
                                        required="required"
                                        placeholder="Enter  Api key for tokens"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.apikeyfortokens}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        security key for tokens
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlesecuritykeyfortokensChange
                                        }
                                        className="form-control"
                                        name="securitykeyfortokens"
                                        value={this.state.securitykeyfortokens}
                                        required="required"
                                        placeholder="Enter securitykeyfortokens"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.securitykeyfortokens
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Customer Type
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlecustomertypeChange}
                                        className="form-control"
                                        name="customertype"
                                        value={this.state.customertype}
                                        required="required"
                                        placeholder="Enter customertype"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.customertype}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        zone
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlezoneChange}
                                        className="form-control"
                                        name="zone"
                                        value={this.state.zone}
                                        required="required"
                                        placeholder="Enter zone"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.zone}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Min Age
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleminAgeChange}
                                        className="form-control"
                                        name="minAge"
                                        value={this.state.minAge}
                                        required="required"
                                        placeholder="Enter minAge"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.minAge}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Max Age
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlenMaxAgeChange}
                                        className="form-control"
                                        name="maxAge"
                                        value={this.state.maxAge}
                                        required="required"
                                        placeholder="Enter maxAge"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.maxAge}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Eldest Min Age
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleneldestminAgeChange
                                        }
                                        className="form-control"
                                        name="eldestminAge"
                                        value={this.state.eldestminAge}
                                        required="required"
                                        placeholder="Enter eldestminAge"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.eldestminAge}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Family DefsAccepted
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlefamilyDefsAcceptedChange
                                        }
                                        className="form-control"
                                        name="familyDefsAccepted"
                                        value={this.state.familyDefsAccepted}
                                        required="required"
                                        placeholder="Enter familyDefsAccepted"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.familyDefsAccepted
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Max Adult
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlemaxAdultChange}
                                        className="form-control"
                                        name="maxAdult"
                                        value={this.state.maxAdult}
                                        required="required"
                                        placeholder="Enter maxAdult"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.maxAdult}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Max Child
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlemaxChildChange}
                                        className="form-control"
                                        name="maxChild"
                                        value={this.state.maxChild}
                                        required="required"
                                        placeholder="Enter maxChild"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.maxChild}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Min Child
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleminChildChange}
                                        className="form-control"
                                        name="minChild"
                                        value={this.state.minChild}
                                        required="required"
                                        placeholder="Enter minChild"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.minChild}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Min Adult
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleminAdultChange}
                                        className="form-control"
                                        name="minAdult"
                                        value={this.state.minAdult}
                                        required="required"
                                        placeholder="Enter minAdult"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.minAdult}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        dummyOne
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyOneChange}
                                        className="form-control"
                                        name="dummyOne"
                                        value={this.state.dummyOne}
                                        required="required"
                                        placeholder="Enter dummyOne"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyOne}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        dummy Two
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyTwoChange}
                                        className="form-control"
                                        name="dummyTwo"
                                        value={this.state.dummyTwo}
                                        required="required"
                                        placeholder="Enter dummyTwo"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyTwo}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        dummy Three
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyTwoChange}
                                        className="form-control"
                                        name="dummyThree"
                                        value={this.state.dummyThree}
                                        required="required"
                                        placeholder="Enter dummyThree"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyThree}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                </Form>
                              </Row>
                            </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={
                                    this.ChihealthplansintegratedsubmitHandler
                                  }
                                  style={{
                                    marginTop: "20px",
                                  }}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                      {/*offcanvas is over */}
                    </Col>
                  </Row>
                  <br />
                  <div>
                    {""}
                    {this.state.isEditing && (
                      // edit offcanvas
                      <Offcanvas
                        show={this.state.isEditing}
                        onHide={() => this.setState({ isEditing: false })}
                        {...this.props}
                        style={{ width: "700px", height: "auto" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            Edit CHI Health Plans Integrated
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container
                            className="form-horizontal form-label-left input_mask"
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                            <Row className="divstyle">
                              <Row>
                                <Form>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Insurer name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleinsurernameChange}
                                        className="form-control"
                                        name="insurername"
                                        value={this.state.insurername}
                                        required="required"
                                        placeholder="Enter insurername"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.insurername}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        insurer logo
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="file"
                                        onChange={this.handleinsurerlogoChange}
                                        className="form-control"
                                        name="insurerlogo"
                                        // value={this.state.insurerlogo}
                                        required="required"
                                        placeholder="Enter insurerlogo"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.insurerlogo}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        product name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleproductnameChange}
                                        className="form-control"
                                        name="productname"
                                        value={this.state.productname}
                                        required="required"
                                        placeholder="Enter productname"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.productname}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        product code
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleproductcodeChange}
                                        className="form-control"
                                        name="productcode"
                                        value={this.state.productcode}
                                        required="required"
                                        placeholder="Enter productcode"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.productcode}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Base product Id
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlebaseproductIdChange
                                        }
                                        className="form-control"
                                        name="baseproductId"
                                        value={this.state.baseproductId}
                                        required="required"
                                        placeholder="Enter baseproductId"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.baseproductId}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        sum insured
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        onChange={this.handlesuminsuredChange}
                                        className="form-control"
                                        name="suminsured"
                                        value={this.state.suminsured}
                                        required="required"
                                        placeholder="Enter suminsured"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.suminsured}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        si code
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        onChange={this.handlesicodeChange}
                                        className="form-control"
                                        name="sicode"
                                        value={this.state.sicode}
                                        required="required"
                                        placeholder="Enter sicode"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.sicode}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        auth security key
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlecovertypeChange}
                                        className="form-control"
                                        name="covertype"
                                        value={this.state.covertype}
                                        required="required"
                                        placeholder="Enter covertype"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.covertype}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        premium api security key
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlepremiumapisecuritykeyChange
                                        }
                                        className="form-control"
                                        name="premiumapisecuritykey"
                                        value={this.state.premiumapisecuritykey}
                                        required="required"
                                        placeholder="Enter premiumapisecuritykey"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.premiumapisecuritykey
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        partner id
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlepartneridChange}
                                        className="form-control"
                                        name="partnerid"
                                        value={this.state.partnerid}
                                        required="required"
                                        placeholder="Enter partnerid"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.partnerid}{" "}
                                    </div>
                                  </Row>

                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        tenure
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handletenureChange}
                                        className="form-control"
                                        name="tenure"
                                        value={this.state.tenure}
                                        required="required"
                                        placeholder="Enter tenure"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.tenure}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        plan type
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleplantypeChange}
                                        className="form-control"
                                        name="plantype"
                                        value={this.state.plantype}
                                        required="required"
                                        placeholder="Enter plantype"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.plantype}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        customer type
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlecustomertypeChange}
                                        className="form-control"
                                        name="customertype"
                                        value={this.state.customertype}
                                        required="required"
                                        placeholder="Enter customertype"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.customertype}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        partner id for tokens
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlebaseproductIdChange
                                        }
                                        className="form-control"
                                        name="baseproductId"
                                        value={this.state.baseproductId}
                                        required="required"
                                        placeholder="Enter baseproductId"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.baseproductId}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Api key for tokens
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleapikeyfortokensChange
                                        }
                                        className="form-control"
                                        name="apikeyfortokens"
                                        value={this.state.apikeyfortokens}
                                        required="required"
                                        placeholder="Enter  Api key for tokens"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.apikeyfortokens}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        security key for tokens
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlesecuritykeyfortokensChange
                                        }
                                        className="form-control"
                                        name="securitykeyfortokens"
                                        value={this.state.securitykeyfortokens}
                                        required="required"
                                        placeholder="Enter securitykeyfortokens"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.securitykeyfortokens
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Customer Type
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlecustomertypeChange}
                                        className="form-control"
                                        name="customertype"
                                        value={this.state.customertype}
                                        required="required"
                                        placeholder="Enter customertype"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.customertype}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        zone
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlezoneChange}
                                        className="form-control"
                                        name="zone"
                                        value={this.state.zone}
                                        required="required"
                                        placeholder="Enter zone"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.zone}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Min Age
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleminAgeChange}
                                        className="form-control"
                                        name="minAge"
                                        value={this.state.minAge}
                                        required="required"
                                        placeholder="Enter minAge"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.minAge}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Max Age
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlenMaxAgeChange}
                                        className="form-control"
                                        name="maxAge"
                                        value={this.state.maxAge}
                                        required="required"
                                        placeholder="Enter maxAge"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.maxAge}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Eldest Min Age
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleneldestminAgeChange
                                        }
                                        className="form-control"
                                        name="eldestminAge"
                                        value={this.state.eldestminAge}
                                        required="required"
                                        placeholder="Enter eldestminAge"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.eldestminAge}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Family DefsAccepted
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlefamilyDefsAcceptedChange
                                        }
                                        className="form-control"
                                        name="familyDefsAccepted"
                                        value={this.state.familyDefsAccepted}
                                        required="required"
                                        placeholder="Enter familyDefsAccepted"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.familyDefsAccepted
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Max Adult
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlemaxAdultChange}
                                        className="form-control"
                                        name="maxAdult"
                                        value={this.state.maxAdult}
                                        required="required"
                                        placeholder="Enter maxAdult"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.maxAdult}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Max Child
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlemaxChildChange}
                                        className="form-control"
                                        name="maxChild"
                                        value={this.state.maxChild}
                                        required="required"
                                        placeholder="Enter maxChild"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.maxChild}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Min Child
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleminChildChange}
                                        className="form-control"
                                        name="minChild"
                                        value={this.state.minChild}
                                        required="required"
                                        placeholder="Enter minChild"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.minChild}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Min Adult
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleminAdultChange}
                                        className="form-control"
                                        name="minAdult"
                                        value={this.state.minAdult}
                                        required="required"
                                        placeholder="Enter minAdult"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.minAdult}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        dummyOne
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyOneChange}
                                        className="form-control"
                                        name="dummyOne"
                                        value={this.state.dummyOne}
                                        required="required"
                                        placeholder="Enter dummyOne"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyOne}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        dummy Two
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyTwoChange}
                                        className="form-control"
                                        name="dummyTwo"
                                        value={this.state.dummyTwo}
                                        required="required"
                                        placeholder="Enter dummyTwo"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyTwo}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        dummy Three
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyTwoChange}
                                        className="form-control"
                                        name="dummyThree"
                                        value={this.state.dummyThree}
                                        required="required"
                                        placeholder="Enter dummyThree"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyThree}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                </Form>
                              </Row>
                            </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={
                                    this.ChihealthplansintegratedupdateHandler
                                  }
                                  style={{
                                    marginTop: "20px",
                                  }}
                                >
                                  Update
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                    )}
                  </div>
                  <br />
                  <Row>
                    <div style={{ marginRight: "5px" }}>
                      {/* searchbar,pagination,filter */}
                      <input
                        type="text"
                        style={{ float: "left", marginBottom: "10px" }}
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        paginationRowsPerPageOptions={[
                          10, 20, 30, 50, 60, 70, 80, 90, 100,
                        ]}
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

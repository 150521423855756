import React, { Component } from "react";
import { Button, Container, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";

export default class Healthproducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.healthproducts_Id,
          sortable: true,
          cell: (row) => <span>{row.healthproducts_Id}</span>,
        },
        {
          name: "insurername",
          selector: (row, i) => row.insurername,
          sortable: true,
          cell: (row) => <span>{row.insurername}</span>,
        },
        {
          name: "insurer logo",
          selector: (row, i) => row.insurerlogo,
          sortable: true,
          cell: (row) => <span>{row.insurerlogo}</span>,
        },
        {
          name: "product name",
          selector: (row, i) => row.productname,
          sortable: true,
          cell: (row) => <span>{row.productname}</span>,
        },
        {
          name: "product code",
          selector: (row, i) => row.productcode,
          sortable: true,
          cell: (row) => <span>{row.productcode}</span>,
        },
        {
          name: "product id",
          selector: (row, i) => row.productid,
          sortable: true,
          cell: (row) => <span>{row.productid}</span>,
        },
        {
          name: "api key",
          selector: (row, i) => row.apikey,
          sortable: true,
          cell: (row) => <span>{row.apikey}</span>,
        },
        {
          name: "auth secret",
          selector: (row, i) => row.authsecret,
          sortable: true,
          cell: (row) => <span>{row.authsecret}</span>,
        },
        {
          name: "partner id",
          selector: (row, i) => row.partnerid,
          sortable: true,
          cell: (row) => <span>{row.partnerid}</span>,
        },
        {
          name: "app id",
          selector: (row, i) => row.appid,
          sortable: true,
          cell: (row) => <span>{row.appid}</span>,
        },
        {
          name: "security key",
          selector: (row, i) => row.securitykey,
          sortable: true,
          cell: (row) => <span>{row.securitykey}</span>,
        },
        {
          name: "dummy one",
          selector: (row, i) => row.dummyone,
          sortable: true,
          cell: (row) => <span>{row.dummyone}</span>,
        },
        {
          name: "dummy two",
          selector: (row, i) => row.dummytwo,
          sortable: true,
          cell: (row) => <span>{row.dummytwo}</span>,
        },
        {
          name: "dummy three",
          selector: (row, i) => row.dummythree,
          sortable: true,
          cell: (row) => <span>{row.dummythree}</span>,
        },
        {
          name: "plan type",
          selector: (row, i) => row.plantype,
          sortable: true,
          cell: (row) => <span>{row.plantype}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen"
                style={{ marginRight: "3px" }}
              ></Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      insurername: "",
      insurerlogo: "",
      productname: "",
      productcode: "",
      productid: "",
      apikey: "",
      authsecret: "",
      partnerid: "",
      appid: "",
      securitykey: "",
      dummyone: "",
      dummytwo: "",
      dummythree: "",
      plantype: "",
      healthproducts_Id: "",
      isEditing: false, // Flag to indicate edit mode
      isNewEntry: false,
    };
  }
  //edit
  handleEnterEditMode = (row) => {
    // this.setState({ cData: { ...row }, isEditing: true });
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    this.setState({
      insurername: row.insurername,
      insurerlogo: row.insurerlogo,
      productname: row.productname,
      productcode: row.productcode,
      productid: row.productid,
      apikey: row.apikey,
      authsecret: row.authsecret,
      partnerid: row.partnerid,
      appid: row.appid,
      securitykey: row.securitykey,
      dummyone: row.dummyone,
      dummytwo: row.dummytwo,
      dummythree: row.dummythree,
      plantype: row.plantype,
      healthproducts_Id: row.healthproducts_Id,
      isEditing: true,
    });
  };
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({
      show: true,
      insurername: "",
      insurerlogo: "",
      productname: "",
      productcode: "",
      productid: "",
      apikey: "",
      authsecret: "",
      partnerid: "",
      appid: "",
      securitykey: "",
      dummyone: "",
      dummytwo: "",
      dummythree: "",
      plantype: "",
      healthproducts_Id: "",
    });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  HealthproductseditHandler = (e) => {
    e.preventDefault();
    // const updateFormData = {...editFormData};
    // updateFormData['Healthproducts_Id'] = e.target.parentElement.getAttribute("id");
    // setEditFormData(updateFormData);
    // setEdit(true);
  };

  HealthproductsfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Healthproducts/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  HealthproductssubmitHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("insurername", this.state.insurername);
    formData.append("insurerlogo", this.state.insurerlogo);
    formData.append("productname", this.state.productname);
    formData.append("productcode", this.state.productcode);
    formData.append("productid", this.state.productid);
    formData.append("apikey", this.state.apikey);
    formData.append("authsecret", this.state.authsecret);
    formData.append("partnerid", this.state.partnerid);
    formData.append("appid", this.state.appid);
    formData.append("securitykey", this.state.securitykey);
    formData.append("dummyone", this.state.dummyone);
    formData.append("dummytwo", this.state.dummytwo);
    formData.append("dummythree", this.state.dummythree);
    formData.append("plantype", this.state.plantype);

    fetch(configData.api_url + "C_Healthproducts/add", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.HealthproductsfetchHandler();
          this.handleOffcanvasClose();
          this.setState({
            insurername: "",
            insurerlogo: "",
            productname: "",
            productcode: "",
            productid: "",
            apikey: "",
            authsecret: "",
            partnerid: "",
            appid: "",
            securitykey: "",
            dummyone: "",
            dummytwo: "",
            dummythree: "",
            plantype: "",
          });
        }
      });
  };
  HealthproductsupdateHandler = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("insurername", this.state.insurername);
    formData.append("insurerlogo", this.state.insurerlogo);
    formData.append("productname", this.state.productname);
    formData.append("productcode", this.state.productcode);
    formData.append("productid", this.state.productid);
    formData.append("apikey", this.state.apikey);
    formData.append("authsecret", this.state.authsecret);
    formData.append("partnerid", this.state.partnerid);
    formData.append("appid", this.state.appid);
    formData.append("securitykey", this.state.securitykey);
    formData.append("dummyone", this.state.dummyone);
    formData.append("dummytwo", this.state.dummytwo);
    formData.append("dummythree", this.state.dummythree);
    formData.append("plantype", this.state.plantype);
    formData.append("healthproducts_Id", this.state.healthproducts_Id);

    fetch(configData.api_url + "C_Healthproducts/update", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.HealthproductsfetchHandler();
          this.handleOffcanvasClose();
          this.setState({
            insurername: "",
            insurerlogo: "",
            productname: "",
            productcode: "",
            productid: "",
            apikey: "",
            authsecret: "",
            partnerid: "",
            appid: "",
            securitykey: "",
            dummyone: "",
            dummytwo: "",
            dummythree: "",
            plantype: "",
          });
        }
      });
  };

  componentDidMount() {
    this.HealthproductsfetchHandler();
  }
  handleinsurernameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ insurername: fieldValue });
  };
  handleinsurerlogoChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ insurerlogo: fieldValue });
  };
  handleproductnameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ productname: fieldValue });
  };
  handleproductcodeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ productcode: fieldValue });
  };
  handleproductidChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ productid: fieldValue });
  };
  handleapikeyChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ apikey: fieldValue });
  };
  handleauthsecretChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ authsecret: fieldValue });
  };
  handlepartneridChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ partnerid: fieldValue });
  };
  handleappidChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ appid: fieldValue });
  };
  handlesecuritykeyChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ securitykey: fieldValue });
  };
  handledummyoneChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyone: fieldValue });
  };
  handledummytwoChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummytwo: fieldValue });
  };
  handledummythreeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummythree: fieldValue });
  };
  handleplantypeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ plantype: fieldValue });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
    console.log(`Editing row with ID ${row.id}`);
    // implement your edit logic here
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("healthproducts_Id", row.healthproducts_Id);
    fetch(configData.api_url + "C_Healthproducts/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.HealthproductsfetchHandler();
        }
      });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div className="page-title">
        <div className="row">
          <div className="title_left">
            <h2 style={{ textAlign: "start" }}>Health products</h2>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                                   dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "5px", marginTop: "20px" }}
                    >
                      <Button
                        variant="primary"
                        className="bi bi-plus "
                        onClick={this.handleOffcanvasShow}
                      >
                        New
                      </Button>
                      <Offcanvas
                        show={this.state.show}
                        onHide={this.handleOffcanvasClose}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>Healthproducts page</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                            <Row className="divstyle">
                              <Row>
                                <Form>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        insurername
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleinsurernameChange}
                                        className="form-control"
                                        name="insurername"
                                        value={this.state.insurername}
                                        required="required"
                                        placeholder="Enter insurername"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.insurername}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        insurer logo
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleinsurerlogoChange}
                                        className="form-control"
                                        name="insurerlogo"
                                        value={this.state.insurerlogo}
                                        required="required"
                                        placeholder="Enter insurerlogo"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.insurerlogo}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        product name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleproductnameChange}
                                        className="form-control"
                                        name="productname"
                                        value={this.state.productname}
                                        required="required"
                                        placeholder="Enter productname"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.productname}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        product code
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleproductcodeChange}
                                        className="form-control"
                                        name="productcode"
                                        value={this.state.productcode}
                                        required="required"
                                        placeholder="Enter productcode"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.productcode}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        product id
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleproductidChange}
                                        className="form-control"
                                        name="productid"
                                        value={this.state.productid}
                                        required="required"
                                        placeholder="Enter productid"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.productid}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        api key
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleapikeyChange}
                                        className="form-control"
                                        name="apikey"
                                        value={this.state.apikey}
                                        required="required"
                                        placeholder="Enter apikey"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.apikey}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        auth secret
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleauthsecretChange}
                                        className="form-control"
                                        name="authsecret"
                                        value={this.state.authsecret}
                                        required="required"
                                        placeholder="Enter authsecret"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.authsecret}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        partner id
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlepartneridChange}
                                        className="form-control"
                                        name="partnerid"
                                        value={this.state.partnerid}
                                        required="required"
                                        placeholder="Enter partnerid"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.partnerid}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        app id
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleappidChange}
                                        className="form-control"
                                        name="appid"
                                        value={this.state.appid}
                                        required="required"
                                        placeholder="Enter appid"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.appid}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        security key
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlesecuritykeyChange}
                                        className="form-control"
                                        name="securitykey"
                                        value={this.state.securitykey}
                                        required="required"
                                        placeholder="Enter securitykey"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.securitykey}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        dummy one
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyoneChange}
                                        className="form-control"
                                        name="dummyone"
                                        value={this.state.dummyone}
                                        required="required"
                                        placeholder="Enter dummyone"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyone}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        dummy two
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummytwoChange}
                                        className="form-control"
                                        name="dummytwo"
                                        value={this.state.dummytwo}
                                        required="required"
                                        placeholder="Enter dummytwo"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummytwo}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        dummy three
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummythreeChange}
                                        className="form-control"
                                        name="dummythree"
                                        value={this.state.dummythree}
                                        required="required"
                                        placeholder="Enter dummythree"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummythree}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        plan type
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleplantypeChange}
                                        className="form-control"
                                        name="plantype"
                                        value={this.state.plantype}
                                        required="required"
                                        placeholder="Enter plantype"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.plantype}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                </Form>
                              </Row>
                            </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={this.HealthproductssubmitHandler}
                                  style={{
                                    marginTop: "20px",
                                  }}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                      {/*offcanvas is over */}
                    </Col>
                  </Row>
                  <br />
                  <div>
                    {" "}
                    {this.state.isEditing && (
                      // Edit Offcanvas
                      <Offcanvas
                        show={this.state.isEditing}
                        onHide={() => this.setState({ isEditing: false })}
                        {...this.props}
                        style={{ width: "500px", height: "auto" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            Edit Health Products Page
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <div className="form-horizontal form-label-left input_mask">
                            <Form className="form-horizontal form-label-left input_mask">
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    insurername
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleinsurernameChange}
                                    className="form-control"
                                    name="insurername"
                                    value={this.state.insurername}
                                    required="required"
                                    placeholder="Enter insurername"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.insurername}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    insurer logo
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleinsurerlogoChange}
                                    className="form-control"
                                    name="insurerlogo"
                                    value={this.state.insurerlogo}
                                    required="required"
                                    placeholder="Enter insurerlogo"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.insurerlogo}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    product name
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleproductnameChange}
                                    className="form-control"
                                    name="productname"
                                    value={this.state.productname}
                                    required="required"
                                    placeholder="Enter productname"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.productname}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    product code
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleproductcodeChange}
                                    className="form-control"
                                    name="productcode"
                                    value={this.state.productcode}
                                    required="required"
                                    placeholder="Enter productcode"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.productcode}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    product id
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleproductidChange}
                                    className="form-control"
                                    name="productid"
                                    value={this.state.productid}
                                    required="required"
                                    placeholder="Enter productid"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.productid}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    api key
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleapikeyChange}
                                    className="form-control"
                                    name="apikey"
                                    value={this.state.apikey}
                                    required="required"
                                    placeholder="Enter apikey"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.apikey}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    auth secret
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleauthsecretChange}
                                    className="form-control"
                                    name="authsecret"
                                    value={this.state.authsecret}
                                    required="required"
                                    placeholder="Enter authsecret"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.authsecret}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    partner id
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handlepartneridChange}
                                    className="form-control"
                                    name="partnerid"
                                    value={this.state.partnerid}
                                    required="required"
                                    placeholder="Enter partnerid"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.partnerid}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    app id
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleappidChange}
                                    className="form-control"
                                    name="appid"
                                    value={this.state.appid}
                                    required="required"
                                    placeholder="Enter appid"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.appid}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    security key
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handlesecuritykeyChange}
                                    className="form-control"
                                    name="securitykey"
                                    value={this.state.securitykey}
                                    required="required"
                                    placeholder="Enter securitykey"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.securitykey}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    dummy one
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handledummyoneChange}
                                    className="form-control"
                                    name="dummyone"
                                    value={this.state.dummyone}
                                    required="required"
                                    placeholder="Enter dummyone"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.dummyone}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    dummy two
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handledummytwoChange}
                                    className="form-control"
                                    name="dummytwo"
                                    value={this.state.dummytwo}
                                    required="required"
                                    placeholder="Enter dummytwo"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.dummytwo}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    dummy three
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handledummythreeChange}
                                    className="form-control"
                                    name="dummythree"
                                    value={this.state.dummythree}
                                    required="required"
                                    placeholder="Enter dummythree"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.dummythree}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    plan type
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleplantypeChange}
                                    className="form-control"
                                    name="plantype"
                                    value={this.state.plantype}
                                    required="required"
                                    placeholder="Enter plantype"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.plantype}{" "}
                                </div>
                              </Row>
                              <br />
                              <br />
                              <div style={{ float: "right" }}>
                                <button
                                  className="btn btn-primary me-2 mt-5"
                                  style={{
                                    backgroundColor: "#23547e",
                                    color: "#fff",
                                    borderColor: "#2e6da4",
                                  }}
                                  onClick={this.HealthproductsupdateHandler}
                                >
                                  Update
                                </button>
                              </div>
                            </Form>
                          </div>
                        </Offcanvas.Body>
                      </Offcanvas>
                    )}
                  </div>
                  <br />
                  <Row>
                    <div style={{ marginRight: "5px" }}>
                      {/* searchbar,pagination,filter */}
                      <input
                        type="text"
                        style={{ float: "left", marginBottom: "10px" }}
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        paginationRowsPerPageOptions={[
                          10, 20, 30, 50, 60, 70, 80, 90, 100,
                        ]}
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

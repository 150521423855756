import { json } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";
import configData from "../config.json";
// const encryptionKey = "donotouchapnap";
export const LoginUser = async (email, password, forgotPassword) => {
  try {
    //console.log(encryptData(password));
    const formData = new FormData();
    formData.append("username", email);
    formData.append(
      "password",
      CryptoJS.HmacMD5(password, configData.secret).toString()
    );
    fetch(configData.api_url + "login", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())

      .then((data) => {
        showToast(data.message, data.status);
        if (data.status === "success") {
          sessionStorage.setItem(
            "userName",
            data.detail.fName + " " + data.detail.lName
          );
          sessionStorage.setItem("emailId", data.detail.emailId);
          sessionStorage.setItem("uId", data.detail.employees_Id);
          sessionStorage.setItem("sessionId", data.detail.sessionId);
          sessionStorage.setItem("roleData", data.detail.roleData);
          sessionStorage.setItem("lPage", data.detail.lPage);
          sessionStorage.setItem("isPwd", data.detail.isPwd);
          sessionStorage.setItem("employeeId", data.detail.eId);
          window.location.href = data.detail.lPage;
        }
      });
  } catch (error) {
    showToast("An error occurred during login!", "error");
    return false;
  }
};

const showToast = (msg, type) => {
  let tType = toast.TYPE.INFO;
  if (type === "success") tType = toast.TYPE.SUCCESS;
  if (type === "error") tType = toast.TYPE.ERROR;
  if (type === "warning") tType = toast.TYPE.WARNING;
  toast(msg, { type: tType });
};
export var encryptData = (roleData) => {
  const data = CryptoJS.AES.encrypt(
    JSON.stringify(roleData),
    configData.secret
  ).toString();
  return data;
};
export var LogOut = () => {
  showToast("User logged out!", "success");
  sessionStorage.clear();
  // window.location.href = configData.web_url;
  window.location.href = "/";
};

export var getEmail = () => {
  const email = sessionStorage.getItem("email");
  return email ? email : null;
};

export var setEmail = (email) => {
  sessionStorage.setItem("email", email);
};

export var getPassword = () => {
  const password = sessionStorage.getItem("password");
  return password ? password : null;
};

export var setPassword = (password) => {
  sessionStorage.setItem("password", password);
};

export var getUser = () => {
  // get user data from local storage
  const user = localStorage.getItem("user");
  return user ? JSON.parse(user) : null;
};
export var setItem = () => {
  const user = sessionStorage.setItem("user");
  return user ? json.parse(user) : null;
};
export const forgotPassword = async (email) => {
  if (!email) {
    showToast("Enter your email address!", "error");
    return false;
  }
};

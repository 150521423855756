import React, { Component } from "react";
import { Button, Container, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";

export default class Integrationlibrary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),

      errors: {},
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.integrationlibrary_Id,
          sortable: true,
          cell: (row) => <span>{row.integrationlibrary_Id}</span>,
        },
        {
          name: "Partner Name",
          selector: (row, i) => row.partnerName,
          sortable: true,
          cell: (row) => <span>{row.partnerName}</span>,
        },
        {
          name: "API Name",
          selector: (row, i) => row.apiName,
          sortable: true,
          cell: (row) => <span>{row.apiName}</span>,
        },
        {
          name: "API Description ",
          selector: (row, i) => row.apiDescription,
          sortable: true,
          cell: (row) => <span>{row.apiDescription}</span>,
        },
        {
          name: "IP/URL",
          selector: (row, i) => row.ipUrl,
          sortable: true,
          cell: (row) => <span>{row.ipUrl}</span>,
        },
        {
          name: "Authentication Method",
          selector: (row, i) => row.authenticationMethod,
          sortable: true,
          cell: (row) => <span>{row.authenticationMethod}</span>,
        },
        {
          name: "Token",
          selector: (row, i) => row.token,
          sortable: true,
          cell: (row) => <span>{row.token}</span>,
        },
        {
          name: "User Name",
          selector: (row, i) => row.userName,
          sortable: true,
          cell: (row) => <span>{row.userName}</span>,
        },
        {
          name: "Pwd",
          selector: (row, i) => row.pwd,
          sortable: true,
          cell: (row) => <span>{row.pwd}</span>,
        },
        {
          name: "Throttle limit",
          selector: (row, i) => row.throttleLimit,
          sortable: true,
          cell: (row) => <span>{row.throttleLimit}</span>,
        },
        {
          name: "Type",
          selector: (row, i) => row.type,
          sortable: true,
          cell: (row) => <span>{row.type}</span>,
        },
        {
          name: "Dummy One",
          selector: (row, i) => row.dummyOne,
          sortable: true,
          cell: (row) => <span>{row.dummyOne}</span>,
        },
        {
          name: "Dummy Two",
          selector: (row, i) => row.dummyTwo,
          sortable: true,
          cell: (row) => <span>{row.dummyTwo}</span>,
        },
        {
          name: "Dummy Three",
          selector: (row, i) => row.dummyThree,
          sortable: true,
          cell: (row) => <span>{row.dummyThree}</span>,
        },
        {
          name: "Dummy Four",
          selector: (row, i) => row.dummyFour,
          sortable: true,
          cell: (row) => <span>{row.dummyFour}</span>,
        },
        {
          name: "Dummy Five",
          selector: (row, i) => row.dummyFive,
          sortable: true,
          cell: (row) => <span>{row.dummyFive}</span>,
        },
        {
          name: "Dummy Six",
          selector: (row, i) => row.dummySix,
          sortable: true,
          cell: (row) => <span>{row.dummySix}</span>,
        },
        {
          name: "Dummy Seven",
          selector: (row, i) => row.dummySeven,
          sortable: true,
          cell: (row) => <span>{row.dummySeven}</span>,
        },
        {
          name: "Dummy Eight",
          selector: (row, i) => row.dummyEight,
          sortable: true,
          cell: (row) => <span>{row.dummyEight}</span>,
        },
        {
          name: "Dummy Nine",
          selector: (row, i) => row.dummyNine,
          sortable: true,
          cell: (row) => <span>{row.dummyNine}</span>,
        },
        {
          name: "Dummy Ten",
          selector: (row, i) => row.dummyTen,
          sortable: true,
          cell: (row) => <span>{row.dummyTen}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                style={{ marginRight: "10px" }}
                variant="primary"
                onClick={this.handleOffcanvasShow}
                className="bi bi-pen"
              ></Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      Integrationtype: [],
      AuthenticationMethod: [],
      currentPage: 1,
      itemsPerPage: 10,
      partnerName: "",
      apiName: "",
      apiDescription: "",
      ipUrl: "",
      authenticationMethod: "",
      token: "",
      userName: "",
      pwd: "",
      throttleLimit: "",
      type: "",
      dummyOne: "",
      dummyTwo: "",
      dummyThree: "",
      dummyFour: "",
      dummyFive: "",
      dummySix: "",
      dummySeven: "",
      dummyEight: "",
      dummyNine: "",
      dummyTen: "",
      integrationlibrary_Id: "",
    };
  }
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    switch (type) {
      case "success":
        toast.success(msg);
        break;
      case "error":
        toast.error(msg);
        break;
      case "warning":
        toast.warn(msg); // 'warn' is used instead of 'warning'
        break;
      default:
        toast.info(msg);
    }
  };
  IntegrationlibraryeditHandler = (e) => {
    e.preventDefault();
    // const updateFormData = {...editFormData};
    // updateFormData['Integrationlibrary_Id'] = e.target.parentElement.getAttribute("id");
    // setEditFormData(updateFormData);
    // setEdit(true);
  };

  IntegrationlibraryfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Integrationlibrary/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  IntegrationlibrarysubmitHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("partnerName", this.state.partnerName);
    formData.append("apiName", this.state.apiName);
    formData.append("apiDescription", this.state.apiDescription);
    formData.append("ipUrl", this.state.ipUrl);
    formData.append("authenticationMethod", this.state.authenticationMethod);
    formData.append("token", this.state.token);
    formData.append("userName", this.state.userName);
    formData.append("pwd", this.state.pwd);
    formData.append("throttleLimit", this.state.throttleLimit);
    formData.append("type", this.state.type);
    formData.append("dummyOne", this.state.dummyOne);
    formData.append("dummyTwo", this.state.dummyTwo);
    formData.append("dummyThree", this.state.dummyThree);
    formData.append("dummyFour", this.state.dummyFour);
    formData.append("dummyFive", this.state.dummyFive);
    formData.append("dummySix", this.state.dummySix);
    formData.append("dummySeven", this.state.dummySeven);
    formData.append("dummyEight", this.state.dummyEight);
    formData.append("dummyNine", this.state.dummyNine);
    formData.append("dummyTen", this.state.dummyTen);
    fetch(configData.api_url + "C_Integrationlibrary/add", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          sessionStorage.setItem(
            "integrationlibrary_Id",
            data.integrationlibrary_Id
          );
          this.IntegrationlibraryfetchHandler();
          this.handleOffcanvasClose();
          this.setState({
            partnerName: "",
            apiName: "",
            apiDescription: "",
            ipUrl: "",
            authenticationMethod: "",
            token: "",
            userName: "",
            pwd: "",
            throttleLimit: "",
            type: "",
          });
          window.location.href = "./samplejsonrequest";
        }
      });
  };
  IntegrationlibraryupdateHandler = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("partnerName", this.state.partnerName);
    formData.append("apiName", this.state.apiName);
    formData.append("apiDescription", this.state.apiDescription);
    formData.append("ipUrl", this.state.ipUrl);
    formData.append("authenticationMethod", this.state.authenticationMethod);
    formData.append("token", this.state.token);
    formData.append("userName", this.state.userName);
    formData.append("pwd", this.state.pwd);
    formData.append("throttleLimit", this.state.throttleLimit);
    formData.append("type", this.state.type);
    formData.append("dummyOne", this.state.dummyOne);
    formData.append("dummyTwo", this.state.dummyTwo);
    formData.append("dummyThree", this.state.dummyThree);
    formData.append("dummyFour", this.state.dummyFour);
    formData.append("dummyFive", this.state.dummyFive);
    formData.append("dummySix", this.state.dummySix);
    formData.append("dummySeven", this.state.dummySeven);
    formData.append("dummyEight", this.state.dummyEight);
    formData.append("dummyNine", this.state.dummyNine);
    formData.append("dummyTen", this.state.dummyTen);

    fetch(configData.api_url + "C_Integrationlibrary/update", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.IntegrationlibraryfetchHandler();
          this.handleOffcanvasClose();
        }
      });
  };
  IntegrationtypemastersfetchHandler = () => {
    var formData = new FormData();

    formData.append("sId", this.state.sId);

    formData.append("uId", this.state.uId);

    fetch(configData.api_url + "C_Integrationtypemasters/list", {
      // Enter your IP address here

      method: "POST",

      //headers :{ 'Content-Type' : 'application/json'},

      mode: "cors",

      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())

      .then((data) => {
        if (data.status === "success") {
          this.setState({ Integrationtype: data.list });
        }
      });
  };
  AuthenticationmethodfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Authenticationmethod/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ AuthenticationMethod: data.list });
        }
      });
  };

  componentDidMount() {
    this.IntegrationlibraryfetchHandler();
    this.IntegrationtypemastersfetchHandler();
    this.AuthenticationmethodfetchHandler();
  }
  handlepartnerNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ partnerName: fieldValue });
  };
  handleapiNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ apiName: fieldValue });
  };
  handleapiDescriptionChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ apiDescription: fieldValue });
  };
  handleipUrlChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ ipUrl: fieldValue });
  };
  handleauthenticationMethodChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ authenticationMethod: fieldValue });
  };
  handletokenChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ token: fieldValue });
  };
  handleuserNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ userName: fieldValue });
  };
  handlepwdChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ pwd: fieldValue });
  };
  handlethrottleLimitChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ throttleLimit: fieldValue });
  };
  handletypeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ type: fieldValue });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handledummyOneChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;

    this.setState({ dummyOne: fieldValue });
  };
  handledummyTwoChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;

    this.setState({ dummyTwo: fieldValue });
  };
  handledummyThreeChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;

    this.setState({ dummyThree: fieldValue });
  };
  handledummyFourChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;

    this.setState({ dummyFour: fieldValue });
  };
  handledummyFiveChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;

    this.setState({ dummyFive: fieldValue });
  };
  handledummySixChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;

    this.setState({ dummySix: fieldValue });
  };
  handledummySevenChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;

    this.setState({ dummySeven: fieldValue });
  };
  handledummyEightChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;

    this.setState({ dummyEight: fieldValue });
  };
  handledummyNineChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;

    this.setState({ dummyNine: fieldValue });
  };
  handledummyTenChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;

    this.setState({ dummyTen: fieldValue });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
    console.log(`Editing row with ID ${row.id}`);
    // implement your edit logic here
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("integrationlibrary_Id", row.integrationlibrary_Id);
    fetch(configData.api_url + "C_Integrationlibrary/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.IntegrationlibraryfetchHandler();
        }
      });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div className="page-title">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <div>
                      <h3
                        className="logoskybluecolor"
                        style={{ textAlign: "start" }}
                      >
                        Integration Library
                      </h3>
                    </div>
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "5px", marginTop: "20px" }}
                    >
                      <Offcanvas
                        show={this.state.show}
                        onHide={this.handleOffcanvasClose}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            Integrationlibrary page
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                            <Row className="divstyle">
                              <Row>
                                <Form>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Partner Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlepartnerNameChange}
                                        className="form-control"
                                        name="partnerName"
                                        value={this.state.partnerName}
                                        required="required"
                                        placeholder="Enter Partner Name"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.partnerName}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        API Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleapiNameChange}
                                        className="form-control"
                                        name="apiName"
                                        value={this.state.apiName}
                                        required="required"
                                        placeholder="Enter API Name"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.apiName}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        API Description{" "}
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handleapiDescriptionChange
                                        }
                                        className="form-control"
                                        name="apiDescription"
                                        value={this.state.apiDescription}
                                        required="required"
                                        placeholder="Enter API Description "
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.apiDescription}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        IP/URL
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleipUrlChange}
                                        className="form-control"
                                        name="ipUrl"
                                        value={this.state.ipUrl}
                                        required="required"
                                        placeholder="Enter IP/URL"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.ipUrl}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Integration Type
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        as="select"
                                        onChange={this.handletypeChange}
                                        className="form-select"
                                        name="type"
                                        value={this.state.type}
                                        required="required"
                                        placeholder="Enter Type"
                                      >
                                        <option defaultValue>
                                          Select Integration Type
                                        </option>
                                        {this.state.Integrationtype.map(
                                          (api, index, idx) => (
                                            <option
                                              key={index}
                                              value={api.typeName}
                                            >
                                              {api.typeName}
                                            </option>
                                          )
                                        )}
                                      </Form.Control>
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.type}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Authentication Method
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        as="select"
                                        onChange={
                                          this.handleauthenticationMethodChange
                                        }
                                        className="form-select"
                                        name="authenticationMethod"
                                        value={this.state.authenticationMethod}
                                        required="required"
                                      >
                                        <option defaultValue>
                                          Select Method
                                        </option>
                                        {this.state.AuthenticationMethod.map(
                                          (api, index, idx) => (
                                            <option
                                              key={index}
                                              value={api.methodName}
                                            >
                                              {api.methodName}
                                            </option>
                                          )
                                        )}
                                      </Form.Control>
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.authenticationMethod
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Token
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handletokenChange}
                                        className="form-control"
                                        name="token"
                                        value={this.state.token}
                                        required="required"
                                        placeholder="Enter Token"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.token}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        User Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleuserNameChange}
                                        className="form-control"
                                        name="userName"
                                        value={this.state.userName}
                                        required="required"
                                        placeholder="Enter User Name"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.userName}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Pwd
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlepwdChange}
                                        className="form-control"
                                        name="pwd"
                                        value={this.state.pwd}
                                        required="required"
                                        placeholder="Enter Pwd"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.pwd}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Throttle limit
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="number"
                                        onChange={
                                          this.handlethrottleLimitChange
                                        }
                                        className="form-control"
                                        name="throttleLimit"
                                        value={this.state.throttleLimit}
                                        required="required"
                                        placeholder="Enter Throttle limit"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.throttleLimit}{" "}
                                    </div>
                                  </Row>
                                  <br />

                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy One
                                      </Form.Label>
                                    </Col>

                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyOneChange}
                                        className="form-control"
                                        name="dummyOne"
                                        value={this.state.dummyOne}
                                        required="required"
                                        placeholder="Enter Dummy One"
                                      />
                                    </Col>

                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyOne}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Two
                                      </Form.Label>
                                    </Col>

                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyTwoChange}
                                        className="form-control"
                                        name="dummyTwo"
                                        value={this.state.dummyTwo}
                                        required="required"
                                        placeholder="Enter Dummy Two"
                                      />
                                    </Col>

                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyTwo}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Three
                                      </Form.Label>
                                    </Col>

                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyThreeChange}
                                        className="form-control"
                                        name="dummyThree"
                                        value={this.state.dummyThree}
                                        required="required"
                                        placeholder="Enter Dummy Three"
                                      />
                                    </Col>

                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyThree}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Four
                                      </Form.Label>
                                    </Col>

                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyFourChange}
                                        className="form-control"
                                        name="dummyFour"
                                        value={this.state.dummyFour}
                                        required="required"
                                        placeholder="Enter Dummy Four"
                                      />
                                    </Col>

                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyFour}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Five
                                      </Form.Label>
                                    </Col>

                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyFiveChange}
                                        className="form-control"
                                        name="dummyFive"
                                        value={this.state.dummyFive}
                                        required="required"
                                        placeholder="Enter Dummy Five"
                                      />
                                    </Col>

                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyFive}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Six
                                      </Form.Label>
                                    </Col>

                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummySixChange}
                                        className="form-control"
                                        name="dummySix"
                                        value={this.state.dummySix}
                                        required="required"
                                        placeholder="Enter Dummy Six"
                                      />
                                    </Col>

                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummySix}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Seven
                                      </Form.Label>
                                    </Col>

                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummySevenChange}
                                        className="form-control"
                                        name="dummySeven"
                                        value={this.state.dummySeven}
                                        required="required"
                                        placeholder="Enter Dummy Seven"
                                      />
                                    </Col>

                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummySeven}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Eight
                                      </Form.Label>
                                    </Col>

                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyEightChange}
                                        className="form-control"
                                        name="dummyEight"
                                        value={this.state.dummyEight}
                                        required="required"
                                        placeholder="Enter Dummy Eight"
                                      />
                                    </Col>

                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyEight}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Nine
                                      </Form.Label>
                                    </Col>

                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyNineChange}
                                        className="form-control"
                                        name="dummyNine"
                                        value={this.state.dummyNine}
                                        required="required"
                                        placeholder="Enter Dummy Nine"
                                      />
                                    </Col>

                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyNine}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Ten
                                      </Form.Label>
                                    </Col>

                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyTenChange}
                                        className="form-control"
                                        name="dummyTen"
                                        value={this.state.dummyTen}
                                        required="required"
                                        placeholder="Enter Dummy Ten"
                                      />
                                    </Col>

                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyTen}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                </Form>
                              </Row>
                            </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={this.IntegrationlibrarysubmitHandler}
                                  style={{
                                    marginTop: "20px",
                                  }}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                      {/*offcanvas is over */}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <div style={{ marginRight: "5px" }}>
                      {/* searchbar,pagination,filter */}
                      <input
                        type="text"
                        style={{ float: "left", marginBottom: "10px" }}
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <Button
                        variant="primary"
                        className="bi bi-plus "
                        onClick={this.handleOffcanvasShow}
                        style={{ float: "right", marginBottom: "10px" }}
                      >
                        New
                      </Button>
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

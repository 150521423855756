import React, { Component } from "react";
import { Button, Container, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";

export default class Widgetdefinition extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.widgetdefinition_Id,
          sortable: true,
          cell: (row) => <span>{row.widgetdefinition_Id}</span>,
        },
        {
          name: "Widget Id",
          selector: (row, i) => row.widgetId,
          sortable: true,
          cell: (row) => <span>{row.widgetId}</span>,
        },
        {
          name: "Widget Name",
          selector: (row, i) => row.widgetName,
          sortable: true,
          cell: (row) => <span>{row.widgetName}</span>,
        },
        {
          name: "chartType",
          selector: (row, i) => row.chartType,
          sortable: true,
          cell: (row) => <span>{row.chartType}</span>,
        },
        {
          name: "Max Width",
          selector: (row, i) => row.maxWidth,
          sortable: true,
          cell: (row) => <span>{row.maxWidth}</span>,
        },
        {
          name: "Min Width",
          selector: (row, i) => row.minWidth,
          sortable: true,
          cell: (row) => <span>{row.minWidth}</span>,
        },
        {
          name: "Max Height",
          selector: (row, i) => row.maxHeight,
          sortable: true,
          cell: (row) => <span>{row.maxHeight}</span>,
        },
        {
          name: "min height",
          selector: (row, i) => row.minHeight,
          sortable: true,
          cell: (row) => <span>{row.minHeight}</span>,
        },
        {
          name: "Table Name",
          selector: (row, i) => row.tableName,
          sortable: true,
          cell: (row) => <span>{row.tableName}</span>,
        },
        {
          name: "Column Name",
          selector: (row, i) => row.columnName,
          sortable: true,
          cell: (row) => <span>{row.columnName}</span>,
        },
        {
          name: "Column Value Operator",
          selector: (row, i) => row.columnvalueOperator,
          sortable: true,
          cell: (row) => <span>{row.columnvalueOperator}</span>,
        },
        {
          name: "Value",
          selector: (row, i) => row.value1,
          sortable: true,
          cell: (row) => <span>{row.value1}</span>,
        },
        {
          name: "Logical Operator",
          selector: (row, i) => row.logicalOperator,
          sortable: true,
          cell: (row) => <span>{row.logicalOperator}</span>,
        },
        {
          name: "Column Name Two",
          selector: (row, i) => row.columnName2,
          sortable: true,
          cell: (row) => <span>{row.columnName2}</span>,
        },
        {
          name: "Column Value Operator Two",
          selector: (row, i) => row.columnvalueOperator2,
          sortable: true,
          cell: (row) => <span>{row.columnvalueOperator2}</span>,
        },
        {
          name: "Value Two",
          selector: (row, i) => row.value2,
          sortable: true,
          cell: (row) => <span>{row.value2}</span>,
        },
        {
          name: "Column Name Three",
          selector: (row, i) => row.columnName3,
          sortable: true,
          cell: (row) => <span>{row.columnNamethree}</span>,
        },
        {
          name: "Column Value Operator Three",
          selector: (row, i) => row.columnvalueOperator3,
          sortable: true,
          cell: (row) => <span>{row.columnvalueOperator3}</span>,
        },
        {
          name: "Value Three",
          selector: (row, i) => row.value3,
          sortable: true,
          cell: (row) => <span>{row.value3}</span>,
        },
        {
          name: "Column Name Four",
          selector: (row, i) => row.columnName4,
          sortable: true,
          cell: (row) => <span>{row.columnName4}</span>,
        },
        {
          name: "Column Value Operator Four",
          selector: (row, i) => row.columnName4,
          sortable: true,
          cell: (row) => <span>{row.columnName4}</span>,
        },
        {
          name: "Value Four",
          selector: (row, i) => row.value4,
          sortable: true,
          cell: (row) => <span>{row.value4}</span>,
        },
        {
          name: "Arithmetic Operators:",
          selector: (row, i) => row.arithematicOperator,
          sortable: true,
          cell: (row) => <span>{row.arithematicOperator}</span>,
        },
        {
          name: "Column of Arithmetic",
          selector: (row, i) => row.columnofArithematic,
          sortable: true,
          cell: (row) => <span>{row.columnofArithematic}</span>,
        },
        {
          name: "Group by Column",
          selector: (row, i) => row.groupbyColumn,
          sortable: true,
          cell: (row) => <span>{row.groupbyColumn}</span>,
        },
        {
          name: "Extract Month",
          selector: (row, i) => row.extractMonth,
          sortable: true,
          cell: (row) => <span>{row.extractMonth}</span>,
        },
        {
          name: "Extract Year",
          selector: (row, i) => row.extractYear,
          sortable: true,
          cell: (row) => <span>{row.extractYear}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                style={{ marginRight: "10px" }}
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen"
              ></Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      widgetId: "",
      widgetName: "",
      chartType: "",
      maxWidth: "",
      minWidth: "",
      maxHeight: "",
      minHeight: "",
      tableName: "",
      columnName: "",
      columnvalueOperator: "",
      value1: "",
      logicalOperator: "",
      logicalOperator2: "",
      logicalOperator3: "",
      logicalOperator4: "",
      columnName2: "",
      columnvalueOperator2: "",
      value2: "",
      columnName3: "",
      columnvalueOperator3: "",
      value3: "",
      columnName4: "",
      columnvalueOperator4: "",

      value4: "",
      arithematicOperator: "",
      columnofArithematic: "",
      groupbyColumn: "",
      extractMonth: "",
      extractYear: "",
      dummyone: "",
      dummytwo: "",
      dummythree: "",
      dummyfour: "",
      dummyfive: "",
      dummysix: "",
      dummyseven: "",
      dummyeight: "",
      dummynine: "",
      dummyten: "",
      widgetdefinition_Id: "",
      isEditing: false, // Flag to indicate edit mode
      isNewEntry: false,
    };
  }
  handleEnterEditMode = (row) => {
    // this.setState({ cData: { ...row }, isEditing: true });
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    this.setState({
      widgetId: row.widgetId,
      widgetName: row.widgetName,
      chartType: row.chartType,
      maxWidth: row.maxWidth,
      minWidth: row.minWidth,
      maxHeight: row.maxHeight,
      minHeight: row.minHeight,
      tableName: row.tableName,
      columnName: row.columnName,
      columnvalueOperator: row.columnvalueOperator,
      value1: row.value1,
      logicalOperator: row.logicalOperator,
      logicalOperator2: row.logicalOperator2,
      logicalOperator3: row.logicalOperator3,
      logicalOperator4: row.logicalOperator4,
      columnName2: row.columnName2,
      columnvalueOperator2: row.columnvalueOperator2,
      value2: row.value2,
      columnName3: row.columnName3,
      columnvalueOperator3: row.columnvalueOperator3,
      value3: row.value3,
      columnName4: row.columnName4,
      columnvalueOperator4: row.columnvalueOperator4,

      value4: row.value4,
      arithematicOperator: row.arithematicOperator,
      columnofArithematic: row.columnofArithematic,
      groupbyColumn: row.groupbyColumn,
      extractMonth: row.extractMonth,
      extractYear: row.extractYear,
      dummyone: row.dummyone,
      dummytwo: row.dummytwo,
      dummythree: row.dummythree,
      dummyfour: row.dummyfour,
      dummyfive: row.dummyfive,
      dummysix: row.dummysix,
      dummyseven: row.dummyseven,
      dummyeight: row.dummyeight,
      dummynine: row.dummynine,
      dummyten: row.dummyten,
      widgetdefinition_Id: row.widgetdefinition_Id,
      isEditing: true,
    });
  };
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({
      show: true,
      widgetId: "",
      widgetName: "",
      chartType: "",
      maxWidth: "",
      minWidth: "",
      maxHeight: "",
      minHeight: "",
      tableName: "",
      columnName: "",
      columnvalueOperator: "",
      value1: "",
      logicalOperator: "",
      logicalOperator2: "",
      logicalOperator3: "",
      logicalOperator4: "",
      columnName2: "",
      columnvalueOperator2: "",
      value2: "",
      columnName3: "",
      columnvalueOperator3: "",
      value3: "",
      columnName4: "",
      columnvalueOperator4: "",
      value4: "",
      arithematicOperator: "",
      columnofArithematic: "",
      groupbyColumn: "",
      extractMonth: "",
      extractYear: "",
      dummyone: "",
      dummytwo: "",
      dummythree: "",
      dummyfour: "",
      dummyfive: "",
      dummysix: "",
      dummyseven: "",
      dummyeight: "",
      dummynine: "",
      dummyten: "",
      widgetdefinition_Id: "",
    });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    switch (type) {
      case "success":
        toast.success(msg);
        break;
      case "error":
        toast.error(msg);
        break;
      case "warning":
        toast.warn(msg); // 'warn' is used instead of 'warning'
        break;
      default:
        toast.info(msg);
    }
  };
  WidgetdefinationeditHandler = (e) => {
    e.preventDefault();
    // const updateFormData = {...editFormData};
    // updateFormData['Widgetdefination_Id'] = e.target.parentElement.getAttribute("id");
    // setEditFormData(updateFormData);
    // setEdit(true);
  };

  WidgetdefinationfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Widgetdefinition/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  WidgetdefinationsubmitHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("widgetId", this.state.widgetId);
    formData.append("widgetName", this.state.widgetName);
    formData.append("chartType", this.state.chartType);
    formData.append("maxWidth", this.state.maxWidth);
    formData.append("minWidth", this.state.minWidth);
    formData.append("maxHeight", this.state.maxHeight);
    formData.append("minHeight", this.state.minHeight);
    formData.append("tableName", this.state.tableName);
    formData.append("columnName", this.state.columnName);
    formData.append("columnvalueOperator", this.state.columnvalueOperator);
    formData.append("value1", this.state.value1);
    formData.append("logicalOperator", this.state.logicalOperator);
    formData.append("logicalOperator2", this.state.logicalOperator2);
    formData.append("logicalOperator3", this.state.logicalOperator3);
    formData.append("logicalOperator4", this.state.logicalOperator4);
    formData.append("columnName2", this.state.columnName2);
    formData.append("columnvalueOperator2", this.state.columnvalueOperator2);
    formData.append("value2", this.state.value2);
    formData.append("columnName3", this.state.columnName3);
    formData.append("columnvalueOperator3", this.state.columnvalueOperator3);
    formData.append("value3", this.state.value3);
    formData.append("columnName4", this.state.columnName4);
    formData.append("value4", this.state.value4);
    formData.append("columnvalueOperator4", this.state.columnvalueOperator4);
    formData.append("arithematicOperator", this.state.arithematicOperator);
    formData.append("columnofArithematic", this.state.columnofArithematic);
    formData.append("groupbyColumn", this.state.groupbyColumn);
    formData.append("extractMonth", this.state.extractMonth);
    formData.append("extractYear", this.state.extractYear);
    formData.append("dummyone", this.state.dummyone);
    formData.append("dummytwo", this.state.dummytwo);
    formData.append("dummythree", this.state.dummythree);
    formData.append("dummyfour", this.state.dummyfour);
    formData.append("dummyfive", this.state.dummyfive);
    formData.append("dummysix", this.state.dummysix);
    formData.append("dummyseven", this.state.dummyseven);
    formData.append("dummyeight", this.state.dummyeight);
    formData.append("dummynine", this.state.dummynine);
    formData.append("dummyten", this.state.dummyten);

    fetch(configData.api_url + "C_Widgetdefinition/add", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.WidgetdefinationfetchHandler();
          this.handleOffcanvasClose();
          this.setState({
            widgetId: "",
            widgetName: "",
            chartType: "",
            maxWidth: "",
            minWidth: "",
            maxHeight: "",
            minHeight: "",
            tableName: "",
            columnName: "",
            columnvalueOperator: "",
            value1: "",
            logicalOperator: "",
            logicalOperator2: "",
            logicalOperator3: "",
            logicalOperator4: "",
            columnName2: "",
            columnvalueOperator2: "",
            value2: "",
            columnName3: "",
            columnvalueOperator3: "",
            value3: "",
            columnName4: "",
            columnvalueOperator4: "",
            value4: "",
            arithematicOperator: "",
            columnofArithematic: "",
            groupbyColumn: "",
            extractMonth: "",
            extractYear: "",
            dummyone: "",
            dummytwo: "",
            dummythree: "",
            dummyfour: "",
            dummyfive: "",
            dummysix: "",
            dummyseven: "",
            dummyeight: "",
            dummynine: "",
            dummyten: "",
          });
        }
      });
  };
  WidgetdefinationupdateHandler = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("widgetId", this.state.widgetId);
    formData.append("widgetName", this.state.widgetName);
    formData.append("chartType", this.state.chartType);
    formData.append("maxWidth", this.state.maxWidth);
    formData.append("minWidth", this.state.minWidth);
    formData.append("maxHeight", this.state.maxHeight);
    formData.append("minHeight", this.state.minHeight);
    formData.append("tableName", this.state.tableName);
    formData.append("columnName", this.state.columnName);
    formData.append("columnvalueOperator", this.state.columnvalueOperator);
    formData.append("value1", this.state.value1);
    formData.append("logicalOperator", this.state.logicalOperator);
    formData.append("logicalOperator2", this.state.logicalOperator2);
    formData.append("logicalOperator3", this.state.logicalOperator3);
    formData.append("logicalOperator4", this.state.logicalOperator4);
    formData.append("columnName2", this.state.columnName2);
    formData.append("columnvalueOperator2", this.state.columnvalueOperator2);
    formData.append("value2", this.state.value2);
    formData.append("columnName3", this.state.columnName3);
    formData.append("columnvalueOperator3", this.state.columnvalueOperator3);
    formData.append("value3", this.state.value3);
    formData.append("columnName4", this.state.columnName4);
    formData.append("columnvalueOperator4", this.state.columnvalueOperator4);
    formData.append("value4", this.state.value4);
    formData.append("arithematicOperator", this.state.arithematicOperator);
    formData.append("columnofArithematic", this.state.columnofArithematic);
    formData.append("groupbyColumn", this.state.groupbyColumn);
    formData.append("extractMonth", this.state.extractMonth);
    formData.append("extractYear", this.state.extractYear);
    formData.append("dummyone", this.state.dummyone);
    formData.append("dummytwo", this.state.dummytwo);
    formData.append("dummythree", this.state.dummythree);
    formData.append("dummyfour", this.state.dummyfour);
    formData.append("dummyfive", this.state.dummyfive);
    formData.append("dummysix", this.state.dummysix);
    formData.append("dummyseven", this.state.dummyseven);
    formData.append("dummyeight", this.state.dummyeight);
    formData.append("dummynine", this.state.dummynine);
    formData.append("dummyten", this.state.dummyten);
    formData.append("widgetdefinition_Id", this.state.widgetdefinition_Id);
    fetch(configData.api_url + "C_Widgetdefinition/update", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.WidgetdefinationfetchHandler();
          this.handleOffcanvasClose();
        }
      });
  };

  componentDidMount() {
    this.WidgetdefinationfetchHandler();
  }
  handlewidgetIdChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ widgetId: fieldValue });
  };
  handlewidgetNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ widgetName: fieldValue });
  };
  handlecharttypeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ chartType: fieldValue });
  };
  handlemaxwidthChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ maxWidth: fieldValue });
  };
  handleminWidthChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ minWidth: fieldValue });
  };
  handlemaxHeightChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ maxHeight: fieldValue });
  };
  handleminHeightChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ minHeight: fieldValue });
  };
  handletableNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ tableName: fieldValue });
  };
  handlecolumnNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ columnName: fieldValue });
  };
  handlecolumnvalueOperatorChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ columnvalueOperator: fieldValue });
  };
  handlevalue1Change = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ value1: fieldValue });
  };
  handlelogicalOperatorChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ logicalOperator: fieldValue });
  };
  handlelogicalOperator2Change = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ logicalOperator2: fieldValue });
  };
  handlelogicalOperator3Change = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ logicalOperator3: fieldValue });
  };
  handlelogicalOperator4Change = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ logicalOperator4: fieldValue });
  };
  handlecolumnName2Change = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ columnName2: fieldValue });
  };
  handlecolumnvalueOperator2Change = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ columnvalueOperator2: fieldValue });
  };
  handlevalue2Change = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ value2: fieldValue });
  };
  handlecolumnName3Change = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ columnName3: fieldValue });
  };
  handlecolumnvalueOperator3Change = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ columnvalueOperator3: fieldValue });
  };
  handlevalue3Change = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ value3: fieldValue });
  };
  handlecolumnName4Change = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ columnName4: fieldValue });
  };

  handlevalue4Change = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ value4: fieldValue });
  };
  handlecolumnvalueOperator4Change = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ columnvalueOperator4: fieldValue });
  };
  handlearithematicoperatorChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ arithematicOperator: fieldValue });
  };
  handlecolumnofArithematicChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ columnofArithematic: fieldValue });
  };
  handlegroupbyColumnChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ groupbyColumn: fieldValue });
  };
  handleextractMonthChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ extractMonth: fieldValue });
  };
  handleextractYearChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ extractYear: fieldValue });
  };
  handledummyoneChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyone: fieldValue });
  };
  handledummytwoChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummytwo: fieldValue });
  };
  handledummythreeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummythree: fieldValue });
  };
  handledummyfourChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyfour: fieldValue });
  };
  handledummyfiveChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyfive: fieldValue });
  };
  handledummysixChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummysix: fieldValue });
  };
  handledummysevenChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyseven: fieldValue });
  };
  handledummyeightChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyeight: fieldValue });
  };
  handledummynineChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummynine: fieldValue });
  };
  handledummytenChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyten: fieldValue });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
    console.log(`Editing row with ID ${row.id}`);
    // implement your edit logic here
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("widgetdefinition_Id", row.widgetdefinition_Id);
    fetch(configData.api_url + "C_Widgetdefinition/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.WidgetdefinationfetchHandler();
        }
      });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div className="page-title">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <div>
                      <h3
                        className="logoskybluecolor"
                        style={{ textAlign: "start" }}
                      >
                        Widget Definition
                      </h3>
                    </div>
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "5px", marginTop: "20px" }}
                    >
                      <Offcanvas
                        show={this.state.show}
                        onHide={this.handleOffcanvasClose}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            Widget Definition page
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                            <Row className="divstyle">
                              <Row>
                                <Form>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Widget Id
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlewidgetIdChange}
                                        className="form-control"
                                        name="widgetId"
                                        value={this.state.widgetId}
                                        required="required"
                                        placeholder="Enter Widget Id"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.widgetId}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Widget Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlewidgetNameChange}
                                        className="form-control"
                                        name="widgetName"
                                        value={this.state.widgetName}
                                        required="required"
                                        placeholder="Enter Widget Name"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.widgetName}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Chart Type
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlecharttypeChange}
                                        className="form-control"
                                        name="chartType"
                                        value={this.state.chartType}
                                        required="required"
                                        placeholder="Enter Chart Type"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.chartType}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Max Width
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlemaxwidthChange}
                                        className="form-control"
                                        name="maxWidth"
                                        value={this.state.maxWidth}
                                        required="required"
                                        placeholder="Enter Max Width"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.maxWidth}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Min Width
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleminWidthChange}
                                        className="form-control"
                                        name="minWidth"
                                        value={this.state.minWidth}
                                        required="required"
                                        placeholder="Enter Min Width"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.minWidth}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Max Height
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlemaxHeightChange}
                                        className="form-control"
                                        name="maxHeight"
                                        value={this.state.maxHeight}
                                        required="required"
                                        placeholder="Enter Max Height"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.maxHeight}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Min Height
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleminHeightChange}
                                        className="form-control"
                                        name="minHeight"
                                        value={this.state.minHeight}
                                        required="required"
                                        placeholder="Enter Min Height"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.minHeight}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Table Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handletableNameChange}
                                        className="form-control"
                                        name="tableName"
                                        value={this.state.tableName}
                                        required="required"
                                        placeholder="Enter Table Name"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.tableName}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Column Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlecolumnNameChange}
                                        className="form-control"
                                        name="columnName"
                                        value={this.state.columnName}
                                        required="required"
                                        placeholder="Enter Column Name"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.columnName}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Column Value Operator
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlecolumnvalueOperatorChange
                                        }
                                        className="form-control"
                                        name="columnvalueOperator"
                                        value={this.state.columnvalueOperator}
                                        required="required"
                                        placeholder="Enter Column Value Operator"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.columnvalueOperator
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Value One
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlevalue1Change}
                                        className="form-control"
                                        name="value1"
                                        value={this.state.value1}
                                        required="required"
                                        placeholder="Enter Value"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.value1}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Logical Operator One
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlelogicalOperatorChange
                                        }
                                        className="form-control"
                                        name="logicalOperator"
                                        value={this.state.logicalOperator}
                                        required="required"
                                        placeholder="Enter Logical Operator"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.logicalOperator}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Column Name Two
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlecolumnName2Change}
                                        className="form-control"
                                        name="columnName2"
                                        value={this.state.columnName2}
                                        required="required"
                                        placeholder="Enter Column Name Two"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.columnName2}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Column Value Operator Two
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlecolumnvalueOperator2Change
                                        }
                                        className="form-control"
                                        name="columnvalueOperator2"
                                        value={this.state.columnvalueOperator2}
                                        required="required"
                                        placeholder="Enter Column Value Operator Two"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.columnvalueOperator2
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Value Two
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlevalue2Change}
                                        className="form-control"
                                        name="value2"
                                        value={this.state.value2}
                                        required="required"
                                        placeholder="Enter Value Two"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.value2}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Logical Operator Two
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlelogicalOperator2Change
                                        }
                                        className="form-control"
                                        name="logicalOperator2 "
                                        value={this.state.logicalOperator2}
                                        required="required"
                                        placeholder="Enter Logical Operator Two"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.logicalOperator}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Column Name Three
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlecolumnName3Change}
                                        className="form-control"
                                        name="columnName3"
                                        value={this.state.columnName3}
                                        required="required"
                                        placeholder="Enter Column Name Three"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.columnName3}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Column Value Operator Three
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlecolumnvalueOperator3Change
                                        }
                                        className="form-control"
                                        name="columnvalueOperator3"
                                        value={this.state.columnvalueOperator3}
                                        required="required"
                                        placeholder="Enter Column Value Operator Three"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.columnvalueOperator3
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />{" "}
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Value Three
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlevalue3Change}
                                        className="form-control"
                                        name="value3"
                                        value={this.state.value3}
                                        required="required"
                                        placeholder="Enter Value Three"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.value3}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Logical Operator Three
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlelogicalOperator3Change
                                        }
                                        className="form-control"
                                        name="logicalOperator3"
                                        value={this.state.logicalOperator3}
                                        required="required"
                                        placeholder="Enter Logical Operator Three"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.logicalOperator}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Column Name Four
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlecolumnName4Change}
                                        className="form-control"
                                        name="columnName4"
                                        value={this.state.columnName4}
                                        required="required"
                                        placeholder="Enter Column Name Four"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.columnName4}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Column Value Operator Four
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlecolumnvalueOperator4Change
                                        }
                                        className="form-control"
                                        name="columnvalueOperator4"
                                        value={this.state.columnvalueOperator4}
                                        required="required"
                                        placeholder="Enter Column Value Operator Four"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.columnvalueOperator4
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Value Four
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlevalue4Change}
                                        className="form-control"
                                        name="value4"
                                        value={this.state.value4}
                                        required="required"
                                        placeholder="Enter Value Four"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.value4}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Logical Operator Four
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlelogicalOperator4Change
                                        }
                                        className="form-control"
                                        name="logicalOperator4 "
                                        value={this.state.logicalOperator4}
                                        required="required"
                                        placeholder="Enter Logical Operator Three"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.logicalOperator}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Arithmetic Operators:
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlearithematicoperatorChange
                                        }
                                        className="form-control"
                                        name="arithematicOperator"
                                        value={this.state.arithematicOperator}
                                        required="required"
                                        placeholder="Enter Arithmetic Operators:"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.arithematicOperator
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Column of Arithmetic
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlecolumnofArithematicChange
                                        }
                                        className="form-control"
                                        name="columnofArithematic"
                                        value={this.state.columnofArithematic}
                                        required="required"
                                        placeholder="Enter Column of Arithmetic"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {
                                        this.state.errors.columnofArithematic
                                      }{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Group by Column
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={
                                          this.handlegroupbyColumnChange
                                        }
                                        className="form-control"
                                        name="groupbyColumn"
                                        value={this.state.groupbyColumn}
                                        required="required"
                                        placeholder="Enter Group by Column"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.groupbyColumn}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Extract Month
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleextractMonthChange}
                                        className="form-control"
                                        name="extractMonth"
                                        value={this.state.extractMonth}
                                        required="required"
                                        placeholder="Enter Extract Month"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.extractMonth}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Extract Year
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleextractYearChange}
                                        className="form-control"
                                        name="extractYear"
                                        value={this.state.extractYear}
                                        required="required"
                                        placeholder="Enter Extract Year"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.extractYear}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  {/* <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy One
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyoneChange}
                                        className="form-control"
                                        name="dummyone"
                                        value={this.state.dummyone}
                                        required="required"
                                        placeholder="Enter Dummy One"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyone}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Two
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummytwoChange}
                                        className="form-control"
                                        name="dummytwo"
                                        value={this.state.dummytwo}
                                        required="required"
                                        placeholder="Enter Dummy Two"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummytwo}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy three
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummythreeChange}
                                        className="form-control"
                                        name="dummythree"
                                        value={this.state.dummythree}
                                        required="required"
                                        placeholder="Enter Dummy three"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummythree}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Four
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyfourChange}
                                        className="form-control"
                                        name="dummyfour"
                                        value={this.state.dummyfour}
                                        required="required"
                                        placeholder="Enter Dummy Four"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyfour}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Five
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyfiveChange}
                                        className="form-control"
                                        name="dummyfive"
                                        value={this.state.dummyfive}
                                        required="required"
                                        placeholder="Enter Dummy Five"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyfive}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        DummySix
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummysixChange}
                                        className="form-control"
                                        name="dummysix"
                                        value={this.state.dummysix}
                                        required="required"
                                        placeholder="Enter DummySix"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummysix}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Seven
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummysevenChange}
                                        className="form-control"
                                        name="dummyseven"
                                        value={this.state.dummyseven}
                                        required="required"
                                        placeholder="Enter Dummy Seven"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyseven}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Eight
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyeightChange}
                                        className="form-control"
                                        name="dummyeight"
                                        value={this.state.dummyeight}
                                        required="required"
                                        placeholder="Enter Dummy Eight"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyeight}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Nine
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummynineChange}
                                        className="form-control"
                                        name="dummynine"
                                        value={this.state.dummynine}
                                        required="required"
                                        placeholder="Enter Dummy Nine"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummynine}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Ten
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummytenChange}
                                        className="form-control"
                                        name="dummyten"
                                        value={this.state.dummyten}
                                        required="required"
                                        placeholder="Enter Dummy Ten"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyten}{" "}
                                    </div>
                                  </Row> */}
                                  <br />
                                </Form>
                              </Row>
                            </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={this.WidgetdefinationsubmitHandler}
                                  style={{
                                    marginTop: "20px",
                                  }}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                      {/*offcanvas is over */}
                    </Col>
                  </Row>
                  <br />
                  <div>
                    {" "}
                    {this.state.isEditing && (
                      // Edit Offcanvas
                      <Offcanvas
                        show={this.state.isEditing}
                        onHide={() => this.setState({ isEditing: false })}
                        {...this.props}
                        style={{ width: "500px", height: "auto" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            Edit Widget definition
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <div className="form-horizontal form-label-left input_mask">
                            <Form className="form-horizontal form-label-left input_mask">
                              <Row className="divstyle">
                                <Row>
                                  <Form>
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Widget Id
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handlewidgetIdChange}
                                          className="form-control"
                                          name="widgetId"
                                          value={this.state.widgetId}
                                          required="required"
                                          placeholder="Enter Widget Id"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.widgetId}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Widget Name
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handlewidgetNameChange}
                                          className="form-control"
                                          name="widgetName"
                                          value={this.state.widgetName}
                                          required="required"
                                          placeholder="Enter Widget Name"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.widgetName}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Chart Type
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handlecharttypeChange}
                                          className="form-control"
                                          name="chartType"
                                          value={this.state.chartType}
                                          required="required"
                                          placeholder="Enter Chart Type"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.chartType}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Max Width
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handlemaxwidthChange}
                                          className="form-control"
                                          name="maxWidth"
                                          value={this.state.maxWidth}
                                          required="required"
                                          placeholder="Enter Max Width"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.maxWidth}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Min Width
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handleminWidthChange}
                                          className="form-control"
                                          name="minWidth"
                                          value={this.state.minWidth}
                                          required="required"
                                          placeholder="Enter Min Width"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.minWidth}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Max Height
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handlemaxHeightChange}
                                          className="form-control"
                                          name="maxHeight"
                                          value={this.state.maxHeight}
                                          required="required"
                                          placeholder="Enter Max Height"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.maxHeight}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Min Height
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handleminHeightChange}
                                          className="form-control"
                                          name="minHeight"
                                          value={this.state.minHeight}
                                          required="required"
                                          placeholder="Enter Min Height"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.minHeight}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Table Name
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handletableNameChange}
                                          className="form-control"
                                          name="tableName"
                                          value={this.state.tableName}
                                          required="required"
                                          placeholder="Enter Table Name"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.tableName}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Column Name
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handlecolumnNameChange}
                                          className="form-control"
                                          name="columnName"
                                          value={this.state.columnName}
                                          required="required"
                                          placeholder="Enter Column Name"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.columnName}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Column Value Operator
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={
                                            this.handlecolumnvalueOperatorChange
                                          }
                                          className="form-control"
                                          name="columnvalueOperator"
                                          value={this.state.columnvalueOperator}
                                          required="required"
                                          placeholder="Enter Column Value Operator"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {
                                          this.state.errors.columnvalueOperator
                                        }{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Value One
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handlevalue1Change}
                                          className="form-control"
                                          name="value1"
                                          value={this.state.value1}
                                          required="required"
                                          placeholder="Enter Value"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.value1}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Logical Operator One
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={
                                            this.handlelogicalOperatorChange
                                          }
                                          className="form-control"
                                          name="logicalOperator"
                                          value={this.state.logicalOperator}
                                          required="required"
                                          placeholder="Enter Logical Operator"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.logicalOperator}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Column Name Two
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={
                                            this.handlecolumnName2Change
                                          }
                                          className="form-control"
                                          name="columnName2"
                                          value={this.state.columnName2}
                                          required="required"
                                          placeholder="Enter Column Name Two"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.columnName2}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Column Value Operator Two
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={
                                            this
                                              .handlecolumnvalueOperator2Change
                                          }
                                          className="form-control"
                                          name="columnvalueOperator2"
                                          value={
                                            this.state.columnvalueOperator2
                                          }
                                          required="required"
                                          placeholder="Enter Column Value Operator Two"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {
                                          this.state.errors.columnvalueOperator2
                                        }{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Value Two
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handlevalue2Change}
                                          className="form-control"
                                          name="value2"
                                          value={this.state.value2}
                                          required="required"
                                          placeholder="Enter Value Two"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.value2}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Logical Operator Two
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={
                                            this.handlelogicalOperator2Change
                                          }
                                          className="form-control"
                                          name="logicalOperator2 "
                                          value={this.state.logicalOperator2}
                                          required="required"
                                          placeholder="Enter Logical Operator Two"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.logicalOperator}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Column Name Three
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={
                                            this.handlecolumnName3Change
                                          }
                                          className="form-control"
                                          name="columnName3"
                                          value={this.state.columnName3}
                                          required="required"
                                          placeholder="Enter Column Name Three"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.columnName3}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Column Value Operator Three
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={
                                            this
                                              .handlecolumnvalueOperator3Change
                                          }
                                          className="form-control"
                                          name="columnvalueOperator3"
                                          value={
                                            this.state.columnvalueOperator3
                                          }
                                          required="required"
                                          placeholder="Enter Column Value Operator Three"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {
                                          this.state.errors.columnvalueOperator3
                                        }{" "}
                                      </div>
                                    </Row>
                                    <br />{" "}
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Value Three
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handlevalue3Change}
                                          className="form-control"
                                          name="value3"
                                          value={this.state.value3}
                                          required="required"
                                          placeholder="Enter Value Three"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.value3}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Logical Operator Three
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={
                                            this.handlelogicalOperator3Change
                                          }
                                          className="form-control"
                                          name="logicalOperator3"
                                          value={this.state.logicalOperator3}
                                          required="required"
                                          placeholder="Enter Logical Operator Three"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.logicalOperator}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Column Name Four
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={
                                            this.handlecolumnName4Change
                                          }
                                          className="form-control"
                                          name="columnName4"
                                          value={this.state.columnName4}
                                          required="required"
                                          placeholder="Enter Column Name Four"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.columnName4}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Column Value Operator Four
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={
                                            this
                                              .handlecolumnvalueOperator4Change
                                          }
                                          className="form-control"
                                          name="columnvalueOperator4"
                                          value={
                                            this.state.columnvalueOperator4
                                          }
                                          required="required"
                                          placeholder="Enter Column Value Operator Four"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {
                                          this.state.errors.columnvalueOperator4
                                        }{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Value Four
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handlevalue4Change}
                                          className="form-control"
                                          name="value4"
                                          value={this.state.value4}
                                          required="required"
                                          placeholder="Enter Value Four"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.value4}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Logical Operator Four
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={
                                            this.handlelogicalOperator4Change
                                          }
                                          className="form-control"
                                          name="logicalOperator4 "
                                          value={this.state.logicalOperator4}
                                          required="required"
                                          placeholder="Enter Logical Operator Three"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.logicalOperator}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Arithmetic Operators:
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={
                                            this.handlearithematicoperatorChange
                                          }
                                          className="form-control"
                                          name="arithematicOperator"
                                          value={this.state.arithematicOperator}
                                          required="required"
                                          placeholder="Enter Arithmetic Operators:"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {
                                          this.state.errors.arithematicOperator
                                        }{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Column of Arithmetic
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={
                                            this.handlecolumnofArithematicChange
                                          }
                                          className="form-control"
                                          name="columnofArithematic"
                                          value={this.state.columnofArithematic}
                                          required="required"
                                          placeholder="Enter Column of Arithmetic"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {
                                          this.state.errors.columnofArithematic
                                        }{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Group by Column
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={
                                            this.handlegroupbyColumnChange
                                          }
                                          className="form-control"
                                          name="groupbyColumn"
                                          value={this.state.groupbyColumn}
                                          required="required"
                                          placeholder="Enter Group by Column"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.groupbyColumn}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Extract Month
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={
                                            this.handleextractMonthChange
                                          }
                                          className="form-control"
                                          name="extractMonth"
                                          value={this.state.extractMonth}
                                          required="required"
                                          placeholder="Enter Extract Month"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.extractMonth}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{
                                            paddingTop: "8px",
                                          }}
                                        >
                                          Extract Year
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={
                                            this.handleextractYearChange
                                          }
                                          className="form-control"
                                          name="extractYear"
                                          value={this.state.extractYear}
                                          required="required"
                                          placeholder="Enter Extract Year"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.extractYear}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    {/* <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy One
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyoneChange}
                                        className="form-control"
                                        name="dummyone"
                                        value={this.state.dummyone}
                                        required="required"
                                        placeholder="Enter Dummy One"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyone}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Two
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummytwoChange}
                                        className="form-control"
                                        name="dummytwo"
                                        value={this.state.dummytwo}
                                        required="required"
                                        placeholder="Enter Dummy Two"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummytwo}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy three
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummythreeChange}
                                        className="form-control"
                                        name="dummythree"
                                        value={this.state.dummythree}
                                        required="required"
                                        placeholder="Enter Dummy three"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummythree}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Four
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyfourChange}
                                        className="form-control"
                                        name="dummyfour"
                                        value={this.state.dummyfour}
                                        required="required"
                                        placeholder="Enter Dummy Four"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyfour}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Five
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyfiveChange}
                                        className="form-control"
                                        name="dummyfive"
                                        value={this.state.dummyfive}
                                        required="required"
                                        placeholder="Enter Dummy Five"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyfive}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        DummySix
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummysixChange}
                                        className="form-control"
                                        name="dummysix"
                                        value={this.state.dummysix}
                                        required="required"
                                        placeholder="Enter DummySix"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummysix}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Seven
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummysevenChange}
                                        className="form-control"
                                        name="dummyseven"
                                        value={this.state.dummyseven}
                                        required="required"
                                        placeholder="Enter Dummy Seven"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyseven}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Eight
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyeightChange}
                                        className="form-control"
                                        name="dummyeight"
                                        value={this.state.dummyeight}
                                        required="required"
                                        placeholder="Enter Dummy Eight"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyeight}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Nine
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummynineChange}
                                        className="form-control"
                                        name="dummynine"
                                        value={this.state.dummynine}
                                        required="required"
                                        placeholder="Enter Dummy Nine"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummynine}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Ten
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummytenChange}
                                        className="form-control"
                                        name="dummyten"
                                        value={this.state.dummyten}
                                        required="required"
                                        placeholder="Enter Dummy Ten"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyten}{" "}
                                    </div>
                                  </Row> */}
                                    <br />
                                  </Form>
                                </Row>
                              </Row>
                              <br />
                              <div style={{ float: "right" }}>
                                <button
                                  className="btn btn-primary me-2 mt-5"
                                  style={{
                                    backgroundColor: "#23547e",
                                    color: "#fff",
                                    borderColor: "#2e6da4",
                                  }}
                                  onClick={this.WidgetdefinationupdateHandler}
                                >
                                  Update
                                </button>
                              </div>
                            </Form>
                          </div>
                        </Offcanvas.Body>
                      </Offcanvas>
                    )}
                  </div>
                  <br />
                  <Row>
                    <div style={{ marginRight: "5px" }}>
                      {/* searchbar,pagination,filter */}
                      <input
                        type="text"
                        style={{ float: "left", marginBottom: "10px" }}
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <Button
                        variant="primary"
                        className="bi bi-plus "
                        onClick={this.handleOffcanvasShow}
                        style={{ float: "right" }}
                      >
                        New
                      </Button>
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Button, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";
import CryptoJS from "crypto-js";
import "react-datepicker/dist/react-datepicker.css";

export default class Posp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      filteredData: null,
      showPassword: false,
      startDate: new Date(),
      tableClms: [
        {
          name: "S.NO",
          width: "75px",
          id: "employees_Id",
          selector: (row, i) => row.employees_Id,
          sortable: true,
          cell: (row) => <span>{row.employees_Id}</span>,
        },
        {
          name: "First Name",
          id: "fName",
          selector: (row, i) => row.fName,
          sortable: true,
          cell: (row) => <span>{row.fName}</span>,
        },
        {
          name: "Last Name",
          id: "lName",
          selector: (row, i) => row.lName,
          sortable: true,
          cell: (row) => <span>{row.lName}</span>,
        },
        {
          name: "Employee Id",
          id: "eId",
          selector: (row, i) => row.eId,
          sortable: true,
          cell: (row) => <span>{row.eId}</span>,
        },
        {
          name: "Mobile No",
          id: "mobile",
          selector: (row, i) => row.mobile,
          sortable: true,
          cell: (row) => <span>{row.mobile}</span>,
        },
        {
          name: "Department",
          id: "dName",
          selector: (row, i) => row.dName,
          sortable: true,
          cell: (row) => <span>{row.dName}</span>,
        },
        {
          name: "Designation",
          id: "dsName",
          selector: (row, i) => row.dsName,
          sortable: true,
          cell: (row) => <span>{row.dsName}</span>,
        },
        {
          name: "DOJ",
          id: "dob",
          selector: (row, i) => row.doj,
          sortable: true,
          cell: (row) => <span>{row.doj}</span>,
        },

        {
          name: "Role ",
          id: "rName",
          selector: (row, i) => row.rName,
          sortable: true,
          cell: (row) => <span>{row.rName}</span>,
        },
        {
          name: "Reporting Manager",
          id: "rmName",
          selector: (row, i) => row.rmName,
          sortable: true,
          cell: (row) => <span>{row.rmName}</span>,
        },
        {
          name: "Employee Type",
          id: "etName",
          selector: (row, i) => row.etName,
          sortable: true,
          cell: (row) => <span>{row.etName}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={() => this.handleEnterViewMode(row)}
                className="bi bi-eye"
                style={{ marginRight: "3px" }}
              ></Button>

              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      fName: "",
      lName: "",
      gender: [],
      gId: "",
      dob: null,
      doj: null,
      dor: null,
      bloodGroup: "",
      pan: "",
      panProof: "",
      adhar: "",
      adharProof: "",
      imageProof: "",
      departments: [],
      dId: "",
      designations: [],
      dsId: "",
      mobile: "",
      aMobile: "",
      cPerson: "",
      relationships: [],
      rId: "",
      address: "",
      aProof: "",
      roles: [],
      roId: "",
      rmId: "",
      rvmId: "",
      employeetype: [],
      etId: "",
      eId: "",
      password: "",
      emailId: "",
      employees_Id: "",
    };
  }

  //viwe page
  handleEnterViewMode = (row) => {
    const employees_Id = row.employees_Id;
    sessionStorage.setItem("employees_Id", employees_Id);
    window.location.href = "/pospprofile";

    // Replace '/profile' with the actual URL for the profile page
  };

  //editing data
  handleEnterEditMode = (row) => {
    // this.setState({ cData: { ...row }, isEditing: true });
    var dob = "";
    if (row.dob !== "0000-00-00") dob = row.doj;
    this.setState({
      fName: row.fName,
      lName: row.lName,
      gId: row.gId,
      dob: dob,
      doj: row.doj,
      dor: row.dor,
      bloodGroup: row.bloodGroup,
      pan: row.pan,
      panProof: row.panProof,
      adhar: row.adhar,
      adharProof: row.adharProof,
      imageProof: row.imageProof,
      dId: row.dId,
      dsId: row.dsId,
      mobile: row.mobile,
      aMobile: row.aMobile,
      cPerson: row.cPerson,
      rId: row.rId,
      address: row.address,
      aProof: row.aProof,
      roId: row.roId,
      rmId: row.rmId,
      rvmId: row.rvmId,
      etId: row.etId,
      eId: row.eId,
      password: row.password,
      emailId: row.emailId,
      employees_Id: row.employees_Id,
      isEditing: true,
    });
  };
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  EmployeeseditHandler = (e) => {
    e.preventDefault();
    // const updateFormData = {...editFormData};
    // updateFormData['Employees_Id'] = e.target.parentElement.getAttribute("id");
    // setEditFormData(updateFormData);
    // setEdit(true);
  };

  EmployeesfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Employees/pospslist", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  validateForm() {
    let data = this.state;
    let errors = {};
    let formIsValid = true;
    // Regular expressions for validation
    // const panRegex = /[A-Z]{5}[0-9]{4}[A-Z]{1}/;
    // const adharRegex = /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/;
    const mobileRegex = /^[6-9]\d{9}$/;
    // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!data["eId"]) {
      formIsValid = false;
      toast.info("*Please Enter Employee Id.");
    } else if (!data["fName"]) {
      formIsValid = false;
      toast.info("*Please Enter First Name.");
    } else if (!data["lName"]) {
      formIsValid = false;
      toast.info("*Please Enter Last Name.");
    } else if (!data["gId"]) {
      formIsValid = false;
      toast.info("*Please Select Gender.");
    } else if (
      !data["mobile"] ||
      !mobileRegex.test(data["mobile"]) ||
      data["mobile"].length !== 10
    ) {
      formIsValid = false;
      toast.info("*Please Enter a Valid 10-digit Mobile Number.");
    }
    // else if (data["emailId"] === "" || !emailRegex.test(data["emailId"])) {
    //   formIsValid = false;
    //   toast.info("*Please Enter a Valid Email Id.");
    // } else if (!data["dob"]) {
    //   formIsValid = false;
    //   toast.info("*Please Enter Date of Birth.");
    // } else if (!data["doj"]) {
    //   formIsValid = false;
    //   toast.info("*Please Enter Date of Joining.");
    // } else if (!data["bloodGroup"]) {
    //   formIsValid = false;
    //   toast.info("*Please Enter Blood Group.");
    // } else if (!data["pan"] || !panRegex.test(data["pan"])) {
    //   formIsValid = false;
    //   toast.info("*Please Enter a Valid PAN Card Number.");
    // } else if (
    //   !data["adhar"] ||
    //   !adharRegex.test(data["adhar"]) ||
    //   data["adhar"].length !== 12
    // ) {
    //   formIsValid = false;
    //   toast.info("*Please Enter a Valid 12-digit Aadhaar Number.");
    // } else if (!data["cPerson"]) {
    //   formIsValid = false;
    //   toast.info("*Please Enter Emergency Contact Person.");
    // } else if (
    //   !data["aMobile"] ||
    //   !mobileRegex.test(data["aMobile"]) ||
    //   data["aMobile"].length !== 10
    // ) {
    //   formIsValid = false;
    //   toast.info("*Please Enter Emergency Mobile Number.");
    // } else if (!data["rId"]) {
    //   formIsValid = false;
    //   toast.info("*Please Enter Relationship.");
    // } else if (!data["address"]) {
    //   formIsValid = false;
    //   toast.info("*Please Enter Address.");
    // } else if (!data["dId"]) {
    //   formIsValid = false;
    //   toast.info("*Please Select Department.");
    // } else if (!data["roId"]) {
    //   formIsValid = false;
    //   toast.info("*Please Select Role.");
    // } else if (!data["dsId"]) {
    //   formIsValid = false;
    //   toast.info("*Please Select Designation.");
    // } else if (!data["rmId"]) {
    //   formIsValid = false;
    //   toast.info("*Please Select Reporting Manager.");
    // } else if (!data["rvmId"]) {
    //   formIsValid = false;
    //   toast.info("*Please Select Reviewing Manager .");
    // } else if (!data["etId"]) {
    //   formIsValid = false;
    //   toast.info("*Please Select Employee Type.");
    // }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  EmployeessubmitHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    if (this.validateForm()) {
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("fName", this.state.fName);
      formData.append("lName", this.state.lName);
      formData.append("gender", this.state.gId);
      formData.append("dob", this.state.dob);
      formData.append("doj", this.state.doj);
      formData.append("dor", this.state.dor);
      formData.append("bloodGroup", this.state.bloodGroup);
      formData.append("pan", this.state.pan);
      formData.append("panProof", this.state.panProof);
      formData.append("adhar", this.state.adhar);
      formData.append("adharProof", this.state.adharProof);
      formData.append("imageProof", this.state.imageProof);
      formData.append("dId", this.state.dId);
      formData.append("dsId", this.state.dsId);
      formData.append("mobile", this.state.mobile);
      formData.append("aMobile", this.state.aMobile);
      formData.append("cPerson", this.state.cPerson);
      formData.append("rId", this.state.rId);
      formData.append("address", this.state.address);
      formData.append("aProof", this.state.aProof);
      formData.append("roId", this.state.roId);
      formData.append("rmId", this.state.rmId);
      formData.append("rvmId", this.state.rvmId);
      formData.append("etId", this.state.etId);
      formData.append("eId", this.state.eId);
      formData.append(
        "password",
        CryptoJS.HmacMD5(this.state.password, configData.secret).toString()
      );
      formData.append("emailId", this.state.emailId);

      fetch(configData.api_url + "C_Employees/add", {
        // Enter your IP address here

        method: "POST",
        //headers :{ 'Content-Type' : 'application/json'},
        mode: "cors",
        body: formData, // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            this.EmployeesfetchHandler();
            this.handleOffcanvasClose();
            this.setState({
              fName: "",
              lName: "",
              gId: "",
              dob: "",
              doj: "",
              dor: "",
              bloodGroup: "",
              pan: "",
              panProof: "",
              adhar: "",
              adharProof: "",
              imageProof: "",
              dId: "",
              dsId: "",
              mobile: "",
              aMobile: "",
              cPerson: "",
              rId: "",
              address: "",
              aProof: "",
              roId: "",
              rmId: "",
              rvmId: "",
              etId: "",
              eId: "",
              password: "",
              emailId: "",
            });
          }
        });
    }
  };
  EmployeesupdateHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);

    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("fName", this.state.fName);
    formData.append("lName", this.state.lName);
    formData.append("gender", this.state.gId);
    formData.append("dob", this.state.dob);
    formData.append("doj", this.state.doj);
    formData.append("bloodGroup", this.state.bloodGroup);
    formData.append("pan", this.state.pan);
    formData.append("adhar", this.state.adhar);
    formData.append("dId", this.state.dId);
    formData.append("mobile", this.state.mobile);
    formData.append("aMobile", this.state.aMobile);
    formData.append("cPerson", this.state.cPerson);
    formData.append("rId", this.state.rId);
    formData.append("address", this.state.address);
    formData.append("aProof", this.state.aProof);
    formData.append("roId", this.state.roId);
    formData.append("rmId", this.state.rmId);
    formData.append("rvmId", this.state.rvmId);
    formData.append("etId", this.state.etId);
    formData.append("eId", this.state.eId);
    formData.append("employees_Id", this.state.employees_Id);
    fetch(configData.api_url + "C_Employees/update", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.EmployeesfetchHandler();
          this.handleOffcanvasClose();
          this.setState({
            fName: "",
            lName: "",
            gId: "",
            dob: "",
            doj: "",
            dor: "",
            bloodGroup: "",
            pan: "",
            panProof: "",
            adhar: "",
            adharProof: "",
            imageProof: "",
            dId: "",
            dsId: "",
            mobile: "",
            aMobile: "",
            cPerson: "",
            rId: "",
            address: "",
            aProof: "",
            roId: "",
            rmId: "",
            rvmId: "",
            etId: "",
            eId: "",
            password: "",
            emailId: "",
          });
        }
      });
  };

  componentDidMount() {
    this.EmployeesfetchHandler();
    this.genderfetchHandler();
    this.departmentsfetchHandler();
    this.designationsfetchHandler();
    this.relationshipsfetchHandler();
    this.rolesfetchHandler();
    this.employeetypefetchHandler();
  }
  handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "fName") {
      const lettersAndSpacesRegex = /^[A-Za-z\s/./-/_]*$/;
      if (!lettersAndSpacesRegex.test(value) && value !== "") {
        toast.error("Not Allowed Special Characters And Numbers.");

        return;
      }
    }
    if (name === "lName") {
      const lettersAndSpacesRegex = /^[A-Za-z\s/./-/_]*$/;
      if (!lettersAndSpacesRegex.test(value) && value !== "") {
        toast.error("Not Allowed Special Characters And Numbers.");

        return;
      }
    }
    if (name === "cPerson") {
      const lettersAndSpacesRegex = /^[A-Za-z\s/./-/_]*$/;
      if (!lettersAndSpacesRegex.test(value) && value !== "") {
        toast.error("Not Allowed Special Characters And Numbers.");

        return;
      }
    }
    if (name === "zipcode" && value.length > 0 && value.charAt(0) === "0") {
      // Display an error toast message or handle the error as needed
      toast.error("Zipcode cannot start with the number 0.");
      return; // Stop further processing for this field
    }

    if (name === "mobile" && value.length > 0) {
      const firstDigit = parseInt(value.charAt(0), 10);

      if (firstDigit < 6 || firstDigit > 9) {
        // Display an error toast message or handle the error as needed
        toast.error("Please Enter Valid Mobile Number.");
        return; // Stop further processing for this field
      }
    }
    if (name === "aMobile" && value.length > 0) {
      const firstDigit = parseInt(value.charAt(0), 10);

      if (firstDigit < 6 || firstDigit > 9) {
        // Display an error toast message or handle the error as needed
        toast.error("Please Enter Valid Mobile Number.");
        return; // Stop further processing for this field
      }
    }
    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: "", // Clear the corresponding error when the field value changes
      },
    });
  };
  handlefNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ fName: fieldValue });
  };
  handlelNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ lName: fieldValue });
  };
  handlegenderChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ gId: fieldValue });
  };
  genderfetchHandler = () => {
    fetch(configData.api_url + "C_Gender/list", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ gender: data.list });
        }
      });
  };
  handledobChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ dob: fieldValue });
  };
  handledojChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ doj: fieldValue });
  };
  handledorChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ dor: fieldValue });
  };
  handlebloodGroupChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ bloodGroup: fieldValue });
  };
  handlepanChange = (e) => {
    // e.preventDefault();
    // const fieldValue = e.target.value;
    // this.setState({ pan: fieldValue });
    const { name, value } = e.target;
    const uppercaseValue = value.toUpperCase();

    this.setState({
      [name]: name === "pan" ? uppercaseValue : value,
      errors: {
        ...this.state.errors,
        [name]: uppercaseValue,
      },
    });
  };
  handleAdharChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ adhar: fieldValue });
  };
  handledIdChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ dId: fieldValue });
  };
  handledsIdChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ dsId: fieldValue });
  };
  departmentsfetchHandler = () => {
    fetch(configData.api_url + "C_Departments/list", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ departments: data.list });
        }
      });
  };
  designationsfetchHandler = () => {
    fetch(configData.api_url + "C_Designations/list", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ designations: data.list });
        }
      });
  };
  onKeyDown = (event) => {
    if (event.key === " ") {
      event.preventDefault();
      toast.error("Spaces are not allowed.");
    }
  };

  handleaMobileChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ aMobile: fieldValue });
  };
  handlecPersonChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ cPerson: fieldValue });
  };
  handlerIdChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ rId: fieldValue });
  };
  handlermIdChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ rmId: fieldValue });
  };
  handlervmIdChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ rvmId: fieldValue });
  };
  relationshipsfetchHandler = () => {
    fetch(configData.api_url + "C_Relationships/list", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ relationships: data.list });
        }
      });
  };
  handleaddressChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ address: fieldValue });
  };
  handleaProofChange = (e) => {
    const file = e.target.files[0];
    // const newFormData = this.state.aProof;
    // newFormData["aProof"] = file;
    this.setState({ aProof: file });
  };
  handleaPancardUploadChange = (e) => {
    const file = e.target.files[0];
    // const newFormData = this.state.panProof;
    // newFormData["panProof"] = file;
    this.setState({ panProof: file });
  };
  handleAdharcardUploadChange = (e) => {
    const file = e.target.files[0];
    // const newFormData = this.state.panProof;
    // newFormData["panProof"] = file;
    this.setState({ adharProof: file });
  };
  handleImageUploadChange = (e) => {
    const file = e.target.files[0];
    // const newFormData = this.state.panProof;
    // newFormData["panProof"] = file;
    this.setState({ imageProof: file });
  };
  handleroIdChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ roId: fieldValue });
  };

  rolesfetchHandler = () => {
    fetch(configData.api_url + "roles/all", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ roles: data.list });
        }
      });
  };

  handleetIdChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ etId: fieldValue });
  };
  employeetypefetchHandler = () => {
    fetch(configData.api_url + "C_Employeetype/list", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ employeetype: data.list });
        }
      });
  };
  handleeIdChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ eId: fieldValue });
  };
  handlepasswordChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ password: fieldValue });
  };
  handleemailIdChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ emailId: fieldValue });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
    console.log(`Editing row with ID ${row.id}`);
    // implement your edit logic here
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    const employees_Id = row.employees_Id;
    // console.log(e.target.parentElement);
    formData.append("employees_Id", employees_Id);
    fetch(configData.api_url + "C_Employees/delete", {
      // Enter your IP address here
      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.EmployeesfetchHandler();
        }
      });
    //implement your delete logic here
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };
  handleDChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      console.log(date);
      console.log(formattedDate);
      // Assuming you want to update the 'dob' state with the selected date
      this.setState({
        dob: formattedDate,
        startDate: new Date(),
      });
    }
  };
  handleDOJChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      console.log(date);
      console.log(formattedDate);
      // Assuming you want to update the 'dob' state with the selected date
      this.setState({
        doj: formattedDate,
        startDate: new Date(),
      });
    }
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );
    const previousYearStart = new Date();
    previousYearStart.setFullYear(previousYearStart.getFullYear() - 100);
    previousYearStart.setMonth(0); // Set the month to January
    previousYearStart.setDate(1); // Set the date to the first day of January

    // Example end year
    const endYear = new Date();
    endYear.setFullYear(endYear.getFullYear() + 10);
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 18);

    return (
      <div className="page-title">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                  dt-bootstrap no-footer"
                >
                  <div className="title_left">
                    <h3
                      className="logoskybluecolor"
                      style={{ textAlign: "start" }}
                    >
                      POSPS
                    </h3>
                  </div>
                  <Row>
                    <div style={{ marginRight: "5px" }}>
                      {/* searchbar,pagination,filter */}
                      <input
                        type="text"
                        style={{ float: "left", marginBottom: "10px" }}
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        paginationRowsPerPageOptions={[
                          10, 20, 30, 50, 60, 70, 80, 90, 100,
                        ]}
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

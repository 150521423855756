import React, { Component } from "react";
import {
  Button,
  Container,
  Row,
  Form,
  Col,
  Offcanvas,
  Table,
  Modal,
} from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";
// import "./Ratecharts.css";
export default class RulePage extends Component {
  constructor(props) {
    super(props);
    this.inputFileRef = React.createRef();
    this.state = {
      show: false,
      showOne: false,
      message: "",
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      apis: [],
      templates: [],
      rules: [],
      tables: [],
      columns: [],
      rcolumns: [],
      columnsWT: [],
      conditions: [],
      actions: [],
      rId: "",
      rConditions: {
        tableName: "",
        columnName: "",
        cOperator: "",
        cdate: "",
        ctime: "",
        cValue: "",
        cGate: "",
        currentPage: 1,
      },
      rActions: {
        rtTableName: "--",
        rtColumnName: "--",
        rtTemplate: "--",
        rtType: "--",
        rtGate: "--",
        currentPage: 1,
      },
      rDate: {
        rDateTableName: "--",
        rDateColumnName: "--",
        rDateTemplate: "--",
        rDateType: "--",
        rDateGate: "--",
        currentPage: 1,
      },
      receive_parameters: [],
      send_parameters: [],
      // offcanvas form control data
      ruleData: {
        rName: "",
        rDescription: "",
        reTime: "",
        rMonth: "",
        rDate: "",
        rTime: "",
        rSelect: "",
        minutes: 0,
        seconds: 0,
        rMinutes: 0,
        rSeconds: 0,
        currentPage: 1,
      },
      errors: {},
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.rId,
          sortable: true,
          cell: (row) => <span>{row.rId}</span>,
        },
        {
          name: "Name",
          selector: (row, i) => row.rName,
          sortable: true,
          cell: (row) => <span>{row.rName}</span>,
        },
        {
          name: "Description",
          selector: (row, i) => row.rDescription,
          sortable: true,
          cell: (row) => <span>{row.rDescription}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row, index) => (
            <div>
              {/* edit button and delete button */}
              <button
                style={{ marginLeft: "5px" }}
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen btn btn-primary"
              ></button>{" "}
              <button
                variant="danger"
                onClick={() => this.handleDelete(row, index)}
                className="bi bi-trash btn btn-danger"
              ></button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      isEditing: false, // Flag to indicate edit mode
      isNewEntry: false,
    };
  }
  //edit
  handleEnterEditMode = (row) => {
    // this.setState({ cData: { ...row }, isEditing: true });
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    this.setState({
      rId: row.rId,
      ruleData: {
        rName: row.rName,
        rDescription: row.rDescription,
        reTime: row.reTime,
        rMonth: row.rMonth,
        rDate: row.rDate,
        rTime: row.rTime,
        rSelect: row.rSelect,
        minutes: row.minutes,
        seconds: row.seconds,
        rMinutes: row.rMinutes,
        rSeconds: row.rSeconds,
      },
      receive_parameters: row.receive_parameters, // No need to parse
      send_parameters: row.send_parameters,
      rConditions: {
        tableName: row.send_parameters[0].tableName,
        columnName: row.send_parameters[0].columnName,
        cOperator: row.send_parameters[0].cOperator,
        cdate: row.send_parameters[0].cdate,
        ctime: row.send_parameters[0].ctime,
        cValue: row.send_parameters[0].cValue,
        cGate: row.send_parameters[0].cGate,
      },
      rActions: {
        rtTableName: "row.rtTableName",
        rtColumnName: "row.rtColumnName",
        rtTemplate: "row.rtTemplate",
        rtType: "row.rtType",
        rtGate: "row.rtGate",
        currentPage: 1,
      },
      rDate: {
        rDateTableName: "row.rDateTableName",
        rDateColumnName: "row.rDateColumnName",
        rDateTemplate: "row.rDateTemplate",
        rDateType: "row.rDateType",
        rDateGate: "row.rDateGate",
        currentPage: 1,
      },
      isEditing: true,
    });
  };
  //modal
  handleButtonClick = () => {
    this.inputFileRef.current.click();
  };
  handleCloseOne = () => {
    this.setState({ showOne: false });
  };

  handleShowOne = () => {
    this.setState({ showOne: true });
  };
  // offcanvas Open
  handleShow = () => {
    this.setState({
      show: true,
      rId: "",
      rConditions: {
        tableName: "",
        columnName: "",
        cOperator: "",
        cdate: "",
        ctime: "",
        cValue: "",
        cGate: "",
        currentPage: 1,
      },
      rActions: {
        rtTableName: "--",
        rtColumnName: "--",
        rtTemplate: "--",
        rtType: "--",
        rtGate: "--",
        currentPage: 1,
      },
      rDate: {
        rDateTableName: "--",
        rDateColumnName: "--",
        rDateTemplate: "--",
        rDateType: "--",
        rDateGate: "--",
        currentPage: 1,
      },
      receive_parameters: [],
      send_parameters: [],
      ruleData: {
        rName: "",
        rDescription: "",
        reTime: "",
        rMonth: "",
        rDate: "",
        rTime: "",
        rSelect: "",
        minutes: 0,
        seconds: 0,
        rMinutes: 0,
        rSeconds: 0,
        currentPage: 1,
      },
    });
  };
  // Offcanvas close button
  handleClose = () => {
    this.setState({ show: false });
  };

  addSendRow = (e) => {
    e.preventDefault();

    if (this.keyvalidate()) {
      this.setState((prevState) => ({
        conditions: [...prevState.conditions, this.state.rConditions],
      }));

      this.setState(
        (prevState) => ({
          rConditions: {
            ...prevState.rConditions,
            tableName: "",
            columnName: "",
            cOperator: "",
            cdate: "",
            ctime: "",
            cValue: "",
            cGate: "",
          },
        }),
        () => {
          ////console.log(this.state.rConditions); // Display cleared data in ////console
        }
      );
    }
  };
  keyvalidate() {
    const { currentStep, rConditions } = this.state;
    let formIsValid = true;

    if (!rConditions["tableName"]) {
      formIsValid = false;
      toast.info("*Enter Table Name.");
    } else if (!rConditions["columnName"]) {
      formIsValid = false;
      toast.info("* Select Column Name.");
    } else if (!rConditions["cOperator"]) {
      formIsValid = false;
      toast.info("* Select Operator.");
    } else if (!rConditions["cValue"]) {
      formIsValid = false;
      toast.info("* Enter Operator.");
    } else if (!rConditions["cGate"]) {
      formIsValid = false;
      toast.info("* Select Gate.");
    } else {
      // If all fields are filled, proceed to the next step
      this.setState({ currentStep: currentStep + 1 });
    }
    return formIsValid;
  }

  handleColumnChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.rConditions;
    const selectedLabel = e.target.options[e.target.selectedIndex].text;
    inputData[fieldName] = selectedLabel;
    this.setState({ columnValue: fieldValue });
    ////console.log(fieldValue);
  };

  inputSChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.rConditions;
    inputData[fieldName] = fieldValue;
    this.setState({ rConditions: inputData });
    //this.setState({visibletype: e.target.value})
  };
  inputtableSChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.rConditions;
    inputData[fieldName] = fieldValue;
    this.setState({ rConditions: inputData });
    this.getColumnsWT(fieldValue, "columns");
  };

  handleRemoveSpecificRowS = (idx) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    const tempRows = this.state.conditions; // Create a copy of the array
    tempRows.splice(idx, 1); // Remove the row at the specified index
    this.setState({ conditions: tempRows });
  };
  // Offcanvas Formchange
  inputChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const newFormData = this.state.ruleData;
    newFormData[fieldName] = fieldValue;
    this.setState({ ruleData: newFormData });
  };

  handleRemoveSpecificRowSAction = (idx) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    const tempRows = this.state.actions;
    tempRows.splice(idx, 1);
    this.setState({ actions: tempRows });
  };

  inputRChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    this.setState((prevState) => ({
      rActions: {
        ...prevState.rActions,
        [fieldName]: fieldValue,
      },
      visibletype: fieldValue,
    }));
  };

  inputtableRChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.rActions;
    inputData[fieldName] = fieldValue;
    this.setState({ rActions: inputData });
    this.getColumns(fieldValue, "rcolumns");
  };

  addFormsubmitdata = (e) => {
    e.preventDefault();
    const newAction = {
      rtTableName: this.state.rActions.rtTableName,
      rtColumnName: this.state.rActions.rtColumnName,
      rtTemplate: this.state.rActions.rtTemplate,
      rtType: this.state.rActions.rtType,
      rtGate: this.state.rActions.rtGate,
    };

    // Add the newAction object to the actions array
    this.setState((prevState) => ({
      actions: [...prevState.actions, newAction],
      rActions: {
        rtTableName: "--",
        rtColumnName: "--",
        rtTemplate: "--",
        rtType: "--",
        rtGate: "--",
      },
    }));
  };
  // offcanvas events//
  onSubmitAPI = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("rName", this.state.ruleData.rName);
      formData.append("rDescription", this.state.ruleData.rDescription);
      formData.append("reTime", this.state.ruleData.reTime);
      formData.append("minutes", this.state.ruleData.minutes);
      formData.append("seconds", this.state.ruleData.seconds);
      formData.append("rMonth", this.state.ruleData.rMonth);
      formData.append("rDate", this.state.ruleData.rDate);
      formData.append("rTime", this.state.ruleData.rTime);
      formData.append("rMinutes", this.state.ruleData.rMinutes);
      formData.append("rSeconds", this.state.ruleData.rSeconds);
      formData.append("rSelect", this.state.ruleData.rSelect);
      formData.append("rconditions", JSON.stringify(this.state.conditions));
      formData.append("ractions", JSON.stringify(this.state.actions));
      fetch(configData.api_url + "/rules/add/", {
        method: "POST",
        mode: "cors",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            // ////console.log(data.status);
            this.setState({
              show: false,
              conditions: [],
              actions: [],
              ruleData: {
                rName: "",
                rDescription: "",
                reTime: "",
                rMonth: "",
                rDate: "",
                rTime: "",
                rSelect: "",
                minutes: 0,
                seconds: 0,
                rMinutes: 0,
                rSeconds: 0,
              },
              rConditions: {
                tableName: "",
                columnName: "",
                cOperator: "",
                cdate: "",
                ctime: "",
                cValue: "",
                cGate: "",
              },
              rActions: {
                rtTableName: "--",
                rtColumnName: "--",
                rtTemplate: "--",
                rtType: "--",
                rtGate: "--",
              },
            });
          }
        });
    }
    //console.log(this.state.ruleData);
  };

  validateForm() {
    const { currentStep, ruleData } = this.state;
    let formIsValid = true;

    if (!ruleData["rName"]) {
      formIsValid = false;
      toast.info("*Please Enter Rule Name.");
    } else if (!ruleData["rDescription"]) {
      formIsValid = false;
      toast.info("*Please Enter Description.");
    } else if (!ruleData["reTime"]) {
      formIsValid = false;
      toast.info("*Please Enter Time.");
    } else if (!ruleData["rMonth"]) {
      formIsValid = false;
      toast.info("*Please Enter Month.");
    } else if (!ruleData["rDate"]) {
      formIsValid = false;
      toast.info("*Please Enter Date.");
    } else if (!ruleData["rTime"]) {
      formIsValid = false;
      toast.info("*Please Enter Time.");
    } else if (!ruleData["rSelect"]) {
      formIsValid = false;
      toast.info("*Please Select Option.");
    } else if (!ruleData["minutes"]) {
      formIsValid = false;
      toast.info("*Please Enter Minutes.");
    } else if (!ruleData["seconds"]) {
      formIsValid = false;
      toast.info("*Please Enter Seconds.");
    } else if (!ruleData["rMinutes"]) {
      formIsValid = false;
      toast.info("*Please Enter Minutes.");
    } else if (!ruleData["rSeconds"]) {
      formIsValid = false;
      toast.info("*Please Enter Seconds.");
    } else {
      // If all fields are filled, proceed to the next step
      this.setState({ currentStep: currentStep + 1 });
    }
    return formIsValid;
  }
  rulesHandlerupdate = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("rName", this.state.ruleData.rName);
      formData.append("rDescription", this.state.ruleData.rDescription);
      formData.append("reTime", this.state.ruleData.reTime);
      formData.append("minutes", this.state.ruleData.minutes);
      formData.append("seconds", this.state.ruleData.seconds);
      formData.append("rMonth", this.state.ruleData.rMonth);
      formData.append("rDate", this.state.ruleData.rDate);
      formData.append("rTime", this.state.ruleData.rTime);
      formData.append("rMinutes", this.state.ruleData.rMinutes);
      formData.append("rSeconds", this.state.ruleData.rSeconds);
      formData.append("rSelect", this.state.ruleData.rSelect);
      formData.append("rconditions", JSON.stringify(this.state.conditions));
      formData.append("ractions", JSON.stringify(this.state.actions));
      formData.append("rId", this.state.rId);
      fetch(configData.api_url + "/rules/update", {
        method: "POST",
        mode: "cors",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            // ////console.log(data.status);
            this.apisfetchHandler();
            this.handleClose();
            this.setState({
              show: false,
              conditions: [],
              actions: [],
              ruleData: {
                rName: "",
                rDescription: "",
                reTime: "",
                rMonth: "",
                rDate: "",
                rTime: "",
                rSelect: "",
                minutes: 0,
                seconds: 0,
                rMinutes: 0,
                rSeconds: 0,
              },
              rConditions: {
                tableName: "",
                columnName: "",
                cOperator: "",
                cdate: "",
                ctime: "",
                cValue: "",
                cGate: "",
              },
              rActions: {
                rtTableName: "--",
                rtColumnName: "--",
                rtTemplate: "--",
                rtType: "--",
                rtGate: "--",
              },
            });
          }
        });
    }
    //console.log(this.state.ruleData);
  };
  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "/rules/list", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ rules: data.list });
        }
      });
  };
  getTables = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "/masters/gettables", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({ tables: data.tables });
          //console.log(this.state.tables);
        }
      });
  };

  getColumns = (tableName, rcolumn) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("tableName", tableName);
    fetch(configData.api_url + "/masters/getcolumns", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({ [rcolumn]: data.columns });
        }
      });
  };
  getColumnsWT = (tableName, rcolumn) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("tableName", tableName);
    fetch(configData.api_url + "/masters/getcolumns", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({ [rcolumn]: data.columnsWT });
        }
      });
  };
  apisfetchHandlerTemplate = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "/templates/list", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ templates: data.list });
        }
      });
  };
  apisfetchHandlerdata = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "/apis/list", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ apis: data.list });
          ////console.log(this.state.apis);
          ////console.log(this.state.tableClms);
        }
      });
  };

  componentDidMount() {
    this.apisfetchHandler();
    this.getTables();
    this.apisfetchHandlerTemplate();
    this.apisfetchHandlerdata();
  }
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    // const rowid = row.id;
    //console.log(`Editing row with ID ${rowid}`);
    // implement your edit logic here
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("rId", row.rId);
    fetch(configData.api_url + "/rules/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.apisfetchHandler();
        }
      });
    ////console.log(row.rId);
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.rules.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  inputDChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.rDate;
    inputData[fieldName] = fieldValue;
    this.setState({ rDate: inputData });
    this.setState({ visibletype: e.target.value });
  };
  inputtableDChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.rDate;
    inputData[fieldName] = fieldValue;
    this.setState({ rdate: inputData });
    // this.getColumns(fieldValue, "rcolumns");
  };
  handleDurationChange = (event) => {
    const { name, value } = event.target;
    const { ruleData } = this.state;
    const updatedDuration = { ...ruleData, [name]: parseInt(value) };

    if (updatedDuration.seconds >= 60) {
      updatedDuration.minutes += Math.floor(updatedDuration.seconds / 60);
      updatedDuration.seconds %= 60;
    }

    if (updatedDuration.minutes >= 60) {
      updatedDuration.hours += Math.floor(updatedDuration.minutes / 60);
      updatedDuration.minutes %= 60;
    }

    if (updatedDuration.rSeconds >= 60) {
      updatedDuration.rMinutes += Math.floor(updatedDuration.rSeconds / 60);
      updatedDuration.rSeconds %= 60;
    }

    if (updatedDuration.rMinutes >= 60) {
      updatedDuration.hours += Math.floor(updatedDuration.rMinutes / 60);
      updatedDuration.rMinutes %= 60;
    }

    this.setState({ ruleData: updatedDuration });
  };

  render() {
    const DefaultData = this.state.rConditions;
    //////console.log(this.state.tableClms);
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.rules.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div>
        <Row>
          <Col lg="12" md="12" sm="12">
            <h2 style={{ float: "left" }}>RULES PAGE</h2>
          </Col>
        </Row>
        <div className="x_panel">
          <div>
            <Row>
              <Col
                className="d-flex justify-content-end"
                style={{ marginRight: "5px", marginTop: "20px" }}
              >
                <Button
                  variant="warning"
                  className="me-2 bi bi-upload"
                  onClick={this.handleShowOne}
                >
                  Upload *csv
                </Button>
                <Button variant="info" className="me-2 bi bi-download">
                  Download *csv
                </Button>
                <Button
                  variant="primary"
                  className="bi bi-plus "
                  value={DefaultData}
                  onClick={this.handleShow}
                >
                  New
                </Button>
                <div>
                  <Modal show={this.state.showOne} onHide={this.handleCloseOne}>
                    <Modal.Header closeButton>
                      <Modal.Title>Upload *csv file</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Button
                        variant="success"
                        className="bi bi-upload"
                        onClick={this.handleButtonClick}
                      >
                        {" "}
                        Upload
                      </Button>
                      <input
                        type="file"
                        ref={this.inputFileRef}
                        style={{ display: "none" }}
                        accept=".csv"
                        onChange={this.handleFileChange}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={this.handleCloseOne}>
                        Close
                      </Button>
                      <Button variant="primary" onClick={this.handleCloseOne}>
                        Save Changes
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <Offcanvas
                  show={this.state.show}
                  onHide={this.handleClose}
                  {...this.props}
                  style={{ width: "960px" }}
                  placement="end"
                  backdrop="true"
                >
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title>New Rule page</Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Container style={{ overflowY: "auto", height: "auto" }}>
                      <Row className="divstyle">
                        <Row>
                          <Form>
                            <Row>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicPassword"
                              >
                                <Row>
                                  <Col lg="4" md="4">
                                    <Form.Label>Rule Name</Form.Label>
                                  </Col>
                                  <Col lg="8" md="8">
                                    <Form.Control
                                      name={"rName"}
                                      value={DefaultData.rName}
                                      onChange={this.inputChange}
                                      required="required"
                                      type="text"
                                      placeholder="Enter Rule Name"
                                      className="form-control"
                                    />
                                  </Col>
                                </Row>
                              </Form.Group>
                            </Row>
                            <Row>
                              <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlTextarea1"
                              >
                                <Row>
                                  <Col lg="4" md="4">
                                    <Form.Label>Rule description</Form.Label>
                                  </Col>
                                  <Col lg="8" md="8">
                                    <Form.Control
                                      as="textarea"
                                      rows={3}
                                      name={"rDescription"}
                                      value={DefaultData.rDescription}
                                      onChange={this.inputChange}
                                      required="required"
                                      placeholder="Enter Rule Description"
                                      className="form-control"
                                    />
                                    <div
                                      className="errorMsg text-center"
                                      style={{ color: "red" }}
                                    >
                                      {this.state.errors.rDescription}
                                    </div>
                                  </Col>
                                </Row>
                              </Form.Group>
                            </Row>
                            <Row>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicPassword"
                              >
                                <Row>
                                  <Col lg="4" md="4">
                                    <Form.Label>Rule Execution Time</Form.Label>
                                  </Col>
                                  <Col lg="8" md="8">
                                    <Row className="d-flex">
                                      <Col>
                                        <Form.Control
                                          // // id="appt-time"
                                          // step="2"
                                          name="reTime"
                                          value={this.state.ruleData.reTime}
                                          onChange={this.inputChange}
                                          required="required"
                                          type="time"
                                          placeholder="Enter Time"
                                          className="form-control"
                                        />
                                      </Col>
                                      <Col>
                                        <Form.Control
                                          type="number"
                                          name="minutes"
                                          value={this.state.ruleData.minutes}
                                          onChange={this.handleDurationChange}
                                        />
                                      </Col>
                                      <Col>
                                        <Form.Control
                                          type="number"
                                          name="seconds"
                                          value={this.state.ruleData.seconds}
                                          onChange={this.handleDurationChange}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                  <div
                                    className="errorMsg text-center"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors.rName}
                                    {this.state.errors.minutes}
                                    {this.state.errors.seconds}
                                  </div>
                                </Row>
                              </Form.Group>
                            </Row>
                            <Row>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicPassword"
                              >
                                <Row>
                                  <Col lg="4" md="4">
                                    <Form.Label>Rule Frequency</Form.Label>
                                  </Col>
                                  <Col lg="8" md="8">
                                    <Row className="d-flex">
                                      <Col lg="4" md="4" sm="4">
                                        <Form.Control
                                          name="rMonth"
                                          value={this.state.ruleData.rMonth}
                                          onChange={this.inputChange}
                                          required="required"
                                          type="month"
                                          placeholder="Enter Month"
                                          className="form-control"
                                        />
                                      </Col>
                                      <Col lg="4" md="4" sm="4">
                                        <Form.Control
                                          name="rDate"
                                          value={this.state.ruleData.rDate}
                                          onChange={this.inputChange}
                                          required="required"
                                          type="date"
                                          placeholder="Enter Date"
                                          className="form-control"
                                        />
                                      </Col>
                                      <Col lg="4" md="4" sm="4">
                                        <Form.Control
                                          //  id="appt-time"
                                          // step="2"
                                          name="rTime"
                                          value={this.state.ruleData.rTime}
                                          onChange={this.inputChange}
                                          required="required"
                                          type="time"
                                          placeholder="Enter Time"
                                          className="form-control"
                                        />
                                      </Col>
                                      <Col
                                        lg="3"
                                        md="3"
                                        sm="3"
                                        style={{ marginTop: "5px" }}
                                      >
                                        <Form.Control
                                          type="number"
                                          name="rMinutes"
                                          value={this.state.ruleData.rMinutes}
                                          onChange={this.handleDurationChange}
                                        />
                                      </Col>
                                      <Col
                                        lg="3"
                                        md="3"
                                        sm="3"
                                        style={{ marginTop: "5px" }}
                                      >
                                        <Form.Control
                                          type="number"
                                          name="rSeconds"
                                          value={this.state.ruleData.rSeconds}
                                          onChange={this.handleDurationChange}
                                        />
                                      </Col>
                                    </Row>
                                  </Col>
                                  <div
                                    className="errorMsg text-center"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors.rMonth}
                                    {this.state.errors.rDate}
                                    {this.state.errors.rTime}
                                    {this.state.errors.rMinutes}
                                    {this.state.errors.rSeconds}
                                  </div>
                                </Row>
                              </Form.Group>
                            </Row>
                            <Row>
                              <Form.Group
                                className="mb-3"
                                controlId="formBasicPassword"
                              >
                                <Row>
                                  <Col lg="4" md="4">
                                    <Form.Label>
                                      Re Apply Old Entries
                                    </Form.Label>
                                  </Col>
                                  <Col lg="8" md="8">
                                    <Form.Select
                                      name="rSelect"
                                      value={this.state.ruleData.rSelect}
                                      onChange={this.inputChange}
                                      required="required"
                                      //type="select"
                                      placeholder="Select Old Entries"
                                      className="form-control"
                                      aria-label="Default select example"
                                    >
                                      <option>Select Old Entries</option>
                                      <option value="True">True</option>
                                      <option value="False">False</option>
                                    </Form.Select>
                                  </Col>
                                  <div
                                    className="errorMsg text-center"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors.rSelect}
                                  </div>
                                </Row>
                              </Form.Group>
                            </Row>
                          </Form>
                        </Row>
                        <br />
                        <Row className="d-flex" style={{ marginTop: "20px" }}>
                          <h4>if</h4>
                          <Table
                            className="table table-bordered table-hover"
                            id="tab_logic"
                          >
                            <thead>
                              <tr>
                                <th className="text-center">S.NO</th>
                                <th className="text-center">Table Names</th>
                                <th className="text-center">Coloumns Names</th>
                                <th className="text-center">Operators</th>
                                <th className="text-center">Value</th>
                                <th className="text-center">Operatorgates</th>
                                <th className="text-center">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.conditions.length > 0 ? (
                                // this.state.conditions.map(
                                //   (condition, index, idx) => (
                                <tr>
                                  <td>1</td>
                                  <td>{this.state.conditions[0].tableName}</td>
                                  <td>{this.state.conditions[0].columnName}</td>
                                  <td>{this.state.conditions[0].cOperator}</td>
                                  <td>
                                    <span>{`${this.state.conditions[0].cValue} ${this.state.conditions[0].ctime}`}</span>
                                  </td>
                                  <td>{this.state.conditions[0].cGate}</td>
                                  <td style={{ width: "200px" }}>
                                    <button
                                      className="bi bi-pen btn btn-info"
                                      style={{ marginLeft: "5px" }}
                                    ></button>{" "}
                                    <button
                                      variant="danger"
                                      className="bi bi-trash-fill btn btn-danger"
                                      onClick={() =>
                                        this.handleRemoveSpecificRowS(0)
                                      }
                                    ></button>
                                  </td>
                                </tr>
                              ) : (
                                //   )
                                // )
                                <tr>
                                  <td colSpan="7"> No Records</td>
                                </tr>
                              )}
                              {this.state.conditions.length === 0 && (
                                <tr>
                                  <td>--</td>
                                  <td>
                                    <select
                                      className="form-select"
                                      value={DefaultData.tableName}
                                      name="tableName"
                                      onChange={this.inputtableSChange}
                                    >
                                      <option defaultValue>Select Table</option>
                                      {this.state.tables.map(
                                        (api, index, idx) => (
                                          <option key={index} value={api}>
                                            {api}
                                          </option>
                                        )
                                      )}
                                    </select>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {this.state.errors.tableName}
                                    </div>
                                  </td>
                                  <td>
                                    <select
                                      className="form-select"
                                      aria-label="Default select example"
                                      value={this.state.columnName}
                                      name="columnName"
                                      onChange={this.handleColumnChange}
                                    >
                                      <option defaultValue>
                                        Select Column
                                      </option>
                                      {this.state.columns.map((api, index) => (
                                        <option
                                          key={index}
                                          value={api.DATA_TYPE}
                                        >
                                          {api.COLUMN_NAME}
                                        </option>
                                      ))}
                                    </select>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {this.state.errors.columnName}
                                    </div>
                                    {/* <p>Selected value: {this.state.COLUMN_NAME}</p> */}
                                  </td>

                                  <td>
                                    <>
                                      {this.state.columnValue === "int" && (
                                        <select
                                          className="form-select"
                                          name="cOperator"
                                          onChange={this.inputSChange}
                                          value={DefaultData.cOperator}
                                          style={{ width: "100px" }}
                                        >
                                          <option defaultValue>
                                            Operators
                                          </option>
                                          <option value="=">=</option>
                                          <option value="<">&lt;</option>
                                          <option value=">">&gt;</option>
                                          <option value="<=">&le;</option>
                                          <option value=">=">&ge;</option>
                                          <option value="Between">
                                            Between
                                          </option>
                                          <option value="Not Between">
                                            Not Between
                                          </option>
                                          <option value="Is Empty">
                                            Is Empty
                                          </option>
                                          <option value="Is Not Empty">
                                            Is Not Empty
                                          </option>
                                        </select>
                                      )}
                                      {this.state.columnValue === "varchar" && (
                                        <select
                                          className="form-select"
                                          name="cOperator"
                                          onChange={this.inputSChange}
                                          value={DefaultData.cOperator}
                                          style={{ width: "100px" }}
                                        >
                                          <option>VarcharOperators</option>
                                          <option value="Is">Is</option>
                                          <option value="Isn't">Isn't</option>
                                          <option value="Contains">
                                            Contains
                                          </option>
                                          <option value="Doesn't Contains">
                                            Doesn't Contains
                                          </option>
                                          <option value="Starts With">
                                            Starts With
                                          </option>
                                          <option value="Ends With">
                                            Ends With
                                          </option>
                                          <option value="Is Before">
                                            Is Before
                                          </option>
                                          <option value="Is After">
                                            Is After
                                          </option>
                                          <option value="Between">
                                            Between
                                          </option>
                                          <option value="Not Between">
                                            Not Between
                                          </option>
                                          <option value="Today">Today</option>
                                          <option value="Tomorrow">
                                            Tomorrow
                                          </option>
                                          <option value="Tomorrow Onwards">
                                            Tomorrow Onwards
                                          </option>
                                          <option value="Is Empty">
                                            Is Empty
                                          </option>
                                          <option value="Is Not Empty">
                                            Is Not Empty
                                          </option>
                                          <option value="Yesterday">
                                            Yesterday
                                          </option>
                                          <option value="Till Yesterday">
                                            Till Yesterday
                                          </option>
                                          <option value="Last Month">
                                            Last Month
                                          </option>
                                          <option value="This Month">
                                            This Month
                                          </option>
                                          <option value="Next Month">
                                            Next Month
                                          </option>
                                          <option value="Last Week">
                                            Last Week
                                          </option>
                                          <option value="This Week">
                                            This Week
                                          </option>
                                          <option value="Next Week">
                                            Next Week
                                          </option>
                                          <option value="Age In Days">
                                            Age In Days
                                          </option>
                                          <option value="Due In Days">
                                            Due In Days
                                          </option>
                                        </select>
                                      )}
                                      {this.state.columnValue === "text" && (
                                        <select
                                          className="form-select"
                                          value={DefaultData.cOperator}
                                          onChange={this.inputSChange}
                                          name="cOperator"
                                          style={{ width: "100px" }}
                                        >
                                          <option defaultValue>
                                            textOperators
                                          </option>
                                          <option value="Is">Is</option>
                                          <option value="Isn't">Isn't</option>
                                          <option value="Contains">
                                            Contains
                                          </option>
                                          <option value="Doesn't Contains">
                                            Doesn't Contains
                                          </option>
                                          <option value="Is Before">
                                            Is Before
                                          </option>
                                          <option value="Is After">
                                            Is After
                                          </option>
                                          <option value="Between">
                                            Between
                                          </option>
                                          <option value="Not Between">
                                            Not Between
                                          </option>
                                          <option value="Today">Today</option>
                                          <option value="Tomorrow">
                                            Tomorrow
                                          </option>
                                          <option value="Tomorrow Onwards">
                                            Tomorrow Onwards
                                          </option>
                                          <option value="Yesterday">
                                            Yesterday
                                          </option>
                                          <option value="Till Yesterday">
                                            Till Yesterday
                                          </option>
                                          <option value="Starts With">
                                            Starts With
                                          </option>
                                          <option value="Ends With">
                                            Ends With
                                          </option>
                                          <option value="Is Empty">
                                            Is Empty
                                          </option>
                                          <option value="Is Not Empty">
                                            Is Not Empty
                                          </option>
                                        </select>
                                      )}
                                      {this.state.columnValue ===
                                        "timestamp" && (
                                        <select
                                          className="form-select"
                                          value={DefaultData.cOperator}
                                          onChange={this.inputSChange}
                                          name="cOperator"
                                          style={{ width: "100px" }}
                                        >
                                          <option defaultValue>
                                            timestampOperators
                                          </option>
                                          <option value="Is">Is</option>
                                          <option value="Isn't">Isn't</option>
                                          <option value="Is Before">
                                            Is Before
                                          </option>
                                          <option value="Is After">
                                            Is After
                                          </option>
                                          <option value="Today">Today</option>
                                          <option value="Tomorrow">
                                            Tomorrow
                                          </option>
                                          <option value="Tomorrow Onwards">
                                            Tomorrow Onwards
                                          </option>
                                          <option value="Yesterday">
                                            Yesterday
                                          </option>
                                          <option value="Till Yesterday">
                                            Till Yesterday
                                          </option>
                                          <option value="Last Month">
                                            Last Month
                                          </option>
                                          <option value="This Month">
                                            This Month
                                          </option>
                                          <option value="Next Month">
                                            Next Month
                                          </option>
                                          <option value="Last Week">
                                            Last Week
                                          </option>
                                          <option value="This Week">
                                            This Week
                                          </option>
                                          <option value="Next Week">
                                            Next Week
                                          </option>
                                          <option value="Is Empty">
                                            Is Empty
                                          </option>
                                          <option value="Is Not Empty">
                                            Is Not Empty
                                          </option>
                                          <option value="Age In Days">
                                            Age In Days
                                          </option>
                                          <option value="Due In Days">
                                            Due In Days
                                          </option>
                                        </select>
                                      )}
                                    </>
                                  </td>
                                  <td>
                                    {this.state.columnValue === "timestamp" ? (
                                      <>
                                        <input
                                          type="date"
                                          value={DefaultData.cValue}
                                          style={{ width: "100px" }}
                                          className="form-select"
                                          onChange={this.inputSChange}
                                          name="cValue"
                                        />
                                        <input
                                          type="time"
                                          value={DefaultData.ctime}
                                          className="form-select"
                                          style={{ width: "100px" }}
                                          onChange={this.inputSChange}
                                          name="ctime"
                                        />
                                        <div
                                          className="errorMsg"
                                          style={{ color: "red" }}
                                        >
                                          {this.state.errors.cOperator}
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <Form.Control
                                          value={DefaultData.cValue}
                                          name="cValue"
                                          required="required"
                                          style={{
                                            width: "100px",
                                            marginTop: "3px",
                                            height: "38px",
                                          }}
                                          placeholder="Value."
                                          onChange={this.inputSChange}
                                        ></Form.Control>
                                        <div
                                          className="errorMsg"
                                          style={{ color: "red" }}
                                        >
                                          {this.state.errors.cValue}
                                        </div>
                                      </>
                                    )}
                                  </td>
                                  <td>
                                    <select
                                      className="form-select"
                                      style={{ width: "100px" }}
                                      aria-label="Default select example"
                                      value={DefaultData.cGate}
                                      name="cGate"
                                      required="required"
                                      onChange={this.inputSChange}
                                    >
                                      <option defaultValue>SelectGates</option>
                                      <option value="And">And</option>
                                      <option value="Or">Or</option>
                                      <option value="END">END</option>
                                    </select>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {this.state.errors.cGate}
                                    </div>
                                  </td>
                                  <td>
                                    <Button
                                      variant="success"
                                      onClick={this.addSendRow}
                                    >
                                      Add
                                    </Button>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </Table>
                        </Row>
                        <br />
                        <Row className="mt-3 d-flex">
                          <h4>Do</h4>
                          <Col>
                            <select
                              style={{ marginTop: "16px" }}
                              className="form-select"
                              aria-label="Default select example"
                              value={this.state.rActions.rtType}
                              name="rtType"
                              required="required"
                              onChange={this.inputRChange}
                            >
                              <option>Action Type</option>
                              <option value="UpdateTable">Update Table</option>
                              <option value="SendEmail">Send Email</option>
                              <option value="SendSms">Send Sms</option>
                              <option value="CallAPI">Call API</option>
                              <option value="whatsapp">
                                Send What'sapp Message
                              </option>
                            </select>
                          </Col>
                          <Col style={{ marginLeft: "-21px" }}>
                            {this.state.visibletype === "UpdateTable" && (
                              <select
                                // style={{ width: "200px" }}
                                className="form-select mt-3"
                                aria-label="Default select example"
                                value={this.state.rActions.rtTableName}
                                name="rtTableName"
                                required="required"
                                onChange={this.inputtableRChange}
                              >
                                <option defaultValue hidden>
                                  Table Names
                                </option>
                                {this.state.tables.map((api, index, idx) => (
                                  <option value={api}>{api}</option>
                                ))}
                              </select>
                            )}
                            {this.state.visibletype === "SendEmail" && (
                              <>
                                <select
                                  className="form-select mt-3"
                                  style={{ width: "200px" }}
                                  value={this.state.rActions.rtTemplate}
                                  name="rtTemplate"
                                  required="required"
                                  aria-label="Default select example"
                                  onChange={this.inputtableRChange}
                                >
                                  <option defaultValue>Select Email</option>
                                  {this.state.templates.map((api, index) => (
                                    <option key={api} value={api.tName}>
                                      {api.tName}
                                    </option>
                                  ))}
                                </select>
                              </>
                            )}
                            {this.state.visibletype === "SendSms" && (
                              <select
                                className="form-select mt-3"
                                style={{ width: "200px" }}
                                value={this.state.rActions.rtTemplate}
                                name="rtTemplate"
                                required="required"
                                aria-label="Default select example"
                                onChange={this.inputtableRChange}
                              >
                                <option defaultValue>Select SendSms</option>
                                {this.state.templates.map((api, index) => (
                                  <option key={api} value={api.tName}>
                                    {api.tName}
                                  </option>
                                ))}
                              </select>
                            )}
                            {this.state.visibletype === "CallAPI" && (
                              <select
                                className="form-select mt-3"
                                style={{ width: "200px" }}
                                value={this.state.rActions.rtTemplate}
                                name="rtTemplate"
                                required="required"
                                aria-label="Default select example"
                                onChange={this.inputtableRChange}
                              >
                                <option defaultValue>select Api</option>
                                {this.state.apis.map((api, index) => (
                                  <option key={api} value={api.aName}>
                                    {api.aName}
                                  </option>
                                ))}
                              </select>
                            )}
                            {this.state.visibletype === "whatsapp" && (
                              <select
                                style={{ width: "200px" }}
                                className="form-select mt-3"
                                value={this.state.rActions.rtTemplate}
                                name="rtTemplate"
                                required="required"
                                aria-label="Default select example"
                                onChange={this.inputtableRChange}
                              >
                                <option defaultValue> select message</option>
                                {this.state.templates.map((api, index) => (
                                  <option key={api} value={api.tName}>
                                    {api.tName}
                                  </option>
                                ))}
                              </select>
                            )}
                          </Col>
                          <Col style={{ marginLeft: "-21px" }}>
                            {this.state.visibletype === "UpdateTable" && (
                              <select
                                style={{ width: "200px" }}
                                className="form-select mt-3"
                                aria-label="Default select example"
                                value={this.state.rActions.rtColumnName}
                                name="rtColumnName"
                                required="required"
                                onChange={this.inputtableRChange}
                              >
                                <option defaultValue hidden>
                                  Column Names
                                </option>
                                {this.state.rcolumns.map((api, index, idx) => (
                                  <option value={api}>{api}</option>
                                ))}
                              </select>
                            )}
                            {this.state.visibletype === "SendEmail" && (
                              <select
                                style={{ width: "200px" }}
                                className="form-select mt-3"
                                aria-label="Default select example"
                                value={this.state.rActions.rtGate}
                                name="rtGate"
                                required="required"
                                onChange={this.inputtableRChange}
                              >
                                <option defaultValue hidden>
                                  Operators
                                </option>
                                <option value="And">And</option>
                                <option value="Or">Or</option>
                                <option value="END">END</option>
                              </select>
                            )}
                            {this.state.visibletype === "SendSms" && (
                              <select
                                style={{ width: "200px" }}
                                className="form-select mt-3"
                                value={this.state.rActions.rtGate}
                                name="rtGate"
                                required="required"
                                aria-label="Default select example"
                                onChange={this.inputtableRChange}
                              >
                                <option defaultValue>select Gate</option>
                                <option value="And">And</option>
                                <option value="Or">Or</option>
                                <option value="END">END</option>
                              </select>
                            )}
                            {this.state.visibletype === "CallAPI" && (
                              <select
                                style={{ width: "200px" }}
                                className="form-select mt-3"
                                value={this.state.rActions.rtGate}
                                name="rtGate"
                                required="required"
                                aria-label="Default select example"
                                onChange={this.inputtableRChange}
                              >
                                <option defaultValue>select Gate</option>
                                <option value="And">And</option>
                                <option value="Or">Or</option>
                                <option value="END">END</option>
                              </select>
                            )}
                            {this.state.visibletype === "whatsapp" && (
                              <select
                                style={{ width: "200px" }}
                                className="form-select mt-3"
                                value={this.state.rActions.rtGate}
                                name="rtGate"
                                required="required"
                                aria-label="Default select example"
                                onChange={this.inputtableRChange}
                              >
                                <option defaultValue>select Gate</option>
                                <option value="And">And</option>
                                <option value="Or">Or</option>
                                <option value="END">END</option>
                              </select>
                            )}
                          </Col>
                          <Col style={{ marginLeft: "-21px" }}>
                            {this.state.visibletype === "UpdateTable" && (
                              <select
                                // style={{ width: "200px" }}
                                className="form-select mt-3"
                                aria-label="Default select example"
                                value={this.state.rActions.rtGate}
                                name="rtGate"
                                required="required"
                                onChange={this.inputtableRChange}
                              >
                                <option defaultValue hidden>
                                  Operators
                                </option>
                                <option value="And">And</option>
                                <option value="Or">Or</option>
                                <option value="END">END</option>
                              </select>
                            )}
                            {this.state.visibletype === "SendEmail" && (
                              <Button
                                variant="success"
                                className="mt-3"
                                onClick={this.addFormsubmitdata}
                              >
                                Add
                              </Button>
                            )}
                            {this.state.visibletype === "SendSms" && (
                              <Button
                                variant="success"
                                className="mt-3"
                                onClick={this.addFormsubmitdata}
                              >
                                Add
                              </Button>
                            )}
                            {this.state.visibletype === "CallAPI" && (
                              <Button
                                variant="success"
                                className="mt-3"
                                onClick={this.addFormsubmitdata}
                              >
                                Add
                              </Button>
                            )}
                            {this.state.visibletype === "whatsapp" && (
                              <Button
                                variant="success"
                                className="mt-3"
                                onClick={this.addFormsubmitdata}
                              >
                                Add
                              </Button>
                            )}
                          </Col>
                          <Col style={{ marginLeft: "-21px" }}>
                            {this.state.visibletype === "UpdateTable" && (
                              <Button
                                variant="success"
                                className="mt-3"
                                onClick={this.addFormsubmitdata}
                              >
                                Add
                              </Button>
                            )}
                          </Col>
                        </Row>
                        <br />
                        <Row style={{ marginTop: "20px" }}>
                          <table className="table table-bordered table-hover">
                            <thead>
                              <tr>
                                <th className="text-center">S.NO</th>
                                <th className="text-center">Action Type</th>
                                <th className="text-center">Table Names</th>
                                <th className="text-center">Column Names</th>
                                <th className="text-center">Template</th>
                                <th className="text-center">operators</th>
                                <th className="text-center">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.actions.length > 0 ? (
                                this.state.actions.map((action, index, idx) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{action.rtType}</td>
                                    <td>{action.rtTableName}</td>
                                    <td>{action.rtColumnName}</td>
                                    <td>{action.rtTemplate}</td>
                                    <td>{action.rtGate}</td>
                                    <td>
                                      <Button className="bi bi-pen btn btn-info"></Button>{" "}
                                      <Button
                                        variant="danger"
                                        className="bi bi-trash-fill btn btn-danger"
                                        onClick={() =>
                                          this.handleRemoveSpecificRowSAction(
                                            index
                                          )
                                        }
                                        // idx={idx}
                                      ></Button>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan="9"> No Records</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </Row>
                      </Row>
                      <Row>
                        <Col
                          className="d-flex justify-content-end"
                          style={{ marginRight: "50px" }}
                        >
                          <Button
                            className="button"
                            variant="success"
                            onClick={this.onSubmitAPI}
                            style={{
                              borderRadius: "0px",
                              marginTop: "20px",
                            }}
                          >
                            Save
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  </Offcanvas.Body>
                </Offcanvas>
              </Col>
            </Row>
          </div>
          <br />
          <div>
            {" "}
            {this.state.isEditing && (
              // Edit Offcanvas
              <Offcanvas
                show={this.state.isEditing}
                onHide={() => this.setState({ isEditing: false })}
                {...this.props}
                style={{ width: "700px", height: "auto" }}
                placement="end"
                backdrop="true"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Edit RulePage</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <div className="form-horizontal form-label-left input_mask">
                    <Form className="form-horizontal form-label-left input_mask">
                      <Row>
                        <Form>
                          <Row>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicPassword"
                            >
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label>Rule Name</Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    name={"rName"}
                                    value={this.state.ruleData.rName}
                                    onChange={this.inputChange}
                                    required="required"
                                    type="text"
                                    placeholder="Enter Rule Name"
                                    className="form-control"
                                  />
                                </Col>
                              </Row>
                            </Form.Group>
                          </Row>
                          <Row>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlTextarea1"
                            >
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label>Rule description</Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name={"rDescription"}
                                    value={this.state.ruleData.rDescription}
                                    onChange={this.inputChange}
                                    required="required"
                                    placeholder="Enter Rule Description"
                                    className="form-control"
                                  />
                                  <div
                                    className="errorMsg text-center"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors.rDescription}
                                  </div>
                                </Col>
                              </Row>
                            </Form.Group>
                          </Row>
                          <Row>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicPassword"
                            >
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label>Rule Execution Time</Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Row className="d-flex">
                                    <Col>
                                      <Form.Control
                                        // // id="appt-time"
                                        // step="2"
                                        name="reTime"
                                        value={this.state.ruleData.reTime}
                                        onChange={this.inputChange}
                                        required="required"
                                        type="time"
                                        placeholder="Enter Time"
                                        className="form-control"
                                      />
                                    </Col>
                                    <Col>
                                      <Form.Control
                                        type="number"
                                        name="minutes"
                                        value={this.state.ruleData.minutes}
                                        onChange={this.handleDurationChange}
                                      />
                                    </Col>
                                    <Col>
                                      <Form.Control
                                        type="number"
                                        name="seconds"
                                        value={this.state.ruleData.seconds}
                                        onChange={this.handleDurationChange}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <div
                                  className="errorMsg text-center"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors.rName}
                                  {this.state.errors.minutes}
                                  {this.state.errors.seconds}
                                </div>
                              </Row>
                            </Form.Group>
                          </Row>
                          <Row>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicPassword"
                            >
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label>Rule Frequency</Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Row className="d-flex">
                                    <Col lg="4" md="4" sm="4">
                                      <Form.Control
                                        name="rMonth"
                                        value={this.state.ruleData.rMonth}
                                        onChange={this.inputChange}
                                        required="required"
                                        type="month"
                                        placeholder="Enter Month"
                                        className="form-control"
                                      />
                                    </Col>
                                    <Col lg="4" md="4" sm="4">
                                      <Form.Control
                                        name="rDate"
                                        value={this.state.ruleData.rDate}
                                        onChange={this.inputChange}
                                        required="required"
                                        type="date"
                                        placeholder="Enter Date"
                                        className="form-control"
                                      />
                                    </Col>
                                    <Col lg="4" md="4" sm="4">
                                      <Form.Control
                                        //  id="appt-time"
                                        // step="2"
                                        name="rTime"
                                        value={this.state.ruleData.rTime}
                                        onChange={this.inputChange}
                                        required="required"
                                        type="time"
                                        placeholder="Enter Time"
                                        className="form-control"
                                      />
                                    </Col>
                                    <Col
                                      lg="4"
                                      md="4"
                                      sm="4"
                                      style={{ marginTop: "5px" }}
                                    >
                                      <Form.Control
                                        type="number"
                                        name="rMinutes"
                                        value={this.state.ruleData.rMinutes}
                                        onChange={this.handleDurationChange}
                                      />
                                    </Col>
                                    <Col
                                      lg="4"
                                      md="4"
                                      sm="4"
                                      style={{ marginTop: "5px" }}
                                    >
                                      <Form.Control
                                        type="number"
                                        name="rSeconds"
                                        value={this.state.ruleData.rSeconds}
                                        onChange={this.handleDurationChange}
                                      />
                                    </Col>
                                  </Row>
                                </Col>
                                <div
                                  className="errorMsg text-center"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors.rMonth}
                                  {this.state.errors.rDate}
                                  {this.state.errors.rTime}
                                  {this.state.errors.rMinutes}
                                  {this.state.errors.rSeconds}
                                </div>
                              </Row>
                            </Form.Group>
                          </Row>
                          <Row>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicPassword"
                            >
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label>Re Apply Old Entries</Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Select
                                    name="rSelect"
                                    value={this.state.ruleData.rSelect}
                                    onChange={this.inputChange}
                                    required="required"
                                    //type="select"
                                    placeholder="Select Old Entries"
                                    className="form-control"
                                    aria-label="Default select example"
                                  >
                                    <option>Select Old Entries</option>
                                    <option value="True">True</option>
                                    <option value="False">False</option>
                                  </Form.Select>
                                </Col>
                                <div
                                  className="errorMsg text-center"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors.rSelect}
                                </div>
                              </Row>
                            </Form.Group>
                          </Row>
                        </Form>
                      </Row>
                      <br />
                      <Row className="d-flex" style={{ marginTop: "20px" }}>
                        <h4>if</h4>
                        <Table
                          className="table table-bordered table-hover"
                          id="tab_logic"
                        >
                          <thead>
                            <tr>
                              <th className="text-center">S.NO</th>
                              <th className="text-center">Table Names</th>
                              <th className="text-center">Coloumns Names</th>
                              <th className="text-center">Operators</th>
                              <th className="text-center">Value</th>
                              <th className="text-center">Operatorgates</th>
                              <th className="text-center">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.conditions.length > 0 ? (
                              // this.state.conditions.map(
                              //   (condition, index, idx) => (
                              <tr>
                                <td>1</td>
                                <td>{this.state.conditions[0].tableName}</td>
                                <td>{this.state.conditions[0].columnName}</td>
                                <td>{this.state.conditions[0].cOperator}</td>
                                <td>
                                  <span>{`${this.state.conditions[0].cValue} ${this.state.conditions[0].ctime}`}</span>
                                </td>
                                <td>{this.state.conditions[0].cGate}</td>
                                <td style={{ width: "200px" }}>
                                  <button
                                    className="bi bi-pen btn btn-info"
                                    style={{ marginLeft: "5px" }}
                                  ></button>{" "}
                                  <button
                                    variant="danger"
                                    className="bi bi-trash-fill btn btn-danger"
                                    onClick={() =>
                                      this.handleRemoveSpecificRowS(0)
                                    }
                                  ></button>
                                </td>
                              </tr>
                            ) : (
                              //   )
                              // )
                              <tr>
                                <td colSpan="7"> No Records</td>
                              </tr>
                            )}
                            {this.state.conditions.length === 0 && (
                              <tr>
                                <td>--</td>
                                <td>
                                  <select
                                    className="form-select"
                                    value={this.state.rConditions.tableName}
                                    name="tableName"
                                    onChange={this.inputtableSChange}
                                  >
                                    <option defaultValue>Select Table</option>
                                    {this.state.tables.map(
                                      (api, index, idx) => (
                                        <option key={index} value={api}>
                                          {api}
                                        </option>
                                      )
                                    )}
                                  </select>
                                  <div
                                    className="errorMsg"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors.tableName}
                                  </div>
                                </td>
                                <td>
                                  <select
                                    className="form-select"
                                    aria-label="Default select example"
                                    value={this.state.columnName}
                                    name="columnName"
                                    onChange={this.handleColumnChange}
                                  >
                                    <option defaultValue>Select Column</option>
                                    {this.state.columns.map((api, index) => (
                                      <option key={index} value={api.DATA_TYPE}>
                                        {api.COLUMN_NAME}
                                      </option>
                                    ))}
                                  </select>
                                  <div
                                    className="errorMsg"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors.columnName}
                                  </div>
                                  {/* <p>Selected value: {this.state.COLUMN_NAME}</p> */}
                                </td>

                                <td>
                                  <>
                                    {this.state.columnValue === "int" && (
                                      <select
                                        className="form-select"
                                        name="cOperator"
                                        onChange={this.inputSChange}
                                        value={this.state.rConditions.cOperator}
                                        style={{ width: "100px" }}
                                      >
                                        <option defaultValue>Operators</option>
                                        <option value="=">=</option>
                                        <option value="<">&lt;</option>
                                        <option value=">">&gt;</option>
                                        <option value="<=">&le;</option>
                                        <option value=">=">&ge;</option>
                                        <option value="Between">Between</option>
                                        <option value="Not Between">
                                          Not Between
                                        </option>
                                        <option value="Is Empty">
                                          Is Empty
                                        </option>
                                        <option value="Is Not Empty">
                                          Is Not Empty
                                        </option>
                                      </select>
                                    )}
                                    {this.state.columnValue === "varchar" && (
                                      <select
                                        className="form-select"
                                        name="cOperator"
                                        onChange={this.inputSChange}
                                        value={this.state.rConditions.cOperator}
                                        style={{ width: "100px" }}
                                      >
                                        <option>VarcharOperators</option>
                                        <option value="Is">Is</option>
                                        <option value="Isn't">Isn't</option>
                                        <option value="Contains">
                                          Contains
                                        </option>
                                        <option value="Doesn't Contains">
                                          Doesn't Contains
                                        </option>
                                        <option value="Starts With">
                                          Starts With
                                        </option>
                                        <option value="Ends With">
                                          Ends With
                                        </option>
                                        <option value="Is Before">
                                          Is Before
                                        </option>
                                        <option value="Is After">
                                          Is After
                                        </option>
                                        <option value="Between">Between</option>
                                        <option value="Not Between">
                                          Not Between
                                        </option>
                                        <option value="Today">Today</option>
                                        <option value="Tomorrow">
                                          Tomorrow
                                        </option>
                                        <option value="Tomorrow Onwards">
                                          Tomorrow Onwards
                                        </option>
                                        <option value="Is Empty">
                                          Is Empty
                                        </option>
                                        <option value="Is Not Empty">
                                          Is Not Empty
                                        </option>
                                        <option value="Yesterday">
                                          Yesterday
                                        </option>
                                        <option value="Till Yesterday">
                                          Till Yesterday
                                        </option>
                                        <option value="Last Month">
                                          Last Month
                                        </option>
                                        <option value="This Month">
                                          This Month
                                        </option>
                                        <option value="Next Month">
                                          Next Month
                                        </option>
                                        <option value="Last Week">
                                          Last Week
                                        </option>
                                        <option value="This Week">
                                          This Week
                                        </option>
                                        <option value="Next Week">
                                          Next Week
                                        </option>
                                        <option value="Age In Days">
                                          Age In Days
                                        </option>
                                        <option value="Due In Days">
                                          Due In Days
                                        </option>
                                      </select>
                                    )}
                                    {this.state.columnValue === "text" && (
                                      <select
                                        className="form-select"
                                        value={this.state.rConditions.cOperator}
                                        onChange={this.inputSChange}
                                        name="cOperator"
                                        style={{ width: "100px" }}
                                      >
                                        <option defaultValue>
                                          textOperators
                                        </option>
                                        <option value="Is">Is</option>
                                        <option value="Isn't">Isn't</option>
                                        <option value="Contains">
                                          Contains
                                        </option>
                                        <option value="Doesn't Contains">
                                          Doesn't Contains
                                        </option>
                                        <option value="Is Before">
                                          Is Before
                                        </option>
                                        <option value="Is After">
                                          Is After
                                        </option>
                                        <option value="Between">Between</option>
                                        <option value="Not Between">
                                          Not Between
                                        </option>
                                        <option value="Today">Today</option>
                                        <option value="Tomorrow">
                                          Tomorrow
                                        </option>
                                        <option value="Tomorrow Onwards">
                                          Tomorrow Onwards
                                        </option>
                                        <option value="Yesterday">
                                          Yesterday
                                        </option>
                                        <option value="Till Yesterday">
                                          Till Yesterday
                                        </option>
                                        <option value="Starts With">
                                          Starts With
                                        </option>
                                        <option value="Ends With">
                                          Ends With
                                        </option>
                                        <option value="Is Empty">
                                          Is Empty
                                        </option>
                                        <option value="Is Not Empty">
                                          Is Not Empty
                                        </option>
                                      </select>
                                    )}
                                    {this.state.columnValue === "timestamp" && (
                                      <select
                                        className="form-select"
                                        value={this.state.rConditions.cOperator}
                                        onChange={this.inputSChange}
                                        name="cOperator"
                                        style={{ width: "100px" }}
                                      >
                                        <option defaultValue>
                                          timestampOperators
                                        </option>
                                        <option value="Is">Is</option>
                                        <option value="Isn't">Isn't</option>
                                        <option value="Is Before">
                                          Is Before
                                        </option>
                                        <option value="Is After">
                                          Is After
                                        </option>
                                        <option value="Today">Today</option>
                                        <option value="Tomorrow">
                                          Tomorrow
                                        </option>
                                        <option value="Tomorrow Onwards">
                                          Tomorrow Onwards
                                        </option>
                                        <option value="Yesterday">
                                          Yesterday
                                        </option>
                                        <option value="Till Yesterday">
                                          Till Yesterday
                                        </option>
                                        <option value="Last Month">
                                          Last Month
                                        </option>
                                        <option value="This Month">
                                          This Month
                                        </option>
                                        <option value="Next Month">
                                          Next Month
                                        </option>
                                        <option value="Last Week">
                                          Last Week
                                        </option>
                                        <option value="This Week">
                                          This Week
                                        </option>
                                        <option value="Next Week">
                                          Next Week
                                        </option>
                                        <option value="Is Empty">
                                          Is Empty
                                        </option>
                                        <option value="Is Not Empty">
                                          Is Not Empty
                                        </option>
                                        <option value="Age In Days">
                                          Age In Days
                                        </option>
                                        <option value="Due In Days">
                                          Due In Days
                                        </option>
                                      </select>
                                    )}
                                  </>
                                </td>
                                <td>
                                  {this.state.columnValue === "timestamp" ? (
                                    <>
                                      <input
                                        type="date"
                                        value={this.state.rConditions.cValue}
                                        style={{ width: "100px" }}
                                        className="form-select"
                                        onChange={this.inputSChange}
                                        name="cValue"
                                      />
                                      <input
                                        type="time"
                                        value={this.state.rConditions.ctime}
                                        className="form-select"
                                        style={{ width: "100px" }}
                                        onChange={this.inputSChange}
                                        name="ctime"
                                      />
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {this.state.errors.cOperator}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <Form.Control
                                        value={this.state.rConditions.cValue}
                                        name="cValue"
                                        required="required"
                                        style={{
                                          width: "100px",
                                          marginTop: "3px",
                                          height: "38px",
                                        }}
                                        placeholder="Value."
                                        onChange={this.inputSChange}
                                      ></Form.Control>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {this.state.errors.cValue}
                                      </div>
                                    </>
                                  )}
                                </td>
                                <td>
                                  <select
                                    className="form-select"
                                    style={{ width: "100px" }}
                                    aria-label="Default select example"
                                    value={this.state.rConditions.cGate}
                                    name="cGate"
                                    required="required"
                                    onChange={this.inputSChange}
                                  >
                                    <option defaultValue>SelectGates</option>
                                    <option value="And">And</option>
                                    <option value="Or">Or</option>
                                    <option value="END">END</option>
                                  </select>
                                  <div
                                    className="errorMsg"
                                    style={{ color: "red" }}
                                  >
                                    {this.state.errors.cGate}
                                  </div>
                                </td>
                                <td>
                                  <Button
                                    variant="success"
                                    onClick={this.addSendRow}
                                  >
                                    Add
                                  </Button>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Row>
                      <br />
                      <Row className="mt-3 d-flex">
                        <h4>Do</h4>
                        <Col>
                          <select
                            style={{ marginTop: "16px" }}
                            className="form-select"
                            aria-label="Default select example"
                            value={this.state.rActions.rtType}
                            name="rtType"
                            required="required"
                            onChange={this.inputRChange}
                          >
                            <option>Action Type</option>
                            <option value="UpdateTable">Update Table</option>
                            <option value="SendEmail">Send Email</option>
                            <option value="SendSms">Send Sms</option>
                            <option value="CallAPI">Call API</option>
                            <option value="whatsapp">
                              Send What'sapp Message
                            </option>
                          </select>
                        </Col>
                        <Col style={{ marginLeft: "-21px" }}>
                          {this.state.visibletype === "UpdateTable" && (
                            <select
                              // style={{ width: "200px" }}
                              className="form-select mt-3"
                              aria-label="Default select example"
                              value={this.state.rActions.rtTableName}
                              name="rtTableName"
                              required="required"
                              onChange={this.inputtableRChange}
                            >
                              <option defaultValue hidden>
                                Table Names
                              </option>
                              {this.state.tables.map((api, index, idx) => (
                                <option value={api}>{api}</option>
                              ))}
                            </select>
                          )}
                          {this.state.visibletype === "SendEmail" && (
                            <>
                              <select
                                className="form-select mt-3"
                                style={{ width: "200px" }}
                                value={this.state.rActions.rtTemplate}
                                name="rtTemplate"
                                required="required"
                                aria-label="Default select example"
                                onChange={this.inputtableRChange}
                              >
                                <option defaultValue>Select Email</option>
                                {this.state.templates.map((api, index) => (
                                  <option key={api} value={api.tName}>
                                    {api.tName}
                                  </option>
                                ))}
                              </select>
                            </>
                          )}
                          {this.state.visibletype === "SendSms" && (
                            <select
                              className="form-select mt-3"
                              style={{ width: "200px" }}
                              value={this.state.rActions.rtTemplate}
                              name="rtTemplate"
                              required="required"
                              aria-label="Default select example"
                              onChange={this.inputtableRChange}
                            >
                              <option defaultValue>Select SendSms</option>
                              {this.state.templates.map((api, index) => (
                                <option key={api} value={api.tName}>
                                  {api.tName}
                                </option>
                              ))}
                            </select>
                          )}
                          {this.state.visibletype === "CallAPI" && (
                            <select
                              className="form-select mt-3"
                              style={{ width: "200px" }}
                              value={this.state.rActions.rtTemplate}
                              name="rtTemplate"
                              required="required"
                              aria-label="Default select example"
                              onChange={this.inputtableRChange}
                            >
                              <option defaultValue>select Api</option>
                              {this.state.apis.map((api, index) => (
                                <option key={api} value={api.aName}>
                                  {api.aName}
                                </option>
                              ))}
                            </select>
                          )}
                          {this.state.visibletype === "whatsapp" && (
                            <select
                              style={{ width: "200px" }}
                              className="form-select mt-3"
                              value={this.state.rActions.rtTemplate}
                              name="rtTemplate"
                              required="required"
                              aria-label="Default select example"
                              onChange={this.inputtableRChange}
                            >
                              <option defaultValue> select message</option>
                              {this.state.templates.map((api, index) => (
                                <option key={api} value={api.tName}>
                                  {api.tName}
                                </option>
                              ))}
                            </select>
                          )}
                        </Col>
                        <Col style={{ marginLeft: "-21px" }}>
                          {this.state.visibletype === "UpdateTable" && (
                            <select
                              style={{ width: "200px" }}
                              className="form-select mt-3"
                              aria-label="Default select example"
                              value={this.state.rActions.rtColumnName}
                              name="rtColumnName"
                              required="required"
                              onChange={this.inputtableRChange}
                            >
                              <option defaultValue hidden>
                                Column Names
                              </option>
                              {this.state.rcolumns.map((api, index, idx) => (
                                <option value={api}>{api}</option>
                              ))}
                            </select>
                          )}
                          {this.state.visibletype === "SendEmail" && (
                            <select
                              style={{ width: "200px" }}
                              className="form-select mt-3"
                              aria-label="Default select example"
                              value={this.state.rActions.rtGate}
                              name="rtGate"
                              required="required"
                              onChange={this.inputtableRChange}
                            >
                              <option defaultValue hidden>
                                Operators
                              </option>
                              <option value="And">And</option>
                              <option value="Or">Or</option>
                              <option value="END">END</option>
                            </select>
                          )}
                          {this.state.visibletype === "SendSms" && (
                            <select
                              style={{ width: "200px" }}
                              className="form-select mt-3"
                              value={this.state.rActions.rtGate}
                              name="rtGate"
                              required="required"
                              aria-label="Default select example"
                              onChange={this.inputtableRChange}
                            >
                              <option defaultValue>select Gate</option>
                              <option value="And">And</option>
                              <option value="Or">Or</option>
                              <option value="END">END</option>
                            </select>
                          )}
                          {this.state.visibletype === "CallAPI" && (
                            <select
                              style={{ width: "200px" }}
                              className="form-select mt-3"
                              value={this.state.rActions.rtGate}
                              name="rtGate"
                              required="required"
                              aria-label="Default select example"
                              onChange={this.inputtableRChange}
                            >
                              <option defaultValue>select Gate</option>
                              <option value="And">And</option>
                              <option value="Or">Or</option>
                              <option value="END">END</option>
                            </select>
                          )}
                          {this.state.visibletype === "whatsapp" && (
                            <select
                              style={{ width: "200px" }}
                              className="form-select mt-3"
                              value={this.state.rActions.rtGate}
                              name="rtGate"
                              required="required"
                              aria-label="Default select example"
                              onChange={this.inputtableRChange}
                            >
                              <option defaultValue>select Gate</option>
                              <option value="And">And</option>
                              <option value="Or">Or</option>
                              <option value="END">END</option>
                            </select>
                          )}
                        </Col>
                        <Col style={{ marginLeft: "-21px" }}>
                          {this.state.visibletype === "UpdateTable" && (
                            <select
                              // style={{ width: "200px" }}
                              className="form-select mt-3"
                              aria-label="Default select example"
                              value={this.state.rActions.rtGate}
                              name="rtGate"
                              required="required"
                              onChange={this.inputtableRChange}
                            >
                              <option defaultValue hidden>
                                Operators
                              </option>
                              <option value="And">And</option>
                              <option value="Or">Or</option>
                              <option value="END">END</option>
                            </select>
                          )}
                          {this.state.visibletype === "SendEmail" && (
                            <Button
                              variant="success"
                              className="mt-3"
                              onClick={this.addFormsubmitdata}
                            >
                              Add
                            </Button>
                          )}
                          {this.state.visibletype === "SendSms" && (
                            <Button
                              variant="success"
                              className="mt-3"
                              onClick={this.addFormsubmitdata}
                            >
                              Add
                            </Button>
                          )}
                          {this.state.visibletype === "CallAPI" && (
                            <Button
                              variant="success"
                              className="mt-3"
                              onClick={this.addFormsubmitdata}
                            >
                              Add
                            </Button>
                          )}
                          {this.state.visibletype === "whatsapp" && (
                            <Button
                              variant="success"
                              className="mt-3"
                              onClick={this.addFormsubmitdata}
                            >
                              Add
                            </Button>
                          )}
                        </Col>
                        <Col style={{ marginLeft: "-21px" }}>
                          {this.state.visibletype === "UpdateTable" && (
                            <Button
                              variant="success"
                              className="mt-3"
                              onClick={this.addFormsubmitdata}
                            >
                              Add
                            </Button>
                          )}
                        </Col>
                      </Row>
                      <br />
                      <Row style={{ marginTop: "20px" }}>
                        <table className="table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th className="text-center">S.NO</th>
                              <th className="text-center">Action Type</th>
                              <th className="text-center">Table Names</th>
                              <th className="text-center">Column Names</th>
                              <th className="text-center">Template</th>
                              <th className="text-center">operators</th>
                              <th className="text-center">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.actions.length > 0 ? (
                              this.state.actions.map((action, index, idx) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{action.rtType}</td>
                                  <td>{action.rtTableName}</td>
                                  <td>{action.rtColumnName}</td>
                                  <td>{action.rtTemplate}</td>
                                  <td>{action.rtGate}</td>
                                  <td>
                                    <Button className="bi bi-pen btn btn-info"></Button>{" "}
                                    <Button
                                      variant="danger"
                                      className="bi bi-trash-fill btn btn-danger"
                                      onClick={() =>
                                        this.handleRemoveSpecificRowSAction(
                                          index
                                        )
                                      }
                                      // idx={idx}
                                    ></Button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="9"> No Records</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </Row>
                      <br />

                      <button
                        className="btn btn-primary me-2 mt-5"
                        style={{
                          backgroundColor: "#23547e",
                          color: "#fff",
                          borderColor: "#2e6da4",
                          float: "right",
                        }}
                        onClick={this.rulesHandlerupdate}
                      >
                        Update
                      </button>
                    </Form>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            )}
          </div>
          <br />
          <div style={{ marginRight: "5px" }}>
            {/* searchbar,pagination,filter */}
            <input
              style={{ float: "left", marginBottom: "10px" }}
              type="text"
              onChange={this.handleFilter}
              placeholder="Search..."
            />
            <DataTable
              data={paginatedData}
              columns={this.state.tableClms}
              pagination
              paginationServer
              paginationTotalRows={
                this.state.filteredData
                  ? this.state.filteredData.length
                  : this.state.rules.length
              }
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
              paginationPerPage={this.state.itemsPerPage}
              keyField="id"
              paginationRowsPerPageOptions={[
                10, 20, 30, 50, 60, 70, 80, 90, 100,
              ]}
              className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
            />
          </div>
        </div>
      </div>
    );
  }
}

import React, { Component } from "react";
import { Button, Container, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class Itsupportticketscreate extends Component {
  constructor(props) {
    super(props);
    const uName = sessionStorage.getItem("userName");
    const employeeId = sessionStorage.getItem("employeeId");
    this.state = {
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.itsupporttickets_Id,
          sortable: true,
          cell: (row) => <span>{row.itsupporttickets_Id}</span>,
        },
        {
          name: "Raised By",
          selector: (row, i) => row.raisedby,
          sortable: true,
          cell: (row) => <span>{row.raisedby}</span>,
        },
        {
          name: "Employee Id",
          selector: (row, i) => row.eId,
          sortable: true,
          cell: (row) => <span>{row.eId}</span>,
        },
        {
          name: "Ticket Id",
          selector: (row, i) => row.ticketid,
          sortable: true,
          cell: (row) => <span>{row.ticketid}</span>,
        },
        {
          name: "Date of Request",
          selector: (row, i) => row.dateofrequest,
          sortable: true,
          cell: (row) => <span>{row.dateofrequest}</span>,
        },
        {
          name: "Type Of Request",
          selector: (row, i) => row.typeofrequest,
          sortable: true,
          cell: (row) => <span>{row.typeofrequest}</span>,
        },
        {
          name: "Remarks",
          selector: (row, i) => row.remarks,
          sortable: true,
          cell: (row) => <span>{row.remarks}</span>,
        },
        {
          name: "Screen shot",
          selector: (row, i) => row.screenshot,
          sortable: true,
          cell: (row) => <span>{row.screenshot}</span>,
        },
        {
          name: "Ticket Status",
          selector: (row, i) => row.ticketstatus,
          sortable: true,
          cell: (row) => <span>{row.ticketstatus}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen"
                style={{ marginRight: "3px" }}
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      raisedby: uName,
      eId: employeeId,
      ticketid: "",
      dateofrequest: null,
      requesttypemaster: [],
      typeofrequest: "",
      remarks: "",
      screenshot: "",
      ticketstatus: "",
      dateofclosed: null,
      closedremarks: "",
      closedby: "",
      startDate: new Date(),
      itsupporttickets_Id: "",
      reopendate: null,
      isEditing: false,
      isNewEntry: false,
    };
  }
  //edit
  handleEnterEditMode = (row) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    var dateofrequest = "";
    if (row.dateofrequest !== "0000-00-00") dateofrequest = row.dateofrequest;
    var dateofclosed = "";
    if (row.dateofclosed !== "0000-00-00") dateofclosed = row.dateofclosed;
    var reopendate = "";
    if (row.reopendate !== "0000-00-00") reopendate = row.reopendate;
    this.setState({
      raisedby: row.raisedby,
      eId: row.eId,
      ticketid: row.ticketid,
      dateofrequest: dateofrequest,
      typeofrequest: row.typeofrequest,
      remarks: row.remarks,
      screenshot: row.screenshot,
      ticketstatus: row.ticketstatus,
      dateofclosed: dateofclosed,
      closedremarks: row.closedremarks,
      closedby: row.closedby,
      reopendate: reopendate,
      itsupporttickets_Id: row.itsupporttickets_Id,
      isEditing: true,
    });
    // this.setState({ cData: { ...row }, isEditing: true });
  };
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({
      show: true,
      typeofrequest: "",
      remarks: "",
      screenshot: "",
      ticketstatus: "",
      dateofclosed: null,
      closedremarks: "",

      itsupporttickets_Id: "",
    });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  handleOffcanvasCloseOne = () => {
    this.setState({ isEditing: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  ItsupportticketseditHandler = (e) => {
    e.preventDefault();
    // const updateFormData = {...editFormData};
    // updateFormData['Itsupporttickets_Id'] = e.target.parentElement.getAttribute("id");
    // setEditFormData(updateFormData);
    // setEdit(true);
  };

  ItsupportticketsfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Itsupporttickets/listbysId", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  ItsupportticketssubmitHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    const newTicketId = this.generateTicketId();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("raisedby", this.state.raisedby);
    formData.append("eId", this.state.eId);
    formData.append("ticketid", newTicketId);
    formData.append("dateofrequest", this.state.dateofrequest);
    formData.append("typeofrequest", this.state.typeofrequest);
    formData.append("remarks", this.state.remarks);
    formData.append("screenshot", this.state.screenshot);
    formData.append("ticketstatus", this.state.ticketstatus);

    fetch(configData.api_url + "C_Itsupporttickets/add", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.ItsupportticketsfetchHandler();
          this.handleOffcanvasClose();
          this.setState({
            raisedby: "",
            eId: "",
            ticketid: "",
            dateofrequest: "",
            requesttypemaster: [],
            typeofrequest: "",
            remarks: "",
            screenshot: "",
            ticketstatus: "",
          });
        }
      });
  };
  ItsupportticketsupdateHandler = (e) => {
    e.preventDefault();
    const newTicketId = this.generateTicketId();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("raisedby", this.state.raisedby);
    formData.append("eId", this.state.eId);
    formData.append("ticketid", newTicketId);
    formData.append("dateofrequest", this.state.dateofrequest);
    formData.append("typeofrequest", this.state.typeofrequest);
    formData.append("remarks", this.state.remarks);
    formData.append("screenshot", this.state.screenshot);
    formData.append("ticketstatus", this.state.ticketstatus);
    formData.append("reopendate", this.state.reopendate);
    formData.append("itsupporttickets_Id", this.state.itsupporttickets_Id);
    fetch(configData.api_url + "C_Itsupporttickets/update", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.ItsupportticketsfetchHandler();
          this.handleOffcanvasCloseOne();
          this.setState({
            raisedby: "",
            eId: "",
            ticketid: "",
            dateofrequest: "",
            requesttypemaster: [],
            typeofrequest: "",
            remarks: "",
            screenshot: "",
            ticketstatus: "",
            dateofclosed: null,
            closedremarks: "",
            closedby: "",
          });
        }
      });
  };

  componentDidMount() {
    this.ItsupportticketsfetchHandler();
    this.requesttypemasterfetchHandler();
  }
  handleraisedbyChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ raisedby: fieldValue });
  };
  handleemployeeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ eId: fieldValue });
  };
  generateTicketId = () => {
    const latestTicket = this.state.data.sort(
      (a, b) => b.itsupporttickets_Id - a.itsupporttickets_Id
    )[0];
    const lastId = latestTicket
      ? latestTicket.ticketid.replace("APNAIT", "")
      : "10000";
    const nextId = parseInt(lastId, 10) + 1;
    return `APNAIT${nextId}`;
  };
  handleticketidChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ ticketid: fieldValue });
  };
  handleRequestChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

      this.setState({
        dateofrequest: formattedDate,
        startDate: new Date(),
      });
    }
  };

  handletypeofrequestChange = (e) => {
    e.preventDefault();

    const fieldValue = e.target.value;
    this.setState({ typeofrequest: fieldValue });
  };
  requesttypemasterfetchHandler = () => {
    fetch(configData.api_url + "C_Requesttypemaster/list", {
      // Enter your IP address here
      method: "POST",
      mode: "cors",
      body: {}, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ requesttypemaster: data.list });
        }
      });
  };
  handleremarksChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ remarks: fieldValue });
  };
  handlescreenshotChange = (e) => {
    e.preventDefault();
    const files = e.target.files[0];
    this.setState({ screenshot: files });
    // const fieldValue = e.target.value;
    // this.setState({ screenshot: fieldValue });
  };
  handleticketstatusChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ ticketstatus: fieldValue });
  };
  handleReopenChange = (date) => {
    if (date) {
      const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
        .toString()
        .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
      console.log(date);
      console.log(formattedDate);
      // Assuming you want to update the 'dob' state with the selected date
      this.setState({
        reopendate: formattedDate,
        startDate: new Date(),
      });
    }
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };
  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );
    const previousYearStart = new Date();
    previousYearStart.setFullYear(previousYearStart.getFullYear() - 100);
    previousYearStart.setMonth(0); // Set the month to January
    previousYearStart.setDate(1);
    return (
      <div>
        <div className="page-title">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="x_panel">
                <div className="x_content">
                  <div
                    id="datatable_wrapper"
                    className="dataTables_wrapper form-inline
                    dt-bootstrap no-footer"
                  >
                    <Row className="row">
                      <div className="title_left">
                        <h3
                          className="logoskybluecolor"
                          style={{ textAlign: "start" }}
                        >
                          IT Support Tickets
                        </h3>
                      </div>
                      <Col
                        className="d-flex justify-content-end"
                        style={{ marginRight: "5px", marginTop: "20px" }}
                      >
                        <Offcanvas
                          show={this.state.show}
                          onHide={this.handleOffcanvasClose}
                          {...this.props}
                          style={{ width: "500px" }}
                          placement="end"
                          backdrop="true"
                        >
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                              IT Support Tickets
                            </Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                            <Container
                              style={{ overflowY: "auto", height: "auto" }}
                            >
                              <Row className="divstyle">
                                <Row>
                                  <Form>
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Raised By
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handleraisedbyChange}
                                          className="form-control"
                                          name="raisedby"
                                          value={this.state.raisedby}
                                          required="required"
                                          placeholder="Enter raisedby"
                                          disabled
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.raisedby}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Employee Id
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handleemployeeChange}
                                          className="form-control"
                                          name="eId"
                                          value={this.state.eId}
                                          id="eId"
                                          required="required"
                                          placeholder="Enter Employee Id"
                                          disabled
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.eId}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Date of Request
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <DatePicker
                                          name="dateofrequest"
                                          dateFormat="dd/MM/yyyy"
                                          showTimeSelect={false}
                                          selected={this.state.dateofrequest}
                                          onChange={(date) =>
                                            this.handleRequestChange(date)
                                          }
                                          className="form-control"
                                          id="dateofrequest"
                                          placeholderText="Select Date"
                                          peekNextMonth
                                          showMonthDropdown
                                          showYearDropdown
                                          minDate={this.state.dateofrequest}
                                          //minDate={previousYearStart}
                                          dropdownMode="select"
                                          maxDate={new Date()}
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.dateofrequest}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Type Of Request
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <select
                                          style={{ borderRadius: "0px" }}
                                          className="form-select"
                                          name="tableName"
                                          onChange={
                                            this.handletypeofrequestChange
                                          }
                                        >
                                          <option defaultValue>
                                            {" "}
                                            Select Type Of Request{" "}
                                          </option>
                                          {this.state.requesttypemaster.map(
                                            (api, index, idx) => (
                                              <option
                                                key={index}
                                                value={api.requestType}
                                              >
                                                {" "}
                                                {api.requestType}{" "}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.typeofrequest}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Remarks
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <textarea
                                          onChange={this.handleremarksChange}
                                          className="form-control"
                                          name="remarks"
                                          value={this.state.remarks}
                                          required="required"
                                          placeholder="Enter Remarks"
                                          rows="4"
                                        ></textarea>
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.remarks}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Screen Shot
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="file"
                                          accept=".pdf,.jpg,jpeg,.png"
                                          onChange={this.handlescreenshotChange}
                                          className="form-control"
                                          name="screenshot"
                                          id="screenshot"
                                          //value={this.state.screenshot}
                                          required="required"
                                          placeholder="Enter Screenshot"
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.screenshot}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                  </Form>
                                </Row>
                              </Row>
                              <Row>
                                <Col
                                  className="d-flex justify-content-end"
                                  style={{ marginRight: "50px" }}
                                >
                                  <Button
                                    className="button"
                                    variant="success"
                                    onClick={this.ItsupportticketssubmitHandler}
                                    style={{
                                      marginTop: "20px",
                                    }}
                                  >
                                    Save
                                  </Button>
                                </Col>
                              </Row>
                            </Container>
                          </Offcanvas.Body>
                        </Offcanvas>
                        {/*offcanvas is over */}
                      </Col>
                    </Row>
                    <div>
                      {" "}
                      {this.state.isEditing && (
                        //off canvas
                        <Offcanvas
                          show={this.state.isEditing}
                          onHide={() => this.setState({ isEditing: false })}
                          {...this.props}
                          style={{ width: "600px", height: "auto" }}
                          placement="end"
                          backdrop="true"
                        >
                          <Offcanvas.Header closeButton>
                            <Offcanvas.Title>
                              Edit IT Support Tickets Page
                            </Offcanvas.Title>
                          </Offcanvas.Header>
                          <Offcanvas.Body>
                            <Container
                              style={{ overflowY: "auto", height: "auto" }}
                            >
                              <Row className="divstyle">
                                <Row>
                                  <Form>
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Raised By
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handleraisedbyChange}
                                          className="form-control"
                                          name="raisedby"
                                          value={this.state.raisedby}
                                          required="required"
                                          placeholder="Enter raised By"
                                          disabled
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.raisedby}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Employee Id
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handleemployeeChange}
                                          className="form-control"
                                          name="eId"
                                          value={this.state.eId}
                                          id="eId"
                                          required="required"
                                          placeholder="Enter Employee Id"
                                          disabled
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.eId}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Ticket Id
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handleticketidChange}
                                          className="form-control"
                                          name="ticketid"
                                          value={this.state.ticketid}
                                          required="required"
                                          placeholder="Enter Ticket Id"
                                          disabled
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.ticketid}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Date of Request
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <DatePicker
                                          name="dateofrequest"
                                          dateFormat="dd/MM/yyyy"
                                          showTimeSelect={false}
                                          selected={this.state.dateofrequest}
                                          onChange={(date) =>
                                            this.handleRequestChange(date)
                                          }
                                          className="form-control"
                                          id="dateofrequest"
                                          placeholderText="Select Date of Birth"
                                          peekNextMonth
                                          showMonthDropdown
                                          showYearDropdown
                                          minDate={previousYearStart}
                                          dropdownMode="select"
                                          maxDate={new Date()}
                                          disabled
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.dateofrequest}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Type Of Request
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <select
                                          style={{ borderRadius: "0px" }}
                                          className="form-select"
                                          name="typeofrequest"
                                          value={this.state.typeofrequest}
                                          onChange={
                                            this.handletypeofrequestChange
                                          }
                                          disabled
                                        >
                                          <option defaultValue>
                                            {" "}
                                            Select Type Of Request{" "}
                                          </option>
                                          {this.state.requesttypemaster.map(
                                            (api, index, idx) => (
                                              <option
                                                key={index}
                                                value={api.requestType}
                                              >
                                                {" "}
                                                {api.requestType}{" "}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.typeofrequest}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Remarks
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <textarea
                                          onChange={this.handleremarksChange}
                                          className="form-control"
                                          name="remarks"
                                          value={this.state.remarks}
                                          required="required"
                                          placeholder="Enter Remarks"
                                          rows="4"
                                          disabled
                                        ></textarea>
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.remarks}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Screen Shot
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handlescreenshotChange}
                                          className="form-control"
                                          name="screenshot"
                                          value={this.state.screenshot}
                                          required="required"
                                          placeholder="Enter Screen Shot"
                                          disabled
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.screenshot}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Ticket Status
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={
                                            this.handleticketstatusChange
                                          }
                                          className="form-control"
                                          name="ticketstatus"
                                          value={this.state.ticketstatus}
                                          required="required"
                                          placeholder="Enter Ticket Status"
                                          disabled
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.ticketstatus}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Date Of Closed
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <DatePicker
                                          name="dateofclosed"
                                          dateFormat="dd/MM/yyyy"
                                          showTimeSelect={false}
                                          selected={this.state.dateofclosed}
                                          onChange={(date) =>
                                            this.handleCloasedChange(date)
                                          }
                                          className="form-control"
                                          id="dateofclosed"
                                          placeholderText="Select Date of Birth"
                                          peekNextMonth
                                          showMonthDropdown
                                          showYearDropdown
                                          minDate={previousYearStart}
                                          dropdownMode="select"
                                          maxDate={new Date()}
                                          disabled
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.dateofclosed}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Closed Remarks
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <textarea
                                          onChange={
                                            this.handleclosedremarksChange
                                          }
                                          className="form-control"
                                          name="closedremarks"
                                          value={this.state.closedremarks}
                                          required="required"
                                          placeholder="Enter closedremarks"
                                          rows="4"
                                          disabled
                                        ></textarea>
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.closedremarks}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Closed By
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <Form.Control
                                          type="text"
                                          onChange={this.handleclosedbyChange}
                                          className="form-control"
                                          name="closedby"
                                          value={this.state.closedby}
                                          required="required"
                                          placeholder="Enter closedby"
                                          disabled
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.closedby}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg="4" md="4">
                                        <Form.Label
                                          style={{ paddingTop: "8px" }}
                                        >
                                          Re Open Date
                                        </Form.Label>
                                      </Col>
                                      <Col lg="8" md="8">
                                        <DatePicker
                                          name="reopendate"
                                          dateFormat="dd/MM/yyyy"
                                          showTimeSelect={false}
                                          selected={this.state.reopendate}
                                          onChange={(date) =>
                                            this.handleReopenChange(date)
                                          }
                                          className="form-control"
                                          id="reopendate"
                                          placeholderText="Select Date"
                                          peekNextMonth
                                          showMonthDropdown
                                          showYearDropdown
                                          minDate={previousYearStart}
                                          dropdownMode="select"
                                          maxDate={new Date()}
                                        />
                                      </Col>
                                      <div
                                        className="errorMsg"
                                        style={{ color: "red" }}
                                      >
                                        {" "}
                                        {this.state.errors.reopendate}{" "}
                                      </div>
                                    </Row>
                                    <br />
                                  </Form>
                                </Row>
                              </Row>
                              <Row>
                                <Col
                                  className="d-flex justify-content-end"
                                  style={{ marginRight: "50px" }}
                                >
                                  <Button
                                    className="button"
                                    variant="success"
                                    onClick={this.ItsupportticketsupdateHandler}
                                    style={{
                                      marginTop: "20px",
                                    }}
                                  >
                                    Update
                                  </Button>
                                </Col>
                              </Row>
                            </Container>
                          </Offcanvas.Body>
                        </Offcanvas>
                      )}
                    </div>

                    <Row>
                      <div style={{ marginRight: "5px" }}>
                        {/* searchbar,pagination,filter */}
                        <input
                          type="text"
                          style={{ float: "left", marginBottom: "10px" }}
                          onChange={this.handleFilter}
                          placeholder="Search..."
                        />
                        <Button
                          style={{ float: "right" }}
                          variant="primary"
                          className="bi bi-plus "
                          onClick={this.handleOffcanvasShow}
                        >
                          New
                        </Button>
                        <DataTable
                          data={paginatedData}
                          columns={this.state.tableClms}
                          pagination
                          paginationServer
                          paginationTotalRows={
                            this.state.filteredData
                              ? this.state.filteredData.length
                              : this.state.data.length
                          }
                          onChangeRowsPerPage={this.handlePerRowsChange}
                          onChangePage={this.handlePageChange}
                          paginationPerPage={this.state.itemsPerPage}
                          keyField="id"
                          className="table table-striped table-bordered dataTable no-footer employeesTableIcons hsGprm"
                        />
                      </div>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

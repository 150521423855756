import React, { Component } from "react";
import { Button, Container, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";

export default class Caresupremeaddons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.caresupremeaddons_Id,
          sortable: true,
          cell: (row) => <span>{row.caresupremeaddons_Id}</span>,
        },
        {
          name: "Product Name",
          selector: (row, i) => row.productName,
          sortable: true,
          cell: (row) => <span>{row.productName}</span>,
        },
        {
          name: "Add On",
          selector: (row, i) => row.addOn,
          sortable: true,
          cell: (row) => <span>{row.addOn}</span>,
        },
        {
          name: "isMandatory",
          selector: (row, i) => row.isMandatory,
          sortable: true,
          cell: (row) => <span>{row.isMandatory}</span>,
        },
        {
          name: "Add On Code",
          selector: (row, i) => row.addONCode,
          sortable: true,
          cell: (row) => <span>{row.addONCode}</span>,
        },
        {
          name: "Disable Addon",
          selector: (row, i) => row.disabledAddons,
          sortable: true,
          cell: (row) => <span>{row.disabledAddons}</span>,
        },
        {
          name: "DummyOne",
          selector: (row, i) => row.dummyOne,
          sortable: true,
          cell: (row) => <span>{row.dummyOne}</span>,
        },
        {
          name: "Dummy Two",
          selector: (row, i) => row.dummyTwo,
          sortable: true,
          cell: (row) => <span>{row.dummyTwo}</span>,
        },
        {
          name: "Dummy Three",
          selector: (row, i) => row.dummyThree,
          sortable: true,
          cell: (row) => <span>{row.dummyThree}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen"
                style={{ marginRight: "3px" }}
              ></Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      productName: "",
      addOn: "",
      isMandatory: "",
      addONCode: "",
      disabledAddons: "",
      dummyOne: "",
      dummyTwo: "",
      dummyThree: "",
      caresupremeaddons_Id: "",
      isEditing: false, // Flag to indicate edit mode
      isNewEntry: false,
    };
  }
  //edit
  handleEnterEditMode = (row) => {
    // this.setState({ cData: { ...row }, isEditing: true });
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Departments/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({
            productName: row.productName,
            addOn: row.addOn,
            isMandatory: row.isMandatory,
            addONCode: row.addONCode,
            disabledAddons: row.disabledAddons,
            dummyOne: row.dummyOne,
            dummyTwo: row.dummyTwo,
            dummyThree: row.dummyThree,
            caresupremeaddons_Id: row.caresupremeaddons_Id,
            isEditing: true,
          });
        }
      });
  };
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({ show: true });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  CaresupremeaddonseditHandler = (e) => {
    e.preventDefault();
    // const updateFormData = {...editFormData};
    // updateFormData['Caresupremeaddons_Id'] = e.target.parentElement.getAttribute("id");
    // setEditFormData(updateFormData);
    // setEdit(true);
  };

  CaresupremeaddonsfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Caresupremeaddons/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  CaresupremeaddonssubmitHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("productName", this.state.productName);
    formData.append("addOn", this.state.addOn);
    formData.append("isMandatory", this.state.isMandatory);
    formData.append("addONCode", this.state.addONCode);
    formData.append("disabledAddons", this.state.disabledAddons);
    formData.append("dummyOne", this.state.dummyOne);
    formData.append("dummyTwo", this.state.dummyTwo);
    formData.append("dummyThree", this.state.dummyThree);

    fetch(configData.api_url + "C_Caresupremeaddons/add", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.CaresupremeaddonsfetchHandler();
          this.handleOffcanvasClose();
          this.setState({
            productName: "",
            addOn: "",
            isMandatory: "",
            disabledAddons: "",
            addONCode: "",
            dummyOne: "",
            dummyTwo: "",
            dummyThree: "",
          });
        }
      });
  };
  CaresupremeaddonsupdateHandler = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("productName", this.state.productName);
    formData.append("addOn", this.state.addOn);
    formData.append("isMandatory", this.state.isMandatory);
    formData.append("addONCode", this.state.addONCode);
    formData.append("disabledAddons", this.state.disabledAddons);
    formData.append("dummyOne", this.state.dummyOne);
    formData.append("dummyTwo", this.state.dummyTwo);
    formData.append("dummyThree", this.state.dummyThree);
    formData.append("caresupremeaddons_Id", this.state.caresupremeaddons_Id);

    fetch(configData.api_url + "C_Caresupremeaddons/update", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.CaresupremeaddonsfetchHandler();
          this.handleOffcanvasClose();
        }
      });
  };

  componentDidMount() {
    this.CaresupremeaddonsfetchHandler();
  }
  handleproductNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ productName: fieldValue });
  };
  handleaddOnChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ addOn: fieldValue });
  };
  handleisMandatoryChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ isMandatory: fieldValue });
  };
  handleaddONCodeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ addONCode: fieldValue });
  };
  handledisableOneChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ disabledAddons: fieldValue });
  };
  handledummyOneChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyOne: fieldValue });
  };
  handledummyTwoChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyTwo: fieldValue });
  };
  handledummyThreeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyThree: fieldValue });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
    console.log(`Editing row with ID ${row.id}`);
    // implement your edit logic here
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("caresupremeaddons_Id", row.caresupremeaddons_Id);
    fetch(configData.api_url + "C_Caresupremeaddons/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.CaresupremeaddonsfetchHandler();
        }
      });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div className="page-title">
        <div className="row">
          <div className="title_left">
            <h2 style={{ textAlign: "start" }}>Care Supreme AddOns</h2>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                                   dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "5px", marginTop: "20px" }}
                    >
                      <Button
                        variant="primary"
                        className="bi bi-plus "
                        onClick={this.handleOffcanvasShow}
                      >
                        New
                      </Button>
                      <Offcanvas
                        show={this.state.show}
                        onHide={this.handleOffcanvasClose}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            Caresupremeaddons page
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                            <Row className="divstyle">
                              <Row>
                                <Form>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Product Name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleproductNameChange}
                                        className="form-control"
                                        name="productName"
                                        value={this.state.productName}
                                        required="required"
                                        placeholder="Enter productName"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.productName}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Add On
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleaddOnChange}
                                        className="form-control"
                                        name="addOn"
                                        value={this.state.addOn}
                                        required="required"
                                        placeholder="Enter addOn"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.addOn}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        isMandatory
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleisMandatoryChange}
                                        className="form-control"
                                        name="isMandatory"
                                        value={this.state.isMandatory}
                                        required="required"
                                        placeholder="Enter isMandatory"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.isMandatory}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Add On Code
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleaddONCodeChange}
                                        className="form-control"
                                        name="addONCode"
                                        value={this.state.addONCode}
                                        required="required"
                                        placeholder="Enter addONCode"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.addONCode}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Disable Addon
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledisableOneChange}
                                        className="form-control"
                                        name="disabledAddons"
                                        value={this.state.disabledAddons}
                                        required="required"
                                        placeholder="Enter disabledAddons"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.disabledAddons}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        DummyOne
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyOneChange}
                                        className="form-control"
                                        name="dummyOne"
                                        value={this.state.dummyOne}
                                        required="required"
                                        placeholder="Enter dummyOne"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyOne}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Two
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyTwoChange}
                                        className="form-control"
                                        name="dummyTwo"
                                        value={this.state.dummyTwo}
                                        required="required"
                                        placeholder="Enter dummyTwo"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyTwo}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        Dummy Three
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyThreeChange}
                                        className="form-control"
                                        name="dummyThree"
                                        value={this.state.dummyThree}
                                        required="required"
                                        placeholder="Enter dummyThree"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyThree}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                </Form>
                              </Row>
                            </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={this.CaresupremeaddonssubmitHandler}
                                  style={{
                                    marginTop: "20px",
                                  }}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                      {/*offcanvas is over */}
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <div>
                    {" "}
                    {this.state.isEditing && (
                      // Edit Offcanvas
                      <Offcanvas
                        show={this.state.isEditing}
                        onHide={() => this.setState({ isEditing: false })}
                        {...this.props}
                        style={{ width: "600px", height: "auto" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            Edit Care supreme addons Page
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <div className="form-horizontal form-label-left input_mask">
                            <Form className="form-horizontal form-label-left input_mask">
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    Product Name
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleproductNameChange}
                                    className="form-control"
                                    name="productName"
                                    value={this.state.productName}
                                    required="required"
                                    placeholder="Enter productName"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.productName}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    Add On
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleaddOnChange}
                                    className="form-control"
                                    name="addOn"
                                    value={this.state.addOn}
                                    required="required"
                                    placeholder="Enter addOn"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.addOn}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    isMandatory
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleisMandatoryChange}
                                    className="form-control"
                                    name="isMandatory"
                                    value={this.state.isMandatory}
                                    required="required"
                                    placeholder="Enter isMandatory"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.isMandatory}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    Add On Code
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleaddONCodeChange}
                                    className="form-control"
                                    name="addONCode"
                                    value={this.state.addONCode}
                                    required="required"
                                    placeholder="Enter addONCode"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.addONCode}{" "}
                                </div>
                              </Row>
                              <br />
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    Disable Addon
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handledisableOneChange}
                                    className="form-control"
                                    name="disabledAddons"
                                    value={this.state.disabledAddons}
                                    required="required"
                                    placeholder="Enter disabledAddons"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.disabledAddons}{" "}
                                </div>
                              </Row>
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    DummyOne
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handledummyOneChange}
                                    className="form-control"
                                    name="dummyOne"
                                    value={this.state.dummyOne}
                                    required="required"
                                    placeholder="Enter dummyOne"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.dummyOne}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    Dummy Two
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handledummyTwoChange}
                                    className="form-control"
                                    name="dummyTwo"
                                    value={this.state.dummyTwo}
                                    required="required"
                                    placeholder="Enter dummyTwo"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.dummyTwo}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    Dummy Three
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handledummyThreeChange}
                                    className="form-control"
                                    name="dummyThree"
                                    value={this.state.dummyThree}
                                    required="required"
                                    placeholder="Enter dummyThree"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.dummyThree}{" "}
                                </div>
                              </Row>
                              <br />
                              <div style={{ float: "right" }}>
                                <button
                                  className="btn btn-primary me-2 mt-5"
                                  style={{
                                    backgroundColor: "#23547e",
                                    color: "#fff",
                                    borderColor: "#2e6da4",
                                  }}
                                  onClick={this.CaresupremeaddonsupdateHandler}
                                >
                                  Update
                                </button>
                              </div>
                            </Form>
                          </div>
                        </Offcanvas.Body>
                      </Offcanvas>
                    )}
                  </div>
                  <Row>
                    <div style={{ marginRight: "5px" }}>
                      {/* searchbar,pagination,filter */}
                      <input
                        type="text"
                        style={{ float: "left", marginBottom: "10px" }}
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        paginationRowsPerPageOptions={[
                          10, 20, 30, 50, 60, 70, 80, 90, 100,
                        ]}
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

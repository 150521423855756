import React, { Component } from "react";
import { Form, Row, Col, Table, Button, Card } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import configData from "../config.json"; // Ensure you have the correct import path
import { MultiSelect } from "react-multi-select-component";

export default class GoMedicals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chpiId: sessionStorage.getItem("chpiId"),
      proposalId: sessionStorage.getItem("proposalId"),
      baseproductId: sessionStorage.getItem("baseproductId"),
      cqrId: sessionStorage.getItem("cqrId"),
      show: false,
      policiestab2: [],
      policiesform: [],
      members: [],
      medicals: [],
      medicalSets: {},
      isQuestionCheckbox1: {},
      selectedCheckboxes: {},
      mandatoryaddons: [],
      optionaladdons: [],
      showExistingSince: {},
      isSameAsPermanent: false,
      dateValues: {},
      goDigitquestions: [],
      selectedQuestionIds: [],
      goselectedOptions: [],
      selectedOptions: [],
      selectedMulti: [],
      isButtondisabled:false,
      prevquestionId:0,
      memberId:0,
      tab3Data: {
        medicalQns: "",
        productCode: "",
        questionSetCode: "",
        questionCode: "",
        qnDesc: "",
        newDesc: "",
        dummyThree: "",
        dummyTwo: "",
      },
      medicalQuestions: [
        {
          questionCode: "",
          answerType: "BOOLEAN",
          isApplicable: "True",
          detailAnswer: "TRUE",
          subQuestions: [
            {
              answerType: "domain",
              questionCode: "",
              isApplicable: "",
              detailAnswer: "",
            },
            {
              answerType: "TEXT_DESCRIPTION",
              questionCode: "",
              isApplicable: "",
              detailAnswer: "",
            },
          ],
        },
      ],
      consentCheckbox: false,
      declarationCheckbox: false,
      activeTabIndex: 0,
      pStatus: 2,
      firstTabInput: "",
      noPEDChecked: false,
    };
  }
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };

  componentDidMount() {
    this.apisfetchHandler();
    this.CaresuprememedicalqnsfetchHandler();
    this.updateAnswerTypes();
  }

  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "god_Leads/getleaddetails/", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          var members = data.lead.members;
          members.forEach((member) => {
            const minDate1 = new Date(member.dob);
            const cYear = minDate1.getFullYear();
            const cMonth = (minDate1.getMonth() + 1)
              .toString()
              .padStart(2, "0");
            const minDate = `${cYear}-${cMonth}`;
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear();
            const currentMonth = (currentDate.getMonth() + 1)
              .toString()
              .padStart(2, "0");
            const maxDate = `${currentYear}-${currentMonth}`;
            member.minDate = minDate;
            member.maxDate = maxDate;
          });
          this.setState({ members: members });
          this.CaresupremeaddonsfetchHandler();
        }
      });
  };

  CaresuprememedicalqnsfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "god_Leads/getproductdetails", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ goDigitquestions: data.list });
          this.setMedicalQuestions();
        }
      });
  };
  handleSubmitBackClick = (e) => {
    window.location.href = "/gomemberinfo";
  };
  handleMultiSelectChange(
    selectedValues,
    medicalsId,
    memberId,
    dummyTwo,
    personId
  ) {
    this.setState((prevState) => ({
      selectedMulti: {
        ...prevState.selectedMulti,
        [`${medicalsId}-${memberId}`]: selectedValues,
      },
    }));
  }

  updateAnswerTypes = () => {
    const { medicalQuestions } = this.state;

    medicalQuestions.forEach((question) => {
      let answerType = "";
      question.subQuestions.forEach((subQuestion) => {
        if (subQuestion.type === "domain") {
          answerType = "TEXT_DESCRIPTION";
        }
      });

      // Update the answerType of the question
      question.answerType = answerType;
    });

    // Update state with modified medicalQuestions
    this.setState({ medicalQuestions });
  };
  
  handleCheckboxChange = (
    questionSetCode,
    questionCode,
    memberId,
    lhmId,
    isChecked,
    selectedValue,
    checked
  ) => {
    const { selectedCheckboxes } = this.state;
    const key = `${questionCode}-${lhmId}`;
    const checkboxKey = questionSetCode + "-" + memberId;
    const isApplicable = selectedValue !== null ? "True" : "False";
    const detailAnswer = selectedValue !== null ? "TRUE" : "FALSE";

    const newQuestion = {
      questionCode: questionSetCode,
      answerType: "BOOLEAN",
      isApplicable: isApplicable,
      detailAnswer: detailAnswer,
      subQuestions: [], // Initialize subQuestions array
    };
    this.setState((prevState) => ({
      selectedCheckboxes: {
        ...selectedCheckboxes,
        [key]: isChecked,
      },
    }));
    if (checked && questionCode === questionSetCode) {
      const generatedSubQuestions = this.generateSubQuestions(
        questionSetCode,
        "checkbox",
        selectedValue
      );
      newQuestion.subQuestions.push(...generatedSubQuestions);
    }
    console.log("Updated question:", checkboxKey);
    console.log("Updated question:", newQuestion);
  };status

  handleSelectChange = (questionSetCode, selectedValue, memberId) => {
    const isApplicable = selectedValue !== null ? "True" : "False";
    const subQuestion = {
      questionCode: questionSetCode,
      answerType: "Domain",
      isApplicable: isApplicable,
      detailAnswer: selectedValue,
    };
    console.log("Select options:", subQuestion);
    if (questionSetCode === memberId) {
      const generatedSubQuestions = this.generateSubQuestions(
        questionSetCode,
        "select",
        selectedValue
      );
      console.log("Generated Subquestions:", generatedSubQuestions);
    }
  };

  handleTextChange = (questionSetCode, selectedValue, questionCode) => {
    const subQuestions = {
      questionCode: questionSetCode,
      answerType: "TEXT",
      isApplicable: "True",
      detailAnswer: selectedValue,
    };
    if (questionCode !== questionSetCode) {
      subQuestions.subQuestions = this.generateSubQuestions(
        questionSetCode,
        "text",
        selectedValue
      );
    }

    console.log("Updated question:", subQuestions);
  };

  generateSubQuestions = (questionSetCode, type, selectedValue) => {
    let subQuestions = [];
    const isApplicable = selectedValue ? "True" : "False";
    if (type === "checkbox") {
      subQuestions.push({
        questionCode: questionSetCode,
        answerType: "TEXT_DESCRIPTION",
        isApplicable: isApplicable,
        detailAnswer: "TRUE",
      });
    } else if (type === "text") {
      subQuestions.push({
        questionCode: questionSetCode,
        answerType: "TEXT_DESCRIPTION",
        isApplicable: isApplicable,
        detailAnswer: "TRUE",
      });
    }

    return subQuestions;
  };

  //new events
  CaresupremeaddonsfetchHandler = () => {
    var formData = new FormData();
    formData.append("chpiId", this.state.chpiId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "god_Leads/getproductdetails", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ policiesTable: data.product.medicalqns });
          this.setMedicalQuestions();
        }
      });
  };
  setMedicalQuestions = () => {
    const initialState = {};
    const medicalSets1 = {};
    const isQuestionCheckbox1 = {};
    this.state.policiesTable?.forEach((medicals) => {
       if (
          medicals?.dummyTwo === "checkbox" &&
          medicals.qnDesc !==
            "Does any person(s) to be insured has any Pre-existing diseases?"
        )
      {
        if(!isQuestionCheckbox1[medicals?.caresuprememedicalqns_Id])
        {
          isQuestionCheckbox1[medicals?.caresuprememedicalqns_Id] = medicals?.questionSetCode;
        }
      }
      if(!medicalSets1[medicals?.questionSetCode])
      {
        medicalSets1[medicals?.questionSetCode] = [];
       
      }
      medicalSets1[medicals?.questionSetCode].push(medicals?.caresuprememedicalqns_Id);
      // console.log(isQuestionCheckbox1);
      var isChecked = "No";
      if (
        medicals?.dummyTwo === "textbox" ||
        medicals?.dummyTwo === "multiselect" ||
        medicals?.dummyTwo === "select"
      ) {
        isChecked = "";
      }
      if (
        medicals?.dummyTwo === "checkbox" &&
        medicals.qnDesc ===
          "Does any person(s) to be insured has any Pre-existing diseases?"
      ) {
        isChecked = "Yes";
      }

      this.state.members?.forEach((member) => {
        if (!initialState[medicals?.caresuprememedicalqns_Id]) {
          initialState[medicals?.caresuprememedicalqns_Id] = {};
        }
        initialState[medicals?.caresuprememedicalqns_Id][member?.lhmId] =
          isChecked;
        if (
          medicals?.dummyTwo === "checkbox" &&
          medicals.qnDesc ===
            "Does any person(s) to be insured has any Pre-existing diseases?"
        ) {
          this.setState((prevState) => ({
            noPEDChecked: {
              ...prevState.noPEDChecked,
              [member.lhmId]: isChecked === "Yes" ? true : false,
            },
          }));
          // console.log(member.lhmId);
          // console.log(isChecked);
          // console.log(this.state.noPEDChecked);
        }
      });
    });
    this.setState({
      showExistingSince: initialState,
      medicalSets:medicalSets1,
      isQuestionCheckbox:isQuestionCheckbox1
    });
    // console.log(initialState);
    // console.log(this.state.noPEDChecked);
  };

  handleMedicalCheckboxChange = (fieldName) => {
    this.setState((prevState) => ({
      tab3Data: {
        ...prevState.tab3Data,
        [fieldName]: !prevState.tab3Data[fieldName],
      },
    }));
  };
  checkisKnockout = (value,isKnokout,questionId,memberId) =>
  {
    if(!this.state.isButtondisabled || questionId === this.state.prevquestionId)
    {
      var isButtondisabled = false;
      var qns = isKnokout.split(',');
      if(qns.indexOf(value) > -1)
      {
        isButtondisabled = true;
        console.log(isButtondisabled);
        this.setState({ isButtondisabled: isButtondisabled });
        this.setState({ prevquestionId: questionId });
        this.setState({ errormemberId: memberId });
        this.showToast("Policy Declined", "error");
      }
      else{
        if(this.state.isButtondisabled!==isButtondisabled){this.setState({ isButtondisabled: isButtondisabled });}
        if(this.state.prevquestionId !== 0){this.setState({ prevquestionId: 0 });}
        if(this.state.errormemberId !== 0){this.setState({ errormemberId: 0 });}
      }
    }
    return this.state.isButtondisabled;
  }
  handleCheckboxMedChange = (questionId, memberId, isChecked, dateValue,isKnokout) => {
    var dateValue1 = dateValue;
    if (isChecked === "check") {
      dateValue1 = dateValue ? "Yes" : "No";
    }
    this.checkisKnockout(dateValue1,isKnokout,questionId,memberId);
    const medicalSets1 = this.state.medicalSets;
    const isQuestionCheckbox1 = this.state.isQuestionCheckbox;
    var prevquestionId = this.state.prevquestionId;
    var errormemberId = this.state.errormemberId;
    // var isButtondisabled = this.state.isButtondisabled;
    this.setState((prevState) => {
      var newShowExistingSince = { ...prevState.showExistingSince };
        if (!newShowExistingSince[questionId]) {
          newShowExistingSince[questionId] = {};
        }
        newShowExistingSince[questionId][memberId] = dateValue1;
         if (isChecked === "check") {
          if(!dateValue)
          {
            medicalSets1[isQuestionCheckbox1[questionId]]?.forEach((medicals) => {
              newShowExistingSince[medicals][memberId] = "";
            });
          }
        }
        
        return {
          showExistingSince: newShowExistingSince
        };
    });
     if (isChecked === "check") {
          if(!dateValue)
          {
            medicalSets1[isQuestionCheckbox1[questionId]]?.forEach((medicals) => {

              if(medicals === prevquestionId && errormemberId === memberId)
              {
                this.setState({ errormemberId: 0,prevqestionId:0,isButtondisabled:false });
              }
            });
          }
        }
        
  };

  handleSubmitNextClick = (e) => {
    e.preventDefault();
    const allQuestionData = [];
    // Loop through all checked checkboxes and add question data to the array
    const { showExistingSince } = this.state;
    this.state.policiesTable.forEach((medicals) => {
      this.state.members.forEach((member) => {
        let ans =
          showExistingSince[medicals.caresuprememedicalqns_Id]?.[member.lhmId];
        // Add question data to the array
        if (medicals.dummyTwo === "multiselect") {
          if (ans !== "") ans = JSON.stringify(ans);
        }
        allQuestionData.push({
          csmqId: medicals.caresuprememedicalqns_Id,
          ans: ans,
          lhmId: member.lhmId,
          questionSetCode: medicals.questionSetCode,
          questionCode: medicals.questionCode,
        });
      });
    });

    const fd = new FormData();
    fd.append("proposalId", this.state.proposalId);
    fd.append("baseproductId", this.state.baseproductId);
    fd.append("qns", JSON.stringify(allQuestionData));
    fetch(configData.api_url + "god_Leads/updatemedicalqns/", {
      method: "POST",
      headers: {},
      mode: "cors",
      body: fd,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          window.location.href = "/gonomineedetails";
        }
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
      });
  };

  handleNoPEDChange = (e, member, caresuprememedicalqns_Id) => {
    const isChecked = e.target.checked;

    this.setState((prevState) => ({
      noPEDChecked: {
        ...prevState.noPEDChecked,
        [member.lhmId]: isChecked,
      },
    }));
    console.log(isChecked);
    this.setState((prevState) => {
      const updatedShowExistingSince = { ...prevState.showExistingSince };
      if (!isChecked) {
        this.state.policiesTable.forEach((medicals) => {
          updatedShowExistingSince[medicals.caresuprememedicalqns_Id][
            member.lhmId
          ] = "No";
        });
      } else
        updatedShowExistingSince[caresuprememedicalqns_Id][member.lhmId] =
          "Yes";
      console.log("updatedShowExistingSince:", updatedShowExistingSince);
      return { showExistingSince: updatedShowExistingSince };
    });
  };
  render() {
    const { members, policiesTable } = this.state;

    return (
      <div>
        <Row id="MainRoOne">
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-person-fill  "
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Proposer Info</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-person-vcard-fill"
                        onClick={this.handleSubmitBackClick}
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6
                        style={{ color: "#3d5a95" }}
                        onClick={this.handleSubmitBackClick}
                      >
                        Member Info
                      </h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-heart-pulse"
                        id="activeIcon1"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Medicals</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="inActiveTab">
                      <span
                        className="bi bi-pencil-square"
                        id="normalIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#98968c" }}>Nominee Details</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span className="bi bi-arrow-right" id="inActiveArrow"></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="inActiveTab">
                      <span
                        className="bi bi-clipboard-plus"
                        id="normalIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#98968c" }}>Add - On's</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}></Col>
            </Row>
          </Col>
        </Row>
        <br />
        <div className="row">
          <div className="col">
            <Table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Question</th>
                  {members.map((member) => (
                    <th key={member.relation}>
                      {member.fname}
                      {member.lname}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Array.isArray(policiesTable) &&
                  policiesTable.map((medicals, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{medicals.qnDesc}</td>
                      {this.state.members.map((member) => (
                        <td key={member.lhmId}>
                          {medicals.dummyTwo === "select" && (
                            <Form.Select
                              className="form-select"
                              name={`checkboxmonth-${medicals.caresuprememedicalqns_Id}-${member.lhmId}`}
                              value={
                                this.state.showExistingSince[
                                  medicals.caresuprememedicalqns_Id
                                ]?.[member.lhmId] || ""
                              }
                              id="checkboxmonth"
                              onChange={(e) =>
                                this.handleCheckboxMedChange(
                                  medicals.caresuprememedicalqns_Id,
                                  member.lhmId,
                                  medicals.dummyTwo,
                                  e.target.value,
                                  medicals.dummyFour
                                )
                              }
                            >
                              <option value="">Select an option</option>
                              {medicals.dummyThree ? (
                                JSON.parse(
                                  medicals.dummyThree.replace(/'/g, "")
                                ).map((option, index) => (
                                  <option
                                    key={option.ansCode}
                                    value={option.ansCode}
                                  >
                                    {option.ansDesc}
                                  </option>
                                ))
                              ) : (
                                <option value="">No options available</option>
                              )}
                            </Form.Select>
                          )}

                          {medicals.dummyTwo === "multiselect" && (
                            <MultiSelect
                              options={
                                medicals.dummyThree
                                  ? JSON.parse(
                                      medicals.dummyThree.replace(/'/g, "")
                                    ).map((option, index) => ({
                                      value: option.ansCode,
                                      label: option.ansDesc,
                                    }))
                                  : []
                              }
                              onChange={(e) =>
                                this.handleCheckboxMedChange(
                                  medicals.caresuprememedicalqns_Id,
                                  member.lhmId,
                                  medicals.dummyTwo,
                                  e,
                                  medicals.dummyFour
                                )
                              }
                              value={
                                this.state.showExistingSince[
                                  medicals.caresuprememedicalqns_Id
                                ]?.[member.lhmId] || []
                              }
                              name={`checkboxmonth-${medicals.caresuprememedicalqns_Id}-${member.lhmId}`}
                              id="checkboxmonth"
                              labelledBy={"Select"}
                            />
                          )}

                          {medicals.dummyTwo !== "select" &&
                            medicals.dummyTwo !== "multiselect" && (
                              <input
                                type="checkbox"
                                checked={
                                  this.state.showExistingSince[
                                    medicals.caresuprememedicalqns_Id
                                  ]?.[member.lhmId] === "Yes"
                                }
                                onChange={(e) => {
                                  medicals.qnDesc ===
                                  "Does any person(s) to be insured have any Pre-existing diseases?"
                                    ? this.handleNoPEDChange(
                                        e,
                                        member,
                                        medicals.caresuprememedicalqns_Id
                                      )
                                    : this.handleCheckboxMedChange(
                                        medicals.caresuprememedicalqns_Id,
                                        member.lhmId,
                                        "check",
                                        !(
                                          this.state.showExistingSince[
                                            medicals.caresuprememedicalqns_Id
                                          ]?.[member.lhmId] === "Yes"
                                        ),
                                        medicals.dummyFour
                                      );
                                }}
                              />
                            )}
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
        <Row className="mt-5">
          <Col>
            <Button
              id="getmedicalss4"
              onClick={this.handleSubmitBackClick}
              type="submit"
              style={{ float: "left" }}
            >
              Back
            </Button>
          </Col>
          <Col>
            <Button
              id="getmedicalss4"
              onClick={this.handleSubmitNextClick}
              type="submit"
              style={{ float: "right" }}
              disabled={this.state.isButtondisabled}
            >
              Next
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}
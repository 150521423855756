import React, { Component } from "react";
import "./PoliciesPage.css";
import {
  Col,
  Row,
  Form,
  Container,
  Image,
  Modal,
  Button,
  Table,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import configData from "../config.json";
import PoliciesKYCForm from "./PoliciesKYCForm";

export default class PoliciesPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isdefault: false,
      show: false,
      showOne: false,
      showTest: false,
      showModal: false,
      proposalId: sessionStorage.getItem("proposalId"),
      baseproductId: sessionStorage.getItem("baseproductId"),
      // $chpiIds: sessionStorage.getItem("$chpiIds"),

      //   itId: this.props.params.itId,
      quotes: [],
      
      highlights: [],
      selectedProduct: null,
      productBenefits: [],
      isLoading: true,
      policiesCondition: false,
    };
  }
  //modal
  handleButtonClick = () => {
    this.inputFileRef.current.click();
  };
  handleCloseOne = () => {
    this.setState({ showOne: false });
  };
  handleShowOne = (event, baseproductId) => {
    event.preventDefault(); // Prevent the default behavior of the link
    console.log("Selected Base Product ID:", baseproductId);
    this.setState({ showOne: true, selectedBaseproductId: baseproductId });
  };

  handleDownload = (fileName) => {
    const link = document.createElement("a");
    link.href = `/files/${fileName}`;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isLoading: false });
      const { proposalId } = this.state;
      var fd = new FormData();
      fd.append("proposalId", proposalId);
      console.log(proposalId);
      fetch(configData.api_url + "leads/getquotes/", {
        method: "POST",
        mode: "cors",
        body: fd, // body data type must match "Content-Type" header
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(this.state.quotes);
          this.showToast(data.message, data.success);
          if (data.status === "success") {
            console.log(data.quotes);
            this.setState({ quotes: data.quotes });
            this.HealthproductsfeaturesfetchHandler();
          }
        });
      console.log(this.state.quotes);
    }, 20000);
  }

  HealthproductsfeaturesfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "C_Healthproductsfeatures/listbyBaseproducId", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ highlights: data.list });
        }
      });
  };
  // //toast
  // handleButtonClick = () => {
  //   this.showToast("Please choose more than one policy.", "error");
  //   return false;
  // };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  handleChange = (e) => {
    //const { name, value } = e.target;
    // this.setState({
    //   [name]: value,
    //   errors: {
    //     ...this.state.errors,
    //     [name]: '', // Clear the corresponding error when the field value changes
    //   },
    // });
  };
  handleCompareClick = () => {
    const checkedPolicies = document.querySelectorAll(".compareP:checked");
    const clength = checkedPolicies.length;

    if (clength <= 1) {
      this.showToast("Please choose more than one policy.", "error");
      return false;
    }

    if (clength > 5) {
      this.showToast("Please choose less than 3 policies.", "error");
      return false;
    }

    let $chpiIds = "";
    checkedPolicies.forEach((policiescomparepage) => {
      $chpiIds +=
        policiescomparepage.parentNode.querySelector(
          ".chihealthplansintegrated_Id"
        ).value + ",";
    });

    $chpiIds = $chpiIds.replace(/,\s*$/, "");
    sessionStorage.setItem("chpiIds",$chpiIds);
    window.location = "/policiescomparepage/";
    console.log($chpiIds)
  };
  // handleBackPasswordClick = (quote) => {
  //   const chpiId = quote.chpiId;
  //   const cqrId = quote.cqrId;
  //   const baseproductId = quote.baseproductId;
  //   console.log(baseproductId);
  //   const { proposalId } = this.state;
  //   sessionStorage.setItem("chpiId", chpiId);
  //   sessionStorage.setItem("cqrId", cqrId);
  //   sessionStorage.setItem("baseproductId", baseproductId);
  //   var fd = new FormData();
  //   fd.append("proposalId", proposalId);
  //   fd.append("cqrId", cqrId);
  //   fd.append("chpiId", chpiId);
  //   fd.append("baseproductId", baseproductId);
  //   console.log(proposalId);
  //   fetch(configData.api_url + "leads/updateproposal/", {
  //     method: "POST",
  //     mode: "cors",
  //     body: fd, // body data type must match "Content-Type" header
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(this.state.quotes);
  //       this.showToast(data.message, data.success);
  //       if (data.status === "success") {
  //         this.setState({ policiesCondition: true });
  //       }
  //     });
  // };
  handleBackPasswordClick = (quote) => {
    const chpiId = quote.chpiId;
    const cqrId = quote.cqrId;
    const baseproductId = quote.baseproductId;
    const insurername = quote.insurername;
    const productcode = quote.productcode;

    console.log(baseproductId);
    const { proposalId } = this.state;
    sessionStorage.setItem("chpiId", chpiId);
    sessionStorage.setItem("cqrId", cqrId);
    sessionStorage.setItem("baseproductId", baseproductId);
    var fd = new FormData();
    fd.append("proposalId", proposalId);
    fd.append("cqrId", cqrId);
    fd.append("chpiId", chpiId);
    fd.append("baseproductId", baseproductId);
    console.log(proposalId);
    if (insurername === "Care") {
      window.location.href = "/policieskycform"; // Assign new URL to window.location.href
    }
    if (productcode === "10401") {
      // Navigate to PoliciesThankesPage
      window.location.href = "/goproposerinfo"; // Assign new URL to window.location.href
    }
    if (productcode === "2868") {
      // Navigate to PoliciesThankesPage
      window.location.href = "/rgipolicieskyc"; // Assign new URL to window.location.href
    }
    if (baseproductId === "1186") {
      window.location.href = "/icicipolicieskycpage"; // Assign new URL to window.location.href
    }
    if (productcode === "2824") {
      window.location.href = "/rgipolicieskyc"; // Assign new URL to window.location.href
    }
    if (productcode === "2876") {
      window.location.href = "/rgipolicieskyc"; // Assign new URL to window.location.href
    }
    fetch(configData.api_url + "leads/updateproposal/", {
      method: "POST",
      mode: "cors",
      body: fd, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(this.state.quotes);
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          this.setState({ policiesCondition: true });
        }
      });
  };

  render() {
    const { policiesCondition } = this.state;
    const { isLoading } = this.state;
    const { quotes } = this.state;
    if (policiesCondition) {
      return <PoliciesKYCForm />;
    }

    if (isLoading) {
      return (
        <div>
          <div className="text-center mt-5">
            <div className="spinner-grow text-primary" role="status">
              <span className="visually-hidden"></span>
            </div>
            <div className="spinner-grow text-success" role="status">
              <span className="visually-hidden"></span>
            </div>
            <div className="spinner-grow text-danger" role="status">
              <span className="visually-hidden"></span>
            </div>
            <div className="spinner-grow text-warning" role="status">
              <span className="visually-hidden"></span>
            </div>
            <div className="spinner-grow text-info" role="status">
              <span className="visually-hidden"></span>
            </div>
            <div className="spinner-grow text-light" role="status">
              <span className="visually-hidden"></span>
            </div>
            <div>We are Bringing Best Quotes For You...</div>
          </div>
        </div>
      );
    }
    if (!quotes || quotes.length === 0) {
      return (
        <div>
          <h3 className="logoskybluecolor">Policies page</h3>
          <Container className="thm-container bgColorContainer mt-3">
            <Row className="comparePolicyScreenTopHeading">
              <Col lg="2" md="2" sm="2" className="text-center">
                <button
                  type="button"
                  className="btn btn-secondary"
                  id="comparePolocies"
                  onClick={this.handleCompareClick}
                >
                  Compare
                </button>
              </Col>
              <Col lg="4" md="4" sm="4"></Col>
              <Col lg="3" md="3" sm="3">
                <Form>
                  <Row>
                    <Col lg="4" md="4" sm="4">
                      <Form.Group className="form-group">
                        <Form.Label
                          className="control-label col-form-label"
                          style={{ color: "white" }}
                        >
                          Cover:
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col lg="8" md="8" sm="8">
                      <Form.Group className="form-group">
                        <Form.Select
                          aria-label="Default select example"
                          // value={sumInsured}
                          onChange={this.handleChange}
                          className="form-control"
                          id="sumInsured"
                        >
                          <option value="">Choose Cover</option>
                          <option value="1***3">1 lakh to 3 lakh</option>
                          <option value="3***6">3 lakh to 6 lakh</option>
                          <option value="6***9">6 lakh to 9 lakh</option>
                          <option value="9***12">9 lakh to 12 lakh</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Col>
              <Col lg="3" md="3" sm="3">
                <Form>
                  <Row>
                    <Col lg="5" md="5" sm="5">
                      <Form.Group className="form-group">
                        <Form.Label
                          className="control-label col-form-label font-weight-bold"
                          style={{ color: "white" }}
                        >
                          Plan Type:
                        </Form.Label>
                      </Form.Group>
                    </Col>
                    <Col lg="7" md="7" sm="7">
                      <Form.Group className="form-group">
                        <Form.Select
                          aria-label="Default select example"
                          // defaultValue={this.state.customer.planType}
                          className="form-control"
                          id="planType"
                        >
                          <option value="Base">Base</option>
                          <option value="Top">TopUp</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          
          </Container>
        </div>
      );
    }
    return (
      <div>
        <h3 className="logoskybluecolor">Policies page</h3>
        <Container className="thm-container bgColorContainer mt-3">
          <Row className="comparePolicyScreenTopHeading">
            <Col lg="2" md="2" sm="2" className="text-center">
              <button
                type="button"
                className="btn btn-secondary"
                id="comparePolocies"
                onClick={this.handleCompareClick}
              >
                Compare
              </button>
            </Col>
            <Col lg="4" md="4" sm="4"></Col>
            <Col lg="3" md="3" sm="3">
              <Form>
                <Row>
                  <Col lg="4" md="4" sm="4">
                    <Form.Group className="form-group">
                      <Form.Label
                        className="control-label col-form-label"
                        style={{ color: "white" }}
                      >
                        Cover:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg="8" md="8" sm="8">
                    <Form.Group className="form-group">
                      <Form.Select
                        aria-label="Default select example"
                        // value={sumInsured}
                        onChange={this.handleChange}
                        className="form-control"
                        id="sumInsured"
                      >
                        <option value="">Choose Cover</option>
                        <option value="1***3">1 lakh to 3 lakh</option>
                        <option value="3***6">3 lakh to 6 lakh</option>
                        <option value="6***9">6 lakh to 9 lakh</option>
                        <option value="9***12">9 lakh to 12 lakh</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
            <Col lg="3" md="3" sm="3">
              <Form>
                <Row>
                  <Col lg="5" md="5" sm="5">
                    <Form.Group className="form-group">
                      <Form.Label
                        className="control-label col-form-label font-weight-bold"
                        style={{ color: "white" }}
                      >
                        Plan Type:
                      </Form.Label>
                    </Form.Group>
                  </Col>
                  <Col lg="7" md="7" sm="7">
                    <Form.Group className="form-group">
                      <Form.Select
                        aria-label="Default select example"
                        // defaultValue={this.state.customer.planType}
                        className="form-control"
                        id="planType"
                      >
                        <option value="Base">Base</option>
                        <option value="Top">TopUp</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Row>
            <br />
            <input type="hidden" id="itId" value=""></input>
            <input
              type="hidden"
              id="chihealthplansintegrated_Id"
              value=""
            ></input>
          </Row>
          <br />
          <Row>
            {this.state.quotes.map((quote, index) => (
              <div key={index} className="compareDetailssection">
                <Col
                  lg="12"
                  md="12"
                  sm="12"
                  className="compareDetailssectionleft"
                >
                  <Row className="compareDetailssectionleftbox">
                    <Col
                      lg="1"
                      md="1"
                      sm="1"
                      style={{ justifyContent: "center", marginTop: "60px" }}
                    >
                      <input
                        type="hidden"
                        className="chihealthplansintegrated_Id mt-2"
                        style={{ justifyContent: "center" }}
                        value={quote.chihealthplansintegrated_Id}
                      />
                      <input type="checkbox" className="compareP" />
                    </Col>
                    <Col lg="2" md="2" sm="2">
                      <Image
                        style={{
                          width: "100%",
                          marginLeft: "5px",
                          marginTop: "10px",
                        }}
                        src={configData.static_url + quote.insurerlogo}
                        alt="image"
                      ></Image>

                      <Link
                        onClick={(e) =>
                          this.handleShowOne(e, quote.baseproductId)
                        }
                      >
                        Features
                      </Link>

                      <div>
                        <Modal
                          size="lg"
                          // centered
                          show={this.state.showOne}
                          onHide={this.handleCloseOne}
                        >
                          <Modal.Header closeButton>Benefits</Modal.Header>
                          <Modal.Body>
                            {" "}
                            <Table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>S No.</th>
                                  <th>Benefits</th>
                                  <th>Description</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.quotes.map(
                                  (quote, index) =>
                                    quote.baseproductId ===
                                      this.state.selectedBaseproductId &&
                                    quote.features.map((policy, index) => (
                                      <tr key={"sa" + index}>
                                        <td>{index + 1}</td>
                                        <td>{policy.featuressubcategory}</td>
                                        <td>{policy.featuresdescription}</td>
                                      </tr>
                                    ))
                                )}
                              </tbody>
                            </Table>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={this.handleCloseOne}
                            >
                              Close
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </Col>
                    <Col lg="2" md="2" sm="2">
                      <p className="mt-3">Product Name - {quote.productname}</p>
                      <p className="mt-3">Tenure - {quote.tenure}</p>
                      <p className="mt-3">Cover Type - {quote.covertype}</p>
                    </Col>
                    <Col lg="1" md="1" sm="2">
                      <p>Sum Insured</p>
                      <b>{quote.suminsured}</b>
                    </Col>
                    <Col lg="4" md="4" sm="2">
                      <p>Benefits</p>
                      {quote.features.slice(0, 4).map((policy, index) => (
                        <div className="bi bi-caret-right" key={"sa" + index}>
                          {policy.featuressubcategory}
                        </div>
                      ))}
                      <br />
                      {quote.hsList !== "" && (
                        <Link
                          to={configData.static_url + quote.hsList}
                          target="_blank"
                          // onClick={() =>
                          //   this.handleDownload(configData.static_url + quote.hsList)
                          // }
                        >
                          Hospital List 1
                        </Link>
                      )}
                      <br />
                      {quote.hsList_OPD !== "" && (
                        <Link
                          to={configData.static_url + quote.hsList_OPD}
                          target="_blank"
                          // onClick={() => this.handleDownload("example.xlsx")}
                        >
                          Hospital List 2
                        </Link>
                      )}
                      <br />
                      {quote.brochure !== "" && (
                        <Link
                          to={configData.static_url + quote.brochure}
                          target="_blank"
                          // onClick={() => this.handleDownload("example.xlsx")}
                        >
                          Brochure
                        </Link>
                      )}
                      <br />
                      {quote.policywordings !== "" && (
                        <Link
                          to={configData.static_url + quote.policywordings}
                          target="_blank"
                          // onClick={() => this.handleDownload("example.xlsx")}
                        >
                          Policy wordings
                        </Link>
                      )}
                    </Col>
                    <Col lg="2" md="2" sm="3" className="comparepagesLastDiv">
                      <br />
                      <button
                        onClick={() => this.handleBackPasswordClick(quote)}
                        //  to={"/policieskycform/"}
                        className="btn btn-primary"
                        style={{ color: "#ffffff" }}
                      >
                        RS. {quote.premium}/-
                      </button>
                    </Col>
                  </Row>
                </Col>
              </div>
            ))}
          </Row>
          <br />
        </Container>
      </div>
    );
  }
}

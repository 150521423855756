import React, { Component } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Delta from "quill-delta";
import "./Templates.css";
import {
  Button,
  Container,
  Row,
  Form,
  Col,
  Offcanvas,
  Modal,
} from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import "bootstrap/dist/css/bootstrap.min.css";
import configData from "../config.json";

export default class Templates extends Component {
  constructor(props) {
    super(props);
    this.inputFileRef = React.createRef();
    this.state = {
      show: false,
      showOne: false,
      templates: [],
      selectedOptions: [],
      tables: [],
      columns: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      editIndex: null,
      inputValue: "",
      showModal: false,
      text: "",
      selectedOption: "",
      selectedValue: "",
      value: "",
      inputFieldHtml: "",
      htmlContent: "",
      editorValue: "",
      filteredData: null,
      errors: {},
      tId: "",
      ruleData: {
        tName: "",
        tParams: "",
        cHeight: "",
        cWidth: "",
        tData: "",
      },
      rConditions: {
        tableName: "",
        columnName: "",
        cOperator: "",
        cValue: "",
        cGate: "",
      },
      formData: {
        data: "",
      },
      tableData: [
        {
          name: "S.NO",
          selector: (row, i) => row.tId,
          sortable: true,
          cell: (row) => <span>{row.tId}</span>,
        },
        {
          name: "Template Name",
          selector: (row, i) => row.tName,
          sortable: true,
          cell: (row) => <span>{row.tName}</span>,
        },
        // {
        //   name: "Template Data",
        //   selector: (row, i) => row.tData,
        //   sortable: true,
        //   cell: (row) => <span>{row.tData}</span>,
        // },
        {
          name: "Template Parameters",
          selector: (row, i) => row.tParams,
          sortable: true,
          cell: (row) => <span>{row.tParams}</span>,
        },
        {
          name: "Container Width",
          selector: (row, i) => row.cWidth,
          sortable: true,
          cell: (row) => <span>{row.cWidth}</span>,
        },
        {
          name: "Container Height",
          selector: (row, i) => row.cHeight,
          sortable: true,
          cell: (row) => <span>{row.cHeight}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row, index) => (
            <div>
              {/* edit button and delete button */}
              <button
                style={{ marginLeft: "5px" }}
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen btn btn-primary"
              ></button>{" "}
              <button
                variant="danger"
                onClick={() => this.handleDeleteRow(row, index)}
                className="bi bi-trash btn btn-danger"
              ></button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      isEditing: false, // Flag to indicate edit mode
      isNewEntry: false,
    };
    this.quillRef = React.createRef();
  }
  //edit
  handleEnterEditMode = (row) => {
    // this.setState({ cData: { ...row }, isEditing: true });
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    this.setState({
      tId: row.tId,
      ruleData: {
        tName: row.tName,
        tParams: row.tParams,
        cHeight: row.cHeight,
        cWidth: row.cWidth,
        tData: row.tData,
      },
      rConditions: {
        tableName: row.tableName,
        columnName: row.columnName,
        cOperator: row.cOperator,
        cValue: row.cValue,
        cGate: row.cGate,
      },
      isEditing: true,
    });
  };
  // offcanvas Open
  handleShow = () => {
    this.setState({
      show: true,
      tId: "",
      ruleData: {
        tName: "",
        tParams: "",
        cHeight: "",
        cWidth: "",
        tData: "",
      },
      rConditions: {
        tableName: "",
        columnName: "",
        cOperator: "",
        cValue: "",
        cGate: "",
      },
      formData: {
        data: "",
      },
    });
  };
  // Offcanvas close button
  handleClose = () => {
    this.setState({ show: false });
  };
  // Offcanvas Formchange
  inputChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const newFormData = this.state.ruleData;
    newFormData[fieldName] = fieldValue;
    this.setState({ ruleData: newFormData });
  };

  // quill ,modal, add, delete , update, Html format functionalities

  modifyHtml(htmlString) {
    // Create a new DOM element from the HTML string
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(htmlString, "text/html");

    // Find all the image elements in the HTML
    const images = parsedHtml.getElementsByTagName("img");

    // Modify the width of each image element
    for (let i = 0; i < images.length; i++) {
      images[i].style.width = "100%";
    }

    // Return the modified HTML code
    return parsedHtml.documentElement.innerHTML;
  }

  handleChange = (value) => {
    this.setState({
      tData: value,
      tName: "",
      tParams: "",
    });
    // e.preventDefault();
    const fieldName = "tData";
    const fieldValue = value;
    const newFormData = this.state.ruleData;
    newFormData[fieldName] = fieldValue;
    this.setState({ ruleData: newFormData });
  };
  validateForm() {
    let template = this.state.ruleData;
    let errors = {};
    let formIsValid = true;

    if (!template["tName"]) {
      formIsValid = false;
      toast.info("Please Pass Template Name");
    }
    // if (!template["tParams"]) {
    //   formIsValid = false;
    //   errors["tParams"] = "*Please enter parameters.";
    // }
    // if (!template["tData"]) {
    //   formIsValid = false;
    //   errors["html"] = "*Please enter template.";
    // }
    else if (!template["cHeight"]) {
      formIsValid = false;
      toast.info("*Please Enter Height.");
    } else if (!template["cWidth"]) {
      formIsValid = false;
      toast.info("*Please Enter Width.");
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  //add event

  handleButtonClick = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("tName", this.state.ruleData.tName);
      formData.append("tData", this.state.ruleData.tData);
      formData.append("tParams", this.state.ruleData.tParams);
      formData.append("cWidth", this.state.ruleData.cWidth);
      formData.append("cHeight", this.state.ruleData.cHeight);

      fetch(configData.api_url + "/templates/add", {
        method: "POST",
        mode: "cors",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            this.showToast(data.message, data.status);
            this.setState({
              show: false,
              rConditions: {
                tableName: "",
                columnName: "",
                cOperator: "",
                cValue: "",
                cGate: "",
              },
              ruleData: {
                tName: "",
                tParams: [],
                cHeight: "",
                cWidth: "",
                tData: "",
              },
            });

            this.apisfetchHandler();
          }
        });
    }
  };
  templateHandleupdate = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      var formData = new FormData();
      formData.append("sId", this.state.sId);
      formData.append("uId", this.state.uId);
      formData.append("tName", this.state.ruleData.tName);
      formData.append("tData", this.state.ruleData.tData);
      formData.append("tParams", this.state.ruleData.tParams);
      formData.append("cWidth", this.state.ruleData.cWidth);
      formData.append("cHeight", this.state.ruleData.cHeight);
      formData.append("tId", this.state.tId);
      fetch(configData.api_url + "/templates/update", {
        method: "POST",
        mode: "cors",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          this.showToast(data.message, data.status);
          if (data.status === "success") {
            this.apisfetchHandler();
            this.handleClose();
            this.showToast(data.message, data.status);
            this.setState({
              show: false,
              rConditions: {
                tableName: "",
                columnName: "",
                cOperator: "",
                cValue: "",
                cGate: "",
              },
              ruleData: {
                tName: "",
                tParams: [],
                cHeight: "",
                cWidth: "",
                tData: "",
              },
            });

            this.apisfetchHandler();
          }
        });
    }
  };
  //delete event
  // handleButtonClickOne = () => {
  //   this.setState({ showModal: true });
  // };
  handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "text/csv") {
      // Handle the selected CSV file here
      //console.log("Selected CSV file:", file);
    } else {
      // File is not a CSV file, handle the error
      //console.log("Please select a CSV file");
    }
  };

  handleCloseOne = () => {
    this.setState({ showOne: false });
  };

  handleShowOne = () => {
    this.setState({ showOne: true });
  };
  handleDeleteRow = (idx, index) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    const updatedData = this.state.templates;
    updatedData.splice(index, 1);
    this.setState({ templates: updatedData });
  };
  //   const rowid = row.id;
  // //console.log(`Delete row with ID ${rowid}`);

  handlePrintClick = () => {
    const { value } = this.state;
    const htmlContent = value.split("\n");
    this.setState({ htmlContent });
  };

  handleHtmlClick = () => {
    //const { cHeight, cWidth } = this.state;
    const inputFieldHtml = `<div style="height: ${this.state.ruleData.cHeight}; width: ${this.state.ruleData.cWidth};"></div>`;
    this.setState({ inputFieldHtml });
  };

  handleHeightChange = (e) => {
    //this.setState({ cHeight: event.target.value });
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const newFormData = this.state.ruleData;
    newFormData[fieldName] = fieldValue;
    this.setState({ ruleData: newFormData });
  };

  handleWidthChange = (e) => {
    //this.setState({ cWidth: event.target.value });
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const newFormData = this.state.ruleData;
    newFormData[fieldName] = fieldValue;
    this.setState({ ruleData: newFormData });
  };

  handleEditorChange(value) {
    this.setState({ editorValue: value });
  }
  handleQuillChange = (content, delta, source, editor) => {
    this.setState({ quillData: editor.getHTML() });
  };

  handleColumnChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.rConditions;
    const selectedLabel = e.target.options[e.target.selectedIndex].text;
    inputData[fieldName] = selectedLabel;
    this.setState({ columnValue: fieldValue });
  };

  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "/templates/list", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ templates: data.list });
        }
      });
  };

  getTables = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "/masters/gettables", {
      method: "POST",
      mode: "cors",
      body: {},
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({ tables: data.tables });
          //console.log(this.state.tables);
        }
      });
  };

  getColumns = (tableName, rcolumn) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("tableName", tableName);
    fetch(configData.api_url + "/masters/getcolumns", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({ [rcolumn]: data.columnsWT });
        }
      });
  };

  componentDidMount() {
    this.apisfetchHandler();
    this.getTables();
  }

  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };

  handleShowModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  handleOptionChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.rConditions;
    inputData[fieldName] = fieldValue;
    this.setState({ rConditions: inputData });
    this.getColumnsWT(fieldValue, "columns");
    this.setState({ selectedOption: e.target.value });
  };

  // insertSelectedOption = () => {
  //   const { rConditions } = this.state;
  //   if (!rConditions) return;

  //   const quill = this.quillRef.current.getEditor();
  //   const selection = quill.getSelection();

  //   if (!selection || selection.index === undefined) {
  //     // Handle the case when the selection is not set or invalid
  //     return;
  //   }

  //   const selectedOption = `$\{${rConditions.tableName}.${rConditions.columnName}}`;

  //   const newDelta = new Delta()
  //     .retain(selection.index)
  //     .delete(selection.length)
  //     .insert(selectedOption, { option: selectedOption });

  //   quill.updateContents(newDelta, "user");
  //   this.handleCloseModal();
  // };
  // insertSelectedOption = () => {
  //   const { rConditions } = this.state;
  //   const templateParameter = this.state.ruleData.tParams;
  //   if (!rConditions) return;

  //   const quill = this.quillRef.current.getEditor();
  //   const selection = quill.getSelection();

  //   if (!selection || selection.index === undefined) {
  //     // Handle the case when the selection is not set or invalid
  //     return;
  //   }

  //   const selectedOption = `$\{${rConditions.tableName}.${rConditions.columnName}}`;

  //   const newDelta = new Delta()
  //   .retain(selection.index)
  //   .delete(selection.length)
  //   .insert(selectedOption,{option:selectedOption})

  //  quill.updateContents(newDelta, "user");
  //   this.handleCloseModal();

  //   // Push the selected option to templateParameter as a list

  //   const tParams = [...templateParameter, `${rConditions.tableName}.${rConditions.columnName}`];
  //   this.setState({
  //     ruleData: {
  //       tParams: tParams
  //     }
  //   });

  //   // Display templateParameter in //console

  //   //console.log("templateParameter:", tParams);

  // };
  insertSelectedOption = () => {
    const { rConditions, selectedOptions } = this.state;
    const templateParameter = this.state.ruleData.tParams;
    if (!rConditions) return;

    const selectedOption = `$\{${rConditions.tableName}.${rConditions.columnName}}`;

    if (selectedOptions.includes(selectedOption)) {
      alert("Error: Duplicate entry. The selected combination already exists.");
      return;
    }

    const quill = this.quillRef.current.getEditor();
    const selection = quill.getSelection();

    if (!selection || selection.index === undefined) {
      return;
    }

    const newDelta = new Delta()
      .retain(selection.index)
      .delete(selection.length)
      .insert(selectedOption);

    quill.updateContents(newDelta, "user");
    this.handleCloseModal();

    const tParams = [
      ...templateParameter,
      `${rConditions.tableName}.${rConditions.columnName}`,
    ];
    this.setState(
      {
        ruleData: {
          tParams: tParams,
        },
        selectedOptions: [...selectedOptions, selectedOption],
      },
      () => {
        //console.log("templateParameter:", tParams);
      }
    );
  };

  handleKeyDown = (event) => {
    if (event.key === "#") {
      this.setState({ showModal: true });
    }
  };

  // handleEditorChange = (value) => {
  //     this.setState({ editorHtml: value });
  //   }

  handleTemplateSubmit = (e) => {
    e.preventDefault();
    ////console.log(this.state.text);
  };

  handleUpdateRow = (index) => {
    const { tableData } = this.state;
    const row = tableData[index];
    this.setState({ tData: row.data, selectedRow: index });
  };
  // filter
  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.rules.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };
  inputtableSChange = (e) => {
    e.preventDefault();
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const inputData = this.state.rConditions;
    inputData[fieldName] = fieldValue;
    this.setState({ rConditions: inputData });
    this.getColumns(fieldValue, "columns");
  };
  handleButtonClickOne = () => {
    this.inputFileRef.current.click();
  };
  render() {
    const DefaultData = this.state.rConditions;
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.templates.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div>
        <div className="row">
          <h2 style={{ textAlign: "start" }}>TEMPLATE PAGE</h2>
        </div>
        <div className="x_panel">
          <Col
            className="d-flex justify-content-end"
            style={{ marginRight: "5px", marginTop: "20px" }}
          >
            <Button
              variant="warning"
              className="me-2"
              onClick={this.handleShowOne}
            >
              {" "}
              Upload *csv
            </Button>
            <Button variant="info" className="me-2  ">
              Download *csv
            </Button>

            <Button
              variant="primary"
              className="bi bi-plus "
              ref={this.inputFileRef}
              onClick={this.handleShow}
            >
              New
            </Button>
            <Offcanvas
              show={this.state.show}
              onHide={this.handleClose}
              {...this.props}
              style={{ width: "700px", height: "auto" }}
              placement="end"
              backdrop="true"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title>New Template</Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Container
                //  style={{ "overflow-y": "auto", height: "auto" }}
                >
                  <div>
                    <div>
                      <Row>
                        <Col lg="4" md="4">
                          <Form.Label>Template Name</Form.Label>
                        </Col>
                        <Col lg="8" md="8">
                          <Form.Control
                            name="tName"
                            value={DefaultData.tName}
                            onChange={this.inputChange}
                            required="required"
                            type="text"
                            placeholder="Enter Template Name"
                            className="form-control"
                          />
                        </Col>
                        <div
                          className="errorMsg text-center"
                          style={{ color: "red" }}
                        >
                          {" "}
                          {this.state.errors.tName}{" "}
                        </div>
                      </Row>
                    </div>
                    <br />
                    {/* <div>
                    <Row>
                      <Col lg="4" md="4">
                        <Form.Label>Template Parameters</Form.Label>
                      </Col>
                      <Col lg="8" md="8">
                        <Form.Control
                          name="tParams"
                          value={this.state.ruleData.tParams}
                          onChange={this.inputChange}
                          required="required"
                          type="text"
                          placeholder="Enter Template Parameters"
                          className="form-control"
                        />
                      </Col>
                    </Row>
                  </div>
                  <br /> */}
                    <div>
                      <Row>
                        <Col lg="4" md="4">
                          <Form.Label>Container Height:</Form.Label>
                        </Col>
                        <Col lg="8" md="8">
                          <Form.Control
                            name="cHeight"
                            required="required"
                            type="text"
                            placeholder="Container Height:"
                            className="form-control"
                            value={DefaultData.cHeight}
                            onChange={this.handleHeightChange}
                          />
                        </Col>
                        <div
                          className="errorMsg text-center"
                          style={{ color: "red" }}
                        >
                          {" "}
                          {this.state.errors.cHeight}{" "}
                        </div>
                      </Row>
                    </div>
                    <br />
                    <div>
                      <Row>
                        <Col lg="4" md="4">
                          <Form.Label>Container Width:</Form.Label>
                        </Col>
                        <Col lg="8" md="8">
                          <Form.Control
                            name="cWidth"
                            required="required"
                            type="text"
                            placeholder="Container Width"
                            className="form-control"
                            value={DefaultData.cWidth}
                            onChange={this.handleWidthChange}
                          />
                        </Col>
                        <div
                          className="errorMsg text-center"
                          style={{ color: "red" }}
                        >
                          {" "}
                          {this.state.errors.cWidth}{" "}
                        </div>
                      </Row>
                    </div>
                    <br />

                    <Modal
                      show={this.state.showModal}
                      onHide={this.handleCloseModal}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Select Option</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <select
                          className="form-select"
                          name="tableName"
                          onChange={this.inputtableSChange}
                          // value={this.state.selectedOption} onChange={this.handleOptionChange}
                        >
                          <option defaultValue>Select Table</option>
                          {this.state.tables.map((api, index, idx) => (
                            <option key={index} value={api}>
                              {api}
                            </option>
                          ))}
                        </select>
                        <br />
                        <select
                          className="form-select"
                          aria-label="Default select example"
                          value={DefaultData.columnName}
                          name="columnName"
                          onChange={this.handleColumnChange}
                        >
                          <option defaultValue>Select Column</option>
                          {this.state.columns.map((api, index) => (
                            <option key={index} value={api.DATA_TYPE}>
                              {api.COLUMN_NAME}
                            </option>
                          ))}
                        </select>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="secondary"
                          onClick={this.handleCloseModal}
                        >
                          Close
                        </Button>
                        <Button
                          variant="primary"
                          onClick={this.insertSelectedOption}
                        >
                          Insert Option
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <div>
                      <div className="container">
                        <ReactQuill
                          name="tData"
                          theme="snow"
                          ref={this.quillRef}
                          value={this.state.tData}
                          onKeyDown={this.handleKeyDown}
                          onChange={this.handleChange}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              [{ font: [] }],
                              ["bold", "italic", "underline", "strike"],
                              [{ color: [] }, { background: [] }],
                              [{ script: "sub" }, { script: "super" }],
                              ["blockquote", "code-block"],
                              [{ align: [] }],
                              [{ list: "ordered" }, { list: "bullet" }],
                              ["blockquote", "code-block"],
                              ["link", "image", "video"],
                              ["clean"],
                              [
                                { indent: "-1" },
                                { indent: "+1" },
                                { align: [] },
                              ],
                              [
                                {
                                  handler: "setWidth",
                                  label: "Set Width 100%",
                                },
                              ],
                            ],
                          }}
                          style={{
                            height: "200px",
                          }}
                        />
                        <style>{`.ql-editor img { max-width: 100%; }`}</style>
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />
                  {/* this bellow button for sizes are displaying in ////console */}
                  {/* <button onClick={this.getContent}>Get content</button> */}
                  <div className="mt-5">
                    <div className="row d-flex mt-5">
                      <div
                        className="justifyContent-end"
                        style={{ textAlign: "end" }}
                      >
                        <Button
                          style={{ textAlign: "end" }}
                          variant="success"
                          className=" bi bi-plus mt-5"
                          onClick={this.handleButtonClick}
                        >
                          Add Template
                        </Button>
                      </div>
                    </div>
                  </div>
                </Container>
              </Offcanvas.Body>
            </Offcanvas>
          </Col>
          <div>
            <Modal show={this.state.showOne} onHide={this.handleCloseOne}>
              <Modal.Header closeButton>
                <Modal.Title>Upload *csv file</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Button
                  variant="success"
                  className="bi bi-upload"
                  onClick={this.handleButtonClickOne}
                >
                  {" "}
                  Upload
                </Button>
                <input
                  type="file"
                  ref={this.inputFileRef}
                  style={{ display: "none" }}
                  accept=".csv"
                  onChange={this.handleFileChange}
                />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
                <Button variant="primary" onClick={this.handleClose}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
          <br />
          <div>
            {" "}
            {this.state.isEditing && (
              // Edit Offcanvas
              <Offcanvas
                show={this.state.isEditing}
                onHide={() => this.setState({ isEditing: false })}
                {...this.props}
                style={{ width: "700px", height: "auto" }}
                placement="end"
                backdrop="true"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Edit Templates</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <div className="form-horizontal form-label-left input_mask">
                    <form className="form-horizontal form-label-left input_mask">
                      <div>
                        <div>
                          <Row>
                            <Col lg="4" md="4">
                              <Form.Label>Template Name</Form.Label>
                            </Col>
                            <Col lg="8" md="8">
                              <Form.Control
                                name="tName"
                                value={this.state.ruleData.tName}
                                onChange={this.inputChange}
                                required="required"
                                type="text"
                                placeholder="Enter Template Name"
                                className="form-control"
                              />
                            </Col>
                            <div
                              className="errorMsg text-center"
                              style={{ color: "red" }}
                            >
                              {" "}
                              {this.state.errors.tName}{" "}
                            </div>
                          </Row>
                        </div>
                        <br />
                        {/* <div>
                    <Row>
                      <Col lg="4" md="4">
                        <Form.Label>Template Parameters</Form.Label>
                      </Col>
                      <Col lg="8" md="8">
                        <Form.Control
                          name="tParams"
                          value={this.state.ruleData.tParams}
                          onChange={this.inputChange}
                          required="required"
                          type="text"
                          placeholder="Enter Template Parameters"
                          className="form-control"
                        />
                      </Col>
                    </Row>
                  </div>
                  <br /> */}
                        <div>
                          <Row>
                            <Col lg="4" md="4">
                              <Form.Label>Container Height:</Form.Label>
                            </Col>
                            <Col lg="8" md="8">
                              <Form.Control
                                name="cHeight"
                                required="required"
                                type="text"
                                placeholder="Container Height:"
                                className="form-control"
                                value={this.state.ruleData.cHeight}
                                onChange={this.handleHeightChange}
                              />
                            </Col>
                            <div
                              className="errorMsg text-center"
                              style={{ color: "red" }}
                            >
                              {" "}
                              {this.state.errors.cHeight}{" "}
                            </div>
                          </Row>
                        </div>
                        <br />
                        <div>
                          <Row>
                            <Col lg="4" md="4">
                              <Form.Label>Container Width:</Form.Label>
                            </Col>
                            <Col lg="8" md="8">
                              <Form.Control
                                name="cWidth"
                                required="required"
                                type="text"
                                placeholder="Container Width"
                                className="form-control"
                                value={this.state.ruleData.cWidth}
                                onChange={this.handleWidthChange}
                              />
                            </Col>
                            <div
                              className="errorMsg text-center"
                              style={{ color: "red" }}
                            >
                              {" "}
                              {this.state.errors.cWidth}{" "}
                            </div>
                          </Row>
                        </div>
                        <br />

                        <Modal
                          show={this.state.showModal}
                          onHide={this.handleCloseModal}
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Select Option</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <select
                              className="form-select"
                              name="tableName"
                              onChange={this.inputtableSChange}
                              // value={this.state.selectedOption} onChange={this.handleOptionChange}
                            >
                              <option defaultValue>Select Table</option>
                              {this.state.tables.map((api, index, idx) => (
                                <option key={index} value={api}>
                                  {api}
                                </option>
                              ))}
                            </select>
                            <br />
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              value={DefaultData.columnName}
                              name="columnName"
                              onChange={this.handleColumnChange}
                            >
                              <option defaultValue>Select Column</option>
                              {this.state.columns.map((api, index) => (
                                <option key={index} value={api.DATA_TYPE}>
                                  {api.COLUMN_NAME}
                                </option>
                              ))}
                            </select>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={this.handleCloseModal}
                            >
                              Close
                            </Button>
                            <Button
                              variant="primary"
                              onClick={this.insertSelectedOption}
                            >
                              Insert Option
                            </Button>
                          </Modal.Footer>
                        </Modal>
                        <div>
                          <div className="container">
                            <ReactQuill
                              name="tData"
                              theme="snow"
                              ref={this.quillRef}
                              value={this.state.tData}
                              onKeyDown={this.handleKeyDown}
                              onChange={this.handleChange}
                              modules={{
                                toolbar: [
                                  [{ header: [1, 2, 3, 4, 5, 6, false] }],
                                  [{ font: [] }],
                                  ["bold", "italic", "underline", "strike"],
                                  [{ color: [] }, { background: [] }],
                                  [{ script: "sub" }, { script: "super" }],
                                  ["blockquote", "code-block"],
                                  [{ align: [] }],
                                  [{ list: "ordered" }, { list: "bullet" }],
                                  ["blockquote", "code-block"],
                                  ["link", "image", "video"],
                                  ["clean"],
                                  [
                                    { indent: "-1" },
                                    { indent: "+1" },
                                    { align: [] },
                                  ],
                                  [
                                    {
                                      handler: "setWidth",
                                      label: "Set Width 100%",
                                    },
                                  ],
                                ],
                              }}
                              style={{
                                height: "200px",
                              }}
                            />
                            <style>{`.ql-editor img { max-width: 100%; }`}</style>
                          </div>
                        </div>
                      </div>
                      <br />
                      <br />
                      <div className="mt-5">
                        <button
                          className="btn btn-primary me-2 mt-5"
                          style={{
                            backgroundColor: "#23547e",
                            color: "#fff",
                            borderColor: "#2e6da4",
                            float: "right",
                          }}
                          onClick={this.templateHandleupdate}
                        >
                          Update
                        </button>
                      </div>
                    </form>
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            )}
          </div>
          <br />
          <div style={{ marginRight: "5px" }}>
            {/* searchbar,pagination,filter */}
            <input
              type="text"
              onChange={this.handleFilter}
              placeholder="Search..."
              style={{ float: "left", marginBottom: "10px" }}
            />
            <DataTable
              data={paginatedData}
              columns={this.state.tableData}
              pagination
              paginationServer
              paginationTotalRows={
                this.state.filteredData
                  ? this.state.filteredData.length
                  : this.state.templates.length
              }
              onChangeRowsPerPage={this.handlePerRowsChange}
              onChangePage={this.handlePageChange}
              paginationPerPage={this.state.itemsPerPage}
              keyField="id"
              paginationRowsPerPageOptions={[
                10, 20, 30, 50, 60, 70, 80, 90, 100,
              ]}
              className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
            />
          </div>
        </div>
      </div>
    );
  }
}

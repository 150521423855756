import React, { Component } from "react";
import { Button, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTable from "react-data-table-component";
import configData from "../config.json";

export default class Roles extends Component {
  constructor(props) {
    super(props);
    this.inputFileRef = React.createRef();
    this.state = {
      show: false,
      error: {},
      roleItems: [],
      roles: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      rName: "",
      lPage: "",
      rId: "",
      rData: [],
      currentStep: 1,
      filteredData: null,
      tableClms: [
        {
          name: "Role Id",
          selector: (row, i) => row.rId,
          sortable: true,
          cell: (row) => <span>{row.rId}</span>,
        },
        {
          name: "Role Name",
          selector: (row, i) => row.rName,
          sortable: true,
          cell: (row) => <span>{row.rName}</span>,
        },

        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                style={{ marginRight: "10px" }}
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen"
              ></Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      isEditing: false, // Flag to indicate edit mode
      isNewEntry: false,
    };
  }
  //editing data
  handleEnterEditMode = (row) => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    const rData = JSON.parse(row.rData);
    this.setState({
      rName: row.rName,
      rId: row.rId,
      lPage: row.lPage,
      rData: rData,
      isEditing: true,
    });
  };

  handleCheckboxChange = (permission) => {
    this.setState((prevState) => ({
      rData: {
        ...prevState.rData,
        [permission]: !prevState.rData[permission],
      },
    }));
  };
  handleEnterNewEntryMode = () => {
    this.setState({
      rName: "",
      lPage: "",
      rData: {},
      isNewEntry: true,
    });
  };

  oncheckChange = (e) => {
    // this.setState({ showButton: true });
    this.setState({ rData: e.target.checked });
  };
  //Offcanvas
  handleClick = () => {
    this.setState({ showButton: true });
  };
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({ show: true, rName: "", lPage: "", rId: "", rData: [] });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type, key) => {
    const toastType =
      {
        success: toast.TYPE.SUCCESS,
        error: toast.TYPE.ERROR,
        warning: toast.TYPE.WARNING,
        info: toast.TYPE.INFO,
      }[type] || toast.TYPE.INFO; // Default to info if the type is not recognized

    toast(msg, { type: toastType, toastId: key });
  };
  getKey = (key, rData) => {
    const checked = rData[key];
    return checked;
  };

  getroleItems = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Role_items/list", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          // Initialize the checkbox data based on fetched table names
          this.setState({
            roleItems: data.list,
          });
        }
      });
  };

  validateForm() {
    let user = this.state;
    let errors = {};
    let formIsValid = true;

    if (!user["rName"]) {
      formIsValid = false;
      toast.info("*Please Enter Role Name.");
    } else if (!user["lPage"]) {
      formIsValid = false;
      toast.info("*Please Enter Landing Page.");
    }
    this.setState({
      errors: errors,
    });
    return formIsValid;
  }
  RolesfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "roles/all", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({ roles: data.list });
        }
      });
  };

  RolessubmitHandler = (e) => {
    e.preventDefault();
    // // Log the updated checkbox data
    const roleItems = document.getElementsByClassName("roleItems");
    const roleData = {};
    // console.log(roleItems);
    for (let i = 0; i <= roleItems.length - 1; i++) {
      // console.log(roleItems[i]);
      var id = roleItems[i].getElementsByClassName("rKeyName")[0].value;
      // console.log(roleItems[i].getElementsByClassName("rKeyName")[0].value);
      var checkBoxes = roleItems[i].getElementsByClassName("rowitem");
      for (var j = 0; j < checkBoxes.length; j++) {
        var checked = false;
        if (checkBoxes[j].checked) {
          checked = true;
        }
        // console.log(checked);
        var named = checkBoxes[j].getAttribute("name");
        //console.log(named);
        roleData[id + "_" + named] = checked;
      }
    }
    // console.log(roleData);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("rName", this.state.rName);
    formData.append("lPage", this.state.lPage);
    formData.append("rData", JSON.stringify(roleData));
    fetch(configData.api_url + "roles/add/", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({
            rName: "",
            lPage: "",
          });
          this.RolesfetchHandler();
        }
      });
  };
  allOnchange = (e) => {
    e.preventDefault();
    const roleItems = document.getElementsByClassName("roleItems");
    // console.log(roleItems);
    for (let i = 0; i <= roleItems.length - 1; i++) {
      var checkBoxes = roleItems[i].getElementsByClassName("rowitem");
      for (var j = 0; j < checkBoxes.length; j++) {
        checkBoxes[j].checked = e.target.checked;
      }
    }
  };

  rolesupdateHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    const roleItems = document.getElementsByClassName("roleItems");
    const roleData = {};
    // console.log(roleItems);
    for (let i = 0; i <= roleItems.length - 1; i++) {
      // console.log(roleItems[i]);
      var id = roleItems[i].getElementsByClassName("rKeyName")[0].value;
      // console.log(roleItems[i].getElementsByClassName("rKeyName")[0].value);
      var checkBoxes = roleItems[i].getElementsByClassName("rowitem");
      for (var j = 0; j < checkBoxes.length; j++) {
        var checked = false;
        if (checkBoxes[j].checked) {
          checked = true;
        }
        // console.log(checked);
        var named = checkBoxes[j].getAttribute("name");
        //console.log(named);
        roleData[id + "_" + named] = checked;
      }
    }
    // consosle.log(roleData);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("rName", this.state.rName);
    formData.append("lPage", this.state.lPage);
    formData.append("rId", this.state.rId);
    formData.append("rData", JSON.stringify(roleData));
    fetch(configData.api_url + "roles/update", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.setState({
            rName: "",
            lPage: "",
            rData: {},
            isEditing: false,
          });
          this.RolesfetchHandler();
          this.handleOffcanvasClose();
        }
      });
  };

  componentDidMount() {
    this.RolesfetchHandler();
    this.getroleItems();
  }

  handlerNameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ rName: fieldValue });
  };
  handlelPageChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ lPage: fieldValue });
  };

  handleEdit = (row) => {
    // console.log(`Editing row with ID ${row.id}`);
    // implement your edit logic here
  };
  handleDelete = (row, index) => {
    const newData = this.state.data;
    newData.splice(index, 1);
    this.setState({ data: newData });
    // console.log(`Delete row with ID ${row.id}`);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    const users_Id = row.users_Id;
    // console.log(e.target.parentElement);
    formData.append("users_Id", users_Id);
    fetch(configData.api_url + "/C_Users/delete", {
      // Enter your IP address here
      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.RolesfetchHandler();
        }
      });
    //implement your delete logic here
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  renderCheckboxesForItem = (item, itemIndex) => {
    const { checkboxData } = this.state;
    const checkboxArray = Array(5).fill(null);

    return (
      <div key={itemIndex}>
        <div className="row">
          <div className="col-md-4 col-lg-4">
            <span>{item}</span>
          </div>
          <div className="col-md-8 col-lg-8">
            {checkboxArray.map((_, checkboxIndex) => (
              <label key={checkboxIndex}>
                <input
                  type="checkbox"
                  checked={
                    checkboxData[`${item}_${checkboxIndex + 1}`] || false
                  }
                  onChange={() =>
                    this.handleCheckboxChange(item, checkboxIndex)
                  }
                />
                {item}&nbsp;_{checkboxIndex + 1}
              </label>
            ))}
          </div>
        </div>
      </div>
    );
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.roles.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.roles.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div>
        <div className="x_panel">
          <div className="x_content">
            <div className="dataTables_wrapper form-inline dt-bootstrap no-footer">
              <div>
                <h3 className="logoskybluecolor">Roles</h3>
              </div>
              <div
                className=" d-flex justify-content-end"
                style={{ marginRight: "5px", marginTop: "20px" }}
              >
                <div>
                  <Offcanvas
                    show={this.state.show}
                    onHide={this.handleOffcanvasClose}
                    {...this.props}
                    style={{ width: "700px" }}
                    placement="end"
                    backdrop="true"
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>Add New Role</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <div>
                        <div className="row divstyle">
                          <div className="col">
                            <Form>
                              <div className="row">
                                <div className="col-md-2 col-lg-2 col-sm-2">
                                  <label className="form-label">
                                    Role Name
                                  </label>
                                </div>
                                <div className="col-md-10 col-sm-10 col-lg-10">
                                  <input
                                    type="text"
                                    onChange={this.handlerNameChange}
                                    className="form-control"
                                    name="rName"
                                    value={this.state.rName}
                                    required="required"
                                    placeholder="Enter Role Name"
                                  ></input>
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-md-2 col-lg-2 col-sm-2">
                                  <label className="form-label">
                                    Landing Page
                                  </label>
                                </div>
                                <div className="col-md-10 col-sm-10 col-lg-10">
                                  <input
                                    type="text"
                                    onChange={this.handlelPageChange}
                                    className="form-control"
                                    name="lPage"
                                    value={this.state.lPage}
                                    required="required"
                                    placeholder="Please copy paste Url"
                                  ></input>
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-md-2 col-lg-2 col-sm-2">
                                  <label className="form-label">
                                    Permission Set
                                  </label>
                                </div>
                                <div className="col-md-10 col-sm-10 col-lg-10">
                                  <input
                                    type="checkbox"
                                    name="all"
                                    className="rowitemall"
                                    onChange={this.allOnchange}
                                  />
                                </div>
                              </div>
                              <br />
                              <div className="row">
                                <div className="col-md-12 col-lg-12 col-sm-12">
                                  <Row>
                                    <Col>
                                      <table
                                        className="table table-bordered table-hover"
                                        id="tab_logic"
                                      >
                                        <thead>
                                          <tr>
                                            <th className="text-center">
                                              S.NO
                                            </th>
                                            <th className="text-center">
                                              Name
                                            </th>
                                            <th className="text-center">Key</th>
                                            <th className="text-center">
                                              View
                                            </th>
                                            <th className="text-center">
                                              Create
                                            </th>
                                            <th className="text-center">
                                              Update
                                            </th>
                                            <th className="text-center">
                                              Delete
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {this.state.roleItems.length > 0 ? (
                                            this.state.roleItems.map(
                                              (api, index, idx) => (
                                                <tr
                                                  key={index}
                                                  className="roleItems"
                                                >
                                                  <td>
                                                    {index + 1}
                                                    <input
                                                      type="hidden"
                                                      name="rKeyName"
                                                      className="rKeyName"
                                                      value={api.rKey}
                                                    />
                                                  </td>
                                                  <td>{api.riName}</td>
                                                  <td>{api.rKey}</td>
                                                  <td>
                                                    <input
                                                      type="checkbox"
                                                      name="view"
                                                      className="rowitem"
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="checkbox"
                                                      name="create"
                                                      className="rowitem"
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="checkbox"
                                                      name="update"
                                                      className="rowitem"
                                                    />
                                                  </td>
                                                  <td>
                                                    <input
                                                      type="checkbox"
                                                      name="delete"
                                                      className="rowitem"
                                                    />
                                                  </td>
                                                </tr>
                                              )
                                            )
                                          ) : (
                                            <tr>
                                              <td colSpan="7"> No Records</td>
                                            </tr>
                                          )}
                                        </tbody>
                                      </table>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </Form>
                          </div>
                        </div>
                        <Row>
                          <Col
                            className="d-flex justify-content-end"
                            style={{ marginRight: "50px" }}
                          >
                            <Button
                              className="button"
                              variant="success"
                              onClick={this.RolessubmitHandler}
                              style={{
                                borderRadius: "0px",
                                marginTop: "20px",
                              }}
                            >
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </Offcanvas.Body>
                  </Offcanvas>
                  {/*offcanvas is over */}
                </div>
              </div>
              <br />
              <div>
                {this.state.isEditing && (
                  // Edit Offcanvas
                  <Offcanvas
                    show={this.state.isEditing}
                    // onHide={() => this.setState({ isEditing: false })}
                    onHide={() =>
                      this.setState({ isEditing: false, rData: {} })
                    }
                    {...this.props}
                    style={{ width: "700px", height: "auto" }}
                    placement="end"
                    backdrop="true"
                  >
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>Edit Roles</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <Form className="form-horizontal form-label-left input_mask">
                        <div>
                          <div className="row divstyle">
                            <div className="col">
                              <Form>
                                <div className="row">
                                  <div className="col-md-2 col-lg-2 col-sm-2">
                                    <label className="form-label">
                                      Role Name
                                    </label>
                                  </div>
                                  <div className="col-md-10 col-sm-10 col-lg-10">
                                    <input
                                      type="text"
                                      onChange={this.handlerNameChange}
                                      className="form-control"
                                      name="rName"
                                      value={this.state.rName}
                                      required="required"
                                      placeholder="Enter Role Name"
                                    ></input>
                                  </div>
                                </div>
                                <br />
                                <div className="row">
                                  <div className="col-md-2 col-lg-2 col-sm-2">
                                    <label className="form-label">
                                      Landing Page
                                    </label>
                                  </div>
                                  <div className="col-md-10 col-sm-10 col-lg-10">
                                    <input
                                      type="text"
                                      onChange={this.handlelPageChange}
                                      className="form-control"
                                      name="lPage"
                                      value={this.state.lPage}
                                      required="required"
                                      placeholder="Please copy paste Url"
                                    ></input>
                                  </div>
                                </div>
                                <br />
                                <div className="row">
                                  <div className="col-md-2 col-lg-2 col-sm-2">
                                    <label className="form-label">
                                      Permission Set
                                    </label>
                                  </div>
                                  <div className="col-md-10 col-sm-10 col-lg-10">
                                    <input
                                      type="checkbox"
                                      name="all"
                                      className="rowitemall"
                                      onChange={this.allOnchange}
                                    />
                                  </div>
                                </div>
                                <br />
                                <div className="row">
                                  <div className="col-md-12 col-lg-12 col-sm-12">
                                    <Row>
                                      <Col>
                                        <table
                                          className="table table-bordered table-hover"
                                          id="tab_logic"
                                        >
                                          <thead>
                                            <tr>
                                              <th className="text-center">
                                                S.NO
                                              </th>
                                              <th className="text-center">
                                                Name
                                              </th>
                                              <th className="text-center">
                                                Key
                                              </th>
                                              <th className="text-center">
                                                View
                                              </th>
                                              <th className="text-center">
                                                Create
                                              </th>
                                              <th className="text-center">
                                                Update
                                              </th>
                                              <th className="text-center">
                                                Delete
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {this.state.roleItems.length > 0 ? (
                                              this.state.roleItems.map(
                                                (api, index, idx) => (
                                                  <tr
                                                    key={index}
                                                    className="roleItems"
                                                  >
                                                    <td>
                                                      {index + 1}
                                                      <input
                                                        type="hidden"
                                                        name="rKeyName"
                                                        className="rKeyName"
                                                        value={api.rKey}
                                                      />
                                                    </td>
                                                    <td>{api.riName}</td>
                                                    <td>{api.rKey}</td>
                                                    <td>
                                                      <input
                                                        type="checkbox"
                                                        name="view"
                                                        className="rowitem"
                                                        checked={this.getKey(
                                                          `${api.rKey}_view`,
                                                          this.state.rData
                                                        )}
                                                        onChange={
                                                          this.oncheckChange
                                                        }
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="checkbox"
                                                        name="create"
                                                        className="rowitem"
                                                        checked={this.getKey(
                                                          `${api.rKey}_create`,
                                                          this.state.rData
                                                        )}
                                                        onChange={
                                                          this.oncheckChange
                                                        }
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="checkbox"
                                                        name="update"
                                                        className="rowitem"
                                                        checked={this.getKey(
                                                          `${api.rKey}_update`,
                                                          this.state.rData
                                                        )}
                                                        onChange={
                                                          this.oncheckChange
                                                        }
                                                      />
                                                    </td>
                                                    <td>
                                                      <input
                                                        type="checkbox"
                                                        name="delete"
                                                        className="rowitem"
                                                        checked={this.getKey(
                                                          `${api.rKey}_delete`,
                                                          this.state.rData
                                                        )}
                                                        onChange={
                                                          this.oncheckChange
                                                        }
                                                      />
                                                    </td>
                                                  </tr>
                                                )
                                              )
                                            ) : (
                                              <tr>
                                                <td colSpan="7"> No Records</td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </Col>
                                    </Row>
                                  </div>
                                </div>
                              </Form>
                            </div>
                          </div>
                        </div>
                        <br />
                        <br />
                        <button
                          className=" btn btn-warning me-2"
                          style={{
                            backgroundColor: "#fff",
                            color: "#000",
                            borderColor: "#ccc",
                          }}
                          onClick={() => this.setState({ isEditing: false })}
                        >
                          Close
                        </button>
                        <button
                          className="btn btn-primary me-2"
                          style={{
                            backgroundColor: "#23547e",
                            color: "#fff",
                            borderColor: "#2e6da4",
                          }}
                          onClick={this.rolesupdateHandler}
                        >
                          Save
                        </button>
                      </Form>
                    </Offcanvas.Body>
                  </Offcanvas>
                )}
              </div>
              <div className="row mt-3" style={{ marginRight: "5px" }}>
                {/* searchbar,pagination,filter */}
                <div className="col">
                  <input
                    type="text"
                    style={{ float: "left", marginBottom: "10px" }}
                    onChange={this.handleFilter}
                    placeholder="Search..."
                  />
                  <Button
                    style={{ float: "right" }}
                    variant="primary"
                    className="bi bi-plus "
                    onClick={this.handleOffcanvasShow}
                  >
                    New
                  </Button>
                  <DataTable
                    data={paginatedData}
                    columns={this.state.tableClms}
                    pagination
                    paginationServer
                    paginationTotalRows={
                      this.state.filteredData
                        ? this.state.filteredData.length
                        : this.state.roles.length
                    }
                    paginationRowsPerPageOptions={[
                      10, 20, 30, 50, 60, 70, 80, 90, 100,
                    ]}
                    onChangeRowsPerPage={this.handlePerRowsChange}
                    onChangePage={this.handlePageChange}
                    paginationPerPage={this.state.itemsPerPage}
                    keyField="id"
                    className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

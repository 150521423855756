import React, { Component } from "react";
import { Button, Container, Row, Form, Col, Offcanvas } from "react-bootstrap";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import configData from "../config.json";

export default class Healthageaddons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      data: [],
      sId: sessionStorage.getItem("sessionId"),
      uId: sessionStorage.getItem("uId"),
      errors: {},
      filteredData: null,
      tableClms: [
        {
          name: "S.NO",
          selector: (row, i) => row.healthageaddons_Id,
          sortable: true,
          cell: (row) => <span>{row.healthageaddons_Id}</span>,
        },
        {
          name: "insurername",
          selector: (row, i) => row.insurername,
          sortable: true,
          cell: (row) => <span>{row.insurername}</span>,
        },
        {
          name: "product name",
          selector: (row, i) => row.productname,
          sortable: true,
          cell: (row) => <span>{row.productname}</span>,
        },
        {
          name: "product code",
          selector: (row, i) => row.productcode,
          sortable: true,
          cell: (row) => <span>{row.productcode}</span>,
        },
        {
          name: "product id",
          selector: (row, i) => row.productid,
          sortable: true,
          cell: (row) => <span>{row.productid}</span>,
        },
        {
          name: "minimum age",
          selector: (row, i) => row.minimumage,
          sortable: true,
          cell: (row) => <span>{row.minimumage}</span>,
        },
        {
          name: "maximum age",
          selector: (row, i) => row.maximumage,
          sortable: true,
          cell: (row) => <span>{row.maximumage}</span>,
        },
        {
          name: "addon code",
          selector: (row, i) => row.addoncode,
          sortable: true,
          cell: (row) => <span>{row.addoncode}</span>,
        },
        {
          name: "addon code id",
          selector: (row, i) => row.addoncodeid,
          sortable: true,
          cell: (row) => <span>{row.addoncodeid}</span>,
        },
        {
          name: "dummy one",
          selector: (row, i) => row.dummyone,
          sortable: true,
          cell: (row) => <span>{row.dummyone}</span>,
        },
        {
          name: "dummy two",
          selector: (row, i) => row.dummytwo,
          sortable: true,
          cell: (row) => <span>{row.dummytwo}</span>,
        },
        {
          name: "dummy three",
          selector: (row, i) => row.dummythree,
          sortable: true,
          cell: (row) => <span>{row.dummythree}</span>,
        },
        {
          name: "Actions",
          sortable: false,
          cell: (row) => (
            <div>
              {/* edit button and delete button */}
              <Button
                variant="primary"
                onClick={() => this.handleEnterEditMode(row)}
                className="bi bi-pen"
                style={{ marginRight: "3px" }}
              ></Button>
              <Button
                variant="danger"
                onClick={() => this.handleDelete(row)}
                className="bi bi-trash danger"
              ></Button>
            </div>
          ),
        },
      ],
      currentPage: 1,
      itemsPerPage: 10,
      insurername: "",
      productname: "",
      productcode: "",
      productid: "",
      minimumage: "",
      maximumage: "",
      addoncode: "",
      addoncodeid: "",
      dummyone: "",
      dummytwo: "",
      dummythree: "",
      healthageaddons_Id: "",
      isEditing: false, // Flag to indicate edit mode
      isNewEntry: false,
    };
  }
  //edit
  handleEnterEditMode = (row) => {
    // this.setState({ cData: { ...row }, isEditing: true });
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    this.setState({
      insurername: row.insurername,
      productname: row.productname,
      productcode: row.productcode,
      productid: row.productid,
      minimumage: row.minimumage,
      maximumage: row.maximumage,
      addoncode: row.addoncode,
      addoncodeid: row.addoncodeid,
      dummyone: row.dummyone,
      dummytwo: row.dummytwo,
      dummythree: row.dummythree,
      healthageaddons_Id: row.healthageaddons_Id,
      isEditing: true,
    });
  };
  // offcanvas Open
  handleOffcanvasShow = () => {
    this.setState({
      show: true,
      insurername: "",
      productname: "",
      productcode: "",
      productid: "",
      minimumage: "",
      maximumage: "",
      addoncode: "",
      addoncodeid: "",
      dummyone: "",
      dummytwo: "",
      dummythree: "",
    });
  };
  // Offcanvas close button
  handleOffcanvasClose = () => {
    this.setState({ show: false });
  };
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  HealthageaddonseditHandler = (e) => {
    e.preventDefault();
    // const updateFormData = {...editFormData};
    // updateFormData['Healthageaddons_Id'] = e.target.parentElement.getAttribute("id");
    // setEditFormData(updateFormData);
    // setEdit(true);
  };

  HealthageaddonsfetchHandler = () => {
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    fetch(configData.api_url + "C_Healthageaddons/list", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ data: data.list });
        }
      });
  };
  HealthageaddonssubmitHandler = (e) => {
    e.preventDefault();
    //   setButtons(contacts);
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("insurername", this.state.insurername);
    formData.append("productname", this.state.productname);
    formData.append("productcode", this.state.productcode);
    formData.append("productid", this.state.productid);
    formData.append("minimumage", this.state.minimumage);
    formData.append("maximumage", this.state.maximumage);
    formData.append("addoncode", this.state.addoncode);
    formData.append("addoncodeid", this.state.addoncodeid);
    formData.append("dummyone", this.state.dummyone);
    formData.append("dummytwo", this.state.dummytwo);
    formData.append("dummythree", this.state.dummythree);

    fetch(configData.api_url + "C_Healthageaddons/add", {
      // Enter your IP address here

      method: "POST",
      //headers :{ 'Content-Type' : 'application/json'},
      mode: "cors",
      body: formData, // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.HealthageaddonsfetchHandler();
          this.handleOffcanvasClose();
          this.setState({
            insurername: "",
            productname: "",
            productcode: "",
            productid: "",
            minimumage: "",
            maximumage: "",
            addoncode: "",
            addoncodeid: "",
            dummyone: "",
            dummytwo: "",
            dummythree: "",
          });
        }
      });
  };
  HealthageaddonsupdateHandler = (e) => {
    e.preventDefault();
    var formData = new FormData();
    formData.append("sId", this.state.sId);
    formData.append("uId", this.state.uId);
    formData.append("insurername", this.state.insurername);
    formData.append("productname", this.state.productname);
    formData.append("productcode", this.state.productcode);
    formData.append("productid", this.state.productid);
    formData.append("minimumage", this.state.minimumage);
    formData.append("maximumage", this.state.maximumage);
    formData.append("addoncode", this.state.addoncode);
    formData.append("addoncodeid", this.state.addoncodeid);
    formData.append("dummyone", this.state.dummyone);
    formData.append("dummytwo", this.state.dummytwo);
    formData.append("dummythree", this.state.dummythree);
    formData.append("healthageaddons_Id", this.state.healthageaddons_Id);

    fetch(configData.api_url + "C_Healthageaddons/update", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.HealthageaddonsfetchHandler();
          this.handleOffcanvasClose();
        }
      });
  };

  componentDidMount() {
    this.HealthageaddonsfetchHandler();
  }
  handleinsurernameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ insurername: fieldValue });
  };
  handleproductnameChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ productname: fieldValue });
  };
  handleproductcodeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ productcode: fieldValue });
  };
  handleproductidChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ productid: fieldValue });
  };
  handleminimumageChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ minimumage: fieldValue });
  };
  handlemaximumageChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ maximumage: fieldValue });
  };
  handleaddoncodeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ addoncode: fieldValue });
  };
  handleaddoncodeidChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ addoncodeid: fieldValue });
  };
  handledummyoneChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummyone: fieldValue });
  };
  handledummytwoChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummytwo: fieldValue });
  };
  handledummythreeChange = (e) => {
    e.preventDefault();
    const fieldValue = e.target.value;
    this.setState({ dummythree: fieldValue });
  };
  handleRowSelect = (row) => {
    this.setState({
      selectedRow: row.index,
    });
  };
  handleRowUpdate = (newData, oldData) => {
    const data = [...this.state.data];
    const index = oldData.index;
    data[index] = newData;
    this.setState({ data });
  };

  handleEdit = (row) => {
    console.log(`Editing row with ID ${row.id}`);
    // implement your edit logic here
  };
  handleDelete = (row, index) => {
    var formData = new FormData();
    formData.append("healthageaddons_Id", row.healthageaddons_Id);
    fetch(configData.api_url + "C_Healthageaddons/delete", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.HealthageaddonsfetchHandler();
        }
      });
  };
  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handlePerRowsChange = (newPerPage, page) => {
    this.setState({ itemsPerPage: newPerPage, currentPage: page });
  };

  handleFilter = (e) => {
    const searchValue = e.target.value.toLowerCase();
    const filteredData = this.state.data.filter((row) => {
      return Object.values(row).join(" ").toLowerCase().includes(searchValue);
    });
    this.setState({ filteredData });
  };

  render() {
    const paginatedData = this.state.filteredData
      ? this.state.filteredData.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        )
      : this.state.data.slice(
          (this.state.currentPage - 1) * this.state.itemsPerPage,
          this.state.currentPage * this.state.itemsPerPage
        );

    return (
      <div className="page-title">
        <div className="row">
          <div className="title_left">
            <h2 style={{ textAlign: "start" }}>Health Age-Addons</h2>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="x_panel">
              <div className="x_content">
                <div
                  id="datatable_wrapper"
                  className="dataTables_wrapper form-inline
                                   dt-bootstrap no-footer"
                >
                  <Row className="row">
                    <Col
                      className="d-flex justify-content-end"
                      style={{ marginRight: "5px", marginTop: "20px" }}
                    >
                      <Button
                        variant="primary"
                        className="bi bi-plus "
                        onClick={this.handleOffcanvasShow}
                      >
                        New
                      </Button>
                      <Offcanvas
                        show={this.state.show}
                        onHide={this.handleOffcanvasClose}
                        {...this.props}
                        style={{ width: "500px" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            Health Age-Addons Page
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Container
                            style={{ overflowY: "auto", height: "auto" }}
                          >
                            <Row className="divstyle">
                              <Row>
                                <Form>
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        insurername
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleinsurernameChange}
                                        className="form-control"
                                        name="insurername"
                                        value={this.state.insurername}
                                        required="required"
                                        placeholder="Enter insurername"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.insurername}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        product name
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleproductnameChange}
                                        className="form-control"
                                        name="productname"
                                        value={this.state.productname}
                                        required="required"
                                        placeholder="Enter productname"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.productname}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        product code
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleproductcodeChange}
                                        className="form-control"
                                        name="productcode"
                                        value={this.state.productcode}
                                        required="required"
                                        placeholder="Enter productcode"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.productcode}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        product id
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleproductidChange}
                                        className="form-control"
                                        name="productid"
                                        value={this.state.productid}
                                        required="required"
                                        placeholder="Enter productid"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.productid}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        minimum age
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleminimumageChange}
                                        className="form-control"
                                        name="minimumage"
                                        value={this.state.minimumage}
                                        required="required"
                                        placeholder="Enter minimumage"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.minimumage}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        maximum age
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handlemaximumageChange}
                                        className="form-control"
                                        name="maximumage"
                                        value={this.state.maximumage}
                                        required="required"
                                        placeholder="Enter maximumage"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.maximumage}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        addon code
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleaddoncodeChange}
                                        className="form-control"
                                        name="addoncode"
                                        value={this.state.addoncode}
                                        required="required"
                                        placeholder="Enter addoncode"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.addoncode}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        addon code id
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handleaddoncodeidChange}
                                        className="form-control"
                                        name="addoncodeid"
                                        value={this.state.addoncodeid}
                                        required="required"
                                        placeholder="Enter addoncodeid"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.addoncodeid}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        dummy one
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummyoneChange}
                                        className="form-control"
                                        name="dummyone"
                                        value={this.state.dummyone}
                                        required="required"
                                        placeholder="Enter dummyone"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummyone}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        dummy two
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummytwoChange}
                                        className="form-control"
                                        name="dummytwo"
                                        value={this.state.dummytwo}
                                        required="required"
                                        placeholder="Enter dummytwo"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummytwo}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg="4" md="4">
                                      <Form.Label style={{ paddingTop: "8px" }}>
                                        dummy three
                                      </Form.Label>
                                    </Col>
                                    <Col lg="8" md="8">
                                      <Form.Control
                                        type="text"
                                        onChange={this.handledummythreeChange}
                                        className="form-control"
                                        name="dummythree"
                                        value={this.state.dummythree}
                                        required="required"
                                        placeholder="Enter dummythree"
                                      />
                                    </Col>
                                    <div
                                      className="errorMsg"
                                      style={{ color: "red" }}
                                    >
                                      {" "}
                                      {this.state.errors.dummythree}{" "}
                                    </div>
                                  </Row>
                                  <br />
                                </Form>
                              </Row>
                            </Row>
                            <Row>
                              <Col
                                className="d-flex justify-content-end"
                                style={{ marginRight: "50px" }}
                              >
                                <Button
                                  className="button"
                                  variant="success"
                                  onClick={this.HealthageaddonssubmitHandler}
                                  style={{
                                    marginTop: "20px",
                                  }}
                                >
                                  Save
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </Offcanvas.Body>
                      </Offcanvas>
                      {/*offcanvas is over */}
                    </Col>
                  </Row>
                  <br />
                  <div>
                    {" "}
                    {this.state.isEditing && (
                      // Edit Offcanvas
                      <Offcanvas
                        show={this.state.isEditing}
                        onHide={() => this.setState({ isEditing: false })}
                        {...this.props}
                        style={{ width: "500px", height: "auto" }}
                        placement="end"
                        backdrop="true"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title>
                            Edit Health Age Addons
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <div className="form-horizontal form-label-left input_mask">
                            <Form className="form-horizontal form-label-left input_mask">
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    insurername
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleinsurernameChange}
                                    className="form-control"
                                    name="insurername"
                                    value={this.state.insurername}
                                    required="required"
                                    placeholder="Enter insurername"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.insurername}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    product name
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleproductnameChange}
                                    className="form-control"
                                    name="productname"
                                    value={this.state.productname}
                                    required="required"
                                    placeholder="Enter productname"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.productname}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    product code
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleproductcodeChange}
                                    className="form-control"
                                    name="productcode"
                                    value={this.state.productcode}
                                    required="required"
                                    placeholder="Enter productcode"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.productcode}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    product id
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleproductidChange}
                                    className="form-control"
                                    name="productid"
                                    value={this.state.productid}
                                    required="required"
                                    placeholder="Enter productid"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.productid}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    minimum age
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleminimumageChange}
                                    className="form-control"
                                    name="minimumage"
                                    value={this.state.minimumage}
                                    required="required"
                                    placeholder="Enter minimumage"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.minimumage}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    maximum age
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handlemaximumageChange}
                                    className="form-control"
                                    name="maximumage"
                                    value={this.state.maximumage}
                                    required="required"
                                    placeholder="Enter maximumage"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.maximumage}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    addon code
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleaddoncodeChange}
                                    className="form-control"
                                    name="addoncode"
                                    value={this.state.addoncode}
                                    required="required"
                                    placeholder="Enter addoncode"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.addoncode}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    addon code id
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handleaddoncodeidChange}
                                    className="form-control"
                                    name="addoncodeid"
                                    value={this.state.addoncodeid}
                                    required="required"
                                    placeholder="Enter addoncodeid"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.addoncodeid}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    dummy one
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handledummyoneChange}
                                    className="form-control"
                                    name="dummyone"
                                    value={this.state.dummyone}
                                    required="required"
                                    placeholder="Enter dummyone"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.dummyone}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    dummy two
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handledummytwoChange}
                                    className="form-control"
                                    name="dummytwo"
                                    value={this.state.dummytwo}
                                    required="required"
                                    placeholder="Enter dummytwo"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.dummytwo}{" "}
                                </div>
                              </Row>
                              <br />
                              <Row>
                                <Col lg="4" md="4">
                                  <Form.Label style={{ paddingTop: "8px" }}>
                                    dummy three
                                  </Form.Label>
                                </Col>
                                <Col lg="8" md="8">
                                  <Form.Control
                                    type="text"
                                    onChange={this.handledummythreeChange}
                                    className="form-control"
                                    name="dummythree"
                                    value={this.state.dummythree}
                                    required="required"
                                    placeholder="Enter dummythree"
                                  />
                                </Col>
                                <div
                                  className="errorMsg"
                                  style={{ color: "red" }}
                                >
                                  {" "}
                                  {this.state.errors.dummythree}{" "}
                                </div>
                              </Row>
                              <br />
                              <div style={{ float: "right" }}>
                                <button
                                  className="btn btn-primary me-2 mt-5"
                                  style={{
                                    backgroundColor: "#23547e",
                                    color: "#fff",
                                    borderColor: "#2e6da4",
                                  }}
                                  onClick={this.HealthageaddonsupdateHandler}
                                >
                                  Update
                                </button>
                              </div>
                            </Form>
                          </div>
                        </Offcanvas.Body>
                      </Offcanvas>
                    )}
                  </div>
                  <br />
                  <Row>
                    <div style={{ marginRight: "5px" }}>
                      {/* searchbar,pagination,filter */}
                      <input
                        type="text"
                        style={{ float: "left", marginBottom: "10px" }}
                        onChange={this.handleFilter}
                        placeholder="Search..."
                      />
                      <DataTable
                        data={paginatedData}
                        columns={this.state.tableClms}
                        pagination
                        paginationServer
                        paginationTotalRows={
                          this.state.filteredData
                            ? this.state.filteredData.length
                            : this.state.data.length
                        }
                        paginationRowsPerPageOptions={[
                          10, 20, 30, 50, 60, 70, 80, 90, 100,
                        ]}
                        onChangeRowsPerPage={this.handlePerRowsChange}
                        onChangePage={this.handlePageChange}
                        paginationPerPage={this.state.itemsPerPage}
                        keyField="id"
                        className="table table-striped table-bordered dataTable no-footer employeesTableIcons"
                      />
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
